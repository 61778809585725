import { subActions } from "../../actionTypes";
import { stripePayment } from "../../../api/libs/plans";
import toast from "react-hot-toast";
import { fetchCompany } from "../company";
import { fetchInvoiceLocations } from "../invoice";

export const initStripePayment = (data, history) => {
	console.log("initStripePayment");
	return async dispatch => {
		dispatch({
			type: subActions.INIT_SUBSCRIPTION,
		});
		stripePayment(data)
			.then(() => {
				toast.success("Success");
				// dispatch({
				//   type: subActions.INIT_SUBSCRIPTION_FULFILLED,
				//   payload: res,
				// });
				dispatch(fetchCompany(localStorage.getItem("activeCompany")));
				dispatch(fetchInvoiceLocations());
				// history.push("/");
			})
			.catch(err => {
				dispatch({
					type: subActions.INIT_SUBSCRIPTION_REJECTED,
					payload: err.response.data,
				});
				toast.error("Cancel other plans to create new one");
				history.push("/company");
			});
	};
};
