export const content = {
	en: {
		header: "Events",
		client: "Create Event",
		description: "Description",
		title: "Title",
		start_at: "Start at",
		end_at: "End at",
		location: "Location",
		locb: "Location button",
		no_data: "There is no data for this type.",
		add_event: "Add event",
		future: "future",
		old: "old",
		published: "published",
		archive: "Archive",
		recurrence: "Recurrence",
		event_start_at: "Event starts at",
		event_end_at: "Event ends at",
		edit: "Edit",
		logo: "Logo",
		delete: "Delete",
		copy: "Link",
	},
	de: {
		header: "Veranstaltungen",
		client: "Ereignis erstellen",
		description: "Beschreibung",
		title: "Titel",
		start_at: "Beginne bei",
		end_at: "Ende um",
		location: "Standort",
		locb: "Standort-Schaltfläche",
		no_data: "Für diesen Typ sind keine Daten vorhanden.",
		add_event: "Ereignis hinzufügen",
		future: "Zukunft",
		old: "alt",
		published: "veröffentlicht",
		archive: "Archiv",
		recurrence: "Wiederauftreten",
		event_start_at: "Veranstaltung beginnt um",
		event_end_at: "Veranstaltung endet um",
		edit: "Bearbeiten",
		logo: "Logo",
		delete: "Löschen",
		copy: "Link",
	},
};
