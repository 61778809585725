import { companyActions, customActions } from "../../actionTypes";
import { getCompany } from "../../../api/libs/company";
import { setActiveCompanyToken } from "../../../api";
import { fetchInvoiceLocations } from "../invoice";

export const fetchCompany = id => {
	return async dispatch => {
		dispatch({
			type: companyActions.FETCH_COMPANY,
		});
		try {
			const res = await getCompany(id);
			dispatch({
				type: companyActions.FETCH_COMPANY_FULFILLED,
				payload: res.company,
			});
			setActiveCompanyToken(res.company.id);
			localStorage.setItem("activeCompany", res.company.id);
			dispatch(fetchInvoiceLocations());
			dispatch({
				type: customActions.SET_APP_STATE,
				payload: false,
			});
			return res.company;
		} catch (err) {
			dispatch({
				type: companyActions.FETCH_COMPANY_REJECTED,
				payload: err.response,
			});
			dispatch({
				type: customActions.SET_APP_STATE,
				payload: true,
			});
			// localStorage.removeItem("activeCompany");
			// localStorage.removeItem("activeInvoice");
			dispatch({
				type: customActions.SET_APP_STATE,
				payload: false,
			});
			throw err;
		}
	};
};
