import { notificationActions } from "../../actionTypes";
import { getNotificationsCount } from "../../../api/libs/notifications";

export const fetchNotificationsCount = () => {
	return async dispatch => {
		dispatch({
			type: notificationActions.FETCH_NOTIFICATIONS_COUNT,
		});
		getNotificationsCount()
			.then(res => {
				dispatch({
					type: notificationActions.FETCH_NOTIFICATIONS_COUNT_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: notificationActions.FETCH_NOTIFICATIONS_COUNT_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
