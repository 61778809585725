export const content = {
	en: {
		confirm: "Confirm",
		reset: "Reset",
		future: "future",
		old: "old",
		published: "published",
		Description: "Description",
		Location: "Location",
		Logo: "Logo",
		Gallery: "Gallery",
		Keywords: "Keywords",
		"Google map": "Google map",
		AlbumText: "Text",
		Builder: "Builder",
		Hours: "Hours",
		Coupon: "Coupon",
		archive: "ARCHIVED",
		Preview: "Preview",
		DescriptionStory: "Description",
		deleted: "Deleted",
		Create: "Create",
		Coupons: "Coupons",
		push_notification: "Notification",
		paypal: "Paypal",
		stripe: "Stripe",
		location: "locations",
		event: "Events",
		news: "News",
	},
	de: {
		confirm: "Bestätigen",
		reset: "Zurücksetzten",
		future: "GEPLANT",
		old: "ARCHIVIERT",
		published: "veröffentlicht",
		Description: "Location",
		Location: "Addresse",
		Logo: "Logo",
		Gallery: "Bildalbum",
		Keywords: "Suchbegriffe",
		"Google map": "Googlesuche",
		AlbumText: "Albumtext",
		Builder: "Bild / Layout",
		Hours: "Öffnungszeiten",
		Coupon: "Coupon",
		archive: "ARCHIVIERT",
		Preview: "Vorschau",
		DescriptionStory: "Beschreibung",
		deleted: "Gelöscht",
		Create: "Erstellen",
		Coupons: "Gutscheine",
		push_notification: "Benachrichtigung",
		paypal: "Paypal",
		stripe: "Stripe",
		location: "locations",
		event: "Events",
		news: "News",
	},
};
