import { fabric } from "fabric";
import { onDrawBorders, onDrawSnapHelperLines } from "./fabricActions";
import { calculateDimensions } from "./dimensions";

export const initCanvas = (variant, refer, or, dimensions) => {
	let canvas = new fabric.Canvas(`c-${variant}`, {
		preserveObjectStacking: false,
		containerClass: "canvas-container",
	});
	canvas.setDimensions({
		width: dimensions[or].width,
		height: dimensions[or].height,
	});
	readyCanvas(canvas);
	onDrawBorders();
	onDrawSnapHelperLines(canvas);
	return canvas;
};

export const addBg = canvas => {
	if (canvas) {
		canvas.setBackgroundColor(
			{
				source: require("../../../../assets/builder/transparent-pattern.png").default,
				repeat: "repeat",
				crossOrigin: "anonymous",
				helper: true,
			},
			() => {
				canvas.renderAll();
			},
		);
	}
};

const removeBg = canvas => {
	if (canvas) {
		canvas.setBackgroundColor(null, () => canvas.renderAll());
	}
};

// const removeMask = (canvas) => {
//   if (canvas) {
//     canvas.setOverlayImage(null, () => canvas.renderAll());
//   }
// };

const readyCanvas = canvas => {
	addBg(canvas);
};

const prepareCanvas = canvas => {
	removeBg(canvas);
};

export const changeMobOrientation = (canvas, target) => {
	const dimensions = calculateDimensions();
	for (let d in dimensions) {
		if (dimensions[d].name === target) {
			canvas.forEachObject(obj => {
				if (obj.id === "vertical_snap") {
					obj.set("x1", dimensions[d].width / 2);
					obj.set("y1", 0);
					obj.set("x2", dimensions[d].width / 2);
					obj.set("y2", dimensions[d].height);
				} else if (obj.id === "horizontal_snap") {
					obj.set("x1", 0);
					obj.set("y1", dimensions[d].height / 2);
					obj.set("x2", dimensions[d].width);
					obj.set("y2", dimensions[d].height / 2);
				}
			});
			canvas
				.setDimensions({
					width: dimensions[d].width,
					height: dimensions[d].height,
				})
				.renderAll();
		}
	}
};

export const drawSnapLine = (canvas, type, opacity) => {
	canvas.forEachObject(obj => {
		if (obj.id === type) {
			obj.set("evented", false);
			obj.set("selectable", false);
			obj.set("opacity", opacity);
		}
	});
};

export const jsonToCanvas = (canvas, template, callback = () => {}) => {
	if (canvas && template) {
		canvas.loadFromJSON(template, () => {
			callback();
			canvas.renderAll();
		});
	}
};

export const canvasToPng = (canvas, ratio = 2) => {
	if (canvas) {
		return canvas.toDataURL({ format: "png", multiplier: ratio });
	}
};

export const exportPng = (canvas, variant) => {
	if (canvas) {
		prepareCanvas(canvas, variant);
		let base64Png = canvasToPng(canvas);
		readyCanvas(canvas, variant);
		return base64Png;
	}
};

const previewLogo = (slides, canvas, formik) => {
	if (slides[0].isEdited) {
		let newPng = exportPng(canvas, "logo");
		formik.setFieldValue("logo", newPng);
	}
};

export const previewGallery = (slides, formik) => {
	let newArr = [];
	for (let [, slide] of slides.entries()) {
		if (slide.isVideo) {
			newArr.push(slide.video);
		} else if (slide.isEdited) {
			newArr.push(slide.exPng);
		} else {
			if (slide.png) {
				newArr.push(slide.png);
			}
		}
	}
	formik.setFieldValue("gallery", newArr);
};

export const preview = (slides, canvas, variant, formik) => {
	if (variant === "logo") {
		previewLogo(slides, canvas, formik);
	} else {
		previewGallery(slides, formik);
	}
};
