import React from "react";
import { Box, Heading, Text } from "@chakra-ui/layout";
import PrimaryButton from "../../buttons/PrimaryButton";
import PrimaryInput from "../../inputs/PrimaryInput";
import { Skeleton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteKeyword } from "../../../../store/actions";

export default function Layout({
	createKeyword,
	formik,
	keywords,
	keywordIds,
	fetchingKeywords,
	dispatch,
	fetchingMyKeywords,
	myKeywords,
	myKeywordIds,
}) {
	return (
		<Box display="flex" flexDirection="column" h={"61vh"}>
			<form
				onSubmit={createKeyword.handleSubmit}
				style={{ display: "flex", flexDirection: "column" }}
			>
				<PrimaryInput
					column={true}
					type="text"
					formik={createKeyword}
					id={`name`}
					label="Create keyword"
				/>
				<PrimaryButton type="submit" mt="1em" label="Create" />
			</form>

			<Box>
				<Heading fontSize="1.5em" my="5">
					Selected keywords
				</Heading>

				{formik.values.keywords.length ? (
					<PrimaryButton
						onClick={() => formik.setFieldValue("keywords", [])}
						size="lg"
						label="UNSELECT ALL"
					/>
				) : (
					<Text>There are no keywords selected yet.</Text>
				)}
			</Box>
			<Box display="flex" flexWrap="wrap" my="5">
				{formik.values.keywords.map((item, key) => (
					<PrimaryButton
						key={key}
						onClick={() =>
							formik.values.keywords.map(item => item.id).includes(item.id)
								? formik.setFieldValue(
										"keywords",
										formik.values.keywords.filter(filt => filt.name !== item.name),
								  )
								: formik.setFieldValue("keywords", [...formik.values.keywords, item])
						}
						_focus={{ outline: "none" }}
						m="1"
						color="#FFF"
						bg={formik.values.keywords.map(item => item.id).includes(item.id) ? "#AFCA09" : "#333"}
						label={item.name}
					></PrimaryButton>
				))}
			</Box>
			{!fetchingKeywords && keywords.data.length ? (
				<>
					<Heading fontSize="1.5em" my="5">
						Keywords By Category
					</Heading>
					<Box display="flex">
						<PrimaryButton
							onClick={() => {
								formik.setFieldValue("keywords", [
									...new Set([...keywords.data, ...formik.values.keywords]),
								]);
							}}
							size="lg"
							mr="1"
							label="SELECT ALL"
						/>
					</Box>
					<Box display="flex" flexWrap="wrap" my="5">
						{fetchingKeywords ? (
							<Skeleton width="100%" height="40px"></Skeleton>
						) : (
							keywords.data.map((item, key) => (
								<PrimaryButton
									key={key}
									onClick={() =>
										formik.values.keywords.map(item => item.id).includes(item.id)
											? formik.setFieldValue(
													"keywords",
													formik.values.keywords.filter(filt => filt.name !== item.name),
											  )
											: formik.setFieldValue("keywords", [...formik.values.keywords, item])
									}
									_focus={{ outline: "none" }}
									m="1"
									bg={
										formik.values.keywords.map(item => item.id).includes(item.id)
											? "#AFCA09"
											: "#D9D9D9"
									}
									color={
										formik.values.keywords.map(item => item.id).includes(item.id)
											? "#FFF"
											: "rgba(0,0,0,0.5)"
									}
									label={item.name}
								></PrimaryButton>
							))
						)}
					</Box>
				</>
			) : null}
			{!fetchingMyKeywords && myKeywords.data.length ? (
				<>
					<Heading fontSize="1.5em" my="5">
						My Keywords
					</Heading>
					<Box display="flex">
						<PrimaryButton
							onClick={() => {
								formik.setFieldValue("keywords", [
									...new Set([...myKeywords.data, ...formik.values.keywords]),
								]);
							}}
							size="lg"
							mr="1"
							label="SELECT ALL"
						/>
					</Box>
					<Box display="flex" flexWrap="wrap" my="5">
						{fetchingMyKeywords ? (
							<Skeleton width="100%" height="40px"></Skeleton>
						) : (
							myKeywords.data.map((item, key) => (
								<Box key={key} position="relative">
									<Box
										as="button"
										width="1em"
										height="1em"
										backgroundColor="red"
										position="absolute"
										right="0"
										top="0"
										zIndex="2"
										borderRadius="100%"
										_hover={{ opacity: 0.7 }}
										display="flex"
										alignItems="center"
										justifyContent="center"
										onClick={() => {
											dispatch(deleteKeyword(item.id, formik));
										}}
									>
										<FontAwesomeIcon size="xs" icon="times" color="white" />
									</Box>
									<PrimaryButton
										onClick={() =>
											formik.values.keywords.map(item => item.id).includes(item.id)
												? formik.setFieldValue(
														"keywords",
														formik.values.keywords.filter(filt => filt.id !== item.id),
												  )
												: formik.setFieldValue("keywords", [...formik.values.keywords, item])
										}
										_focus={{ outline: "none" }}
										m="1"
										bg={
											formik.values.keywords.map(item => item.id).includes(item.id)
												? "#AFCA09"
												: "#D9D9D9"
										}
										color={
											formik.values.keywords.map(item => item.id).includes(item.id)
												? "#FFF"
												: "rgba(0,0,0,0.5)"
										}
										label={item.name}
									></PrimaryButton>
								</Box>
							))
						)}
					</Box>
				</>
			) : null}
		</Box>
	);
}
