import { subscriberActions } from "../../actionTypes";
import { getSubs } from "../../../api/libs/stats";

export const fetchSubs = config => {
	console.log("აქ", config);
	return async dispatch => {
		dispatch({
			type: subscriberActions.FETCH_SUBS,
		});
		getSubs(config)
			.then(res => {
				dispatch({
					type: subscriberActions.FETCH_SUBS_FULFILED,
					payload: { ...config, res: res.data },
				});
			})
			.catch(err => {
				dispatch({
					type: subscriberActions.FETCH_SUBS_REJECTED,
					payload: err.response,
				});
			});
	};
};
