import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

function DNDContainer({ children, onDragEnd, id = "slides-dnd" }) {
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId={id} direction="vertical">
				{provided => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						{children}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export default DNDContainer;
