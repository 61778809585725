export const content = {
	en: {
		header: "Push notifications",
		client: "Create notification",
		ctitle: "Title",
		body: "Text",
		push_notification: "Push Notification",
		publish: "Publish",
		reset: "Reset",
	},
	de: {
		header: "Benachrichtigungen",
		client: "Benachrichtigung erstellen",
		ctitle: "Titel",
		body: "Körper",
		push_notification: "Push-Benachrichtigung",
		publish: "Veröffentlichen",
		reset: "Zurücksetzten",
	},
};
