// return the user data from the local storage
export const getUser = () => {
	const userStr = window.localStorage.getItem("user");
	if (userStr) return JSON.parse(userStr);
	else return null;
};

// return the token from the local storage
export const getToken = () => {
	return window.localStorage.getItem("token") || "";
};

// remove the token and user from the local storage
export const removeUserSession = () => {
	window.localStorage.removeItem("token");
	window.localStorage.removeItem("user");
};

// set the token and user from the local storage
export const setUserSession = (token, user) => {
	window.localStorage.setItem("token", token);
	window.localStorage.setItem("user", JSON.stringify(user));
};

export const getDescendantProp = (obj, desc) => {
	var arr = desc.split(".");
	while (arr.length && (obj = obj[arr.shift()]));
	return obj;
};
