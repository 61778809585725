import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { setLastUsedColor } from "../../../../../../../../../store/actions";

import PickerDisplay from "./PickerDisplay";
import ColorPicker from "../custom-picker";
import Sliders from "./Sliders";

import { onUpdateTextColor } from "../../../../../../utils/textboxActions";

function Picker({ state, canvas }) {
	const dispatch = useDispatch();

	const [txtCol, setTxtCol] = useState(state.activeObject ? state.activeObject.fill : "#ffffff");
	const [activeSlider, setActiveSlider] = useState(0);

	const handleChange = (color, x, y) => {
		onUpdateTextColor(canvas, state, color, x, y);
		setTxtCol(color);
	};

	const handleDispatch = (color, x, y) => {
		dispatch(setLastUsedColor({ value: color, x, y }));
		canvas.fire("event:cache", {
			eName: "change-color",
			from: "picker",
		});
	};

	return (
		<PickerDisplay>
			<ColorPicker
				activeObject={state.activeObject}
				handleChange={handleChange}
				handleDispatch={handleDispatch}
				activeSlider={activeSlider}
				setActiveSlider={setActiveSlider}
			/>
			<Sliders
				canvas={canvas}
				txtCol={txtCol}
				activeSlider={activeSlider}
				setActiveSlider={setActiveSlider}
			/>
		</PickerDisplay>
	);
}

export default Picker;
