import React from "react";
import { Box, Text, Divider, IconButton } from "@chakra-ui/react";
import { Button, Skeleton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import { content } from "../components/content";
import TabHeaderLight from "../../../shared/tabs/TabHeaderLight";
import { getOriginal } from "../../../../utils/imgUri";
import { useDispatch } from "react-redux";
import { deleteEvent } from "../../../../store/actions/event";
import { recoverEvent } from "../../../../store/actions/event";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { handleScroll } from "../../../../utils/scroll";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../../shared/table";
import toast from "react-hot-toast";
import Modal from "../../../shared/Modal";
import Charts from "../../../shared/charts/ChartsModalComp";

function Layout({
	header,
	lang,
	events,
	setFetchEventsType,
	loading,
	fetchEventsType,
	next_page_url,
	current_page,
	fetchEvents,
	fetched,
	chartsModal,
	setChartsModal,
	handleClose,
}) {
	const dispatch = useDispatch();
	const history = useHistory();

	const t = content[lang];
	const options = ["published", "future", "archive", "deleted"];

	return (
		<Box
			paddingX="20px"
			display="flex"
			flexDirection="column"
			flexGrow="1"
			overflowY="auto"
			position="absolute"
			bottom="0"
			right="0"
			width="100%"
			top={["3em", "0"]}
			pb={["5em", "1em"]}
			onScroll={e =>
				handleScroll(
					e,
					() => dispatch(fetchEvents(fetchEventsType, current_page + 1)),
					current_page,
					loading,
					next_page_url,
				)
			}
		>
			{chartsModal.active && (
				<Modal header="Statistics" handleClose={handleClose}>
					<Charts currentTab={"event"} itemId={chartsModal.itemId} />
				</Modal>
			)}
			<Box py="3" display="flex" justifyContent="space-between" gridGap="1em">
				<Box>
					<Box mb="1">
						<Text fontSize="24px">{header}</Text>
					</Box>
					<Box w="40px">
						<Divider borderBottom="4px" opacity="1" />
					</Box>
				</Box>
				<Link to="/events/new">
					<Box
						border="2px solid #000"
						p={["0.5em", "1em"]}
						cursor="pointer"
						transition="100ms all ease"
						_hover={{ transform: "scale(1.05)" }}
					>
						<Box
							p="0"
							backgroundColor="transparent"
							_hover={{
								backgroundColor: "#transparent",
							}}
							_first={{ _hover: { color: "green" } }}
							_focus={{ border: "none" }}
							color="#D7D8D7"
							display="flex"
							alignItems="center"
						>
							<FontAwesomeIcon icon="plus" color="green" />
							<Text color="#000" _last={{ _hover: { color: "#000" } }} ml="2">
								{t.add_event}
							</Text>
						</Box>
					</Box>
				</Link>
			</Box>
			<TabHeaderLight options={options} onChange={setFetchEventsType} />
			<Box display="flex" flexGrow="1" pt="30px" flexDirection="column">
				<Table>
					<Thead>
						<Tr>
							<Th>{t.logo}</Th>
							<Th>{t.title}</Th>
							<Th textAlign="center">{t.recurrence}</Th>
							<Th>{t.event_start_at}</Th>
							<Th>Views</Th>
							<Th textAlign="center">{t.edit}</Th>
							<Th>{t.copy}</Th>
							{fetchEventsType !== "deleted" ? <Th textAlign="center">{t.delete}</Th> : null}
						</Tr>
					</Thead>
					<Tbody>
						{fetched && events.data.length
							? events.data.map(item => (
									<Tr key={item.id}>
										<Td>
											<div
												style={{
													position: "relative",
													background: "rgba(0, 0, 0, 0.8)",
													borderRadius: `${100}%`,
													width: 70,
													height: 70,
												}}
											>
												<img
													style={{
														width: 70,
														height: 70,
														borderRadius: `${100}%`,
														border: "none",
														transform: "scale(0.97)",
														background: "#fff",
														cursor: "pointer",
													}}
													src={getOriginal(item.logo)}
													alt="Company Logo"
												/>
											</div>
										</Td>
										<Td>
											<Box display="flex">{item.title}</Box>
										</Td>
										<Td hide>
											<Box display="flex" justifyContent="center">
												<Text
													display="flex"
													fontSize="0.8em"
													bg="#AFCB2E"
													borderRadius="2%"
													py="0.3em"
													px="1em"
													color="#FFF"
													fontFamily="Segoe UI Bold"
												>
													{!item.price_from && !item.price_to ? "FREE" : "€"}
												</Text>
												<Text
													display="flex"
													fontSize="0.8em"
													bg={"#0E65AE"}
													borderRadius="2%"
													py="0.3em"
													px="1em"
													color={item.recurrence ? "#FFF" : "rgba(255,255,255,0.5)"}
													fontFamily="Segoe UI Bold"
												>
													{item.recurrence ? item.recurrence.toUpperCase() : "NO RECURRENCE"}
												</Text>
											</Box>
										</Td>

										<Td>{item.post_start_at}</Td>
										<Td>
											<Button
												onClick={() => {
													setChartsModal({
														active: true,
														itemId: item.id,
													});
													// open charts modal
												}}
												p="0"
												backgroundColor="transparent"
												_hover={{
													backgroundColor: "#transparent",
													color: "#000",
												}}
												_focus={{ border: "none" }}
												color="#D7D8D7"
											>
												<FontAwesomeIcon size="2x" icon="eye" />
												<Text ml={"3px"}>{item.views}</Text>
											</Button>
										</Td>
										{fetchEventsType !== "deleted" ? (
											<Td textAlign="center">
												<Link to={`/events/edit/${item.id}`}>
													<Button
														p="0"
														backgroundColor="transparent"
														_hover={{
															backgroundColor: "#transparent",
															color: "#E7792A",
														}}
														_focus={{ border: "none" }}
														color="#D7D8D7"
													>
														<FontAwesomeIcon size="2x" icon="pencil-alt" />
													</Button>
												</Link>
											</Td>
										) : (
											<Td textAlign="center">
												<Button
													onClick={() => dispatch(recoverEvent(item.id, history))}
													p="0"
													backgroundColor="transparent"
													_hover={{
														backgroundColor: "#transparent",
														color: "#E7792A",
													}}
													_focus={{ border: "none" }}
													color="#D7D8D7"
												>
													<FontAwesomeIcon size="2x" icon="arrow-left" />
												</Button>
											</Td>
										)}
										<Td>
											<IconButton
												backgroundColor="transparent"
												_hover={{
													backgroundColor: "#transparent",
													color: "#E7792A",
												}}
												_focus={{ border: "none" }}
												color="#D7D8D7"
												onClick={() => {
													navigator.clipboard.writeText(item.share_link).then(() => {
														toast.success("copied");
													});
												}}
											>
												<FontAwesomeIcon size="2x" icon="link" />
											</IconButton>
										</Td>
										{fetchEventsType !== "deleted" ? (
											<Td textAlign="center">
												<PrimaryButton
													onClick={() => dispatch(deleteEvent(item.id))}
													prompt={true}
													labelType="icon"
													label={<FontAwesomeIcon size="2x" icon="times" />}
													type="circle"
													hoverColor="#F58121"
												></PrimaryButton>
											</Td>
										) : null}
									</Tr>
							  ))
							: !loading && (
									<Tr p="5">
										<Td>{t.no_data}</Td>
									</Tr>
							  )}
						{loading && (
							<Tr
								_hover={{
									backgroundColor: "#F2F1F0",
								}}
							>
								{new Array(fetchEventsType === "deleted" ? 5 : 6)
									.fill(undefined)
									.map((item, id) => (
										<Td key={id}>
											<Skeleton height="20px" />
										</Td>
									))}
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</Box>
	);
}

export default Layout;
