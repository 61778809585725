export const content = {
	en: {
		title: "Email verification",
		content: "Email has been sent. Please verify first",
		notRecived: "Resend email",
		change: "Change email",
	},
	de: {
		title: "Email verification",
		content: "Email has been sent. Please verify first",
		notRecived: "Resend email",
		change: "Change email",
	},
};
