import React, { useState } from "react";
import { Box, Image } from "@chakra-ui/react";

function ActionBtn({ activeObject = true, state0, state1, action }) {
	const [onMouseDown, setOnMouseDown] = useState(false);

	if (!activeObject) {
		return (
			<Box display="flex" flexDir="column" alignItems="center" cursor="pointer">
				<Image opacity="0.5" src={state0} boxSize="35px" cursor="pointer" mr="10px" />
			</Box>
		);
	}
	return (
		<Box
			display="flex"
			flexDir="column"
			alignItems="center"
			onMouseDown={() => setOnMouseDown(true)}
			onMouseUp={() => {
				setOnMouseDown(false);
				action();
			}}
			cursor="pointer"
		>
			<Image src={onMouseDown ? state1 : state0} boxSize="35px" cursor="pointer" mr="10px" />
		</Box>
	);
}

export default ActionBtn;
