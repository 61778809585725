// CUSTOM

export const customActions = {
	SET_APP_STATE: "SET_APP_STATE",
};

// language

export const languageActions = {
	SET_LANGUAGE: "SET_LANGUAGE",
};

// sidebar

export const sidebarAction = {
	TOOGLE_SIDEBAR: "TOOGLE_SIDEBAR",
};

//requests actions-types

export const tokenActions = {
	REQUEST_TOKEN: "REQUEST_TOKEN",
	REQUEST_FULFILLED: "REQUEST_FULFILLED",
	REQUEST_REJECTED: "REQUEST_REJECTED",
	RESET_RECOVER_STATE: "RESET_RECOVER_STATE",
};

// USER

export const userActions = {
	USER_FETCHING: "USER_FETCHING",
	USER_FULFILLED: "USER_FULFILLED",
	USER_REJECTED: "USER_REJECTED",
	LOGOUT_USER: "LOGOUT_USER",
	SET_CURRENT_USER: "SET_CURRENT_USER",

	LOGS_FETCHING: "LOGS_FETCHING",
	LOGS_FULFILLED: "LOGS_FULFILLED",
	LOGS_REJECTED: "LOGS_REJECTED",

	//Update user

	USER_UPDATE_FETCHING: "USER_UPDATE_FETCHING",
	USER_UPDATE_FULFILLED: "USER_UPDATE_FULFILLED",
	USER_UPDATE_REJECTED: "USER_UPDATE_REJECTED",
	RESET_UPDATE_STATE: "RESET_UPDATE_STATE",
	//register

	REGISTER_USER: "REGISTER_USER",
	REGISTER_USER_FULFILLED: "REGISTER_USER_FULFILLED",
	REGISTER_USER_REJECTED: "REGISTER_USER_REJECTED",
	RESET_REGISTER_STATE: "RESET_REGISTER_STATE",

	//email verification

	VERIFY_EMAIL: "VERIFY_EMAIL",
	VERIFICATION_FULLFILLED: "VERIFICATION_FULLFILLED",
	VERIFICATION_REJECTED: "VERIFICATION_REJECTED",

	RESEND_EMAIL: "RESEND_EMAIL",
	RESEND_FULLFILLED: "RESEND_FULLFILLED",
	RESEND_REJECTED: "RESEND_REJECTED",

	// password reset

	RESET_PASSWORD: "RESET_PASSWORD",
	RESET_FULLFILLED: "RESET_FULLFILLED",
	RESET_REJECTED: "RESET_REJECTED",

	// get facebook token
	GET_FACEBOOK_TOKEN_FETCHING: "GET_FACEBOOK_TOKEN_FETCHING",
	GET_FACEBOOK_TOKEN_FULFILLED: "GET_FACEBOOK_TOKEN_FULFILLED",
	GET_FACEBOOK_TOKEN_REJECTED: "GET_FACEBOOK_TOKEN_REJECTED",

	//send facebook token
	SEND_FACEBOOK_TOKEN_FETCHING: "SEND_FACEBOOK_TOKEN_FETCHING",
	SEND_FACEBOOK_TOKEN_FULFILLED: "SEND_FACEBOOK_TOKEN_FULFILLED",
	SEND_FACEBOOK_TOKEN_REJECTED: "SEND_FACEBOOK_TOKEN_REJECTED",

	// fetch facebook details
	FACEBOOK_PAGES_FETCHING: "FACEBOOK_PAGES_FETCHING",
	FACEBOOK_PAGES_FULFILLED: "FACEBOOK_PAGES_FULFILLED",
	FACEBOOK_PAGES_REJECTED: "FACEBOOK_PAGES_REJECTED",

	// fetch facebook details
	FACEBOOK_USER_DETAILS_FETCHING: "FACEBOOK_USER_DETAILS_FETCHING",
	FACEBOOK_USER_DETAILS_FULFILLED: "FACEBOOK_USER_DETAILS_FULFILLED",
	FACEBOOK_USER_DETAILS_REJECTED: "FACEBOOK_USER_DETAILS_REJECTED",
};

// MENU

export const menuActions = {
	FETCH_MENU: "FETCH_MENU",
	FETCH_MENU_FULFILLED: "FETCH_MENU_FULFILLED",
	FETCH_MENU_REJECTED: "FETCH_MENU_REJECTED",
};

// COMPANY

export const companyActions = {
	FETCH_COMPANIES: "FETCH_COMPANIES",
	FETCH_COMPANIES_FULFILLED: "FETCH_COMPANIES_FULFILLED",
	FETCH_COMPANIES_REJECTED: "FETCH_COMPANIES_REJECTED",
	SET_COMPANY: "SET_COMPANY",

	FETCH_COMPANY: "FETCH_COMPANY",
	FETCH_COMPANY_FULFILLED: "FETCH_COMPANY_FULFILLED",
	FETCH_COMPANY_REJECTED: "FETCH_COMPANY_REJECTED",
	SET_CURRENT_COMPANY: "SET_CURRENT_COMPANY",

	//Update company

	COMPANY_UPDATE_FETCHING: "COMPANY_UPDATE_FETCHING",
	COMPANY_UPDATE_FULFILLED: "COMPANY_UPDATE_FULFILLED",
	COMPANY_UPDATE_REJECTED: "COMPANY_UPDATE_REJECTED",
	RESET_UPDATE_STATE: "RESET_UPDATE_STATE",

	CREATE_COMPANY: "CREATE_COMPANY",
	CREATE_COMPANY_FULFILLED: "CREATE_COMPANY_FULFILLED",
	CREATE_COMPANY_REJECTED: "CREATE_COMPANY_REJECTED",

	FETCH_COMPANY_CATEGORIES: "FETCH_COMPANY_CATEGORIES",
	FETCH_COMPANY_CATEGORIES_FULFILLED: "FETCH_COMPANY_CATEGORIES_FULFILLED",
	FETCH_COMPANY_CATEGORIES_REJECTED: "FETCH_COMPANY_CATEGORIES_REJECTED",

	SELECT_COMPANY: "SELECT_COMPANY",
	REMOVE_SELECTED_COMPANY: "REMOVE_SELECTED_COMPANY",
};

export const subActions = {
	FETCH_PLANS: "FETCH_PLANS",
	FETCH_PLANS_FULFILLED: "FETCH_PLANS_FULFILLED",
	FETCH_PLANS_REJECTED: "FETCH_PLANS_REJECTED",

	FETCH_PLAN: "FETCH_PLAN",
	FETCH_PLAN_FULFILLED: "FETCH_PLAN_FULFILLED",
	FETCH_PLAN_REJECTED: "FETCH_PLAN_REJECTED",

	FETCH_ACTIVE_PLAN: "FETCH_ACTIVE_PLAN",
	FETCH_ACTIVE_PLAN_FULFILLED: "FETCH_ACTIVE_PLAN_FULFILLED",
	FETCH_ACTIVE_PLAN_REJECTED: "FETCH_ACTIVE_PLAN_REJECTED",

	INIT_SUBSCRIPTION: "INIT_SUBSCRIPTION",
	INIT_SUBSCRIPTION_FULFILLED: "INIT_SUBSCRIPTION_FULFILLED",
	INIT_SUBSCRIPTION_REJECTED: "INIT_SUBSCRIPTION_REJECTED",

	PAYPAL_SUCCESS: "PAYPAL_SUCCESS",
	PAYPAL_SUCCESS_FULFILLED: "PAYPAL_SUCCESS_FULFILLED",
	PAYPAL_SUCCESS_REJECTED: "PAYPAL_SUCCESS_REJECTED",

	PAYPAL_CANCEL: "PAYPAL_CANCEL",
	PAYPAL_CANCEL_FULFILLED: "PAYPAL_CANCEL_FULFILLED",
	PAYPAL_CANCEL_REJECTED: "PAYPAL_CANCEL_REJECTED",
};

export const locationActions = {
	CREATE_LOCATION: "CREATE_LOCATION",
	CREATE_LOCATION_FULFILLED: "CREATE_LOCATION_FULFILLED",
	CREATE_LOCATION_REJECTED: "CREATE_LOCATION_REJECTED",

	DELETE_LOCATION: "DELETE_LOCATION",
	DELETE_LOCATION_FULFILLED: "DELETE_LOCATION_FULFILLED",
	DELETE_LOCATION_REJECTED: "DELETE_LOCATION_REJECTED",

	RECOVER_LOCATION: "RECOVER_LOCATION",
	RECOVER_LOCATION_FULFILLED: "RECOVER_LOCATION_FULFILLED",
	RECOVER_LOCATION_REJECTED: "RECOVER_LOCATION_REJECTED",

	CREATE_HOURS: "CREATE_HOURS",
	CREATE_HOURS_FULFILLED: "CREATE_HOURS_FULFILLED",
	CREATE_HOURS_REJECTED: "CREATE_HOURS_REJECTED",

	FETCH_HOURS: "FETCH_HOURS",
	FETCH_HOURS_FULFILLED: "FETCH_HOURS_FULFILLED",
	FETCH_HOURS_REJECTED: "FETCH_HOURS_REJECTED",

	DELETE_HOURS: "DELETE_HOURS",
	DELETE_HOURS_FULFILLED: "DELETE_HOURS_FULFILLED",
	DELETE_HOURS_REJECTED: "DELETE_HOURS_REJECTED",

	FETCH_LOCATIONS: "FETCH_LOCATIONS",
	FETCH_LOCATIONS_FULFILLED: "FETCH_LOCATIONS_FULFILLED",
	FETCH_LOCATIONS_REJECTED: "FETCH_LOCATIONS_REJECTED",

	SET_CURRENT_PAGE: "SET_CURRENT_PAGE",

	FETCH_LOCATION: "FETCH_LOCATION",
	FETCH_LOCATION_FULFILLED: "FETCH_LOCATION_FULFILLED",
	FETCH_LOCATION_REJECTED: "FETCH_LOCATION_REJECTED",

	UPDATE_LOCATION: "UPDATE_LOCATION",
	UPDATE_LOCATION_FULFILLED: "UPDATE_LOCATION_FULFILLED",
	UPDATE_LOCATION_REJECTED: "UPDATE_LOCATION_REJECTED",

	//email verification

	VERIFY_LOCATION_EMAIL: "VERIFY_LOCATION_EMAIL",
	VERIFY_LOCATION_EMAIL_FULLFILLED: "VERIFY_LOCATION_EMAIL_FULLFILLED",
	VERIFY_LOCATION_EMAIL_REJECTED: "VERIFY_LOCATION_EMAIL_REJECTED",
};

export const eventActions = {
	CREATE_EVENT: "CREATE_EVENT",
	CREATE_EVENT_FULFILLED: "CREATE_EVENT_FULFILLED",
	CREATE_EVENT_REJECTED: "CREATE_EVENT_REJECTED",

	FETCH_EVENTS: "FETCH_EVENTS",
	FETCH_EVENTS_FULFILLED: "FETCH_EVENTS_FULFILLED",
	FETCH_EVENTS_REJECTED: "FETCH_EVENTS_REJECTED",

	FETCH_EVENT_CATEGORIES: "FETCH_EVENT_CATEGORIES",
	FETCH_EVENT_CATEGORIES_FULFILLED: "FETCH_EVENT_CATEGORIES_FULFILLED",
	FETCH_EVENT_CATEGORIES_REJECTED: "FETCH_EVENT_CATEGORIES_REJECTED",

	FETCH_EVENT_SUB_CATEGORIES: "FETCH_EVENT_SUB_CATEGORIES",
	FETCH_EVENT_SUB_CATEGORIES_FULFILLED: "FETCH_EVENT_SUB_CATEGORIES_FULFILLED",
	FETCH_EVENT_SUB_CATEGORIES_REJECTED: "FETCH_EVENT_SUB_CATEGORIES_REJECTED",

	FETCH_EVENT_FORMATS: "FETCH_EVENT_FORMATS",
	FETCH_EVENT_FORMATS_FULFILLED: "FETCH_EVENT_FORMATS_FULFILLED",
	FETCH_EVENT_FORMATS_REJECTED: "FETCH_EVENT_FORMATS_REJECTED",

	FETCH_EVENT_FORMAT: "FETCH_EVENT_FORMAT",
	FETCH_EVENT_FORMAT_FULFILLED: "FETCH_EVENT_FORMAT_FULFILLED",
	FETCH_EVENT_FORMAT_REJECTED: "FETCH_EVENT_FORMAT_REJECTED",

	FETCH_EVENT: "FETCH_EVENT",
	FETCH_EVENT_FULFILLED: "FETCH_EVENT_FULFILLED",
	FETCH_EVENT_REJECTED: "FETCH_EVENT_REJECTED",

	RECOVER_EVENT: "RECOVER_EVENT",
	RECOVER_EVENT_FULFILLED: "RECOVER_EVENT_FULFILLED",
	RECOVER_EVENT_REJECTED: "RECOVER_EVENT_REJECTED",

	UPDATE_EVENT: "UPDATE_EVENT",
	UPDATE_EVENT_FULFILLED: "UPDATE_EVENT_FULFILLED",
	UPDATE_EVENT_REJECTED: "UPDATE_EVENT_REJECTED",

	DELETE_EVENT: "DELETE_EVENT",
	DELETE_EVENT_FULFILLED: "DELETE_EVENT_FULFILLED",
	DELETE_EVENT_REJECTED: "DELETE_EVENT_REJECTED",
};

export const templateActions = {
	FETCH_TEMPLATES: "FETCH_TEMPLATES",
	FETCH_TEMPLATES_FULFILLED: "FETCH_TEMPLATES_FULFILLED",
	FETCH_TEMPLATES_REJECTED: "FETCH_TEMPLATES_REJECTED",

	LOAD_TEMPLATE: "LOAD_TEMPLATE",
	LOAD_TEMPLATE_FULFILLED: "LOAD_TEMPLATE_FULFILLED",
	LOAD_TEMPLATE_REJECTED: "LOAD_TEMPLATE_REJECTED",
	RESET_LOADED_TEMPLATE: "RESET_LOADED_TEMPLATE",

	ADD_TEMPLATE: "ADD_TEMPLATE",
	DELETE_TEMPLATE: "DELETE_TEMPLATE",

	REMOVE_TEMPLATE: "REMOVE_TEMPLATE",
	REMOVE_TEMPLATE_FULFILLED: "REMOVE_TEMPLATE_FULFILLED",
	REMOVE_TEMPLATE_REJECTED: "REMOVE_TEMPLATE_REJECTED",
};
export const newsActions = {
	CREATE_NEWS: "CREATE_NEWS",
	CREATE_NEWS_FULFILLED: "CREATE_NEWS_FULFILLED",
	CREATE_NEWS_REJECTED: "CREATE_NEWS_REJECTED",

	FETCH_NEWSES: "FETCH_NEWSES",
	FETCH_NEWSES_FULFILLED: "FETCH_NEWSES_FULFILLED",
	FETCH_NEWSES_REJECTED: "FETCH_NEWSES_REJECTED",

	FETCH_NEWS: "FETCH_NEWS",
	FETCH_NEWS_FULFILLED: "FETCH_NEWS_FULFILLED",
	FETCH_NEWS_REJECTED: "FETCH_NEWS_REJECTED",

	RECOVER_NEWS: "RECOVER_NEWS",
	RECOVER_NEWS_FULFILLED: "RECOVER_NEWS_FULFILLED",
	RECOVER_NEWS_REJECTED: "RECOVER_NEWS_REJECTED",

	UPDATE_NEWS: "UPDATE_NEWS",
	UPDATE_NEWS_FULFILLED: "UPDATE_NEWS_FULFILLED",
	UPDATE_NEWS_REJECTED: "UPDATE_NEWS_REJECTED",

	DELETE_NEWS: "DELETE_NEWS",
	DELETE_NEWS_FULFILLED: "DELETE_NEWS_FULFILLED",
	DELETE_NEWS_REJECTED: "DELETE_NEWS_REJECTED",
};

export const editorActions = {
	SAVE_LOGO_STATE: "SAVE_LOGO_STATE",
	SAVE_GALLERY_STATE: "SAVE_GALLERY_STATE",
	RESET_STATE: "RESET_STATE",
	UPDATE_COLOR: "UPDATE_COLOR",
	SAVE_INITIAL_CANVAS: "SAVE_INITIAL_CANVAS",

	ADD_TO_STATELINE: "ADD_TO_STATELINE",
	REMOVE_FROM_STATELINE: "REMOVE_FROM_STATELINE",
	UNDO: "UNDO",
	REDO: "REDO",

	SET_LAST_USED_COLOR: "SET_LAST_USED_COLOR",
};

export const galleryActions = {
	FETCH_GALLERY: "FETCH_GALLERY",
	FETCH_GALLERY_FULFILLED: "FETCH_GALLERY_FULFILLED",
	FETCH_GALLERY_REJECTED: "FETCH_GALLERY_REJECTED",

	CREATE_GALLERY: "CREATE_GALLERY",
	CREATE_GALLERY_FULFILLED: "CREATE_GALLERY_FULFILLED",
	CREATE_GALLERY_REJECTED: "CREATE_GALLERY_REJECTED",
};
export const couponActions = {
	CREATE_COUPON: "CREATE_COUPON",
	CREATE_COUPON_FULFILLED: "CREATE_COUPON_FULFILLED",
	CREATE_COUPON_REJECTED: "CREATE_COUPON_REJECTED",

	FETCH_COUPONS: "FETCH_COUPONS",
	FETCH_COUPONS_FULFILLED: "FETCH_COUPONS_FULFILLED",
	FETCH_COUPONS_REJECTED: "FETCH_COUPONS_REJECTED",

	FETCH_COUPON: "FETCH_COUPON",
	FETCH_COUPON_FULFILLED: "FETCH_COUPON_FULFILLED",
	FETCH_COUPON_REJECTED: "FETCH_COUPON_REJECTED",

	UPDATE_COUPON: "UPDATE_COUPON",
	UPDATE_COUPON_FULFILLED: "UPDATE_COUPON_FULFILLED",
	UPDATE_COUPON_REJECTED: "UPDATE_COUPON_REJECTED",

	DELETE_COUPON: "DELETE_COUPON",
	DELETE_COUPON_FULFILLED: "DELETE_COUPON_FULFILLED",
	DELETE_COUPON_REJECTED: "DELETE_COUPON_REJECTED",
};

export const storyActions = {
	CREATE_STORY: "CREATE_STORY",
	CREATE_STORY_FULFILLED: "CREATE_STORY_FULFILLED",
	CREATE_STORY_REJECTED: "CREATE_STORY_REJECTED",

	FETCH_STORIES: "FETCH_STORIES",
	FETCH_STORIES_FULFILLED: "FETCH_STORIES_FULFILLED",
	FETCH_STORIES_REJECTED: "FETCH_STORIES_REJECTED",
	RESET_STORIES_STATE: "RESET_STORIES_STATE",

	FETCH_STORY: "FETCH_STORY",
	FETCH_STORY_FULFILLED: "FETCH_STORY_FULFILLED",
	FETCH_STORY_REJECTED: "FETCH_STORY_REJECTED",
	RESET_STORY_STATE: "RESET_STORY_STATE",

	UPDATE_STORY: "UPDATE_STORY",
	UPDATE_STORY_FULFILLED: "UPDATE_STORY_FULFILLED",
	UPDATE_STORY_REJECTED: "UPDATE_STORY_REJECTED",
};

export const usersActions = {
	FETCH_USERS: "FETCH_USERS",
	FETCH_USERS_FULFILLED: "FETCH_USERS_FULFILLED",
	FETCH_USERS_REJECTED: "FETCH_USERS_REJECTED",

	FETCH_USER: "FETCH_USER",
	FETCH_USER_FULFILLED: "FETCH_USER_FULFILLED",
	FETCH_USER_REJECTED: "FETCH_USER_REJECTED",
};

export const notificationActions = {
	FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
	FETCH_NOTIFICATIONS_FULFILLED: "FETCH_NOTIFICATIONS_FULFILLED",
	FETCH_NOTIFICATIONS_REJECTED: "FETCH_NOTIFICATIONS_REJECTED",
	RESET_NOTIFICATIONS_STATE: "RESET_NOTIFICATIONS_STATE",

	FETCH_NOTIFICATIONS_COUNT: "FETCH_NOTIFICATIONS_COUNT",
	FETCH_NOTIFICATIONS_COUNT_FULFILLED: "FETCH_NOTIFICATIONS_COUNT_FULFILLED",
	FETCH_NOTIFICATIONS_COUNT_REJECTED: "FETCH_NOTIFICATIONS_COUNT_REJECTED",

	FETCH_NOTIFICATION: "FETCH_NOTIFICATION",
	FETCH_NOTIFICATION_FULFILLED: "FETCH_NOTIFICATION_FULFILLED",
	FETCH_NOTIFICATION_REJECTED: "FETCH_NOTIFICATION_REJECTED",
	RESET_NOTIFICATION_STATE: "RESET_NOTIFICATION_STATE",

	UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
	UPDATE_NOTIFICATION_FULFILLED: "UPDATE_NOTIFICATION_FULFILLED",
	UPDATE_NOTIFICATION_REJECTED: "UPDATE_NOTIFICATION_REJECTED",

	UPDATE_MULTIPLE_NOTIFICATIONS: "UPDATE_MULTIPLE_NOTIFICATIONS",
	UPDATE_MULTIPLE_NOTIFICATIONS_FULFILLED: "UPDATE_MULTIPLE_NOTIFICATIONS_FULFILLED",
	UPDATE_MULTIPLE_NOTIFICATIONS_REJECTED: "UPDATE_MULTIPLE_NOTIFICATIONS_REJECTED",
};

export const keywordActions = {
	ADD_KEYWORD: "ADD_KEYWORD",
	REMOVE_KEYWORD: "REMOVE_KEYWORD",

	CREATE_KEYWORD: "CREATE_KEYWORD",
	CREATE_KEYWORD_FULFILLED: "CREATE_KEYWORD_FULFILLED",
	CREATE_KEYWORD_REJECTED: "CREATE_KEYWORD_REJECTED",

	DELETE_KEYWORD: "DELETE_KEYWORD",
	DELETE_KEYWORD_FULFILLED: "DELETE_KEYWORD_FULFILLED",
	DELETE_KEYWORD_REJECTED: "DELETE_KEYWORD_REJECTED",

	FETCH_KEYWORDS: "FETCH_KEYWORDS",
	FETCH_KEYWORDS_FULFILLED: "FETCH_KEYWORDS_FULFILLED",
	FETCH_KEYWORDS_REJECTED: "FETCH_KEYWORDS_REJECTED",

	FETCH_MY_KEYWORDS: "FETCH_MY_KEYWORDS",
	FETCH_MY_KEYWORDS_FULFILLED: "FETCH_MY_KEYWORDS_FULFILLED",
	FETCH_MY_KEYWORDS_REJECTED: "FETCH_MY_KEYWORDS_REJECTED",

	ATTACH_KEYWORDS: "ATTACH_KEYWORDS",
	ATTACH_KEYWORDS_FULFILLED: "ATTACH_KEYWORDS_FULFILLED",
	ATTACH_KEYWORDS_REJECTED: "ATTACH_KEYWORDS_REJECTED",

	FETCH_KEYWORDS_BY_ID: "FETCH_KEYWORDS_BY_ID",
	FETCH_KEYWORDS_BY_ID_FULFILLED: "FETCH_KEYWORDS_BY_ID_FULFILLED",
	FETCH_KEYWORDS_BY_ID_REJECTED: "FETCH_KEYWORDS_BY_ID_REJECTED",
};

export const invoiceActions = {
	RESET_INVOICES: "RESET_INVOICES",

	CREATE_INVOICE_LOCATION: "CREATE_INVOICE_LOCATION",
	CREATE_INVOICE_LOCATION_FULFILLED: "CREATE_INVOICE_LOCATION_FULFILLED",
	CREATE_INVOICE_LOCATION_REJECTED: "CREATE_INVOICE_LOCATION_REJECTED",

	FETCH_INVOICE_LOCATIONS: "FETCH_INVOICE_LOCATIONS",
	FETCH_INVOICE_LOCATIONS_FULFILLED: "FETCH_INVOICE_LOCATIONS_FULFILLED",
	FETCH_INVOICE_LOCATIONS_REJECTED: "FETCH_INVOICE_LOCATIONS_REJECTED",

	FETCH_INVOICE_LOCATION: "FETCH_INVOICE_LOCATION",
	FETCH_INVOICE_LOCATION_FULFILLED: "FETCH_INVOICE_LOCATION_FULFILLED",
	FETCH_INVOICE_LOCATION_REJECTED: "FETCH_INVOICE_LOCATION_REJECTED",

	UPDATE_INVOICE_LOCATION: "UPDATE_INVOICE_LOCATION",
	UPDATE_INVOICE_LOCATION_FULFILLED: "UPDATE_INVOICE_LOCATION_FULFILLED",
	UPDATE_INVOICE_LOCATION_REJECTED: "UPDATE_INVOICE_LOCATION_REJECTED",

	FETCH_INVOICES: "FETCH_INVOICES",
	FETCH_INVOICES_FULFILLED: "FETCH_INVOICES_FULFILLED",
	FETCH_INVOICES_REJECTED: "FETCH_INVOICES_REJECTED",
};

export const countriesActions = {
	FETCH_COUNTRIES: "FETCH_COUNTRIES",
	FETCH_COUNTRIES_FULFILLED: "FETCH_COUNTRIES_FULFILLED",
	FETCH_COUNTRIES_REJECTED: "FETCH_COUNTRIES_REJECTED",

	FETCH_STATES: "FETCH_STATES",
	FETCH_STATES_FULFILLED: "FETCH_STATES_FULFILLED",
	FETCH_STATES_REJECTED: "FETCH_STATES_REJECTED",

	FETCH_CITIES: "FETCH_CITIES",
	FETCH_CITIES_FULFILLED: "FETCH_CITIES_FULFILLED",
	FETCH_CITIES_REJECTED: "FETCH_CITIES_REJECTED",

	FETCH_TIMEZONES: "FETCH_TIMEZONES",
	FETCH_TIMEZONES_FULFILLED: "FETCH_TIMEZONES_FULFILLED",
	FETCH_TIMEZONES_REJECTED: "FETCH_TIMEZONES_REJECTED",
};

export const pushNotificationActions = {
	FETCH_PUSH_NOTIFICATIONS: "FETCH_PUSH_NOTIFICATIONS",
	FETCH_PUSH_NOTIFICATIONS_FULFILLED: "FETCH_PUSH_NOTIFICATIONS_FULFILLED",
	FETCH_PUSH_NOTIFICATIONS_REJECTED: "FETCH_PUSH_NOTIFICATIONS_REJECTED",

	FETCH_PUSH_NOTIFICATION: "FETCH_PUSH_NOTIFICATION",
	FETCH_PUSH_NOTIFICATION_FULFILLED: "FETCH_PUSH_NOTIFICATION_FULFILLED",
	FETCH_PUSH_NOTIFICATION_REJECTED: "FETCH_PUSH_NOTIFICATION_REJECTED",

	CREATE_PUSH_NOTIFICATION: "CREATE_PUSH_NOTIFICATION",
	CREATE_PUSH_NOTIFICATION_FULFILLED: "CREATE_PUSH_NOTIFICATION_FULFILLED",
	CREATE_PUSH_NOTIFICATION_REJECTED: "CREATE_PUSH_NOTIFICATION_REJECTED",

	FETCH_NOTIFIABLE_USERS: "FETCH_NOTIFIABLE_USERS",
	FETCH_NOTIFIABLE_USERS_FULFILLED: "FETCH_NOTIFIABLE_USERS_FULFILLED",
	FETCH_NOTIFIABLE_USERS_REJECTED: "FETCH_NOTIFIABLE_USERS_REJECTED",
};

export const chatsActions = {
	ADD_MESSAGE: "ADD_MESSAGE",
	ADD_MESSAGE_TOP: "ADD_MESSAGE_TOP",
	ADD_MESSAGE_TOP_START: "ADD_MESSAGE_TOP_START",

	UPDATE_MESSAGE: "UPDATE_MESSAGE",

	FETCH_NEXT: "FETCH_NEXT",
	FETCH_NEXT_FULFILLED: "FETCH_NEXT_FULFILLED",
	FETCH_NEXT_REJECTED: "FETCH_NEXT_REJECTED",

	REVEAL_USERS: "REVEAL_USERS",
	REVEAL_USERS_FULFILLED: "REVEAL_USERS_FULFILLED",
	REVEAL_USERS_REJECTED: "REVEAL_USERS_REJECTED",

	STORE_TOKEN: "STORE_TOKEN",
	STORE_TOKEN_FULFILLED: "STORE_TOKEN_FULFILLED",
	STORE_TOKEN_REJECTED: "STORE_TOKEN_REJECTED",

	FETCH_CHATABLE_USERS: "FETCH_CHATABLE_USERS",
	FETCH_CHATABLE_USERS_FULFILLED: "FETCH_CHATABLE_USERS_FULFILLED",
	FETCH_CHATABLE_USERS_REJECTED: "FETCH_CHATABLE_USERS_REJECTED",
	FETCH_CHATABLE_USERS_NEXT: "FETCH_CHATABLE_USERS_NEXT",
	FETCH_CHATABLE_USERS_NEXT_FULFILLED: "FETCH_CHATABLE_USERS_NEXT_FULFILLED",
	FETCH_CHATABLE_USERS_NEXT_REJECTED: "FETCH_CHATABLE_USERS_NEXT_REJECTED",

	CREATE_CHAT: "CREATE_CHAT",
	CREATE_CHAT_FULFILLED: "CREATE_CHAT_FULFILLED",
	CREATE_CHAT_REJECTED: "CREATE_CHAT_REJECTED",

	FETCH_CHATS: "FETCH_CHATS",
	FETCH_CHATS_FULFILLED: "FETCH_CHATS_FULFILLED",
	FETCH_CHATS_REJECTED: "FETCH_CHATS_REJECTED",
	FETCH_CHATS_NEXT: "FETCH_CHATS_NEXT",
	FETCH_CHATS_NEXT_FULFILLED: "FETCH_CHATS_NEXT_FULFILLED",
	FETCH_CHATS_NEXT_REJECTED: "FETCH_CHATS_NEXT_REJECTED",

	FETCH_MESSAGES: "FETCH_MESSAGES",
	FETCH_MESSAGES_FULFILLED: "FETCH_MESSAGES_FULFILLED",
	FETCH_MESSAGES_REJECTED: "FETCH_MESSAGES_REJECTED",

	CREATE_MESSAGE: "CREATE_MESSAGE",
	CREATE_MESSAGE_FULFILLED: "CREATE_MESSAGE_FULFILLED",
	CREATE_MESSAGE_REJECTED: "CREATE_MESSAGE_REJECTED",
};

export const stripeActions = {
	FETCH_STRIPE_SUBSCRIPTIONS: "FETCH_STRIPE_SUBSCRIPTIONS",
	FETCH_STRIPE_SUBSCRIPTIONS_FULFILLED: "FETCH_STRIPE_SUBSCRIPTIONS_FULFILLED",
	FETCH_STRIPE_SUBSCRIPTIONS_REJECTED: "FETCH_STRIPE_SUBSCRIPTIONS_REJECTED",

	FETCH_STRIPE_SUBSCRIPTION: "FETCH_STRIPE_SUBSCRIPTION",
	FETCH_STRIPE_SUBSCRIPTION_FULFILLED: "FETCH_STRIPE_SUBSCRIPTION_FULFILLED",
	FETCH_STRIPE_SUBSCRIPTION_REJECTED: "FETCH_STRIPE_SUBSCRIPTION_REJECTED",

	SWAP_STRIPE_SUBSCRIPTION: "SWAP_STRIPE_SUBSCRIPTION",
	SWAP_STRIPE_SUBSCRIPTION_FULFILLED: "SWAP_STRIPE_SUBSCRIPTION_FULFILLED",
	SWAP_STRIPE_SUBSCRIPTION_REJECTED: "SWAP_STRIPE_SUBSCRIPTION_REJECTED",

	CANCEL_STRIPE_SUBSCRIPTION: "CANCEL_STRIPE_SUBSCRIPTION",
	CANCEL_STRIPE_SUBSCRIPTION_FULFILLED: "CANCEL_STRIPE_SUBSCRIPTION_FULFILLED",
	CANCEL_STRIPE_SUBSCRIPTION_REJECTED: "CANCEL_STRIPE_SUBSCRIPTION_REJECTED",

	RESUME_STRIPE_SUBSCRIPTION: "RESUME_STRIPE_SUBSCRIPTION",
	RESUME_STRIPE_SUBSCRIPTION_FULFILLED: "RESUME_STRIPE_SUBSCRIPTION_FULFILLED",
	RESUME_STRIPE_SUBSCRIPTION_REJECTED: "RESUME_STRIPE_SUBSCRIPTION_REJECTED",
};

export const paypalActions = {
	FETCH_PAYPAL_SUBSCRIPTIONS: "FETCH_PAYPAL_SUBSCRIPTIONS",
	FETCH_PAYPAL_SUBSCRIPTIONS_FULFILLED: "FETCH_PAYPAL_SUBSCRIPTIONS_FULFILLED",
	FETCH_PAYPAL_SUBSCRIPTIONS_REJECTED: "FETCH_PAYPAL_SUBSCRIPTIONS_REJECTED",

	FETCH_PAYPAL_SUBSCRIPTION: "FETCH_PAYPAL_SUBSCRIPTION",
	FETCH_PAYPAL_SUBSCRIPTION_FULFILLED: "FETCH_PAYPAL_SUBSCRIPTION_FULFILLED",
	FETCH_PAYPAL_SUBSCRIPTION_REJECTED: "FETCH_PAYPAL_SUBSCRIPTION_REJECTED",

	SWAP_PAYPAL_SUBSCRIPTION: "SWAP_PAYPAL_SUBSCRIPTION",
	SWAP_PAYPAL_SUBSCRIPTION_FULFILLED: "SWAP_PAYPAL_SUBSCRIPTION_FULFILLED",
	SWAP_PAYPAL_SUBSCRIPTION_REJECTED: "SWAP_PAYPAL_SUBSCRIPTION_REJECTED",

	PAUSE_PAYPAL_SUBSCRIPTION: "PAUSE_PAYPAL_SUBSCRIPTION",
	PAUSE_PAYPAL_SUBSCRIPTION_FULFILLED: "PAUSE_PAYPAL_SUBSCRIPTION_FULFILLED",
	PAUSE_PAYPAL_SUBSCRIPTION_REJECTED: "PAUSE_PAYPAL_SUBSCRIPTION_REJECTED",

	CANCEL_PAYPAL_SUBSCRIPTION: "CANCEL_PAYPAL_SUBSCRIPTION",
	CANCEL_PAYPAL_SUBSCRIPTION_FULFILLED: "CANCEL_PAYPAL_SUBSCRIPTION_FULFILLED",
	CANCEL_PAYPAL_SUBSCRIPTION_REJECTED: "CANCEL_PAYPAL_SUBSCRIPTION_REJECTED",

	RESUME_PAYPAL_SUBSCRIPTION: "RESUME_PAYPAL_SUBSCRIPTION",
	RESUME_PAYPAL_SUBSCRIPTION_FULFILLED: "RESUME_PAYPAL_SUBSCRIPTION_FULFILLED",
	RESUME_PAYPAL_SUBSCRIPTION_REJECTED: "RESUME_PAYPAL_SUBSCRIPTION_REJECTED",
};

export const statisticActions = {
	FETCH_STATS: "FETCH_STATS",
	FETCH_STATS_FULFILED: "FETCH_STATS_FULFILED",
	FETCH_STATS_REJECTED: "FETCH_STATS_REJECTED",

	FETCH_STATS_ID: "FETCH_STATS_ID",
	FETCH_STATS_ID_FULFILED: "FETCH_STATS_ID_FULFILED",
	FETCH_STATS_ID_REJECTED: "FETCH_STATS_ID_REJECTED",

	FETCH_STATS_COUNTRIES: "FETCH_STATS_COUNTRIES",
	FETCH_STATS_COUNTRIES_FULFILLED: "FETCH_STATS_COUNTRIES_FULFILLED",
	FETCH_STATS_COUNTRIES_REJECTED: "FETCH_STATS_COUNTRIES_REJECTED",

	FETCH_STATS_PLATFORMS: "FETCH_STATS_PLATFORMS",
	FETCH_STATS_PLATFORMS_FULFILLED: "FETCH_STATS_PLATFORMS_FULFILLED",
	FETCH_STATS_PLATFORMS_REJECTED: "FETCH_STATS_PLATFORMS_REJECTED",

	FETCH_LOGS: "FETCH_LOGS",
	FETCH_LOGS_FULFILED: "FETCH_LOGS_FULFILED",
	FETCH_LOGS_REJECTED: "FETCH_LOGS_REJECTED",
};

export const subscriberActions = {
	FETCH_SUBS: "FETCH_SUBS",
	FETCH_SUBS_FULFILED: "FETCH_SUBS_FULFILED",
	FETCH_SUBS_REJECTED: "FETCH_SUBS_REJECTED",

	FETCH_SUBS_ID: "FETCH_SUBS_ID",
	FETCH_SUBS_ID_FULFILED: "FETCH_SUBS_ID_FULFILED",
	FETCH_SUBS_ID_REJECTED: "FETCH_SUBS_ID_REJECTED",

	FETCH_SUBS_COUNTRIES: "FETCH_SUBS_COUNTRIES",
	FETCH_SUBS_COUNTRIES_FULFILLED: "FETCH_SUBS_COUNTRIES_FULFILLED",
	FETCH_SUBS_COUNTRIES_REJECTED: "FETCH_SUBS_COUNTRIES_REJECTED",
};
