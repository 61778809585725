export const content = {
	en: {
		email: "EMAIL",
		password: "PASSWORD",
		login: "LOGIN",
		forget: "FORGOT PASSWORD",
		register: "REGISTRATION",
	},
	de: {
		email: "E-MAIL",
		password: "PASSWORT",
		login: "EINLOGGEN",
		forget: "PASSWORT VERGESSEN",
		register: "ANMELDUNG",
	},
};
