import React from "react";
import { Box } from "@chakra-ui/react";
import Client from "../panels/Client";
import Tab from "../../../shared/tabs";

function Panels({
	lang,
	id,
	formik,
	activeTab,
	setActiveTab,
	submitClicked,
	showMap,
	setShowMap,
	empty,
	setEmpty,
	createKeyword,
	...rest
}) {
	const options = {
		push_notification: <Client couponId={id} formik={formik} lang={lang} />,
	};
	const fields = [["title", "body", "data_type", "data_id", "prefer", "user"]];
	return (
		<Box display="flex" flexDir="column" justifyContent="space-between" {...rest}>
			<Tab
				submitClicked={submitClicked}
				formik={formik}
				fields={fields}
				active={activeTab}
				setActive={setActiveTab}
				options={options}
			/>
		</Box>
	);
}

export default Panels;
