import React from "react";
import { Box } from "@chakra-ui/layout";
import CreateCompany from "../CreateCompany";

function Content({ lang }) {
	return (
		<Box w={"50%"}>
			<CreateCompany lang={lang} />
		</Box>
	);
}

export default Content;
