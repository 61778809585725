import React from "react";
import "./devices.min.css";
import { Box, Text } from "@chakra-ui/react";
import MobilePrev from "./phoneComp/MobilePrev";
import useWindowSize from "../../../../../utils/useWindowSize";
// import Cursor from "./phoneComp/Cursor";

function Preview({ formik, setSubmitClicked, lang, display }) {
	const { width, height } = useWindowSize();

	return (
		<Box
			flex="1"
			flexDirection="column"
			alignItems="center"
			justifyContent="flex-start"
			pb="1em"
			display={display}
		>
			<Text mb="1em">{lang === "de" ? "Vorschau" : "Preview"}</Text>

			{/* {overEmulator && <Cursor />} */}

			<Box
				position="relative"
				top="2px"
				height="2px"
				width="100%"
				pb="1em"
				borderTop="2px solid #000"
			></Box>
			<div
				style={{
					zoom:
						height >= 2560 && width >= 1440
							? "1.05"
							: height >= 1080 && width >= 1920
							? "0.77"
							: height >= 1050 && width >= 1680
							? "0.75"
							: height >= 1024 && width >= 1280
							? "0.72"
							: height >= 900 && width >= 1440
							? "0.63"
							: height >= 864 && width >= 1536
							? "0.6"
							: height >= 800 && width >= 1280
							? "0.55"
							: height >= 768 && width >= 1366
							? "0.52"
							: height >= 720 && width >= 1280
							? "0.48"
							: "0.4",
				}}
				className="marvel-device iphone8 black"
			>
				<div className="top-bar" />
				<div className="sleep" />
				<div className="volume" />
				<div className="camera" />
				<div className="sensor" />
				<div className="speaker" />
				<div className="screen">
					<MobilePrev formik={formik} lang={lang} />
				</div>
				<div className="home" />
				<div className="bottom-bar" />
			</div>
		</Box>
	);
}

export default Preview;
