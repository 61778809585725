import React, { useRef } from "react";
import { useImmer } from "use-immer";

import { useCanvas } from "./hooks/useCanvas";
import { useSlidesListener } from "./hooks/useSlidesListener";
import { initialState } from "./static/initialState";
import { useSlides } from "./store";

// prettier-ignore
import {Layout,RightSideLayout,EditorLayout,LeftSideLayout, } from "./components/layouts";
import { CanvasOverlay, OverlayLoading } from "./components/overlay";
import VideoPlayer from "./components/VideoPlayer";
import PanoramaView from "./components/PanoramaView";
import ActionMenu from "./components/ActionMenu/ActionMenu";
import SideBar from "./components/SideBar/SideBar";
import SideActions from "./components/SideActions/SideActions";
import Modal from "./components/Modal";
import Input from "./components/Input";

function Builder({ variant, refer, formik }) {
	const [state, setState] = useImmer(initialState);
	const [canvas, isReady] = useCanvas(variant, refer, formik, setState);
	const slides = useSlides(state => state[variant]);
	const modifySlide = useSlides(state => state.modifySlide);
	useSlidesListener(canvas, isReady, variant, state, setState);

	const fileInput = useRef(null);

	return (
		<Layout>
			<RightSideLayout>
				<EditorLayout variant={variant}>
					{slides[state.activeSlide].isVideo && <VideoPlayer {...{ state, setState }} />}
					{slides[state.activeSlide].isPano && <PanoramaView {...{ state, setState }} />}
					<CanvasOverlay {...{ canvas, variant, refer, state, setState, fileInput, formik }} />
				</EditorLayout>
				<ActionMenu activeObject={state.activeObject} canvas={canvas} />
			</RightSideLayout>
			<OverlayLoading loading={state.templateLoading || !isReady} />
			<LeftSideLayout>
				<SideBar {...{ canvas, variant, refer, state, setState, fileInput }} />
				<SideActions {...{ state, setState }} />
			</LeftSideLayout>
			{state.modal.active && <Modal {...{ state, setState, canvas, variant }} />}
			<Input
				{...{
					state,
					setState,
					fileInput,
					canvas,
					variant,
					slides,
					modifySlide,
				}}
			/>
		</Layout>
	);
}

export default Builder;
