import React from "react";
import { Box } from "@chakra-ui/react";
import { content } from "../components/content";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import ModalSelect from "../../../shared/inputs/ModalSelect";
import OptionSelect from "../../../shared/inputs/OptionSelect";

function Client({ formik, lang, couponId, newsSelector = true, maxWidth = "auto" }) {
	const t = content[lang];

	return (
		<Box maxWidth={maxWidth}>
			<ModalSelect
				formik={formik}
				id="user.id"
				fields={["avatar", "name", "surname"]}
				label={lang === "en" ? "Users" : "Benutzer"}
				header="Select users"
				type="notifiableUsers"
			/>
			<PrimaryInput column={true} label={t.ctitle} type="text" id="title" formik={formik} />

			<PrimaryInput column={true} label={t.body} type="text" id="body" formik={formik} />

			<OptionSelect
				fields={["logo", "title"]}
				formik={formik}
				label="Data type"
				id="data_type"
				column={true}
				values={["news", "event", "location"]}
				labels={
					lang === "en"
						? ["News", "Event", "Location"]
						: ["Neuigkeiten", "Veranstaltung", "Location"]
				}
				reset={[]}
			/>

			<OptionSelect
				formik={formik}
				label="Prefer"
				id="prefer"
				column={true}
				values={["push_notification", "email_notification"]}
				labels={
					lang === "en"
						? ["Push Notification", "Email Notification"]
						: ["Push-Benachrichtigung", "E-Mail-Benachrichtigung"]
				}
				reset={[]}
			/>
		</Box>
	);
}

export default Client;
