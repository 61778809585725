import { useEffect } from "react";
import { useSlides } from "../store";

import { jsonToCanvas, changeOrientation } from "../utils/canvas";
import { loadPreviewImage } from "../utils/fabricActions";

export function useSlidesListener(canvas, isReady, variant, state, setState) {
	const slides = useSlides(state => state[variant]);
	const setActiveSlide = useSlides(state => state.setActiveSlide);
	const setSlideLoading = useSlides(state => state.setSlideLoading);

	useEffect(() => {
		if (isReady) {
			setActiveSlide(state.activeSlide);
			if (slides[state.activeSlide].template) {
				setSlideLoading(true);
				jsonToCanvas(
					canvas,
					slides[state.activeSlide].template,
					() => {
						setSlideLoading(false);
						changeOrientation(canvas, slides[state.activeSlide].orientation);
					},
					setState,
				);
				if (slides[state.activeSlide].isPreview && !slides[state.activeSlide].isVideo) {
					loadPreviewImage(canvas, slides[state.activeSlide].png);
				}
			}
		}
	}, [isReady, state.activeSlide, state.triggerRender]);
}
