import React, { useState } from "react";
import {
	Box,
	Text,
	Divider,
	Button,
	useDisclosure,
	IconButton,
	Image,
	Flex,
} from "@chakra-ui/react";
import Preview from "./locations-preview/Preview";
import Panels from "./Panels";
import { content } from "./content";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
} from "@chakra-ui/react";
import smallIcon from "../../../../assets/yezzgoSmallIcon.png";

function Layout({ header, lang, formik, countries, newsId }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [activeTab, setActiveTab] = useState(0);
	const [submitClicked, setSubmitClicked] = useState(false);
	const { title, description, location, keywords } = formik.errors;
	const t = content[lang];

	return (
		<Box
			h={["100vh", "100%"]}
			paddingX="20px"
			paddingY={["5em", "10px"]}
			display="flex"
			flexDirection="column"
			paddingBottom="0"
		>
			<Box py="3" display={["none", "block"]}>
				<Box mb="1">
					<Text fontSize="24px">{header}</Text>
				</Box>
				<Box w="40px">
					<Divider borderBottom="4px" opacity="1" />
				</Box>
			</Box>
			<PrimaryButton
				display={["block", "none"]}
				mb="1em"
				paddingBottom="8px"
				paddingTop="6px"
				label="Open Preview"
				onClick={onOpen}
			></PrimaryButton>
			<Flex
				display="flex"
				justifyContent={"space-between"}
				flexDirection={["column", "row"]}
				flexWrap={"wrap"}
			>
				<Box w={["100%", "60%"]} h={["10vh", "75vh"]}>
					<Panels
						countries={countries}
						newsId={newsId}
						formik={formik}
						lang={lang}
						activeTab={activeTab}
						submitClicked={submitClicked}
						setActiveTab={setActiveTab}
						flex="5"
					></Panels>
				</Box>
				<Box
					w={["100%", "30%"]}
					h={["10vh", "60vh"]}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					flexWrap={"wrap"}
					sx={{
						"@media screen and (max-width: 2880px)": {
							height: "60vh",
						},
						"@media screen and (max-width: 2560px)": {
							height: "65vh",
						},
						"@media screen and (max-width: 1920px)": {
							height: "65vh",
						},
						"@media screen and (max-width: 1600px)": {
							height: "67vh",
						},
						"@media screen and (max-width: 1366px)": {
							height: "70vh",
						},
						"@media screen and (max-width: 1024px)": {
							height: "60vh",
						},
						"@media screen and (max-width: 768px)": {
							height: "30vh",
						},
						"@media screen and (max-width: 425px)": {
							height: "0px",
						},
					}}
				>
					<Preview
						height="100%"
						display={["none", "flex"]}
						setSubmitClicked={setSubmitClicked}
						formik={formik}
						submitClicked={submitClicked}
						lang={lang}
					/>
				</Box>
			</Flex>

			<Flex justifyContent={"space-between"}>
				<Box
					borderTop="2px solid black"
					w={"65%"}
					left="0px"
					bottom="0px"
					bg="#FFF"
					zIndex="2"
					pt="1em"
					display="flex"
					justifyContent="center"
					alignItems="center"
					my="3"
				>
					<PrimaryButton
						minWidth="15%"
						hoverColor="#B0D352"
						label={t.back}
						onClick={() => {
							setActiveTab(activeTab - 1);
						}}
						disabled={activeTab === 0}
						mr="2"
					/>
					<PrimaryButton
						minWidth="15%"
						hoverColor="#B0D352"
						label={activeTab === 2 ? t.publish : t.confirm}
						onClick={() => {
							activeTab === 2 ? formik.handleSubmit() : setActiveTab(activeTab + 1);
						}}
						disabled={
							(activeTab === 0 && (title || description || location || !formik.dirty)) ||
							(activeTab === 1 && (keywords || !formik.dirty)) ||
							(activeTab === 2 && !(formik.isValid && formik.dirty))
						}
						mr="2"
					/>
					<PrimaryButton
						minWidth="15%"
						prompt={true}
						ml="2"
						hoverColor="#F58121"
						label={t.reset}
						onClick={() => {
							formik.resetForm();
							setActiveTab(0);
						}}
					/>
				</Box>
				<Box
					borderTop="2px solid black"
					paddingLeft={"14%"}
					my="3"
					pt="1em"
					w={"30%"}
					flexDirection="column"
					alignItems="center"
				>
					<Popover placement="top">
						<PopoverTrigger>
							<Button
								width="3em"
								height="3em"
								borderRadius="100%"
								display="flex"
								alignItems="center"
								justifyContent="center"
								cursor="pointer"
								_focus={{ outline: "none" }}
							>
								<FontAwesomeIcon icon="wifi" color="white" style={{ fontSize: "25" }} />
							</Button>
						</PopoverTrigger>
						<PopoverContent
							color="#000"
							bg="#FFF"
							borderColor="#000"
							borderWidth="4px"
							borderRadius="0"
						>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverBody>
								<Box gridGap="1em" display="flex" flexDirection={"column"}>
									<Box gridGap="1em" display="flex" alignItems="center">
										<Image width="3em" height="3em" src={smallIcon} />
										<IconButton
											borderRadius="100%"
											disabled={!(formik.isValid && formik.dirty)}
											background={
												formik.values.instagram_page_share
													? "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"
													: "#DDDDDE"
											}
											icon={
												<FontAwesomeIcon
													color="#FFF"
													cursor="pointer"
													icon={["fab", "instagram"]}
													size="2x"
												/>
											}
											onClick={() =>
												formik.setFieldValue(
													"instagram_page_share",
													!formik.values.instagram_page_share,
												)
											}
										/>
										<IconButton
											borderRadius="100%"
											disabled={!(formik.isValid && formik.dirty)}
											backgroundColor={formik.values.fb_page_share ? "#4267B2" : "#DDDDDE"}
											icon={
												<FontAwesomeIcon
													color="#FFF"
													cursor="pointer"
													icon={["fab", "facebook"]}
													size="2x"
												/>
											}
											onClick={() =>
												formik.setFieldValue("fb_page_share", !formik.values.fb_page_share)
											}
										/>
									</Box>
									<PrimaryButton
										backgroundColor={!(formik.isValid && formik.dirty) ? "#D7D8D7" : "#000"}
										onClick={
											!(formik.isValid && formik.dirty)
												? () => {}
												: () => {
														formik.handleSubmit();
														setSubmitClicked(true);
												  }
										}
										label="Submit"
									></PrimaryButton>
								</Box>
							</PopoverBody>
						</PopoverContent>
					</Popover>
					<Text color={!(formik.isValid && formik.dirty) ? "#D7D8D7" : "#000"}>
						{lang === "de" ? "Veröffentlichen" : "Publish"}
					</Text>
				</Box>
			</Flex>

			{/* <Box
        paddingLeft={"14%"}
        my="3"
        pt="1em"
        w={"30%"}
        flexDirection="column"
        alignItems="center"
      >
        <Popover placement="top">
          <PopoverTrigger>
            <Button
              width="3em"
              height="3em"
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              _focus={{ outline: "none" }}
            >
              <FontAwesomeIcon
                icon="wifi"
                color="white"
                style={{ fontSize: "25" }}
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            color="#000"
            bg="#FFF"
            borderColor="#000"
            borderWidth="4px"
            borderRadius="0"
          >
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Box gridGap="1em" display="flex" flexDirection={"column"}>
                <Box gridGap="1em" display="flex" alignItems="center">
                  <Image width="3em" height="3em" src={smallIcon} />
                  <IconButton
                    borderRadius="100%"
                    disabled={!(formik.isValid && formik.dirty)}
                    background={
                      formik.values.instagram_page_share
                        ? "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"
                        : "#DDDDDE"
                    }
                    icon={
                      <FontAwesomeIcon
                        color="#FFF"
                        cursor="pointer"
                        icon={["fab", "instagram"]}
                        size="2x"
                      />
                    }
                    onClick={() =>
                      formik.setFieldValue(
                        "instagram_page_share",
                        !formik.values.instagram_page_share
                      )
                    }
                  />
                  <IconButton
                    borderRadius="100%"
                    disabled={!(formik.isValid && formik.dirty)}
                    backgroundColor={
                      formik.values.fb_page_share ? "#4267B2" : "#DDDDDE"
                    }
                    icon={
                      <FontAwesomeIcon
                        color="#FFF"
                        cursor="pointer"
                        icon={["fab", "facebook"]}
                        size="2x"
                      />
                    }
                    onClick={() =>
                      formik.setFieldValue(
                        "fb_page_share",
                        !formik.values.fb_page_share
                      )
                    }
                  />
                </Box>
                <PrimaryButton
                  backgroundColor={
                    !(formik.isValid && formik.dirty) ? "#D7D8D7" : "#000"
                  }
                  onClick={
                    !(formik.isValid && formik.dirty)
                      ? () => {}
                      : () => {
                          formik.handleSubmit();
                          setSubmitClicked(true);
                        }
                  }
                  label="Submit"
                ></PrimaryButton>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Text color={!(formik.isValid && formik.dirty) ? "#D7D8D7" : "#000"}>
          {lang === "de" ? "Veröffentlichen" : "Publish"}
        </Text>
      </Box> */}
			<Box display={["none", "flex"]}></Box>
			<Modal display={["flex", "none"]} size="full" isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody width="100vw" height="100vh">
						<Preview
							display={["flex", "none"]}
							setSubmitClicked={setSubmitClicked}
							formik={formik}
							lang={lang}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default Layout;
