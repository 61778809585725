import React, { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Button, Image } from "@chakra-ui/react";
import Stories from "./Stories";
import Modal from "../Modal";
import { getOriginal } from "../../../utils/imgUri";
import useWindowSize from "../../../utils/useWindowSize";

export default function Gallery({ storyModal, setStoryModal, locationIndex, gallery }) {
	const [selectedGallery, setSelectedGallery] = useState(0);
	const { width, height } = useWindowSize();
	useEffect(() => {
		setSelectedGallery(0);
	}, [storyModal]);
	return storyModal ? (
		<Modal header="Story" handleClose={() => setStoryModal(false)}>
			<Box m="1em" gridGap="0.5em" display="flex">
				{gallery.map((item, index) => (
					<Button
						key={index}
						onClick={() => {
							setSelectedGallery(index);
						}}
						borderRadius="100%"
						width="5em"
						height="5em"
						p="0"
						m="0"
						border={index === selectedGallery ? "2px solid #73B13F" : "2px solid #000"}
						outline="none"
						boxShadow="none"
						_focus={{ outline: "none", boxShadow: "none" }}
						_active={{ outline: "none", boxShadow: "none" }}
						_hover={{
							outline: "none",
							boxShadow: "none",
							border: "2px solid #73B13F",
						}}
						transition="300ms all ease"
						position="relative"
					>
						<Text
							top="50%"
							transform="translateY(-50%)"
							display="flex"
							fontSize="0.8em"
							bg={item.event_id ? "#0E65AE" : "#AFCA09"}
							borderRadius="2%"
							p="0.3em"
							color="#FFF"
							fontFamily="Segoe UI Bold"
							position="absolute"
						>
							{item.event_id ? "EVENT" : "NEWS"}
						</Text>
						<Image
							src={getOriginal(item.gallery.data[0].image)}
							objectFit="cover"
							width="100%"
							height="100%"
							borderRadius="100%"
						/>
					</Button>
				))}
			</Box>
			<Box
				m="1em"
				overflow="hidden"
				display="flex"
				flexDir="column"
				justifyContent="space-between"
				height="100%"
				alignItems="space-between"
			>
				<Box height="100%">
					<Box
						flex="1"
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="flex-start"
					>
						<Box position="relative" top="2px" height="2px" width="100%"></Box>
						<div
							className="marvel-device iphone8 black"
							style={{
								zoom:
									height >= 1440 && width >= 2560
										? "1.40"
										: height >= 1080 && width >= 1920
										? "1"
										: height >= 1050 && width >= 1680
										? "0.90"
										: height >= 1024 && width >= 1280
										? "0.92"
										: height >= 900 && width >= 1440
										? "0.8"
										: height >= 864 && width >= 1536
										? "0.75"
										: height >= 800 && width >= 1280
										? "0.7"
										: height >= 768 && width >= 1366
										? "0.65"
										: height >= 720 && width >= 1280
										? "0.6"
										: "0.4",
							}}
						>
							<div className="top-bar" />
							<div className="sleep" />
							<div className="volume" />
							<div className="camera" />
							<div className="sensor" />
							<div className="speaker" />
							<div className="screen">
								<Stories
									locationIndex={locationIndex}
									gallery={gallery}
									selectedGallery={selectedGallery}
								/>
							</div>
							<div className="home" />
							<div className="bottom-bar" />
						</div>
					</Box>
				</Box>
			</Box>
		</Modal>
	) : null;
}
