import React from "react";
import BackButton from "./BackButton";

import Fonts from "../Fonts";
import Alignment from "../Alignment";
import Decorations from "../Decorations";
import Shadow from "../Shadow";

function ControllBox({ activeBox, setActiveBox, canvas, state }) {
	const onGoBack = () => {
		setActiveBox({
			active: false,
			type: null,
			icon: null,
		});
	};

	const ActiveControll = () => {
		switch (activeBox.type) {
			case "fonts":
				return <Fonts {...{ canvas, state }} />;
			case "align":
				return <Alignment {...{ canvas, state }} />;
			case "decor":
				return <Decorations {...{ canvas, state }} />;
			case "shadow":
				return <Shadow {...{ canvas }} />;
			case "picker":
				return <div></div>;
			default:
				return null;
		}
	};
	return (
		<>
			<BackButton icon={activeBox.icon} action={onGoBack} />
			<ActiveControll />
		</>
	);
}

export default ControllBox;
