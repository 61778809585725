import React from "react";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { content } from "./content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import { logOut } from "../../../../store/actions";
import { useDispatch } from "react-redux";
import { getAvatar } from "../../../../utils/imgUri";

function UserBoard({ lang, name, avatar, ...rest }) {
	const t = content[lang];
	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<Menu>
			<MenuButton
				as={Button}
				bg="white"
				outline="none"
				_active={{ outline: "none" }}
				_focus={{ outline: "none" }}
				rightIcon={
					<FontAwesomeIcon icon="chevron-down" color="#CCCCCC" style={{ fontSize: ".85em" }} />
				}
				{...rest}
			>
				<Box display="flex" alignItems="center">
					<Image mr="10px" w="30px" h="30px" borderRadius="100%" src={getAvatar(avatar)} />

					<Text mr="5px">{t.logged}</Text>
					<Text mr="5px">{name}</Text>
				</Box>
			</MenuButton>
			<MenuList>
				<Link to="/profile/">
					<MenuItem>{t.profile}</MenuItem>
				</Link>
				<Link to="/profile/edit">
					<MenuItem>{t["edit.profile"]}</MenuItem>
				</Link>
				<Link
					to="/"
					onClick={e => {
						e.preventDefault();
						dispatch(logOut(() => history.push("/login")));
					}}
				>
					<MenuItem>{t.logout}</MenuItem>
				</Link>
			</MenuList>
		</Menu>
	);
}

export default UserBoard;
