import { Box, Checkbox, Text, Image } from "@chakra-ui/react";
import React from "react";
import { getAvatar } from "../../../utils/imgUri";

function UserCard({ item, id, selectedUserList, setSelectedUserList, handleClick }) {
	return (
		<Box
			display="flex"
			p="1em"
			_hover={{ backgroundColor: "#0E65AF", color: "#FFF" }}
			cursor="pointer"
			transition="100ms all ease"
			backgroundColor={id === item.id && id ? "#AECB2D" : "#EBEAEB"}
			color="#000"
			fontWeight="500"
			width={360}
			alignItems="center"
			onClick={handleClick}
		>
			<Image
				src={item.avatar ? getAvatar(item.avatar) : ""}
				alt="avatar"
				w="30px"
				h="30px"
				borderRadius="50%"
				mr="20px"
			/>
			<Text mr="auto">{item.name}</Text>
			<Box onClick={e => e.stopPropagation()}>
				<Checkbox
					colorScheme="green"
					isChecked={selectedUserList.filter(i => i === item.id).length > 0}
					onChange={e => {
						e.stopPropagation();
						if (e.target.checked) {
							setSelectedUserList([...selectedUserList, item.id]);
						} else {
							setSelectedUserList(selectedUserList.filter(i => i !== item.id));
						}
					}}
				/>
			</Box>
		</Box>
	);
}

export default UserCard;
