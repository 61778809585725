import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import { Table, Thead, Tbody, Tr, Th, Td, TableCaption } from "@chakra-ui/react";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-US";

export default function SelectedTable({ formik, modalVisible, setModalVisible, t, lang }) {
	return (
		<Box
			as="button"
			width="100%"
			px="4"
			py="0"
			minHeight="2em"
			borderRadius="0px"
			borderColor="#CBCCCB"
			borderWidth="1px"
			backgroundColor={modalVisible ? "#F2F1F1" : "transparent"}
			boxShadow="none"
			_hover={{ backgroundColor: "#F2F1F1" }}
			_focus={{
				backgroundColor: "#F2F1F1",
				borderColor: "#1274BC",
			}}
			color="#000"
			_placeholder={{ color: "rgba(0,0,0,0.5)" }}
			onClick={() => {
				setModalVisible(true);
			}}
		>
			{Object.keys(formik.values.availability).length ? (
				<Table variant="simple">
					{formik.values.recurrence !== "none" && (
						<TableCaption placement="top" mt="0">
							Event starts from{" "}
							{Object.keys(formik.values.availability)[0]
								? format(new Date(Object.keys(formik.values.availability)[0]), "dd/MM/yyyy")
								: null}{" "}
							and repeats {formik.values.recurrence}
						</TableCaption>
					)}

					<Thead>
						<Tr>
							{formik.values.recurrence === "none" ? (
								<Th>Date</Th>
							) : formik.values.recurrence === "daily" ? (
								<Th>Recurrence</Th>
							) : formik.values.recurrence === "weekly" ? (
								<Th>Week day</Th>
							) : (
								<Th>Day of the month</Th>
							)}

							<Th>Opening time</Th>
							<Th>Closing time</Th>
						</Tr>
					</Thead>
					<Tbody>
						{Object.keys(formik.values.availability)
							.sort(function (a, b) {
								// Turn your strings into dates, and then subtract them
								// to get a value that is either negative, positive, or zero.
								return new Date(b.date) - new Date(a.date);
							})
							.map((key, index) => {
								switch (formik.values.recurrence) {
									case "none":
										return (
											<Tr key={index}>
												<Td> {key && format(new Date(key), "dd/MM/yyyy")}</Td>
												<Td>{format(formik.values.availability[key].start_at, "HH:mm")}</Td>
												<Td>{format(formik.values.availability[key].end_at, "HH:mm")}</Td>
											</Tr>
										);
									case "range":
										return (
											<Tr key={index}>
												<Td>{format(new Date(key), "dd-MM-yyyy")}</Td>

												<Td>{format(formik.values.availability[key].start_at, "HH:mm")}</Td>
												<Td>{format(formik.values.availability[key].end_at, "HH:mm")}</Td>
											</Tr>
										);
									case "daily":
										return (
											<Tr key={index}>
												<Td>{formik.values.recurrence.toUpperCase()}</Td>

												<Td>{format(formik.values.availability[key].start_at, "HH:mm")}</Td>
												<Td>{format(formik.values.availability[key].end_at, "HH:mm")}</Td>
											</Tr>
										);
									case "weekly":
										return (
											<Tr key={index}>
												<Td>
													{format(new Date(key), "EEEE", {
														locale: lang === "de" ? de : en,
													})}
												</Td>

												<Td>{format(formik.values.availability[key].start_at, "HH:mm")}</Td>
												<Td>{format(formik.values.availability[key].end_at, "HH:mm")}</Td>
											</Tr>
										);
									case "monthly":
										return (
											<Tr key={index}>
												<Td>
													{format(new Date(key), "do", {
														locale: lang === "de" ? de : en,
													})}
												</Td>

												<Td>{format(formik.values.availability[key].start_at, "HH:mm")}</Td>
												<Td>{format(formik.values.availability[key].end_at, "HH:mm")}</Td>
											</Tr>
										);
									default:
										break;
								}
							})}
					</Tbody>
				</Table>
			) : (
				<Text>{t.choose}</Text>
			)}
		</Box>
	);
}
