export const content = {
	en: {
		header: "Locations",
		client: "Create Location",
		desc: "Description",
		title: "Title",
		service: "Trade / Service",
		performance: "Performance range",
		plz: "Post code",
		city: "City",
		street: "Street",
		number: "House number",
		tel: "Phone",
		fax: "Fax",
		mail: "E-Mail",
		web: "Web site",
		opens: "Opening time",
		country: "Country",
		address: "Address",
		description: "Description",
		map: "Map",
		delete: "Delete",
		edit: "Edit",
		sure: "Are you sure to delete?",
		cancel: "Cancel",
		add_location: "Add location",
		logo: "Logo",
		reset: "Reset",
		confirm: "Confirm",
		files: "Files",
		close: "Close",
		location_count: "Secret locations",
		copy: "Link",
	},
	de: {
		header: "Mandant erstellen",
		client: "Mandat",
		desc: "Beschreibung",
		title: "Titel",
		service: "Gewerbe / Dienstleistung",
		performance: "Leistungsbereich",
		plz: "PLZ",
		city: "Stadt",
		street: "Straße",
		number: "Hausnummer",
		tel: "Telefon",
		fax: "Fax",
		mail: "E-Mail",
		web: "Internet",
		opens: "Öffnungszeit",
		country: "Land",
		address: "Anschrift",
		description: "Bezeichnung",
		map: "Karte",
		delete: "löschen",
		edit: "Bearbeiten",
		sure: "Bist du sicher, das du das löschen möchtest?",
		cancel: "Abbrechen",
		add_location: "Ort hinzufügen",
		logo: "Logo",
		confirm: "Bestätigen",
		reset: "Zurücksetzten",
		files: "Dateien",
		close: "Nah dran",
		location_count: "Geheime Orte",
		copy: "Link",
	},
};
