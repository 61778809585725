import { useEffect, useState } from "react";
import SideBarContainer from "./components/SidebarContainer";
import { useSelector, useDispatch } from "react-redux";
import { logOut, fetchMenu, toogleSidebar } from "../../../store/actions";
import { useHistory } from "react-router-dom";

function Sidebar() {
	const history = useHistory();
	const dispatch = useDispatch();
	const lang = useSelector(state => state.languageProvider.language);
	const { menu } = useSelector(state => state.menu);
	const active = useSelector(state => state.sidebar.active);
	const { locations: locationsSecond } = useSelector(state => state.fetchLocations);
	const [limits, setLimits] = useState([]);
	const { locations, stripe_subscriptions, paypal_subscriptions } = useSelector(
		state => state.fetchCompany.company,
	);
	useEffect(() => {
		let stripeLimits = {},
			paypalLimits = {};

		if (stripe_subscriptions?.length > 0) {
			stripeLimits = stripe_subscriptions[0].plan_price.company_per_plan_limitation;
			Object.keys(stripeLimits).forEach(key => {
				if (stripeLimits[key] !== 0) delete stripeLimits[key];
			});
		}

		if (paypal_subscriptions?.length > 0) {
			paypalLimits = paypal_subscriptions[0].plan_price.company_per_plan_limitation;
			Object.keys(paypalLimits).forEach(key => {
				if (paypalLimits[key] !== 0) delete paypalLimits[key];
			});
		}
		if (stripeLimits) {
			if (
				Object.keys(stripeLimits)?.length < Object.keys(paypalLimits)?.length ||
				paypal_subscriptions?.length === 0
			) {
				setLimits(Object.keys(stripeLimits));
			} else {
				setLimits(Object.keys(paypalLimits));
			}
		}
	}, [paypal_subscriptions, stripe_subscriptions]);

	const handleLogOut = () => {
		dispatch(logOut(() => history.push("/login")));
	};
	const handleToggle = () => {
		dispatch(toogleSidebar());
	};

	useEffect(() => {
		dispatch(fetchMenu());
	}, [lang]);

	return (
		<SideBarContainer
			locationsLength={locations?.data?.length > 0 || locationsSecond?.data?.length > 0}
			active={active}
			handleToggle={handleToggle}
			menu={menu}
			lang={lang}
			handleLogOut={handleLogOut}
			limits={limits}
		/>
	);
}

export default Sidebar;
