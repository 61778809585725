import React from "react";
import { Box } from "@chakra-ui/react";
import { format, isSameMonth, isToday } from "date-fns";

function Day({ day, currentMonthsFirstDay, dayColor, handleDisabled, onOpen }) {
	return isSameMonth(day, currentMonthsFirstDay) ? (
		<Box
			as="button"
			_disabled={{
				bg: dayColor(day) ? "#AFCB2E" : "#transparent",
				color: dayColor(day) ? "#909090" : "#c3c3c3",
				cursor: "default",
			}}
			border={isToday(day) ? "0.5px solid #AFCB2E" : null}
			disabled={handleDisabled(day)}
			borderRadius="100%"
			width="1.5em"
			height="1.5em"
			transition="30ms all ease"
			_hover={{ bg: "#0E65AE", color: "#FFF" }}
			_active={{ bg: "#AFCB2E", color: "#000" }}
			bg={dayColor(day) ? "#AFCB2E" : "#transparent"}
			onClick={() => onOpen(day)}
		>
			{format(day, "dd")}
		</Box>
	) : (
		<Box></Box>
	);
}

export default Day;
