import React from "react";
import { Box } from "@chakra-ui/react";

function HorizontalScrollContainer(props) {
	return (
		<Box
			w={window.innerWidth}
			h="50px"
			overflowX="scroll"
			overflowY="hidden"
			whiteSpace="nowrap"
			display="flex"
			borderTop="0.5px solid black"
			padding={0}
		>
			{props.children}
		</Box>
	);
}

export default HorizontalScrollContainer;
