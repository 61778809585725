export const content = {
	en: {
		reset: "Reset password",
		email: "Email Address",
		password: "Password",
		repass: "Confirm password",
		res: "Reset",
	},
	de: {
		reset: "Passwort zurücksetzen",
		email: "E-Mail-Addresse",
		password: "Passwort",
		repass: "Kennwort bestätigen",
		res: "Zurücksetzen",
	},
};
