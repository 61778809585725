import React from "react";
import HSContainer from "./HSContainer";

import Slide from "./Slide";
import NewSlideButton from "./NewSlideButton";

function Slides({ variant, slides, state, setState, onAddSlide }) {
	return (
		<HSContainer id="slides-dnd">
			{slides.map((slide, index) => (
				<Slide
					key={index}
					slide={slide}
					index={index}
					activeSlide={state.activeSlide}
					setState={setState}
				/>
			))}
			{slides.length < 5 && variant === "gallery" && <NewSlideButton onAddSlide={onAddSlide} />}
		</HSContainer>
	);
}

export default Slides;
