import React from "react";
import { Box } from "@chakra-ui/react";

function ScrollLayout(props) {
	return (
		<Box
			overflowX="auto"
			// className="custom-scroll"
			display="flex"
			flexDir="row"
		>
			{props.children}
		</Box>
	);
}

export default ScrollLayout;
