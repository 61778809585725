import { paypalActions } from "../../actionTypes";
import { changeSubscription } from "../../../api/libs/paypal";
import { fetchPaypalSubscriptions } from "./fetchPaypalSubscriptions";

export const swapPaypalSubscription = data => {
	return async dispatch => {
		dispatch({
			type: paypalActions.SWAP_PAYPAL_SUBSCRIPTION,
		});
		changeSubscription(data)
			.then(res => {
				dispatch({
					type: paypalActions.SWAP_PAYPAL_SUBSCRIPTION_FULFILLED,
					payload: res.data,
				});
				dispatch(fetchPaypalSubscriptions());
				window.open(res.data.href, "_blank").focus();
			})
			.catch(err => {
				dispatch({
					type: paypalActions.SWAP_PAYPAL_SUBSCRIPTION_REJECTED,
					payload: err.response.data.error,
				});
			});
	};
};
