export const dimensions = [
	{
		name: "portrait",
		width: 432,
		height: 540,
		ratio: 2.5,
	},
	{
		name: "landscape",
		width: 540,
		height: 304,
		ratio: 2,
	},
	{
		name: "squere",
		width: 540,
		height: 540,
		ratio: 2,
	},
];

export const dimensionsObj = {
	squere: {
		name: "squere",
		width: 540,
		height: 540,
		ratio: 2,
	},
	portrait: {
		name: "portrait",
		width: 432,
		height: 540,
		ratio: 2.5,
	},
	landscape: {
		name: "landscape",
		width: 540,
		height: 304,
		ratio: 2,
	},
};

export const getDimensions = (variant, refer) => {
	if (variant === "logo") {
		return {
			width: dimensionsObj.squere.width,
			height: dimensionsObj.squere.height,
			name: "squere",
		};
	} else {
		if (refer === "locations") {
			return {
				width: dimensionsObj.landscape.width,
				height: dimensionsObj.landscape.height,
				name: "landscape",
			};
		} else
			return {
				width: dimensionsObj.portrait.width,
				height: dimensionsObj.portrait.height,
				name: "portrait",
			};
	}
};
