import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import Client from "../panels/Client";
import Map from "../panels/Map";
import Hours from "../panels/hours";
import Tab from "../../../shared/tabs";
import Description from "../panels/Description";
import Search from "../../../shared/keywords";
import { useJsApiLoader } from "@react-google-maps/api";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useSelector } from "react-redux";
// import LogoEditor from "../../../editor-v3/logo";
import Editor from "../../../Editor";

// const Builder = lazy(() => import("../../../builder-refactor"));

function Panels({
	lang,
	active,
	id,
	formik,
	submitClicked,
	activeTab,
	setActiveTab,
	empty,
	setEmpty,
	showMap,
	setShowMap,
	...rest
}) {
	const { location, fetched } = useSelector(state => state.fetchLocation);

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",

		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
	});

	const options = {
		Description: <Description formik={formik} lang={lang} />,
		Location: (
			<Client
				isLoaded={isLoaded}
				setShowMap={setShowMap}
				locationId={id}
				formik={formik}
				lang={lang}
			/>
		),
		Logo: (
			// <Builder key={0} variant="logo" refer={"locations"} formik={formik} />
			// <LogoEditor key={0} formik={formik} />
			<Editor key={0} variant="logo" refer="locations" formik={formik} />
		),
		Gallery: (
			<Editor key={1} variant="gallery" refer="locations" formik={formik} />
			// <Builder key={1} variant="gallery" refer={"locations"} formik={formik} />
		),
		Keywords: <Search type="locations" formik={formik} lang={lang} />,
		Hours: <Hours id={id} lang={lang} formikHours={formik} />,
		"Google map": (
			<Map
				isLoaded={isLoaded}
				locationId={id}
				showMap={showMap}
				formik={formik}
				lang={lang}
				empty={empty}
			/>
		),
	};
	useEffect(() => {
		if (activeTab === 6) {
			setMap();
		} else if (activeTab === 2 || activeTab === 3 || activeTab === 0) {
			const scroll = document.getElementById("previewScroll");
			scroll.scrollTop = 0;
		} else if (activeTab === 1) {
			const scroll = document.getElementById("previewScroll");
			scroll.scrollTop = scroll.scrollHeight;
		}
	}, [activeTab]);

	const setMap = () => {
		if (fetched) {
			if (
				location.latitude !== formik.values.latitude ||
				location.longitude !== formik.values.longitude ||
				location.street !== formik.values.street ||
				location.street_number !== formik.values.street_number ||
				location.postal_code !== formik.values.postal_code ||
				location.city !== formik.values.city ||
				location.country !== formik.values.country
			) {
				if (
					!formik.errors.street &&
					!formik.errors.street_number &&
					!formik.errors.postal_code &&
					!formik.errors.city &&
					!formik.errors.country
				) {
					geocodeByAddress(
						`${formik.values.street_number} ${formik.values.street} ${formik.values.city?.name} ${formik.values.country?.name} ${formik.values.postal_code}`,
					)
						.then(results => {
							// results[0].address_components.forEach((item) => {
							//   if (item.types.includes("country"))
							//     formik.setFieldValue("country", item.short_name);
							// });
							if (!results.length) {
								setEmpty(true);
							} else {
								setEmpty(false);
							}

							return getLatLng(results?.[0]);
						})
						.then(latLng => {
							formik.setFieldValue("latitude", latLng.lat);
							formik.setFieldValue("longitude", latLng.lng);
						})
						.catch(error => {
							console.error(error);
						});
					setShowMap(true);
				}
			} else {
				setEmpty(false);
			}
		} else {
			if (
				!formik.errors.street &&
				!formik.errors.street_number &&
				!formik.errors.postal_code &&
				!formik.errors.city &&
				!formik.errors.country
			) {
				geocodeByAddress(
					`${formik.values.street_number} ${formik.values.street} ${formik.values.city} ${formik.values.country} ${formik.values.postal_code}`,
				)
					.then(results => {
						console.log(results);
						// results[0].address_components.forEach((item) => {
						//   if (item.types.includes("country"))
						//     formik.setFieldValue("country", item.short_name);
						// });
						if (!results.length) {
							setEmpty(true);
						} else {
							setEmpty(false);
						}

						return getLatLng(results?.[0]);
					})
					.then(latLng => {
						formik.setFieldValue("latitude", latLng.lat);
						formik.setFieldValue("longitude", latLng.lng);
					})
					.catch(error => {
						console.error(error);
					});
				setShowMap(true);
			}
		}
	};

	const fields = [
		["title", "sub_title", "description", "description_title"],
		[
			"official_name",
			"street",
			"street_number",
			"postal_code",
			"city",
			"timezone",
			"state",
			"category_id",
			"country",
			"phone_number",
			"web_page",
			"email",
		],
		["logo"],
		["gallery"],
		["keywords"],
		["open_hours"],
		["longitude", "latitude"],
	];

	return (
		<Box overflowY="hidden" display="flex" flexDir="column" {...rest}>
			<Tab
				submitClicked={submitClicked}
				formik={formik}
				fields={fields}
				active={activeTab}
				setActive={setActiveTab}
				options={options}
			/>
		</Box>
	);
}

export default Panels;
