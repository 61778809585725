import React from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Box,
} from "@chakra-ui/react";
import PrimaryButton from "../../../buttons/PrimaryButton";
import TimeInput from "../../TimeInput";
import { isToday, setHours, setMinutes } from "date-fns";
import format from "date-fns/format";

export default function TimeModal({
	isOpen,
	onClose,
	handleModalConfirm,
	setTimeValue,
	selectedDate,
	setOpenAt,
	openAt,
	lang,
}) {
	return (
		<Modal
			size="xl"
			isCentered
			isOpen={isOpen}
			onClose={() => {
				onClose();

				setTimeValue("00:00");
			}}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Select time</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box display="grid" gridTemplateColumns="1fr" gridGap="0.5em" alignItems="center">
						<TimeInput
							disabled={false}
							min={isToday(selectedDate) ? format(new Date(), "HH:mm") : null}
							iconDisabled={false}
							label={lang === "en" ? "Choose time" : "Zeit wählen"}
							onChange={value => {
								if (value !== "") {
									const dateWithHours = setHours(selectedDate, value.split(":")[0]);
									const dateWithMinutes = setMinutes(dateWithHours, value.split(":")[1]);

									setOpenAt(dateWithMinutes);
								} else {
									setOpenAt(undefined);
								}
							}}
							value={openAt ? format(openAt, "HH:mm") : undefined}
						/>
					</Box>
				</ModalBody>

				<ModalFooter>
					<PrimaryButton
						disabled={openAt === undefined}
						label="Confirm"
						mr={3}
						onClick={() => {
							handleModalConfirm();
						}}
					></PrimaryButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
