import React, { useState } from "react";
import MainContainer from "./components/MainContainer";
import ControllList from "./components/ControllList";
import ControllBox from "./components/ControllBox";

function TextBoxControlls({ canvas, state, setState }) {
	const [activeBox, setActiveBox] = useState({
		active: false,
		type: null,
		icon: null,
	});

	const openPickerModal = () => {
		setState(state => {
			state.modal = { active: true, type: "picker" };
		});
	};
	return (
		<MainContainer>
			{activeBox.active ? (
				<ControllBox {...{ activeBox, setActiveBox, canvas, state }} />
			) : (
				<ControllList {...{ setActiveBox, openPickerModal }} />
			)}
		</MainContainer>
	);
}

export default TextBoxControlls;
