import { useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getClientSecret } from "../../api/libs/user";
import { initStripePayment } from "../../store/actions";
import useQuery from "../../utils/query";
import Loading from "../shared/Loading";

export default function Form() {
	let query = useQuery();
	const history = useHistory();
	const stripe = useStripe();
	const [clientSecret, setClientSecret] = useState("");
	const dispatch = useDispatch();
	useEffect(() => {
		getClientSecret({
			plan_price_id: localStorage.getItem("selectedPlan"),
			invoice_location_id: localStorage.getItem("activeInvoice"),
		})
			.then(res => {
				if (res.data.client_secret) {
					setClientSecret(res.data.client_secret);
				}
			})
			.catch(err => {
				const key = Object.keys(err.response.data.errors)[0];
				toast.error(err.response.data.errors[key]);
				history.push("/");
			});
	}, []);
	useEffect(() => {
		if (stripe) {
			stripe.retrieveSetupIntent(query.get("setup_intent_client_secret")).then(function (result) {
				let data = {
					plan_price_id: localStorage.getItem("selectedPlan"),
					stripe_token: result.setupIntent.payment_method,
					invoice_location_id: localStorage.getItem("activeInvoice"),
				};
				dispatch(initStripePayment(data, history));
				if (result.error) {
					// Inform the customer that there was an error.
				}
				// Handle result.error or result.paymentIntent
			});
		}
	}, [stripe]);

	return <Loading />;
}
