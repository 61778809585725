import React from "react";
import { Text } from "@chakra-ui/react";

function TextInfo({ txt, active = true }) {
	return (
		<Text
			mt="5px"
			mb="10px"
			fontSize="14px"
			color={!active ? "#D7D8D7" : "black"}
			userSelect="none"
		>
			{txt}
		</Text>
	);
}

export default TextInfo;
