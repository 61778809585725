import React from "react";

import ImageControlls from "./components/ImageControlls/ImageControlls";
import TextBoxControlls from "./components/TextBoxControlls/TextBoxControlls";

function TargetControlls({ canvas, state, setState, activeObject }) {
	if (!activeObject) return null;
	if (activeObject?.type === "image") {
		return <ImageControlls canvas={canvas} />;
	} else return <TextBoxControlls {...{ canvas, state, setState }} />;
}

export default TargetControlls;
