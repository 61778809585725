import React, { useEffect } from "react";
import Layout from "./components/Layout";
import Content from "./components/Content";

import { useSelector, useDispatch } from "react-redux";
import { fetchPlans, fetchSelectedPlan } from "../../store/actions";
import Loading from "../shared/Loading";
import PublicLayout from "../shared/PublicLayout";
import { useState } from "react";

function Subscription({
	active = { plan_price_id: "", plan_id: "" },
	type,
	onPlanClose,
	setPage,
	setTmpPlan,
}) {
	const dispatch = useDispatch();
	const [activePlan, setActivePlan] = useState(-1);
	const { plans, fetching, fetched } = useSelector(state => state.plans);
	const { onePlan } = useSelector(state => state.selectedPlan);
	const { selectedPlan: plan, fetching: loading } = useSelector(state => state.selectedPlan);
	const lang = useSelector(state => state.languageProvider.language);
	useEffect(() => {
		setTimeout(() => {
			window.history.replaceState({}, null, window.location.href.replace("/subscription", ""));
		}, 1000);
	}, []);

	const handleChange = tmpId => {
		setTmpPlan(true);
		console.log("tmpid", tmpId);
		localStorage.setItem("selectedPlan", tmpId);
		console.log(plans.data);
		dispatch(fetchSelectedPlan(tmpId));
		plans.data.map(item => {
			if (item.id === tmpId) {
				localStorage.setItem("activePlanItem", JSON.stringify(item));
			}
		});
	};

	useEffect(() => {
		dispatch(fetchPlans());
	}, []);

	// get url params
	if (fetching) return <Loading />;
	return (
		<>
			{fetched && (
				<Content
					setActivePlan={setActivePlan}
					active={active}
					type={type}
					lang={lang}
					handleChange={handleChange}
					plans={plans}
					selectedPlan={plan}
					cardLoading={loading}
					onPlanClose={onPlanClose}
					setPage={setPage}
				/>
			)}
		</>
	);
}

export default Subscription;
