import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";

import { useInView } from "react-intersection-observer";
import { useSlides } from "../../../../../../store";

function FontsDisplay(props) {
	const { ref, inView, entry } = useInView({ threshold: 0 });

	const { data, fetching, fetched, canFetchNext, fetchingNext } = useSlides(state => state.fonts);

	const getFontsNext = useSlides(state => state.getFontsNext);

	useEffect(() => {
		if (inView) {
			if (!fetching && !fetchingNext && fetched) {
				if (canFetchNext) {
					getFontsNext();
				}
			}
		}
	}, [inView]);
	return (
		<Box
			borderBottom="1px solid #CBCCCB"
			overflowY="auto"
			overflowX="hidden"
			className="custom-scroll"
			h="60vh"
		>
			<Box
				display="grid"
				gridTemplateColumns="repeat(2, 50%)"
				gridColumnGap="10px"
				gridRowGap="5px"
				mt="20px"
				py="20px"
				pt="0"
				pr="20px"
			>
				{props.children}
			</Box>
			<div
				ref={ref}
				style={{
					alignSelf: "center",
					width: "100%",
					justifySelf: "center",
					textAlign: "center",
				}}
			>
				{(fetching || fetchingNext) && <h2>loading</h2>}
				{!canFetchNext && <h2>No more fonts</h2>}
			</div>
		</Box>
	);
}

export default FontsDisplay;
