import React from "react";
import { Box, Text, Divider } from "@chakra-ui/react";
import { content } from "../components/content";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../../shared/table";

function Layout({
	lang,
	logs,
	isOpen,
	onOpen,
	onClose,
	currentId,
	setCurrentId,
	next_page_url,
	prev_page_url,
	setCurrentPage,
	current_page,
	per_page,
	fetching,
}) {
	const t = content[lang];

	const chooseDescription = description => {
		switch (description) {
			case "updated":
				return (
					<Table>
						<Thead>
							<Tr>
								<Th>{t.old}</Th>
								<Th>{t.new}</Th>
							</Tr>
						</Thead>
						<Tbody borderTop="2px solid #000">
							{Object.keys(logs[currentId].properties.attributes).map(
								(key, index) =>
									key !== "location" && (
										<Tr
											key={index}
											_hover={{
												backgroundColor: "#F2F1F0",
											}}
										>
											<Td>
												{logs[currentId].properties.old[key] !==
												logs[currentId].properties.attributes[key] ? (
													<Text color="red">{logs[currentId].properties.old[key] || t.empty}</Text>
												) : (
													<Text color="#000">{logs[currentId].properties.old[key] || t.empty}</Text>
												)}
											</Td>

											<Td>
												{logs[currentId].properties.old[key] !==
												logs[currentId].properties.attributes[key] ? (
													<Text color="green">
														{logs[currentId].properties.attributes[key] || t.empty}
													</Text>
												) : (
													<Text color="#000">
														{logs[currentId].properties.attributes[key] || t.empty}
													</Text>
												)}
											</Td>
										</Tr>
									),
							)}
						</Tbody>
					</Table>
				);

			case "created":
				return (
					<Table>
						<Thead>
							<Tr>
								<Th fontSize="1.2em">{t.created}</Th>
							</Tr>
						</Thead>
						<Tbody borderTop="2px solid #000">
							{Object.keys(logs[currentId].properties.attributes).map((key, index) => (
								<Tr
									key={index}
									_hover={{
										backgroundColor: "#F2F1F0",
									}}
								>
									<Td>
										<Text color="green">
											{(key !== "location" && logs[currentId].properties.attributes[key]) ||
												t.empty}
										</Text>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				);
			default:
		}
	};

	const chooseType = (type, description = false) => {
		switch (type) {
			case "payment":
				return (
					<Box display="flex">
						<Text>{t.payment}:</Text>
						{logs[currentId].properties.action ? (
							<Text ml="1" color="green">
								{logs[currentId].properties.action}
							</Text>
						) : (
							<Text ml="1" color="red">
								{logs[currentId].properties.action}
							</Text>
						)}
					</Box>
				);
			case "auth":
				return (
					<Table>
						<Thead>
							<Tr>
								<Th fontSize="1.2em">{t.action}</Th>
								<Th fontSize="1.2em">{t.ip_address}</Th>
								<Th fontSize="1.2em">{t.browser}</Th>
							</Tr>
						</Thead>
						<Tbody borderTop="2px solid #000">
							<Tr
								_hover={{
									backgroundColor: "#F2F1F0",
								}}
							>
								{Object.keys(logs[currentId].properties).map((key, index) => (
									<Td key={index}>
										<Text>{logs[currentId].properties[key] || t.empty}</Text>
									</Td>
								))}
							</Tr>
						</Tbody>
					</Table>
				);
			case "gallery":
				return <Box>Gallery log</Box>;
			case "users":
				return chooseDescription(description);
			case "events":
				return chooseDescription(description);
			case "news":
				return chooseDescription(description);
			case "locations":
				return chooseDescription(description);
			default:
		}
	};

	const handleScroll = e => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom && next_page_url && !fetching) {
			setCurrentPage(current_page + 1);
		}
	};
	return (
		<Box
			paddingX="20px"
			display="flex"
			flexDirection="column"
			flexGrow="1"
			overflowY="scroll"
			position="absolute"
			onScroll={handleScroll}
			bottom="0"
			right="0"
			width="100%"
			top={["3em", "0"]}
			pb={["5em", "1em"]}
		>
			<Box py="3">
				<Box mb="1">
					<Text fontSize="24px">Logs</Text>
				</Box>
				<Box w="40px">
					<Divider borderBottom="4px" opacity="1" />
				</Box>
			</Box>
			<Box display="flex" flexDirection="column">
				<Table>
					<Thead>
						<Tr>
							<Th>#</Th>
							<Th>{t.name}</Th>
							<Th>{t.description}</Th>
							<Th>{t.action}</Th>
							<Th>{t.updated_at}</Th>
						</Tr>
					</Thead>

					<Tbody borderTop="2px solid #000">
						{logs.map((item, key) => (
							<Tr key={key}>
								<Td py="2em">{key + 1}</Td>
								<Td>{item.log_name}</Td>
								<Td>{item.description}</Td>

								<Td hide>
									<PrimaryButton
										onClick={() => {
											setCurrentId(key);
											onOpen();
										}}
										label={t.open_details}
									/>
								</Td>
								<Td>{item.updated_at}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>

			<Modal onClose={onClose} size="full" isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t.log_details}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box display="flex" flexGrow="1" pt="30px" flexDirection="column">
							{chooseType(logs[currentId]["log_name"], logs[currentId]["description"])}
						</Box>
					</ModalBody>
					<ModalFooter>
						<PrimaryButton onClick={onClose} label={t.close} />
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default Layout;
