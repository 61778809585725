import { chatsActions } from "../../actionTypes";
import { getMessages } from "../../../api/libs/chats";

export const fetchMessages = (id, page) => {
	return async dispatch => {
		dispatch({
			type: chatsActions.FETCH_MESSAGES,
		});
		getMessages(id, page)
			.then(res => {
				dispatch({
					type: chatsActions.FETCH_MESSAGES_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: chatsActions.FETCH_MESSAGES_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
