import { chatsActions } from "../../actionTypes";

const initialState = {
	chats: [],
	fetching: false,
	fetched: false,
	fetchingNext: false,
	nextPageUrl: null,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case chatsActions.FETCH_CHATS: {
			return { ...initialState, fetching: true };
		}

		case chatsActions.FETCH_CHATS_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				chats: action.payload.data.data,
				nextPageUrl: action.payload.data.next_page_url,
			};
		}

		case chatsActions.FETCH_CHATS_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		case chatsActions.FETCH_CHATS_NEXT: {
			return { ...state, fetchingNext: true };
		}
		case chatsActions.FETCH_CHATS_NEXT_FULFILLED: {
			return {
				...state,
				fetchingNext: false,
				chats: [...state.chats, ...action.payload.data.data],
				nextPageUrl: action.payload.data.next_page_url,
			};
		}

		default:
			return state;
	}
};

export default Reducer;
