import { paypalActions } from "../../actionTypes";

const initialState = {
	subscriptions: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case paypalActions.FETCH_PAYPAL_SUBSCRIPTIONS: {
			return action.payload.reset
				? { ...initialState, fetching: true }
				: { ...state, fetching: true };
		}

		case paypalActions.FETCH_PAYPAL_SUBSCRIPTIONS_FULFILLED: {
			return state.subscriptions.current_page < action.payload.current_page
				? {
						...state,
						fetching: false,
						fetched: true,
						subscriptions: {
							...action.payload,
							data: state.subscriptions.data
								? [...state.subscriptions.data, ...action.payload.data]
								: action.payload.data,
						},
				  }
				: {
						...state,
						fetching: false,
						fetched: true,
						subscriptions: action.payload,
				  };
		}

		case paypalActions.FETCH_PAYPAL_SUBSCRIPTIONS_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
