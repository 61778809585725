import React from "react";
import { Box } from "@chakra-ui/react";

import TextInfo from "../TextInfo/TextInfo";
import ActionBtn from "./components/ActionBtn";

import rl0 from "../../../../../assets/builder/rotate_10_l_1.svg";
import rl1 from "../../../../../assets/builder/rotate_10_l_2.svg";
import rr0 from "../../../../../assets/builder/rotate_10_r_1.svg";
import rr1 from "../../../../../assets/builder/rotate_10_r_2.svg";
import rR0 from "../../../../../assets/builder/reset_rotate_1.svg";
import rR1 from "../../../../../assets/builder/reset_rotate_2.svg";
import fh0 from "../../../../../assets/builder/flip_h_1.svg";
import fh1 from "../../../../../assets/builder/flip_h_2.svg";
import fv0 from "../../../../../assets/builder/flip_v_1.svg";
import fv1 from "../../../../../assets/builder/flip_v_2.svg";
import zi0 from "../../../../../assets/builder/zoom_in_1.svg";
import zi1 from "../../../../../assets/builder/zoom_in_2.svg";
import zo0 from "../../../../../assets/builder/zoom_out_1.svg";
import zo1 from "../../../../../assets/builder/zoom_out_2.svg";
import d0 from "../../../../../assets/builder/duplicate_1.svg";
import d1 from "../../../../../assets/builder/duplicate_2.svg";

import {
	onObjectRotate,
	onObjectRotateReset,
	onObjectFlip,
	onMagnifier,
	onCloneObject,
} from "../../utils/objectActions";

function ActionMenu({ activeObject, canvas }) {
	return (
		<>
			<Box display="flex" mt="20px">
				<ActionBtn
					activeObject={activeObject}
					state0={rl0}
					state1={rl1}
					action={() => {
						onObjectRotate(canvas, "left");
					}}
				/>
				<ActionBtn
					activeObject={activeObject}
					state0={rr0}
					state1={rr1}
					action={() => {
						onObjectRotate(canvas, "right");
					}}
				/>
				<ActionBtn
					activeObject={activeObject}
					state0={rR0}
					state1={rR1}
					action={() => {
						onObjectRotateReset(canvas);
					}}
				/>
				<ActionBtn
					activeObject={activeObject}
					state0={fh0}
					state1={fh1}
					action={() => {
						onObjectFlip(canvas, "y");
					}}
				/>
				<ActionBtn
					activeObject={activeObject}
					state0={fv0}
					state1={fv1}
					action={() => {
						onObjectFlip(canvas, "x");
					}}
				/>
				<ActionBtn
					activeObject={activeObject}
					state0={zi0}
					state1={zi1}
					action={() => {
						onMagnifier(canvas, "in");
					}}
				/>
				<ActionBtn
					activeObject={activeObject}
					state0={zo0}
					state1={zo1}
					action={() => {
						onMagnifier(canvas, "out");
					}}
				/>
				<ActionBtn
					activeObject={activeObject}
					state0={d0}
					state1={d1}
					action={() => {
						onCloneObject(canvas, activeObject);
					}}
				/>
			</Box>
			<TextInfo txt="Layoutwerkzeuge" active={activeObject} />
		</>
	);
}

export default ActionMenu;
