import { galleryActions } from "../../actionTypes";

const initialState = {
	gallery: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case galleryActions.CREATE_GALLERY:
			return { ...initialState, fetching: true };
		case galleryActions.CREATE_GALLERY_FULFILLED:
			return {
				...state,
				fetched: true,
				fetching: false,
				error: false,
				gallery: action.payload,
			};
		case galleryActions.CREATE_GALLERY_REJECTED:
			return {
				...state,
				fetched: false,
				fetching: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
export default Reducer;
