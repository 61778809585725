import React from "react";
import { FormControl, FormLabel, FormErrorMessage, Box, Tooltip } from "@chakra-ui/react";

import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import { getSubCategories } from "../../../../api/libs/events";

export default function SubCategories({ formik, column }) {
	const lang = useSelector(state => state.languageProvider.language);

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		if (searchQuery && searchQuery.length < 2) {
			return null;
		}
		const response = await getSubCategories(formik.values.category_id.id, page);

		if (formik.values.sub_category_id && page === 1) {
			formik.setFieldValue("sub_category_id", response.data[0]);
		}
		return {
			options: response.data.data,
			hasMore: response.next_page_url,
			additional: {
				page: searchQuery ? 2 : page + 1,
			},
		};
	};

	const onChange = option => {
		formik.setFieldValue("sub_category_id", option);
	};

	return (
		<Tooltip label={formik.errors.sub_category_id} hasArrow arrowSize={15} bg="red.600">
			<FormControl
				display="grid"
				gridTemplateColumns={
					column ? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "2fr 8fr", "2fr 8fr"]
				}
				alignItems="center"
				isInvalid={formik.errors.sub_category_id && formik.touched.sub_category_id}
				id="sub_category_id"
			>
				<FormLabel
					_focus={{
						backgroundColor: "#F2F1F1",
						borderColor: formik.errors ? "red" : "#1274BC",
					}}
				>
					{lang === "de" ? "Unterkategorie" : "Sub category"}
				</FormLabel>

				<Box display="flex" flexDirection="column">
					<AsyncPaginate
						key={JSON.stringify(formik.values.category_id)}
						theme={theme => ({
							...theme,
							borderRadius: 0,
						})}
						isDisabled={!formik.values.category_id}
						value={formik.values.sub_category_id || ""}
						loadOptions={loadOptions}
						getOptionValue={option => option.id}
						getOptionLabel={option => option.name}
						onChange={onChange}
						placeholder={
							formik.values.sub_category_id !== ""
								? formik.values.sub_category_id
								: "Auswählen Unterkategorie"
						}
						additional={{
							page: 1,
						}}
					/>

					{/* <FormErrorMessage
          position={column ? "absolute" : "relative"}
          bottom="-1.2em"
        >
          {formik.errors.sub_category_id}
        </FormErrorMessage> */}
				</Box>
			</FormControl>
		</Tooltip>
	);
}
