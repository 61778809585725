import React from "react";
import { calculateDimensions } from "../../../../../utils/dimensions";

function Player({ media }) {
	let dimensions = calculateDimensions();
	return (
		<video
			src={media}
			width={dimensions.squere.height}
			height={dimensions.squere.height}
			controls
			muted
			autoPlay={false}
			loop
			style={{
				maxHeight: dimensions.squere.height,
				maxWidth: dimensions.squere.height,
			}}
		>
			<source src={media} />
		</video>
	);
}

export default Player;
