import React from "react";
import { Box } from "@chakra-ui/react";
import Content from "./Content";
import { useSelector } from "react-redux";
import TabHeader from "./TabHeader";

const Tab = ({
	options,
	active,
	setActive,
	fields,
	formik,
	submitClicked,
	height = "70vh",
	...rest
}) => {
	const lang = useSelector(state => state.languageProvider.language);

	return (
		<Box display="flex" flexDirection="column" overflowY="hidden" {...rest}>
			<TabHeader
				submitClicked={submitClicked}
				formik={formik}
				fields={fields}
				active={active}
				options={options}
				setActive={setActive}
				lang={lang}
			/>
			<Content options={options} lang={lang} active={Object.keys(options)[active]}></Content>
		</Box>
	);
};

export default Tab;
