import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "../components/shared/Loading";
import NewCompany from "../components/new-company";
import SelectCompany from "../components/select-company";
import Wizard from "../components/auth/register/components/Wizard";
import WizardNew from "../components/auth/register/components/Wizzardnew";
function CompanyRouter({ data }) {
	if (data.length === 0) {
		return (
			<Wizard>
				<Switch>
					<Route exact path="/create-company">
						{props => <NewCompany welcome={true} />}
					</Route>
					<Redirect to="/create-company" />
				</Switch>
			</Wizard>
		);
	}
	if (data.length > 1) {
		return (
			<Switch>
				<Route path="/select-company" component={SelectCompany} />
				<Redirect to="/select-company" />
			</Switch>
		);
	}
}

export default CompanyRouter;
