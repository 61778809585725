import { fabric } from "fabric";
import { uploadBase64, uploadLogo } from "../../../../api/libs/upload";
import { onUploadTemplate } from "../../../../utils/upload";
import { dimensions, getDimensions } from "../static/canvasDimensions";
import { onDrawBorders, onDrawSnapHelperLines } from "./fabricActions";

// import { useSlides } from "../store";

export const initCanvas = (variant, refer) => {
	let canvas = new fabric.Canvas(`c-${variant}`, {
		preserveObjectStacking: false,
		containerClass: "canvas-container",
	});
	const resDim = getDimensions(variant, refer);
	canvas.setDimensions({ width: resDim.width, height: resDim.height });
	readyCanvas(canvas, variant);
	onDrawBorders();
	onDrawSnapHelperLines(canvas);
	return canvas;
};

export const addBg = canvas => {
	if (canvas) {
		canvas.setBackgroundColor(
			{
				source: require("../../../../assets/builder/transparent-pattern.png").default,
				repeat: "repeat",
				crossOrigin: "anonymous",
				helper: true,
			},
			() => {
				canvas.renderAll();
			},
		);
	}
};

export const addMask = canvas => {
	canvas.setOverlayImage(
		require("../../../../assets/builder/mask.svg").default,
		() => {
			canvas.renderAll();
		},
		{
			width: 540,
			height: 540,
			crossOrigin: "anonymous",
			helper: true,
		},
	);
};

const removeBg = canvas => {
	if (canvas) {
		canvas.setBackgroundColor(null, () => canvas.renderAll());
	}
};

const removeMask = canvas => {
	if (canvas) {
		canvas.setOverlayImage(null, () => canvas.renderAll());
	}
};

const readyCanvas = (canvas, variant) => {
	addBg(canvas);
	if (variant === "logo") {
		addMask(canvas);
	}
};

const prepareCanvas = (canvas, variant) => {
	removeBg(canvas);
	if (variant === "logo") {
		removeMask(canvas);
	}
};

export const changeOrientation = (canvas, target) => {
	if (canvas) {
		for (let d in dimensions) {
			if (dimensions[d].name === target) {
				canvas.forEachObject(obj => {
					if (obj.id === "vertical_snap") {
						obj.set("x1", dimensions[d].width / 2);
						obj.set("y1", 0);
						obj.set("x2", dimensions[d].width / 2);
						obj.set("y2", dimensions[d].height);
					} else if (obj.id === "horizontal_snap") {
						obj.set("x1", 0);
						obj.set("y1", dimensions[d].height / 2);
						obj.set("x2", dimensions[d].width);
						obj.set("y2", dimensions[d].height / 2);
					}
				});
				canvas
					.setDimensions({
						width: dimensions[d].width,
						height: dimensions[d].height,
					})
					.renderAll();
			}
		}
	}
};

export const canvasToJson = canvas => {
	return canvas.toJSON(["id", "picker_x", "picker_y", "selectable", "evented", "helper", "uuid"]);
};

export const jsonToCanvas = (canvas, template, callback, setState, renderLve = true) => {
	if (canvas && template) {
		canvas.loadFromJSON(template, () => {
			if (renderLve) {
				setState(state => {
					state.liveImg = canvas.toDataURL({ format: "png", multiplier: 1 });
				});
			}
			callback();
			canvas.renderAll();
		});
	}
};

export const canvasToPng = (canvas, ratio = 2) => {
	if (canvas) {
		return canvas.toDataURL({ format: "png", multiplier: ratio });
	}
};

export const prevPng = canvas => {
	if (canvas) {
		return canvas.toDataURL({ format: "png", multiplier: 1 });
	}
};

export const exportPng = (canvas, variant) => {
	if (canvas) {
		prepareCanvas(canvas, variant);
		let base64Png = canvasToPng(canvas);
		readyCanvas(canvas, variant);
		return base64Png;
	}
};

export const drawSnapLine = (canvas, type, opacity) => {
	canvas.forEachObject(obj => {
		if (obj.id === type) {
			obj.set("evented", false);
			obj.set("selectable", false);
			obj.set("opacity", opacity);
		}
	});
};

const previewLogo = (slides, canvas, formik) => {
	if (slides[0].isEdited) {
		let newPng = exportPng(canvas, "logo");
		formik.setFieldValue("logo", newPng);
	}
};

const previewGallery = (slides, activeSlide, canvas, formik) => {
	let newArr = [];
	for (let [index, slide] of slides.entries()) {
		if (slide.isVideo) {
			newArr.push(slide.video);
		} else if (slide.isPano) {
			newArr.push(slide.pano);
		} else if (slide.isEdited) {
			if (index === activeSlide) {
				newArr.push(exportPng(canvas, "gallery"));
			} else newArr.push(slide.exPng);
		} else {
			if (slide.png) {
				newArr.push(slide.png);
			}
		}
	}
	formik.setFieldValue("gallery", newArr);
};

export const preview = (slides, canvas, variant, activeSlide, formik) => {
	if (variant === "logo") {
		previewLogo(slides, canvas, formik);
	} else {
		previewGallery(slides, activeSlide, canvas, formik);
	}
};

const toFile = async url => {
	let file = await fetch(url).then(r => r.blob());
	return file;
};

const createPreview = async canvas => {
	// prepareCanvas(canvas, variant);
	let prev64 = canvasToPng(canvas);
	const formData = new FormData();
	formData.append("image[0]", prev64);
	let {
		data: { images },
	} = await uploadBase64(formData);
	// readyCanvas(canvas, variant);
	return images[0];
};

const createTemplateInstance = async canvas => {
	let template = canvasToJson(canvas);
	let objects = template.objects;
	for (let [i, obj] of objects.entries()) {
		if (obj.src?.includes("blob:http")) {
			let img = await toFile(obj.src);
			const formData = new FormData();
			formData.append("image[0]", img, true);
			let res = await uploadLogo(formData, true);
			template.objects[i].src = res.data.images[0];
			template.objects[i].crossOrigin = "anonymous";
		}
	}
	return template;
};

export const saveTemplate = async (canvas, variant, setState, dispatch) => {
	setState(state => {
		state.templateLoading = true;
	});
	let preview = await createPreview(canvas, variant);
	let original = await createPreview(canvas, variant);
	let template = await createTemplateInstance(canvas);
	onUploadTemplate(
		template,
		preview,
		original,
		variant,
		bool => {
			setState(state => {
				state.templateLoading = bool;
			});
		},
		dispatch,
	);
};
