import { newsActions } from "../../actionTypes";
import { deleteNews as deleteNewsFunction } from "../../../api/libs/news";
import toast from "react-hot-toast";
import { fetchNewses } from ".";

export const deleteNews = id => {
	return async dispatch => {
		dispatch({
			type: newsActions.DELETE_NEWS,
		});
		deleteNewsFunction(id)
			.then(res => {
				dispatch({
					type: newsActions.DELETE_NEWS_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully deleted news");

				dispatch(fetchNewses());
			})
			.catch(err => {
				dispatch({
					type: newsActions.DELETE_NEWS_REJECTED,
					payload: err.response,
				});
			});
	};
};
