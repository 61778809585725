import React, { Suspense, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ToastBar, Toaster } from "react-hot-toast";
import { toasterConfig, toastOptions } from "./toaster.conf";
import Loading from "./components/shared/Loading";
import store from "./store";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { theme } from "./chakra.conf";

const rootElement = document.getElementById("app");
const root = createRoot(rootElement);

const version = "1.1.6";
root.render(
	<StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<ChakraProvider theme={theme}>
					<Suspense fallback={<Loading />}>
						<Toaster toastOptions={toastOptions} toasterConfig={toasterConfig}>
							{t => (
								<ToastBar
									toast={t}
									style={{
										...t.style,
										animation: t.visible ? "custom-enter 1s ease" : "custom-exit 1s ease",
									}}
								/>
							)}
						</Toaster>
						<Box
							position="absolute"
							bg="green"
							color="#FFF"
							p="0.1em"
							top="0"
							right="0"
							zIndex="9999"
						>
							{version}
						</Box>
						<App />
					</Suspense>
				</ChakraProvider>
			</Provider>
		</BrowserRouter>
	</StrictMode>,
);
