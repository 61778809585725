import { apiCallAc } from "..";

const formatData = data => {
	let images = [];
	for (let i = 0; i < data.length; i++) {
		if (data[i].includes(".mp4")) {
			images.push({ video: data[i] });
		} else {
			images.push({ image: data[i] });
		}
	}
	return {
		data: images,
	};
};

export function createGallery(id, data, instagram_page_share) {
	let body = formatData(data);
	return apiCallAc.post(
		`/dashboard/gallery/${id}?instagram_page_share=${instagram_page_share}`,
		body,
	);
}
/*
   data = {
	   "images": [
			{
				"image":"n7sRmDIIY2yME0SOwtHoSz7nVilPN9nxLUeyQyaQ.jpeg"
			}
	   ]
   }
 */
