import React from "react";
import { Box, Text, Divider, Button, Image } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { content } from "../components/content";
import TabHeaderLight from "../../../shared/tabs/TabHeaderLight";
import { getQR } from "../../../../utils/imgUri";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { deleteCoupon } from "../../../../store/actions/coupons";
import { handleScroll } from "../../../../utils/scroll";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../../shared/table";

function Layout({
	header,
	lang,
	coupons,
	setFetchCouponsType,
	fetchCouponsType,
	loading,
	next_page_url,
	current_page,
	fetchCoupons,
	fetched,
}) {
	const dispatch = useDispatch();

	const t = content[lang];
	const options = ["published", "archive", "deleted"];

	return (
		<Box
			paddingX="20px"
			display="flex"
			flexDirection="column"
			flexGrow="1"
			overflowY="auto"
			position="absolute"
			bottom="0"
			right="0"
			width="100%"
			top={["3em", "0"]}
			pb={["5em", "1em"]}
			onScroll={e =>
				handleScroll(
					e,
					() => dispatch(fetchCoupons(fetchCouponsType, current_page + 1)),
					current_page,
					loading,
					next_page_url,
				)
			}
		>
			<Box py="3" display="flex" justifyContent="space-between" gridGap="1em">
				<Box>
					<Box mb="1">
						<Text fontSize="24px">{header}</Text>
					</Box>
					<Box w="40px">
						<Divider borderBottom="4px" opacity="1" />
					</Box>
				</Box>
				<Link to="/coupons/new">
					<Box
						border="2px solid #000"
						p={["0.5em", "1em"]}
						cursor="pointer"
						transition="100ms all ease"
						_hover={{ transform: "scale(1.05)" }}
					>
						<Box
							p="0"
							backgroundColor="transparent"
							_hover={{
								backgroundColor: "#transparent",
							}}
							_first={{ _hover: { color: "green" } }}
							_focus={{ border: "none" }}
							color="#D7D8D7"
							display="flex"
							alignItems="center"
						>
							<FontAwesomeIcon icon="plus" color="green" />
							<Text color="#000" _last={{ _hover: { color: "#000" } }} ml="2">
								{t.add_news}
							</Text>
						</Box>
					</Box>
				</Link>
			</Box>

			<TabHeaderLight options={options} onChange={setFetchCouponsType} />
			<Box display="flex" flexGrow="1" pt="30px" flexDirection="column">
				<Table>
					<Thead>
						<Tr>
							<Th>{t.title}</Th>
							<Th textAlign="center">{t.coupon_code}</Th>
							<Th textAlign="center">{t.discount}</Th>
							<Th>{t.info}</Th>
							<Th textAlign="center">{t.used_amount}</Th>
							<Th textAlign="center">{t.end_at}</Th>
							{fetchCouponsType !== "deleted" ? <Th textAlign="center">{t.edit}</Th> : null}
							{fetchCouponsType !== "deleted" ? <Th textAlign="center">{t.delete}</Th> : null}
						</Tr>
					</Thead>
					<Tbody>
						{fetched && coupons.data.length
							? coupons.data.map(item => (
									<Tr key={item.id}>
										<Td position="relative">
											{item.title}
											<Text
												position="absolute"
												top="0px"
												left={["auto", "0"]}
												right={["0", "auto"]}
												fontSize="0.6em"
												display="flex"
												bg="#1A1919"
												borderRadius="2%"
												py="0.3em"
												px="1em"
												color="#FFF"
												fontFamily="Segoe UI Bold"
											>
												{item.visible_for}
											</Text>
										</Td>
										<Td>
											<Box textAlign="center" display="flex" justifyContent="center">
												<Image
													title={item.coupon_code}
													alt={item.coupon_code}
													width="10ch"
													src={getQR(item.qr_code) || "ტესტ"}
												/>
											</Box>
										</Td>
										<Td textAlign="center">
											<Box m="0">
												{item.previous_price && (
													<Text m="0" as="del" color="#797A7C" fontWeight="600">
														{item.previous_price} €
													</Text>
												)}
												<Text
													m="0"
													backgroundColor="#E9FCDE"
													color="#318101"
													fontWeight="800"
													fontSize="0.8em"
												>
													{item.percentage_value || item.money_value}{" "}
													{item.type === "percentage"
														? "% OFF"
														: item.type === "free"
														? "FREE"
														: "€ OFF"}
												</Text>
												{item.new_price && (
													<Text m="0" color="#318101" fontWeight="600">
														{item.new_price} €
													</Text>
												)}
											</Box>
										</Td>
										<Td hide>
											<Text
												display="flex"
												fontSize="0.8em"
												bg="#AFCB2E"
												borderRadius="2%"
												py="0.3em"
												px="1em"
												color="#FFF"
												fontFamily="Segoe UI Bold"
											>
												Users: {item.user_count}
											</Text>
											<Text
												display="flex"
												fontSize="0.8em"
												bg="#0E65AE"
												borderRadius="2%"
												py="0.3em"
												px="1em"
												color="#FFF"
												fontFamily="Segoe UI Bold"
											>
												News: {item.news_count}
											</Text>
											<Text
												display="flex"
												fontSize="0.8em"
												bg="#1B1919"
												borderRadius="2%"
												py="0.3em"
												px="1em"
												color="#FFF"
												fontFamily="Segoe UI Bold"
											>
												Locations: {item.custom_location_count}
											</Text>
										</Td>
										<Td textAlign="center">
											{item.used_amount || "0"}
											{" / "}
											{item.limited_amount || "∞"}
										</Td>
										<Td textAlign="center">{item.post_end_at || t.no_end_date}</Td>

										{fetchCouponsType !== "deleted" ? (
											<Td textAlign="center">
												<Link to={`/coupons/edit/${item.id}`}>
													<Button
														p="0"
														backgroundColor="transparent"
														_hover={{
															backgroundColor: "#transparent",
															color: "#77B93E",
														}}
														_focus={{ border: "none" }}
														color="#D7D8D7"
													>
														<FontAwesomeIcon size="2x" icon="pencil-alt" />
													</Button>
												</Link>
											</Td>
										) : null}

										{fetchCouponsType !== "deleted" ? (
											<Td textAlign="center">
												<PrimaryButton
													onClick={() => dispatch(deleteCoupon(item.id))}
													prompt={true}
													labelType="icon"
													label={<FontAwesomeIcon size="2x" icon="times" />}
													type="circle"
													hoverColor="#F58121"
												></PrimaryButton>
											</Td>
										) : null}
									</Tr>
							  ))
							: null}
						{loading && (
							<Tr>
								{new Array(fetchCouponsType === "deleted" ? 7 : 8)
									.fill(undefined)
									.map((item, id) => (
										<Td key={id}>
											<Skeleton height="20px" />
										</Td>
									))}
							</Tr>
						)}
					</Tbody>
				</Table>
				{fetched && !coupons.data.length ? (
					<Box textAlign="center" py="1em">
						{t.no_data}
					</Box>
				) : null}
			</Box>
		</Box>
	);
}

export default Layout;
