import React from "react";
import Layout from "./components/Layout";
import Content from "./components/Content";

import { useSelector, useDispatch } from "react-redux";
import { fetchCompany } from "../../store/actions";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";

function Company({ setPage }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const companies = useSelector(state => state.fetchCompanies.companies.data) || [];
	const fetched = useSelector(state => state.fetchCompany.fetched);
	const lang = useSelector(state => state.languageProvider.language);

	const handleSelect = id => {
		// localStorage.removeItem("activeInvoice");
		history.push("/invoices");
		// setPage(1);
		dispatch(fetchCompany(id)).then(res => {
			toast.success(`Selected company: ${res.name}`);
		});
	};

	// useEffect(() => {
	//   if (fetched) {
	//     history.push("/invoices");
	//   }
	// }, [fetched]);

	return (
		<>
			<Layout lang={lang}>
				<Content
					setPage={setPage}
					lang={lang}
					fetched={fetched}
					companies={companies}
					handleSelect={handleSelect}
				/>
			</Layout>
		</>
	);
}

export default Company;
