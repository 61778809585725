import { Button } from "@chakra-ui/button";
import React from "react";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	PopoverArrow,
	Box,
	Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PrimaryButton({
	onClick,
	disabled,
	type,
	bg = "#A9A9AD",
	label,
	hoverColor = "#1274BC",
	prompt = false,
	labelType,
	iconName,
	size,
	...rest
}) {
	const lang = useSelector(state => state.languageProvider.language);

	return !prompt ? (
		<Button
			size={size}
			outline="none"
			type={type}
			bg={bg}
			color="white"
			_hover={{ bg: labelType === "icon" ? "transparent" : hoverColor }}
			borderRadius="3em"
			onClick={onClick}
			disabled={disabled}
			_focus={{ outline: "none" }}
			height={type === "circle" ? "3em" : "2em"}
			width={type === "circle" ? "3em" : "auto"}
			{...rest}
		>
			{labelType === "icon" ? <FontAwesomeIcon size="2x" color={bg} icon="times-circle" /> : label}
		</Button>
	) : (
		<Popover placement="top" isLazy>
			{({ isOpen, onClose }) => (
				<>
					<PopoverTrigger>
						<Button
							outline="none"
							type={type}
							height={type === "circle" ? "3em" : "2em"}
							width={type === "circle" ? "3em" : "auto"}
							bg={bg}
							color="white"
							_hover={{ bg: hoverColor }}
							borderRadius="3em"
							disabled={disabled}
							_focus={{ outline: "none" }}
							{...rest}
						>
							{label}
						</Button>
					</PopoverTrigger>
					<PopoverContent
						_focus={{ border: "none" }}
						width="auto"
						p="2"
						borderRadius="20"
						backgroundColor="#272A2A"
						color="#FFF"
					>
						<PopoverArrow backgroundColor="#272A2A" />

						<PopoverBody
							display="flex"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
						>
							<Text>
								{lang === "de" ? "inhalte zurücksetzten?" : "Are you sure to reset content?"}
							</Text>
							<Box mt="3">
								<Button
									backgroundColor="#9F9F9E"
									color="#1B1919"
									px="4"
									borderRadius="20"
									py="1"
									m="0"
									mr="2"
									height="auto"
									onClick={() => {
										onClick();
										onClose();
									}}
									_focus={{ border: "none" }}
									_hover={{ backgroundColor: "#FFF" }}
								>
									{lang === "de" ? "löschen" : "Clear"}
								</Button>
								<Button
									backgroundColor="#9F9F9E"
									color="#1B1919"
									px="4"
									borderRadius="20"
									py="1"
									m="0"
									height="auto"
									onClick={onClose}
									_focus={{ border: "none" }}
									_hover={{ backgroundColor: "#FFF" }}
								>
									{lang === "de" ? "abbrechen" : "Abort"}
								</Button>
							</Box>
						</PopoverBody>
					</PopoverContent>
				</>
			)}
		</Popover>
	);
}
