import React, { useEffect, useState } from "react";
import { Box, Text, Divider, TableCaption, TableContainer, useDisclosure } from "@chakra-ui/react";
import { handleScroll } from "../../../../utils/scroll";
import {
	fetchPaypalSubscription,
	fetchPaypalSubscriptions,
	fetchStripeSubscription,
	fetchStripeSubscriptions,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import TabHeaderLight from "../../../shared/tabs/TabHeaderLight";
import PaypalList from "./PaypalList";
import StripeList from "./StripeList";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Subscription from "../../../Subscription";
import Loading from "../../../shared/Loading";
function Layout({
	header,
	stripeList,
	paypalList,
	loading,
	paypal_next_page_url,
	paypal_current_page,
	stripe_next_page_url,
	stripe_current_page,
	onClose,
	isOpen,
	onOpen,
}) {
	const { isOpen: isPlanOpen, onOpen: onPlanOpen, onClose: onPlanClose } = useDisclosure();
	const [planId, setPlanId] = useState({
		plan_id: "",
		plan_price_id: "",
	});
	const [id, setId] = useState("");
	const [paymentType, setPaymentType] = useState(paypalList?.data?.length ? "paypal" : "stripe");
	const dispatch = useDispatch();

	const { subscription: paypalData, fetching: fetchingPaypal } = useSelector(
		state => state.fetchPaypalSubscription,
	);

	const { subscription: stripeData, fetching: fetchingStripe } = useSelector(
		state => state.fetchStripeSubscription,
	);

	useEffect(() => {
		if (paymentType === "paypal") dispatch(fetchPaypalSubscription(id));
		else dispatch(fetchStripeSubscription(id));
	}, [id]);
	const options =
		paypalList?.data?.length && stripeList?.data?.length
			? ["paypal", "stripe"]
			: paypalList?.data?.length
			? ["paypal"]
			: ["stripe"];

	return (
		<Box
			paddingX="20px"
			display="flex"
			flexDirection="column"
			flexGrow="1"
			overflowY="scroll"
			onScroll={e =>
				handleScroll(
					e,
					() =>
						paymentType === "paypal"
							? dispatch(fetchPaypalSubscriptions(paypal_current_page + 1))
							: dispatch(fetchStripeSubscriptions(paypal_current_page + 1)),
					paymentType === "paypal" ? paypal_current_page : stripe_current_page,
					loading,
					paymentType === "paypal" ? paypal_next_page_url : stripe_next_page_url,
				)
			}
			position="absolute"
			bottom="0"
			right="0"
			width="100%"
			top={["3em", "0"]}
			pb={["5em", "1em"]}
		>
			<Box py="3" display="flex" justifyContent="space-between" gridGap="1em">
				<Box>
					<Box mb="1">
						<Text fontSize="24px">Payments</Text>
					</Box>
					<Box w="40px">
						<Divider borderBottom="4px" opacity="1" />
					</Box>
				</Box>
				<Box
					onClick={() => {
						setPlanId({
							plan_id: "",
							plan_price_id: "",
						});
						onPlanOpen();
					}}
				>
					<Box
						alignSelf="center"
						border="2px solid #000"
						p={["0.5em", "1em"]}
						cursor="pointer"
						transition="100ms all ease"
						_hover={{ transform: "scale(1.05)" }}
					>
						<Box
							p="0"
							backgroundColor="transparent"
							_hover={{
								backgroundColor: "#transparent",
							}}
							_first={{ _hover: { color: "green" } }}
							_focus={{ border: "none" }}
							color="#D7D8D7"
							display="flex"
							alignItems="center"
						>
							<FontAwesomeIcon icon="plus" color="green" />
							<Text color="#000" _last={{ _hover: { color: "#000" } }} ml="2">
								Add Plan
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<TabHeaderLight options={options} onChange={setPaymentType} />
			{paymentType === "paypal" ? (
				<PaypalList
					data={paypalList}
					onOpen={onOpen}
					setId={setId}
					setPlanId={setPlanId}
					onPlanOpen={onPlanOpen}
				/>
			) : (
				<StripeList
					data={stripeList}
					onOpen={onOpen}
					setId={setId}
					setPlanId={setPlanId}
					onPlanOpen={onPlanOpen}
				/>
			)}
			<Modal onClose={onClose} size="full" isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Test</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{fetchingPaypal || fetchingStripe ? (
							<Loading height={true} />
						) : (
							<>
								<Table variant="striped">
									<Tbody>
										<Tr>
											<Td>Name</Td>
											<Td>
												{paymentType === "paypal"
													? paypalData?.plan?.description
													: stripeData?.data?.[0]?.lines_in_pdf?.data?.[0]?.description}
											</Td>
										</Tr>

										<Tr>
											<Td>Price</Td>
											<Td>
												{paymentType === "paypal"
													? paypalData?.plan_price?.cost
													: stripeData?.data?.[0].total}
											</Td>
										</Tr>
										{paymentType === "paypal" ? (
											<Tr>
												<Td>Next payment</Td>
												<Td>
													{paypalData?.response_from_paypal_server?.billing_info?.next_billing_time}
												</Td>
											</Tr>
										) : null}
									</Tbody>
								</Table>
								<Box>
									{paymentType === "stripe" ? (
										<TableContainer>
											<Table variant="simple">
												<TableCaption>Invoices</TableCaption>
												<Thead>
													<Tr>
														<Th>Status</Th>
														<Th>Price</Th>
														<Th>Hosted Invoice</Th>
														<Th>Invoice pdf</Th>
														<Th>Date</Th>
													</Tr>
												</Thead>
												<Tbody>
													{stripeData?.data?.map(item => (
														<Tr>
															<Td>{item.status}</Td>
															<Td>{item.total}</Td>
															<Td>
																<PrimaryButton
																	label="HOSTED INVOICE"
																	onClick={() => window.open(item.hosted_invoice_url, "_blank")}
																/>
															</Td>
															<Td>
																<PrimaryButton
																	label="PDF"
																	onClick={() => window.open(item.invoice_pdf, "_blank")}
																/>
															</Td>
															<Td>{item.date}</Td>
														</Tr>
													))}
												</Tbody>
											</Table>
										</TableContainer>
									) : null}
								</Box>
							</>
						)}
					</ModalBody>

					<ModalFooter>
						<Button onClick={onClose}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal onClose={onPlanClose} size="full" isOpen={isPlanOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Plan</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Subscription active={planId} type={paymentType} onPlanClose={onPlanClose} />
					</ModalBody>

					<ModalFooter>
						<Button onClick={onPlanClose}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default Layout;
