import { userActions } from "../../actionTypes";
import { getFacebookToken } from "../../../api/libs/user";

export const fetchFacebookToken = () => {
	return async dispatch => {
		dispatch({
			type: userActions.GET_FACEBOOK_TOKEN_FETCHING,
		});
		getFacebookToken()
			.then(res => {
				dispatch({
					type: userActions.GET_FACEBOOK_TOKEN_FULFILLED,
					payload: res,
				});

				window.open(res.data.redirect_url);
			})
			.catch(err => {
				dispatch({
					type: userActions.GET_FACEBOOK_TOKEN_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
