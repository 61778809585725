import React from "react";
import { Box, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from "@chakra-ui/react";

const CustomSlider = ({ canvas, val, setVal, color, max = 100, type, isDisabled }) => {
	return (
		<Box h="100%" display="flex" justifyContent="center" alignItems="center" w="100%" px="20px">
			<Slider
				aria-label="slider-ex-1"
				defaultValue={val}
				value={val}
				min={0}
				max={max}
				onChange={val => {
					setVal(val);
				}}
				onChangeEnd={() => {
					canvas.fire("event:cache", {
						eName: `change-${type}`,
						from: "picker/sliders",
					});
				}}
				isDisabled={isDisabled}
			>
				<SliderTrack bg={"linear-gradient(to right, black,rgba(0,0,0,0))"} h="8px">
					<SliderFilledTrack bg="transperant" />
				</SliderTrack>
				<SliderThumb bg={color} borderColor={"rgba(0,0,0,0.5)"} borderWidth={1} h="20px" w="20px" />
			</Slider>
		</Box>
	);
};

export default CustomSlider;
