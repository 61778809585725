import { chatsActions } from "../../actionTypes";

const initialState = {
	messages: {
		data: [],
	},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case chatsActions.FETCH_MESSAGES: {
			return { ...initialState, fetching: true };
		}

		case chatsActions.FETCH_MESSAGES_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				messages: action.payload,
			};
		}

		case chatsActions.ADD_MESSAGE_TOP_START: {
			return {
				...state,
				fetching: true,
			};
		}

		case chatsActions.ADD_MESSAGE_TOP: {
			return {
				...state,

				fetching: false,
				messages: {
					...state.messages,
					...action.payload,
					data: [...state.messages.data, ...action.payload.data],
				},
			};
		}

		case chatsActions.ADD_MESSAGE: {
			return {
				...state,
				messages: {
					...state.messages,
					data: [
						{
							message: action.payload.message,
							title: action.payload.title,
							sender_mobile_user_id: action.payload.mobile_id,
							sender_dashboard_user_id: action.payload.dashboard_id,
						},
						...state.messages.data,
					],
				},
			};
		}

		case chatsActions.FETCH_MESSAGES_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}
		case chatsActions.UPDATE_MESSAGE: {
			return {
				...state,
				messages: {
					...state.messages,
					data: action.payload,
				},
			};
		}

		default:
	}

	return state;
};

export default Reducer;
