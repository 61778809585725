import { templateActions } from "../../actionTypes";
import { getTemplates } from "../../../api/libs/templates";

export const fetchTemplates = () => {
	return async dispatch => {
		dispatch({
			type: templateActions.FETCH_TEMPLATES,
		});
		getTemplates()
			.then(res => {
				dispatch({
					type: templateActions.FETCH_TEMPLATES_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: templateActions.FETCH_TEMPLATES_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
