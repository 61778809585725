import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { triggerBase64Download } from "react-base64-downloader";

import { useSlides } from "../../../store";

import Layout from "./components/Layout";
import Container from "./components/Container";
import CanvasButton from "./components/CanvasButton";
import MenuButton from "./components/MenuButton";

import orPor from "../../../../../../assets/builder/orientation/orPor.svg";
import orLand from "../../../../../../assets/builder/orientation/orLand.svg";

import { getDimensions } from "../../../static/canvasDimensions";
import { onInputImg } from "../../../utils/common";
import { downloadFile } from "../../../utils/download";
import {
	canvasToJson,
	changeOrientation,
	exportPng,
	preview,
	saveTemplate,
} from "../../../utils/canvas";

function CanvasOverlay({ canvas, variant, refer, state, setState, fileInput, formik }) {
	const dispatch = useDispatch();
	const lang = useSelector(state => state.languageProvider.language);
	const slides = useSlides(state => state[variant]);
	const toogleOrientation = useSlides(state => state.toogleOrientation);
	const modifySlide = useSlides(state => state.modifySlide);

	const handleInput = () => {
		onInputImg(setState, fileInput);
	};

	const onToogleOrientation = () => {
		toogleOrientation(canvas);
	};

	const onPreview = () => {
		preview(slides, canvas, variant, state.activeSlide, formik);
	};
	const onExportPng = () => {
		let newPng = exportPng(canvas, variant);
		triggerBase64Download(newPng, `${variant}-image-${state.activeSlide}`);
	};

	const onExportTmpl = () => {
		let tmpl = canvasToJson(canvas);
		downloadFile({
			data: JSON.stringify(tmpl),
			fileName: "template.json",
			fileType: "text/json",
		});
	};
	const onSaveTemplate = () => {
		saveTemplate(canvas, variant, setState, dispatch);
	};
	const onRemoveVideo = () => {
		if (slides[state.activeSlide].isVideo) {
			modifySlide(state.activeSlide, {
				isVideo: false,
				video: null,
				orientation: getDimensions("gallery", refer).name,
			});
			changeOrientation(canvas, getDimensions("gallery", refer).name);
		}
	};

	const onRemovePano = () => {
		if (slides[state.activeSlide].isPano) {
			modifySlide(state.activeSlide, {
				isPano: false,
				pano: null,
				orientation: getDimensions("gallery", refer).name,
			});
			changeOrientation(canvas, getDimensions("gallery", refer).name);
		}
	};

	return (
		<Layout as={state.activeSlide} slides={slides}>
			<Container>
				{!slides[state.activeSlide].isVideo &&
					!slides[state.activeSlide].isPano &&
					variant !== "logo" && (
						<CanvasButton
							icon={slides[state.activeSlide].orientation === "landscape" ? orPor : orLand}
							li
							action={onToogleOrientation}
						/>
					)}
				{!slides[state.activeSlide].isVideo && !slides[state.activeSlide].isPano && (
					<MenuButton
						lang={lang}
						onPreview={onPreview}
						onExportPng={onExportPng}
						onExportTmpl={onExportTmpl}
						onSaveTemplate={onSaveTemplate}
					/>
				)}
			</Container>
			<Container mt>
				{slides[state.activeSlide].isVideo || slides[state.activeSlide].isPano ? (
					<CanvasButton
						icon="times"
						action={slides[state.activeSlide].isVideo ? onRemoveVideo : onRemovePano}
					/>
				) : (
					<CanvasButton icon="camera" action={handleInput} />
				)}
				<CanvasButton icon="check" action={onPreview} />
			</Container>
		</Layout>
	);
}

export default CanvasOverlay;
