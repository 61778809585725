import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import InvoiceCreation from "../components/invoice/new";
import Invoices from "../components/invoice/list";
import Wizard from "../components/auth/register/components/Wizard";

function UnpaidRouter() {
	return (
		<Wizard>
			<Switch>
				<Route path="/invoices/new" component={InvoiceCreation} />
				<Route path="/invoices" component={Invoices} />
				<Redirect to="/invoices" />
			</Switch>
		</Wizard>
	);
}

export default UnpaidRouter;
