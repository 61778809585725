export const content = {
	en: {
		email_error: "Couldn't send email",
		profile: "Profile",
		name: "Name",
		surname: "Surname",
		email: "Email",
		verified: "Verified",
		unverified: "Unverified",
		phone_number: "Phone number",
		address: "Address",
		country: "Country",
		city: "City",
		street: "Street",
		postal_code: "Postal code",
		logs: "Logs",
		edit_profile: "Edit profile",
		email_sent: "Email sent",
		change_password: "Change password",
		change_email: "Change email",
		new_email: "New email",
		save: "Save",
		cancel: "Cancel",
		facebook: "Facebook",
	},
	de: {
		email_error: "E-Mail konnte nicht gesendet werden",
		profile: "Profil",
		name: "Name",
		surname: "Nachname",
		email: "Email",
		verified: "Verifiziert",
		unverified: "Nicht bestätigt",
		phone_number: "Telefonnummer",
		address: "Die Anschrift",
		country: "Land",
		city: "Stadt",
		street: "Straße",
		postal_code: "Postleitzahl",
		logs: "Protokolle",
		edit_profile: "Profil bearbeiten",
		email_sent: "E-Mail gesendet",
		change_password: "Passwort ändern",
		change_email: "Ändern Sie die E-Mail",
		new_email: "Neue e-mail",
		save: "Speichern",
		cancel: "Abbrechen",
		facebook: "Facebook",
	},
};
