import React from "react";
import { Box } from "@chakra-ui/react";

function Layout({ children }) {
	return (
		<Box display="flex" flexDir="column" h="100%">
			{children}
		</Box>
	);
}

export default Layout;
