import { companyActions } from "../../actionTypes";

const initialState = {
	companies: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case companyActions.FETCH_COMPANIES: {
			return { ...initialState, fetching: true };
		}

		case companyActions.SET_COMPANY: {
			const oldCompany = state.companies.data.filter(item => item.company.id !== action.payload.id);
			return {
				...state,
				companies: {
					data: [
						...state.companies.data.filter(item => item.company.id !== action.payload.id),
						{ company: action.payload, role: oldCompany[0].role },
					],
				},
			};
		}

		case companyActions.FETCH_COMPANIES_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				companies: action.payload,
			};
		}

		case companyActions.FETCH_COMPANIES_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
