import { keywordActions } from "../../actionTypes";
import { attachKeywords as attachKeywordsFunc } from "../../../api/libs/keywords";

export const attachKeywords = (type, id, keywords) => {
	return async dispatch => {
		dispatch({
			type: keywordActions.ATTACH_KEYWORDS,
		});
		attachKeywordsFunc(type, id, keywords)
			.then(res => {
				dispatch({
					type: keywordActions.ATTACH_KEYWORDS_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				dispatch({
					type: keywordActions.ATTACH_KEYWORDS_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
