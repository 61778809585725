import React from "react";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import { content } from "../lang";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";

function Form({ formik, lang }) {
	const t = content[lang];

	return (
		<form
			style={{ display: "flex", flexDirection: "column", width: "100%" }}
			onSubmit={formik.handleSubmit}
		>
			<PrimaryInput column={true} label={t.name} type="text" id="name" formik={formik} />
			<PrimaryInput column={true} label={t.email} type="email" id="email" formik={formik} />

			<PrimaryButton type="submit" label={t.create} mt="1em" />
		</form>
	);
}

export default Form;
