import React from "react";
import { Box } from "@chakra-ui/react";

import ActionBtn from "./buttons/ActionBtn";

import t1 from "../../../../../../../assets/builder/test_1.svg";
import t2 from "../../../../../../../assets/builder/test_2.svg";

function PanelButtons({ state, activePanel, setActivePanel }) {
	return (
		<Box display="flex" justifyContent="space-evenly" borderTop="1px solid #CBCCCB" py="20px">
			<ActionBtn
				label="font editor"
				state0={activePanel === "FONT_EDIT" ? t2 : t1}
				state1={activePanel === "FONT_EDIT" ? t2 : t1}
				state2={t2}
				action={() => {
					setActivePanel("FONT_EDIT");
				}}
			/>
			<ActionBtn
				label="color picker"
				state0={activePanel === "COLOR_PICKER" ? t2 : t1}
				state1={activePanel === "COLOR_PICKER" ? t2 : t1}
				state2={t2}
				action={() => {
					if (state.activeObject !== null && state.activeObject?.type === "textbox") {
						setActivePanel("COLOR_PICKER");
					}
				}}
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
			/>
		</Box>
	);
}

export default PanelButtons;
