import React, { useEffect, useState } from "react";
import Layout from "./../../components/Subscription/components/Layout";
import Form from "./components/Form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getClientSecret } from "../../api/libs/user";
import { useSelector } from "react-redux";
import Loading from "../shared/Loading";
import toast from "react-hot-toast";
import PublicLayout from "../shared/PublicLayout";
import {
	Box,
	Flex,
	Heading,
	ListIcon,
	ListItem,
	Stack,
	Stat,
	StatHelpText,
	StatNumber,
	Text,
	UnorderedList,
} from "@chakra-ui/react";
import { fetchPlans } from "../../store/actions";
import { useDispatch } from "react-redux";
import { MdCheckCircle, MdRemoveCircle } from "react-icons/md";
const stripePromise = loadStripe("pk_test_idXEYEBalDxDzEQJ0KFRk5IH00Tm4xoPbw");

function Checkout({ history, setPage }) {
	const selectedPlan = useSelector(state => state.selectedPlan.selectedPlan);
	const [activePlan] = useState(localStorage.getItem("activePlanID"));
	const [clientSecret, setClientSecret] = useState("");
	const dispatch = useDispatch();
	const [plan, setPlan] = useState(null);
	const labels = {
		image_editor: "Image editor",
		statistics: "Statistics",
		chats: "Chats",
		locations: "Locations / Events / News",
		facebook_pages: "Facebook Pages",
		push_notifications: "Push / Email Notifications",
		company_coupons: "Company Coupons",
		vip_actions: "VIP aktionen",
		shop: "shop",
	};

	const [prices, setPrices] = useState({});

	useEffect(() => {
		dispatch(fetchPlans()).then(res => {
			res.data.some(item => {
				if (item.id === activePlan) {
					setPlan(item);
					return true;
				}
				return false;
			});
		});
	}, []);
	if (!selectedPlan.id) {
		setPage(3);
	}
	useEffect(() => {
		if (selectedPlan.id) {
			getClientSecret({
				plan_price_id: selectedPlan.id,
				invoice_location_id: localStorage.getItem("activeInvoice"),
			})
				.then(res => {
					if (res.data.client_secret) {
						setClientSecret(res.data.client_secret);
					}
				})
				.catch(err => {
					const key = Object.keys(err.response.data.errors)[0];
					toast.error(err.response.data.errors[key]);
					// history.push("/");
				});
		}
	}, [selectedPlan]);

	useEffect(() => {
		if (plan) {
			plan.plan_prices.map(item => {
				if (item.id === localStorage.getItem("selectedPlan")) setPrices(item);
			});
		}
	}, [plan]);

	console.log("client secret", selectedPlan);

	if (!clientSecret) {
		return <Loading />;
	}
	return (
		<Flex gap={"50px"}>
			<Flex
				border={"3px solid #AFC80D"}
				bg="#FFFFFF"
				borderRadius="3em"
				overflow={"hidden"}
				p="1em"
				w="50%"
				height="100%"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="space-between"
			>
				<Box>
					<Heading fontSize="2em" color={"#AFC80D"} textAlign="center">
						{plan?.description}
					</Heading>
				</Box>
				<Stack textAlign={"center"} color="#1A202C" align={"center"}>
					<Text color="#CA3171" pt="1em">
						SALE - UP TO <span style={{ fontWeight: 900 }}>10%</span>
					</Text>
					<Stack direction={"row"} align={"space-evenly"} justify={"space-evenly"} spacing={5}>
						<Stat color={"#AFC80D"}>
							<Text color="#000" textDecoration="line-through" textDecorationColor="#CA3171">
								{prices.previous_cost}€
							</Text>
							<StatNumber>
								<Text>{prices.cost}€</Text>
							</StatNumber>
							<StatHelpText>/{prices.interval}</StatHelpText>
						</Stat>
					</Stack>
				</Stack>
				<Box px={3} py={5}>
					<UnorderedList listStyleType="none" spacing={1}>
						{Object.keys(plan?.plan_prices[0]?.company_per_plan_limitation).map((s, key) =>
							plan.plan_prices[0].company_per_plan_limitation[s] !== 0 && labels[s] ? (
								<ListItem key={key}>
									<ListIcon as={MdCheckCircle} color={"#306496"} />
									{labels[s]}
								</ListItem>
							) : labels[s] ? (
								<ListItem key={key}>
									<ListIcon as={MdRemoveCircle} color="#ABBED2" />
									{labels[s]}
								</ListItem>
							) : null,
						)}
						{plan.slug === "enterprise_company" || plan.slug === "premium_company" ? (
							<ListItem>
								<ListIcon as={MdCheckCircle} color={"#306496"} />
								Virtual Reality{" "}
								<Text as="span" color="#CA3171">
									{" "}
									(Later)
								</Text>
							</ListItem>
						) : (
							<ListItem>
								<ListIcon as={MdRemoveCircle} color="#ABBED2" />
								Virtual Reality{" "}
								<Text as="span" color="#CA3171">
									{" "}
									(Later)
								</Text>
							</ListItem>
						)}
						{plan.slug === "enterprise_company" ? (
							<ListItem>
								<ListIcon as={MdCheckCircle} color={"#306496"} />
								VIP Aktionen
								<Text as="span" color="#CA3171">
									{" "}
									(Later)
								</Text>
							</ListItem>
						) : (
							<ListItem>
								<ListIcon as={MdRemoveCircle} color="#ABBED2" />
								VIP Aktionen
								<Text as="span" color="#CA3171">
									{" "}
									(Later)
								</Text>
							</ListItem>
						)}
						{plan.slug === "enterprise_company" ? (
							<ListItem>
								<ListIcon as={MdCheckCircle} color={"#306496"} />
								Shop
								<Text as="span" color="#CA3171">
									{" "}
									(Later)
								</Text>
							</ListItem>
						) : (
							<ListItem>
								<ListIcon as={MdRemoveCircle} color="#ABBED2" />
								Shop
								<Text as="span" color="#CA3171">
									{" "}
									(Later)
								</Text>
							</ListItem>
						)}
					</UnorderedList>
				</Box>
			</Flex>
			<Box width={"50%"}>
				<Elements stripe={stripePromise} options={{ clientSecret }}>
					<Form history={history} />
					{/* </PublicLayout> */}
				</Elements>
			</Box>
		</Flex>
	);
}

export default Checkout;
