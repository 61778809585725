import React from "react";
import Logo from "../../../../assets/images/logo-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Transition } from "react-transition-group";
import { Box, Text } from "@chakra-ui/layout";
import { Image, Button } from "@chakra-ui/react";

import MenuItem from "./MenuItem";

import { content } from "./content";
import useWindowSize from "../../../../utils/useWindowSize";

function SidebarContainer({
	active,
	menu,
	lang,
	handleLogOut,
	handleToggle,
	locationsLength,
	limits,
}) {
	const { width, height } = useWindowSize();
	const t = content[lang];
	const duration = 0;
	const defaultStyle = {
		transition: `all ${duration}ms ease-in-out`,
		opacity: 0,
	};

	const transitionStyles = {
		entered: { opacity: 1, maxWidth: "100vw" },
		exited: {
			opacity: 0,
			transform: "translateX(100%)",
			overflow: "hidden",
			maxWidth: "0px",
		},
	};

	const iconTransitionStyles = {
		exited: { marginLeft: "auto", marginRight: "auto" },
	};

	return (
		<Transition in={active} timeout={duration}>
			{state => (
				<Box
					sx={width <= 768 && { ...defaultStyle, ...transitionStyles[state] }}
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					minHeight={`${height}`}
					height={["fill-available", "100vh"]}
					position="relative"
					backgroundColor="#1A1919"
					color="#FFF"
					zIndex="3"
				>
					<Box display="flex" flexDirection="column">
						<Box style={{ ...defaultStyle, ...transitionStyles[state] }}>
							<Image src={Logo} alt="logo" py="1em" height={["auto", "7em"]} />
						</Box>

						<Button
							backgroundColor="transparent"
							position="absolute"
							right="0.5em"
							top="0.5em"
							display={["block", "none"]}
							style={{ ...defaultStyle, ...transitionStyles[state] }}
							onClick={handleToggle}
							_hover={{ backgroundColor: "transparent" }}
							_focus={{ backgroundColor: "transparent" }}
							_active={{ backgroundColor: "transparent" }}
						>
							<FontAwesomeIcon color="#AECB2D" size="2x" icon="window-close" />
						</Button>

						{menu.map((item, key) => (
							<MenuItem
								limits={limits}
								handleToggle={width <= 768 ? handleToggle : null}
								locationsLength={locationsLength}
								key={key}
								item={item}
								active={active}
								trStyle={{ ...defaultStyle, ...transitionStyles[state] }}
								iStyle={iconTransitionStyles[state]}
							/>
						))}
					</Box>

					<Button
						mb={"16px"}
						_focus={{ boxShadow: "none" }}
						borderRadius={0}
						backgroundColor="#1A1919"
						justifyContent="flex-start"
						_hover={{ backgroundColor: "#4D4D4D" }}
						display="flex"
						width="100%"
						onClick={handleLogOut}
					>
						<Box width="20%" display="flex">
							<FontAwesomeIcon icon={"sign-out-alt"}></FontAwesomeIcon>
						</Box>
						<Box style={{ ...defaultStyle, ...transitionStyles[state] }}>
							<Text>{t.logout}</Text>
						</Box>
					</Button>
				</Box>
			)}
		</Transition>
	);
}

export default SidebarContainer;
