import React, { useState, useEffect } from "react";
import { Input } from "@chakra-ui/react";

import { onLoadImage } from "../../../../utils/fabricActions";
import { onLoadVideo } from "../../../../utils/video";

import defaultVideoThumbnail from "../../../../../../../assets/builder/def-vid-thumb.jpeg";

function InputComp({ state, setState, fileInput, canvas, variant, activeSlide }) {
	const [video, setVideo] = useState({
		status: false,
		media: false,
		url: false,
		thumb: defaultVideoThumbnail,
	});

	const resetState = () => {
		setVideo({
			status: false,
			media: false,
			url: false,
			thumb: defaultVideoThumbnail,
		});
	};

	useEffect(() => {
		if (video.status && video.media && video.thumb) {
			onLoadVideo(activeSlide, video.media, video.thumb, resetState, setState);
		}
	}, [video]);

	function checkForFile() {
		if (fileInput.current.value.length) {
		} else {
			setState(state => {
				state.imageLoading = false;
			});
		}
		document.body.onfocus = null;
	}
	function addFocusListener() {
		document.body.onfocus = checkForFile;
	}

	const handleLoad = e => {
		let media = e.target.files[0];
		let url = URL.createObjectURL(media);
		if (media.type.toString().includes("video")) {
			setVideo({
				status: true,
				thumb: defaultVideoThumbnail,
				url,
				media,
			});
		} else {
			onLoadImage(canvas, url);
		}
		fileInput.current.value = null;
	};

	return (
		<>
			<Input
				onClick={addFocusListener}
				type="file"
				accept={variant === "logo" ? "image/*" : "image/*,video/*"}
				name="upload"
				id="upload"
				ref={fileInput}
				display="none"
				onChange={handleLoad}
			/>
		</>
	);
}

export default InputComp;
