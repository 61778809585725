import { customActions } from "../../actionTypes";

export const setLoading = bool => {
	return dispatch => {
		dispatch({
			type: customActions.SET_APP_STATE,
			payload: bool,
		});
	};
};
