import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";

function FontItem({ name, handleClick }) {
	const [fontLoaded, setFontLoaded] = useState(false);
	const loadFont = async font => {
		return font.load();
	};
	useEffect(() => {
		const font = new FontFace(
			name.slice(0, -4),
			`url(https://static.yezzgo.de/image-editor-statics/fonts/${name})`,
		);
		loadFont(font)
			.then(res => {
				setFontLoaded(true);
				document.fonts.add(font);
			})
			.catch(err => console.log("error loading font", err));
	}, []);
	if (!fontLoaded) return null;
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			border="1px solid rgba(0,0,0,.15)"
			userSelect="none"
			cursor="pointer"
			_hover={{ border: "2px solid #AACD56" }}
			h="40px"
			minW="100px"
			p="5px"
			onClick={handleClick}
			mr="10px"
		>
			<Text fontSize="auto" fontFamily={name.slice(0, -4)}>
				{name.slice(0, -4)}
			</Text>
		</Box>
	);
}

export default FontItem;
