import React from "react";
import { Box, Spinner, Image } from "@chakra-ui/react";
import { getThumb } from "../../../../../../../utils/imgUri";

import Button from "./Button";

function TemplateItem({
	id,
	img,
	selected,
	onLoadTemplate,
	loadingTemplate,
	onRemoveTemplate,
	removingTemplate,
}) {
	let url = getThumb(img);
	return (
		<Box pos="relative" w="48%">
			{(loadingTemplate || removingTemplate) && selected === id && (
				<Box
					pos="absolute"
					top="0"
					left="0"
					h="100%"
					w="100%"
					display="flex"
					justifyContent="center"
					alignItems="center"
					borderRadius="5"
					bg="rgba(0,0,0,0.5)"
				>
					<Spinner color="#AACD56" size="xl" />
				</Box>
			)}
			<Image src={url} />
			<Box
				pos="absolute"
				bottom="0"
				left="0"
				width="100%"
				display="flex"
				justifyContent="space-between"
				px="5px"
				pb="5px"
			>
				<Button icon={"cloud-download-alt"} action={() => onLoadTemplate(id)} />
				<Button icon={"trash"} action={() => onRemoveTemplate(id)} />
			</Box>
		</Box>
	);
}

export default TemplateItem;
