import { fabric } from "fabric";

export function onSetActiveObject(canvas, setState, obj) {
	if (canvas && obj) {
		canvas.setActiveObject(obj).renderAll();
		setState(state => {
			state.activeObject = obj;
		});
	} else {
		canvas.discardActiveObject();

		setState(state => {
			state.activeObject = null;
		});
	}
}

export function onObjectRotate(canvas, direction) {
	if (canvas) {
		if (direction === "left") {
			canvas.getActiveObject().rotate(canvas.getActiveObject().get("angle") - 10);
			canvas.renderAll();
			canvas.fire("event:cache", {
				eName: "rotate-left",
				from: "objectActions",
			});
		}
		if (direction === "right") {
			canvas.getActiveObject().rotate(canvas.getActiveObject().get("angle") + 10);
			canvas.renderAll();
			canvas.fire("event:cache", {
				eName: "rotate-right",
				from: "objectActions",
			});
		}
		canvas.fire("event:render-live", {});
	}
}

export function onObjectRotateReset(canvas) {
	if (canvas) {
		canvas.getActiveObject().get("angle");
		canvas.getActiveObject().rotate(0);
		//  canvas.getActiveObject().set({ angle: 0 });
		canvas.renderAll();
		canvas.fire("event:cache", {
			eName: "rotate-reset",
			from: "objectActions",
		});
		canvas.fire("event:render-live", {});
	}
}

export function onObjectFlip(canvas, direction) {
	if (direction === "y") {
		canvas.getActiveObject().set({ flipY: !canvas.getActiveObject().get("flipY") });
		canvas.renderAll();
		canvas.fire("event:cache", { eName: "flip-y", from: "objectActions" });
	}
	if (direction === "x") {
		canvas.getActiveObject().set({ flipX: !canvas.getActiveObject().get("flipX") });
		canvas.renderAll();
		canvas.fire("event:cache", { eName: "flip-x", from: "objectActions" });
	}
	canvas.fire("event:render-live", {});
}

export function onMagnifier(canvas, direction) {
	if (canvas) {
		if (direction === "in") {
			canvas.getActiveObject().set({ scaleX: canvas.getActiveObject().get("scaleX") * 1.1 });
			canvas.getActiveObject().set({ scaleY: canvas.getActiveObject().get("scaleY") * 1.1 });
			canvas.fire("event:cache", { eName: "zoomi-in", from: "objectActions" });
			canvas.renderAll();
		}
		if (direction === "out") {
			canvas.getActiveObject().set({ scaleX: canvas.getActiveObject().get("scaleX") / 1.1 });
			canvas.getActiveObject().set({ scaleY: canvas.getActiveObject().get("scaleY") / 1.1 });
			canvas.fire("event:cache", { eName: "zoomi-out", from: "objectActions" });
			canvas.renderAll();
		}
		canvas.fire("event:render-live", {});
	}
}

export function onCloneObject(canvas, activeObject) {
	if (canvas && activeObject) {
		const currentObject = canvas.getActiveObject();
		currentObject.clone(clone => {
			clone.index = canvas.getObjects().length;
			clone.set({
				left: 200,
				top: 200,
			});

			canvas.add(clone);
			canvas.renderAll();
			canvas.fire("event:cache", { eName: "clone", from: "objectActions" });
			canvas.fire("event:render-live", {});
		});
	}
}

const filtersList = {
	Grayscale: new fabric.Image.filters.Grayscale(),
	Invert: new fabric.Image.filters.Invert(),
	Sepia: new fabric.Image.filters.Sepia(),
	BlackWhite: new fabric.Image.filters.BlackWhite(),
	Brownie: new fabric.Image.filters.Brownie(),
	Vintage: new fabric.Image.filters.Vintage(),
	Kodachrome: new fabric.Image.filters.Kodachrome(),
	Technicolor: new fabric.Image.filters.Technicolor(),
	Polaroid: new fabric.Image.filters.Polaroid(),
};

export const applyFilter = (canvas, index) => {
	if (canvas) {
		let ao = canvas.getActiveObject();
		if (ao) {
			if (ao.type === "image") {
				let check = ao.filters.filter(i => i.type === index);
				if (check.length === 0) {
					ao.filters.push(filtersList[index]);
					ao.applyFilters();
					canvas.renderAll();
					canvas.fire("event:cache", {
						eName: "apply-filter",
						from: "objectActions",
					});
				}
			}
		}
	}
};

export const removeFilter = (canvas, index) => {
	if (canvas) {
		let ao = canvas.getActiveObject();
		if (ao) {
			if (ao.type === "image" && ao.filters.length > 0) {
				let newArr = ao.filters.filter(i => i.type !== index);
				ao.set("filters", newArr);
				ao.applyFilters();
				canvas.renderAll();
				canvas.fire("event:cache", {
					eName: "remove-filter",
					from: "objectActions",
				});
			}
		}
	}
};

export function onToggleHideObject(canvas, item, setState) {
	if (item) {
		if (item.opacity !== 0) {
			item.set("opacity", 0);
			item.set("selectable", false);
			onSetActiveObject(canvas, setState, null);
		} else {
			item.set("opacity", 1);
			item.set("selectable", true);
		}
		canvas.fire("event:cache", {
			eName: "toogle-Object",
			from: "objectActions",
		});
		canvas.renderAll();
	}
}

export function onbjectOpacityChange(canvas, opacity) {
	if (canvas) {
		let activeObject = canvas.getActiveObject();
		if (activeObject) {
			activeObject.set("opacity", opacity);
			canvas.renderAll();
			// canvas.fire("event:cache", {
			//   eName: "opacity - Object",
			//   from: "objectActions",
			// });
		}
	}
}

export function onRemoveObjectFromCanvas(canvas, item) {
	canvas.remove(item);

	for (const object of canvas.getObjects()) {
		object.index = object.get("index") !== 0 ? object.get("index") - 1 : 0;
	}
	canvas.fire("event:cache", {
		eName: "remove-Object",
		from: "objectActions",
	});
	canvas.renderAll();
}

export function onObjectMove(canvas, item, index) {
	if (canvas) {
		canvas.moveTo(item, index).renderAll();
		canvas.fire("event:cache", {
			eName: "move-Object",
			from: "objectActions",
		});
	}
}

export function onBringObjectForward(canvas) {
	if (canvas) {
		let activeObject = canvas.getActiveObject();
		if (activeObject) {
			canvas.bringForward(activeObject);
		}
	}
}

export function onSendObjectBack(canvas) {
	if (canvas) {
		let activeObject = canvas.getActiveObject();
		if (activeObject) {
			canvas.sendToBack(activeObject);
		}
	}
}
