import { storyActions } from "../../actionTypes";

const initialState = {
	story: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case storyActions.FETCH_STORY: {
			return { ...state, fetching: true };
		}

		case storyActions.RESET_STORY_STATE:
			return { ...initialState };

		case storyActions.FETCH_STORY_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				story: action.payload,
			};
		}

		case storyActions.FETCH_STORY_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
