import React from "react";
import { Box } from "@chakra-ui/react";
import useWindowSize from "../../../utils/useWindowSize";

export default function Content({ lang, active, formik, options }) {
	const { width, height } = useWindowSize();

	return (
		<Box
			height="100%"
			flex="1"
			flexGrow="2"
			mt={
				height >= 1080 && width >= 1920
					? "2em"
					: height >= 1050 && width >= 1680
					? "2em"
					: height >= 1024 && width >= 1280
					? "2em"
					: height >= 900 && width >= 1440
					? "2em"
					: height >= 864 && width >= 1536
					? "2em"
					: height >= 800 && width >= 1280
					? "2em"
					: height >= 768 && width >= 1366
					? "2em"
					: height >= 720 && width >= 1280
					? "2em"
					: "2em"
			}
			pb="2em"
			display="flex"
			flexDirection="column"
			// borderBottom="2px solid black"
			overflowY="auto"
		>
			{options[active]}
		</Box>
	);
}
