import React from "react";
import ControllButton from "./ControllButton";

import fonts0 from "../../../../../../assets/textbox-icons/fonts0.png";
import fonts1 from "../../../../../../assets/textbox-icons/fonts1.png";
import align0 from "../../../../../../assets/textbox-icons/align0.png";
import align1 from "../../../../../../assets/textbox-icons/align1.png";
import decor0 from "../../../../../../assets/textbox-icons/decor0.png";
import decor1 from "../../../../../../assets/textbox-icons/decor1.png";
import shadow0 from "../../../../../../assets/textbox-icons/shadow0.png";
import shadow1 from "../../../../../../assets/textbox-icons/shadow1.png";
import picker0 from "../../../../../../assets/textbox-icons/picker0.png";
import picker1 from "../../../../../../assets/textbox-icons/picker1.png";

function ControllList({ setActiveBox, openPickerModal }) {
	return (
		<>
			<ControllButton
				state0={fonts0}
				state1={fonts1}
				action={() => {
					setActiveBox({
						active: true,
						type: "fonts",
						icon: fonts1,
					});
				}}
			/>
			<ControllButton
				state0={align0}
				state1={align1}
				action={() => {
					setActiveBox({
						active: true,
						type: "align",
						icon: align1,
					});
				}}
			/>
			<ControllButton
				state0={decor0}
				state1={decor1}
				action={() => {
					setActiveBox({
						active: true,
						type: "decor",
						icon: decor1,
					});
				}}
			/>
			<ControllButton
				state0={shadow0}
				state1={shadow1}
				action={() => {
					setActiveBox({
						active: true,
						type: "shadow",
						icon: shadow1,
					});
				}}
			/>
			<ControllButton state0={picker0} state1={picker1} action={openPickerModal} />
		</>
	);
}

export default ControllList;
