import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Divider, Button } from "@chakra-ui/react";
import { content } from "../components/content";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import PrimaryButton from "../../shared/buttons/PrimaryButton";
import { handleScroll } from "../../../utils/scroll";
import { useDispatch } from "react-redux";

function Layout({
	lang,
	isOpen,
	onOpen,
	onClose,
	currentId,
	setCurrentId,
	notifications,
	next_page_url,
	current_page,
	fetchNotifications,
	loading,
}) {
	const dispatch = useDispatch();
	const t = content[lang];

	const chooseType = (type, description = false) => {
		switch (type) {
			case "payment":
				return (
					<ModalBody>
						<Table variant="striped">
							<Thead>
								<Tr>
									<Th>#</Th>

									<Th>1</Th>
								</Tr>
							</Thead>
							<Tbody>
								<Tr>
									<Td>Title</Td>
									<Td>{notifications[currentId].title}</Td>
								</Tr>
								<Tr>
									<Td>Date</Td>
									<Td>{notifications[currentId].created_at}</Td>
								</Tr>
							</Tbody>
						</Table>
					</ModalBody>
				);

			default:
		}
	};

	return (
		<Box
			paddingX="20px"
			display="flex"
			flexDirection="column"
			flexGrow="1"
			overflowY="scroll"
			position="absolute"
			bottom="0"
			right="0"
			width="100%"
			top={["3em", "0"]}
			pb={["5em", "1em"]}
			onScroll={e =>
				handleScroll(
					e,
					() => dispatch(fetchNotifications(current_page + 1)),
					current_page,
					loading,
					next_page_url,
				)
			}
		>
			<Box py="3">
				<Box mb="1">
					<Text fontSize="24px">Notifications</Text>
				</Box>
				<Box w="40px">
					<Divider borderBottom="4px" opacity="1" />
				</Box>
			</Box>
			<Box display="flex" flexDirection="column">
				<Table variant="simple">
					<Thead
						zIndex="2"
						boxShadow="0 3px 0px -2px black"
						position="sticky"
						top="0"
						backgroundColor="#FFF"
					>
						<Tr>
							<Th fontSize="1.2em">#</Th>
							<Th fontSize="1.2em">{t.name}</Th>

							<Th fontSize="1.2em">{t.action}</Th>
							<Th fontSize="1.2em">{t.updated_at}</Th>
						</Tr>
					</Thead>

					<Tbody borderTop="2px solid #000">
						{notifications.map((item, key) => (
							<Tr
								key={key}
								_hover={{
									backgroundColor: "#F2F1F0",
								}}
							>
								<Td>{key + 1}</Td>
								<Td>{item.title}</Td>

								<Td>
									<PrimaryButton
										onClick={() => {
											setCurrentId(key);
											onOpen();
										}}
										label={t.open_details}
									/>
								</Td>
								<Td>{item.created_at}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
			<Modal onClose={onClose} size="full" isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{notifications[currentId].id}</ModalHeader>
					<ModalCloseButton />
					{chooseType(notifications[currentId]["type"], notifications[currentId]["description"])}

					<ModalFooter>
						<Button onClick={onClose}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default Layout;
