import React from "react";
import { Box } from "@chakra-ui/react";
import Client from "../panels/Client";
import Tab from "../../../shared/tabs";

function Panels({
	lang,
	id,
	formik,
	activeTab,
	setActiveTab,
	submitClicked,
	showMap,
	setShowMap,
	empty,
	setEmpty,
	createKeyword,
	...rest
}) {
	const options = {
		Coupon: <Client couponId={id} formik={formik} lang={lang} />,
	};
	const fields = [
		[
			"title.de",
			"description.de",
			"news",
			"type",
			"visible_for",
			"coupon_code",
			"post_end_at",
			"limited_amount",
			"previous_price",
		],
	];
	return (
		<Box display="flex" flexDir="column" justifyContent="space-between" {...rest}>
			<Tab
				submitClicked={submitClicked}
				formik={formik}
				fields={fields}
				active={activeTab}
				setActive={setActiveTab}
				options={options}
			/>
		</Box>
	);
}

export default Panels;
