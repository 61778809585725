import { tokenActions } from "../../actionTypes";
import { recoverPassword } from "../../../api/libs/user";
import toast from "react-hot-toast";

export const requestRecover = (email, setErrors) => {
	return async dispatch => {
		dispatch({
			type: tokenActions.REQUEST_TOKEN,
		});
		recoverPassword(email)
			.then(() => {
				dispatch({
					type: tokenActions.REQUEST_FULFILLED,
				});
				toast.success("Email has been sent");
			})
			.catch(err => {
				dispatch({
					type: tokenActions.REQUEST_REJECTED,
					payload: err.response.data,
				});
				setErrors(err.response.data.errors);
				toast.error("Somthing went wrong");
			});
	};
};

export const resetRecoverState = () => {
	return dispatch => {
		dispatch({
			type: tokenActions.RESET_RECOVER_STATE,
		});
	};
};
