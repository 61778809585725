import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../../shared/table";
import { Box, Skeleton } from "@chakra-ui/react";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { cancelPaypalSubscription } from "../../../../store/actions";
import { useDispatch } from "react-redux";
export default function PaypalList({ data, loading, onOpen, setId, setPlanId, onPlanOpen }) {
	const dispatch = useDispatch();

	return (
		<>
			<Box display="flex" flexDirection="column">
				<Table>
					<Thead>
						<Tr>
							<Th>NAME</Th>
							<Th>INTERVAL</Th>
							<Th>COST</Th>
							<Th>SWAP</Th>
							<Th>DETAILS</Th>
							<Th>CANCEL</Th>
						</Tr>
					</Thead>

					<Tbody>
						{data?.data?.map((item, index) => (
							<Tr key={index}>
								<Td>{item?.plan?.name}</Td>
								<Td>{item?.items?.[0]?.plan_price?.interval}</Td>
								<Td>{item?.items?.[0]?.plan_price?.cost}</Td>

								<Td>
									<PrimaryButton
										label="SWAP"
										onClick={() => {
											setPlanId({
												plan_price_id: item.plan_price.id,
												plan_id: item.plan.id,
												subscription_id: item.id,
											});
											onPlanOpen();
										}}
									/>
								</Td>
								<Td>
									<PrimaryButton
										label="DETAILS"
										onClick={() => {
											setId(item.id);
											onOpen();
										}}
									/>
								</Td>

								<Td>
									<PrimaryButton
										onClick={() => {
											dispatch(
												cancelPaypalSubscription({
													paypal_subscription_id: item.id,
													reason: "Test",
												}),
											);
										}}
										prompt={true}
										label="CANCEL"
										hoverColor="#F58121"
									></PrimaryButton>
								</Td>
							</Tr>
						))}
						{loading && (
							<Tr
								_hover={{
									backgroundColor: "#F2F1F0",
								}}
							>
								<Td>
									<Skeleton height="20px" />
								</Td>

								<Td>
									<Skeleton height="20px" />
								</Td>

								<Td>
									<Skeleton height="20px" />
								</Td>

								<Td>
									<Skeleton height="20px" />
								</Td>

								<Td>
									<Skeleton height="20px" />
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</>
	);
}
