import React from "react";
import { Select } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TimeInput({
	label,
	onChange,
	dayIndex,
	timeIndex,
	iconDisabled,
	min,
	disabled,
	max,
	value,
}) {
	const hours = [
		"00",
		"01",
		"02",
		"03",
		"04",
		"05",
		"06",
		"07",
		"08",
		"09",
		"10",
		"11",
		"12",
		"13",
		"14",
		"15",
		"16",
		"17",
		"18",
		"19",
		"20",
		"21",
		"22",
		"23",
	];
	const times = ["00", "15", "30", "45"];
	return (
		<div>
			<Select
				icon={iconDisabled ? <></> : <FontAwesomeIcon icon="caret-down" />}
				borderRadius="0px"
				borderColor="#CBCCCB"
				borderWidth="1px"
				backgroundColor="transparent"
				boxShadow="none"
				_hover={{ backgroundColor: "#F2F1F1" }}
				_focus={{
					backgroundColor: "#F2F1F1",
					borderColor: "#1274BC",
				}}
				_disabled={{ borderColor: "#CBCCCB", color: "#000", cursor: "pointer" }}
				color={"#000"}
				_placeholder={{ color: "rgba(0,0,0,0.5)" }}
				onChange={e => {
					onChange(e.target.value, dayIndex, timeIndex);
				}}
				placeholder={label}
				disabled={disabled}
				value={value}
			>
				{hours.map(hour =>
					times.map(minute =>
						min > `${hour}:${minute}` ? null : (
							<option value={`${hour}:${minute}`} key={`${hour}:${minute}`}>
								{hour}:{minute}
							</option>
						),
					),
				)}
				<option value="24:00" key="24:00">
					24:00
				</option>
			</Select>
		</div>
	);
}
