import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiCallAc, setActiveCompanyToken } from "../../../api";
import { fetchPaypalSubscriptions, fetchStripeSubscriptions } from "../../../store/actions";
import { content } from "./components/content";
import Layout from "./components/Layout";

function CompanyHome() {
	const dispatch = useDispatch();
	const {
		subscriptions: { current_page: paypal_current_page, next_page_url: paypal_next_page_url },
		subscriptions: paypalList,
		fetching: fetchingPaypal,
		fetched: fetchedPaypalSubscriptions,
	} = useSelector(state => state.fetchPaypalSubscriptions);
	const {
		subscriptions: { current_page: stripe_current_page, next_page_url: stripe_next_page_url },
		subscriptions: stripeList,
		fetching: fetchingStripe,
		fetched: fetchedStripeSubscriptions,
	} = useSelector(state => state.fetchStripeSubscriptions);
	const company = useSelector(state => state.fetchCompany.company);

	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];

	useEffect(() => {
		dispatch({ type: "RESET_STATE" });
	}, []);

	useEffect(() => {
		setActiveCompanyToken(company.id);
		if (apiCallAc.defaults.headers.common) {
			// if (!fetchedPaypalSubscriptions) dispatch(fetchPaypalSubscriptions(1));
			// if (!fetchedStripeSubscriptions) dispatch(fetchStripeSubscriptions(1));
			dispatch(fetchPaypalSubscriptions(1));
			dispatch(fetchStripeSubscriptions(1));
		}
	}, [company, lang]);
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Layout
			onClose={onClose}
			isOpen={isOpen}
			onOpen={onOpen}
			header={t.header}
			lang={lang}
			loading={fetchingPaypal || fetchingStripe}
			stripeList={stripeList}
			stripe_next_page_url={stripe_next_page_url}
			stripe_current_page={stripe_current_page}
			paypalList={paypalList}
			paypal_next_page_url={paypal_next_page_url}
			paypal_current_page={paypal_current_page}
		/>
	);
}

export default CompanyHome;
