import { chatsActions } from "../../actionTypes";

const initialState = {
	chat: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case chatsActions.CREATE_CHAT: {
			return { ...initialState, fetching: true };
		}

		case chatsActions.CREATE_CHAT_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				chat: action.payload,
			};
		}

		case chatsActions.CREATE_CHAT_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
