export function onSetFontFamily(canvas, state, fontFamily) {
	if (
		canvas &&
		state.activeObject &&
		canvas.getObjects().length &&
		state.activeObject.get("type") === "textbox"
	) {
		canvas.getActiveObject().set("fontFamily", fontFamily);
		canvas.renderAll();
		canvas.fire("event:cache", { eName: "change-font", from: "textboxAction" });
	}
}

export function onUpdateTextColor(canvas, state, color, x, y) {
	if (canvas && state.activeObject && state.activeObject.get("type") === "textbox") {
		canvas.getActiveObject().set("fill", color);
		canvas.getActiveObject().set("picker_x", x);
		canvas.getActiveObject().set("picker_y", y);
		canvas.renderAll();
	}
}

export function onToogleUnderline(canvas, state) {
	if (canvas && state.activeObject && state.activeObject.get("type") === "textbox") {
		let obj = canvas.getActiveObject();
		obj.set("underline", obj.underline ? false : true);
		canvas.renderAll();
		canvas.fire("event:cache", {
			eName: "toogle-underline",
			from: "textboxAction",
		});
	}
}

export function onToogleLinethrough(canvas, state) {
	if (canvas && state.activeObject && state.activeObject.get("type") === "textbox") {
		let obj = canvas.getActiveObject();
		obj.set("linethrough", obj.linethrough ? false : true);
		canvas.renderAll();
		canvas.fire("event:cache", {
			eName: "toogle-linethrough",
			from: "textboxAction",
		});
	}
}

export function onSetTextAlignment(canvas, state, direction) {
	if (canvas && state.activeObject && state.activeObject.get("type") === "textbox") {
		if (direction === "left" || direction === "right" || direction === "center") {
			canvas.getActiveObject().set("textAlign", direction);
			canvas.renderAll();
			canvas.fire("event:cache", {
				eName: "aliggn-text",
				from: "textboxAction",
			});
		}
	}
}

export function onToUpperCase(canvas, state) {
	if (canvas && state.activeObject && state.activeObject.get("type") === "textbox") {
		let obj = canvas.getActiveObject();
		obj.set("text", obj.text.toUpperCase());
		canvas.renderAll();
		canvas.fire("event:cache", {
			eName: "uppercase-font",
			from: "textboxAction",
		});
	}
}

export function onToLowerCase(canvas, state) {
	if (canvas && state.activeObject && state.activeObject.get("type") === "textbox") {
		let obj = canvas.getActiveObject();
		obj.set("text", obj.text.toLowerCase());
		canvas.renderAll();
		canvas.fire("event:cache", {
			eName: "lowercase-font",
			from: "textboxAction",
		});
	}
}

export function onSuperScript(canvas, state) {
	if (canvas && state.activeObject && state.activeObject.get("type") === "textbox") {
		let obj = canvas.getActiveObject();
		let styles = obj.getSelectionStyles();

		for (let i = 0; i < styles.length; i++) {
			if (Object.keys(styles[i]).length > 0) {
				obj.removeStyle("fontSize");
				obj.removeStyle("deltaY");

				canvas.renderAll();
				canvas.fire("event:cache", {
					eName: "remove-upscript-font",
					from: "textboxAction",
				});

				return;
			} else {
				obj.setSuperscript();
				canvas.renderAll();
				canvas.fire("event:cache", {
					eName: "upscript-font",
					from: "textboxAction",
				});
				return;
			}
		}
	}
}

export function onSubScript(canvas, state) {
	if (canvas && state.activeObject && state.activeObject.get("type") === "textbox") {
		let obj = canvas.getActiveObject();
		let styles = obj.getSelectionStyles();

		for (let i = 0; i < styles.length; i++) {
			if (Object.keys(styles[i]).length > 0) {
				obj.removeStyle("fontSize");
				obj.removeStyle("deltaY");
				canvas.renderAll();
				canvas.fire("event:cache", {
					eName: "remove-subscript-font",
					from: "textboxAction",
				});
				return;
			} else {
				obj.setSubscript();
				canvas.renderAll();
				canvas.fire("event:cache", {
					eName: "subscript-font",
					from: "textboxAction",
				});
				return;
			}
		}
	}
}
