import { Box } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import React from "react";

export default function Loading({ height, width }) {
	return (
		<Box
			display="flex"
			justifyContent="center"
			height={height ? "100%" : "100vh"}
			alignItems="center"
			my="1em"
			width={width ?? "unset"}
			backgroundColor="transparent"
		>
			<Spinner thickness="4px" speed="0.65s" emptyColor="#0E65AE" color="#AFCB2E" size="xl" />
		</Box>
	);
}
