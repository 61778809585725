import React from "react";
import { Box } from "@chakra-ui/react";

const LeftSideLayout = props => {
	return (
		<Box h="100%" display="flex" flexDir="column" flexGrow="1" ml="10px">
			{props.children}
		</Box>
	);
};

export default LeftSideLayout;
