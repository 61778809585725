import React from "react";
import { Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export default function ChildButton({ child, trStyle }) {
	return (
		<NavLink key={child.id} to={child.url} activeClassName="activeChild">
			<Button
				className="child"
				_focus={{ boxShadow: "none" }}
				borderRadius={0}
				fontSize="0.6em"
				backgroundColor="#1A1919"
				_hover={{ backgroundColor: "#4D4D4D" }}
				display="flex"
				justifyContent="flex-start"
				width="100%"
			>
				{child.name}
			</Button>
		</NavLink>
	);
}
