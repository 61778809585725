import React from "react";
import { isDesktop } from "./utils/device";

import Builder from "./Builder";
import RBuilder from "./RBuilder";

function Editor({ variant, refer, formik }) {
	if (isDesktop()) return <Builder {...{ variant, refer, formik }} />;
	return <RBuilder {...{ variant, refer, formik }} />;
}

export default Editor;
