import React, { lazy, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./styles/index.scss";
import { setTokenHeader } from "./api";

import { useDispatch, useSelector } from "react-redux";
import {
	setLoading,
	fetchUserData,
	fetchCompanies,
	fetchCompany,
	setLanguage,
} from "./store/actions";
import { library } from "@fortawesome/fontawesome-svg-core";
import AppLayout from "./components/shared/AppLayout";
import PublicLayout from "./components/shared/PublicLayout";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Loading from "./components/shared/Loading";
import Notification from "./components/shared/firebaseNotifications/Notification";

import {
	faComments,
	faArrowLeft,
	faHome,
	faUser,
	faTimes,
	faFont,
	faBrush,
	faLayerGroup,
	faTh,
	faCheck,
	faMobile,
	faCloud,
	faDownload,
	faFileExport,
	faEyeSlash,
	faEye,
	faTrashAlt,
	faCloudDownloadAlt,
	faTrash,
	faPlus,
	faImage,
	faCopy,
	faTimesCircle,
	faPencilAlt,
	faMapMarked,
	faWifi,
	faStar,
	faMapMarkerAlt,
	faHeart,
	faPhoneVolume,
	faEnvelope,
	faGlobe,
	faChevronDown,
	faEllipsisV,
	faChevronLeft,
	faChevronRight,
	faSignOutAlt,
	faCamera,
	faMap,
	faStreetView,
	faNewspaper,
	faChartPie,
	faBars,
	faUserTag,
	faWindowClose,
	faUnderline,
	faStrikethrough,
	faLevelDownAlt,
	faLevelUpAlt,
	faSubscript,
	faSuperscript,
	faAlignLeft,
	faAlignCenter,
	faAlignRight,
	faStickyNote,
	faShareAlt,
	faUndo,
	faRedo,
	faFilter,
	faCaretDown,
	faCaretUp,
	faVideo,
	faBell,
	faArrowRight,
	faPaperPlane,
	faLink,
	faPaperclip,
	faList,
} from "@fortawesome/free-solid-svg-icons";
import Wizard from "./components/auth/register/components/Wizard";
import PublicRouter from "./router/PublicRouter";
import UnverifiedRouter from "./router/UnverifiedRouter";
import CompanyRouter from "./router/CompanyRouter";
import UnpaidRouter from "./router/UnpaidRouter";
import InvoiceRouter from "./router/InvoiceRouter";
import PrivateRouter from "./router/PrivateRouter";
import CombinedRouter from "./router/CombinedRouter";
// const PublicRouter = lazy(() => import("./router/PublicRouter"));
// const UnverifiedRouter = lazy(() => import("./router/UnverifiedRouter"));
// const CompanyRouter = lazy(() => import("./router/CompanyRouter"));
// const UnpaidRouter = lazy(() => import("./router/UnpaidRouter"));
// const InvoiceRouter = lazy(() => import("./router/InvoiceRouter"));

// const PrivateRouter = lazy(() => import("./router/PrivateRouter"));

library.add(
	faLink,
	faPaperPlane,
	faComments,
	faInstagram,
	faFacebook,
	faBell,
	faCaretUp,
	faCaretDown,
	faShareAlt,
	faArrowLeft,
	faArrowRight,
	faUnderline,
	faStrikethrough,
	faLevelDownAlt,
	faLevelUpAlt,
	faSubscript,
	faSuperscript,
	faAlignLeft,
	faAlignCenter,
	faAlignRight,
	//
	faHome,
	faUser,
	faTimes,
	faFont,
	faBrush,
	faLayerGroup,
	faTh,
	faCheck,
	faMobile,
	faCloud,
	faDownload,
	faFileExport,
	faEyeSlash,
	faEye,
	faTrashAlt,
	faCloudDownloadAlt,
	faTrash,
	faPlus,
	faImage,
	faCopy,
	faTimesCircle,
	faPencilAlt,
	faMapMarked,
	faWifi,
	faStar,
	faMapMarkerAlt,
	faHeart,
	faPhoneVolume,
	faEnvelope,
	faGlobe,
	faChevronDown,
	faEllipsisV,
	faChevronLeft,
	faChevronRight,
	faSignOutAlt,
	faCamera,
	faMap,
	faStreetView,
	faNewspaper,
	faChartPie,
	faBars,
	faWindowClose,
	faUserTag,
	faStickyNote,
	faUndo,
	faRedo,
	faFilter,
	faVideo,
	faPaperclip,
	faList,
);

function App() {
	const history = useHistory();
	const dispatch = useDispatch();

	const appLoading = useSelector(state => state.appLoading);

	const { isAuthenticated, fetching: fetchingUser } = useSelector(state => state.currentUser);

	const email_verified_at = useSelector(state => state.currentUser.user.user?.email_verified_at);

	const roles = useSelector(state => state.currentUser.user.user?.roles);

	const {
		invoiceLocations,
		fetching: fetchingInvoiceLocations,
		fetched: fetchedInvoiceLocations,
	} = useSelector(state => state.fetchInvoiceLocations);

	const {
		fetched,
		companies,
		fetching: fetchingCompanies,
	} = useSelector(state => state.fetchCompanies);

	const {
		company,
		fetching: fetchingCompany,
		fetched: fetchedCompany,
	} = useSelector(state => state.fetchCompany);

	useEffect(() => {
		if (!localStorage.getItem("lang")) {
			dispatch(setLanguage("de"));
		} else {
			dispatch(setLanguage(localStorage.getItem("lang")));
		}
	}, []);

	useEffect(() => {
		if (localStorage.getItem("token")) {
			setTokenHeader(localStorage.getItem("token"));
			dispatch(fetchUserData());
		} else {
			dispatch(setLoading(false));
		}
	}, []);

	useEffect(() => {
		if (isAuthenticated && email_verified_at) {
			dispatch(fetchCompanies());
			dispatch(setLoading(true));
		}
		if (isAuthenticated && !email_verified_at) {
			dispatch(setLoading(false));
		}
	}, [isAuthenticated, email_verified_at]);

	useEffect(() => {
		if (fetched) {
			if (companies.data.length === 0) {
				history.push({
					pathname: "/create-company",
				});
				dispatch(setLoading(false));
			} else if (companies.data.length === 1 || localStorage.getItem("activeCompany")) {
				if (localStorage.getItem("activeCompany")) {
					dispatch(fetchCompany(localStorage.getItem("activeCompany")));
				} else dispatch(fetchCompany(companies.data[0].company.id));
			} else {
				history.push({
					pathname: "/select-company",
				});
				dispatch(setLoading(false));
			}
		}
	}, [fetched]);

	if (
		appLoading ||
		fetchingUser ||
		fetchingCompanies ||
		fetchingCompany ||
		fetchingInvoiceLocations
	) {
		return <Loading />;
	}

	// --not authenticated +
	if (!isAuthenticated) return <PublicRouter />;

	// --not verified +
	if (!email_verified_at) {
		return <UnverifiedRouter />;
	}

	// --got no company +

	if (Object.keys(company).length === 0)
		if (fetched) {
			return <CompanyRouter data={companies.data} />;
		}

	// --got company but got no invoice location -
	if (Object.keys(invoiceLocations).length > 0) {
		if (
			Object.values(invoiceLocations.data).map(item =>
				item.default === true ? localStorage.setItem("activeInvoice", item.id) : null,
			)
		)
			if (!localStorage.getItem("activeInvoice")) {
				return <InvoiceRouter />;
			}
	}

	// --not paid -
	if (Object.keys(company).length > 0) {
		for (let i = 0; i < roles.length; i++) {
			if (!company.paypal_subscriptions.length && !company.stripe_subscriptions.length) {
				// console.log("araa gadaxdcili")
				return <UnpaidRouter data={companies.data} isAuthenticated={isAuthenticated} />;
			}
		}
	}

	// ready for dashboard
	return (
		<AppLayout isAuthenticated={isAuthenticated}>
			<Notification />
			<PrivateRouter />
		</AppLayout>
	);
}

export default App;
