import { notificationActions } from "../../actionTypes";
import { getNotification } from "../../../api/libs/notifications";
import { updateNotification } from ".";

export const fetchNotification = id => {
	return async dispatch => {
		dispatch({
			type: notificationActions.FETCH_NOTIFICATION,
		});
		getNotification(id)
			.then(res => {
				dispatch({
					type: notificationActions.FETCH_NOTIFICATION_FULFILLED,
					payload: res.data,
				});

				dispatch(updateNotification(id, true));
			})
			.catch(err => {
				dispatch({
					type: notificationActions.FETCH_NOTIFICATION_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
