export const content = {
	en: {
		facebook: "Facebook",
		email_verified_at: "Email verified at",
		facebook_email: "Facebook email",
		facebook_user_id: "Facebook user id",
		token_updated_at: "Token updated",
		token_valid_for_days: "Token valid for",
		open_details: "Linked instagram",
		logo: "Logo",
		name: "Name",
		instagram: "Instagram",
		resync: "Resync Facebook",
		close: "Close",
		instagram_details: "Instagram",
		no_details: "No instagram linked",
		connect: "Connect Facebook",
	},
	de: {
		facebook: "Facebook",
		email_verified_at: "Email verified at",
		facebook_email: "Facebook email",
		facebook_user_id: "Facebook user id",
		token_updated_at: "Token updated",
		token_valid_for_days: "Token valid for",
		open_details: "Linked instagram",
		logo: "Logo",
		name: "Name",
		instagram: "Instagram",
		resync: "Resync Facebook",
		close: "Close",
		instagram_details: "Instagram",
		no_details: "No instagram linked",
		connect: "Facebook verbinden",
	},
};
