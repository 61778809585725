import React from "react";
import { Box, Text } from "@chakra-ui/react";

import Picker from "./components/Picker";

function ColorPicker({ state, canvas }) {
	return (
		<Box flexGrow="1" py="20px">
			<Text fontSize="22px"> Pick color </Text>
			<Picker state={state} canvas={canvas} />
		</Box>
	);
}

export default ColorPicker;
