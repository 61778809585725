import React from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { createCompany } from "../../store/actions";

import { validate } from "./validate";

import Form from "./components/new-comp/Form";
import { useHistory } from "react-router";
import { useEffect } from "react";

function NewCompany({ lang }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const { user } = useSelector(state => state.currentUser.user);

	const handleSubmit = (values, { setErrors }) => {
		dispatch(createCompany(values, setErrors, history));
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			email: user.email,
		},
		validate: validate,
		onSubmit: handleSubmit,
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		<>
			<Form lang={lang} formik={formik} />
		</>
	);
}

export default NewCompany;
