import { sidebarAction } from "../../actionTypes";
const initialState = {
	active: false,
};

export const sidebar = (state = initialState, action) => {
	switch (action.type) {
		case sidebarAction.TOOGLE_SIDEBAR:
			return { ...state, active: !state.active };
		default:
			return state;
	}
};
