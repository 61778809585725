import { locationActions } from "../../actionTypes";
import { getLocation } from "../../../api/libs/locations";

export const fetchLocation = id => {
	return async dispatch => {
		dispatch({
			type: locationActions.FETCH_LOCATION,
		});
		getLocation(id)
			.then(res => {
				dispatch({
					type: locationActions.FETCH_LOCATION_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: locationActions.FETCH_LOCATION_REJECTED,
					payload: err.response,
				});
			});
	};
};
