import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

function OverlayLoading({ loading }) {
	if (!loading) return null;
	return (
		<Box
			position="absolute"
			width="100%"
			h="540px"
			top="0"
			left="0"
			bg="rgba(0,0,0,0.9)"
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			zIndex={100}
		>
			<Text mb="10px" color="white">
				Loading...
			</Text>
			<Box>
				<Spinner color="#AACD56" size="xl" />
			</Box>
		</Box>
	);
}

export default OverlayLoading;
