import { apiCallAc } from "..";

export function getTemplates(type) {
	switch (type) {
		case "logo":
			return apiCallAc.get("/dashboard/template?type=logo");
		case "gallery":
			return apiCallAc.get("/dashboard/template?type=gallery");
		default:
			return apiCallAc.get("/dashboard/template");
	}
}

export function getTemplate(id) {
	return apiCallAc.get(`/dashboard/template/${id}`);
}

export function deleteTemplate(id) {
	return apiCallAc.delete(`/dashboard/template/${id}`);
}
