import { chatsActions } from "../../actionTypes";
import { revealUsers } from "../../../api/libs/chats";

export const fetchReveal = id => {
	return async dispatch => {
		dispatch({
			type: chatsActions.REVEAL_USERS,
		});
		revealUsers(id)
			.then(res => {
				dispatch({
					type: chatsActions.REVEAL_USERS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: chatsActions.REVEAL_USERS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
