import React, { useEffect } from "react";
import queryString from "query-string";

import { useSelector, useDispatch } from "react-redux";

import Layout from "./components/Layout";
import Form from "./components/Form";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";
import { useFormik } from "formik";
import { verifyLocation } from "../../../store/actions/locations";

function LocationEmailVerification({ location, history }) {
	const lang = useSelector(state => state.languageProvider.language);

	const dispatch = useDispatch();

	const { token } = queryString.parse(location.search);

	const handleSubmit = () => {
		const data = {
			token,
		};
		dispatch(verifyLocation(data, formik.setErrors));
	};

	useEffect(() => {
		handleSubmit();
	}, []);
	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
	});
	return (
		<Layout>
			<Form lang={lang} formik={formik} />
		</Layout>
	);
}

export default LocationEmailVerification;
