import React from "react";
import { Box } from "@chakra-ui/react";

import VideoPlayer from "../VideoPlayer";

function CanvasLayout({ variant, slide }) {
	return (
		<Box>
			<canvas id={`c-${variant}`} />
			{slide.isVideo && <VideoPlayer slide={slide} />}
		</Box>
	);
}

export default CanvasLayout;
