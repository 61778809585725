import React from "react";
import { Box, Text, Divider } from "@chakra-ui/react";
import { content } from "./content";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";

function Form({ lang, formik }) {
	const t = content[lang];

	return (
		<Box w={["auto", "60%"]} ml={[0, "35%"]}>
			<Box mb="2" p={5}>
				<Box mb="1">
					<Text fontSize="20px">{t.recover}</Text>{" "}
				</Box>
				<Box w="40px">
					<Divider borderBottom="4px" opacity="1" />
				</Box>
			</Box>
			<form onSubmit={formik.handleSubmit}>
				<Box align="right" mr={[0, "40px"]} p={5}>
					<PrimaryInput label={t.email} type="email" formik={formik} id="email" />
					<PrimaryButton
						height="1.5rem"
						px="3em"
						mt="1em"
						label={t.send}
						onClick={formik.handleSubmit}
						type="submit"
						bg="#A9A9AD"
					/>
				</Box>
			</form>
		</Box>
	);
}

export default Form;
