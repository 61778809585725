import { eventActions } from "../../actionTypes";
import { getEvents } from "../../../api/libs/events";

export const fetchEvents = (type, page, reset) => {
	return async dispatch => {
		dispatch({
			type: eventActions.FETCH_EVENTS,
			payload: { reset },
		});
		getEvents(type, page)
			.then(res => {
				dispatch({
					type: eventActions.FETCH_EVENTS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: eventActions.FETCH_EVENTS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
