import React from "react";
import { Box, Input } from "@chakra-ui/react";

export default function SelectedTable({ formik, modalVisible, setModalVisible, t, lang }) {
	return (
		<Box
			as="button"
			width="100%"
			px="4"
			py="0"
			minHeight="2em"
			borderRadius="0px"
			borderColor="#CBCCCB"
			borderWidth="1px"
			backgroundColor={modalVisible ? "#F2F1F1" : "transparent"}
			boxShadow="none"
			_hover={{ backgroundColor: "#F2F1F1" }}
			_focus={{
				backgroundColor: "#F2F1F1",
				borderColor: "#1274BC",
			}}
			color="#000"
			_placeholder={{ color: "rgba(0,0,0,0.5)" }}
			onClick={() => {
				setModalVisible(true);
			}}
		>
			{formik.values.post_end_at ? (
				<Input
					border="none"
					shadow="none"
					type="datetime-local"
					cursor="pointer !important"
					disabled
					value={formik.values.post_end_at}
					display="flex"
					alignItems="center"
				/>
			) : lang === "en" ? (
				"Date not selected"
			) : (
				"Datum nicht ausgewählt"
			)}
		</Box>
	);
}
