import { keywordActions } from "../../actionTypes";
import { getMyKeywords } from "../../../api/libs/keywords";

export const fetchMyKeywords = () => {
	return async dispatch => {
		dispatch({
			type: keywordActions.FETCH_MY_KEYWORDS,
		});
		getMyKeywords()
			.then(res => {
				dispatch({
					type: keywordActions.FETCH_MY_KEYWORDS_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				dispatch({
					type: keywordActions.FETCH_MY_KEYWORDS_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
