import { Box } from "@chakra-ui/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ActionBox({ ...rest }) {
	return (
		<Box mr="1em" display="flex" alignItems="center" {...rest}>
			<FontAwesomeIcon size="lg" icon="ellipsis-v"></FontAwesomeIcon>
		</Box>
	);
}

export default ActionBox;
