import React, { useEffect, useState } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Loading from "../../../shared/Loading";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { useHistory } from "react-router-dom";
import { getInvoiceLocations } from "../../../../api/libs/invoice";
function Content({ setPage, setActiveEdit }) {
	const [locations, setLocations] = useState([]);
	const [activeLocation, setActiveLocation] = useState(
		localStorage.getItem("activeInvoice") ? localStorage.getItem("activeInvoice") : null,
	);
	const { invoiceLocations, fetched } = useSelector(state => state.fetchInvoiceLocations);
	const history = useHistory();

	useEffect(() => {
		getInvoiceLocations()
			.then(res => {
				setLocations(res.data.data);
			})
			.catch(err => console.log("eee", err));
	}, []);

	useEffect(() => {
		console.log("in invoice");
		setTimeout(() => {
			window.history.replaceState({}, null, window.location.href.replace("/invoices", ""));
		}, 1000);
	}, []);

	const handleSelect = id => {
		setActiveLocation(id);
		localStorage.setItem("activeInvoice", id);
	};

	if (!fetched) return <Loading />;
	return (
		<Box
			pt="34px"
			pb="34px"
			display={"flex"}
			justifyContent="center"
			flexWrap={["wrap", "wrap"]}
			width="100%"
			gridTemplateColumns={["1fr", "repeat(3,1fr)"]}
			gridGap="1em"
		>
			<Box
				p="2em"
				borderRadius="0px"
				borderColor="#CBCCCB"
				borderWidth="1px"
				backgroundColor="transparent"
				boxShadow="none"
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexdiection={["column", "row"]}
				width="350px"
			>
				<PrimaryButton
					mt="1em"
					p="3em"
					label="Create"
					onClick={() => {
						setPage(2);
						console.log("clickeed");
					}}
				/>
			</Box>
			{locations.map(
				({ id, address_line_1, address_line_2, city, state, country, postal_code }) => (
					<Box
						key={id}
						p="2em"
						borderRadius="0px"
						borderColor={id == activeLocation ? "green.300" : "#CBCCCB"}
						borderWidth="1px"
						backgroundColor="transparent"
						boxShadow="none"
						_hover={{ backgroundColor: "#F2F1F1" }}
						_focus={{
							backgroundColor: "#F2F1F1",
							borderColor: "#1274BC",
						}}
					>
						<Text>{address_line_1}</Text>
						<Text>{address_line_2}</Text>
						<Text>{`${city}, ${state} ${postal_code}, ${country}`}</Text>
						<Flex gap={"14px"}>
							<PrimaryButton
								mt="1em"
								label="Select"
								onClick={() => {
									handleSelect(id);
								}}
							/>
							<PrimaryButton
								mt="1em"
								label="Edit"
								onClick={() => {
									setPage(5);
									console.log("555");
									setActiveEdit(id);
								}}
							/>
						</Flex>
					</Box>
				),
			)}
		</Box>
	);
}

export default Content;
