import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiCallAc, setActiveCompanyToken } from "../../../api";
import { fetchLocations } from "../../../store/actions";
import { content } from "./components/content";
import Layout from "./components/Layout";

function Locations() {
	const dispatch = useDispatch();
	const [chartsModal, setChartsModal] = useState({ active: false, itemId: null });
	const [mapModal, setMapModal] = useState(false);
	const [storyModal, setStoryModal] = useState(false);
	const [fetchLocationsType, setFetchLocationsType] = useState("published");
	const {
		locations: { current_page, next_page_url },
		locations,
		fetching,
		fetched: fetchedLocations,
	} = useSelector(state => state.fetchLocations);
	const company = useSelector(state => state.fetchCompany.company);
	const fetched = useSelector(state => state.createLocation.fetched);

	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];

	const handleClose = () => {
		setMapModal(false);
		setChartsModal({
			active: false,
			itemId: null,
		});
	};

	const handleStoryClose = () => {
		setStoryModal(false);
	};

	useEffect(() => {
		dispatch({ type: "RESET_STATE" });
	}, []);

	useEffect(() => {
		setActiveCompanyToken(company.id);
		if (apiCallAc.defaults.headers.common) {
			if (!fetchedLocations) dispatch(fetchLocations(fetchLocationsType, 1));
		}
	}, [company, lang, fetched]);
	useEffect(() => {
		dispatch(fetchLocations(fetchLocationsType, 1, true));
	}, [fetchLocationsType]);

	return (
		<Layout
			fetching={fetching}
			loading={fetching}
			mapModal={mapModal}
			handleClose={handleClose}
			chartsModal={chartsModal}
			setChartsModal={setChartsModal}
			setMapModal={setMapModal}
			storyModal={storyModal}
			setStoryModal={setStoryModal}
			handleStoryClose={handleStoryClose}
			header={t.header}
			locations={locations}
			lang={lang}
			next_page_url={next_page_url}
			current_page={current_page}
			fetchLocationsType={fetchLocationsType}
			setFetchLocationsType={setFetchLocationsType}
		/>
	);
}

export default Locations;
