import React, { useState } from "react";
import { Box, Text, Divider, useDisclosure } from "@chakra-ui/react";
import Panels from "./Panels";
import Preview from "./locations-preview/Preview";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { content } from "./content";
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from "@chakra-ui/react";

function Layout({ header, lang, id, formik, createKeyword }) {
	const { isOpen, onClose } = useDisclosure();

	const [activeTab, setActiveTab] = useState(0);

	const [submitClicked, setSubmitClicked] = useState(false);
	const { title } = formik.errors;
	const t = content[lang];
	const [empty, setEmpty] = useState(true);
	const [showMap, setShowMap] = useState(false);

	return (
		<Box
			h="100%"
			display="flex"
			flexDirection="column"
			paddingX="20px"
			paddingY={["5em", "10px"]}
			paddingBottom="0"
		>
			<Box py="3" display={["none", "block"]}>
				<Box mb="1">
					<Text fontSize="24px">{header}</Text>
				</Box>
				<Box w="40px">
					<Divider borderBottom="4px" opacity="1" />
				</Box>
			</Box>

			<Box display="grid" gridTemplateColumns={["1fr", "1fr"]} gridGap="1em">
				<Panels
					showMap={showMap}
					setShowMap={setShowMap}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					submitClicked={submitClicked}
					empty={empty}
					setEmpty={setEmpty}
					flex="5"
					id={id}
					formik={formik}
					lang={lang}
					createKeyword={createKeyword}
				></Panels>

				<Box
					position={["absolute", "relative"]}
					left="0px"
					bottom="0px"
					width="100%"
					bg="#FFF"
					zIndex="2"
					pt="1em"
					display="flex"
					justifyContent="center"
					alignItems="center"
					my="3"
				>
					<PrimaryButton
						minWidth="15%"
						hoverColor="#B0D352"
						label={t.publish}
						onClick={() => {
							formik.handleSubmit();
							setSubmitClicked(true);
						}}
						disabled={activeTab === 0 && title}
						mr="2"
					/>
					<PrimaryButton
						minWidth="15%"
						prompt={true}
						ml="2"
						hoverColor="#F58121"
						label={t.reset}
						onClick={() => {
							formik.resetForm();
							setActiveTab(0);
						}}
					/>
				</Box>
			</Box>
			<Modal display={["flex", "none"]} size="full" isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody width="100vw" h={["100%", "100vh"]}>
						<Preview
							display={["flex", "none"]}
							setSubmitClicked={setSubmitClicked}
							formik={formik}
							lang={lang}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default Layout;
