import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";

const Success = () => {
	useEffect(() => {
		console.log("success payment page");
	}, []);
	return (
		<Box>
			<Text>asdasdsadsa asdsadsa</Text>
		</Box>
	);
};

export default Success;
