import React from "react";
import { Box } from "@chakra-ui/react";
import Client from "../panels/Client";
import Tab from "../../../shared/tabs";
import Search from "../../../shared/keywords";
// const Builder = lazy(() => import("../../../builder-refactor"));
import Editor from "../../../Editor";

function Panels({
	lang,
	newsId,
	formik,
	locations,
	activeTab,
	setActiveTab,
	submitClicked,
	...rest
}) {
	const options = {
		AlbumText: <Client newsId={newsId} formik={formik} lang={lang} />,
		Keywords: <Search formik={formik} lang={lang} type="news" />,
		Builder: <Editor formik={formik} refer={"events"} variant="gallery" />,
	};

	const fields = [["title", "description", "location"], ["keywords"], ["gallery"]];

	return (
		<Box
			overflow="scroll"
			height={{ base: "100%", md: "calc(100% - 60px)" }}
			display="flex"
			flexDir="column"
			justifyContent="space-between"
			{...rest}
		>
			<Tab
				submitClicked={submitClicked}
				formik={formik}
				fields={fields}
				active={activeTab}
				setActive={setActiveTab}
				options={options}
			/>
		</Box>
	);
}

export default Panels;
