import { chatsActions } from "../../actionTypes";
import { uploadToken } from "../../../api/libs/chats";

export const storeToken = data => {
	return async dispatch => {
		dispatch({
			type: chatsActions.STORE_TOKEN,
		});
		uploadToken(data)
			.then(res => {
				dispatch({
					type: chatsActions.STORE_TOKEN_REJECTED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: chatsActions.STORE_TOKEN_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
