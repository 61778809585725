export const draw = (state, setState) => {
	let gradient;

	// draw colored pallete

	gradient = state.context.createLinearGradient(0, 0, state.width, 0);
	gradient.addColorStop(0, "rgb(255,0,0)");
	gradient.addColorStop(0.15, "rgb(255,0,255)");
	gradient.addColorStop(0.33, "rgb(0,0,255)");
	gradient.addColorStop(0.49, "rgb(0,255,0)");
	gradient.addColorStop(0.67, "rgb(255,0,0)");
	gradient.addColorStop(0.85, "rgb(255,255,0)");
	gradient.addColorStop(0.99, "rgb(255,255,255)");
	gradient.addColorStop(0.1, "rgb(255,0,0)");
	state.context.fillStyle = gradient;
	state.context.fillRect(0, 0, state.width, state.height);

	// draw black/white pallete

	gradient = state.context.createLinearGradient(0, 0, 0, state.height);
	gradient.addColorStop(0, "rgba(255,255,255, 1)");
	gradient.addColorStop(0.5, "rgba(255,255,255, 0)");
	gradient.addColorStop(0.5, "rgba(0,0,0, 0)");
	gradient.addColorStop(1, "rgba(0,0,0, 1)");
	state.context.fillStyle = gradient;
	state.context.fillRect(0, 0, state.width, state.height);

	// draw handle

	state.context.beginPath();
	state.context.arc(
		state.pickerHandle.x,
		state.pickerHandle.y,
		state.pickerHandle.width,
		0,
		Math.PI * 2,
	);
	state.context.strokeStyle = "black";

	if (state.pickerHandle.y >= 200) {
		//interpolation for stroke color
		const calcRgba = () => {
			let x1 = 200,
				x2 = 300,
				X = state.pickerHandle.y;
			let res = (255 / (x2 - x1)) * (X - x1);
			if (res <= 255) {
				return res;
			} else return 255;
		};
		let val = calcRgba();
		state.context.strokeStyle = `rgb(${val},${val},${val} )`;
	}

	state.context.stroke();
	state.context.closePath();

	// callback function

	setState(state => {
		state.isReady = true;
	});
};

function toHex(c) {
	let hex = c.toString(16);
	return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
	return "#" + toHex(r) + toHex(g) + toHex(b);
}

export const parseRgb = str => {
	if (str.includes("rgb")) {
		let arr = str.substring(str.indexOf("(") + 1, str.lastIndexOf(")")).split(/,s*/);

		return {
			r: arr[0],
			g: arr[1],
			b: arr[2],
		};
	} else {
		let toRgb = hexToRgb(str);
		return {
			...toRgb,
		};
	}
};

export function rgbStringToHex(str) {
	let arr = str.substring(str.indexOf("(") + 1, str.lastIndexOf(")")).split(/,s*/);
	let red = arr[0];
	let green = arr[1];
	let blue = arr[2];

	let res = rgbToHex(red, green, blue);
	return res;
}

export function hexToRgb(hex) {
	let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}

export const getPickedColor = (ctx, handle, updatePickedColor) => {
	let data = ctx.getImageData(handle.x, handle.y, 1, 1);
	let rgbObj = {
		r: data.data[0],
		g: data.data[1],
		b: data.data[2],
	};
	let sRGB = `rgb(${data.data[0]}, ${data.data[1]}, ${data.data[3]})`;
	let hex = rgbToHex(rgbObj.r, rgbObj.g, rgbObj.b);
	updatePickedColor(prev => ({
		...prev,
		pickedColor: {
			sRGB,
			hex,
		},
	}));
	return { sRGB, hex };
};

export const getColor = (ctx, handle) => {
	let data = ctx.getImageData(handle.x, handle.y, 1, 1);
	let rgbObj = {
		r: data.data[0],
		g: data.data[1],
		b: data.data[2],
	};
	let sRGB = `rgb(${data.data[0]}, ${data.data[1]}, ${data.data[3]})`;
	let hex = rgbToHex(rgbObj.r, rgbObj.g, rgbObj.b);
	return { sRGB, hex };
};

export const positionHandle = (ctx, col, picker_x, picker_y, setState) => {
	if (picker_x && picker_y) {
		setState(state => {
			state.pickerHandle = {
				...state.pickerHandle,
				x: picker_x,
				y: picker_y,
			};
		});
	} else {
		setState(state => {
			state.pickerHandle = {
				...state.pickerHandle,
				x: 0,
				y: 300,
			};
		});
	}
};
