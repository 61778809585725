import { newsActions } from "../../actionTypes";
import { restoreNews } from "../../../api/libs/news";
import toast from "react-hot-toast";

export const recoverNews = (id, history) => {
	return async dispatch => {
		dispatch({
			type: newsActions.RECOVER_NEWS,
		});
		restoreNews(id)
			.then(res => {
				dispatch({
					type: newsActions.RECOVER_NEWS_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully recovered news");
				history.push(`/news/edit/${id}`);
			})
			.catch(err => {
				dispatch({
					type: newsActions.RECOVER_NEWS_REJECTED,
					payload: err.response,
				});
			});
	};
};
