import React from "react";
import { Box } from "@chakra-ui/react";

function ScrollContainer(props) {
	return (
		<Box
			w="100%"
			h="50px"
			overflowX="auto"
			overflowY="hidden"
			whiteSpace="nowrap"
			display="flex"
			alignItems="center"
			padding={0}
			px="10px"
		>
			{props.children}
		</Box>
	);
}

export default ScrollContainer;
