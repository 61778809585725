import { Button, IconButton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function OverlayButton({ icon, label, active = true, action = () => {} }) {
	if (label) {
		return (
			<Button
				pointerEvents="visible"
				cursor="pointer"
				outline="none"
				_active={{ outline: "none", bg: "rgba(176,177,175, 0.9)" }}
				_focus={{
					outline: "none",
					boxShadow: "none",
					bg: "rgba(176,177,175, 0.7)",
				}}
				_hover={{
					bg: "rgba(176,177,175, 0.7)",
				}}
				h="45px"
				borderRadius="full"
				aria-label="Canvas-Button"
				bg="rgba(176,177,175, 0.7)"
				leftIcon={<FontAwesomeIcon color="white" icon={icon} style={{ fontSize: "22px" }} />}
				variant="outline"
				onClick={active ? action : () => {}}
				color="white"
			>
				{label && label}
			</Button>
		);
	}
	return (
		<Button
			// _notLast={{ marginRight: "10px" }}
			mr="10px"
			as={IconButton}
			pointerEvents="visible"
			cursor="pointer"
			outline="none"
			_active={{ outline: "none", bg: "rgba(176,177,175, 0.9)" }}
			_focus={{
				outline: "none",
				boxShadow: "none",
				bg: "rgba(176,177,175, 0.7)",
			}}
			_hover={{
				bg: "rgba(176,177,175, 0.7)",
			}}
			h="40px"
			w="40px"
			borderRadius="full"
			aria-label="Canvas-Button"
			bg="rgba(176,177,175, 0.7)"
			icon={<FontAwesomeIcon color="white" icon={icon} style={{ fontSize: "20px" }} />}
			variant="outline"
			onClick={active ? action : () => {}}
		/>
	);
}

export default OverlayButton;
