import { usersActions } from "../../actionTypes";

const initialState = {
	users: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case usersActions.FETCH_USERS: {
			return { ...initialState, fetching: true };
		}

		case usersActions.FETCH_USERS_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				users: action.payload,
			};
		}

		case usersActions.FETCH_USERS_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
