import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/form-control";
import { Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/skeleton";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";
import { fetchLocations } from "../../../store/actions";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Marker, Circle } from "@react-google-maps/api";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { content } from "../../locations/list/components/content";
import { getOriginal } from "../../../utils/imgUri";
import building from "../../../assets/images/cat5.png";
import coupon from "../../../assets/images/cat1.png";
import { handleScroll } from "../../../utils/scroll";

export default function CustomLocation({ formik, column }) {
	const dispatch = useDispatch();
	const [center, setCenter] = useState({ lat: 0, lng: 0 });
	const [selectedLocation, setSelectedLocation] = useState("");

	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];
	const {
		locations,
		fetched,
		fetching,
		locations: { current_page, next_page_url },
	} = useSelector(state => state.fetchLocations);
	const [modalState, setModalState] = useState(false);

	useEffect(() => {
		if (!fetched) dispatch(fetchLocations(1));
	}, []);

	const containerStyle = {
		width: "100%",
		height: "100%",
	};
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
	});
	const mapRef = useRef();
	const [map, setMap] = React.useState(null);
	const onLoad = map => {
		const bounds = new window.google.maps.LatLngBounds();

		if (fetched) {
			locations.data.forEach(item => {
				bounds.extend({
					lat: parseFloat(item.latitude),
					lng: parseFloat(item.longitude),
				});
			});
		}

		map.fitBounds(bounds);
		map.setZoom(map.getZoom() - 1);
		setMap(map);
	};

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);

	if (!fetched && !isLoaded) return <Skeleton height="2em" />;

	return (
		<FormControl
			display="flex"
			flexDirection="column"
			mt="0.5em"
			isInvalid={formik.errors["custom_location"]}
			id="custom_location"
		>
			{modalState && (
				<Modal
					header="Select locations"
					handleClose={() => setModalState(false)}
					display="grid"
					gridTemplateRows="1fr 1fr"
					overflow="hidden"
					gridGap="1em"
					p="1em"
				>
					<Box
						border="2px solid #000"
						display="flex"
						flexDirection="column"
						overflowY="scroll"
						css={{
							"&::-webkit-scrollbar": {
								width: "10px",
							},

							"&::-webkit-scrollbar-track": {
								background: "#f1f1f1",
							},

							"&::-webkit-scrollbar-thumb": {
								background: "#8B8C8B",
							},

							"&::-webkit-scrollbar-thumb:hover": {
								background: "#555",
							},
						}}
						onScroll={e =>
							handleScroll(
								e,
								() => dispatch(fetchLocations(current_page + 1)),
								current_page,
								fetching,
								next_page_url,
							)
						}
					>
						<Table variant="simple">
							<Thead
								zIndex="2"
								boxShadow="0 3px 0px -2px black"
								position="sticky"
								top="0px"
								backgroundColor="#FFF"
							>
								<Tr>
									<Th fontSize="1.2em">{t.logo}</Th>
									<Th fontSize="1.2em">{t.title}</Th>
									<Th fontSize="1.2em">{t.address}</Th>
									<Th fontSize="1.2em">{t.location_count}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{locations?.data?.map((item, index) => (
									<Tr
										onClick={() => {
											setSelectedLocation(item.id);

											setCenter({
												lat: parseFloat(item.latitude),
												lng: parseFloat(item.longitude),
											});
											map.setZoom(16);
										}}
										cursor="pointer"
										backgroundColor={
											item.id === selectedLocation ||
											formik.values.custom_location.find(s => s.location_id === item.id)
												? "#AFCB2E"
												: "transparent"
										}
										_hover={{
											backgroundColor:
												item.id === selectedLocation ||
												formik.values.custom_location.find(s => s.location_id === item.id)
													? "#A2CB2E"
													: "#F2F1F0",
										}}
										key={index}
									>
										<Td>
											<div
												className={item.pinnedGalleries.length ? "rainbow" : ""}
												style={{
													position: "relative",
													background: "rgba(0, 0, 0, 0.8)",

													borderRadius: `${100}%`,
													width: 70,
													height: 70,
												}}
											>
												<img
													style={{
														width: 70,
														height: 70,
														borderRadius: `${100}%`,
														border: "none",
														transform: "scale(0.97)",
														background: "#fff",
														cursor: "pointer",
													}}
													src={getOriginal(item.logo)}
													alt="Company Logo"
												/>
											</div>
										</Td>
										<Td>{item.title}</Td>
										<Td>{item.street + " " + item.street_number + ", " + item.city}</Td>
										<Td textAlign="center">
											{formik.values.custom_location.find(s => s.location_id === item.id)
												? formik.values.custom_location.find(s => s.location_id === item.id)
														.coupon_coordinates.length
												: "0"}
										</Td>
									</Tr>
								))}
								{!fetched && (
									<Tr
										_hover={{
											backgroundColor: "#F2F1F0",
										}}
									>
										<Td>
											<Skeleton height="20px" />
										</Td>
										<Td>
											<Skeleton height="20px" />
										</Td>

										<Td>
											<Skeleton height="20px" />
										</Td>

										<Td>
											<Skeleton height="20px" />
										</Td>
									</Tr>
								)}
							</Tbody>
						</Table>
					</Box>
					<Box border="2px solid #000">
						<GoogleMap
							center={center}
							ref={mapRef}
							mapContainerStyle={containerStyle}
							onLoad={onLoad}
							onUnmount={onUnmount}
						>
							{formik.values.custom_location.map(item =>
								item?.coupon_coordinates.map((coords, index) => (
									<Marker
										icon={{
											url: coupon,
											scaledSize: {
												width: 32,
												height: 32,
											},
											anchor: new window.google.maps.Point(32, 32),
										}}
										key={index}
										onClick={e => {
											formik.setFieldValue(
												"custom_location",
												formik.values.custom_location.map(item => ({
													...item,
													coupon_coordinates: item.coupon_coordinates.filter(
														it =>
															!(
																it.longitude === coords.longitude && it.latitude === coords.latitude
															),
													),
												})),
											);
										}}
										position={{
											lat: parseFloat(coords.latitude),
											lng: parseFloat(coords.longitude),
										}}
									></Marker>
								)),
							)}
							{locations.data.map((location, index) => (
								<Box key={index}>
									<Marker
										icon={{
											url: building,
											scaledSize: {
												width: 32,
												height: 32,
											},
											anchor: new window.google.maps.Point(32, 32),
										}}
										draggable={false}
										position={{
											lat: parseFloat(location.latitude),
											lng: parseFloat(location.longitude),
										}}
									></Marker>
									<Circle
										radius={300}
										center={{
											lat: parseFloat(location.latitude),
											lng: parseFloat(location.longitude),
										}}
										options={{
											strokeColor: "#AFCB2E",
											strokeOpacity: 0.8,
											strokeWeight: 2,
											fillColor: "#AFCB2E",
											fillOpacity: 0.35,
											clickable: true,
											draggable: false,
											editable: false,
											visible: true,
											radius: 300,
											zIndex: 1,
										}}
										onClick={e => {
											if (
												formik.values.custom_location.filter(e => e.location_id === location.id)
													.length > 0
											) {
												formik.setFieldValue(
													"custom_location",
													formik.values.custom_location.map(item =>
														item.location_id === location.id
															? {
																	...item,
																	coupon_coordinates: [
																		...item.coupon_coordinates,
																		{
																			latitude: e.latLng.lat(),
																			longitude: e.latLng.lng(),
																		},
																	],
															  }
															: item,
													),
												);
											} else {
												formik.setFieldValue("custom_location", [
													...formik.values.custom_location,
													{
														location_id: location.id,
														coupon_coordinates: [
															{
																latitude: e.latLng.lat(),
																longitude: e.latLng.lng(),
															},
														],
													},
												]);
											}
										}}
									></Circle>
								</Box>
							))}
						</GoogleMap>
					</Box>
				</Modal>
			)}
			<FormLabel>{lang === "de" ? "Standorte" : "Locations"}</FormLabel>
			<Box display="flex" flexDirection="column">
				<Box
					onClick={() => setModalState(true)}
					height="2em"
					borderRadius="0px"
					borderColor="#CBCCCB"
					borderWidth="1px"
					backgroundColor="transparent"
					boxShadow="none"
					_hover={{ backgroundColor: "#F2F1F1" }}
					_focus={{ backgroundColor: "#F2F1F1" }}
					as={Button}
				>
					{lang === "de" ? "Benutzerdefinierte Standorte" : "Custom locations"} (
					{formik.values.custom_location.reduce(
						(acc, element) => acc + element.coupon_coordinates.length,
						0,
					)}
					)
				</Box>
				<FormErrorMessage position={column ? "absolute" : "relative"} bottom="-1.2em">
					{formik.errors["custom_location"]}
				</FormErrorMessage>
			</Box>
		</FormControl>
	);
}
