import React from "react";
import { FormControl, FormLabel, FormErrorMessage, Button } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { getDescendantProp } from "../../../utils/common";

export default function TimeCategories({
	label,
	type,
	id,
	column,
	formik,
	setUploaded,
	labelBackground,
	labelAlign,
	containerHeight,
	customChange,
	labels,
	values,
	...rest
}) {
	return (
		<FormControl
			display="grid"
			gridTemplateColumns={
				column ? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "2fr 8fr", "2fr 8fr"]
			}
			alignItems="center"
			gridGap={labelBackground && "1em"}
			isInvalid={getDescendantProp(formik.errors, id) && getDescendantProp(formik.touched, id)}
			id={id}
		>
			<FormLabel
				fontFamily="Segoe UI Semilight"
				_focus={{
					color: labelBackground ? "#FFF" : "#1274BC",
					fontWeight: 700,
				}}
				height={labelBackground && "2em"}
				m={labelBackground && "0"}
				display={labelBackground && "flex"}
				width={labelBackground && "100%"}
				alignItems={labelBackground && "center"}
				justifyContent={labelBackground && "center"}
				backgroundColor={labelBackground}
				textAlign={labelAlign}
			>
				{label}
			</FormLabel>
			<Box display="flex" flexDirection="column">
				<Box display="grid" gridTemplateColumns={["1fr", `repeat(${values.length}, 1fr)`]}>
					{values.map((val, index) => (
						<Button
							key={index}
							px="4"
							py="0"
							height="2em"
							borderRadius="0px"
							borderColor="#CBCCCB"
							borderWidth="1px"
							backgroundColor={formik.values[id] === val ? "#0E65AE" : "transparent"}
							boxShadow="none"
							_hover={{ backgroundColor: "#0E65AE", color: "#FFF" }}
							_focus={{
								backgroundColor: "#0E65AE",
								borderColor: formik.errors[id] ? "red" : "#1274BC",
							}}
							color={formik.values[id] === val ? "#FFF" : "#000"}
							placeholder={label}
							_placeholder={{ color: "rgba(0,0,0,0.5)" }}
							onClick={customChange ? () => customChange(val) : () => formik.setFieldValue(id, val)}
							{...rest}
						>
							{labels[index]}
						</Button>
					))}
				</Box>
				<FormErrorMessage position={column ? "absolute" : "relative"} bottom="-1.2em">
					{getDescendantProp(formik.errors, id)}
				</FormErrorMessage>
			</Box>
		</FormControl>
	);
}
