import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "../shared/Loading";
import Content from "./components/Content";

function NewCompany({ welcome }) {
	const { language: lang, fetching } = useSelector(state => state.languageProvider);
	useEffect(() => {
		localStorage.removeItem("activeInvoice");
	}, []);
	if (fetching) return <Loading />;
	return <Content lang={lang} />;
}

export default NewCompany;
