export const content = {
	en: {
		email: "Email",
		phone: "Phone",
		name: "Name",
		logo: "Logo",
		address: "Address",
		create: "Create",
		create_new_company: "Create new company",
		welcome: "Welcome",
	},
	de: {
		email: "Email",
		phone: "Telefon",
		name: "Firmenname",
		logo: "Logo",
		address: "Die Anschrift",
		create: "Schaffen",
		create_new_company: "Erstellen Sie Ihr Unternehmen bei Yezzgo",
		welcome: "Willkommen",
	},
};
