import React, { useState } from "react";
import { Box, Image, Text, Spinner } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Draggable } from "react-beautiful-dnd";

function Slide({
	data,
	index,
	indexStr,
	isActive,
	img,
	isPano,
	length,
	onSelectSlide,
	onRemoveSlide,
	onCloneSlide,
	slideLoading,
}) {
	const [mo0, setMo0] = useState(false);
	const [md0, setMd0] = useState(false);
	const [mo1, setMo1] = useState(false);
	const [md1, setMd1] = useState(false);
	const canDelete = () => {
		if (isActive && length > 1) {
			return true;
		}
		return false;
	};

	const canClone = () => {
		if (isActive && length < 5) {
			return true;
		}
		return false;
	};

	return (
		<Draggable draggableId={data.index} index={index}>
			{(provided, snapshotDrag) => {
				let transform = provided.draggableProps.style.transform;

				if (snapshotDrag.isDragging && transform) {
					transform = transform.replace(/\(.+,/, "(0,");
				}

				const style = {
					...provided.draggableProps.style,
					transform,
				};
				return (
					<div
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
						style={style}
					>
						<Box
							display="flex"
							justifyContent="center"
							borderBottom={"1px solid #DEDFDE"}
							backgroundColor="white"
							userSelect="none"
							padding={"15px 0"}
							onClick={onSelectSlide}
						>
							<Text pr="5px" mt="-5px" color={isActive ? "black" : "#D7D8D7"}>
								{indexStr}
							</Text>
							<Box h="30px" w="2px" bg={isActive ? "#9CC862" : "#D7D8D7"} mr="5px" />
							<Box
								h="80px"
								w="40%"
								bg="#F2F1F1"
								pos="relative"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								{img && (
									<>
										{slideLoading && isActive ? (
											<Box
												h="100%"
												w="100%"
												display="flex"
												justifyContent="center"
												alignItems="center"
											>
												<Spinner color="#AACD56" size="xl" />
											</Box>
										) : (
											<Image
												mr="auto"
												ml="auto"
												maxH={isPano ? "80%" : "100%"}
												opacity={isPano ? 0.5 : 1}
												maxW="100%"
												src={img}
												alt={"slide"}
											/>
										)}
									</>
								)}
							</Box>
							<Box pl="10px" display="flex" flexDir="column" alignSelf="flex-end">
								<Box
									w="120%"
									textAlign="center"
									cursor={canClone() && mo0 ? "pointer" : "default"}
									onClick={e => {
										e.stopPropagation();
										onCloneSlide();
									}}
									onMouseOver={() => {
										setMo0(true);
									}}
									onMouseDown={() => {
										setMd0(true);
									}}
									onMouseOut={() => {
										setMo0(false);
										setMd0(false);
									}}
									onMouseUp={() => {
										setMd0(false);
									}}
								>
									{canClone() ? (
										<FontAwesomeIcon
											icon="copy"
											color={isActive && md0 ? "#0066AC" : isActive && !md0 ? "black" : "#D7D8D7"}
										/>
									) : (
										<FontAwesomeIcon icon="copy" color={"#D7D8D7"} />
									)}
								</Box>
								<Box h="1px" width="120%" bg={!canClone() || !canDelete() ? "#D7D8D7" : "black"} />
								<Box
									w="120%"
									textAlign="center"
									cursor={canDelete() && mo1 ? "pointer" : "default"}
									onClick={e => {
										e.stopPropagation();
										onRemoveSlide();
									}}
									onMouseOver={() => {
										setMo1(true);
									}}
									onMouseDown={() => {
										setMd1(true);
									}}
									onMouseOut={() => {
										setMo1(false);
										setMd1(false);
									}}
									onMouseUp={() => {
										setMd1(false);
									}}
								>
									{canDelete() ? (
										<FontAwesomeIcon
											icon="times-circle"
											color={isActive && md1 ? "#0066AC" : isActive && !md1 ? "black" : "#D7D8D7"}
										/>
									) : (
										<FontAwesomeIcon icon="times-circle" color={"#D7D8D7"} />
									)}
								</Box>
							</Box>
						</Box>
					</div>
				);
			}}
		</Draggable>
	);
}

export default Slide;
