import { companyActions } from "../../actionTypes";
import { getCompanyCategories } from "../../../api/libs/company";

export const fetchCompanyCategories = () => {
	return async dispatch => {
		dispatch({
			type: companyActions.FETCH_COMPANY_CATEGORIES,
		});
		getCompanyCategories()
			.then(res => {
				dispatch({
					type: companyActions.FETCH_COMPANY_CATEGORIES_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				console.log(err.response.data);
				dispatch({
					type: companyActions.FETCH_COMPANY_CATEGORIES_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
