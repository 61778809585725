import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Protected from "../components/shared/Protected";
import Verify from "../components/auth/verify-email";

function Router() {
	return (
		<Switch>
			<Route exact path="/" component={Protected} />
			<Route path="/email/verify" component={Verify} />
			<Redirect to="/" />
		</Switch>
	);
}

export default Router;
