import { stripeActions } from "../../actionTypes";
import { changeSubscription } from "../../../api/libs/stripe";
import toast from "react-hot-toast";
import { fetchStripeSubscriptions } from "./fetchStripeSubscriptions";
import { fetchCompany } from "../company";

export const swapStripeSubscription = data => {
	return async dispatch => {
		dispatch({
			type: stripeActions.SWAP_STRIPE_SUBSCRIPTION,
		});
		changeSubscription(data)
			.then(res => {
				dispatch({
					type: stripeActions.SWAP_STRIPE_SUBSCRIPTION_FULFILLED,
					payload: res.data,
				});
				dispatch(fetchStripeSubscriptions());
				dispatch(fetchCompany(localStorage.getItem("activeCompany")));
				toast.success("Successfully swapped plan");
			})
			.catch(err => {
				dispatch({
					type: stripeActions.SWAP_STRIPE_SUBSCRIPTION_REJECTED,
					payload: err.response.data.error,
				});
			});
	};
};
