import { useSlides } from "../../Builder/store";
export const onLoadVideo = (index, media, thumb, resetState) => {
	const modifySlide = useSlides.getState().modifySlide;
	modifySlide(index, {
		isVideo: true,
		video: media,
		orientation: "squere",
		isEdited: true,
		png: thumb,
	});
	resetState();
};
