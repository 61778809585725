import { apiCallAc } from "..";

export function attachKeywords(type, id, keywords) {
	switch (type) {
		case "locations":
			return apiCallAc.post(`/dashboard/keywords/locations/${id}`, {
				keyword_id: keywords,
			});
		case "events":
			return apiCallAc.post(`/dashboard/keywords/events/${id}`, {
				keyword_id: keywords,
			});
		default:
			return apiCallAc.post(`/dashboard/keywords/news/${id}`, {
				keyword_id: keywords,
			});
	}
}

export function getKeywords(type, id) {
	switch (type) {
		case "locations":
			return apiCallAc.get(`/dashboard/keywords/locations/${id}`);
		case "events":
			return apiCallAc.get(`/dashboard/keywords/events/${id}`);
		default:
			return apiCallAc.get(`/dashboard/keywords/news/${id}`);
	}
}

export function addKeyword(data) {
	return apiCallAc.post("/dashboard/keywords", data);
}

export function removeKeyword(id) {
	return apiCallAc.delete("/dashboard/keywords/" + id);
}

export function getMyKeywords() {
	return apiCallAc.get(`/dashboard/myKeywords`);
}

export function showKeywords(
	type,
	location_category_ids,
	event_format_id,
	event_category_id,
	event_sub_category_id,
) {
	switch (type) {
		case "locations":
			return apiCallAc.get(`/dashboard/keywords?location_category_id=${location_category_ids}`);
		case "events":
			return apiCallAc.get(
				`/dashboard/keywords?location_category_id=${location_category_ids}&event_format_id=${event_format_id}&event_category_id=${event_category_id}&event_sub_category_id=${event_sub_category_id}`,
			);
		default:
			return apiCallAc.get(
				`/dashboard/keywords?news=true&location_category_id=${location_category_ids}`,
			);
	}
}
