import React from "react";
import Selector from "./components/Selector";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompany } from "../../../store/actions";
import { useHistory } from "react-router";

function CompanySelector(...rest) {
	const dispatch = useDispatch();
	const lang = useSelector(state => state.languageProvider.language);
	const history = useHistory();
	const { name: comp, logo, id } = useSelector(state => state.fetchCompany.company) || "";
	const companies = useSelector(state => state.fetchCompanies.companies.data) || [];

	const handleSelect = id => {
		history.push("/");
		dispatch(fetchCompany(id));
	};

	return (
		<Selector
			companies={companies}
			currentId={id}
			lang={lang}
			current={comp}
			logo={logo}
			set={handleSelect}
			{...rest}
		/>
	);
}

export default CompanySelector;
