export const content = {
	en: {
		name: "Name",
		description: "Description",
		action: "Action",
		updated_at: "Updated at",
		ip_address: "IP Address",
		browser: "Browser",
		close: "Close",
		open_details: "OPEN DETAILS",
		log_details: "Log details",
		payment: "Payment",
		old: "Old",
		new: "New",
		empty: "Empty",
		created: "Created",
	},
	de: {
		name: "Name",
		description: "Beschreibung",
		action: "Handlung",
		updated_at: "Aktualisiert am",
		ip_address: "IP Addresse",
		browser: "Browser",
		close: "Nah dran",
		open_details: "OFFENE DETAILS",
		log_details: "Log-Details",
		payment: "Zahlung",
		old: "Alt",
		new: "Neu",
		empty: "Leer",
		created: "Erstellt",
	},
};
