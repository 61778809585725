import React, { useState, useRef, useEffect } from "react";
import {
	FormControl,
	FormErrorMessage,
	Progress,
	InputRightElement,
	InputGroup,
	InputLeftElement,
	Textarea,
	Button,
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { getDescendantProp } from "../../../utils/common";
import PrimaryButton from "../buttons/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { uploadMedia } from "../../../api/libs/upload";

export default function ChatInput({
	label,
	type,
	id,
	column,
	formik,
	setUploaded,
	labelBackground,
	labelAlign,
	containerHeight,
	autoComplete,
	customChange,
	handleSubmit,
	setMediaLoading,
	...rest
}) {
	const [loading] = useState(false);
	const [progress] = useState(0);

	const fileInputRef = useRef();
	const [choosenFile, setChooseFile] = useState(null);

	useEffect(() => {
		if (choosenFile) {
			handleMediaUpload();
		}
	}, [choosenFile]);

	const handleOpenPicker = () => {
		fileInputRef.current?.click();
	};

	const handleMediaUpload = () => {
		setMediaLoading(choosenFile);
		const formData = new FormData();
		formData.append("image[0]", choosenFile);
		uploadMedia(formData)
			.then(res => {
				let values = {
					message: res.data.images[0],
				};
				handleSubmit(values);
			})
			.catch(error => {
				console.error("Error:", error);
			})
			.finally(() => {
				setChooseFile(null);
				setMediaLoading(null);
			});
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			<FormControl
				display="grid"
				gridTemplateColumns="1fr"
				alignItems="center"
				gridGap={labelBackground && "1em"}
				isInvalid={getDescendantProp(formik.errors, id) && getDescendantProp(formik.touched, id)}
				id={id}
				position="relative"
			>
				<Box display="flex" flexDirection="column">
					<InputGroup size="md" width="100%">
						<Textarea
							height={"90px"}
							borderRadius="0px"
							// borderColor="#CBCCCB"
							// borderWidth="1px"
							backgroundColor="transparent"
							boxShadow="none"
							_hover={{ backgroundColor: "#F2F1F1" }}
							// _focus={{
							//   backgroundColor: "#F2F1F1",
							//   borderColor: formik.errors[id] ? "red" : "#1274BC",
							// }}
							color={"#000"}
							placeholder={label}
							_placeholder={{ color: "rgba(0,0,0,0.5)" }}
							type={type}
							multiple={true}
							autoComplete={autoComplete}
							onChange={formik.handleChange}
							value={formik.values[id]}
							{...rest}
						/>
						<InputLeftElement marginTop={"11vh"} onClick={handleOpenPicker}>
							<FontAwesomeIcon
								icon="fa-solid fa-image"
								// color="#286AA6"
								style={{
									fontSize: 22,
									width: 40,
								}}
							/>
						</InputLeftElement>
						<InputRightElement marginTop={"11vh"} width="4.5rem">
							<PrimaryButton
								width="70px"
								height="40px"
								borderRadius="8px"
								label="Send"
								size="sm"
								onClick={formik.handleSubmit}
							></PrimaryButton>
						</InputRightElement>
					</InputGroup>
					{/* <FormErrorMessage
            position="absolute"
            bottom={column ? "-1.2em" : ["-0.2em", "-0.2em"]}
          >
            {getDescendantProp(formik.errors, id)}
          </FormErrorMessage> */}
					{loading && <Progress mt="1" hasStripe value={progress} isAnimated={true} />}
				</Box>
			</FormControl>
			{/* <input
        ref={cameraInputRef}
        style={{ display: "none" }}
        accept="image/*"
        id="icon-button-file"
        type="file"
        capture="environment"
        onClick={(res) => {
          res.target.value = null;
        }}
        onChange={(e) => {
          setChooseFile(e.target.files[0]);
        }}
      /> */}
			<input
				ref={fileInputRef}
				style={{ display: "none" }}
				accept="image/*"
				id="icon-button-file"
				type="file"
				// capture="environment"
				onChange={e => {
					setChooseFile(e.target.files[0]);
					setMediaLoading(e.target.files[0]);
				}}
			/>
		</form>
	);
}
