import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import { getOriginal, getVideo } from "../../../../../../utils/imgUri";

function MobileGallery({ gallery }) {
	const renderSlide = s => {
		if (s) {
			if (
				(typeof s === "string" && s.includes("https") && s.includes(".mp4")) ||
				(typeof s === "string" && s.includes(".mp4")) ||
				(typeof s === "object" && s?.video) ||
				(typeof s === "object" && s?.type?.includes("video"))
			) {
				if (typeof s === "string") {
					return (
						<video autoPlay={false} muted loop style={{ width: "100%" }}>
							<source src={s.includes("https") ? s : getVideo(s)} />
						</video>
					);
				} else if (typeof s === "object") {
					return (
						<video autoPlay={false} muted loop style={{ width: "100%" }}>
							<source src={s.video ? getVideo(s.video) : URL.createObjectURL(s)} />
						</video>
					);
				}
			}
		}

		return (
			<img
				src={typeof s === "object" ? getOriginal(s?.image) : s}
				style={{ listStyle: "none", width: "100%" }}
				alt={`Slide`}
			/>
		);
	};

	return (
		<div>
			<Swiper
				id="main"
				tag="section"
				wrapperTag="ul"
				spaceBetween={0}
				slidesPerView={1}
				loop={true}
			>
				{gallery?.length > 0 &&
					gallery.map((s, k) => <SwiperSlide key={`slide-${k}`}>{renderSlide(s)}</SwiperSlide>)}
			</Swiper>
		</div>
	);
}

export default MobileGallery;
