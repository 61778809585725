import React, { useState } from "react";
import { Box, Image } from "@chakra-ui/react";

function ControllButton({ state0, state1, action = () => {} }) {
	const [onDown, setOnDown] = useState(false);
	return (
		<Box
			outline="none"
			display="flex"
			flexDir="column"
			alignItems="center"
			justifyContent="center"
			minWidth={50}
			maxWidth={50}
			height={50}
			padding="5px 0"
			onMouseDown={() => setOnDown(true)}
			onTouchStart={() => setOnDown(true)}
			onMouseUp={() => {
				setOnDown(false);
				action();
			}}
			onMouseMove={() => setOnDown(false)}
			onTouchMove={() => setOnDown(false)}
			onTouchEnd={() => {
				setOnDown(false);
			}}
			cursor="pointer"
			borderRight="0.5px solid black"
		>
			<Image src={onDown ? state1 : state0} boxSize="35px" cursor="pointer" />
		</Box>
	);
}

export default ControllButton;
