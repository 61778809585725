import React, { useCallback, useEffect, useState } from "react";
import {
	Box,
	Divider,
	Flex,
	Grid,
	GridItem,
	Text,
	Spinner,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	StatArrow,
	StatGroup,
	AvatarGroup,
	Avatar,
} from "@chakra-ui/react";
import { Pie, Line, Bar } from "react-chartjs-2";
import {
	fetchStats,
	fetchStatsCountries,
	fetchStatsPlatforms,
	fetchSubs,
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import TabHeaderLight from "../shared/tabs/TabHeaderLight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../shared/Modal";
import LogModal from "./LogModal";
import randomColor from "randomcolor";
import { getSubscribedStat, subscirbedUsers } from "../../api/libs/stats";
import Select from "react-select";
import { useLocation } from "react-router-dom";

function Statistics() {
	const dispatch = useDispatch();
	const fetchData = (refer, period, amount) => {
		dispatch(
			fetchStats({
				refer,
				period,
				amount,
			}),
		);
	};

	const fetchDataC = (refer, period, amount) => {
		dispatch(
			fetchStatsCountries({
				refer,
				period,
				amount,
			}),
		);
	};

	const fetchDataP = (refer, period, amount) => {
		dispatch(
			fetchStatsPlatforms({
				refer,
				period,
				amount,
			}),
		);
	};

	const fetchdataSub = (period, amount) => {
		dispatch(
			fetchSubs({
				period,
				amount,
			}),
		);
	};

	const { stats } = useSelector(state => state.fetchStats);

	const { stats: cpStats } = useSelector(state => state.fetchCPStats);

	const [monthsFilter, setMonthsFilter] = useState(6);
	const [daysFilter, setDaysFilter] = useState(120);
	const [hoursFilter, setHoursFilter] = useState(23);

	const [subFilter, setSubFilter] = useState(6);
	const location = useLocation();
	const tab = new URLSearchParams(location.search).get("tab");

	const [currentTab, setCurrentTab] = useState(tab ? tab : "location");
	// const [currentTab, setCurrentTab] = useState("location");
	//if tab === subscribers setCurrentTab to subscribers

	useEffect(() => {
		console.log("taaaaaaaaab", tab);
	}, [tab]);

	useEffect(() => {
		fetchData(currentTab, "months", monthsFilter);
		fetchDataC(currentTab, "months", monthsFilter);
		fetchDataP(currentTab, "months", monthsFilter);
	}, [monthsFilter, currentTab]);

	useEffect(() => {
		fetchData(currentTab, "days", daysFilter);
		fetchDataC(currentTab, "days", daysFilter);
		fetchDataP(currentTab, "days", daysFilter);
	}, [daysFilter, currentTab]);

	useEffect(() => {
		fetchData(currentTab, "hours", hoursFilter);
		fetchDataC(currentTab, "hours", hoursFilter);
		fetchDataP(currentTab, "hours", hoursFilter);
	}, [hoursFilter, currentTab]);

	useEffect(() => {
		fetchdataSub("months", subFilter);
	}, [subFilter, currentTab]);

	useEffect(() => {
		dispatch(
			fetchStats({
				refer: currentTab,
				period: "months",
				amount: 6,
			}),
		);
	}, []);

	const generateLineChartData = data => {
		return {
			labels: data.labels,
			datasets: [
				{
					label: "# of Views",
					data: data.values,
					fill: false,
					backgroundColor: "#0E65AE",
					borderColor: "rgba(255, 99, 132, 0.2)",
					yAxisID: "y-axis-1",
				},
			],
		};
	};

	useEffect(() => {
		if (tab === "subscribers") {
			setCurrentTab("subscribers");
		}
	}, []);

	const chartOptions = {
		scales: {
			yAxes: [
				{
					type: "linear",
					display: true,
					position: "left",
					id: "y-axis-1",
				},
			],
		},
	};

	const generatePieChartData = data => {
		return {
			labels: data.labels,
			datasets: [
				{
					label: "label",
					data: data.values,
					backgroundColor: pieChartColors,
					// borderColor: pieChartColorsB,
				},
			],
		};
	};

	const pieChartColors = [
		"rgba(200, 125, 98, 0.5)",
		"rgba(155, 155, 122,0.5)",
		"rgba(186, 165, 135,0.5)",
		"rgba(241, 220, 167,0.5)",
		"rgba(217, 174, 148,0.5)",
		"rgba(255, 203, 105,0.5)",
		"rgba(232, 172, 101,0.5)",
		"rgba(181, 132, 99,0.5)",
		"rgba(153, 123, 102,0.5)",
	];

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const onCloseModal = () => {
		setModalIsOpen(false);
	};

	const generatFilterArray = number => {
		let arr = Array.from({ length: number }, (_, index) => index + 1);
		let options = [];
		arr.map((i, k) => {
			options.push({
				value: i,
				label: i.toString(),
			});
		});
		return options;
	};

	const colourStyles = {
		option: (styles, { data, isDisabled, isFocused }) => {
			return {
				...styles,
				backgroundColor: isFocused ? "#3F629D" : data.isValid ? "red" : "",
				color: data.isValid ? "#FFF" : isDisabled ? "gray" : isFocused ? "#FFF" : "black",
				cursor: isDisabled ? "not-allowed" : "pointer",
				textAlign: "center",
			};
		},
		container: styles => ({ ...styles, width: "100px" }),
	};

	const generateColorsArray = data => {
		let arr = [];
		data.map((_, k) => {
			arr[k] = randomColor();
		});
		return arr;
	};
	// console.log tab states
	// console.log(currentTab);
	const [monthFilter, setMonthFilter] = useState(6);

	const [subscribedStatsData, setSubscribedStatsData] = useState();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		getSubscribedStat(monthFilter)
			.then(res => {
				setSubscribedStatsData(res.data);
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [monthFilter]);

	const generateSubLineData = () => {
		if (subscribedStatsData) {
			return {
				labels: subscribedStatsData?.labels,
				datasets: [
					{
						label: "# Subscribed users",
						data: subscribedStatsData?.values,
						fill: false,
						backgroundColor: "#0E65AE",
						borderColor: "rgba(14, 101, 174, 0.4)",
					},
				],
			};
		}
	};

	const Baroptions = {
		indexAxis: "x",
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				position: "right",
			},
		},
	};

	const generateBarChartData = () => {
		if (subscribedStatsData) {
			return {
				labels: subscribedStatsData?.labels,
				datasets: [
					{
						label: "# Subscribed users",
						data: subscribedStatsData?.values,
						fill: false,
						backgroundColor: "#0E65AE",
						borderColor: "rgba(14, 101, 174, 0.4)",
					},
				],
			};
		}
	};
	// check if tab === subscribers and dont load fetchData fetchDatac fetchDataP

	// fetch data for subscirbedUsers

	const [subscribedUsers, setSubscribedUsers] = useState();

	useEffect(() => {
		subscirbedUsers()
			.then(res => {
				setSubscribedUsers(res.data.data);
			})
			.catch(err => {
				console.log(err);
			});
	}, []);
	return (
		<Box height={"150%"} overflow="hidden">
			<Box pb="12px" pt="12px" pl="20px">
				<Box mb="1">
					<Text fontSize="24px">Statistics</Text>
				</Box>
				<Box w="40px">
					<Divider borderBottom="4px" opacity="1" />
				</Box>
			</Box>
			{
				// if tab === subscribers show tab header light with subscribers tab else show tab header light with all tabs
				currentTab === "subscribers" ? (
					<TabHeaderLight
						options={["location", "event", "news", "subscribers"]}
						onChange={setCurrentTab}
					/>
				) : (
					<TabHeaderLight
						options={["location", "event", "news", "subscribers"]}
						onChange={setCurrentTab}
					/>
				)
			}

			<Box
				padding={"30px"}
				display="inline-flex"
				flexDir="row"
				alignItems="center"
				cursor="pointer"
				onClick={() => {
					setModalIsOpen(true);
				}}
			>
				<FontAwesomeIcon icon="fa-list" color="green" />
				<h3
					style={{
						marginLeft: 5,
					}}
				>
					Logs
				</h3>
			</Box>
			{
				// if current tab is location write text this is location tab await for data to be fetched and show loader
				currentTab === "subscribers" ? (
					<>
						{
							// if loading is true show loader
							loading ? (
								<Box display="flex" justifyContent="center" alignItems="center" height="100%">
									<Spinner />
								</Box>
							) : (
								<>
									{
										// await until all data is fetched and show loader
										subscribedStatsData ? (
											<>
												<Box w={"100%"}>
													<Flex w={"100%"} justifyContent={"center"}>
														<Box w={"30%"} display={"flex"}>
															<Box w={"30%"}>
																<h3
																	style={{
																		fontSize: 20,
																		marginRight: 10,
																	}}
																>
																	Filter by Month
																</h3>
															</Box>
															<Box w={"30%"}>
																<Select
																	isSearchable={false}
																	defaultValue={{
																		value: monthFilter,
																		label: monthFilter.toString(),
																	}}
																	options={generatFilterArray(6)}
																	onChange={e => setMonthFilter(e.value)}
																	styles={colourStyles}
																	theme={theme => ({
																		...theme,
																		borderRadius: 0,
																		width: "100%",
																	})}
																/>
															</Box>
														</Box>
													</Flex>
												</Box>
												<Flex paddingLeft={"30px"} paddingRight={"30px"} w={"100%"}>
													<Box w={"50%"}>
														<Bar data={generateBarChartData()} options={Baroptions} />
													</Box>
													<Box w={"50%"}>
														<Line data={generateSubLineData()} options={chartOptions} />
													</Box>
												</Flex>
												<Flex
													paddingLeft={"30px"}
													paddingRight={"30px"}
													w={"100%"}
													justifyContent={"space-between"}
												>
													<Box w={"45%"}>
														<Box width={"100%"}>
															<StatGroup
																marginTop={"3vh"}
																border={"1px solid #E5E4E2"}
																borderRadius={"12px"}
																padding={"15px"}
															>
																<Stat>
																	<StatLabel
																		color={"gray.500"}
																		fontSize={"1.075rem"}
																		fontWeight={"400"}
																	>
																		Previous Subscribes
																	</StatLabel>
																	<StatNumber fontSize={"2.5rem"} fontWeight={"bold"}>
																		{subscribedStatsData.previous_subscribed}
																	</StatNumber>
																	<StatHelpText
																		backgroundColor={"red.100"}
																		w={"80px"}
																		padding={"3px"}
																		borderRadius={"10px"}
																		display={"flex"}
																		alignItems={"center"}
																	>
																		<StatArrow type="decrease" />
																		{
																			// if subscribed_decreased_by is null show 0 else show the value
																			subscribedStatsData.subscribed_decreased_by === null ? (
																				<Text color={"red.600"}>0%</Text>
																			) : (
																				<Text color={"red.600"}>
																					{" "}
																					{subscribedStatsData.subscribed_decreased_by}
																				</Text>
																			)
																		}
																	</StatHelpText>
																</Stat>
																<Stat>
																	<StatLabel
																		color={"gray.500"}
																		fontSize={"1.075rem"}
																		fontWeight={"400"}
																	>
																		New Subscribes
																	</StatLabel>
																	<StatNumber fontSize={"2.5rem"} fontWeight={"bold"}>
																		{subscribedStatsData.previous_subscribed}
																	</StatNumber>
																	<StatHelpText
																		backgroundColor={"green.100"}
																		w={"80px"}
																		padding={"3px"}
																		borderRadius={"10px"}
																		display={"flex"}
																		alignItems={"center"}
																	>
																		<StatArrow type="increase" />
																		{
																			// if subscribed_decreased_by is null show 0 else show the value
																			subscribedStatsData.subscribed_increased_by === null ? (
																				<Text color={"green.600"}>0%</Text>
																			) : (
																				<Text color={"green.600"}>
																					{" "}
																					{subscribedStatsData.subscribed_increased_by}
																				</Text>
																			)
																		}
																	</StatHelpText>
																</Stat>
															</StatGroup>
														</Box>
													</Box>
													<Box w={"45%"}>
														<StatGroup
															marginTop={"3vh"}
															border={"1px solid #E5E4E2"}
															borderRadius={"12px"}
															padding={"15px"}
														>
															<Stat>
																<StatLabel
																	color={"gray.500"}
																	fontSize={"1.075rem"}
																	fontWeight={"400"}
																>
																	Subscribes
																</StatLabel>
																<StatNumber fontSize={"2.5rem"} fontWeight={"bold"}>
																	{subscribedStatsData.subscribers}
																</StatNumber>
															</Stat>
														</StatGroup>
													</Box>
												</Flex>
											</>
										) : (
											<Box display="flex" justifyContent="center" alignItems="center" height="100%">
												<Spinner />
											</Box>
										)
									}
								</>
							)
						}
					</>
				) : (
					<Box h="150%">
						<Box height="30%" overflowY="auto" overflowX={"hidden"}>
							<Grid
								h="100%"
								m="5%"
								templateRows="100px"
								templateColumns={["repeat(1, 1fr)", "repeat(4, 1fr)"]}
								gap={1}
							>
								{/* monthsFilter */}
								<GridItem
									h="100px"
									display="flex"
									justifyContent="center"
									alignItems="center"
									rowSpan={1}
									colSpan={4}
								>
									<h3
										style={{
											fontSize: 20,
											marginRight: 10,
										}}
									>
										Last
									</h3>
									<Select
										isSearchable={false}
										defaultValue={{
											value: monthsFilter,
											label: monthsFilter.toString(),
										}}
										options={generatFilterArray(6)}
										onChange={e => setMonthsFilter(e.value)}
										styles={colourStyles}
										theme={theme => ({
											...theme,
											borderRadius: 0,
											width: "100%",
										})}
									/>
									<h3
										style={{
											fontSize: 20,
											marginLeft: 10,
										}}
									>
										months
									</h3>
								</GridItem>
								<GridItem rowSpan={1} colSpan={2}>
									{stats[currentTab]?.months && (
										<Box width={["100%", "80%"]} maxH={["600px"]} margin="auto">
											<Line
												data={generateLineChartData(stats[currentTab]?.months)}
												options={chartOptions}
											/>
										</Box>
									)}
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									{cpStats[currentTab].countries?.months && (
										<Box
											width={["75%", "75%"]}
											height={["100%", "80%"]}
											margin="auto"
											marginTop="20px"
										>
											<Pie
												data={generatePieChartData(cpStats[currentTab].countries?.months)}
												options={chartOptions}
											/>
										</Box>
									)}
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									{cpStats[currentTab].platforms?.months && (
										<Box
											width={["75%", "75%"]}
											height={["100%", "80%"]}
											margin="auto"
											marginTop="20px"
										>
											<Pie
												data={generatePieChartData(cpStats[currentTab].platforms?.months)}
												options={chartOptions}
											/>
										</Box>
									)}
								</GridItem>
								{/* daysFilter */}
								<GridItem
									h="100px"
									display="flex"
									justifyContent="center"
									alignItems="center"
									rowSpan={1}
									colSpan={4}
								>
									<h3
										style={{
											fontSize: 20,
											marginRight: 10,
										}}
									>
										Last
									</h3>
									<Select
										isSearchable={false}
										defaultValue={{
											value: daysFilter,
											label: daysFilter.toString(),
										}}
										options={generatFilterArray(120)}
										onChange={e => setDaysFilter(e.value)}
										styles={colourStyles}
										theme={theme => ({
											...theme,
											borderRadius: 0,
											width: "100%",
										})}
									/>
									<h3
										style={{
											fontSize: 20,
											marginLeft: 10,
										}}
									>
										days
									</h3>
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									{cpStats[currentTab].countries?.days && (
										<Box
											width={["75%", "75%"]}
											height={["100%", "80%"]}
											margin="auto"
											marginTop="20px"
										>
											<Pie
												data={generatePieChartData(cpStats[currentTab].countries?.days)}
												options={chartOptions}
											/>
										</Box>
									)}
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									{cpStats[currentTab].platforms?.days && (
										<Box
											width={["75%", "75%"]}
											height={["100%", "80%"]}
											margin="auto"
											marginTop="20px"
										>
											<Pie
												data={generatePieChartData(cpStats[currentTab].platforms?.days)}
												options={chartOptions}
											/>
										</Box>
									)}
								</GridItem>
								<GridItem rowSpan={1} colSpan={2}>
									{stats[currentTab]?.days && (
										<Box width={["100%", "80%"]} maxH={["600px"]} margin="auto">
											<Line
												data={generateLineChartData(stats[currentTab]?.days)}
												options={chartOptions}
											/>
										</Box>
									)}
								</GridItem>

								{/* hoursFilter */}
								<GridItem
									h="100px"
									display="flex"
									justifyContent="center"
									alignItems="center"
									rowSpan={1}
									colSpan={4}
								>
									<h3
										style={{
											fontSize: 20,
											marginRight: 10,
										}}
									>
										Last
									</h3>
									<Select
										isSearchable={false}
										defaultValue={{
											value: hoursFilter,
											label: hoursFilter.toString(),
										}}
										options={generatFilterArray(23)}
										onChange={e => setHoursFilter(e.value)}
										styles={colourStyles}
										theme={theme => ({
											...theme,
											borderRadius: 0,
											width: "100%",
										})}
									/>
									<h3
										style={{
											fontSize: 20,
											marginLeft: 10,
										}}
									>
										hours
									</h3>
								</GridItem>
								<GridItem rowSpan={1} colSpan={2}>
									{stats[currentTab]?.hours && (
										<Box width={["100%", "80%"]} maxH={["600px"]} margin="auto">
											<Line
												data={generateLineChartData(stats[currentTab]?.hours)}
												options={chartOptions}
											/>
										</Box>
									)}
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									{cpStats[currentTab].countries?.hours && (
										<Box
											width={["75%", "75%"]}
											height={["100%", "80%"]}
											margin="auto"
											marginTop="20px"
										>
											<Pie
												data={generatePieChartData(cpStats[currentTab].countries?.hours)}
												options={chartOptions}
											/>
										</Box>
									)}
								</GridItem>
								<GridItem rowSpan={1} colSpan={1}>
									{cpStats[currentTab].platforms?.hours && (
										<Box
											width={["75%", "75%"]}
											height={["100%", "80%"]}
											margin="auto"
											marginTop="20px"
										>
											<Pie
												data={generatePieChartData(cpStats[currentTab].platforms?.hours)}
												options={chartOptions}
											/>
										</Box>
									)}
								</GridItem>
							</Grid>
						</Box>
					</Box>
				)
			}

			{modalIsOpen && (
				<Modal
					subscribedUsers={subscribedUsers}
					customWidth={70}
					header={`Logs for ${currentTab}`}
					handleClose={onCloseModal}
				>
					<LogModal refer={currentTab} />
				</Modal>
			)}
		</Box>
	);
}

export default Statistics;
