import { eventActions } from "../../actionTypes";
import { deleteEvent as deleteEventFunction } from "../../../api/libs/events";
import toast from "react-hot-toast";
import { fetchEvents } from ".";

export const deleteEvent = id => {
	return async dispatch => {
		dispatch({
			type: eventActions.DELETE_EVENT,
		});
		deleteEventFunction(id)
			.then(res => {
				dispatch({
					type: eventActions.DELETE_EVENT_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully deleted event");

				dispatch(fetchEvents());
			})
			.catch(err => {
				dispatch({
					type: eventActions.DELETE_EVENT_REJECTED,
					payload: err.response,
				});
			});
	};
};
