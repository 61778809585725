import { customActions, invoiceActions } from "../../actionTypes";
import { createInvoiceLocation as createInvoiceLocationFunc } from "../../../api/libs/invoice";

export const createInvoiceLocation = (data, history) => {
	return async dispatch => {
		try {
			dispatch({
				type: invoiceActions.CREATE_INVOICE_LOCATION,
			});
			const res = await createInvoiceLocationFunc(data);
			dispatch({
				type: invoiceActions.CREATE_INVOICE_LOCATION_FULFILLED,
				payload: res.data,
			});
			dispatch({
				type: customActions.SET_APP_STATE,
				payload: false,
			});
			localStorage.setItem("activeInvoice", res.data.id);
			history.push("/subscription");
		} catch (err) {
			// dispatch({
			//   type: customActions.SET_APP_STATE,
			//   payload: false,
			// });
			// dispatch({
			//   type: invoiceActions.CREATE_INVOICE_LOCATION_REJECTED,
			//   payload: err?.response?.data?.errors,
			// });
			const preview = err || "unknown error";
			const error = new Error(`Error in createInvoiceLocation: ${preview}`);
			error.response = err.response;
			console.log("Frooom hook", error.response);
			throw error.response;
		}
	};
};
