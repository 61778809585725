import React from "react";
import { useSlides } from "../../../Builder/store";

import Layout from "./components/Layout";
import ActiveSlide from "./components/ActiveSlide";
import SlideReorder from "./components/SlideReorder";
import Slides from "./components/Slides";
import { previewGallery } from "../../utils/canvas";

function MainDisplay({ variant, refer, state, setState, setEditorActive, formik }) {
	const slides = useSlides(state => state[variant]);

	const { addSlide, reOrderSlides, removeSlide, cloneSlide } = useSlides(state => state);

	const onAddSlide = () => {
		addSlide(false, refer, setState, true);
	};

	const onRemoveSlide = index => {
		removeSlide(index, setState);
		setState(state => {
			state.triggerPreview = true;
		});
	};
	const onCloneSlide = () => {
		cloneSlide();
	};

	const moveSlide = (index, target) => {
		const updatedOrder = Array.from(slides);
		let tmp = updatedOrder[index];
		if (target === "left") {
			updatedOrder[index] = updatedOrder[index - 1];
			updatedOrder[index - 1] = tmp;
			setState(state => {
				state.activeSlide = index - 1;
			});
		} else {
			updatedOrder[index] = updatedOrder[index + 1];
			updatedOrder[index + 1] = tmp;
			setState(state => {
				state.activeSlide = index + 1;
			});
		}
		reOrderSlides(updatedOrder);
		previewGallery(updatedOrder, formik);
	};

	const onOpenEditor = () => {
		setEditorActive(true);
	};

	return (
		<Layout>
			<ActiveSlide
				length={slides.length}
				slide={slides[state.activeSlide]}
				acIndex={state.activeSlide}
				variant={variant}
				onOpenEditor={onOpenEditor}
				onRemoveSlide={onRemoveSlide}
				onCloneSlide={onCloneSlide}
			/>
			<SlideReorder {...{ variant, slides, state, moveSlide }} />
			<Slides {...{ variant, slides, state, setState, onAddSlide }} />
		</Layout>
	);
}

export default MainDisplay;
