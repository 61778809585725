import { useState, useEffect } from "react";
import { useParams } from "react-router";

import { initCanvas } from "../utils/canvas";
import { onListenCanvasEvents } from "../utils/eventListener";

import { useSlides } from "../store";
import { getOriginal, getVideo } from "../../../../utils/imgUri";
import { getOrientation } from "../utils/common";
import { generateUUID } from "../../../../utils/uuid";

import defaultVideoThumbnail from "../../../../assets/builder/def-vid-thumb.jpeg";
import { getDimensions } from "../static/canvasDimensions";

export function useCanvas(variant, refer, formik, setState) {
	let { id: isEditing } = useParams();
	const [canvas, setCanvas] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const slides = useSlides(state => state[variant]);
	const isPopulated = useSlides(state => state.isPopulated);
	const setCurrentVariant = useSlides(state => state.setCurrentVariant);
	const setActiveSlide = useSlides(state => state.setActiveSlide);
	const setIsPopulated = useSlides(state => state.setIsPopulated);
	const modifySlide = useSlides(state => state.modifySlide);
	const saveSlide = useSlides(state => state.saveSlide);
	const setOrientation = useSlides(state => state.setOrientation);

	const asyncPopulator = async arr => {
		let orArr = [];
		for (const str of arr) {
			if (str.image) {
				let original = getOriginal(str.image);
				let res = await getOrientation(original);
				orArr.push(res);
			} else {
				orArr.push("squere");
			}
		}
		for (let i = 0; i < arr.length; i++) {
			if (!slides[i]?.isEdited) {
				if (arr[i].image) {
					let original = getOriginal(arr[i].image);
					if (arr[i].width / arr[i].height === 2) {
						modifySlide(i, {
							...slides[0],
							index: generateUUID(),
							png: original,
							orientation: orArr[i],
							isPreview: true,
							isPano: true,
							pano: original,
						});
					} else {
						modifySlide(i, {
							...slides[0],
							index: generateUUID(),
							png: original,
							orientation: orArr[i],
							isPreview: true,
						});
					}
				} else {
					let video = getVideo(formik.values.gallery[i].video);
					modifySlide(i, {
						...slides[0],
						index: generateUUID(),
						png: defaultVideoThumbnail,
						orientation: orArr[i],
						isPreview: true,
						isVideo: true,
						video,
					});
				}
			}
			setIsPopulated(true);
		}
		setIsReady(true);
	};

	useEffect(() => {
		setCurrentVariant(variant);
		setActiveSlide(0);
		if (isEditing) {
			if (variant === "gallery") {
				if (!isPopulated) {
					asyncPopulator(formik.values.gallery);
				} else {
					setIsReady(true);
				}
			} else {
				if (!slides[0].isEdited) {
					let original = getOriginal(formik.values.logo);
					modifySlide(0, {
						...slides[0],
						png: original,
						isPreview: true,
					});
				}
				setIsReady(true);
			}
		} else {
			let target = getDimensions(variant, refer).name;
			setOrientation(target);
			setIsReady(true);
		}
		setCanvas(initCanvas(variant, refer));
	}, []);

	useEffect(() => {
		if (canvas) {
			onListenCanvasEvents(canvas, variant, setState, saveSlide);
		}
	}, [canvas]);

	return [canvas, isReady];
}
