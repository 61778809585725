import { couponActions } from "../../actionTypes";
import { getCoupons } from "../../../api/libs/coupons";

export const fetchCoupons = (type, page, reset, newsId) => {
	return async dispatch => {
		dispatch({
			type: couponActions.FETCH_COUPONS,
			payload: { reset },
		});
		getCoupons(type, page, newsId)
			.then(res => {
				dispatch({
					type: couponActions.FETCH_COUPONS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: couponActions.FETCH_COUPONS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
