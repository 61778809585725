import { eventActions } from "../../actionTypes";

const initialState = {
	format: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case eventActions.FETCH_EVENT_FORMAT: {
			return { ...state, fetching: true };
		}

		case eventActions.FETCH_EVENT_FORMAT_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				format: action.payload.data,
			};
		}

		case eventActions.FETCH_EVENT_FORMAT_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
