export const initialValues = {
	locationTitle: "",
	locationCategory: "",
	locationAddress: "",
	locationPhone: "",
	image: "",
	title: "",
	description: "",
	location: {
		id: [],
	},
	category_id: "",
	sub_category_id: "",
	keywords: [],
	format_id: "",
	gallery: [],
	event_start_at: "",
	event_end_at: "",
	price_from: "",
	price_to: "",
	recurrence: "none",
	recurrence_days_weekly: [],
	recurrence_date_days_monthly: [],
	availability: {},
	free: false,
	meet: 0,
	fb_page_share: false,
	instagram_page_share: false,
	links: {
		zoom: "",
		google_meet: "",
	},
};
