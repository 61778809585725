import { subActions } from "../../actionTypes";
import { getPlans } from "../../../api/libs/plans";

export const fetchPlans = () => {
	return async dispatch => {
		dispatch({
			type: subActions.FETCH_PLANS,
		});
		try {
			const res = await getPlans();
			dispatch({
				type: subActions.FETCH_PLANS_FULFILLED,
				payload: res,
			});
			return res; // return the response payload
		} catch (err) {
			dispatch({
				type: subActions.FETCH_PLANS_REJECTED,
				payload: err.response.data,
			});
			throw err; // rethrow the error
		}
	};
};
