import create from "zustand";
import { devtools } from "zustand/middleware";

import { initialSlides } from "../static/initialSlides";

import {
	setActiveSlide,
	onSaveSlide,
	selectSlide,
	onAddSlide,
	onRemoveSlide,
	onCloneSlide,
	onReorderSlides,
	onToogleOrientation,
	onSetOrientation,
	onSetSlideLoading,
	onModifySlide,
	onMarkAsEdited,
	onSetCurrentVariant,
	onSetISPopulated,
	onResetState,
	onAddExPng,
	getEmojies,
	getEmojiesNext,
	getFonts,
	getFontsNext,
} from "./actions";

const initialData = {
	data: [],
	fetching: false,
	fetched: false,
	canFetchNext: false,
	fetchingNext: false,
	nextPageUrl: null,
};

const store = (set, get) => ({
	logo: initialSlides,
	gallery: initialSlides,
	currentVariant: null,
	activeSlide: 0,
	slideLoading: false,
	isPopulated: false,
	emojies: { ...initialData },
	fonts: { ...initialData },
	getEmojies: () => getEmojies(set, get),
	getEmojiesNext: () => getEmojiesNext(set, get),
	getFonts: () => getFonts(set, get),
	getFontsNext: () => getFontsNext(set, get),
	setIsPopulated: bool => onSetISPopulated(set, get, bool),
	setCurrentVariant: variant => onSetCurrentVariant(set, get, variant),
	selectSlide: (canvas, index, state, setState) =>
		selectSlide(set, get, canvas, index, state, setState),
	setActiveSlide: index => setActiveSlide(set, get, index),
	saveSlide: canvas => onSaveSlide(set, get, canvas),
	addSlide: (canvas, refer, setState) => onAddSlide(set, get, canvas, refer, setState),
	removeSlide: (index, setState) => onRemoveSlide(set, get, index, setState),
	cloneSlide: () => onCloneSlide(set, get),
	reOrderSlides: updatedOrder => onReorderSlides(set, get, updatedOrder),
	toogleOrientation: (canvas, forMob) => onToogleOrientation(set, get, canvas, forMob),
	setOrientation: or => onSetOrientation(set, get, or),
	setSlideLoading: bool => {
		onSetSlideLoading(set, get, bool);
	},
	modifySlide: (index, obj) => onModifySlide(set, get, index, obj),
	markSlideEdited: () => onMarkAsEdited(set, get),
	addExPng: canvas => onAddExPng(set, get, canvas),
	resetStore: () => onResetState(set, get),
});

export const useSlides = create(devtools(store, { name: "Builder Store" }));
