import { apiCallAc } from "..";

export function getSubscriptions(page) {
	return apiCallAc.get(`/payment/company/paypal/subscribe?page=${page}`);
}

export function getSubscription(id) {
	return apiCallAc.get(`/payment/company/paypal/subscribe/${id}`);
}

export function changeSubscription(data) {
	return apiCallAc.post(`/payment/company/paypal/subscribe/revise`, data);
}

export function stopSubscription(data) {
	return apiCallAc.delete(`/payment/company/paypal/subscribe`, { data });
}

export function pauseSubscription(data) {
	return apiCallAc.post(`/payment/company/paypal/subscribe/suspend`, data);
}

export function continueSubscription(data) {
	return apiCallAc.post(`/payment/company/paypal/subscribe/activate`, data);
}
