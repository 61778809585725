import React from "react";
import { Box } from "@chakra-ui/react";

function Container({ children, mt = false }) {
	return (
		<Box display="flex" justifyContent="space-between" mt={mt ? "auto" : 0}>
			{children}
		</Box>
	);
}

export default Container;
