import { chatsActions } from "../../actionTypes";

export const updateList = data => {
	return async dispatch => {
		dispatch({
			type: chatsActions.UPDATE_MESSAGE,
			payload: data,
		});
	};
};
