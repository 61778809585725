import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { requestRecover, resetRecoverState } from "../../../store/actions";

import Layout from "./components/Layout";
import Form from "./components/Form";
import { useFormik } from "formik";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";

function Recover({ history }) {
	const lang = useSelector(state => state.languageProvider.language);
	const { status, loading, error } = useSelector(state => state.requestRecover);
	const dispatch = useDispatch();

	const handleSubmit = values => {
		dispatch(requestRecover(values, formik.setErrors));
	};

	useEffect(() => {
		if (status) {
			history.push("/");
			dispatch(resetRecoverState());
		}
	}, [status]);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		<Layout>
			<Form
				formik={formik}
				handleSubmit={handleSubmit}
				lang={lang}
				loading={loading}
				error={error}
			/>
		</Layout>
	);
}
export default Recover;
