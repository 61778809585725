import produce from "immer";
import { editorActions } from "../../actionTypes";

const initialState = {
	lastUsedColor: {
		value: null,
		x: null,
		y: null,
	},
	initialCanvas: false,
	logoSlides: false,
	gallerySlides: false,
};

export const editorReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case editorActions.SET_LAST_USED_COLOR: {
				draft.lastUsedColor = action.payload;
				break;
			}
			case editorActions.SAVE_INITIAL_CANVAS: {
				draft.initialCanvas = action.payload;
				break;
			}
			case editorActions.SAVE_LOGO_STATE: {
				draft.logoSlides = action.payload;
				break;
			}
			case editorActions.SAVE_GALLERY_STATE: {
				draft.gallerySlides = action.payload;
				break;
			}
			case editorActions.RESET_STATE:
				return { ...initialState };
			default:
				return draft;
		}
	});
