import React, { useState } from "react";
import { ChromePicker } from "react-color";

import { onUpdateTextColor } from "../../../../../utils/textboxActions";

function Picker({ canvas, state }) {
	const [color, setColor] = useState(state.activeObject ? state.activeObject.fill : "#ffffff");

	const handleChange = color => {
		setColor(color.hex);
		onUpdateTextColor(canvas, state, color.hex);
	};

	return (
		<ChromePicker
			styles={{ wrap: { width: "100%" } }}
			color={color}
			onChange={handleChange}
			disableAlpha={true}
		/>
	);
}

export default Picker;
