import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	IconButton,
	Image,
	SelectField,
	Text,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	Flex,
	AvatarGroup,
	Avatar,
} from "@chakra-ui/react";
import { FaEdit, FaWindowClose, FaSave, FaTrashAlt } from "react-icons/fa";
import ChatInput from "../../shared/inputs/ChatInput";
import { useDispatch, useSelector } from "react-redux";
import {
	createChat,
	fetchChatableUsers,
	fetchChats,
	fetchChatsNext,
	fetchChatableUsersNext,
	fetchMessages,
	fetchNextMessages,
	fetchReveal,
	updateList,
} from "../../../store/actions";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../shared/Loading";
import { getAvatar, getLogoes, getMobOriginal, getOriginal } from "../../../utils/imgUri";
import { useInView } from "react-intersection-observer";
import { seenMessage } from "../../../api/libs/chats";

import { useScrollDirection } from "react-use-scroll-direction";
import UserCard from "../components/UserCard";
import EditableText from "./EditableText";
import AvatarList from "../components/AvatarList";
import { changeChatName, deleteChat } from "../../../api/libs/chats";
import { BsCheck2All } from "react-icons/bs";
function Client({ formik, lang, couponId, newsSelector = true, maxWidth = "auto", handleSubmit }) {
	const { id } = useParams();
	const history = useHistory();

	const [selected, setSelected] = useState(0);
	const dispatch = useDispatch();

	const {
		chats,
		fetched: fetchedChat,
		nextPageUrl: chatsFetchNext,
	} = useSelector(state => state.fetchChats);

	const {
		users,
		fetched: fetchedUsers,
		nextPageUrl: chatableUsersFetchNext,
	} = useSelector(state => state.fetchChatableUsers);

	const {
		data: { company, mobile_users },
	} = useSelector(state => state.fetchReveal);
	const {
		messages,
		fetched: fetchedMessages,
		fetching: fetchingMessages,
	} = useSelector(state => state.fetchMessages);

	useEffect(() => {
		if (!fetchedChat) {
			dispatch(fetchChats());
		}
		dispatch(fetchChatableUsers());
	}, []);

	useEffect(() => {
		if (messages.next_page_url && !messages.prev_page_url) {
			dispatch(fetchNextMessages(messages.next_page_url));
		}
	}, [messages.next_page_url]);

	useEffect(() => {
		if (id) {
			dispatch(fetchReveal(id));
			dispatch(fetchMessages(id));
		}
	}, [id]);

	const { ref, inView, entry } = useInView({ threshold: 0 });

	const messagesRefs = useRef([]);

	useEffect(() => {
		messagesRefs.current = messagesRefs.current.slice(0, fetchedMessages.length);
	}, [fetchedMessages]);

	// get length of the messages array

	const [hoveringMsg, setHoveringMsg] = useState(null);
	const [dropDownActive, setDropDownActive] = useState(false);

	const handleListUpdate = newList => {
		dispatch(updateList(newList));
	};

	const [chatCreated, setChatCreated] = useState(false);
	const [chatcreatedIndex, setChatCreatedIndex] = useState([]);
	const [loading, setLoading] = useState(false);
	// console.log("chatCreated", chatCreated);
	// if chat created is true and chatcreatedIndex is not empty and is 0 then show the chat with index 0
	useEffect(() => {
		if (chatCreated === true) {
			setLoading(true);
			// dispatch(fetchMessages(chatcreatedIndex[0]));
		}
		setTimeout(() => {
			dispatch(fetchChats());
			dispatch(fetchChatableUsers());
			setLoading(false);
			setChatCreated(false);
			setChatCreatedIndex([]);
		}, 800);
	}, [chatCreated === true]);

	// if isEditing is true then show the edit button else show the save button

	const [detedChat, setDeletedChat] = useState(false);
	const deleteChatHandler = () => {
		deleteChat(id).then(res => {
			setDeletedChat(true);
			console.log("deleteChat", res);
			dispatch(fetchChats());
			dispatch(fetchChatableUsers());
			history.push("/chats");
		});
	};

	// when setDeletedChat is true set time out and make it false again
	useEffect(() => {
		if (detedChat === true) {
			setTimeout(() => {
				setDeletedChat(false);
			}, 2500);
		}
	}, [detedChat === true]);

	const [isScrollDown, setIsScrollDown] = useState(false);

	const [mediaLoading, setMediaLoading] = useState(null);

	const { isScrollingDown, scrollTargetRef } = useScrollDirection();

	const renderMessage = (item, index) => {
		if (item.image) {
			console.log("item", item.message);
			return item.message;
		}
		if (
			item.message.includes(".jpg") ||
			item.message.includes(".jpeg" || item.message.includes(".png"))
		) {
			return (
				<Image
					ref={el => (messagesRefs.current[index] = el)}
					src={
						!item.sender_dashboard_user_id
							? getMobOriginal(item.message)
							: getOriginal(item.message)
					}
					w="30%"
					// h="130px"
					objectFit={"contain"}
					borderRadius={"0.5em"}
					borderBottomLeftRadius={item.sender_dashboard_user_id ? 0 : "0.5em"}
					borderBottomRightRadius={item.sender_dashboard_user_id ? "0.5em" : 0}
					borderColor={item.sender_dashboard_user_id ? "#E4E6EB" : "#286AA6"}
					marginTop="0.1em"
					onMouseOver={() => {
						setHoveringMsg(index);
					}}
					onMouseOut={() => {
						setHoveringMsg(null);
					}}
				/>
			);
		} else {
			return (
				<Box
					pos="relative"
					display="flex"
					justifyContent="center"
					alignItems="center"
					onMouseEnter={() => {
						setHoveringMsg(index);
					}}
					onMouseLeave={() => {
						setHoveringMsg(null);
						setDropDownActive(false);
					}}
				>
					{/* {dropDownActive === index && (
            <Box
              pos="absolute"
              top={"-100px"}
              right={item.sender_dashboard_user_id ? 0 : "unset"}
              left={item.sender_dashboard_user_id ? 0 : "unset"}
              width={"200px"}
              height={"100px"}
              p="10px"
              bg="red"
            ></Box>
          )} */}
					{/* <Text
            ref={(el) => (messagesRefs.current[index] = el)}
            backgroundColor={
              item.sender_dashboard_user_id ? "#E4E6EB" : "#286AA6"
            }
            borderRadius="0.5em"
            borderBottomLeftRadius={item.sender_dashboard_user_id ? 0 : "0.5em"}
            borderBottomRightRadius={
              item.sender_dashboard_user_id ? "0.5em" : 0
            }
            marginTop="0.1em"
            color={item.sender_dashboard_user_id ? "#050505" : "white"}
            p="0.5em"
            display="flex"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
          >
            {hoveringMsg === index && item.sender_dashboard_user_id && (
              <Box
                height="15px"
                width="15px"
                border="0.5px solid black"
                borderRadius="50%"
                bg={"white"}
                // zIndex={10}
                display="flex"
                flexDir="row"
                justifyContent="space-evenly"
                alignItems="center"
                cursor="pointer"
                marginRight="3px"
                onClick={() => {
                  setDropDownActive(index);
                }}
              >
                <Box
                  width="2px"
                  height="2px"
                  borderRadius="50%"
                  background="rgba(0,0,0,0.8)"
                />
                <Box
                  width="2px"
                  height="2px"
                  borderRadius="50%"
                  background="rgba(0,0,0,0.8)"
                />
                <Box
                  width="2px"
                  height="2px"
                  borderRadius="50%"
                  background="rgba(0,0,0,0.8)"
                />
              </Box>
            )}
            {item.message}
            {hoveringMsg === index && !item.sender_dashboard_user_id && (
              <Box
                height="15px"
                width="15px"
                border="0.5px solid black"
                borderRadius="50%"
                bg={"white"}
                zIndex={10}
                display="flex"
                flexDir="row"
                justifyContent="space-evenly"
                alignItems="center"
                cursor="pointer"
                marginLeft="3px"
                onClick={() => {
                  setDropDownActive(index);
                }}
              >
                <Box
                  width="2px"
                  height="2px"
                  borderRadius="50%"
                  background="rgba(0,0,0,0.8)"
                />
                <Box
                  width="2px"
                  height="2px"
                  borderRadius="50%"
                  background="rgba(0,0,0,0.8)"
                />
                <Box
                  width="2px"
                  height="2px"
                  borderRadius="50%"
                  background="rgba(0,0,0,0.8)"
                />
              </Box>
            )}
          </Text> */}
				</Box>
			);
		}
	};

	const [selectedUserList, setSelectedUserList] = useState([]);
	return (
		<Box
			display="grid"
			gridTemplateColumns="2fr 8fr"
			border="2px solid black"
			height="calc(100vh - 51px)"
			overflow="hidden"
		>
			<Box borderRight="2px solid #000" p="1em" height="calc(100vh - 51px)">
				<Box display="grid" gridTemplateColumns="1fr 1fr" mb="0.2em" gridGap="0.2em" flex="1">
					<Button
						onClick={() => setSelected(0)}
						borderWidth="2px"
						borderRadius="0"
						backgroundColor="#FFF"
						borderColor={selected === 0 ? "#0E65AF" : "#171923"}
						color={selected === 0 ? "#0E65AF" : "#000"}
					>
						Chats
					</Button>
					<Button
						onClick={() => setSelected(1)}
						borderWidth="2px"
						borderRadius="0"
						backgroundColor="#FFF"
						borderColor={selected === 1 ? "#0E65AF" : "#171923"}
						color={selected === 1 ? "#0E65AF" : "#000"}
					>
						Users
					</Button>
				</Box>
				{selectedUserList.length > 0 && (
					<Box display="flex" alignItems="center" mb="0.2em">
						{selectedUserList.length <= 10 ? (
							<Button
								borderWidth="1px"
								borderRadius="0"
								backgroundColor="#FFF"
								borderColor={"#171923"}
								color={"#000"}
								onClick={() => {
									dispatch(createChat({ users: selectedUserList }, history, setSelected));
									setChatCreated(true);
									setChatCreatedIndex([0]);
									setSelectedUserList([]);
								}}
							>
								Create group chat
							</Button>
						) : (
							<Text color="red" mr="20px" opacity="0.7">
								Cannot add more users (limit: 10)
							</Text>
						)}
						<Text
							_hover={{
								textDecorationLine: "underline",
								opacity: "0.9",
							}}
							ml="auto"
							mr="20px"
							color={"red"}
							opacity="0.7"
							cursor="pointer"
							onClick={() => {
								setSelectedUserList([]);
							}}
						>
							clear
						</Text>
					</Box>
				)}

				<Box
					display="flex"
					flexDirection="column"
					gridGap="0.2em"
					flex="1"
					height="calc(100vh - 100px)"
					overflow="scroll"
					ref={scrollTargetRef}
					onScroll={e => {
						if (isScrollingDown) {
							if (
								e.target.scrollHeight - e.target.scrollTop > e.target.clientHeight - 10 &&
								e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 10
							) {
								if (selected === 0) {
									if (chatsFetchNext) {
										dispatch(fetchChatsNext(chatsFetchNext));
										console.log(chatsFetchNext);
									}
								}
								if (selected === 1) {
									if (chatableUsersFetchNext) {
										dispatch(fetchChatableUsersNext(chatableUsersFetchNext));
										console.log(chatableUsersFetchNext);
									}
								}
							}
						}
					}}
				>
					{selected === 0 && fetchedChat ? (
						(console.log("chats", chats),
						chats.map((item, key) => (
							<Box
								key={key}
								display="flex"
								flexDir="column"
								p="1em"
								_hover={{ backgroundColor: "#0E65AF", color: "#FFF" }}
								cursor="pointer"
								transition="100ms all ease"
								backgroundColor={id === item.id && id ? "#AECB2D" : "#EBEAEB"}
								color="#000"
								fontWeight="500"
								onClick={() => history.push(`/chats/${item.id}`)}
								width={360}
							>
								<Flex gap={"15px"} alignItems={"center"}>
									<AvatarList chatMembers={item.participants} />
									<Box width={"100%"}>
										<EditableText
											text={item.name}
											chatId={item.id}
											chatName={item.name}
											handleDeleteClick={deleteChatHandler}
											chatMembers={item.participants}
										/>
									</Box>
								</Flex>
								<Text fontSize="0.8em">Last message: {item?.latest_message?.message}</Text>
								<Text fontSize="0.8em">Participants left: {item?.participants_count}</Text>
							</Box>
						)))
					) : selected === 0 && !fetchedChat ? (
						<Loading height={true} width={360} />
					) : selected === 1 && fetchedUsers ? (
						users.map((item, key) => (
							<UserCard
								key={key}
								item={item}
								id={id}
								selectedUserList={selectedUserList}
								setSelectedUserList={setSelectedUserList}
								handleClick={() => {
									dispatch(createChat({ users: [item.id] }, history, setSelected));
									// setChatCreated(true);
								}}
							/>
							// <Box
							//   key={key}
							//   display="flex"
							//   flexDir="column"
							//   p="1em"
							//   _hover={{ backgroundColor: "#0E65AF", color: "#FFF" }}
							//   cursor="pointer"
							//   transition="100ms all ease"
							//   backgroundColor={id === item.id && id ? "#AECB2D" : "#EBEAEB"}
							//   color="#000"
							//   fontWeight="500"
							//   width={360}
							//   // onClick={() => {
							//   //   dispatch(
							//   //     createChat({ users: [item.id] }, history, setSelected)
							//   //   );
							// >
							//   <Checkbox /> <Text>{item.name}</Text>
							// </Box>
						))
					) : (
						<Loading />
					)}

					{selected === 1 && fetchedUsers && (
						<div
							ref={ref}
							style={{
								textAlign: "center",
							}}
						>
							{/* {(fetching || fetchingNext) && <h2>loading</h2>}
              {!canFetchNext && <h2>No more users</h2>} */}
						</div>
					)}
				</Box>
			</Box>

			{id ? (
				<Box height="calc(100vh - 51px)">
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="flex-start"
						height={"55px"}
						width={"100%"}
						borderBottom="2px solid #000"
						back
					>
						{
							// if chat id is not null, show chat name
							// else show group chat name

							id && (
								<Text
									color={"#000"}
									fontSize="1.5em"
									fontWeight="500"
									ml="1em"
									mt="0.5em"
									mb="0.5em"
								>
									{
										<>
											<Flex alignItems={"center"}>
												<AvatarGroup size="sm" max={10}>
													{chats
														.find(chat => chat.id === id)
														?.participants.map(participant => (
															<Avatar name={participant.user.name} src={participant.avatar} />
														))}
												</AvatarGroup>
												<Text ml={"10px"} fontSize={"14px"} fontWeight={"500"} color={"gray.500"}>
													{chats.find(chat => chat.id === id)?.name}
												</Text>
											</Flex>
										</>
									}
								</Text>
							)
						}
					</Box>
					<Box
						px="1em"
						py="1em"
						height="75%"
						borderBottom="2px solid #000"
						display="flex"
						flexDirection="column-reverse"
						overflowY="scroll"
						onScroll={e => {
							if (messages.next_page_url && e.target.scrollTop < 0 && !fetchingMessages) {
								dispatch(fetchNextMessages(messages.next_page_url));
							}
						}}
					>
						{mediaLoading && (
							<Box
								textAlign={"left"}
								display="flex"
								justifyContent={"flex-start"}
								opacity={0.5}
								pos="relative"
								w="30%"
							>
								<Image
									src={URL.createObjectURL(mediaLoading)}
									// w="30%"
									// h="130px"
									objectFit={"contain"}
									borderRadius={"0.5em"}
									borderBottomLeftRadius={0}
									borderColor={"#E4E6EB"}
									marginTop="0.1em"
									// onMouseOver={() => {
									//   setHoveringMsg(index);
									// }}
									// onMouseOut={() => {
									//   setHoveringMsg(null);
									// }}
								/>
								<div
									style={{
										position: "absolute",
										left: 0,
										right: 0,
										top: 0,
										bottom: 0,
										margin: "auto",
									}}
								>
									<Loading height />
								</div>
							</Box>
						)}
						{fetchedMessages ? (
							messages?.data?.map((item, index) => (
								<div key={index.toString()}>
									<Box
										textAlign={item.sender_dashboard_user_id ? "left" : "right"}
										display="flex"
										flexDirection={"column"}
										justifyContent={item.sender_dashboard_user_id ? "flex-start" : "flex-end"}
										alignItems={item.sender_dashboard_user_id ? "flex-start" : "flex-end"}
									>
										{renderMessage(item, index)}
										<Flex alignItems={"end"} gap={"8px"}>
											{item.sender_dashboard_user_id ? (
												!messages?.data[index + 1]?.sender_dashboard_user_id ? (
													<Text>
														{mobile_users?.find(it => it.id === item.sender_mobile_user_id)
															?.seen ? (
															<Flex>
																<Text>seen</Text>
															</Flex>
														) : (
															""
														)}
													</Text>
												) : null
											) : item.sender_mobile_user_id &&
											  !messages?.data[index + 1]?.sender_mobile_user_id ? (
												<Text>
													{mobile_users?.find(it => it.id === item.sender_mobile_user_id)?.seen ? (
														<Flex>
															<BsCheck2All color={"green.500"} fontSize={"22px"} />
														</Flex>
													) : (
														""
													)}
												</Text>
											) : null}
											<Text
												backgroundColor={item.sender_dashboard_user_id ? "#E4E6EB" : "#286AA6"}
												borderRadius="0.5em"
												borderBottomLeftRadius={item.sender_dashboard_user_id ? 0 : "0.5em"}
												borderBottomRightRadius={item.sender_dashboard_user_id ? "0.5em" : 0}
												marginTop="0.1em"
												color={item.sender_dashboard_user_id ? "#050505" : "white"}
												p="0.8em"
											>
												{item.message}
											</Text>
										</Flex>
									</Box>
									{item.sender_dashboard_user_id ? (
										!messages?.data[index + 1]?.sender_dashboard_user_id ? (
											<Text alignSelf="center" textAlign="center" color="#8A8D91">
												{item.created_at}
											</Text>
										) : null
									) : item.sender_mobile_user_id &&
									  !messages?.data[index + 1]?.sender_mobile_user_id ? (
										<Text alignSelf="center" textAlign="center" color="#8A8D91">
											{item.created_at}
										</Text>
									) : null}
									<Box display="flex">
										{item.sender_dashboard_user_id ? (
											!messages?.data[index + 1]?.sender_dashboard_user_id ? (
												<Box display="flex" gridGap="0.5em" my="0.5em">
													<Image
														src={getLogoes(company?.logo)}
														w="30px"
														h="30px"
														borderRadius="50%"
														mr={["0px", "20px"]}
													/>
													<Text>{company?.name}</Text>
												</Box>
											) : (
												<Box />
											)
										) : !messages?.data[index + 1]?.sender_mobile_user_id ? (
											<>
												<Box
													ml={item.sender_mobile_user_id ? "auto" : "0"}
													mr={item.sender_mobile_user_id ? "0" : "auto"}
													display="flex"
													gridGap="0.5em"
													my="0.5"
												>
													<Text>
														{mobile_users?.find(it => it.id === item.sender_mobile_user_id)?.name}
													</Text>

													<Image
														src={getAvatar(
															mobile_users?.find(it => it.id === item.sender_mobile_user_id)
																?.avatar,
														)}
														w="30px"
														h="30px"
														borderRadius="50%"
													/>
												</Box>
											</>
										) : null}
									</Box>
								</div>
							))
						) : (
							<Loading height={true} />
						)}
					</Box>
					<Box p="1em" height="100%">
						<ChatInput
							formik={formik}
							id="message"
							handleSubmit={handleSubmit}
							setMediaLoading={setMediaLoading}
							handleListUpdate={handleListUpdate}
						/>
					</Box>
				</Box>
			) : (
				<Box height="full" display="flex" alignItems="center" justifyContent="center">
					Select user to chat
				</Box>
			)}
			{
				// if chat is deleted retunr alert and remove after 3 seconds
				detedChat == true && (
					<Alert
						position={"absolute"}
						w={"17%"}
						left={"50%"}
						transform={"translateX(-50%)"}
						status="success"
					>
						<AlertIcon />
						<AlertTitle>Delete</AlertTitle>
						<AlertDescription>Chat has been deleted</AlertDescription>
					</Alert>
				)
			}
		</Box>
	);
}

export default Client;
