import { useSlides } from "../store";

export const onLoadPano = (index, media, thumb, resetState) => {
	console.log(thumb);
	const modifySlide = useSlides.getState().modifySlide;
	modifySlide(index, {
		isPano: true,
		pano: media,
		isVideo: false,
		video: null,
		orientation: "squere",
		isEdited: true,
		png: thumb,
	});
	resetState();
};
