import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import Layout from "./components/Layout";
import ScrollLayout from "./components/ScrollLayout";
import StickerItem from "./components/StickerItem";
import Categories from "./components/Categories";

import { onLoadSticker } from "../../../../../utils/fabricActions";

import { useSlides } from "../../../../../../Builder/store";

function Stickers({ canvas, variant }) {
	const [dataCollection] = useState(() => require("./stickers.json"));
	const { categories, stickers } = dataCollection;
	const [categoriesCollection] = useState(() => categories);
	const [stickersCollection, setStickersCollection] = useState(() => stickers);

	const [emojiesActive, setEmojiesActive] = useState(false);

	const handleFilter = key => {
		if (key === "emoji") {
			setEmojiesActive(true);
		} else {
			setEmojiesActive(false);
			const filteredStickers = stickers.filter(({ category }) =>
				JSON.stringify(category).includes(key),
			);
			setStickersCollection(filteredStickers);
		}
	};

	const { data, fetching, fetched, canFetchNext, fetchingNext } = useSlides(state => state.emojies);
	const getEmojies = useSlides(state => state.getEmojies);
	const getEmojiesNext = useSlides(state => state.getEmojiesNext);

	useEffect(() => {
		if (emojiesActive && data.length === 0) {
			getEmojies();
		}
	}, [emojiesActive]);

	const loadSticker = (name, setLoading, url = false) => {
		setLoading(true);
		if (url) {
			onLoadSticker(
				canvas,
				`https://static.yezzgo.de/image-editor-statics/stickers/microsoftEmojis/${name}`,
				setLoading,
				variant,
				url,
			);
		} else {
			onLoadSticker(canvas, name, setLoading, url);
		}
	};

	const { ref, inView, entry } = useInView({ threshold: 0 });

	useEffect(() => {
		if (inView) {
			if (!fetching && !fetchingNext) {
				if (canFetchNext) {
					getEmojiesNext();
				}
			}
		}
	}, [inView]);

	return (
		<Layout>
			<ScrollLayout>
				{emojiesActive ? (
					<>
						{data.map((item, k) => (
							// <></>
							<StickerItem key={k} name={item.data["3d"]} loadSticker={loadSticker} url={true} />
						))}
						<div
							ref={ref}
							style={{
								alignSelf: "center",
							}}
						>
							{(fetching || fetchingNext) && <h2>loading</h2>}
							{!canFetchNext && <h2>Nothing to fetch more</h2>}
						</div>
					</>
				) : (
					<>
						{stickersCollection.map(({ name }, k) => (
							<StickerItem key={k} name={name} loadSticker={loadSticker} />
						))}
					</>
				)}
			</ScrollLayout>
			<Categories categoriesCollection={categoriesCollection} handleFilter={handleFilter} />
		</Layout>
	);
}

export default Stickers;
