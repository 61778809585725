export const initialValues = {
	locationTitle: "",
	locationCategory: "",
	locationAddress: "",
	locationPhone: "",
	image: "",
	title: "",
	description: "",
	location: {
		id: [],
	},
	keywords: [],
	coupon: { id: [] },
	gallery: [],
	post_start_at: 0,
	post_end_at: "",
	post_end_at_number: 0,
	price_from: "",
	price_to: "",
	recurrence: "none",
	recurrence_days_weekly: [],
	recurrence_date_days_monthly: [],
	availability: {},
	free: false,
	fb_page_share: false,
	instagram_page_share: false,
};
