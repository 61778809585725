import { userActions } from "../../actionTypes";
import { verifyEmail } from "../../../api/libs/user";
import toast from "react-hot-toast";

export const verify = (data, setErrors) => {
	return async dispatch => {
		dispatch({
			type: userActions.VERIFY_EMAIL,
		});
		verifyEmail(data)
			.then(res => {
				if (res.success) {
					dispatch({
						type: userActions.VERIFICATION_FULLFILLED,
					});
					toast.success("Email verified");
				}
			})
			.catch(err => {
				dispatch({
					type: userActions.VERIFICATION_REJECTED,
					payload: err.response,
				});
				setErrors(err.response.data.errors);
			});
	};
};
