import React, { useState } from "react";
import MobileGallery from "./MobileGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.scss";
import { getOriginal } from "../../../../../../utils/imgUri";
import { Box, Collapse, Text } from "@chakra-ui/react";
import logoPlaceholder from "../../../../../../assets/images/logoplaceholder.png"; // Tell webpack this JS file uses this image
import getISODay from "date-fns/getISODay";
import format from "date-fns/format";
import { content } from "../../content";
function MobilePrev({
	formik: {
		values: {
			title,
			sub_title,
			street,
			street_number,
			postal_code,
			city,
			phone_number,
			email,
			web_page,
			official_name,
			description_title,
			description,
			logo,
			country,
			gallery,
		},
	},
	formik,
	lang,
}) {
	const t = content[lang];
	const [showHours, setShowHours] = useState(false);
	const [show, setShow] = useState(false);
	const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	const handleToggle = () => setShow(!show);

	return (
		<div className="m-app-prev-root">
			<div id="previewScroll" className="m-app-prev-box">
				<div className="m-app-header">
					<div className="m-app-gallery">
						<MobileGallery gallery={gallery} />
					</div>

					<div className="m-app-branding">
						<div className="m-app-logo">
							<img
								src={
									logo.includes("data:image")
										? logo
										: logo.length === 0
										? logoPlaceholder
										: getOriginal(logo)
								}
								alt="company logo"
							/>
						</div>
						<Box className="m-app-captions" ml="1em">
							<Text
								wordBreak="break-all"
								color={title ? "#FFF" : "rgba(255, 255, 255, 0.7)"}
								fontFamily="Segoe UI Bold"
								fontSize="1.5em"
								noOfLines="1"
							>
								{title || "Location name"}
							</Text>
							<Text
								noOfLines="1"
								wordBreak="break-all"
								color={sub_title ? "#FFF" : "rgba(255, 255, 255, 0.5)"}
								mt="-0.5em"
							>
								{sub_title || "Location Unterzeile"}
							</Text>
						</Box>
					</div>
				</div>
				<div className="m-app-body">
					<Box className="m-app-content">
						<Text
							fontFamily="Segoe UI SemiLight"
							fontSize="1.5em"
							color={description_title ? "#000" : "rgba(0, 0, 0, 0.4)"}
							mb="0.3em"
							mt="0.3em"
						>
							{description_title || "Überschrift"}
						</Text>
						<Collapse startingHeight={170} in={show}>
							<Text
								wordBreak="break-all"
								color={description ? "#000" : "rgba(0, 0, 0, 0.4)"}
								fontFamily="Segoe UI Light"
								fontSize="1em"
							>
								{description || "Beschreibung"}
							</Text>
						</Collapse>
						{description.length > 330 && (
							<Box textAlign="right">
								<Text as="i" onClick={handleToggle}>
									{show ? "Less" : "Read more"}
								</Text>
							</Box>
						)}
					</Box>
					{formik.values.open_hours.some(day =>
						day.hours.some(time => time.open_at && time.close_at),
					) && (
						<>
							{t.open_times}{" "}
							{showHours ? (
								<Box>
									{!formik.errors.open_hours && (
										<>
											{formik.values.open_hours.map((day, dayIndex) =>
												day.hours.map(
													(time, timeIndex) =>
														time.open_at &&
														time.close_at && (
															<Box display="grid" gridTemplateColumns="3fr 7fr" gridGap="0.5em">
																{timeIndex === 0 ? <Text>{days[dayIndex]} </Text> : <Box></Box>}
																<Text>
																	{time.open_at === "00:00" && time.close_at === "24:00"
																		? `${time.open_at} - ${time.close_at} 24/7`
																		: `${time.open_at} - ${time.close_at}`}
																</Text>
															</Box>
														),
												),
											)}
											<Text cursor="pointer" onClick={() => setShowHours(false)}>
												{t.see_less} <FontAwesomeIcon icon="caret-up" />
											</Text>
										</>
									)}
								</Box>
							) : (
								<Box display="flex">
									{formik.values.open_hours[getISODay(new Date()) - 1].hours.some(
										item =>
											format(new Date(), "HH:mm") >= item.open_at &&
											format(new Date(), "HH:mm") <= item.close_at,
									) ? (
										<Box
											display="grid"
											alignItems="center"
											gridTemplateColumns="2fr 5fr 5fr"
											gridGap="0.5em"
										>
											<Text color="#AFCB2E" p="0.5em" pl="0">
												{t.open}
											</Text>
											<Text>
												{
													formik.values.open_hours[getISODay(new Date()) - 1].hours.map(
														item =>
															format(new Date(), "HH:mm") >= item.open_at &&
															format(new Date(), "HH:mm") <= item.close_at &&
															item,
													)[0].open_at
												}
												-
												{
													formik.values.open_hours[getISODay(new Date()) - 1].hours.map(
														item =>
															format(new Date(), "HH:mm") >= item.open_at &&
															format(new Date(), "HH:mm") <= item.close_at &&
															item,
													)[0].close_at
												}
											</Text>
											<Text cursor="pointer" onClick={() => setShowHours(true)}>
												{t.see_more} <FontAwesomeIcon icon="caret-down" />
											</Text>
										</Box>
									) : (
										<Box
											display="grid"
											gridGap="0.5em"
											gridTemplateColumns="3fr 7fr"
											alignItems="center"
										>
											<Text color="#FF0000" py="0.5em">
												{t.closed}
											</Text>
											<Text cursor="pointer" onClick={() => setShowHours(true)}>
												{" "}
												{t.see_more} <FontAwesomeIcon icon="caret-down" />
											</Text>
										</Box>
									)}
								</Box>
							)}
						</>
					)}
					<Text
						color={official_name ? "#000" : "rgba(0, 0, 0, 0.4)"}
						fontSize="0.9em"
						fontFamily="Segoe UI SemiBold"
						mt="1.5em"
						mb="-1em"
					>
						{official_name || "Offizieller Name"}
					</Text>
					<Box mt="1em">
						<Text
							color={street || street_number ? "#000" : "rgba(0, 0, 0, 0.4)"}
							fontSize="0.9em"
							fontFamily="Segoe UI SemiBold"
						>
							{street || street_number ? `${street} ${street_number} ` : `Straße Hausnummer `}
						</Text>
						<Text
							color={city || postal_code ? "#000" : "rgba(0, 0, 0, 0.4)"}
							fontSize="0.9em"
							fontFamily="Segoe UI SemiBold"
						>
							{city || postal_code
								? `${country.iso2 || country}, ${postal_code} ${city.name || city}`
								: `Stadt PLZ Land`}
						</Text>
					</Box>
					<div className="m-app-buttons" style={{ margin: "1em 0" }}>
						<ul>
							<li>
								<FontAwesomeIcon icon="star" className="m-app-buttons-icon" />
								<span className="m-app-buttons-label">offers</span>
							</li>
							<li>
								<FontAwesomeIcon icon="heart" className="m-app-buttons-icon" />
								<span className="m-app-buttons-label">subscribe</span>
							</li>
							<li>
								<FontAwesomeIcon icon="map-marker-alt" className="m-app-buttons-icon" />
								<span className="m-app-buttons-label">navigation</span>
							</li>
							<li>
								<FontAwesomeIcon icon="phone-volume" className="m-app-buttons-icon" />
								<Text
									noOfLines="1"
									wordBreak="break-all"
									className="m-app-buttons-label"
									color={phone_number ? "#000" : "rgba(0, 0, 0, 0.4)"}
								>
									<a href={"tel:" + phone_number}>{phone_number || "Telefonnummer"}</a>
								</Text>
							</li>
							<li>
								<FontAwesomeIcon icon="envelope" className="m-app-buttons-icon" />
								<Text
									noOfLines="1"
									wordBreak="break-all"
									className="m-app-buttons-label"
									color={email ? "#000" : "rgba(0, 0, 0, 0.4)"}
								>
									<a style={{ fontSize: "0.8em" }} href={"mailto:" + email}>
										{email || "Email"}
									</a>
								</Text>
							</li>
							<li>
								<FontAwesomeIcon icon="globe" className="m-app-buttons-icon" />
								<Text
									noOfLines="1"
									wordBreak="break-all"
									className="m-app-buttons-label"
									color={web_page ? "#000" : "rgba(0, 0, 0, 0.4)"}
								>
									<a style={{ fontSize: "0.8em" }} rel="noreferrer" target="_blank" href={web_page}>
										{web_page || "Website"}
									</a>
								</Text>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MobilePrev;
