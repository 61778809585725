import { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import Slider from "./Slider";

const Layout = ({ children, activeObject, onOpacityChange }) => {
	const [opacityVal, setOpacityVal] = useState(activeObject?.opacity || 1);

	useEffect(() => {
		if (activeObject !== null) {
			onOpacityChange(opacityVal);
		}
	}, [opacityVal]);

	return (
		<Box h="100%">
			<Box h="100%" py="20px">
				<Box h="70%" display="flex" flexDir="column" overflowY="auto">
					{children}
				</Box>
				<Box h="100px" w="100%" px="20px">
					<Box display="flex" justifyContent="space-between">
						<Text fontSize="16px">Opacity</Text>
						<Text fontSize="16px">{~~(opacityVal * 100)}%</Text>
					</Box>
					<Slider
						opacityVal={opacityVal}
						setOpacityVal={setOpacityVal}
						activeObject={activeObject}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Layout;
