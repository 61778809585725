export const initialValues = {
	title: "",
	sub_title: "",
	street: "",
	street_number: "",
	postal_code: "",
	state: "",
	city: "",
	phone_number: "",
	email: "",
	web_page: "",
	official_name: "",
	description_title: "",
	category_id: "wa8j5ro0x4ykagm6jn9ve6bldqw7p36r",
	description: "",
	latitude: "",
	longitude: "",
	country: "",
	logo: "",
	gallery: [],
	keywords: [],
	open_hours: [
		{ day_id: 1, hours: [{ open_at: "", close_at: "" }] },
		{ day_id: 2, hours: [{ open_at: "", close_at: "" }] },
		{ day_id: 3, hours: [{ open_at: "", close_at: "" }] },
		{ day_id: 4, hours: [{ open_at: "", close_at: "" }] },
		{ day_id: 5, hours: [{ open_at: "", close_at: "" }] },
		{ day_id: 6, hours: [{ open_at: "", close_at: "" }] },
		{ day_id: 7, hours: [{ open_at: "", close_at: "" }] },
	],
	closed_until: "",
	closed_message: "",
	send_hours: true,
	fb_page_share: false,
	instagram_page_share: false,
};
