import React from "react";
import { Box } from "@chakra-ui/react";

const Layout = props => {
	return (
		<Box h="100%" display="flex" position="relative">
			{props.children}
		</Box>
	);
};

export default Layout;
