import { statisticActions } from "../../actionTypes";
import { getStatsCountries, getStatsPlatforms } from "../../../api/libs/stats";

export const fetchStatsCountries = config => {
	return async dispatch => {
		dispatch({
			type: statisticActions.FETCH_STATS_COUNTRIES,
		});
		getStatsCountries(config)
			.then(res => {
				console.log("success");
				dispatch({
					type: statisticActions.FETCH_STATS_COUNTRIES_FULFILLED,
					payload: { ...config, res: res.data },
				});
			})
			.catch(err => {
				dispatch({
					type: statisticActions.FETCH_STATS_COUNTRIES_REJECTED,
					payload: err.response,
				});
			});
	};
};

export const fetchStatsPlatforms = config => {
	return async dispatch => {
		dispatch({
			type: statisticActions.FETCH_STATS_PLATFORMS,
		});
		getStatsPlatforms(config)
			.then(res => {
				console.log(res.data);
				dispatch({
					type: statisticActions.FETCH_STATS_PLATFORMS_FULFILLED,
					payload: { ...config, res: res.data },
				});
			})
			.catch(err => {
				dispatch({
					type: statisticActions.FETCH_STATS_PLATFORMS_REJECTED,
					payload: err.response,
				});
			});
	};
};
