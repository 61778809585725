import React from "react";
import { Box } from "@chakra-ui/react";

function PickerDisplay(props) {
	return (
		<Box display="flex" flexDir="column" justifyContent="center" mt="20px">
			{props.children}
		</Box>
	);
}

export default PickerDisplay;
