import React, { useState, useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";

import { onSetTextAlignment, onUpdateTextColor } from "../../../../../utils/textboxActions";

import TextActionBtn from "../buttons/TextActionBtn";

import textEditIco from "../../../../../../../../assets/builder/text_edit_1.svg";
import textColorIco from "../../../../../../../../assets/builder/text_color_1.svg";
import textColorIcoDis from "../../../../../../../../assets/builder/text_color_0.svg";

function TextAlignment({ state, canvas, activePanel, tooglePanel, lastUsedColor }) {
	const [selected, setSelected] = useState(false);
	useEffect(() => {
		if (state.activeObject) {
			setSelected(state.activeObject.textAlign);
		}
	}, [state.activeObject]);
	return (
		<Box py="20px" display="flex" justifyContent="center" alignItems="center">
			<TextActionBtn
				type="align-left"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={selected === "left"}
				handleClick={() => {
					onSetTextAlignment(canvas, state, "left");
					setSelected("left");
				}}
			/>
			<TextActionBtn
				type="align-center"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={selected === "center"}
				handleClick={() => {
					onSetTextAlignment(canvas, state, "center");
					setSelected("center");
				}}
			/>
			<TextActionBtn
				type="align-right"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={selected === "right" || false}
				handleClick={() => {
					onSetTextAlignment(canvas, state, "right");
					setSelected("right");
				}}
			/>
			<Box w="1px" bg="black" height="70%" mr="15px" />
			<Box w="25px" h="25px" cursor="pointer" mr="15px" onClick={tooglePanel}>
				{activePanel === "FONT_EDIT" ? (
					<Image
						src={
							state.activeObject !== null && state.activeObject?.type === "textbox"
								? textColorIco
								: textColorIcoDis
						}
					/>
				) : (
					<Image src={textEditIco} />
				)}
			</Box>

			{/* quick color controlls */}
			<Box
				w="25px"
				h="25px"
				cursor="pointer"
				mr="15px"
				borderRadius="50%"
				borderWidth="0.5px"
				borderColor="black"
				bg={state.activeObject !== null ? "black" : "white"}
				onClick={() => {
					if (state.activeObject !== null) {
						onUpdateTextColor(canvas, state, "#000000", 3, 300);
						canvas.fire("event:cache", {
							eName: "change-color",
							from: "quick-color",
						});
					}
				}}
			/>
			<Box
				w="25px"
				h="25px"
				cursor="pointer"
				mr="15px"
				borderRadius="50%"
				borderWidth="0.5px"
				borderColor="black"
				bg={state.activeObject !== null ? "#8b8b7f" : "white"}
				onClick={() => {
					if (state.activeObject !== null) {
						onUpdateTextColor(canvas, state, "#8b8b7f", 295, 220);
						canvas.fire("event:cache", {
							eName: "change-color",
							from: "quick-color",
						});
					}
				}}
			/>
			<Box
				w="25px"
				h="25px"
				cursor="pointer"
				mr="15px"
				borderRadius="50%"
				borderWidth="0.5px"
				borderColor="black"
				bg={"white"}
				onClick={() => {
					if (state.activeObject !== null) {
						onUpdateTextColor(canvas, state, "#ffffff", 295, 5);
						canvas.fire("event:cache", {
							eName: "change-color",
							from: "quick-color",
						});
					}
				}}
			/>
			{lastUsedColor.value && (
				<Box
					w="25px"
					h="25px"
					cursor="pointer"
					mr="15px"
					border="1px solid black"
					borderRadius="50%"
					bg={state.activeObject !== null ? lastUsedColor.value : "white"}
					onClick={() => {
						if (state.activeObject !== null) {
							onUpdateTextColor(
								canvas,
								state,
								lastUsedColor.value,
								lastUsedColor.x,
								lastUsedColor.y,
							);
							canvas.fire("event:cache", {
								eName: "change-color",
								from: "quick-color",
							});
						}
					}}
				/>
			)}
		</Box>
	);
}

export default TextAlignment;
