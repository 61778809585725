import { companyActions } from "../../actionTypes";

const initialState = {
	company: {},
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case companyActions.SELECT_COMPANY: {
			return { ...state, company: action.payload };
		}

		case companyActions.REMOVE_SELECTED_COMPANY: {
			return {
				...state,
				company: {},
			};
		}
		default:
	}

	return state;
};

export default Reducer;
