import React, { useEffect } from "react";
import { format } from "date-fns";
import startOfDay from "date-fns/startOfDay";
import { useFormik } from "formik";
import { useSlides } from "../../Editor/Builder/store";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getCategories, getFormats, getSubCategories } from "../../../api/libs/events";
import { uploadBase64 } from "../../../api/libs/upload";
import {
	attachKeywords,
	createEvent,
	fetchEvent,
	fetchFacebookPages,
	fetchKeywordsById,
	updateEvent,
} from "../../../store/actions";
import Loading from "../../shared/Loading";
import { content } from "./components/lang";
import Layout from "./components/Layout";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";

function Events() {
	const resetBuilderStore = useSlides(state => state.resetStore);
	const dispatch = useDispatch();
	const history = useHistory();

	//If present get event id
	let { id } = useParams();

	//Refetch on language change
	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];
	const { locations, fetched: fetchedLocations } = useSelector(state => state.fetchLocations);
	const { event, fetched, fetching } = useSelector(state => state.fetchEvent);
	const { pages, fetched: fetchedPages } = useSelector(state => state.fetchFacebookPages);
	useEffect(() => {
		if (id) dispatch(fetchEvent(id));
	}, [id]);

	async function checkValue(logo) {
		if (logo.includes("data:image")) {
			const formData = new FormData();
			formData.append("image[0]", logo);
			let newLogo = uploadBase64(formData, false, false);
			return newLogo;
		}
		return logo;
	}
	useEffect(() => {
		if (!fetchedPages) {
			dispatch(fetchFacebookPages(false));
		}
	}, []);

	useEffect(() => {
		formik.setValues({
			...initialValues,
		});
		if (fetchedLocations && !id) {
			if (locations.data.length === 1) {
				formik.setValues({
					...formik.values,
					locationLogo: locations.data[0].logo,
					locationTitle: locations.data[0].title,
					locationPhone: locations.data[0].phone_number,
					locationAddress: locations.data[0].street
						? `${locations.data[0].street} ${locations.data[0].street_number} `
						: "",
					locationCategory: locations.data[0].category.name,
					location: { id: [locations.data[0].id] },
				});
			}
		}
		if (fetched && id && fetchedLocations) {
			const availability = event.availability.reduce(
				(obj, current) => ({
					...obj,
					[startOfDay(new Date(current.start_at.replace(" ", "T")))]: {
						start_at: new Date(current.start_at.replace(" ", "T")),
						end_at: new Date(current.end_at.replace(" ", "T")),
					},
				}),
				{},
			);

			const locationItem = locations.data.find(item => item.id === event.location.id[0]);

			formik.setValues({
				...initialValues,
				...event,
				locationLogo: locationItem?.logo,
				locationTitle: locationItem?.title,
				locationPhone: locationItem?.phone_number,
				locationAddress: locationItem?.street
					? `${locationItem.street} ${locationItem.street_number} `
					: "",
				locationCategory: locationItem?.category?.name,
				availability: availability,
				recurrence: event.recurrence ? event.recurrence : "none",
				free: !event.price_from && !event.price_to ? true : false,
				gallery: event.gallery.data,
				links: !event.links
					? {
							zoom: "",
							google_meet: "",
					  }
					: {
							zoom: event.links.zoom || "",
							google_meet: event.links.google_meet || "",
					  },
			});
		}

		if (id) dispatch(fetchKeywordsById("events", id, formik));

		return () => {
			formik.setValues(initialValues);
			resetBuilderStore();
		};
	}, [fetched, fetchedLocations]);

	const handleKeywordsSubmit = (eventId, setErrors) => {
		dispatch(
			attachKeywords(
				"events",
				eventId,
				formik.values.keywords.map(item => item.id),
			),
		);
	};
	function handleSubmit(values, { setErrors }) {
		const filtered = Object.keys(values)
			.filter(
				key =>
					key !== "gallery" &&
					key !== "locationAddress" &&
					key !== "locationCategory" &&
					key !== "locationTitle" &&
					key !== "start_at" &&
					key !== "end_at" &&
					key !== "post_start_at",
			)
			.reduce((obj, key) => {
				obj[key] = values[key];
				return obj;
			}, {});

		const availability = Object.keys(formik.values.availability).map(key => ({
			start_at: format(formik.values.availability[key].start_at, "yyyy-MM-dd HH:mm:ss"),
			end_at: format(formik.values.availability[key].end_at, "yyyy-MM-dd HH:mm:ss"),
		}));
		let data = {
			...filtered,
			availability: availability,
			recurrence: filtered.recurrence === "none" ? null : filtered.recurrence,
			format_id:
				typeof filtered.format_id === "object" ? filtered.format_id.id : filtered.format_id,
			category_id:
				typeof filtered.category_id === "object" ? filtered.category_id.id : filtered.category_id,
			sub_category_id:
				typeof filtered.sub_category_id === "object"
					? filtered.sub_category_id.id
					: filtered.sub_category_id,
			links:
				filtered.links.zoom && filtered.links.google_meet
					? {
							zoom: filtered.links.zoom,
							google_meet: filtered.links.google_meet,
					  }
					: filtered.links.google_meet
					? {
							google_meet: filtered.links.google_meet,
					  }
					: filtered.links.zoom
					? {
							zoom: filtered.links.zoom,
					  }
					: null,
		};
		checkValue(id && values.gallery[0].image ? values.gallery[0].image : values.gallery[0])
			.then(res => {
				if (typeof res === "object") {
					data.logo = res.data.images[0];
					if (id) {
						dispatch(
							updateEvent(
								id,
								data,
								event.gallery.id,
								values.gallery,
								setErrors,
								history,
								handleKeywordsSubmit,
							),
						);
					} else {
						dispatch(createEvent(data, values.gallery, setErrors, history, handleKeywordsSubmit));
					}
				} else {
					if (id) {
						dispatch(
							updateEvent(
								id,
								data,
								event.gallery.id,
								values.gallery,
								setErrors,
								history,
								handleKeywordsSubmit,
							),
						);
					} else {
						dispatch(createEvent(data, values.gallery, setErrors, history, handleKeywordsSubmit));
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}
	const { subcategories, fetched: fetchedSubs } = useSelector(state => state.fetchSubCategories);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false,
	});

	const setFullNames = async () => {
		const responseCategory = await getCategories(1, formik.values.category_id);
		formik.setFieldValue("category_id", responseCategory.data.data[0]);
		const responseSubCategory = await getSubCategories(
			formik.values.category_id,
			1,
			formik.values.sub_category_id,
		);
		formik.setFieldValue("sub_category_id", responseSubCategory.data.data[0]);

		const responseFormats = await getFormats(1, formik.values.format_id);
		formik.setFieldValue("format_id", responseFormats.data.data[0]);
	};

	useEffect(() => {
		if (typeof formik.values.category_id === "string" && formik.values.category_id) {
			setFullNames();
		}
	}, [formik.values.category_id]);

	useEffect(() => {
		if (fetchedSubs && !id) formik.setFieldValue("sub_category_id", subcategories[0]);
	}, [formik.values.category_id]);
	if (fetching) return <Loading />;
	return <Layout pages={pages} formik={formik} header={t.header} lang={lang} eventId={id} />;
}

export default Events;
