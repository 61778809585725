import React, { useState, useCallback, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import ScrollContainer from "../components/ScrollContainer";
import FontItem from "./components/FontItem";

import { onSetFontFamily } from "../../../../../../../../utils/textboxActions";
import { useSlides } from "../../../../../../../../../Builder/store";

function Fonts({ canvas, state }) {
	const getFonts = useSlides(state => state.getFonts);
	const getFontsNext = useSlides(state => state.getFontsNext);

	const { data, fetching, fetched, canFetchNext, fetchingNext } = useSlides(state => state.fonts);

	const fetchFonts = useCallback(() => {
		if (data.length === 0 && !fetching) {
			getFonts();
		}
	}, []);

	useEffect(() => {
		fetchFonts();
	}, []);

	const handleClick = name => {
		onSetFontFamily(canvas, state, name);
	};

	const { ref, inView, entry } = useInView({ threshold: 0 });
	useEffect(() => {
		if (inView) {
			if (!fetching && !fetchingNext && fetched) {
				if (canFetchNext) {
					getFontsNext();
				}
			}
		}
	}, [inView]);

	return (
		<ScrollContainer>
			{data.map((font, key) => (
				<FontItem
					key={key}
					name={font?.font_names[0]}
					handleClick={() => {
						handleClick(font?.font_names[0].slice(0, -4));
					}}
				/>
			))}
			<div
				ref={ref}
				style={{
					textAlign: "center",
				}}
			>
				{(fetching || fetchingNext) && <h2>loading</h2>}
				{!canFetchNext && <h2>No more fonts</h2>}
			</div>
		</ScrollContainer>
	);
}

export default Fonts;
