import React, { useState } from "react";
import { useSelector } from "react-redux";
import Layout from "./components/Layout";
import { useDisclosure } from "@chakra-ui/react";
import { resendVerificationEmail } from "../../../api/libs/user";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import { useEffect } from "react";

export default function ProfileHome() {
	const [newEmail, setNewEmail] = useState();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const history = useHistory();

	const { user } = useSelector(state => state.currentUser.user);
	const lang = useSelector(state => state.languageProvider.language);

	const handleSubmit = values => {
		toast.promise(resendVerificationEmail(values.new_email), {
			loading: "Sending email...",
			success: () => {
				onClose();
				return <b>Email sent!</b>;
			},
			error: err => {
				formik.setErrors(err.response.data.errors);
				return <b>Couldn't send email.</b>;
			},
		});
	};
	const formik = useFormik({
		initialValues: { new_email: "" },
		onSubmit: handleSubmit,
	});

	const [limits, setLimits] = useState([]);
	const { stripe_subscriptions, paypal_subscriptions } = useSelector(
		state => state.fetchCompany.company,
	);
	useEffect(() => {
		let stripeLimits = {},
			paypalLimits = {};

		if (stripe_subscriptions?.length > 0) {
			stripeLimits = stripe_subscriptions[0].plan_price.company_per_plan_limitation;
			Object.keys(stripeLimits).forEach(key => {
				if (stripeLimits[key] !== 0) delete stripeLimits[key];
			});
		}

		if (paypal_subscriptions?.length > 0) {
			paypalLimits = paypal_subscriptions[0].plan_price.company_per_plan_limitation;
			Object.keys(paypalLimits).forEach(key => {
				if (paypalLimits[key] !== 0) delete paypalLimits[key];
			});
		}

		if (
			Object.keys(stripeLimits)?.length < Object.keys(paypalLimits)?.length ||
			paypal_subscriptions?.length === 0
		) {
			setLimits(Object.keys(stripeLimits));
		} else {
			setLimits(Object.keys(paypalLimits));
		}
	}, [paypal_subscriptions, stripe_subscriptions]);

	return (
		<Layout
			history={history}
			lang={lang}
			formik={formik}
			newEmail={newEmail}
			setNewEmail={setNewEmail}
			onSubmit={handleSubmit}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			user={user}
			limits={limits}
		></Layout>
	);
}
