import { languageActions } from "../../actionTypes";
const initialState = {
	language: window.localStorage.getItem("lang") || "de",
};

export const languageProvider = (state = initialState, action) => {
	switch (action.type) {
		case languageActions.SET_LANGUAGE:
			return { ...state, language: action.payload };
		default:
			return state;
	}
};
