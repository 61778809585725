import { companyActions } from "../../actionTypes";

const initialState = {
	company: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case companyActions.FETCH_COMPANY: {
			return { ...state, fetching: true };
		}

		case companyActions.FETCH_COMPANY_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				company: action.payload,
			};
		}

		case companyActions.SET_CURRENT_COMPANY: {
			return {
				...state,
				company: {
					...action.payload,
					category: {
						...action.payload.category,
						id: action.payload.category_id,
					},
				},
			};
		}

		case companyActions.FETCH_COMPANY_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
