import { userActions } from "../../actionTypes";
import { getFacebookUserDetails } from "../../../api/libs/user";

export const fetchFacebookUserDetails = () => {
	return async dispatch => {
		dispatch({
			type: userActions.FACEBOOK_USER_DETAILS_FETCHING,
		});
		getFacebookUserDetails()
			.then(res => {
				dispatch({
					type: userActions.FACEBOOK_USER_DETAILS_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				dispatch({
					type: userActions.FACEBOOK_USER_DETAILS_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
