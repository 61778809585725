import { useSlides } from "../store";
export const onLoadVideo = (index, media, thumb, resetState) => {
	const modifySlide = useSlides.getState().modifySlide;
	modifySlide(index, {
		isVideo: true,
		video: media,
		isPano: false,
		pano: null,
		orientation: "squere",
		isEdited: true,
		png: thumb,
	});
	resetState();
};
