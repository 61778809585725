export const content = {
	en: {
		header: "News",
		client: "Create news",
		desc: "Description",
		ctitle: "Title",
		start_at: "Start at",
		end_at: "End at",
		location: "Location",
		locations: "Locations",
		confirm: "Next",
		reset: "Reset",
		is_pinned: "Pin news",
		post_ends: "Post ends at",
		back: "Back",
		publish: "Publish",
	},
	de: {
		header: "Mandant erstellen",
		client: "Mandat",
		desc: "Beschreibung",
		ctitle: "Titel Mandant",
		start_at: "Beginne bei",
		end_at: "Ende um",
		location: "Standort",
		locations: "Standorte",
		confirm: "Nächste",
		reset: "Zurücksetzten",
		is_pinned: "Nachrichten pinnen",
		post_ends: "Beitrag endet um",
		back: "Zurück",
		publish: "Veröffentlichen",
	},
};
