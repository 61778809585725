import React from "react";
import ForLogo from "./Components/ForLogo";
import ForGallery from "./Components/ForGallery";

import { onInputImg } from "../../../../utils/common";

import { useSlides } from "../../../../store";

function Initial(props) {
	const { canvas, variant, refer, state, setState, fileInput } = props;

	const slides = useSlides(state => state[variant]);
	const { addSlide, selectSlide, removeSlide, cloneSlide, reOrderSlides, slideLoading } = useSlides(
		state => state,
	);

	const onAddSlide = () => {
		addSlide(canvas, refer, setState);
	};
	const onSelectSlide = index => {
		selectSlide(canvas, index, state, setState);
	};
	const onRemoveSlide = index => {
		removeSlide(index, setState);
	};
	const onCloneSlide = () => {
		cloneSlide();
	};

	const onDragEnd = res => {
		if (!res.destination) return;
		const updatedOrder = Array.from(slides);
		const [reorderedItem] = updatedOrder.splice(res.source.index, 1);
		updatedOrder.splice(res.destination.index, 0, reorderedItem);
		reOrderSlides(updatedOrder);
		if (res.source.index === state.activeSlide) {
			setState(state => {
				state.activeSlide = res.destination.index;
			});
		}
		if (res.destination.index === state.activeSlide) {
			setState(state => {
				state.triggerRender = !state.triggerRender;
			});
		}
	};

	if (variant === "logo") {
		return <ForLogo {...{ state, setState, fileInput, onInputImg }} />;
	}
	return (
		<ForGallery
			{...{
				...props,
				onInputImg,
				slides,
				onAddSlide,
				onSelectSlide,
				onRemoveSlide,
				onCloneSlide,
				slideLoading,
				onDragEnd,
			}}
		/>
	);
}

export default Initial;
