import React, { useEffect, useState } from "react";
import ProfileEdit from "../../company/edit";
import { Box, Heading, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { updateCompany } from "../../../store/actions";
import { initialValues } from "../../company/edit/formInitialValues";
import Loading from "../../shared/Loading";
import { useFormik } from "formik";
import PrimaryInput from "../../shared/inputs/PrimaryInput";
import PrimaryButton from "../../shared/buttons/PrimaryButton";
import { content } from "../../company/edit/components/content";
const EditCompany = () => {
	const [companyId, setCompanyId] = useState(null);
	useEffect(() => {
		setCompanyId(
			localStorage.getItem("activeCompany") ? localStorage.getItem("activeCompany") : null,
		);
	}, []);
	const dispatch = useDispatch();

	const [uploaded, setUploaded] = useState("");

	const { company, fetching: fetchingCompany, fetched } = useSelector(state => state.fetchCompany);
	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];

	const onSubmit = values => {
		const data = {
			...values,
			logo: uploaded[0] || formik.values.logo,
		};
		dispatch(updateCompany(companyId, data, {}, formik.setErrors));
	};

	useEffect(() => {
		const modCompany = { ...company, category_id: company?.category?.id };
		formik.setValues({
			...modCompany,
			country: modCompany.country?.iso2 || modCompany.country,
			state: modCompany.state?.state_code || modCompany.state,
			city: modCompany.city?.name || modCompany.city,
			timezone: modCompany.timezone?.zone_name || modCompany.timezone,
		});
	}, [fetched]);

	useEffect(() => {}, []);
	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: onSubmit,
	});

	if (fetchingCompany) return <Loading />;
	return (
		<>
			<Box p={5} top="0" bottom="0" right="0" width="100%">
				<Heading pt={["2em", "0"]} mb="1em">
					{t.edit_company} {formik.values.name}
				</Heading>
				<form style={{ display: "flex", flexDirection: "column" }} onSubmit={formik.handleSubmit}>
					<PrimaryInput label={t.name} type="text" id="name" formik={formik} />
					<PrimaryInput label={t.email} type="text" id="email" formik={formik} />
					<PrimaryButton mt="0.5em" alignSelf="flex-end" type="submit" label={t.submit} />
				</form>
			</Box>
		</>
	);
};

export default EditCompany;
