import { eventActions } from "../../actionTypes";
import { updateEvent as updateEventFunction } from "../../../api/libs/events";
import toast from "react-hot-toast";
import newGallery from "../../../utils/gallery";
import { createGallery } from "../../../api/libs/gallery";
import { Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";

export const updateEvent = (
	id,
	data,
	galleryId,
	gallery,
	setErrors,
	history,
	handleKeywordsSubmit,
) => {
	return async dispatch => {
		dispatch({
			type: eventActions.UPDATE_EVENT,
		});

		toast.promise(updateEventFunction(id, data), {
			loading: "Updating event...",
			success: res => {
				history.push("/events");
				handleKeywordsSubmit(id, setErrors);
				newGallery(gallery)
					.then(galleryArr => {
						dispatch({
							type: eventActions.UPDATE_EVENT_FULFILLED,
							payload: res.data,
						});
						createGallery(galleryId, galleryArr, data.instagram_page_share)
							.then(r => console.log(r))
							.catch(e => console.log(e));
					})
					.catch(err => {
						console.log(err);
					});

				return (
					<Alert status="success">
						<AlertIcon />
						<b>Successfully edited event.</b>;
					</Alert>
				);
			},
			error: err => {
				setErrors(err.response.data.errors);

				dispatch({
					type: eventActions.UPDATE_EVENT_REJECTED,
					payload: err.response.data.errors,
				});
				return (
					<Alert status="error">
						<AlertIcon />
						<AlertDescription>
							<b>Couldn't update event.</b>
						</AlertDescription>
					</Alert>
				);
			},
		});
	};
};
