import { apiCallAc } from "..";

export function getPushNotifications(page = 1) {
	return apiCallAc.get(`/dashboard/notifications/notification?page=${page}`);
}

export function getPushNotification(id) {
	return apiCallAc.get("/dashboard/notifications/notification/" + id);
}

export function getNotifiableUsers(page = 1) {
	return apiCallAc.get(`/dashboard/notifications/users?page=${page}`);
}

export function addNotification(data) {
	return apiCallAc.post("/dashboard/notifications/notification", data);
}
