import React from "react";
import { Box } from "@chakra-ui/react";

const Container = props => {
	return (
		<Box h="540px" border="1px solid #D6D6D5">
			{props.children}
		</Box>
	);
};

export default Container;
