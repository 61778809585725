import { userActions } from "../../actionTypes";
import { getCurrentUser } from "../../../api/libs/user";

export const fetchUserData = (history, disableLoading) => {
	return async dispatch => {
		if (!disableLoading) {
			dispatch({
				type: userActions.USER_FETCHING,
			});
		}
		getCurrentUser()
			.then(res => {
				dispatch({
					type: userActions.USER_FULFILLED,
					payload: res,
				});
				if (history) {
					history.push("/");
				}
			})
			.catch(err => {
				localStorage.removeItem("token");
				dispatch({
					type: userActions.USER_REJECTED,
					payload: err.response.data.title,
				});
			});
	};
};
