import React, { useState, useEffect } from "react";

import ScrollContainer from "../components/ScrollContainer";
import Slider from "./components/Slider";

function Shadow({ canvas }) {
	const [shadow, setShadow] = useState(0);
	useEffect(() => {
		if (canvas.getActiveObject()) {
			if (canvas.getActiveObject().type === "textbox") {
				if (canvas.getActiveObject().shadow) {
					setShadow(canvas.getActiveObject().shadow.blur);
				}
			}
		}
	}, []);
	useEffect(() => {
		if (canvas.getActiveObject()) {
			if (canvas.getActiveObject().type === "textbox") {
				if (shadow > 0) {
					canvas.getActiveObject().set("shadow", { color: "black", blur: shadow });
					canvas.renderAll();
				} else {
					canvas.getActiveObject().set("shadow", null);
					canvas.renderAll();
				}
			}
		}
	}, [shadow]);
	return (
		<ScrollContainer>
			<Slider
				canvas={canvas}
				type="shadow"
				val={shadow}
				setVal={setShadow}
				max={20}
				isDisabled={canvas.getActiveObject()?.type !== "textbox"}
			/>
		</ScrollContainer>
	);
}

export default Shadow;
