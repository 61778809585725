import { deleteLocation as deleteLocationFunction } from "../../../api/libs/locations";
import toast from "react-hot-toast";
import { fetchLocations } from ".";
import { locationActions } from "../../actionTypes";

export const deleteLocation = id => {
	return async dispatch => {
		dispatch({
			type: locationActions.DELETE_LOCATION,
		});
		deleteLocationFunction(id)
			.then(res => {
				dispatch({
					type: locationActions.DELETE_LOCATION_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully deleted location");

				dispatch(fetchLocations());
			})
			.catch(err => {
				dispatch({
					type: locationActions.DELETE_LOCATION_REJECTED,
					payload: err.response,
				});
			});
	};
};
