import React from "react";
import { Box } from "@chakra-ui/react";
import Countries from "./Countries";
import States from "./States";
import Cities from "./Cities";
import Timezones from "./Timezones";

export default function CountrySelect({ formik, column, countryLabel, ...rest }) {
	return (
		<Box
			display="grid"
			gridTemplateColumns={column ? ["1fr", "1fr 1fr 1fr 1fr"] : "1fr "}
			gridGap="0.5em"
			mt="0.5em"
		>
			<Countries formik={formik} column={column} />
			<States formik={formik} column={column} />
			<Cities formik={formik} column={column} />
			<Timezones formik={formik} column={column} />
		</Box>
	);
}
