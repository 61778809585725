export const content = {
	en: {
		logged: "Logged in as:",
		client: "Active:",
		add_company: "Add company",
		edit_company: "Edit company",
		profile: "Profile",
		"edit.profile": "Edit profile",
		payments: "Payments",
	},
	de: {
		logged: "Angemeldet als",
		client: "Aktiver:",
		add_company: "Unternehmen hinzufügen",
		edit_company: "Firma bearbeiten",
		profile: "Profil",
		"edit.profile": "Profil bearbeiten",
		payments: "Zahlungen",
	},
};
