import { useDisclosure } from "@chakra-ui/hooks";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchNotifications } from "../../store/actions";
import Loading from "../shared/Loading";
import Layout from "./components/Layout";

function Notifications() {
	const dispatch = useDispatch();
	const [currentId, setCurrentId] = useState(0);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		notifications,
		fetched: fetchedNotifications,
		fetching,
		notifications: { current_page, next_page_url },
	} = useSelector(state => state.fetchNotifications);

	const lang = useSelector(state => state.languageProvider.language);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		dispatch(fetchNotifications());
	}, [lang, currentPage]);

	if (!fetchedNotifications) return <Loading />;
	return (
		<Layout
			notifications={notifications.data}
			currentId={currentId}
			setCurrentId={setCurrentId}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			lang={lang}
			setCurrentPage={setCurrentPage}
			next_page_url={next_page_url}
			current_page={current_page}
			fetchNotifications={fetchNotifications}
			loading={fetching}
		/>
	);
}

export default Notifications;
