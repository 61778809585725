import React from "react";
import {
	Menu,
	MenuList,
	MenuButton,
	MenuOptionGroup,
	MenuItemOption,
	Box,
	Image,
	Button,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import enImage from "../../../../assets/images/lang/en.svg";
import deImage from "../../../../assets/images/lang/de.svg";

function Selector({ current, set, ...rest }) {
	return (
		<Menu {...rest}>
			<MenuButton
				bg="white"
				outline="none"
				_active={{ outline: "none" }}
				_focus={{ outline: "none" }}
				as={Button}
				rightIcon={
					<FontAwesomeIcon icon="chevron-down" color="#CCCCCC" style={{ fontSize: ".85em" }} />
				}
			>
				{current === "en" ? (
					<Box display="flex" alignItems="center">
						<Image boxSize="1.5rem" src={enImage} alt="English" mr="12px" />
						<span>English</span>
						<Box ml="5px" h="15px" w="1px" bg="#CCCCCC" borderRadius="full" opacity=".5" />
					</Box>
				) : (
					<Box display="flex" alignItems="center">
						<Image boxSize="1.5rem" src={deImage} alt="Deutsch" mr="12px" />
						<span>Deutsch</span>
						<Box ml="5px" h="15px" w="1px" bg="#CCCCCC" borderRadius="full" opacity=".5" />
					</Box>
				)}
			</MenuButton>

			<MenuList>
				<MenuOptionGroup defaultValue={current} type="radio" onChange={lang => set(lang)}>
					<MenuItemOption minH="48px" value="en">
						<Box display="flex" alignItems="center">
							<Image boxSize="1.5rem" src={enImage} alt="English" mr="12px" />
							<span>English</span>
						</Box>
					</MenuItemOption>
					<MenuItemOption minH="40px" value="de">
						<Box display="flex" alignItems="center">
							<Image boxSize="1.5rem" src={deImage} alt="Deutsch" mr="12px" />
							<span>Deutsch</span>
						</Box>
					</MenuItemOption>
				</MenuOptionGroup>
			</MenuList>
		</Menu>
	);
}

export default Selector;
