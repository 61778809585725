import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useQuery from "../../../utils/query";
import { addFacebookCode } from "../../../store/actions";

export default function Facebook() {
	const dispatch = useDispatch();
	let query = useQuery();
	const history = useHistory();
	useEffect(() => {
		dispatch(addFacebookCode(query.get("code"), history));
	}, [query]);
	return null;
}
