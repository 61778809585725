import axios from "axios";
import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION;

export const apiCall = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/json",
		// Authorization: `Bearer ${token}`,
	},
});

export const apiCallAc = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/json",
	},
});

export const chatUsers = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/json",
	},
});

export const apiMedia = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
		"Image-Type": "image",
	},
});

export const apiMediaLogo = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
		"Image-Type": "logo",
	},
});

export const apiBase64 = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
		"Image-Type": "logo",
	},
});

export const apiVideo = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "multipart/form-data",
		"Video-Type": "story",
	},
});

export const apiACMedia = axios.create({
	baseURL: baseURL,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
	},
});

export const apiGoogle = axios.create({
	baseURL: "https://maps.googleapis.com",
	params: {
		key: process.env.REACT_APP_GOOGLE_MAP_KEY,
	},
});

export const setTokenHeader = token => {
	if (token) {
		apiCall.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		apiCallAc.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		apiMedia.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		apiBase64.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		apiACMedia.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		apiVideo.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		apiMediaLogo.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	} else {
		apiCall.defaults.headers.common["Authorization"] = `Bearer `;
		apiCallAc.defaults.headers.common["Authorization"] = `Bearer `;
		apiMedia.defaults.headers.common["Authorization"] = `Bearer `;
		apiBase64.defaults.headers.common["Authorization"] = `Bearer `;
		apiACMedia.defaults.headers.common["Authorization"] = `Bearer `;
		apiVideo.defaults.headers.common["Authorization"] = `Bearer `;
		apiMediaLogo.defaults.headers.common["Authorization"] = `Bearer `;
	}
};

export const setLanguageHeader = lang => {
	if (lang) {
		apiCall.defaults.headers["Accept-Language"] = lang;
		apiCallAc.defaults.headers["Accept-Language"] = lang;
		apiMedia.defaults.headers["Accept-Language"] = lang;
		apiBase64.defaults.headers["Accept-Language"] = lang;
		apiACMedia.defaults.headers["Accept-Language"] = lang;
		apiMediaLogo.defaults.headers["Accept-Language"] = lang;
	} else {
		apiCall.defaults.headers["Accept-Language"] = localStorage.getItem("lang") || "de";
		apiCallAc.defaults.headers["Accept-Language"] = localStorage.getItem("lang") || "de";
		apiMedia.defaults.headers["Accept-Language"] = localStorage.getItem("lang") || "de";
		apiBase64.defaults.headers["Accept-Language"] = localStorage.getItem("lang") || "de";
		apiACMedia.defaults.headers["Accept-Language"] = localStorage.getItem("lang") || "de";
		apiMediaLogo.defaults.headers["Accept-Language"] = localStorage.getItem("lang") || "de";
	}
};

export const setActiveCompanyToken = token => {
	if (token) {
		apiCallAc.defaults.headers.common["active-company"] = token;
		apiACMedia.defaults.headers.common["active-company"] = token;
		apiVideo.defaults.headers.common["active-company"] = token;
	} else {
		apiCall.defaults.headers.common["active-company"] = ``;
		apiACMedia.defaults.headers.common["active-company"] = ``;
		apiVideo.defaults.headers.common["active-company"] = ``;
	}
};

apiCall.interceptors.response.use(
	function (response) {
		return response.data;
	},

	function (error) {
		let res = error.response;
		if (error.response.status === 401) {
			window.location.reload();
		}
		if (error.response.data.errors.subscription_limitation_reached) {
			toast.error(error.response.data.errors.subscription_limitation_reached);
		}

		console.error("Looks like there was a problem. Status Code: " + res.status);
		return Promise.reject(error);
	},
);

apiCallAc.interceptors.response.use(
	function (response) {
		return response;
	},

	function (error) {
		let res = error.response;
		if (error.response.status === 401) {
			window.location.reload();
		}
		if (error.response.data.errors.subscription_limitation_reached) {
			toast.error(error.response.data.errors.subscription_limitation_reached);
		}

		console.error("Looks like there was a problem. Status Code: " + res.status);
		return Promise.reject(error);
	},
);

apiMedia.interceptors.response.use(
	function (response) {
		return response;
	},

	function (error) {
		let res = error.response;
		if (error.response.status === 401) {
			window.location.reload();
		}
		if (error.response.data.errors.subscription_limitation_reached) {
			toast.error(error.response.data.errors.subscription_limitation_reached);
		}

		console.error("Looks like there was a problem. Status Code: " + res.status);
		return Promise.reject(error);
	},
);

apiBase64.interceptors.response.use(
	function (response) {
		return response;
	},

	function (error) {
		let res = error.response;
		if (error.response.status === 401) {
			window.location.reload();
		}
		if (error.response.data.errors.subscription_limitation_reached) {
			toast.error(error.response.data.errors.subscription_limitation_reached);
		}

		console.error("Looks like there was a problem. Status Code: " + res.status);
		return Promise.reject(error);
	},
);

apiACMedia.interceptors.response.use(
	function (response) {
		return response;
	},

	function (error) {
		let res = error.response;
		if (error.response.status === 401) {
			window.location.reload();
		}
		if (error.response.data.errors.subscription_limitation_reached) {
			toast.error(error.response.data.errors.subscription_limitation_reached);
		}

		console.error("Looks like there was a problem. Status Code: " + res.status);
		return Promise.reject(error);
	},
);
export default apiCall;
