import React from "react";
import { Box, Tabs, TabList, Tab } from "@chakra-ui/react";
import { content } from "./lang.js";

function Tabbar({ lang }) {
	const t = content[lang];

	return (
		<Box>
			<Tabs variant="enclosed" color="black" outline="none">
				<TabList borderColor="black" outline="none" color="#CBCCCB">
					<Tab tabIndex={0} _focus={{ outline: "none" }}>
						{t.select}
					</Tab>
				</TabList>
			</Tabs>
		</Box>
	);
}

export default Tabbar;
