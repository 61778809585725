import React from "react";
import { Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Hamburger({ sidebarActive, handleToogle }) {
	return (
		<Button
			backgroundColor="transparent"
			my="5px"
			mx="5px"
			borderColor="transparent"
			border="0px"
			variant="unstyled"
			p="0px"
			_focus={{
				bg: "transparent",

				borderColor: "transparent",
			}}
			className="hamburger-menu"
			onClick={handleToogle}
		>
			{sidebarActive && (
				<Box display="inline" mr="0.2em">
					<FontAwesomeIcon color="#ADC439" size="sm" icon="chevron-left"></FontAwesomeIcon>
				</Box>
			)}
			<FontAwesomeIcon size="lg" color="#7F7F7B" icon="bars"></FontAwesomeIcon>
			{!sidebarActive && (
				<Box display="inline" ml="0.2em">
					<FontAwesomeIcon color="#ADC439" size="sm" icon="chevron-right"></FontAwesomeIcon>
				</Box>
			)}
		</Button>
	);
}

export default Hamburger;
