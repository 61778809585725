import React from "react";
import { Text } from "@chakra-ui/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Box } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import toast from "react-hot-toast";

export default function ParentButton({ item, trStyle, locationsLength, limits }) {
	return (
		<NavLink
			// onClick={() => (width <= 768 && !expanded ? expandedChange() : null)}
			onClick={() => {
				if (
					limits.includes(item.unique_name) ||
					(item.unique_name === "coupons" && limits.includes("company_coupons")) ||
					(item.unique_name === "notifications" &&
						limits.includes("push_notifications") &&
						limits.includes("email_notifications"))
				) {
					toast.error("Upgrade plan to use feature", {
						style: {
							border: "1px solid #713200",

							color: "#713200",
						},
						iconTheme: {
							primary: "#713200",
							secondary: "#FFFAEE",
						},
					});
				}
			}}
			key={item.id}
			activeClassName={
				limits.includes(item.unique_name) ||
				(item.unique_name === "coupons" && limits.includes("company_coupons")) ||
				(item.unique_name === "notifications" &&
					limits.includes("push_notifications") &&
					limits.includes("email_notifications"))
					? "disabled"
					: "active"
			}
			to={
				limits.includes(item.unique_name) ||
				(item.unique_name === "coupons" && limits.includes("company_coupons")) ||
				(item.unique_name === "notifications" &&
					limits.includes("push_notifications") &&
					limits.includes("email_notifications"))
					? "#"
					: item.url
			}
		>
			<Button
				disabled={
					limits.includes(item.unique_name) ||
					(item.unique_name === "coupons" && limits.includes("company_coupons")) ||
					(item.unique_name === "notifications" &&
						limits.includes("push_notifications") &&
						limits.includes("email_notifications"))
				}
				_focus={{ boxShadow: "none" }}
				borderRadius={0}
				backgroundColor="#1A1919"
				_hover={{ backgroundColor: "#4D4D4D" }}
				display="flex"
				justifyContent="flex-start"
				width="100%"
			>
				<Box width="20%" display="flex">
					<FontAwesomeIcon
						icon={
							item.icon === "fa-solid fa-house"
								? "home"
								: item.icon === "bell-on"
								? "fa-solid fa-bell"
								: item.icon
						}
					></FontAwesomeIcon>
				</Box>
				<Box style={trStyle}>
					<Text>{item.name}</Text>
				</Box>
			</Button>
		</NavLink>
	);
}
