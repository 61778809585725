import React, { useEffect, useState } from "react";
import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { months } from "./constantValues";
import { content } from "./components/content";
import { useSelector } from "react-redux";
import CalendarModal from "./components/Modal";
import {
	getDaysInMonth,
	format,
	isBefore,
	isEqual,
	startOfDay,
	setHours,
	setMinutes,
	isToday,
	addDays,
	getHours,
	getMinutes,
} from "date-fns";
import TimeModal from "./components/TimeModal";
import getDay from "date-fns/getDay";
import isAfter from "date-fns/isAfter";
import SelectedTable from "./components/SelectedTable";

export default function CalendarInputNew({ formik, label, start, end }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const today = new Date();
	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];
	const [modalVisible, setModalVisible] = useState(false);
	const [selectedType, setSelectedType] = useState(1);
	const [data, setData] = useState([]);
	const [dateSelected, setDateSelected] = useState(false);
	const [timeValue, setTimeValue] = useState("00:00");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [openAt, setOpenAt] = useState(new Date());
	const [closeAt, setCloseAt] = useState(new Date());

	//Generates calendar data
	useEffect(() => {
		let generated = [];
		let month = today.getMonth();
		let year = today.getFullYear();

		for (let i = 0; i < 12; i++) {
			let monthData = {};
			let firstDay = new Date(year, month).getDay();
			let daysInMonth = getDaysInMonth(new Date(year, month));
			monthData.firstDay = firstDay;
			monthData.name = months[month];
			monthData.year = year;
			monthData.daysInMonth = daysInMonth;
			monthData.month = month;
			if (month === 11) {
				year += 1;
				month = 0;
			} else {
				month += 1;
			}

			generated.push(monthData);
		}

		setData(generated);
	}, []);

	//Recurrence change radio buttons
	const handleRecurrenceChange = value => {
		formik.setFieldValue("availability", {});
		setSelectedType(1);
		setDateSelected(false);
		formik.setFieldValue("recurrence", value);
	};

	//handle selecting days
	const handleSelect = (day, open_at, close_at) => {
		switch (formik.values.recurrence) {
			case "range": {
				if (Object.keys(formik.values.availability).length === 2) {
					formik.setFieldValue("availability", {
						[day]: { start_at: open_at, end_at: close_at },
					});
				} else {
					formik.setFieldValue("availability", {
						...formik.values.availability,
						[day]: { start_at: open_at, end_at: close_at },
					});
				}

				break;
			}
			case "daily": {
				formik.setFieldValue("availability", {
					[day]: { start_at: open_at, end_at: close_at },
				});
				break;
			}
			case "weekly": {
				formik.setFieldValue("availability", {
					...formik.values.availability,
					[day]: { start_at: open_at, end_at: close_at },
				});

				break;
			}
			case "monthly": {
				formik.setFieldValue("availability", {
					...formik.values.availability,
					[day]: { start_at: open_at, end_at: close_at },
				});
				break;
			}
			default:
				formik.setFieldValue("availability", {
					...formik.values.availability,
					[day]: { start_at: open_at, end_at: close_at },
				});
				break;
		}
	};

	//day color change
	const dayColor = day => {
		const firstDate = new Date(Object.keys(formik.values.availability)[0]);
		if (isBefore(day, firstDate)) return false;

		switch (formik.values.recurrence) {
			case "range": {
				return (
					isEqual(day, new Date(Object.keys(formik.values.availability)[0])) ||
					isEqual(day, new Date(Object.keys(formik.values.availability)[1])) ||
					(isAfter(day, new Date(Object.keys(formik.values.availability)[0])) &&
						isBefore(day, new Date(Object.keys(formik.values.availability)[1])))
				);
			}
			case "daily": {
				return isEqual(firstDate, day) || isAfter(day, firstDate);
			}
			case "weekly": {
				return Object.keys(formik.values.availability).some(key => {
					return getDay(day) === getDay(new Date(key));
				});
			}
			case "monthly": {
				return Object.keys(formik.values.availability).some(key => {
					return format(day, "dd") === format(new Date(key), "dd");
				});
			}
			default:
				return Object.keys(formik.values.availability).some(key => {
					return isEqual(day, new Date(key));
				});
		}
	};

	//Disabled days
	const handleDisabled = day => {
		if (!formik.values.recurrence) return true;
		if (isBefore(day, startOfDay(new Date()))) return true;
		const firstDate = new Date(Object.keys(formik.values.availability)[0]);

		switch (formik.values.recurrence) {
			case "range": {
				return (
					Object.keys(formik.values.availability).length === 1 &&
					isBefore(day, new Date(Object.keys(formik.values.availability)[0]))
				);
			}
			case "daily": {
				return false;
			}
			case "weekly": {
				return isBefore(day, firstDate) || isAfter(day, addDays(firstDate, 6));
			}
			case "monthly": {
				if (format(day, "dd") > 27) return true;

				return (
					isBefore(day, firstDate) ||
					isAfter(day, addDays(firstDate, getDaysInMonth(firstDate) - 1))
				);
			}
			default:
				return isBefore(day, startOfDay(new Date()));
		}
	};

	//Time input modal open
	const handleModalOpen = day => {
		setSelectedDate(day);
		if (
			formik.values.recurrence === "range" &&
			Object.keys(formik.values.availability).length === 1
		) {
			const startDateWithHours = setHours(
				day,
				getHours(formik.values.availability[Object.keys(formik.values.availability)[0]].start_at),
			);
			const startDateWithMinutes = setMinutes(
				startDateWithHours,
				getMinutes(formik.values.availability[Object.keys(formik.values.availability)[0]].start_at),
			);
			const endDateWithHours = setHours(
				day,
				getHours(formik.values.availability[Object.keys(formik.values.availability)[0]].end_at),
			);
			const endDateWithMinutes = setMinutes(
				endDateWithHours,
				getMinutes(formik.values.availability[Object.keys(formik.values.availability)[0]].end_at),
			);

			handleSelect(day, startDateWithMinutes, endDateWithMinutes);
		} else {
			if (formik.values.availability[day]) {
				setOpenAt(formik.values.availability[day].start_at);
				setCloseAt(formik.values.availability[day].end_at);
			} else {
				setOpenAt(null);
				setCloseAt(null);
			}
			if (isToday(day)) setTimeValue(format(new Date(), "HH:mm"));

			if (dateSelected) {
				const dateWithHours = setHours(day, timeValue.split(":")[0]);
				const dateWithMinutes = setMinutes(dateWithHours, timeValue.split(":")[1]);

				handleSelect(day, dateWithMinutes);
			} else {
				onOpen();
			}
		}
	};

	//Time input modal close
	const handleModalConfirm = () => {
		handleSelect(selectedDate, openAt, closeAt);

		onClose();
		setTimeValue("00:00");
	};

	//Reset whole input
	const handleReset = () => {
		formik.setFieldValue("recurrence", "none");
		formik.setFieldValue("recurrence_date_days_monthly", []);
		formik.setFieldValue("recurrence_days_weekly", []);
		formik.setFieldValue("availability", {});
		setSelectedType(1);
		setDateSelected(false);
		formik.setFieldValue(start, null);
		formik.setFieldValue(end, null);
	};
	console.log(formik.values.availability);
	return (
		<Box>
			<CalendarModal
				handleReset={handleReset}
				dayColor={dayColor}
				handleDisabled={handleDisabled}
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				t={t}
				label={label}
				selectedType={selectedType}
				data={data}
				start={start}
				formik={formik}
				handleRecurrenceChange={handleRecurrenceChange}
				end={end}
				setSelectedType={setSelectedType}
				setDateSelected={setDateSelected}
				dateSelected={dateSelected}
				onOpen={handleModalOpen}
			/>
			<Text mt="0.5em">{label}</Text>
			<SelectedTable
				t={t}
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				formik={formik}
				lang={lang}
			/>

			<TimeModal
				setSelectedDate={setSelectedDate}
				selectedDate={selectedDate}
				isOpen={isOpen}
				onClose={onClose}
				handleModalConfirm={handleModalConfirm}
				setTimeValue={setTimeValue}
				setOpenAt={setOpenAt}
				setCloseAt={setCloseAt}
				openAt={openAt}
				closeAt={closeAt}
			/>
		</Box>
	);
}
