import { locationActions } from "../../actionTypes";
import { verifyLocationEmail } from "../../../api/libs/user";
import toast from "react-hot-toast";

export const verifyLocation = (data, setErrors) => {
	return async dispatch => {
		dispatch({
			type: locationActions.VERIFY_LOCATION_EMAIL,
		});
		verifyLocationEmail(data)
			.then(res => {
				if (res.success) {
					dispatch({
						type: locationActions.VERIFY_LOCATION_EMAIL_FULLFILLED,
					});
					toast.success("Location email verified");
				}
			})
			.catch(err => {
				dispatch({
					type: locationActions.VERIFY_LOCATION_EMAIL_REJECTED,
					payload: err.response,
				});
				setErrors(err.response.data.errors);
			});
	};
};
