import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
	prefer: Yup.string().required(),
	data_type: Yup.string().required(),
	data_id: Yup.string().required(),
	user: Yup.object().shape({
		id: Yup.array().min(1).required(),
	}),
});
