import React from "react";
import { Box } from "@chakra-ui/react";

function WrapLayout(props) {
	return (
		<Box
			h="60vh"
			borderBottom="1px solid #CBCCCB"
			overflowY="auto"
			overflowX="hidden"
			className="custom-scroll"
		>
			<Box
				display="flex"
				flexWrap="wrap"
				justifyContent="space-between"
				mt="20px"
				py="20px"
				pt="0"
				pr="20px"
			>
				{props.children}
			</Box>
		</Box>
	);
}

export default WrapLayout;
