import { chatsActions } from "../../actionTypes";
import { addMessage } from "../../../api/libs/chats";

export const createMessage = (id, data) => {
	return async dispatch => {
		dispatch({
			type: chatsActions.CREATE_MESSAGE,
		});
		addMessage(id, data)
			.then(res => {
				dispatch({
					type: chatsActions.CREATE_MESSAGE_FULFILLED,
					payload: res.data,
				});
				dispatch({
					type: chatsActions.ADD_MESSAGE,
					payload: data,
				});
			})
			.catch(err => {
				dispatch({
					type: chatsActions.CREATE_MESSAGE_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
