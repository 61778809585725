import { uploadMedia, uploadTemplate, uploadBase64, uploadLogo } from "../api/libs/upload";
import toast from "react-hot-toast";

export const shallow = e => {
	const files = Array.from(e.target.files);
	const formData = new FormData();
	files.forEach((file, i) => {
		formData.append(`image[${i}]`, file);
	});
	return uploadMedia(formData, false);
};

export const upload = (e, setLoading, callback, fullUrl, setProgress) => {
	const files = Array.from(e.target.files);
	setLoading(true);

	const formData = new FormData();

	files.forEach((file, i) => {
		formData.append(`image[${i}]`, file);
	});

	uploadMedia(formData, fullUrl, setProgress)
		.then(res => {
			callback(res.data.images);

			setLoading(false);
		})
		.catch(err => {
			console.log(err);
			setLoading(false);
		});
};

export const uploadMediaLogo = (e, setLoading, callback, fullUrl, setProgress) => {
	const files = Array.from(e.target.files);
	setLoading(true);

	const formData = new FormData();

	files.forEach((file, i) => {
		formData.append(`image[${i}]`, file);
	});

	const headers = {
		"Content-Type": "application/x-www-form-urlencoded",
		"Image-Type": "logo",
	};
	uploadLogo(formData, fullUrl, setProgress, headers)
		.then(res => {
			callback(res.data.images);
			console.log("Uplpad Successful", res.data.images);

			setLoading(false);
		})
		.catch(err => {
			console.log(err);
			console.log("Uplpad Failed", err);
			setLoading(false);
		});
};

export const upload64 = (e, fullUrl, callback, setProgress) => {
	const formData = new FormData();

	e.forEach((file, i) => {
		formData.append(`image[${i}]`, file);
	});

	uploadBase64(formData, fullUrl, setProgress)
		.then(res => {
			callback(res.data.images);
			setProgress(prev => ({
				...prev,
				val: 0,
			}));
		})
		.catch(err => {
			console.log(err);
			setProgress(prev => ({
				...prev,
				loading: false,
			}));
		});
};

export const onUploadTemplate = (tmpl, prev, original, type, setTemplateLoading, dispatch) => {
	let data = {
		template: JSON.stringify(tmpl),
		preview: prev,
		original: original,
	};
	uploadTemplate(data, type)
		.then(res => {
			setTemplateLoading(false);
			toast.success("Successfully saved template");
			dispatch({
				type: "ADD_TEMPLATE",
				payload: res.data,
			});
		})
		.catch((/* err */) => {
			toast.error("Couldn't save template.");
			setTemplateLoading(false);
		});
};
