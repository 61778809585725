import { newsActions } from "../../actionTypes";
import { updateNews as updateNewsFunction } from "../../../api/libs/news";
import toast from "react-hot-toast";
import { createGallery } from "../../../api/libs/gallery";
import newGallery from "../../../utils/gallery";

export const updateNews = (
	id,
	data,
	galleryId,
	gallery,
	setErrors,
	history,
	handleKeywordsSubmit,
) => {
	return async dispatch => {
		dispatch({
			type: newsActions.UPDATE_NEWS,
		});

		toast.promise(updateNewsFunction(id, data), {
			loading: "Updating news...",
			success: res => {
				history.push("/news");
				handleKeywordsSubmit(id, setErrors);
				newGallery(gallery)
					.then(galleryArr => {
						dispatch({
							type: newsActions.UPDATE_NEWS_FULFILLED,
							payload: res.data,
						});
						createGallery(galleryId, galleryArr, data.instagram_page_share)
							.then(r => console.log(r))
							.catch(e => console.log(e));
					})
					.catch(err => {
						console.log(err);
					});

				return <b>Successfully edited news</b>;
			},
			error: err => {
				setErrors(err.response.data.errors);

				dispatch({
					type: newsActions.UPDATE_NEWS_REJECTED,
					payload: err.response.data.errors,
				});
				<b>Couldn't update news.</b>;
			},
		});
	};
};
