import React from "react";
import { Box } from "@chakra-ui/react";

import { dimensionsObj } from "../../../../static/canvasDimensions";

function Layout({ children, as, slides }) {
	let currentOr = slides[as].orientation;
	return (
		<Box
			position="absolute"
			top="0"
			left="0"
			h="540px"
			w="540px"
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			pointerEvents="none"
		>
			<Box
				h={dimensionsObj[currentOr].height}
				w={dimensionsObj[currentOr].width}
				display="flex"
				flexDir="column"
				justifyContent="space-between"
				paddingX="20px"
				paddingY="20px"
				pointerEvents="none"
			>
				{children}
			</Box>
		</Box>
	);
}

export default Layout;
