import { userActions } from "../../actionTypes";
import { loginUser } from "../../../api/libs/user";
import { setTokenHeader } from "../../../api";
import toast from "react-hot-toast";

export const logUser = userData => {
	return async dispatch => {
		dispatch({
			type: userActions.USER_FETCHING,
		});
		loginUser(userData)
			.then(res => {
				window.localStorage.setItem("token", res.access_token);
				setTokenHeader(res.access_token);
				dispatch({
					type: userActions.USER_FULFILLED,
					payload: res,
				});
			})
			.catch(error => {
				dispatch({
					type: userActions.USER_REJECTED,
					payload: error.response,
				});
				if (error.response?.data) {
					let errorT;

					if (error.response?.data?.errors && error.response?.data?.errors.email_or_password) {
						errorT = error.response?.data?.errors.email_or_password[0];
					} else if (error.response?.data?.errors) {
						errorT = error.response?.data?.errors[0];
					}
					toast.error(errorT);
				}
			});
	};
};
