import React from "react";
import { Box } from "@chakra-ui/react";
import Day from "../components/Day";
import { takeMonth } from "../calendar";

export default function Month({
	year,
	month,
	formik,
	handleSelect,
	dateSelected,
	dayColor,
	handleDisabled,
	onOpen,
}) {
	const currentMonthsFirstDay = new Date(year, month, 1);
	const data = takeMonth(currentMonthsFirstDay)();
	return data.map((week, index) => (
		<Box key={index} display="grid" gridTemplateColumns="repeat(7,1fr)" gridGap="0.1em">
			{week.map((day, index) => (
				<Day
					key={index}
					onOpen={onOpen}
					dayColor={dayColor}
					handleDisabled={handleDisabled}
					dateSelected={dateSelected}
					formik={formik}
					handleSelect={handleSelect}
					day={day}
					currentMonthsFirstDay={currentMonthsFirstDay}
				/>
			))}
		</Box>
	));
}
