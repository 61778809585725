import React, { useState, useEffect, useCallback, memo } from "react";
import { useInView } from "react-intersection-observer";

import Layout from "./Layout";
import FontItem from "./FontItem";

import { onSetFontFamily } from "../../../../../../utils/textboxActions";
import { useSlides } from "../../../../../../store";

function Fonts({ canvas, state }) {
	const { data, fetching } = useSlides(state => state.fonts);

	const getFonts = useSlides(state => state.getFonts);

	const fetchFonts = useCallback(() => {
		if (data.length === 0 && !fetching) {
			getFonts();
		}
	}, []);

	useEffect(() => {
		fetchFonts();
	}, []);

	const handleClick = name => {
		onSetFontFamily(canvas, state, name);
	};

	if (!data) return null;
	if (data.length === 0) return null;

	return (
		<Layout>
			{data.map((font, key) => (
				<FontItem
					key={key}
					name={font?.font_names[0]}
					handleClick={() => {
						handleClick(font?.font_names[0].slice(0, -4));
					}}
				/>
			))}
		</Layout>
	);
}

export default memo(Fonts);
