import React from "react";
import { Box } from "@chakra-ui/react";

function HSContainer({ children, onDragEnd, id }) {
	return (
		<Box
			maxHeight="70px"
			minH="70px"
			overflowX="auto"
			display="flex"
			className="custom-scroll-horizontal"
		>
			{children}
		</Box>
	);
}

export default HSContainer;
