import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ModalLayout({ children, header, onCloseModal, type }) {
	return (
		<Box
			pos="fixed"
			bottom="0"
			right="0"
			zIndex="100"
			h={type === "layers" || "picker" ? "60vh" : "175px"}
			w="100vw"
			bg="white"
			display="flex"
			flexDir="column"
			border="1px solid black"
		>
			<Box
				h="30px"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				bg="black"
				px="10px"
			>
				<Text
					color="white"
					textTransform="uppercase"
					fontSize="20"
					borderBottom="3px solid #73B13F"
				>
					{header}
				</Text>
				<FontAwesomeIcon
					cursor="pointer"
					icon="times"
					color="white"
					style={{ fontSize: "25" }}
					onClick={onCloseModal}
				/>
			</Box>
			<Box h="100%" p="10px">
				{children}
			</Box>
		</Box>
	);
}

export default ModalLayout;
