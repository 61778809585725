import React from "react";
import { Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NewSlideButton({ onAddSlide }) {
	const handleClick = () => {
		onAddSlide();
	};
	return (
		<Box
			display="flex"
			h="50px"
			minW="100px"
			onClick={handleClick}
			mt="auto"
			mb="auto"
			_active={{ bg: "rgba(200,200,200,0.2)" }}
		>
			<Box
				w="100%"
				h="100%"
				display="flex"
				justifyContent="center"
				alignItems="center"
				border="3px solid #AECB2D"
			>
				<FontAwesomeIcon icon="plus" color="#AECB2D" />
			</Box>
		</Box>
	);
}

export default NewSlideButton;
