import React from "react";
import "./index.scss";
import { Box, Image, Text } from "@chakra-ui/react";
import placeholder from "../../../../../../assets/images/placeholder-qr.svg";
import { getQR } from "../../../../../../utils/imgUri";
function MobilePrev({ formik }) {
	return (
		<Box
			height="100%"
			bg="#FFF"
			display="flex"
			alignItems="center"
			p="2em"
			flexDirection="column"
			justifyContent="center"
		>
			<Box bg="#AFCB2E" p="1em" width="100%">
				<Image
					p="1em"
					bg="#FFF"
					objectFit="contain"
					src={formik.values.qrcode ? getQR(formik.values.qrcode) : placeholder}
				/>
			</Box>
			<Box bg="#AFCB2E" width="100%" pb="1em">
				<Text
					textAlign="center"
					color="#FFF"
					fontSize="2em"
					fontFamily="Segoe UI Bold"
					pointerEvents="none"
				>
					SCAN ME
				</Text>
			</Box>
		</Box>
	);
}

export default MobilePrev;
