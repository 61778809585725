import React from "react";
import { Box } from "@chakra-ui/react";
import Day from "../components/Day";
import { takeMonth } from "../calendar";
import { differenceInDays, parseISO } from "date-fns";

export default function Month({
	selectedType,
	year,
	month,
	formik,
	dateSelected,
	dayColor,
	handleDisabled,
	onOpen,
}) {
	const eventLength = differenceInDays(
		parseISO(formik.values.event_end_at),
		parseISO(formik.values.event_start_at),
	);
	const currentMonthsFirstDay = new Date(year, month, 1);
	const data = takeMonth(currentMonthsFirstDay)();
	return data.map((week, index) => (
		<Box key={index} display="grid" gridTemplateColumns="repeat(7,1fr)" gridGap="0.1em">
			{week.map((day, index) => (
				<Day
					key={index}
					onOpen={onOpen}
					dayColor={dayColor}
					handleDisabled={handleDisabled}
					dateSelected={dateSelected}
					eventLength={eventLength}
					selectedType={selectedType}
					formik={formik}
					day={day}
					currentMonthsFirstDay={currentMonthsFirstDay}
				/>
			))}
		</Box>
	));
}
