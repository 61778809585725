import React from "react";
import { Box, Image, Switch, Text } from "@chakra-ui/react";
import { content } from "../components/lang";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import PrimaryTextArea from "../../../shared/inputs/PrimaryTextArea";
import CalendarInputNew from "../../../shared/inputs/calendar-new";
import ModalSelect from "../../../shared/inputs/ModalSelect";
import TimeCategories from "../../../shared/inputs/TimeCategories";
import EventSelect from "../../../shared/inputs/eventSelect";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Client({ formik, lang, list, eventId, pages }) {
	const t = content[lang];
	return (
		<Box h={"60vh"}>
			<PrimaryInput column={true} label={t.ctitle} type="text" id="title" formik={formik} />

			<PrimaryTextArea column={true} label={t.desc} type="text" id="description" formik={formik} />
			<EventSelect formik={formik} column={true} />
			<Box display="grid" gridTemplateColumns={["1fr", "1fr 8fr 8fr"]} gridGap="0.5em">
				<Box
					display="flex"
					alignItems="center"
					flexDirection="column"
					justifyContent="space-around"
				>
					<Text>{t.price_free}</Text>
					<Switch
						isChecked={formik.values.free}
						onChange={e => {
							if (e.target.checked) {
								formik.setFieldValue("price_from", "");
								formik.setFieldValue("price_to", "");
								formik.setFieldValue("free", true);
							} else {
								formik.setFieldValue("free", false);
							}
						}}
					/>
				</Box>
				<PrimaryInput
					disabled={formik.values.free}
					column={true}
					label={t.price_from}
					type="text"
					id="price_from"
					formik={formik}
				/>
				<PrimaryInput
					disabled={formik.values.free}
					column={true}
					label={t.price_to}
					type="text"
					id="price_to"
					formik={formik}
				/>
			</Box>
			<TimeCategories
				formik={formik}
				label={lang === "en" ? "" : "Beitragsbeginn um"}
				id="meet"
				column={true}
				type="date"
				values={[0, 1]}
				labels={[
					<Image height="60%" src="https://st1.zoom.us/static/6.1.6081/image/new/ZoomLogo.png" />,
					<Image
						height="80%"
						src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Google_Meet_text_logo_%282020%29.svg/2560px-Google_Meet_text_logo_%282020%29.svg.png"
					/>,
				]}
			/>
			{formik.values.meet === 0 ? (
				<PrimaryInput
					translate={false}
					column={true}
					type="text"
					placeholder="Invitation Link For Zoom"
					id="links.zoom"
					formik={formik}
				/>
			) : (
				<PrimaryInput
					translate={false}
					column={true}
					type="text"
					placeholder="Google Meet Invitation Link"
					id="links.google_meet"
					formik={formik}
				/>
			)}
			<ModalSelect
				formik={formik}
				id="location.id"
				fields={["logo", "title", "street", "street_number", "city"]}
				label={lang === "en" ? "Location" : "Lage"}
				header="Select location"
				type="location"
			/>

			<CalendarInputNew
				formik={formik}
				label="EVENT RANGE"
				start="event_start_at"
				end="event_end_at"
			/>
			{pages?.data?.length ? (
				<Box display={["block", "none"]}>
					<PrimaryButton
						minWidth="15%"
						ml="2"
						backgroundColor={formik.values.social_share ? "#4267B2" : "#DDDDDE"}
						label={
							<Box display="flex" alignItems="center" gridGap="1em">
								Share To Facebook
								<FontAwesomeIcon cursor="pointer" icon={["fab", "facebook"]} size="1x" />
							</Box>
						}
						onClick={() => formik.setFieldValue("social_share", !formik.values.social_share)}
					/>
				</Box>
			) : null}
		</Box>
	);
}

export default Client;
