export const _overlay = {
	en: {
		savingTemplate: "saving template...",
	},
	de: {
		savingTemplate: "saving template...",
	},
};

export const _menuBtn = {
	en: {
		preview: "Preview",
		saveTmpl: "Save template",
		exportPng: "Export as png",
		exportTmpl: "Export as template",
	},
	de: {
		preview: "Preview",
		saveTmpl: "Save template",
		exportPng: "Export as png",
		exportTmpl: "Export as template",
	},
};

export const _sideMenu = {
	en: {
		loadImg: "Load Image",
		newSlide: "New Slide",
		newText: "new textfield",
	},
	de: {
		loadImg: "Load Image",
		newSlide: "New Slide",
		newText: "new textfield",
	},
};
