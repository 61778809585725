import React, { useEffect, useCallback, useRef } from "react";
import { useImmer } from "use-immer";
import Display from "./components/Display";

import {
	draw,
	getPickedColor,
	getColor,
	positionHandle,
	hexToRgb,
	rgbStringToHex,
} from "./methods";

function ColorPicker({ activeObject, handleChange, handleDispatch }) {
	const [state, setState] = useImmer({
		isReady: false,
		node: null,
		width: 300,
		height: 300,
		context: null,
		pickerHandle: {
			x: 10,
			y: 10,
			width: 7,
			height: 7,
			bg: null,
		},
		mouseDown: false,
		pickedColor: {
			sRGB: "black",
			hex: "black",
		},
	});

	const stateRef = useRef(state);

	const canvasRef = useCallback(node => {
		if (node !== null) {
			node.width = state.width;
			node.height = state.height;

			setState(state => {
				state.node = node;
				state.width = node.width;
				state.height = node.height;
				state.context = node.getContext("2d");
			});
		}
	}, []);

	useEffect(() => {
		if (activeObject && stateRef.current.context) {
			positionHandle(
				stateRef.current.context,
				activeObject.fill,
				activeObject.picker_x,
				activeObject.picker_y,
				setState,
			);
			if (activeObject.fill.includes("rgb")) {
				//move handle to target color on pallete

				setState(state => {
					state.pickedColor = {
						sRGB: activeObject.fill,
						hex: rgbStringToHex(activeObject.fill),
					};
				});
			} else if (activeObject.fill.includes("#")) {
				//move handle to target color on pallete
				setState(state => {
					state.pickedColor = {
						sRGB: hexToRgb(activeObject.fill),
						hex: activeObject.fill,
					};
				});
			}
		}
	}, [activeObject, stateRef.current.context]);

	useEffect(() => {
		stateRef.current = state;
		if (state.context !== null && !state.isReady) {
			draw(stateRef.current, setState);
			registerEventListeners();
		}
	}, [state]);

	useEffect(() => {
		if (state.context !== null) {
			draw(state, setState);
		}
	}, [state.pickerHandle.x, state.pickerHandle.y]);

	const onMouseDown = e => {
		let rect = stateRef.current.node.getBoundingClientRect();
		let currentX = e.clientX - rect.left;
		let currentY = e.clientY - rect.top;

		setState(state => {
			state.mouseDown = true;
		});
		if (
			currentY >= stateRef.current.pickerHandle.y &&
			currentY <= stateRef.current.pickerHandle.y + stateRef.current.pickerHandle.width &&
			currentX >= stateRef.current.pickerHandle.x &&
			currentX <= stateRef.current.pickerHandle.x + stateRef.current.pickerHandle.width
		) {
			setState(state => {
				state.mouseDown = true;
			});
		} else {
			setState(state => {
				state.pickerHandle = {
					...state.pickerHandle,
					x: currentX,
					y: currentY,
				};
			});
		}
	};

	const onMouseMove = e => {
		if (stateRef.current.mouseDown) {
			let rect = stateRef.current.node.getBoundingClientRect();
			let currentX = e.clientX - rect.left;
			let currentY = e.clientY - rect.top;
			getPickedColor(stateRef.current.context, stateRef.current.pickerHandle, setState);

			setState(state => {
				state.pickerHandle = {
					...state.pickerHandle,
					x: currentX,
					y: currentY,
				};
			});
		}
	};

	const onMouseUp = e => {
		getPickedColor(stateRef.current.context, stateRef.current.pickerHandle, setState);
		handleDispatch(
			getColor(stateRef.current.context, stateRef.current.pickerHandle).hex,
			stateRef.current.pickerHandle.x,
			stateRef.current.pickerHandle.y,
		);
		setState(state => {
			state.mouseDown = false;
		});
	};

	useEffect(() => {
		if (state.pickedColor.hex !== "black") {
			handleChange(state.pickedColor.hex, state.pickerHandle.x, state.pickerHandle.y);
		}
	}, [state.pickedColor]);

	const registerEventListeners = () => {
		state.node.addEventListener("mousedown", onMouseDown);
		state.node.addEventListener("mousemove", onMouseMove);
		state.node.addEventListener("mouseup", onMouseUp);
	};

	const unRegisterEventListeners = () => {
		//  state.node.removeEventListener("mousedown", onMouseDown);
		//  state.node.removeEventListener("mousemove", onMouseMove);
		// document.removeEventListener("mouseup", onMouseUp);
	};

	useEffect(() => {
		return () => {
			unRegisterEventListeners();
		};
	}, []);

	return <Display ref={canvasRef} isDisabled={!activeObject || activeObject.type !== "textbox"} />;
}

export default ColorPicker;
