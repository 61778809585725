import { useState, useEffect } from "react";
// import { useParams } from "react-router";

import { initCanvas, jsonToCanvas } from "../utils/canvas";
import { onListenCanvasEvents } from "../utils/eventListener";
import { calculateDimensions } from "../utils/dimensions";
import { loadPreviewImage } from "../utils/fabricActions";

export function useCanvas(variant, refer, slide, setState) {
	// let { id: isEditing } = useParams();
	const [canvas, setCanvas] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [dimensions] = useState(calculateDimensions());

	useEffect(() => {
		setCanvas(initCanvas(variant, refer, slide.orientation, dimensions));
	}, []);

	useEffect(() => {
		if (canvas) {
			onListenCanvasEvents(canvas, setState);
			if (slide.template) {
				jsonToCanvas(canvas, slide.template);
				if (slide.isPreview && !slide.isVideo) {
					loadPreviewImage(canvas, slide.png);
				}
			}
			if (slide.isVideo) {
				setState(state => {
					state.isVideo = true;
					state.video = slide.video;
				});
			}
			setIsReady(true);
		}
	}, [canvas]);

	return [canvas, isReady];
}
