import { locationActions } from "../../actionTypes";
import { getLocations } from "../../../api/libs/locations";

export const fetchLocations = (type, page, reset) => {
	return async dispatch => {
		dispatch({
			type: locationActions.FETCH_LOCATIONS,
			payload: { reset },
		});
		getLocations(type, page)
			.then(res => {
				dispatch({
					type: locationActions.FETCH_LOCATIONS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: locationActions.FETCH_LOCATIONS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
