import { apiCallAc } from "..";

export function getInvoiceLocations() {
	return apiCallAc.get(`/payment/invoice/location`);
}

export function getInvoiceLocation(id) {
	return apiCallAc.get(`/payment/invoice/location/${id}`);
}

export function createInvoiceLocation(data) {
	return apiCallAc.post(`/payment/invoice/location`, data);
}

export function updateInvoiceLocation(id, data) {
	return apiCallAc.put(`/payment/invoice/location/${id}`, data);
}

export function getInvoices() {
	return apiCallAc.get(`/payment/invoices`);
}
