import { statisticActions } from "../../actionTypes";
import produce from "immer";

const initialState = {
	stats: {
		location: {},
		news: {},
		event: {},
	},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case statisticActions.FETCH_STATS: {
				draft.fetching = true;
				break;
				// return { ...initialState, fetching: true };
			}

			case statisticActions.FETCH_STATS_FULFILED: {
				draft.fetching = false;
				draft.fetched = true;
				draft.stats[action.payload.refer][action.payload.period] = action.payload.res;
				break;
			}

			case statisticActions.FETCH_STATS_REJECTED: {
				draft.fetching = false;
				draft.error = action.payload;
				break;
			}
			default:
				return draft;
		}
	});

export default Reducer;
