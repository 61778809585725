import React from "react";
import { Tr as ChakraTr } from "@chakra-ui/react";
import { Consumer } from "../utils";
import useWindowSize from "../../../utils/useWindowSize";

const TrInner = props => {
	const { width } = useWindowSize();
	const { headers, children, inHeader, border, ...rest } = props;
	if (headers && inHeader) {
		React.Children.map(props.children, (child, i) => {
			if (child) {
				headers[i] = child.props.children;
			}
		});
	}

	const childProps = idx => (inHeader ? { key: idx } : { key: idx, columnKey: idx });

	return (
		<ChakraTr
			_hover={{
				backgroundColor: "#F2F1F0",
			}}
			border={border || (width <= 768 && "2px solid #000")}
			my="1em"
			{...rest}
		>
			{children &&
				React.Children.map(
					children,
					(child, idx) => React.isValidElement(child) && React.cloneElement(child, childProps(idx)),
				)}
		</ChakraTr>
	);
};

export const Tr = props => (
	<Consumer>{headers => <TrInner {...props} headers={headers} />}</Consumer>
);
