import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { onPaypalCancel } from "../../../store/actions";
import Loading from "../../shared/Loading";

export default function PaypalCancel() {
	const { search } = useLocation();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(onPaypalCancel(search));
	}, []);
	return <Loading />;
}
