import React from "react";
import { Box, Text } from "@chakra-ui/react";

function NotFound() {
	return (
		<Box h="100%" px="20px">
			<Box h="100%" py="20px">
				<Box w="100%">
					<Text>Templates not found</Text>
					<Text fontSize="0.9rem">Templates will appear here when you create and save them.</Text>
				</Box>
			</Box>
		</Box>
	);
}

export default NotFound;
