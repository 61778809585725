import { chatsActions } from "../../actionTypes";
import { getChats, fetchNext } from "../../../api/libs/chats";

export const fetchChats = data => {
	return async dispatch => {
		dispatch({
			type: chatsActions.FETCH_CHATS,
		});
		getChats(data)
			.then(res => {
				dispatch({
					type: chatsActions.FETCH_CHATS_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				dispatch({
					type: chatsActions.FETCH_CHATS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};

export const fetchChatsNext = url => {
	return async dispatch => {
		dispatch({
			type: chatsActions.FETCH_CHATS_NEXT,
		});
		fetchNext(url + "&show=participants")
			.then(res => {
				dispatch({
					type: chatsActions.FETCH_CHATS_NEXT_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				dispatch({
					type: chatsActions.FETCH_CHATS_NEXT_REJECTED,
					payload: err.response?.data?.errors,
				});
			});
	};
};
