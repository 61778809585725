import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useFormik } from "formik";
import { addDays, addHours, addMinutes, format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { uploadBase64 } from "../../../api/libs/upload";
import {
	attachKeywords,
	createNews,
	fetchKeywordsById,
	fetchNews,
	updateNews,
} from "../../../store/actions";
import { useSlides } from "../../Editor/Builder/store";

import Layout from "./components/Layout";
import Loading from "../../shared/Loading";
import { content } from "./components/content";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";

function News() {
	const resetBuilderStore = useSlides(state => state.resetStore);
	const dispatch = useDispatch();
	const history = useHistory();

	//If present get event id
	let { id } = useParams();

	//Refetch on language change
	const lang = useSelector(state => state.languageProvider.language);
	const { locations, fetched: fetchedLocations } = useSelector(state => state.fetchLocations);
	const t = content[lang];
	const { news, fetched, fetching } = useSelector(state => state.fetchNews);

	useEffect(() => {
		if (id) dispatch(fetchNews(id));
	}, [id]);

	useEffect(() => {
		if (fetchedLocations && !id) {
			if (locations.data.length === 1) {
				formik.setValues({
					...formik.values,
					locationLogo: locations.data[0].logo,
					locationTitle: locations.data[0].title,
					locationPhone: locations.data[0].phone_number,
					locationAddress: locations.data[0].street
						? `${locations.data[0].street} ${locations.data[0].street_number} `
						: "",
					locationCategory: locations.data[0].category.name,
					location: { id: [locations.data[0].id] },
				});
			}
		}
	}, [fetchedLocations]);

	async function checkValue(logo) {
		if (logo.includes("data:image")) {
			const formData = new FormData();
			formData.append("image[0]", logo);
			let newLogo = uploadBase64(formData, false, false);
			return newLogo;
		}
		return logo;
	}

	useEffect(() => {
		formik.setValues({
			...initialValues,
		});
		let locationItem;
		if (locations.data) {
			locationItem = locations.data[0];
		}

		if (fetched && id) {
			formik.setValues({
				...news,
				post_end_at: news.post_end_at ? news.post_end_at.replace(" ", "T") : null,
				locationTitle: locationItem?.title || "",
				locationCategory: locationItem?.category?.name || "",
				locationAddress: `${locationItem?.street} ${locationItem?.street_number}` || "",
				locationPhone: `${locationItem?.phone_number}` || "",
				locationLogo: locationItem.logo,
				post_start_at: 0,
				gallery: news.gallery.data,
			});
		}

		dispatch(fetchKeywordsById("news", id, formik));

		return () => {
			formik.setValues(initialValues);
			resetBuilderStore();
		};
	}, [fetched]);

	const handleKeywordsSubmit = (newsId, setErrors) => {
		dispatch(
			attachKeywords(
				"news",
				newsId,
				formik.values.keywords.map(item => item.id),
			),
		);
	};

	function handleSubmit(values, { setErrors }) {
		const filtered = Object.keys(values)
			.filter(
				key =>
					key !== "gallery" &&
					key !== "locationAddress" &&
					key !== "locationCategory" &&
					key !== "locationTitle" &&
					key !== "post_end_at_number",
			)
			.reduce((obj, key) => {
				obj[key] = values[key];
				return obj;
			}, {});
		const post_start_at =
			values.post_start_at === 0
				? format(new Date(), "yyyy-MM-dd HH:mm:ss")
				: values.post_start_at === 1
				? format(addMinutes(new Date(), 30), "yyyy-MM-dd HH:mm:ss")
				: values.post_start_at === 2
				? format(addHours(new Date(), 1), "yyyy-MM-dd HH:mm:ss")
				: values.post_start_at === 3
				? format(addHours(new Date(), 2), "yyyy-MM-dd HH:mm:ss")
				: format(addDays(new Date(), 1), "yyyy-MM-dd HH:mm:ss");

		let data = {
			...filtered,
			post_start_at: post_start_at,
			post_end_at: values.post_end_at
				? format(new Date(values.post_end_at), "yyyy-MM-dd HH:mm:ss")
				: null,

			recurrence: filtered.recurrence === "none" ? null : filtered.recurrence,
		};

		checkValue(id && values.gallery[0].image ? values.gallery[0].image : values.gallery[0])
			.then(res => {
				if (typeof res === "object") {
					data.logo = res.data.images[0];
					if (id) {
						dispatch(
							updateNews(
								id,
								data,
								news.gallery.id,
								values.gallery,
								setErrors,
								history,
								handleKeywordsSubmit,
							),
						);
					} else {
						dispatch(createNews(data, values.gallery, setErrors, history, handleKeywordsSubmit));
					}
				} else {
					if (id) {
						dispatch(
							updateNews(
								id,
								data,
								news.gallery.id,
								values.gallery,
								setErrors,
								history,
								handleKeywordsSubmit,
							),
						);
					} else {
						dispatch(createNews(data, values.gallery, setErrors, history, handleKeywordsSubmit));
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false,
	});

	if (fetching) return <Loading />;
	return <Layout formik={formik} header={t.header} lang={lang} newsId={id} />;
}

export default News;
