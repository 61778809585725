export const initialValues = {
	city: "",
	country: "",
	state: "",
	timezone: "",
	email: "",
	name: "",
	surname: "",
	postal_code: "",
	phone_number: "",
	vat_number: "",
};
