import { menuActions } from "../../actionTypes";
import { getMenu } from "../../../api/libs/common";

export const fetchMenu = () => {
	return async dispatch => {
		dispatch({
			type: menuActions.FETCH_MENU,
		});
		getMenu()
			.then(res => {
				dispatch({
					type: menuActions.FETCH_MENU_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				dispatch({
					type: menuActions.FETCH_MENU_REJECTED,
					payload: err.response,
				});
			});
	};
};
