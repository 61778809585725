import React from "react";
import { Box } from "@chakra-ui/react";

import OverlayButton from "./OverlayButton";

function ACOverlay({ acIndex, canClone, canDelete, onOpenEditor, onCloneSlide, onRemoveSlide }) {
	return (
		<Box
			pos="absolute"
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			w="100%"
			h="100%"
			pointerEvents="none"
			padding="10px"
		>
			<Box mt="auto">
				<OverlayButton icon="pencil-alt" label="Edit Slide" action={onOpenEditor} />
			</Box>
			<Box alignSelf="flex-end" mt="auto">
				{canClone && <OverlayButton icon="copy" action={onCloneSlide} />}
				{canDelete && (
					<OverlayButton
						icon="times"
						action={() => {
							onRemoveSlide(acIndex);
						}}
					/>
				)}
			</Box>
		</Box>
	);
}

export default ACOverlay;
