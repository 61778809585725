import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createKeyword, fetchKeywords, fetchMyKeywords } from "../../../store/actions";
import Layout from "./components/Layout";
import Loading from "../Loading";
import { useFormik } from "formik";
import { keywordInitialValues } from "./formInitialValues";
import { keywordValidationSchema } from "./validationSchema";

export default function Search({ formik, type = "locations" }) {
	const { locations } = useSelector(state => state.fetchLocations);
	const {
		keywords,
		fetching: fetchingKeywords,
		fetched: fetchedKeywords,
	} = useSelector(state => state.fetchKeywords);

	const {
		myKeywords,
		fetching: fetchingMyKeywords,
		fetched: fetchedMyKeywords,
	} = useSelector(state => state.fetchMyKeywords);

	useEffect(() => {
		if (!fetchedKeywords) {
			if (type === "locations") {
				dispatch(fetchKeywords("locations", formik.values.category_id));
			}
			if (type === "events") {
				dispatch(
					fetchKeywords(
						"events",
						locations.data
							.filter(item => formik.values.location.id.includes(item.id))
							.map(item => item.category_id),
						formik.values.format_id.id || formik.values.format_id,
						formik.values.category_id.id || formik.values.category_id,
						formik.values.sub_category_id.id || formik.values.sub_category_id,
					),
				);
			}
			if (type === "news") {
				console.log(locations.data);
				dispatch(
					fetchKeywords(
						"news",
						locations.data
							.filter(item => formik.values.location.id.includes(item.id))
							.map(item => item.category_id),
					),
				);
			}
		}
	}, [formik.values.category_id]);

	useEffect(() => {
		dispatch(fetchMyKeywords());
	}, []);

	const dispatch = useDispatch();
	function handleKeywordSubmit(values, { setErrors }) {
		dispatch(createKeyword(values, setErrors, formik, formikKeyword));
		formikKeyword.resetForm();
	}
	const formikKeyword = useFormik({
		initialValues: keywordInitialValues,
		onSubmit: handleKeywordSubmit,
		validationSchema: keywordValidationSchema,
		validateOnBlur: false,
		validateOnChange: false,
		validateOnMount: false,
	});

	let keywordIds;
	let myKeywordIds;
	if (type === "locations") {
		if (!fetchingKeywords && fetchedKeywords) {
			keywordIds = keywords.data.map(item => item.id);
		}
	}

	if (!fetchingMyKeywords && fetchedMyKeywords) {
		myKeywordIds = myKeywords.data.map(item => item.id);
	}

	if (fetchingKeywords || !fetchedKeywords || fetchingMyKeywords || !fetchedMyKeywords)
		return <Loading />;
	return (
		<Layout
			dispatch={dispatch}
			formik={formik}
			createKeyword={formikKeyword}
			keywordIds={keywordIds}
			myKeywordIds={myKeywordIds}
			fetchingKeywords={fetchingKeywords}
			fetchingMyKeywords={fetchingMyKeywords}
			keywords={keywords}
			myKeywords={myKeywords}
		/>
	);
}
