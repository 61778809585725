export const content = {
	en: {
		email: "Email Address",
		password: "Password",
		login: "Login",
		forget: "Forgot Password",
		register: "Registration",
		register2: "Register",
		confirm: "Confirm password",
		name: "Name",
		surname: "Surname",
		country: "Country",
		city: "City",
		street: "Street",
		vat: "Vat number",
		post: "Postal code",
		phone: "Phone number",
	},
	de: {
		email: "E-Mail-Addresse",
		password: "Passwort",
		login: "EINLOGGEN",
		forget: "Passwort vergessen",
		register: "Anmeldung",
		register2: "Registrieren",
		confirm: "Kennwort bestätigen",
		name: "Name",
		surname: "Nachname",
		country: "Land",
		city: "Stadt",
		street: "Straße",
		vat: "Umsatzsteuer-Identifikationsnummer",
		post: "Postleitzahl",
		phone: "Telefonnummer",
	},
};
