import React from "react";

function Player({ media }) {
	return (
		<video
			src={media}
			width="540"
			height="540"
			controls
			muted
			autoPlay={false}
			loop
			style={{
				maxHeight: 540,
				maxWidth: 540,
			}}
		>
			<source src={media} />
		</video>
	);
}

export default Player;
