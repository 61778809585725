import { keywordActions } from "../../actionTypes";
import { removeKeyword } from "../../../api/libs/keywords";

export const deleteKeyword = (id, formik) => {
	return async dispatch => {
		dispatch({
			type: keywordActions.DELETE_KEYWORD,
		});
		removeKeyword(id)
			.then(res => {
				dispatch({
					type: keywordActions.DELETE_KEYWORD_FULFILLED,
					payload: res,
				});
				dispatch({
					type: keywordActions.REMOVE_KEYWORD,
					payload: { id },
				});
				formik.setFieldValue(
					"keywords",
					formik.values.keywords.filter(item => item.id !== id),
				);
			})
			.catch(err => {
				dispatch({
					type: keywordActions.DELETE_KEYWORD_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
