import React, { useState, useEffect } from "react";
import { Box, Text, Divider, Flex } from "@chakra-ui/react";
import { Button, Skeleton, IconButton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";

import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { content } from "../components/content";
import { getOriginal } from "../../../../utils/imgUri";
import { getLogoes } from "../../../../utils/imgUri";
import { handleScroll } from "../../../../utils/scroll";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import Gallery from "../../../shared/gallery";
import { fetchLocations, deleteLocation, recoverLocation } from "../../../../store/actions";
import { useDispatch } from "react-redux";
import TabHeaderLight from "../../../shared/tabs/TabHeaderLight";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../../shared/table";
import toast from "react-hot-toast";
import Modal from "../../../shared/Modal";
import Charts from "../../../shared/charts/ChartsModalComp";

function Layout({
	header,
	lang,
	locations,
	mapModal,
	chartsModal,
	setChartsModal,
	setMapModal,
	handleClose,
	loading,
	next_page_url,
	current_page,
	fetching,
	storyModal,
	setStoryModal,
	setFetchLocationsType,
	fetchLocationsType,
}) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [selectedLocation, setSelectedLocation] = useState("");
	const [selectedGallery, setSelectedGallery] = useState([]);
	const [locationIndex, setLocationIndex] = useState("");
	const containerStyle = {
		width: "100%",
		marginTop: "1em",
		height: "100%",
	};
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
	});
	const [geometry, setGeometry] = useState({
		lat: null,
		lng: null,
	});
	const [map, setMap] = React.useState(null);
	const onLoad = map => {};

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);
	const t = content[lang];
	const options = ["published", "deleted"];

	console.log("locations", locations);

	return (
		<Box
			paddingX="20px"
			display="flex"
			flexDirection="column"
			flexGrow="1"
			overflowY="auto"
			onScroll={e =>
				handleScroll(
					e,
					() => dispatch(fetchLocations(fetchLocationsType, current_page + 1)),
					current_page,
					fetching,
					next_page_url,
				)
			}
			position="absolute"
			bottom="0"
			right="0"
			width="100%"
			top={["3em", "0"]}
			pb={["5em", "1em"]}
		>
			<Gallery
				gallery={selectedGallery}
				storyModal={storyModal}
				setStoryModal={setStoryModal}
				locationId={selectedLocation}
				setLocationId={setSelectedLocation}
				locationIndex={locationIndex}
			/>
			{mapModal ? (
				<Modal header="Location" handleClose={handleClose}>
					{isLoaded && (
						<GoogleMap
							center={geometry}
							mapContainerStyle={containerStyle}
							zoom={13}
							onLoad={onLoad}
							onUnmount={onUnmount}
						>
							<Marker draggable={true} position={geometry}></Marker>
							<></>
						</GoogleMap>
					)}
				</Modal>
			) : null}
			{chartsModal.active && (
				<Modal header="Statistics" handleClose={handleClose}>
					<Charts currentTab={chartsModal.currentTab} itemId={chartsModal.itemId} />
				</Modal>
			)}

			<Box py="3" display="flex" justifyContent="space-between" gridGap="1em">
				<Box>
					<Box mb="1">
						<Text fontSize="24px">{header}</Text>
					</Box>
					<Box w="40px">
						<Divider borderBottom="4px" opacity="1" />
					</Box>
				</Box>
				<Link to="/locations/new">
					<Box
						alignSelf="center"
						border="2px solid #000"
						p={["0.5em", "1em"]}
						cursor="pointer"
						transition="100ms all ease"
						_hover={{ transform: "scale(1.05)" }}
					>
						<Box
							p="0"
							backgroundColor="transparent"
							_hover={{
								backgroundColor: "#transparent",
							}}
							_first={{ _hover: { color: "green" } }}
							_focus={{ border: "none" }}
							color="#D7D8D7"
							display="flex"
							alignItems="center"
						>
							<FontAwesomeIcon icon="plus" color="green" />
							<Text color="#000" _last={{ _hover: { color: "#000" } }} ml="2">
								{t.add_location}
							</Text>
						</Box>
					</Box>
				</Link>
			</Box>
			<TabHeaderLight options={options} onChange={setFetchLocationsType} />

			<Box display="flex" flexDirection="column">
				<Table>
					<Thead>
						<Tr>
							<Th>{t.logo}</Th>
							<Th>{t.title}</Th>
							<Th>{t.address}</Th>
							<Th>Subscriptions</Th>
							<Th>Views</Th>
							<Th>{t.map}</Th>
							<Th>{t.copy}</Th>
							<Th textAlign="center">{t.edit}</Th>
							{fetchLocationsType !== "deleted" ? <Th textAlign="center">{t.delete}</Th> : null}
						</Tr>
					</Thead>

					<Tbody>
						{locations?.data?.map((item, index) => (
							<Tr key={index}>
								<Td>
									<div
										className={
											item.pinnedGalleries?.length && fetchLocationsType !== "deleted"
												? "rainbow"
												: ""
										}
										style={{
											position: "relative",
											background: "rgba(0, 0, 0, 0.8)",

											borderRadius: `${100}%`,
											width: 70,
											height: 70,
										}}
									>
										<img
											onClick={() => {
												if (item.pinnedGalleries?.length && fetchLocationsType !== "deleted") {
													setStoryModal(true);
													setSelectedLocation(item.id);
													setSelectedGallery(item.pinnedGalleries);
													setLocationIndex(index);
												}
											}}
											style={{
												width: 70,
												height: 70,
												borderRadius: `${100}%`,
												border: "none",
												transform:
													item.pinnedGalleries?.length && fetchLocationsType !== "deleted"
														? "scale(0.90)"
														: "scale(0.97)",
												background: "#fff",
												cursor: "pointer",
											}}
											src={getLogoes(item.logo)}
											alt="Company Logo"
										/>
									</div>
								</Td>
								<Td>{item.title}</Td>
								<Td>{item.street + " " + item.street_number + ", " + item.city}</Td>
								<Td>
									<Flex justifyContent={"center"} alignItems={"center"}>
										<Button
											onClick={() => {
												setChartsModal({
													currentTab: "subscriber",
													active: true,
													itemId: item.id,
												});
											}}
											p="0"
											backgroundColor="transparent"
											_hover={{
												backgroundColor: "#transparent",
												color: "#000",
											}}
											_focus={{ border: "none" }}
											color="#D7D8D7"
										>
											<FontAwesomeIcon size="2x" icon="bell" />
											<Text ml={"3px"}>{item.subscribed_users_count}</Text>
										</Button>
									</Flex>
								</Td>
								<Td>
									<Button
										onClick={() => {
											setChartsModal({
												currentTab: "location",
												active: true,
												itemId: item.id,
											});
										}}
										p="0"
										backgroundColor="transparent"
										_hover={{
											backgroundColor: "#transparent",
											color: "#000",
										}}
										_focus={{ border: "none" }}
										color="#D7D8D7"
									>
										<FontAwesomeIcon size="2x" icon="eye" />
										<Text ml={"3px"}>{item.views}</Text>
									</Button>
								</Td>

								<Td>
									<Button
										onClick={() => {
											setGeometry({
												lat: parseFloat(item.latitude),
												lng: parseFloat(item.longitude),
											});

											setMapModal(true);
										}}
										p="0"
										backgroundColor="transparent"
										_hover={{
											backgroundColor: "#transparent",
											color: "#000",
										}}
										_focus={{ border: "none" }}
										color="#D7D8D7"
									>
										<FontAwesomeIcon size="2x" icon="map-marked" />
									</Button>
								</Td>
								<Td>
									<IconButton
										backgroundColor="transparent"
										_hover={{
											backgroundColor: "#transparent",
											color: "#E7792A",
										}}
										_focus={{ border: "none" }}
										color="#D7D8D7"
										onClick={() => {
											navigator.clipboard.writeText(item.share_link).then(() => {
												toast.success("copied");
											});
										}}
									>
										<FontAwesomeIcon size="2x" icon="link" />
									</IconButton>
								</Td>

								{fetchLocationsType !== "deleted" ? (
									<Td textAlign="center">
										<Link to={`/locations/edit/${item.id}`}>
											<Button
												p="0"
												backgroundColor="transparent"
												_hover={{
													backgroundColor: "#transparent",
													color: "#E7792A",
												}}
												_focus={{ border: "none" }}
												color="#D7D8D7"
											>
												<FontAwesomeIcon size="2x" icon="pencil-alt" />
											</Button>
										</Link>
									</Td>
								) : (
									<Td textAlign="center">
										<Button
											onClick={() => {
												dispatch(recoverLocation(item.id, history));
											}}
											p="0"
											backgroundColor="transparent"
											_hover={{
												backgroundColor: "#transparent",
												color: "#E7792A",
											}}
											_focus={{ border: "none" }}
											color="#D7D8D7"
										>
											<FontAwesomeIcon size="2x" icon="arrow-left" />
										</Button>
									</Td>
								)}
								{fetchLocationsType !== "deleted" ? (
									<Td textAlign="center">
										<PrimaryButton
											onClick={() => dispatch(deleteLocation(item.id))}
											prompt={true}
											labelType="icon"
											label={<FontAwesomeIcon size="2x" icon="times" />}
											type="circle"
											hoverColor="#F58121"
										></PrimaryButton>
									</Td>
								) : null}
							</Tr>
						))}
						{loading && (
							<Tr
								_hover={{
									backgroundColor: "#F2F1F0",
								}}
							>
								<Td>
									<Skeleton height="20px" />
								</Td>
								<Td>
									<Skeleton height="20px" />
								</Td>

								<Td>
									<Skeleton height="20px" />
								</Td>

								<Td>
									<Skeleton height="20px" />
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</Box>
	);
}

export default Layout;
