import { companyActions } from "../../actionTypes";
import { getCompanies } from "../../../api/libs/company";

export const fetchCompanies = () => {
	return async dispatch => {
		dispatch({
			type: companyActions.FETCH_COMPANIES,
		});
		getCompanies()
			.then(res => {
				dispatch({
					type: companyActions.FETCH_COMPANIES_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				dispatch({
					type: companyActions.FETCH_COMPANIES_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
