import { apiCall } from "..";

export function getCountries(iso2 = "", name = "", page = "1", suggested = "") {
	return apiCall.get(`/countries?iso2=${iso2}&suggested=${suggested}&page=${page}&name=${name}`);
}

export function getStates(state_code = "", name = "", country = "", page = "1", suggested) {
	return apiCall.get(
		`/countries/states?state_code=${state_code}&page=${page}&country=${country}&name=${name}&suggested=${suggested}`,
	);
}

export function getCities(full_city_name = "", name = "", country = "", state = "", page = "1") {
	return apiCall.get(
		`/countries/cities?full_city_name=${full_city_name}&page=${page}&country=${country}&state=${state}&name=${name}`,
	);
}

export function getTimezones(timezone = "", page = "", country = "", suggested = "") {
	return apiCall.get(
		`/countries/timezones?timezone=${timezone}&page=${page}&country=${country}&suggested=${suggested}`,
	);
}
