import React from "react";
import "./index.scss";
import { getOriginal } from "../../../../../../utils/imgUri";
import { Box, Text, Image, Divider } from "@chakra-ui/react";
import logoPlaceholder from "../../../../../../assets/images/logoplaceholder.png"; // Tell webpack this JS file uses this image
import MobileGallery from "./MobileGallery";
import { Collapse } from "@chakra-ui/react";
import share from "../../../../../../assets/images/share.svg";
import star from "../../../../../../assets/images/star.svg";
import text from "../../../../../../assets/images/text.svg";
import cross from "../../../../../../assets/images/cross.svg";
import plus from "../../../../../../assets/images/plus.svg";
import location from "../../../../../../assets/images/location.svg";
import back from "../../../../../../assets/images/back.svg";
import dots from "../../../../../../assets/images/dots.svg";

function MobilePrev({
	formik: {
		values: {
			locationTitle,
			title,
			description,
			gallery,
			locationCategory,
			image,
			locationAddress,
			locationPhone,
			locationLogo,
		},
	},
	lang,
	formik,
}) {
	const [show, setShow] = React.useState(true);
	const [showText, setShowText] = React.useState(true);

	const handleToggle = () => setShow(!show);
	const handleToggleText = () => setShowText(!showText);

	return (
		<Box
			backgroundColor="#000"
			display="flex"
			flexDirection="column"
			flex="1"
			height="100%"
			position="relative"
		>
			<Box backgroundColor="#3a7ce0" color="#FFF" display="flex" zIndex="333">
				<Box
					left="5px"
					top="10px"
					position="absolute"
					width="56px"
					height="56px"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Image width="24px" height="24px" src={back} />
				</Box>

				<Box position="relative" top="7px" left="55px">
					<Image
						width="70px"
						height="70px"
						borderRadius="100%"
						border="2px solid rgb(58, 124, 224)"
						background="#FFF"
						src={locationLogo ? getOriginal(locationLogo) : logoPlaceholder}
						alt="company logo"
					/>
				</Box>
				<Box
					position="relative"
					left="60px"
					top="10px"
					fontFamily="Segoe UI SemiLight"
					justifyContent="center"
					display="flex"
					flexDirection="column"
				>
					<Text wordBreak="break-word" fontSize="1.3em">
						{locationTitle || "Titel Mandant"}
					</Text>
					<Text fontSize="0.8em" position="relative" top="-9px" opacity="0.5">
						{locationCategory || "Kategorie"}
					</Text>
				</Box>
				<Box
					right="0px"
					top="10px"
					position="absolute"
					width="56px"
					height="56px"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Image width="24px" height="24px" src={dots} />
				</Box>
			</Box>
			<Box zIndex="0" background="#000" flex="1">
				<MobileGallery gallery={gallery.slice(1, gallery.length)} />
			</Box>
			<Collapse startingHeight={0} in={show}>
				<Box
					width="100%"
					zIndex="999"
					backgroundColor="rgba(0,0,0,0.3)"
					padding="3%"
					color="#FFF"
					position="absolute"
					bottom="60px"
				>
					<Divider />
					<Text fontSize="1.5rem" fontFamily="Segoe UI Light">
						{title || "Titel Mandant"}
					</Text>

					<Collapse startingHeight={170} in={showText}>
						<Text
							fontFamily="Segoe UI Light"
							fontSize="0.875rem"
							wordBreak="break-all"
							height="170px"
							overflowY={showText ? "scroll" : "hidden"}
							className="no-scroll"
						>
							{description || "Beschreibung"}
						</Text>
					</Collapse>

					{description.length > 330 && (
						<Box textAlign="right">
							<Text as="i" onClick={handleToggleText}>
								{showText ? "Less" : "Read more"}
							</Text>
						</Box>
					)}
					<Text
						fontFamily="Segoe UI Bold"
						fontSize="0.875rem"
						lineHeight="1.5"
						fontWeight="400"
					></Text>
					<Text fontFamily="Segoe UI Bold" fontSize="0.75rem" lineHeight="1.66" fontWeight="400">
						{locationAddress || "Clemensstr. 9"} {locationPhone || "+54 874 874 999"}
					</Text>
				</Box>
			</Collapse>
			<Box
				backgroundColor="#000"
				maxHeight="50px"
				padding="20px 10px"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Box display="flex" alignItems="center">
					<Box
						as="button"
						_focus={{ outline: "none", backgroundColor: "transparent" }}
						_hover={{ backgroundColor: "transparent" }}
						_active={{ backgroundColor: "transparent" }}
						onClick={handleToggle}
						backgroundColor="transparent"
						width="24px"
						height="24px"
						margin="0"
						padding="0"
						display="flex"
					>
						{show ? (
							<Image width="24px" height="24px" src={cross} />
						) : (
							<Image width="24px" height="24px" src={plus} />
						)}

						<Image width="24px" height="24px" src={text} />
					</Box>
				</Box>
				<Box display="flex" width="50%" justifyContent="space-between">
					<Image width="24px" height="24px" src={location} />
					<Divider color="#FFF" height="15px" bg="#FFF" alignSelf="center" orientation="vertical" />
					<Image width="24px" height="24px" src={star} />
					<Divider height="15px" alignSelf="center" orientation="vertical" />
					<Box display="flex" alignItems="center">
						<Image width="24px" height="24px" src={share} />

						<Text fontFamily="Segoe UI SemiLight" ml="1em" color="#FFF" fontSize="0.75rem">
							SHARE
						</Text>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default MobilePrev;
