import React from "react";
import { Heading } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import CategorySelect from "../../../shared/inputs/CategorySelect";
import { content } from "./content";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";

export default function Layout({ formik, setUploaded, lang }) {
	const t = content[lang];
	return (
		<Box p={5} top="0" bottom="0" right="0" width="100%" overflow="scroll">
			<Heading pt={["2em", "0"]} mb="1em">
				{t.edit_company} {formik.values.name}
			</Heading>
			<form style={{ display: "flex", flexDirection: "column" }} onSubmit={formik.handleSubmit}>
				<PrimaryInput label={t.name} type="text" id="name" formik={formik} />

				<PrimaryInput label={t.email} type="text" id="email" formik={formik} />
				<PrimaryInput label={t.phone} type="text" id="phone" formik={formik} />

				<PrimaryInput
					label={t.logo}
					type="file"
					id="logo"
					formik={formik}
					setUploaded={setUploaded}
				/>

				<CategorySelect formik={formik} />

				<PrimaryButton mt="0.5em" alignSelf="flex-end" type="submit" label={t.submit} />
			</form>
		</Box>
	);
}
