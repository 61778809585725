import React, { useEffect } from "react";
import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	Select,
	Skeleton,
	Box,
	Tooltip,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyCategories } from "../../../store/actions";

export default function CategorySelect({ formik, column, ...rest }) {
	const dispatch = useDispatch();

	const { categories, fetching: fetchingCategories } = useSelector(
		state => state.fetchCompanyCategories,
	);

	const lang = useSelector(state => state.languageProvider.language);

	useEffect(() => {
		if (Object.keys(categories).length === 0) {
			dispatch(fetchCompanyCategories());
		}
	}, []);

	return (
		<FormControl
			display="grid"
			gridTemplateColumns={
				column ? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "2fr 8fr", "2fr 8fr"]
			}
			mt="0.5em"
			alignItems="center"
			isInvalid={formik.errors.category_id}
			id="category_id"
		>
			<FormLabel
				_focus={{
					color: "#1274BC",
					fontWeight: 700,
				}}
			>
				{lang === "de" ? "Kategorie" : "Category"}
			</FormLabel>
			<Box display="flex" flexDirection="column">
				{fetchingCategories ? (
					<Skeleton height="2em" />
				) : (
					Object.keys(categories).length > 0 && (
						<Tooltip label={formik.errors.country} hasArrow arrowSize={15} bg="red.600">
							<Select
								height="2em"
								borderRadius="0px"
								borderColor="#CBCCCB"
								borderWidth="1px"
								backgroundColor="transparent"
								boxShadow="none"
								_hover={{ backgroundColor: "#F2F1F1" }}
								_focus={{ backgroundColor: "#F2F1F1", borderColor: "#1274BC" }}
								required
								name="category_id"
								width={"100%"}
								id="category_id"
								value={formik.values.category_id}
								onChange={formik.handleChange}
								{...rest}
							>
								{categories.data.map((item, key) => {
									return (
										<option key={key} value={item.id}>
											{item.name}
										</option>
									);
								})}
							</Select>
						</Tooltip>
					)
				)}
				{/* <FormErrorMessage
          position={column ? "absolute" : "relative"}
          bottom="-1.2em"
        >
          {formik.errors.category_id}
        </FormErrorMessage> */}
			</Box>
		</FormControl>
	);
}
