import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "../components/shared/Loading";

import LocationsList from "../components/locations/list";
import Locations from "../components/locations/new";

import Coupons from "../components/coupons/list";
import Coupon from "../components/coupons/new";

import EventsList from "../components/events/list";
import Events from "../components/events/new";

import NewsList from "../components/news/list";
import News from "../components/news/new";

import Reset from "../components/auth/reset";
import ProfileEdit from "../components/profile/edit";
import FacebookList from "../components/profile/facebook";

import CompanyEdit from "../components/company/edit";
import CompanyHome from "../components/company/home";

import ProfileHome from "../components/profile/home";

import NewCompany from "../components/new-company";
import Verify from "../components/auth/verify-email";
import LocationEmailVerification from "../components/auth/verify-location-email";
import Logs from "../components/logs/list";
import Statistics from "../components/statistics";
import Dashboard from "../components/dashboard";
import Facebook from "../components/auth/facebook";

import Notifications from "../components/notifications";

import PushNotificationsList from "../components/pushNotifications/list";
import PushNotifications from "../components/pushNotifications/new";

import Chat from "../components/chats";
import Checkout from "../components/payment";
import Confirm from "../components/confirm";
import PaypalSuccess from "../components/paypal/success";
import PaypalCancel from "../components/paypal/cancel";
import Subscription from "../components/Subscription";
import Wizard from "../components/auth/register/components/Wizard";
import Success from "../components/payment/success";
function PrivateRouter() {
	const { company, fetched } = useSelector(state => state.fetchCompany);
	if (!fetched) return <Loading />;
	return (
		<Switch>
			<Route exact path="/create-company" component={NewCompany} />

			<Route exact path="/profile" component={ProfileHome} />

			<Route exact path="/profile/edit" component={ProfileEdit} />
			<Route exact path="/profile/facebook" component={FacebookList} />

			<Route exact path="/company" component={CompanyHome} />
			<Route path="/company/edit/:id" component={CompanyEdit} />

			<Route path="/events/edit/:id" component={Events} />

			<Route path="/events/new" component={Events} />

			<Route path="/events" component={EventsList} />

			<Route path="/news/edit/:id" component={News} />

			<Route path="/news/new" component={News} />

			<Route path="/news" component={NewsList} />

			<Route path="/locations/edit/:id" component={Locations} />

			<Route path="/locations/new" component={Locations} />

			<Route path="/locations" component={LocationsList} />

			<Route path="/coupons/edit/:id" component={Coupon} />

			<Route path="/coupons/new" component={Coupon} />

			<Route path="/coupons" component={Coupons} />

			<Route path="/logs" component={Logs} />

			<Route path="/inAppNotifications" component={Notifications} />

			<Route path="/notifications/new" component={PushNotifications} />

			<Route path="/notifications" component={PushNotificationsList} />
			<Route path="/chats/:id" component={Chat} />

			<Route path="/chats" component={Chat} />

			<Route path="/email/verify" component={Verify} />

			<Route path="/locationEmail/verify" component={LocationEmailVerification} />

			<Route path="/password/reset" component={Reset} />
			<Route path="/v1/dashboard/login/facebook/callback" component={Facebook} />
			<Route path="/subscription" component={Subscription} />

			<Route path="/statistics" component={Statistics} />
			<Route path="/dashboard" component={Dashboard} />
			<Route path="/checkout" component={Checkout} />
			<Route path="/confirm" component={Confirm} />
			<Route path="/successpayment" component={Success} />
			{/* <Route path="/register" component={Wizard} /> */}
			<Route path="/payment/company/paypal/subscribe/redirect/success" component={PaypalSuccess} />
			<Route path="/payment/company/paypal/subscribe/redirect/cancel" component={PaypalCancel} />

			<Redirect to={company.locations.length === 0 ? "/locations/new" : "/dashboard"} />
		</Switch>
	);
}

export default PrivateRouter;
