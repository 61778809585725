import React from "react";
import { Box } from "@chakra-ui/react";

function layout(props) {
	return (
		<Box w={"full"} paddingBottom="20px" height={"100%"}>
			{props.children}
		</Box>
	);
}

export default layout;
