import { chatsActions } from "../../actionTypes";

export const addMessage = data => {
	return async dispatch => {
		dispatch({
			type: chatsActions.ADD_MESSAGE,
			payload: data,
		});
	};
};
