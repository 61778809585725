import { Box } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import TabButton from "./TabButton";
import { content } from "./lang";
import { useSelector } from "react-redux";
import useWindowSize from "../../../utils/useWindowSize";
import Select from "react-select";
import { useLocation } from "react-router-dom";
export default function TabHeaderLight({ options, onChange }) {
	const lang = useSelector(state => state.languageProvider.language);
	const location = useLocation();
	const tab = new URLSearchParams(location.search).get("tab");
	const { width } = useWindowSize();
	const [active, setActive] = useState(0);
	const t = content[lang];
	const colourStyles = {
		option: (styles, { data, isDisabled, isFocused }) => {
			return {
				...styles,
				backgroundColor: isFocused ? "#3F629D" : "",
				color: isDisabled ? "gray" : isFocused ? "#FFF" : "black",
				cursor: isDisabled ? "not-allowed" : "pointer",
				textAlign: "center",
			};
		},
		container: styles => ({ ...styles, width: "100%" }),
	};
	const optionsMobile = Object.keys(options).map((key, index) => {
		return {
			value: index,
			label: options[key],
		};
	});
	const optionsDesktop = Object.keys(options).map((key, index) => {
		return {
			value: index,
			label: options[key],
		};
	});

	useEffect(() => {
		if (tab === "subscribers") {
			setActive(3);
		}
	}, []);
	// get the options from the parent component
	// and map them to the optionsMobile array

	return (
		<>
			<Box display={["flex", "none"]} borderColor="red" width="100%">
				<Select
					isSearchable={false}
					value={optionsMobile.find(item => item.value === active)}
					options={optionsMobile}
					onChange={e => {
						setActive(e.value);
						onChange(e.label);
					}}
					styles={colourStyles}
					theme={theme => ({
						...theme,
						borderRadius: 0,
						width: "100%",
						textAlign: "center",
					})}
				/>
			</Box>
			<Box
				display={["none", "flex"]}
				borderBottom="2px solid #000"
				position="relative"
				flexDirection={width <= 768 ? "column" : "row"}
			>
				{/* {optionsDesktop.map((key, index) => (
          <TabButton
            gray={true}
            active={active}
            key={index}
            index={index}
            onClick={() => {
              setActive(index);
              onChange(key);
            }}
            label={t[key].toUpperCase()}
          ></TabButton>
        ))} */}
				{optionsDesktop.map((item, index) => (
					<TabButton
						key={index}
						label={item.label}
						index={index}
						active={active}
						onClick={() => {
							setActive(index);
							onChange(item.label);
						}}
					/>
				))}
			</Box>
		</>
	);
}
