import React from "react";
import { Box, Text } from "@chakra-ui/react";
import TimeInput from "../../../../../shared/inputs/TimeInput";
import PrimaryButton from "../../../../../shared/buttons/PrimaryButton";
import PrimaryInput from "../../../../../shared/inputs/PrimaryInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

export default function Layout({
	formikHours,
	days,
	maxTimes,
	onOpenInputChange,
	onCloseInputChange,
	actionClicked,
	id,
	dispatch,
	handleCopy,
	maxCloseAt,
}) {
	const { id: editId } = useParams();
	return (
		<Box display="grid" gridGap="0.2em" height="100%">
			<Accordion allowToggle defaultIndex={formikHours.send_hours ? [0] : [1]}>
				<AccordionItem>
					{({ isExpanded }) => {
						return (
							<>
								<h2>
									<AccordionButton
										px="4"
										py="0"
										height="2em"
										borderRadius="0px"
										borderColor="#CBCCCB"
										borderWidth="1px"
										backgroundColor="transparent"
										boxShadow="none"
										_hover={{ backgroundColor: "#F2F1F1" }}
										_focus={{
											backgroundColor: "#F2F1F1",
											borderColor: "#1274BC",
										}}
										color={"#000"}
										_placeholder={{ color: "rgba(0,0,0,0.5)" }}
										onClick={() => {
											if (isExpanded && formikHours.send_hours) {
												formikHours.setFieldValue("send_hours", false);
											}
										}}
									>
										<Box flex="1" textAlign="center">
											CLOSED
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4}>
									<Box display="flex" flexDirection="column">
										<PrimaryInput
											column={true}
											label="Closed until"
											type="datetime-local"
											id="closed_until"
											formik={formikHours}
										/>
										<PrimaryInput
											column={true}
											label="Closed message"
											type="text"
											id="closed_message"
											formik={formikHours}
										/>
									</Box>
								</AccordionPanel>
							</>
						);
					}}
				</AccordionItem>

				<AccordionItem>
					{({ isExpanded }) => {
						return (
							<>
								<h2>
									<AccordionButton
										px="4"
										py="0"
										height="2em"
										borderRadius="0px"
										borderColor="#CBCCCB"
										borderWidth="1px"
										backgroundColor="transparent"
										boxShadow="none"
										_hover={{ backgroundColor: "#F2F1F1" }}
										_focus={{
											backgroundColor: "#F2F1F1",
											borderColor: "#1274BC",
										}}
										color={"#000"}
										_placeholder={{ color: "rgba(0,0,0,0.5)" }}
										onClick={() => {
											if (isExpanded && !formikHours.send_hours) {
												formikHours.setFieldValue("send_hours", true);
											}
										}}
									>
										<Box flex="1" textAlign="center">
											OPEN
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4}>
									{formikHours.values.open_hours.map((day, dayIndex) => (
										<Box key={dayIndex} display="grid">
											{day.hours.map((time, timeIndex) => {
												console.log(
													timeIndex,
													timeIndex !== 0 &&
														formikHours.values.open_hours[dayIndex].hours[timeIndex - 1],
												);
												return (
													<Box
														key={timeIndex}
														display="grid"
														my="0.2em"
														gridTemplateColumns={["1fr", "4fr 10fr 10fr 3fr"]}
														gridGap="1em"
														alignItems="center"
													>
														{timeIndex === 0 ? <Text>{days[day.day_id - 1]}</Text> : <Text></Text>}
														<TimeInput
															disabled={timeIndex !== day.hours.length - 1}
															min={
																timeIndex !== 0
																	? formikHours.values.open_hours[dayIndex].hours[timeIndex - 1]
																			.close_at
																	: null
															}
															dayIndex={dayIndex}
															iconDisabled={timeIndex !== day.hours.length - 1}
															timeIndex={timeIndex}
															label={"Opening time"}
															onChange={onOpenInputChange}
															value={time.open_at}
														/>
														<TimeInput
															disabled={
																time.open_at && timeIndex === day.hours.length - 1 ? false : true
															}
															iconDisabled={timeIndex !== day.hours.length - 1 || !time.open_at}
															min={time.open_at}
															dayIndex={dayIndex}
															timeIndex={timeIndex}
															label={time.open_at ? "Closing time" : "Select opening time"}
															value={time.close_at}
															onChange={onCloseInputChange}
														/>
														<Box
															display="grid"
															gridTemplateColumns="1fr 1fr 1fr"
															justifyItems="center"
														>
															{timeIndex === day.hours.length - 1 ? (
																<PrimaryButton
																	alignSelf="center"
																	type="circle"
																	disabled={time.close_at ? false : true}
																	label={<FontAwesomeIcon icon="plus"></FontAwesomeIcon>}
																	onClick={() => {
																		actionClicked(day, "add");
																	}}
																></PrimaryButton>
															) : (
																<Box height={"3em"} width={"3em"}></Box>
															)}

															{timeIndex === day.hours.length - 1 ? (
																<PrimaryButton
																	type="circle"
																	disabled={
																		time.close_at
																			? dayIndex < 6 &&
																			  formikHours.values.open_hours[dayIndex + 1].hours[0]
																					.open_at &&
																			  editId
																				? true
																				: dayIndex === 6
																				? true
																				: false
																			: true
																	}
																	label={<FontAwesomeIcon icon="copy"></FontAwesomeIcon>}
																	onClick={() => {
																		handleCopy(day.hours, dayIndex);
																	}}
																></PrimaryButton>
															) : (
																<Box height={"3em"} width={"3em"}></Box>
															)}

															<PrimaryButton
																type="circle"
																prompt={true}
																onClick={() => {
																	actionClicked(day, "subtract", timeIndex);
																}}
																label={<FontAwesomeIcon icon="times"></FontAwesomeIcon>}
															></PrimaryButton>
														</Box>
													</Box>
												);
											})}
										</Box>
									))}
								</AccordionPanel>
							</>
						);
					}}
				</AccordionItem>
			</Accordion>
		</Box>
	);
}
