import { eventActions } from "../../actionTypes";
import { addEvent } from "../../../api/libs/events";
import toast from "react-hot-toast";
import newGallery from "../../../utils/gallery";
import { createGallery } from "../../../api/libs/gallery";

export const createEvent = (data, gallery, setErrors, history, handleKeywordsSubmit) => {
	return async dispatch => {
		dispatch({
			type: eventActions.CREATE_EVENT,
		});
		toast.promise(addEvent(data), {
			loading: "Creating event...",
			success: res => {
				history.push("/events");
				handleKeywordsSubmit(res.data.id, setErrors);
				newGallery(gallery)
					.then(galleryArr => {
						dispatch({
							type: eventActions.CREATE_EVENT_FULFILLED,
							payload: res.data,
						});
						createGallery(res.data.gallery_id, galleryArr, data.instagram_page_share)
							.then(r => console.log(r))
							.catch(e => console.log(e));
					})
					.catch(err => {
						console.log(err);
					});
				// dispatch({
				//   type: locationActions.CREATE_LOCATION_FULFILLED,
				//   payload: res.data,
				// });
				// handleHourSubmit(res.data.id, setErrors);

				return <b>Successfully created event</b>;
			},
			error: err => {
				setErrors(err.response.data.errors);
				Object.keys(err.response.data.errors).map(key =>
					toast.error(err.response.data.errors[key]),
				);
				dispatch({
					type: eventActions.CREATE_EVENT_REJECTED,
					payload: err.response.data.errors,
				});
				return <b>Couldn't create event</b>;
			},
		});
	};
};
