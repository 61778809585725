import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { createCoupon, fetchCoupon, updateCoupon } from "../../../store/actions";
import Loading from "../../shared/Loading";
import { content } from "./components/content";
import Layout from "./components/Layout";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";

function Coupon() {
	let { id } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];

	const { coupon, fetched, fetching } = useSelector(state => state.fetchCoupon);

	useEffect(() => {
		if (id) dispatch(fetchCoupon(id));
	}, [id]);

	useEffect(() => {
		formik.setValues(initialValues);
		if (fetched && id) {
			const post_end_at = coupon.post_end_at
				? coupon.post_end_at.replace(" ", "T").slice(0, -3)
				: null;

			formik.setValues({
				...coupon,
				post_end_at,

				description: { de: "" },
			});
		}
	}, [fetched]);

	function handleSubmit(values, { setErrors }) {
		let data = {
			...values,
			post_end_at: values.post_end_at
				? new Date(values.post_end_at).toISOString().replace(".000Z", "").replace("T", " ")
				: null,
			news: values.news.id.length ? { id: values.news.id } : [],
		};

		if (id) {
			dispatch(updateCoupon(id, data, setErrors, history));
		} else {
			dispatch(createCoupon(data, setErrors, history));
		}
	}
	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false,
	});
	useEffect(() => {
		formik.setFieldValue("user.id", []);
		formik.setFieldValue("news.id", []);
	}, [formik.values.visible_for]);

	if (fetching) return <Loading />;
	return <Layout header={t.header} lang={lang} id={id} formik={formik} />;
}

export default Coupon;
