import React from "react";
import { Box, Text } from "@chakra-ui/react";

function Header({ onCloseEditorWindow, canSave, onSaveSlide }) {
	return (
		<Box
			w="100%"
			paddingRight={5}
			paddingLeft={5}
			h={35}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
		>
			<Text onClick={onCloseEditorWindow}>Close</Text>
			<Text
				onClick={canSave ? onSaveSlide : () => {}}
				color={canSave ? "#0564B1" : "#000"}
				fontWeight={800}
			>
				Save
			</Text>
		</Box>
	);
}

export default Header;
