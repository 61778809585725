import { pushNotificationActions } from "../../actionTypes";
import { getPushNotifications } from "../../../api/libs/pushNotifications";

export const fetchPushNotifications = (page, reset) => {
	return async dispatch => {
		dispatch({
			type: pushNotificationActions.FETCH_PUSH_NOTIFICATIONS,
			payload: { reset },
		});
		getPushNotifications(page)
			.then(res => {
				dispatch({
					type: pushNotificationActions.FETCH_PUSH_NOTIFICATIONS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: pushNotificationActions.FETCH_PUSH_NOTIFICATIONS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
