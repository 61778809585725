import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import Slider from "./Slider";

function Sliders({ canvas, txtCol }) {
	const [opacity, setOpacity] = useState(0);
	const [shadow, setShadow] = useState(0);
	const [stroke, setStroke] = useState(0);

	useEffect(() => {
		if (canvas.getActiveObject()) {
			if (canvas.getActiveObject().type === "textbox") {
				if (canvas.getActiveObject().opacity < 1) {
					setOpacity(Math.ceil((1 - canvas.getActiveObject().opacity) * 100));
				}
				if (canvas.getActiveObject().shadow) {
					setShadow(canvas.getActiveObject().shadow.blur);
				}
				if (canvas.getActiveObject().stroke) {
					setStroke(canvas.getActiveObject().stroke.strokeWidth * 100);
				}
			}
		}
	}, []);

	useEffect(() => {
		if (canvas.getActiveObject()) {
			if (canvas.getActiveObject().type === "textbox") {
				if (opacity > 0) {
					canvas.getActiveObject().set("opacity", (1 - opacity / 100).toFixed(2));
					canvas.renderAll();
				} else {
					canvas.getActiveObject().set("opacity", 1);
					canvas.renderAll();
				}
			}
		}
	}, [opacity]);

	useEffect(() => {
		if (canvas.getActiveObject()) {
			if (canvas.getActiveObject().type === "textbox") {
				if (shadow > 0) {
					canvas.getActiveObject().set("shadow", { color: "black", blur: shadow });
					canvas.renderAll();
				} else {
					canvas.getActiveObject().set("shadow", null);
					canvas.renderAll();
				}
			}
		}
	}, [shadow]);

	useEffect(() => {
		if (canvas.getActiveObject()) {
			if (canvas.getActiveObject().type === "textbox") {
				if (stroke > 0) {
					canvas.getActiveObject().set("stroke", { strokeWidth: stroke / 100, visible: true });
					canvas.renderAll();
				} else {
					canvas.getActiveObject().set("stroke", null);
					canvas.renderAll();
				}
			}
		}
	}, [stroke]);

	function hexToRgb(hex) {
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}
	return (
		<Box mt="5" width="80%" ml="auto" mr="auto">
			<Slider
				canvas={canvas}
				type="opacity"
				title="Font: color / transparency"
				val={opacity}
				setVal={setOpacity}
				color={hexToRgb(txtCol)}
				isDisabled={canvas.getActiveObject()?.type !== "textbox"}
				op={(1 - opacity / 100).toFixed(2)}
			/>
			<Slider
				canvas={canvas}
				type="shadow"
				title="Font: shadow"
				val={shadow}
				setVal={setShadow}
				max={20}
				isDisabled={canvas.getActiveObject()?.type !== "textbox"}
			/>
			<Slider
				canvas={canvas}
				type="stroke"
				title="Font: Stroke"
				val={stroke}
				setVal={setStroke}
				isDisabled={canvas.getActiveObject()?.type !== "textbox"}
				max={1}
			/>
		</Box>
	);
}

export default Sliders;
