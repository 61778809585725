import { usersActions } from "../../actionTypes";
import { getUsers } from "../../../api/libs/users";

export const fetchUsers = () => {
	return async dispatch => {
		dispatch({
			type: usersActions.FETCH_USERS,
		});
		getUsers()
			.then(res => {
				dispatch({
					type: usersActions.FETCH_USERS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: usersActions.FETCH_USERS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
