import React from "react";
import { Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { onDisplayModal, onCloseModal } from "../../utils/modal";
import TextInfo from "../TextInfo/TextInfo";

function SideActions({ state, setState }) {
	return (
		<>
			<Box display="flex" mt="20px">
				<Box
					mr="10px"
					display="flex"
					justifyContent="center"
					alignItems="center"
					bg={state.modal.type === "fonts" ? "#0066AC" : "black"}
					w="35px"
					h="35px"
					borderRadius="full"
					color="white"
					fontSize="30"
					cursor="pointer"
					_active={{ bg: "#0066AC" }}
					onClick={() => {
						state.activePanel === "INIT"
							? onDisplayModal("fonts", setState)
							: onCloseModal(setState);
					}}
				>
					<FontAwesomeIcon icon="font" color="white" style={{ fontSize: "19px" }} />
				</Box>
				<Box
					mr="10px"
					display="flex"
					justifyContent="center"
					alignItems="center"
					bg={state.modal.type === "layers" ? "#0066AC" : "black"}
					w="35px"
					h="35px"
					borderRadius="full"
					color="white"
					fontSize="30"
					cursor="pointer"
					_active={{ bg: "#0066AC" }}
					onClick={() => {
						state.modal.type === "layers"
							? onCloseModal(setState)
							: onDisplayModal("layers", setState);
					}}
					userSelect="none"
				>
					<FontAwesomeIcon icon="layer-group" color="white" style={{ fontSize: "19px" }} />
				</Box>
				<Box
					mr="10px"
					display="flex"
					justifyContent="center"
					alignItems="center"
					bg={state.modal.type === "stickers" ? "#0066AC" : "black"}
					w="35px"
					h="35px"
					borderRadius="full"
					color="white"
					fontSize="30"
					cursor="pointer"
					_active={{ bg: "#0066AC" }}
					onClick={() => {
						state.modal.type === "stickers"
							? onCloseModal(setState)
							: onDisplayModal("stickers", setState);
					}}
				>
					<FontAwesomeIcon icon="sticky-note" color="white" style={{ fontSize: "19px" }} />
				</Box>
				<Box
					mr="10px"
					display="flex"
					justifyContent="center"
					alignItems="center"
					bg={state.modal.type === "filters" ? "#0066AC" : "black"}
					w="35px"
					h="35px"
					borderRadius="full"
					color="white"
					fontSize="30"
					cursor="pointer"
					_active={{ bg: "#0066AC" }}
					onClick={() => {
						state.modal.type === "filters"
							? onCloseModal(setState)
							: onDisplayModal("filters", setState);
					}}
					userSelect="none"
				>
					<FontAwesomeIcon icon="filter" color="white" style={{ fontSize: "19px" }} />
				</Box>

				<Box
					mr="10px"
					display="flex"
					justifyContent="center"
					alignItems="center"
					bg={state.modal.type === "templates" ? "#0066AC" : "black"}
					w="35px"
					h="35px"
					borderRadius="full"
					color="white"
					fontSize="30"
					cursor="pointer"
					_active={{ bg: "#0066AC" }}
					onClick={() => {
						state.modal.type === "templates"
							? onCloseModal(setState)
							: onDisplayModal("templates", setState);
					}}
					userSelect="none"
				>
					<FontAwesomeIcon icon="th" color="white" style={{ fontSize: "19px" }} />
				</Box>
			</Box>
			<TextInfo txt="Auswahl / Galerie" />
		</>
	);
}

export default SideActions;
