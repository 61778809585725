import { newsActions } from "../../actionTypes";
import { getNewses } from "../../../api/libs/news";

export const fetchNewses = (type, page, reset, couponId) => {
	return async dispatch => {
		dispatch({
			type: newsActions.FETCH_NEWSES,
			payload: { reset },
		});
		getNewses(type, page, couponId)
			.then(res => {
				dispatch({
					type: newsActions.FETCH_NEWSES_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: newsActions.FETCH_NEWSES_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
