import { Slider, SliderTrack, SliderFilledTrack, SliderThumb } from "@chakra-ui/react";

export default function OpacitySlider({ activeObject, opacityVal, setOpacityVal }) {
	return (
		<Slider
			isDisabled={activeObject === null ? true : false}
			aria-label="opacity"
			defaultValue={opacityVal * 100}
			onChange={val => setOpacityVal(val / 100)}
		>
			<SliderTrack>
				<SliderFilledTrack bg="#EEEEEE" />
			</SliderTrack>
			<SliderThumb bg="#73B13F" _active={{ outline: "none" }} _focus={{ outline: "none" }} />
		</Slider>
	);
}
