import React, { useEffect } from "react";
import { Box } from "@chakra-ui/layout";

import CompanyItem from "./CompanyItem";
import Loading from "../../shared/Loading";

function Content({ companies, handleSelect, fetched, setPage }) {
	useEffect(() => {
		console.log("Companies", companies);
		if (companies.length > 1) {
			fetched = true;
		}
	}, [companies]);
	return (
		<Box
			borderWidth="1px"
			borderColor="black"
			borderTop="none"
			p="16px"
			pt="34px"
			pb="34px"
			borderBottomEndRadius="5px"
			borderBottomStartRadius="5px"
		>
			{companies.map((item, key) => (
				<CompanyItem key={key} item={item} setPage={setPage} handleSelect={handleSelect} />
			))}
		</Box>
	);
}

export default Content;
