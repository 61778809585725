import toast from "react-hot-toast";
import { stopSubscription } from "../../../api/libs/stripe";
import { stripeActions } from "../../actionTypes";
import { fetchStripeSubscriptions } from "./fetchStripeSubscriptions";

export const cancelStripeSubscription = data => {
	return async dispatch => {
		dispatch({
			type: stripeActions.CANCEL_STRIPE_SUBSCRIPTION,
		});
		toast.promise(stopSubscription(data), {
			loading: "Pausing subscription...",
			success: res => {
				dispatch({
					type: stripeActions.CANCEL_STRIPE_SUBSCRIPTION_FULFILLED,
					payload: res.data,
				});
				dispatch(fetchStripeSubscriptions());
				return <b>Paused subscription</b>;
			},
			error: err => {
				dispatch({
					type: stripeActions.CANCEL_STRIPE_SUBSCRIPTION_REJECTED,
					payload: err.response,
				});
				return <b>Can't pause subscription</b>;
			},
		});
	};
};
