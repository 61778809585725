import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { getDescendantProp } from "../../../utils/common";
export default function PrimaryInput({
	label,
	type,
	id,
	column,
	formik,
	setUploaded,
	labelBackground,
	labelAlign,
	...rest
}) {
	const handleKeyDown = e => {
		e.target.style.height = "inherit";
		e.target.style.height = `${e.target.scrollHeight}px`;
		// In case you have a limitation
		// e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
	};
	const resolvePath = (object, path, defaultValue) =>
		path
			.split(/[.[\]'"]/)
			.filter(p => p)
			.reduce((o, p) => (o ? o[p] : defaultValue), object);

	return (
		<FormControl
			display="grid"
			gridTemplateColumns={column ? "1fr" : "2fr 8fr"}
			mt="0.5em"
			alignItems="center"
			isInvalid={getDescendantProp(formik.errors, id) && getDescendantProp(formik.touched, id)}
			id={id}
			position="relative"
		>
			<FormLabel
				fontFamily="Segoe UI Semilight"
				_focus={{
					color: labelBackground ? "#FFF" : "#1274BC",
					fontWeight: 700,
				}}
				height={labelBackground && "2em"}
				m={labelBackground && "0"}
				display={labelBackground && "flex"}
				width={labelBackground && "100%"}
				alignItems={labelBackground && "center"}
				justifyContent={labelBackground && "center"}
				backgroundColor={labelBackground}
				textAlign={labelAlign}
				position="relative"
			>
				{label}
			</FormLabel>
			<Box display="flex" flexDirection="column" position="relative">
				<Textarea
					onKeyDown={handleKeyDown}
					resize="none"
					borderRadius="0px"
					borderColor="#CBCCCB"
					borderWidth="1px"
					backgroundColor="transparent"
					boxShadow="none"
					_hover={{ backgroundColor: "#F2F1F1" }}
					_focus={{ backgroundColor: "#F2F1F1", borderColor: "#1274BC" }}
					placeholder={label}
					onChange={formik.handleChange}
					minHeight="10em"
					overflowY="scroll"
					maxHeight="20em"
					value={id.includes(".") ? resolvePath(formik.values, id) : formik.values[id]}
					{...rest}
				/>

				<FormErrorMessage position={column ? "absolute" : "relative"} bottom="-1.2em">
					{getDescendantProp(formik.errors, id)}
				</FormErrorMessage>
			</Box>
		</FormControl>
	);
}
