import { useEffect, useState } from "react";

import { checkVideoStatus } from "../../../../../api/libs/video";
import { useSlides } from "../../store";

import Layout from "./components/Layout";
import Player from "./components/Player";
import EncodingVideo from "./components/EncodingVideo";

function VideoPlayer({ state, setState }) {
	const slides = useSlides(state => state.gallery);
	const slide = slides[state.activeSlide];
	const [videoEncoding, setVideoEncoding] = useState({
		video: null,
		status: "finished",
		progress: 100,
		nextRequest: 0,
	});

	let url = typeof slide.video === "string" ? slide.video : URL.createObjectURL(slide.video);

	useEffect(() => {
		if (typeof slide.video === "string") {
			let id = slide.video.slice(slide.video.lastIndexOf("/") + 1, slide.video.lastIndexOf("."));
			checkVideoStatus(id)
				.then(res => {
					setVideoEncoding({
						video: id,
						status: res.data.encoding,
						progress: res.data.progress,
						nextRequest:
							res.data.encoding === "started" || res.data.encoding === "progressing"
								? videoEncoding.nextRequest + 1
								: 0,
					});
				})
				.catch(err => console.log(err));
		}
	}, []);

	useEffect(() => {
		if (videoEncoding.nextRequest > 0) {
			setTimeout(() => {
				checkVideoStatus(videoEncoding.video)
					.then(res => {
						setVideoEncoding({
							video: videoEncoding.video,
							status: res.data.encoding,
							progress: res.data.progress,
							nextRequest:
								res.data.encoding === "started" || res.data.encoding === "progressing"
									? videoEncoding.nextRequest + 1
									: 0,
						});
						if (res.data.encoding === "finished") {
							// triggerPrev(true);
							//  setState((state) => {
							//    state.preview = true;
							//  });
						}
					})
					.catch(err => console.log(err));
			}, 1000);
		}
	}, [videoEncoding.nextRequest]);

	return (
		<Layout>
			{videoEncoding.status === "finished" ? (
				<Player media={url} />
			) : (
				<EncodingVideo videoEncoding={videoEncoding} />
			)}
		</Layout>
	);
}

export default VideoPlayer;
