import React from "react";
import { Box, Text, Heading } from "@chakra-ui/react";

function Note({ title, content }) {
	return (
		<Box
			bg="white"
			mt={5}
			width={"100%"}
			mr="auto"
			ml="auto"
			display="flex"
			alignItems="center"
			flexDirection="column"
			py="20px"
		>
			<Heading fontSize={"18"} fontWeight="600">
				{title}
			</Heading>
			<Text textAlign="center">{content}</Text>
		</Box>
	);
}

export default Note;
