import React from "react";
import { Box, Text, Button, Stack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio, RadioGroup } from "@chakra-ui/react";
import Modal from "../../../../shared/Modal";
import { differenceInDays } from "date-fns";
import Calendar from "./Calendar";

export default function CalendarModal({
	modalVisible,
	setModalVisible,
	t,
	label,
	selectedType,
	data,
	start,
	formik,
	handleRecurrenceChange,
	end,
	dateSelected,
	dayColor,
	handleDisabled,
	handleReset,
	onOpen,
}) {
	const eventLength =
		formik.values[start] && formik.values[end]
			? differenceInDays(formik.values.event_end_at, formik.values.event_start_at)
			: 0;

	return modalVisible ? (
		<Modal
			header="Veröffentlichung"
			handleClose={() =>
				formik.values.recurrence === "range" && Object.keys(formik.values.availability).length === 1
					? null
					: setModalVisible(false)
			}
		>
			<Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
				<Box
					flexGrow="1"
					borderBottom="2px solid #7C7C7C"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<RadioGroup onChange={handleRecurrenceChange} value={formik.values.recurrence}>
						<Stack direction="row">
							{!eventLength && (
								<Radio colorScheme="green" value="range">
									{t.range}
								</Radio>
							)}
							{!eventLength && (
								<Radio colorScheme="green" value="daily">
									{t.daily}
								</Radio>
							)}
							{eventLength <= 7 && (
								<Radio colorScheme="green" value="weekly">
									{t.weekly}
								</Radio>
							)}
							{eventLength + (formik.values[start] ? formik.values[start].getUTCDate() : 0) <=
								27 && (
								<Radio colorScheme="green" value="monthly">
									{t.monthly}
								</Radio>
							)}
						</Stack>
					</RadioGroup>
				</Box>
				<Box>
					<Text textAlign="center" py="1em">
						{t.selecting} {label} <u>{`${selectedType === 1 ? t.start_date : t.end_date}`}</u>
					</Text>
					{formik.values.recurrence === "range" &&
						Object.keys(formik.values.availability).length === 1 && (
							<Text color="red" textAlign="center">
								Select end date for ranges
							</Text>
						)}

					<Box
						fontFamily="Segoe UI SemiBold"
						display="grid"
						gridTemplateColumns="1fr 1fr"
						gridGap="1em"
						px="3em"
						pb="1em"
						height="70vh"
						overflowY="scroll"
					>
						<Calendar
							onOpen={onOpen}
							dayColor={dayColor}
							handleDisabled={handleDisabled}
							dateSelected={dateSelected}
							data={data}
							start={start}
							selectedType={selectedType}
							formik={formik}
						/>
					</Box>
				</Box>

				<Box
					py="0.5em"
					flexGrow="1"
					borderTop="2px solid #7C7C7C"
					display="grid"
					gridTemplateColumns="1fr 1fr"
					gridGap="1em"
				>
					<Box display="flex" justifyContent="flex-end" alignItems="center">
						<Text mr="1em">{t.confirm}</Text>
						<Button
							disabled={
								formik.values.recurrence === "range" &&
								Object.keys(formik.values.availability).length === 1
							}
							width="3em"
							height="3em"
							borderRadius="100%"
							justifySelf="flex-end"
							_hover={{ bg: "#9BC962" }}
							_focus={{ bg: "#B0D352" }}
							_active={{ bg: "#B0D352" }}
							bg="#D7D7D6"
							onClick={() => {
								if (
									!(
										formik.values.recurrence === "range" &&
										Object.keys(formik.values.availability).length === 1
									)
								)
									setModalVisible(false);
							}}
						>
							<FontAwesomeIcon color="#FFF" size="lg" icon="check" />
						</Button>
					</Box>
					<Box display="flex" alignItems="center">
						<Button
							onClick={handleReset}
							_hover={{ bg: "#AAAAAD" }}
							_focus={{ bg: "#6C6C6E" }}
							_active={{ bg: "#6C6C6E" }}
							width="3em"
							borderRadius="100%"
							bg="#D7D7D6"
							height="3em"
						>
							<FontAwesomeIcon color="#FFF" size="lg" icon="undo" />
						</Button>
						<Text ml="1em">{t.reset}</Text>
					</Box>
				</Box>
			</Box>
		</Modal>
	) : null;
}
