export const content = {
	en: {
		header: "Locations",
		client: "Create Location",
		desc: "Description",
		ctitle: "Title",
		service: "Trade / Service",
		performance: "Performance range",
		plz: "Post code",
		city: "City",
		street: "Street",
		number: "House number",
		tel: "Phone",
		fax: "Fax",
		mail: "E-Mail",
		web: "Web site",
		opens: "Opening time",
		country: "Country",
		address: "Address",
		confirm: "Next",
		reset: "Reset",
		sub_title: "Sub title",
		description_title: "Description title",
		official_name: "Official name",
		street_number: "Street number",
		post_code: "Postal code",
		phone_number: "Phone number",
		email: "Email",
		web_page: "Web Page",
		create_location: "Create location",
		edit_location: "Edit location",
		back: "Back",
		preview: "Preview",
		publish: "Publish",
		closed: "Closed",
		open_times: "Open times",
		open: "Open",
		see_more: "See more",
		see_less: "See less",
	},
	de: {
		header: "Location erstellen",
		client: "Mandat",
		desc: "Beschreibung",
		ctitle: "Location Name",
		service: "Gewerbe / Dienstleistung",
		performance: "Leistungsbereich",
		plz: "PLZ",
		city: "Stadt",
		street: "Straße",
		number: "Hausnummer",
		tel: "Telefon",
		fax: "Fax",
		mail: "E-Mail",
		web: "Web Page",
		opens: "Öffnungszeit",
		country: "Land",
		address: "Anschrift",
		confirm: "Nächste",
		reset: "Zurücksetzten",
		sub_title: "Location Unterzeile",
		description_title: "Überschrift",
		official_name: "Offizieller Name",
		street_number: "Hausnummer",
		post_code: "PLZ",
		phone_number: "Telefon",
		email: "E-Mail",
		web_page: "Web Page",
		create_location: "Location erstellen",
		edit_location: "Location ändern",
		back: "Zurück",
		preview: "Vorschau",
		publish: "Veröffentlichen",
		closed: "Geschlossen",
		open_times: "Öffnungszeiten",
		open: "Offen",
		see_more: "Mehr sehen",
		see_less: "Weniger anzeigen",
	},
};
