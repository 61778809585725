import React from "react";
import { Box } from "@chakra-ui/react";

function Container(props) {
	return (
		<Box w="100%" display="flex" h="50px" borderTop="0.5px solid black">
			{props.children}
		</Box>
	);
}

export default Container;
