import { eventActions } from "../../actionTypes";
import { getEvent } from "../../../api/libs/events";

export const fetchEvent = id => {
	return async dispatch => {
		dispatch({
			type: eventActions.FETCH_EVENT,
		});
		getEvent(id)
			.then(res => {
				dispatch({
					type: eventActions.FETCH_EVENT_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: eventActions.FETCH_EVENT_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
