import React from "react";
import { Box, Text, Progress } from "@chakra-ui/react";

function EncodingVideo({ videoEncoding }) {
	return (
		<Box
			h={540}
			w={540}
			bg="rgba(0,0,0,0.9)"
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDir="column"
		>
			<Text color="white" mb={5}>
				Video encoding is in progress
			</Text>
			<Progress
				width="70%"
				h={1}
				hasStripe
				value={videoEncoding.progress}
				isAnimated={true}
				colorScheme="green"
			/>
			<Text color="white" mt={2} fontSize="0.8rem">
				{videoEncoding.progress}%
			</Text>
		</Box>
	);
}

export default EncodingVideo;
