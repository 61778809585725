import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { createNotification } from "../../../store/actions";
import { content } from "./components/content";
import Layout from "./components/Layout";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";

function PushNotifications() {
	const dispatch = useDispatch();
	const history = useHistory();

	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];

	useEffect(() => {
		formik.setValues(initialValues);
	}, []);

	function handleSubmit(values, { setErrors }) {
		dispatch(createNotification(values, setErrors, history));
	}
	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false,
	});

	return <Layout header={t.header} lang={lang} formik={formik} />;
}

export default PushNotifications;
