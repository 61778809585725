import { pushNotificationActions } from "../../actionTypes";
import { addNotification } from "../../../api/libs/pushNotifications";
import toast from "react-hot-toast";

export const createNotification = (data, setErrors, history) => {
	return async dispatch => {
		dispatch({
			type: pushNotificationActions.CREATE_PUSH_NOTIFICATION,
		});
		addNotification(data)
			.then(res => {
				toast.success("Successfully created notification");
				dispatch({
					type: pushNotificationActions.CREATE_PUSH_NOTIFICATION_FULFILLED,
					payload: res.data,
				});
				dispatch({
					type: pushNotificationActions.FETCH_PUSH_NOTIFICATIONS,
					payload: { reset: true },
				});

				history.push("/pushNotifications");
			})
			.catch(err => {
				setErrors(err.response.data.errors);
				toast.error("Error");
				dispatch({
					type: pushNotificationActions.CREATE_PUSH_NOTIFICATION_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
