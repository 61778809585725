export const content = {
	en: {
		invoice_location: "Invoice Location",
		country: "Country",
		state: "State",
		city: "City",
		address_line_1: "Address line 1",
		address_line_2: "Address line 2",
		postal_code: "Postal code",
		create: "Create",
		vat_number: "VAT number",
		street: "Street",
		street_number: "House number",
	},
	de: {
		invoice_location: "Invoice Location",
		country: "Country",
		state: "State",
		city: "City",
		address_line_1: "Address line 1",
		address_line_2: "Address line 2",
		postal_code: "Postal code",
		create: "Create",
		vat_number: "VAT number",
		street: "Street",
		street_number: "House number",
	},
};
