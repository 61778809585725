import { customActions, invoiceActions } from "../../actionTypes";
import { getInvoiceLocation } from "../../../api/libs/invoice";
import { updateInvoiceLocation } from "./updateInvoiceLocation";

export const fetchInvoiceLocation = (id, history) => {
	return async dispatch => {
		dispatch({
			type: invoiceActions.FETCH_INVOICE_LOCATION,
		});
		dispatch({
			type: customActions.SET_APP_STATE,
			payload: true,
		});
		getInvoiceLocation(id)
			.then(res => {
				dispatch({
					type: invoiceActions.FETCH_INVOICE_LOCATION_FULFILLED,
					payload: res.data,
				});
				localStorage.setItem("activeInvoice", id);
				dispatch(updateInvoiceLocation(res.data.id, { ...res.data, default: true }));
				dispatch({
					type: customActions.SET_APP_STATE,
					payload: false,
				});
				console.log("res from hook", res);
				history.push("/dashboard");
			})
			.catch(err => {
				console.log("err from hook", err);
				dispatch({
					type: invoiceActions.FETCH_INVOICE_LOCATION_REJECTED,
					// payload: err.response.data.errors,
				});
				dispatch({
					type: customActions.SET_APP_STATE,
					payload: true,
				});
				localStorage.removeItem("activeInvoice");
				dispatch({
					type: customActions.SET_APP_STATE,
					payload: false,
				});
			});
	};
};
