import React from "react";
import { Box } from "@chakra-ui/react";
import { content } from "../components/content";
import PrimaryTextArea from "../../../shared/inputs/PrimaryTextArea";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";

function Description({ formik, lang, setShowMap, isLoaded }) {
	// title, address, country

	const t = content[lang];

	return (
		<Box>
			<PrimaryInput column={true} label={t.ctitle} type="text" id="title" formik={formik} />

			<PrimaryInput column={true} label={t.sub_title} type="text" id="sub_title" formik={formik} />

			<PrimaryInput
				column={true}
				label={t.description_title}
				type="text"
				id={"description_title"}
				formik={formik}
			/>

			<PrimaryTextArea
				column={true}
				label={t.desc}
				type="textarea"
				id={"description"}
				formik={formik}
			/>
		</Box>
	);
}

export default Description;
