import { apiCallAc } from "..";

export function addLocation(data) {
	return apiCallAc.post(`/dashboard/locations?fb_page_share=${data.fb_page_share}`, data);
}

// export function subscriptionsPerLocation(id) {
//   return apiCallAc.get(`/dashboard/locations?show=mobile_subscriptions`);
// }

export function getLocations(type = "published", page = 1) {
	return apiCallAc.get(
		type === "published"
			? `/dashboard/locations?show=mobile_subscriptions`
			: type === "published"
			? `/dashboard/locations?show=category&pinnedGalleries=true&page=${page}`
			: `/dashboard/trashed/locations`,
	);
}

export function getLocation(id) {
	return apiCallAc.get("/dashboard/locations/" + id + "/edit");
}

export function updateLocation(id, data) {
	return apiCallAc.put(`/dashboard/locations/${id}?fb_page_share=${data.fb_page_share}`, data);
}

export function deleteLocation(id) {
	return apiCallAc.delete("/dashboard/locations/" + id);
}

export function restoreLocation(id) {
	return apiCallAc.post("/dashboard/trashed/locations/" + id);
}

export function addHours(data) {
	return apiCallAc.post("/dashboard/open-hours", data);
}

export function getHours(id) {
	return apiCallAc.get("/dashboard/open-hours?location_id=" + id);
}

export function removeHours(id, locationId) {
	return apiCallAc.delete(`/dashboard/open-hours/${id}?location_id=${locationId}`);
}
