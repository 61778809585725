import React from "react";
import Content from "./components/Content";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceLocation } from "../../../store/actions";
import { useHistory } from "react-router-dom";

function Invoices() {
	const dispatch = useDispatch();
	const history = useHistory();
	const lang = useSelector(state => state.languageProvider.language);
	const handleSelect = id => {
		dispatch(fetchInvoiceLocation(id, history));
	};
	return <Content handleSelect={handleSelect} />;
}

export default Invoices;
