import React, { useEffect, useRef } from "react";
import { useImmer } from "use-immer";

import Layout from "./components/layout/Layout";
import Header from "./components/Header/Header";
import CanvasLayout from "./components/layout/CanvasLayout";

import ControllsLayout from "./components/layout/ControllsLayout";
import MainControlls from "./components/controlls/MainControlls/MainControlls";
import ObjectControlls from "./components/controlls/ObjectControlls";
import TargetControlls from "./components/controlls/TargetControlls";

import Modal from "./components/Modal";
import MediaInput from "./components/mediaInput/MediaInput";

import { useSlides } from "../../../Builder/store";
import { useCanvas } from "../../hooks/useCanvas";
import { preview } from "../../utils/canvas";

function EditorWindow({ variant, refer, onCloseEditorWindow, formik }) {
	const slides = useSlides(state => state[variant]);
	const activeSlide = useSlides(state => state.activeSlide);
	const markSlideEdited = useSlides(state => state.markSlideEdited);
	const saveSlide = useSlides(state => state.saveSlide);
	const addExPng = useSlides(state => state.addExPng);
	const slide = slides[activeSlide];

	const [state, setState] = useImmer({
		activeObject: null,
		imageLoading: false,
		modal: {
			active: false,
			type: null,
		},
		isEdited: false,
		triggerPrev: false,
	});
	const [canvas, isReady] = useCanvas(variant, refer, slide, setState);
	const fileInput = useRef(null);

	const onSaveSlide = () => {
		markSlideEdited();
		saveSlide(canvas);
		addExPng(canvas);
		setState(state => {
			state.triggerPrev = true;
		});
	};

	useEffect(() => {
		if (state.triggerPrev) {
			preview(slides, canvas, variant, formik);
			onCloseEditorWindow();
		}
	}, [state.triggerPrev]);

	return (
		<Layout>
			<Header
				onCloseEditorWindow={onCloseEditorWindow}
				canSave={state.isEdited}
				onSaveSlide={onSaveSlide}
			/>
			<CanvasLayout {...{ variant, slide }} />
			{!slide.isVideo && (
				<ControllsLayout>
					<TargetControlls
						canvas={canvas}
						state={state}
						setState={setState}
						activeObject={state.activeObject}
					/>
					<ObjectControlls canvas={canvas} activeObject={state.activeObject} />
					<MainControlls {...{ variant, slide, canvas, setState, fileInput }} />
				</ControllsLayout>
			)}
			{slide.isVideo && (
				<ControllsLayout>
					<MainControlls
						{...{
							variant,
							slide,
							canvas,
							setState,
							fileInput,
							activeSlide,
							refer,
						}}
					/>
				</ControllsLayout>
			)}
			{state.modal.active && <Modal {...{ state, setState, canvas, variant }} />}
			<MediaInput
				fileInput={fileInput}
				canvas={canvas}
				state={state}
				setState={setState}
				activeSlide={activeSlide}
			/>
		</Layout>
	);
}

export default EditorWindow;
