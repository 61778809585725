import React, { useState } from "react";
import { Box, Text, Image } from "@chakra-ui/react";

function ActionBtn({
	activeObject = true,
	label = false,
	state0,
	state1,
	state2,
	action = () => {},
	active = true,
}) {
	const [onMouseDown, setOnMouseDown] = useState(false);

	if (!activeObject || !active) {
		return (
			<Box display="flex" flexDir="column" alignItems="center" cursor="pointer">
				<Image opacity="0.5" src={state1} boxSize="35px" cursor="pointer" mr="10px" />
				{label && <Text>{label}</Text>}
			</Box>
		);
	}
	return (
		<Box
			display="flex"
			flexDir="column"
			alignItems="center"
			onMouseDown={() => setOnMouseDown(true)}
			onMouseUp={() => {
				setOnMouseDown(false);
				action();
			}}
			cursor="pointer"
		>
			<Image src={onMouseDown ? state2 : state1} boxSize="35px" cursor="pointer" mr="10px" />
			{label && <Text>{label}</Text>}
		</Box>
	);
}

export default ActionBtn;
