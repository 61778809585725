import { generateUUID } from "../../../../utils/uuid";
import { initialCanvas } from "../static/initialCanvas";

export const initialSlides = [
	{
		index: generateUUID(),
		template: initialCanvas,
		png: null,
		original: null,
		isVideo: false,
		video: null,
		isPano: false,
		pano: null,
		isEdited: false,
		isPreview: false,
		orientation: "squere",
		orientationLocked: false,
	},
];
