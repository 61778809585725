import produce from "immer";
import { generateUUID } from "../../../../utils/uuid";
import { initialCanvas } from "../static/initialCanvas";
import { initialSlides } from "../static/initialSlides";
import { canvasToJson, canvasToPng, exportPng, changeOrientation } from "../utils/canvas";
import { changeMobOrientation } from "../../RBuilder/utils/canvas";
import { fetchStickers, fetchStickersNext } from "../../../../api/libs/stickers";
import { fetchFonts, fetchFontssNext } from "../../../../api/libs/fonts";

const setActiveSlide = (set, get, index) => {
	set(
		produce(state => {
			state.activeSlide = index;
		}),
	);
};

const onSaveSlide = (set, get, canvas) => {
	let activeSlide = get().activeSlide;
	let variant = get().currentVariant;
	if (variant === "gallery") {
		set(
			produce(state => {
				state.gallery[activeSlide] = {
					...state.gallery[activeSlide],
					template: canvasToJson(canvas),
					png: canvasToPng(canvas),
				};
			}),
		);
	} else {
		set(
			produce(state => {
				state.logo[0] = {
					...state.logo[0],
					template: canvasToJson(canvas),
					png: canvasToPng(canvas),
				};
			}),
		);
	}
};

const selectSlide = (set, get, canvas, index, state, setState) => {
	if (state.activeSlide !== index) {
		let slides = get().gallery;
		let activeSlide = get().activeSlide;
		if (slides[activeSlide].isEdited && !slides[activeSlide].isVideo) {
			set(
				produce(state => {
					state.gallery[state.activeSlide].exPng = exportPng(canvas, "gallery");
				}),
			);
		}
		set(produce(() => {}));
		setState(state => {
			state.activeSlide = index;
		});
	}
};

const onAddSlide = (set, get, canvas, refer, setState, forMob = false) => {
	const length = get().gallery.length;
	if (length < 5) {
		let slides = get().gallery;
		let activeSlide = get().activeSlide;
		if (!forMob) {
			if (slides[activeSlide].isEdited && !slides[activeSlide].isVideo) {
				set(
					produce(state => {
						state.gallery[state.activeSlide].exPng = exportPng(canvas, "gallery");
					}),
				);
			}
		}
		set(
			produce(state => {
				state.gallery.push({
					index: generateUUID(),
					template: initialCanvas,
					png: null,
					exPng: null,
					original: null,
					orientation: refer === "locations" ? "landscape" : "portrait",
					orientationLocked: false,
				});
			}),
		);
		setState(state => {
			state.activeSlide = get().gallery.length - 1;
		});
	}
};

const onRemoveSlide = (set, get, index, setState) => {
	let length = get().gallery.length;
	let activeSlide = get().activeSlide;
	if (length > 1 && activeSlide === index) {
		set(
			produce(state => {
				state.gallery = state.gallery.filter((i, k) => k !== index);
			}),
		);
	}
	if (activeSlide === 0) {
		setState(state => {
			state.triggerRender = !state.triggerRender;
		});
	} else {
		setState(state => {
			state.activeSlide = 0;
		});
	}
};

const onCloneSlide = (set, get) => {
	const activeSlide = get().activeSlide;
	set(
		produce(state => {
			state.gallery.splice(activeSlide + 1, 0, {
				...state.gallery[activeSlide],
				index: generateUUID(),
			});
		}),
	);
};

const onReorderSlides = (set, get, updatedOrder) => {
	set(
		produce(state => {
			state.gallery = updatedOrder;
		}),
	);
};

const onToogleOrientation = (set, get, canvas, forMob = false) => {
	const activeSlide = get().activeSlide;
	const currentOr = get().gallery[activeSlide].orientation;
	if (currentOr === "portrait") {
		set(
			produce(state => {
				state.gallery[activeSlide].orientation = "landscape";
			}),
		);
		if (forMob) {
			changeMobOrientation(canvas, "landscape");
		} else {
			changeOrientation(canvas, "landscape");
		}
	} else {
		set(
			produce(state => {
				state.gallery[activeSlide].orientation = "portrait";
			}),
		);
		if (forMob) {
			changeMobOrientation(canvas, "portrait");
		} else {
			changeOrientation(canvas, "portrait");
		}
	}
};

// state[variant][activeSlide]

const onSetOrientation = (set, get, or) => {
	const activeSlide = get().activeSlide;
	let variant = get().currentVariant;
	set(
		produce(state => {
			state[variant][activeSlide].orientation = or;
		}),
	);
};

const onSetSlideLoading = (set, get, bool) => {
	set(
		produce(state => {
			state.slideLoading = bool;
		}),
	);
};

// state[variant][activeSlide] ??

const onModifySlide = (set, get, index, obj) => {
	let variant = get().currentVariant;

	set(
		produce(state => {
			state[variant][index] = { ...state[variant][index], ...obj };
		}),
	);
};

// state[variant][activeSlide]

const onMarkAsEdited = (set, get) => {
	const activeSlide = get().activeSlide;
	let variant = get().currentVariant;
	const isEdited = get()[variant][activeSlide].isEdited;
	const isPreview = get()[variant][activeSlide].isPreview;

	if (!isEdited) {
		set(
			produce(state => {
				state[variant][activeSlide].isEdited = true;
			}),
		);
	}
	if (isPreview) {
		set(
			produce(state => {
				state[variant][activeSlide].isPreview = false;
			}),
		);
	}
};

const onSetCurrentVariant = (set, get, variant) => {
	set(
		produce(state => {
			state.currentVariant = variant;
		}),
	);
};

// state[variant][activeSlide] ??

const onSetISPopulated = (set, get, bool) => {
	set(
		produce(state => {
			state.isPopulated = bool;
		}),
	);
};

const onResetState = set => {
	set(() => ({
		logo: initialSlides,
		gallery: initialSlides,
		currentVariant: null,
		activeSlide: 0,
		slideLoading: false,
		isPopulated: false,
	}));
};

const onAddExPng = (set, get, canvas) => {
	let variant = get().currentVariant;
	set(
		produce(state => {
			state[variant][state.activeSlide].exPng = exportPng(canvas, variant);
		}),
	);
};

const getEmojies = async set => {
	set(
		produce(state => {
			state.emojies.fetching = true;
		}),
	);
	fetchStickers()
		.then(res => {
			set(
				produce(state => {
					state.emojies.fetching = false;
					state.emojies.fetched = true;
					state.emojies.canFetchNext = res.data.next_page_url ? true : false;
					state.emojies.nextPageUrl = res.data.next_page_url;
					state.emojies.data = res.data.data;
				}),
			);
		})
		.catch(err => {
			console.log(err.response.data);
			set(
				produce(state => {
					state.emojies.fetching = false;
					state.emojies.fetched = false;
				}),
			);
		});
};
const getEmojiesNext = (set, get) => {
	let url = get().emojies.nextPageUrl;
	console.log("fetching next fromm", url);
	set(
		produce(state => {
			state.emojies.fetchingNext = true;
		}),
	);
	fetchStickersNext(url)
		.then(res => {
			set(
				produce(state => {
					state.emojies.canFetchNext = res.data.next_page_url ? true : false;
					state.emojies.nextPageUrl = res.data.next_page_url;
					state.emojies.data = [...state.emojies.data, ...res.data.data];
					state.emojies.fetchingNext = false;
				}),
			);
		})
		.catch(err => {
			console.log(err.response.data);
			set(
				produce(state => {
					state.emojies.fetchingNext = false;
				}),
			);
		});
};
const getFonts = async set => {
	set(
		produce(state => {
			state.fonts.fetching = true;
		}),
	);
	fetchFonts()
		.then(res => {
			set(
				produce(state => {
					state.fonts.fetching = false;
					state.fonts.fetched = true;
					state.fonts.canFetchNext = res.data.next_page_url ? true : false;
					state.fonts.nextPageUrl = res.data.next_page_url;
					state.fonts.data = res.data.data;
				}),
			);
		})
		.catch(err => {
			console.log(err.response.data);
			set(
				produce(state => {
					state.fonts.fetching = false;
					state.fonts.fetched = false;
				}),
			);
		});
};
const getFontsNext = (set, get) => {
	let url = get().fonts.nextPageUrl;
	set(
		produce(state => {
			state.fonts.fetchingNext = true;
		}),
	);
	fetchFontssNext(url)
		.then(res => {
			set(
				produce(state => {
					state.fonts.canFetchNext = res.data.next_page_url ? true : false;
					state.fonts.nextPageUrl = res.data.next_page_url;
					state.fonts.data = [...state.fonts.data, ...res.data.data];
					state.fonts.fetchingNext = false;
				}),
			);
		})
		.catch(err => {
			console.log(err.response.data);
			set(
				produce(state => {
					state.fonts.fetchingNext = false;
				}),
			);
		});
};

export {
	setActiveSlide,
	onSaveSlide,
	selectSlide,
	onAddSlide,
	onRemoveSlide,
	onCloneSlide,
	onReorderSlides,
	onToogleOrientation,
	onSetOrientation,
	onSetSlideLoading,
	onModifySlide,
	onMarkAsEdited,
	onSetCurrentVariant,
	onSetISPopulated,
	onResetState,
	onAddExPng,
	getEmojies,
	getEmojiesNext,
	getFonts,
	getFontsNext,
};
