import React from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import Hamburger from "./Hamburger";
import CompanySelector from "../../companySelector";
import UserBoard from "./UserBoard";
import ActionBox from "./ActionBox";
import Loading from "../../../shared/Loading";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
} from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import PrimaryButton from "../../buttons/PrimaryButton";

function Layout({
	sidebarActive,
	user,
	handleToogle,
	lang,
	setExpandedNotification,
	expandedNotification,
	isLoading,
	notifications,
	notification,
	fetchNotification,
	notificationAmount,
	countLoading,
	avatar,
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Box
			position={["absolute", "relative"]}
			width={["100%", "auto"]}
			bg="#FFF"
			zIndex={5}
			borderBottom="1px solid rgba(0, 0, 0, 0.6)"
			display="flex"
			justifyContent="space-between"
			alignItems="center"
		>
			<Hamburger sidebarActive={sidebarActive} handleToogle={handleToogle} />
			<Box mr="auto"></Box>
			<Box display="flex">
				<Menu>
					{({ onClose: closeMenu }) => (
						<>
							<MenuButton
								as={Button}
								bg="white"
								outline="none"
								_active={{ outline: "none" }}
								_focus={{ outline: "none" }}
								style={{ position: "relative" }}
							>
								<Box position="relative">
									{!countLoading && notificationAmount ? (
										<Box
											position="absolute"
											right="0"
											top="0"
											transform="translate(50%,-40%)"
											backgroundColor="rgb(255,158,38)"
											padding="2px 7px"
											borderRadius="10px"
											fontSize="xs"
											zIndex="9"
										>
											{notificationAmount}
										</Box>
									) : null}
									<FontAwesomeIcon
										cursor="pointer"
										icon="bell"
										color="black"
										style={{
											fontSize: "22",
										}}
									/>
								</Box>
							</MenuButton>

							<MenuList
								maxHeight="50vh"
								overflowY="scroll"
								css={{
									"&::-webkit-scrollbar": {
										width: "4px",
									},
									"&::-webkit-scrollbar-track": {
										width: "6px",
									},
									"&::-webkit-scrollbar-thumb": {
										background: "transparent",
										borderRadius: "24px",
									},
								}}
								display="flex"
								flexDirection="column"
								pb="3em"
							>
								{(!isLoading && Object.keys(notifications)).length
									? notifications.data.map(item => (
											<MenuItem key={item.id}>
												<Box
													width="100%"
													pt={2}
													cursor="pointer"
													onClick={() => {
														closeMenu();
														setExpandedNotification(item.id);
														onOpen(true);
													}}
												>
													<Box color={item.seen ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,1)"}>
														<Text fontSize="lg">{item.title}</Text>
														<Box
															display="flex"
															justifyContent="space-between"
															alignItems="center"
															paddingBottom="0.5rem"
														>
															<Text
																backgroundColor={item.seen ? "rgba(239,78,66,0.4)" : "#EF4E42"}
																p="0.2em"
																color="white"
																fontSize="xs"
															>
																{item.type}
															</Text>
															<Text fontSize="xs">{item.created_at}</Text>
														</Box>
													</Box>
													<hr></hr>
												</Box>
											</MenuItem>
									  ))
									: null}
								<Box
									px="0.75em"
									display="flex"
									justifyContent="space-between"
									position="fixed"
									bottom="1px"
									borderBottomRightRadius="6px"
									borderBottomLeftRadius="6px"
									width="98%"
									py="0.5em"
									bg="#fff"
								>
									<Text cursor="pointer" fontSize="sm" onClick={() => {}}>
										Read all
									</Text>
									<Link onClick={closeMenu} to="/inAppNotifications">
										<Text fontSize="sm">See more</Text>
									</Link>
								</Box>
							</MenuList>
						</>
					)}
				</Menu>

				<Box position="relative" className="notifications " data-dropdown></Box>
				<Box h="100%" alignItems="center" mr="0.5em" display="flex">
					<UserBoard avatar={avatar} lang={lang} name={user} display={["none", "flex"]} />
					<Box h="100%" w="1px" bg="rgba(0, 0, 0, 0.6)" mx="10px" display={["none", "block"]} />
					<CompanySelector />
				</Box>
				<ActionBox display="none" />
			</Box>

			<Modal onClose={onClose} size="full" isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{expandedNotification}</ModalHeader>
					<ModalCloseButton />
					{notification !== null ? (
						notification.type === "payment" ? (
							<ModalBody>
								<Table variant="striped">
									<Thead>
										<Tr>
											<Th>#</Th>

											<Th>1</Th>
										</Tr>
									</Thead>
									<Tbody>
										<Tr>
											<Td>Title</Td>
											<Td>{notification.title}</Td>
										</Tr>

										<Tr>
											<Td>Date</Td>
											<Td>{notification.created_at}</Td>
										</Tr>
										{notification.description?.hosted_invoice_url &&
											notification.description?.invoice_pdf && (
												<Tr>
													<Td>
														<Link
															to={{
																pathname: notification.description.hosted_invoice_url,
															}}
															target="_blank"
														>
															<PrimaryButton label="View invoice"></PrimaryButton>
														</Link>
													</Td>
													<Td>
														<Link
															to={{
																pathname: notification.description.invoice_pdf,
															}}
															target="_blank"
														>
															<PrimaryButton label="Download invoice"></PrimaryButton>
														</Link>
													</Td>
												</Tr>
											)}
									</Tbody>
								</Table>
							</ModalBody>
						) : (
							<Loading />
						)
					) : (
						<Loading />
					)}
					<ModalFooter>
						<Button onClick={onClose}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default Layout;
