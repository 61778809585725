import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router";
import { useSlides } from "../../Editor/Builder/store";
import { useSelector, useDispatch } from "react-redux";
import {
	createLocation,
	fetchLocation,
	updateLocation,
	createHours,
	attachKeywords,
	fetchKeywordsById,
} from "../../../store/actions";

import { content } from "./components/content";
import Layout from "./components/Layout";
import { initialValues } from "./formInitialValues";
import Loading from "../../shared/Loading";
import { uploadBase64 } from "../../../api/libs/upload";
import { validationSchema } from "./validationSchema";

import { getCities, getCountries, getStates, getTimezones } from "../../../api/libs/countries";

function Locations() {
	let { id } = useParams();
	const resetBuilderStore = useSlides(state => state.resetStore);
	const dispatch = useDispatch();
	const history = useHistory();
	const { location, fetched, fetching } = useSelector(state => state.fetchLocation);

	const { invoiceLocations, fetched: fetchedInvoiceLocations } = useSelector(
		state => state.fetchInvoiceLocations,
	);

	const lang = useSelector(state => state.languageProvider.language);
	const setFullNames = async () => {
		const responseCountry = await getCountries(
			formik.values.country.iso2 || formik.values.country,
			"",
			1,
		);
		formik.setFieldValue("country", responseCountry.data[0]);
		const responseState = await getStates(
			formik.values.state.state_code || formik.values.state,
			"",
			formik.values.country.iso2 || formik.values.country,
			1,
		);
		formik.setFieldValue("state", responseState.data[0]);

		const responseCity = await getCities(
			formik.values.city.name || formik.values.city,
			"",
			formik.values.country.iso2 || formik.values.country,
			formik.values.state.state_code || formik.values.state,
			1,
		);
		formik.setFieldValue("city", responseCity.data[0]);
		if (id) setFullTimezone();
	};

	const setFullTimezone = async () => {
		const responseTimezone = await getTimezones(
			formik.values.timezone.zone_name || formik.values.timezone,
			1,
			formik.values.country.iso2,
		);
		formik.setFieldValue("timezone", responseTimezone.data[0]);
	};
	const setSuggested = async country => {
		const responseCountry = await getCountries(country, "", 1, false);
		formik.setFieldValue("phone_number", `+${responseCountry.data[0].phone_code}`);
		const responseTimezone = await getTimezones("", "", country);
		formik.setFieldValue("timezone", responseTimezone.data[0]);
	};
	useEffect(() => {
		if (id) {
			dispatch(fetchLocation(id));
		} else {
			if (fetchedInvoiceLocations) {
				const invoice = invoiceLocations.data.find(item => item.default === true);
				formik.setFieldValue("country", invoice.country);
				formik.setFieldValue("state", invoice.state);
				formik.setFieldValue("city", invoice.city);
				formik.setFieldValue("street", invoice.street);
				formik.setFieldValue("street_number", invoice.street_number);
				formik.setFieldValue("postal_code", invoice.postal_code);

				setSuggested(invoice.country);
			} else {
				console.log("Test not fetched");
			}
		}
	}, []);

	const handleHourSubmit = (locationId, setErrors) => {
		const filtData = formik.values.open_hours
			.map(day => ({
				...day,
				hours: day.hours.filter(time => !time.id && time.open_at && time.close_at),
			}))
			.filter(day => day.hours.length);

		const hoursData = {
			location_id: locationId,
			open_hours:
				formik.values.send_hours && formik.values.closed_until
					? formik.values.open_hours
							.map(day => ({
								...day,
								hours: day.hours.filter(time => !time.id && time.open_at && time.close_at),
							}))
							.filter(day => day.hours.length).length
						? filtData
						: null
					: formik.values.send_hours
					? filtData
					: null,
			closed_until: formik.values.send_hours ? null : formik.values.closed_until,
			closed_message: formik.values.send_hours ? null : formik.values.closed_message,
		};
		if (filtData.length === 0 && formik.values.send_hours) {
		} else {
			dispatch(createHours(hoursData, setErrors, history));
		}
	};

	const handleKeywordsSubmit = (locationId, setErrors) => {
		dispatch(
			attachKeywords(
				"locations",
				locationId,
				formik.values.keywords.map(item => item.id),
			),
		);
	};

	async function checkValue(logo) {
		if (logo.includes("data:image")) {
			const formData = new FormData();
			formData.append("image[0]", logo);
			let newLogo = uploadBase64(formData, false, false);
			return newLogo;
		}
		return logo;
	}

	function handleSubmit(values, { setErrors }) {
		console.log(values.gallery);
		const filtered = Object.keys(values)
			.filter(key => key !== "gallery" || key !== "open_hours")
			.reduce((obj, key) => {
				obj[key] = values[key];
				return obj;
			}, {});

		let data = {
			...filtered,
			keywords: values.keywords.map(item => item.id),
			web_page: values.web_page.includes("https://")
				? values.web_page
				: "https://" + values.web_page,

			country: typeof filtered.country === "object" ? filtered.country.iso2 : filtered.country,
			state: typeof filtered.state === "object" ? filtered.state.state_code : filtered.state,
			city: typeof filtered.city === "object" ? filtered.city.name : filtered.city,
			city_id: typeof filtered.city === "object" ? filtered.city.id : filtered.city,
			timezone:
				typeof filtered.timezone === "object" ? filtered.timezone.zone_name : filtered.timezone,
		};

		checkValue(values.logo)
			.then(res => {
				if (typeof res === "object") {
					data.logo = res.data.images[0];
					if (id) {
						dispatch(
							updateLocation(
								id,
								data,
								location.gallery.id,
								values.gallery,
								setErrors,
								history,
								handleHourSubmit,
								handleKeywordsSubmit,
							),
						);
					} else {
						dispatch(
							createLocation(
								data,
								values.gallery,
								setErrors,
								history,
								handleHourSubmit,
								handleKeywordsSubmit,
							),
						);
					}
				} else {
					if (id) {
						dispatch(
							updateLocation(
								id,
								data,
								location.gallery.id,
								values.gallery,
								setErrors,
								history,
								handleHourSubmit,
								handleKeywordsSubmit,
							),
						);
					} else {
						dispatch(
							createLocation(
								data,
								values.gallery,
								setErrors,
								history,
								handleHourSubmit,
								handleKeywordsSubmit,
							),
						);
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false,
	});

	useEffect(() => {
		if (fetched && id) {
			formik.setValues({
				...initialValues,
				...location,
				gallery: location.gallery ? location.gallery.data : [],
			});
		}
		dispatch(fetchKeywordsById("locations", id, formik));
		return () => {
			formik.setValues(initialValues);
			resetBuilderStore();
		};
	}, [fetched]);
	useEffect(() => {
		if (typeof formik.values.country === "string" && formik.values.country) {
			setFullNames();
		}
	}, [formik.values.country]);

	//Create keywords

	const t = content[lang];

	if (fetching) return <Loading />;
	return (
		<Layout id={id} formik={formik} header={id ? t.edit_location : t.create_location} lang={lang} />
	);
}

export default Locations;
