import { companyActions } from "../../actionTypes";
import { addCompany } from "../../../api/libs/company";
import toast from "react-hot-toast";
import { fetchCompanies, fetchCompany } from ".";
import { resetInvoices } from "../invoice";

export const createCompany = (data, setErrors, history) => {
	return async dispatch => {
		dispatch({
			type: companyActions.CREATE_COMPANY,
		});
		addCompany(data)
			.then(res => {
				dispatch({
					type: companyActions.CREATE_COMPANY_FULFILLED,
					payload: res.data,
				});
				dispatch(resetInvoices());
				localStorage.removeItem("activeInvoice");
				localStorage.setItem("activeCompany", res.data.id);
				dispatch(fetchCompany(res.data.id));
				dispatch(fetchCompanies());
				history.push("/invoices");
				toast.success("Successfully created company");
			})
			.catch(err => {
				setErrors(err.response.data.errors);
				if (err?.response?.data?.errors?.active_subscription_required) {
					toast.error(err.response.data.errors.active_subscription_required);
				}
				dispatch({
					type: companyActions.CREATE_COMPANY_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
