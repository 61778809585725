import { userActions } from "../../actionTypes";
import { sendFacebookCode } from "../../../api/libs/user";

export const addFacebookCode = (code, history) => {
	return async dispatch => {
		dispatch({
			type: userActions.SEND_FACEBOOK_TOKEN_FETCHING,
		});
		sendFacebookCode(code)
			.then(res => {
				dispatch({
					type: userActions.SEND_FACEBOOK_TOKEN_FULFILLED,
					payload: res,
				});
				history.push("/profile/facebook?resync=true");
			})
			.catch(err => {
				dispatch({
					type: userActions.SEND_FACEBOOK_TOKEN_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
