import apiCall from "..";

export function getCompanies() {
	return apiCall.get("/dashboard/company");
}
export function getCompany(id) {
	return apiCall.get(`/dashboard/company/${id}`);
}
export function getCompanyCategories() {
	return apiCall.get("/dashboard/location_categories");
}
export function addCompany(data) {
	return apiCall.post("/dashboard/company", JSON.stringify(data));
}

export function updateCompany(id, data) {
	return apiCall.put("/dashboard/company/" + id, JSON.stringify(data));
}
