import React from "react";
import { Box, Image } from "@chakra-ui/react";

function Slide({ slide, index, activeSlide, setState }) {
	const handleClick = () => {
		setState(state => {
			state.activeSlide = index;
		});
	};
	return (
		<Box display="flex" h="70px" w="100px" mr="20px" onClick={handleClick}>
			<Image
				src={slide.png}
				h="70px"
				w="100px"
				minW="100px"
				border={activeSlide === index ? "5px solid #AECB2D" : ""}
				fit="contain"
			/>
		</Box>
	);
}

export default Slide;
