import { couponActions } from "../../actionTypes";

const initialState = {
	coupons: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case couponActions.FETCH_COUPONS: {
			return action.payload.reset
				? { ...initialState, fetching: true }
				: { ...state, fetching: true };
		}

		case couponActions.FETCH_COUPONS_FULFILLED: {
			return state.coupons.current_page < action.payload.current_page
				? {
						...state,
						fetching: false,
						fetched: true,
						coupons: {
							...action.payload,
							data: state.coupons.data
								? [...state.coupons.data, ...action.payload.data]
								: action.payload.data,
						},
				  }
				: {
						...state,
						fetching: false,
						fetched: true,
						coupons: action.payload,
				  };
		}

		case couponActions.FETCH_COUPONS_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
