import React, { useEffect } from "react";
import queryString from "query-string";

import { useSelector, useDispatch } from "react-redux";
import { verify, fetchUserData } from "../../../store/actions";

import Layout from "./components/Layout";
import Form from "./components/Form";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";
import { useFormik } from "formik";

function EmailVerification({ location, history }) {
	const lang = useSelector(state => state.languageProvider.language);
	const { status } = useSelector(state => state.verification);
	const { isAuthenticated } = useSelector(state => state.currentUser);
	const dispatch = useDispatch();

	const { token } = queryString.parse(location.search);

	const handleSubmit = () => {
		const data = {
			token,
		};
		dispatch(verify(data, formik.setErrors));
	};
	useEffect(() => {
		if (status) {
			if (isAuthenticated) {
				dispatch(fetchUserData(history));
			}
		}
	}, [status]);

	useEffect(() => {
		handleSubmit();
	}, []);
	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		<Layout>
			<Form lang={lang} formik={formik} />
		</Layout>
	);
}

export default EmailVerification;
