import apiCall, { apiCallAc } from "..";

// Get Available Country List For Registration
export function getCountries(data, suggested = false) {
	return apiCall.get("/countries", { params: { suggested: suggested } });
}

export function getLogs(page = 1) {
	return apiCall.get(`/dashboard/logs?page=${page}`);
}

export function getClientSecret(data) {
	return apiCallAc.post(`/payment/company/subscribe/client/secret`, data);
}

// Register User
export function registerUser(data) {
	return apiCall.post("/auth/register/client", JSON.stringify(data));
}

// Register User with ref
export function registerUserRef(data, ref) {
	return apiCall.post(`/auth/register/client?ref=${ref}`, JSON.stringify(data));
}

// Verify email
export function verifyEmail({ token, password }) {
	return apiCall.post(`/email/verify?token=${token}`, { password: password });
}

// Verify location email
export function verifyLocationEmail({ token }) {
	return apiCall.get(`/locationEmail/verify?token=${token}`);
}

// Resend Verification email
export function resendVerificationEmail(newEmail) {
	if (newEmail) {
		return apiCall.put(`/resend/emailVerification?new_email=${newEmail}`);
	} else {
		return apiCall.put(`/resend/emailVerification`);
	}
}

// Login User
export function loginUser(data) {
	return apiCall.post("/auth/login", JSON.stringify(data));
}
export function logOutUser() {
	return apiCall.post("/auth/logout");
}

// Forgot Password
export function recoverPassword(data) {
	return apiCall.post("/auth/recover", JSON.stringify(data));
}
// /password/reset?token= Change Password By Forgot Password
export function resetPassword(data) {
	return apiCall.post("/auth/reset", JSON.stringify(data));
}
// Get Current User info
export function getCurrentUser() {
	return apiCall.get("/auth/me");
}

export function updateUser(data) {
	return apiCall.put("/auth/me", data);
}

export function getFacebookToken() {
	return apiCallAc.get("/dashboard/login/facebook");
}

export function sendFacebookCode(code) {
	return apiCallAc.get(`/dashboard/login/facebook/callback?code=${code}`);
}

export function getFacebookUserDetails() {
	return apiCallAc.get(`/dashboard/fb`);
}

export function getFacebookPages(resync = false) {
	return apiCallAc.get(`/dashboard/fbPages?resync=${resync}`);
}
