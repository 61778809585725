export const content = {
	en: {
		recover: "Recover password",
		email: "Email Address",
		send: "Send",
	},
	de: {
		recover: "Passwort wiederherstellen",
		email: "E-Mail-Addresse",
		send: "Senden",
	},
};
