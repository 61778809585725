import React from "react";
import { Box, Text } from "@chakra-ui/react";

import Fonts from "./components/Fonts";

function FontEdit({ state, canvas }) {
	return (
		<Box flexGrow="1" py="20px">
			<Text fontSize="22px"> Choose Font </Text>
			<Fonts state={state} canvas={canvas} />
		</Box>
	);
}

export default FontEdit;
