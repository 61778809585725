export const content = {
	en: {
		edit_company: "Edit company",
		email: "Email",
		phone: "Phone",
		logo: "Logo",
		address: "Address",
		submit: "Submit",
		name: "Name",
	},
	de: {
		name: "Name",
		edit_company: "Firma bearbeiten",
		email: "Email",
		phone: "Telefon",
		logo: "Logo",
		address: "Die Anschrift",
		submit: "Einreichen",
	},
};
