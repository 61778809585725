import { Box, Text, Divider } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/react";
import React from "react";
import toast from "react-hot-toast";
import { recoverPassword } from "../../../../api/libs/user";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import LanguageSelector from "../../../shared/languageSelector";
import { content } from "./content";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";

export default function Layout({
	user,
	isOpen,
	onClose,
	onOpen,
	onSubmit,
	newEmail,
	setNewEmail,
	formik,
	lang,
	history,
	limits,
}) {
	const {
		name,
		surname,
		email,
		phone_number,
		country,
		city,
		street,
		postal_code,
		email_verified_at,
	} = user;
	const t = content[lang];
	return (
		<Box m={5}>
			<Box display="flex" flexDirection="column">
				<Box display="flex" justifyContent="space-between">
					<Box py="3">
						<Box mb="1">
							<Text fontSize="24px">{t.profile}</Text>
						</Box>
						<Box w="40px">
							<Divider borderBottom="4px" opacity="1" />
						</Box>
					</Box>
					<LanguageSelector />
				</Box>

				<Box display="grid" gridTemplateColumns="4fr 8fr">
					<Text>{t.name}: </Text>
					<Text>{name}</Text>
				</Box>
				<Box display="grid" gridTemplateColumns="4fr 8fr">
					<Text>{t.surname}: </Text>
					<Text>{surname}</Text>
				</Box>
				<Box display="grid" gridTemplateColumns="4fr 8fr">
					<Text>{t.email}: </Text>
					<Box display="flex" wordBreak="break-all" flexDirection={["column", "row"]}>
						<Text mr="1em">{email}</Text>
						{email_verified_at ? (
							<Text color="green">{t.verified}</Text>
						) : (
							<Text color="red">{t.unverified}</Text>
						)}
					</Box>
				</Box>
				<Box display="grid" gridTemplateColumns="4fr 8fr">
					<Text>{t.phone_number}: </Text>
					<Text>{phone_number}</Text>
				</Box>
				<Text mt="1em" fontSize="1.5em" mb="0.5em">
					{t.address}
				</Text>
				<Box display="grid" gridTemplateColumns="4fr 8fr">
					<Text>{t.country}: </Text>
					<Text>{country}</Text>
				</Box>
				<Box display="grid" gridTemplateColumns="4fr 8fr">
					<Text>{t.city}: </Text>
					<Text>{city}</Text>
				</Box>
				<Box display="grid" gridTemplateColumns="4fr 8fr">
					<Text>{t.street}: </Text>
					<Text>{street}</Text>
				</Box>
				<Box display="grid" gridTemplateColumns="4fr 8fr">
					<Text>{t.postal_code}: </Text>
					<Text>{postal_code}</Text>
				</Box>
				<Box borderTop="2px solid #000" display="flex" mt="1em" pt="1em" justifyContent="center">
					<Box display="grid" gridGap="1em" gridTemplateColumns={["1fr", "repeat(5,1fr)"]}>
						<PrimaryButton onClick={() => history.push("/logs")} label={t.logs} />
						<PrimaryButton onClick={() => history.push("/profile/edit")} label={t.edit_profile} />

						<PrimaryButton
							onClick={() =>
								toast.promise(recoverPassword({ email: email }), {
									loading: "Sending email...",
									success: <b>{t.email_sent}!</b>,
									error: <b>{t.email_error}.</b>,
								})
							}
							label={t.change_password}
						/>

						<PrimaryButton onClick={onOpen} label={t.change_email} />

						<PrimaryButton
							backgroundColor={limits.includes("facebook_pages") ? "#DEDEDF" : "#A9A9AD"}
							onClick={() =>
								limits.includes("facebook_pages")
									? toast.error("Upgrade plan to use feature", {
											style: {
												border: "1px solid #713200",

												color: "#713200",
											},
											iconTheme: {
												primary: "#713200",
												secondary: "#FFFAEE",
											},
									  })
									: history.push("/profile/facebook")
							}
							label={t.facebook}
						/>
					</Box>
				</Box>
			</Box>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t.change_email}</ModalHeader>
					<ModalCloseButton />
					<form onSubmit={formik.handleSubmit}>
						<ModalBody pb={6}>
							<FormControl isInvalid={formik.errors.new_email} id="new_email">
								<FormLabel>{t.new_email}</FormLabel>
								<Box display="flex" flexDirection="column">
									<FormErrorMessage>{formik.errors.new_email}</FormErrorMessage>
									<Input
										required
										placeholder="example@example.com"
										type="text"
										onChange={formik.handleChange}
										value={formik.values.new_email}
										id="new_email"
									/>
								</Box>
							</FormControl>
						</ModalBody>

						<ModalFooter>
							<PrimaryButton type="submit" label={t.save} mr={3} />
							<PrimaryButton onClick={onClose} label={t.cancel} />
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		</Box>
	);
}
