import { statisticActions } from "../../actionTypes";
import { getStats } from "../../../api/libs/stats";

export const fetchStats = config => {
	return async dispatch => {
		dispatch({
			type: statisticActions.FETCH_STATS,
		});
		getStats(config)
			.then(res => {
				// console.log(config);
				// console.log("success");
				dispatch({
					type: statisticActions.FETCH_STATS_FULFILED,
					payload: { ...config, res: res.data },
				});
			})
			.catch(err => {
				dispatch({
					type: statisticActions.FETCH_STATS_REJECTED,
					payload: err.response,
				});
			});
	};
};
