import { apiCallAc } from "..";

export function addNews(data) {
	return apiCallAc.post(`/dashboard/news?fb_page_share=${data.fb_page_share}`, data);
}

export function getNewses(type = "published", page = 1, couponId = "") {
	return apiCallAc.get(
		`/dashboard/news?order_by=is_pinned&show=${type}&page=${page}&coupon=${couponId}`,
	);
}

export function getNews(id) {
	return apiCallAc.get("/dashboard/news/" + id + "/edit");
}

export function updateNews(id, data) {
	return apiCallAc.put(`/dashboard/news/${id}?fb_page_share=${data.fb_page_share}`, data);
}

export function restoreNews(id) {
	return apiCallAc.post("/dashboard/trashed/news/" + id);
}

export function deleteNews(id) {
	return apiCallAc.delete("/dashboard/news/" + id);
}
