import { apiCallAc } from "..";

export function addCoupon(data) {
	return apiCallAc.post("/dashboard/coupon", data);
}

export function getCoupons(type = "published", page = 1, newsId = "") {
	return apiCallAc.get(`/dashboard/coupon?&show=${type}&page=${page}&news=${newsId}`);
}

export function getCoupon(id) {
	return apiCallAc.get("/dashboard/coupon/" + id + "/edit");
}

export function updateCoupon(id, data) {
	return apiCallAc.put("/dashboard/coupon/" + id, data);
}

export function deleteCoupon(id) {
	return apiCallAc.delete("/dashboard/coupon/" + id);
}
