import React from "react";
import { Thead as ChakraThead } from "@chakra-ui/react";
import useWindowSize from "../../../utils/useWindowSize";

export const Thead = props => {
	const { children, ...rest } = props;
	const { width } = useWindowSize();

	return (
		<ChakraThead
			zIndex="2"
			position="sticky"
			top="0"
			backgroundColor="#FFF"
			_before={{
				content: `""`,
				position: width > 768 ? "absolute" : "relative",
				top: "2.5em",
				width: "100%",
				height: "3px",
				bg: "gray.900",
				opacity: "1",
			}}
			{...rest}
		>
			{React.isValidElement(children) && React.cloneElement(children, { inHeader: true })}
		</ChakraThead>
	);
};
