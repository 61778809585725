import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateCompany } from "../../../store/actions";
import Layout from "./components/Layout";
import Loading from "../../shared/Loading";
import { initialValues } from "./formInitialValues";
export default function ProfileEdit() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [uploaded, setUploaded] = useState("");

	const { company, fetching: fetchingCompany, fetched } = useSelector(state => state.fetchCompany);
	const lang = useSelector(state => state.languageProvider.language);

	const onSubmit = values => {
		const data = {
			...values,
			logo: uploaded[0] || formik.values.logo,
		};
		dispatch(updateCompany(company.id, data, history, formik.setErrors));
	};

	useEffect(() => {
		const modCompany = { ...company, category_id: company?.category?.id };
		formik.setValues({
			...modCompany,
			country: modCompany.country?.iso2 || modCompany.country,
			state: modCompany.state?.state_code || modCompany.state,
			city: modCompany.city?.name || modCompany.city,
			timezone: modCompany.timezone?.zone_name || modCompany.timezone,
		});
	}, [fetched]);

	useEffect(() => {}, []);
	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: onSubmit,
	});

	if (fetchingCompany) return <Loading />;

	return <Layout lang={lang} formik={formik} company={company} setUploaded={setUploaded}></Layout>;
}
