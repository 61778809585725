import React from "react";
import { FormControl, FormLabel, FormErrorMessage, Box, Tooltip } from "@chakra-ui/react";

import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import { getCountries } from "../../../../api/libs/countries";

export default function Countries({ formik, column }) {
	const lang = useSelector(state => state.languageProvider.language);

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		if (searchQuery && searchQuery.length < 2) {
			return null;
		}
		const response = await getCountries(
			formik.values.country.iso2 || formik.values.country,
			searchQuery,
			page,
		);

		if (formik.values.country && page === 1) {
			formik.setFieldValue("country", response.data[0]);
		}
		return {
			options: response.data,
			hasMore: response.data.length >= 1,
			additional: {
				page: searchQuery ? 2 : page + 1,
			},
		};
	};

	const onChange = option => {
		formik.setFieldValue("country", option);
		formik.setFieldValue("phone_number", `+${option.phone_code}`);
		formik.setFieldValue("state", "");
		formik.setFieldValue("city", "");
		formik.setFieldValue("timezone", "");
	};

	return (
		<FormControl
			display="grid"
			gridTemplateColumns={
				column ? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "2fr 8fr", "2fr 8fr"]
			}
			alignItems="center"
			isInvalid={formik.errors.country}
			id="country"
		>
			<FormLabel
				_focus={{
					color: "#1274BC",
					fontWeight: 700,
				}}
			>
				{lang === "de" ? "Land" : "Country"}
			</FormLabel>
			<Box display="flex" flexDirection="column">
				<Tooltip label={formik.errors.country} hasArrow arrowSize={15} bg="red.600">
					<AsyncPaginate
						theme={theme => ({
							...theme,
							borderRadius: 0,
						})}
						value={formik.values.country || ""}
						loadOptions={loadOptions}
						getOptionValue={option => option.iso2}
						getOptionLabel={option => option.name}
						onChange={onChange}
						placeholder={formik.values.country || "Select Country"}
						additional={{
							page: 1,
						}}
					/>
				</Tooltip>
				{/* <FormErrorMessage
          position={column ? "absolute" : "relative"}
          bottom="-1.2em"
        >
          {formik.errors.country}
        </FormErrorMessage> */}
			</Box>
		</FormControl>
	);
}
