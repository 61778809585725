import { apiCallAc } from "..";

export function getSubscriptions() {
	return apiCallAc.get(`/payment/company/subscribe`);
}

export function getSubscription(id) {
	return apiCallAc.get(`/payment/company/invoices?subscription_id=${id}`);
}

export function changeSubscription(data) {
	return apiCallAc.post(`/payment/company/subscribe/swap`, data);
}

export function stopSubscription(data) {
	return apiCallAc.delete(`/payment/company/subscribe`, { data });
}

export function continueSubscription(data) {
	return apiCallAc.post(`/payment/company/subscribe/resume`, data);
}
