import { IconButton, Button, Image } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CanvasButton({ icon, li = false, action = () => {}, active = true }) {
	return (
		<Button
			_last={{ marginLeft: "auto" }}
			pointerEvents="visible"
			cursor="pointer"
			outline="none"
			_active={{ outline: "none" }}
			_focus={{ outline: "none", boxShadow: "none" }}
			_hover={{
				bg: active ? "rgba(170,205,86, 0.7)" : "rgba(176,177,175, 0.7)",
			}}
			w="45px"
			h="45px"
			borderRadius="full"
			as={IconButton}
			aria-label="Canvas-Button"
			bg="rgba(176,177,175, 0.7)"
			icon={
				!li ? (
					<FontAwesomeIcon color="white" icon={icon} style={{ fontSize: "22px" }} />
				) : (
					<Image src={icon} color="white" icon="check" width="22px" minW="22px" maxH="22px" />
				)
			}
			variant="outline"
			onClick={active ? action : () => {}}
		/>
	);
}

export default CanvasButton;
