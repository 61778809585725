import React, { useState } from "react";
import { Box } from "@chakra-ui/layout";

import PlanCard from "./PlanCard";

function Content({
	plans,
	selectedPlan,
	cardLoading,
	handleChange,
	lang,
	active,
	type,
	onPlanClose,
	setPage,
	setActivePlan,
}) {
	const [selectedSlug, setSelectedSlug] = useState("");
	const [timing, setTiming] = useState("month");

	const b = plans.data;

	return (
		<Box width={"100%"} display="grid" gridTemplateColumns={["1fr", `repeat(4,1fr)`]} gridGap="1em">
			{Object.keys(b).map((key, i) => (
				<PlanCard
					setActivePlan={setActivePlan}
					planId={b[key].id}
					active={active}
					title={b[key].description}
					slug={b[key].slug}
					setSelectedSlug={setSelectedSlug}
					disabled={selectedSlug !== b[key].slug && selectedSlug !== ""}
					selected={selectedSlug === b[key].slug}
					handleChange={handleChange}
					planPrices={b[key].plan_prices}
					lang={lang}
					loading={cardLoading}
					onPlanClose={onPlanClose}
					setPage={setPage}
					index={i}
				/>
			))}
		</Box>
	);
}

export default Content;
