import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
	email: Yup.string().email().min(6).required(),
	password: Yup.string().min(6).required(),
	password_confirmation: Yup.string()
		.min(6)
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required(),
});
