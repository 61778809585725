import { notificationActions } from "../../actionTypes";

const initialState = {
	notification: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case notificationActions.UPDATE_NOTIFICATION: {
			return { ...state, ...initialState };
		}

		case notificationActions.UPDATE_NOTIFICATION_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				notification: action.payload,
			};
		}

		case notificationActions.UPDATE_NOTIFICATION_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
