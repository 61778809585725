import React from "react";
import { Box } from "@chakra-ui/react";

function MainContainer(props) {
	return (
		<Box w="100%" display="flex" borderTop="0.5px solid black" h="50px">
			{props.children}
		</Box>
	);
}

export default MainContainer;
