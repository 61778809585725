import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";

import ico from "../../../../../../../assets/builder/stickers_1.svg";

function CategoryBtn({ label, val, handleFilter }) {
	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			cursor="pointer"
			onClick={() => {
				handleFilter(val);
			}}
		>
			<Box w="100%" pt="3px" pb="3px">
				<Image src={ico} w="40px" m="auto" />
			</Box>
			<Text>{label}</Text>
		</Box>
	);
}

export default CategoryBtn;
