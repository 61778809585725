import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "../components/auth/login/";
import Register from "../components/auth/register";
import Verify from "../components/auth/verify-email";
import Recover from "../components/auth/forgot-password";
import Reset from "../components/auth/reset";

// import PublicRoute from "../utils/publicRoute";

function PublicRouter() {
	return (
		<Switch>
			<Route path="/login" component={Login} />
			<Route path="/register" component={Register} />
			<Route path="/email/verify" component={Verify} />
			<Route path="/recover-password" component={Recover} />
			<Route path="/password/reset" component={Reset} />
			<Redirect to="/login" />
		</Switch>
	);
}

export default PublicRouter;
