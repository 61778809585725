import { useState, useEffect } from "react";
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Text,
	VStack,
	Flex,
	useColorModeValue,
	Grid,
	Tooltip,
} from "@chakra-ui/react";
import InvoiceCreation from "../../../invoice/new";
import Subscription from "../../../Subscription";
import Checkout from "../../../payment";
import SelectInvoice from "../../../invoice/list/components/Content";
import Company from "../../../../components/select-company";
import Form from "../../../../components/new-company/components/new-comp/Form";
import { useDispatch, useSelector } from "react-redux";
import { createCompany } from "../../../../store/actions";
import { validate } from "../../../../components/new-company/validate";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import Loading from "../../../shared/Loading";
import EditInvoice from "../../../invoice/edit/EditInvoice";
import EditCompany from "../EditCompany";
import Confirm from "../../../confirm";

const Wizard = (props, lang) => {
	let bgButtonLight = useColorModeValue("gray.50", "white");
	let hoverLight = useColorModeValue({ bg: "gray.50" }, { bg: "white" });
	let activeLight = useColorModeValue({ bg: "gray.50" }, { bg: "white" });
	let colorButtonLight = "gray.700";
	let bgButtonDark = useColorModeValue("gray.700", "blue.500");
	let hoverDark = useColorModeValue({ bg: "gray.800" }, { bg: "blue.600" });
	let activeDark = useColorModeValue({ bg: "gray.700" }, { bg: "blue.500" });
	let colorButtonDark = "white";

	const [page, setPage] = useState(0);
	// 0 about - 1 invoice - 2 create invoice - 3 pricing - 4 checkout - 5 edit invoice - 6 edit company - 7 accept payment
	const dispatch = useDispatch();
	const history = useHistory();

	const [isEditActive, setIsEditActive] = useState(false);

	const [activeEdit, setActiveEdit] = useState(-1);

	const [tmpPlan, setTmpPlan] = useState(false);

	const { user } = useSelector(state => state.currentUser.user);

	const handleSubmit = (values, e, { setErrors }) => {
		e.preventDefault();
		dispatch(createCompany(values, setErrors, history));
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			email: user.email,
		},
		validate: validate,
		onSubmit: handleSubmit,
		validateOnBlur: false,
		validateOnChange: false,
	});

	useEffect(() => {
		const path = new URL(window.location.href).pathname;
		if (window.location.href.split("/").pop() == "invoices") {
			setPage(1);
			console.log("invoices from url");
		}
		if (window.location.href.split("/").pop() == "subscription") {
			setPage(3);
			console.log("invoices from url");
		}
		if (path == "/confirm") {
			setPage(7);
		}
	}, []);

	const [step, setStep] = useState(1);
	// const url = window.location.pathname;

	// useEffect(() => {
	//   if (url === "/create-company") {
	//     setStep(1);
	//   } else if (url === "/invoices" || url === "/invoices/new") {
	//     console.log("u are on invaces")
	//     setStep(2);
	//   } else if (url === "/subscription" || url === "/subscriptions") {
	//       if (step === 2) {
	//         window.location.reload();
	//       }
	//     setStep(3);
	//   } else if (url === "/checkout") {
	//     setStep(4);
	//   } else {
	//   }
	// }, [url]);

	// useEffect(() => {
	//   if (localStorage.getItem("activeInvoice")) {
	//     setPage(1)
	//   }
	// }, []);

	const renderAboutStep = () => {
		return (
			<>
				{/* // if url is create-company then render company else render props.children else if localstorage has activeCompany then render props.children else render company
          // page === 0 ? (
          // props.children
          // ) : localStorage.getItem("activeCompany") ? (
          //   <Company />
          // ) : (
          //   <Company />
          // )
          // tu activecompany araa mashin gamoitanos props.childreni tuara company comp */}
				{page === 0 &&
					(!localStorage.getItem("activeCompany") ? props.children : <Company setPage={setPage} />)}
				{page === 6 && <EditCompany />}
				<Flex
					direction={{ sm: "column", lg: "row" }}
					justifyContent="space-between"
					alignItems="center"
					mt="24px"
				>
					{
						// check if activeCompany exists in  localstorage and if it does, render the button else add tooltip on button
						localStorage.getItem("activeCompany") ? (
							<Button
								onClick={() => setPage(page + 1)}
								type="submit"
								bg={bgButtonDark}
								color={colorButtonDark}
								fontSize="xs"
								variant="no-hover"
								borderRadius="8px"
								px="30px"
								_hover={hoverDark}
								_active={activeDark}
								alignSelf="flex-end"
								mt="25px"
								w={{ sm: "75px", lg: "100px" }}
								h="35px"
							>
								Next
							</Button>
						) : (
							<Button
								onClick={() => setPage(page + 1)}
								type="submit"
								disabled={true}
								bg={bgButtonDark}
								color={colorButtonDark}
								fontSize="xs"
								variant="no-hover"
								borderRadius="8px"
								px="30px"
								_hover={{
									_after: {
										content: `"Please create a company"`,
										position: "absolute",
										top: "-70%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										background: "red",
										color: "white",
										fontSize: "sm",
										padding: "4px",
										borderRadius: "md",
										whiteSpace: "nowrap",
									},
								}}
								_active={activeDark}
								alignSelf="flex-end"
								mt="24px"
								w={{ sm: "75px", lg: "100px" }}
								h="35px"
							>
								Next
							</Button>
						)
					}
				</Flex>
			</>
		);
	};

	// if url is invoices/new and activeinvoice is set in localstorage rerender page once and set step to 2
	// useEffect(() => {
	//   if (url === "/invoices/new" && localStorage.getItem("activeInvoice")) {
	//     window.location.reload();
	//     setStep(2);
	//   }
	// }, []);

	const renderAccountStep = () => {
		//  if actveInvoice is set in localstorage then render the subscription component else render the invoice component
		// and step is 2 show the loading component for 2 seconds and then render the invoice component
		return (
			<>
				{/* {page === 1
        ? props.children
        : page === 2
        ? <InvoiceCreation />
        : localStorage.getItem("activeInvoice")
        ? page === 2
          ? <>
             <SelectInvoice />
            </>
          : <SelectInvoice />
        : <SelectInvoice />
      } */}
				{page === 1 && <SelectInvoice setActiveEdit={setActiveEdit} setPage={setPage} />}
				{page === 2 && <InvoiceCreation setPage={setPage} />}
				{page === 5 && <EditInvoice activeEdit={activeEdit} setPage={setPage} />}
				<Flex
					direction={{ sm: "column", lg: "row" }}
					justifyContent="space-between"
					alignItems="center"
					mt="24px"
				>
					<Button
						alignSelf="flex-end"
						mt="24px"
						w={{ sm: "75px", lg: "100px" }}
						h="35px"
						bg={bgButtonLight}
						color={colorButtonLight}
						fontSize="xs"
						variant="no-hover"
						borderRadius="8px"
						px="30px"
						_hover={hoverLight}
						_active={activeLight}
						onClick={() => {
							page === 1 ? setPage(0) : setPage(1);
						}}
						mr={3}
					>
						Back
					</Button>
					{
						// if activeInvoice is in localstorage then render this
						localStorage.getItem("activeInvoice") ? (
							<Button
								type="submit"
								bg={bgButtonDark}
								color={colorButtonDark}
								fontSize="xs"
								variant="no-hover"
								borderRadius="8px"
								px="30px"
								_hover={hoverDark}
								_active={activeDark}
								alignSelf="flex-end"
								mt="24px"
								w={{ sm: "75px", lg: "100px" }}
								h="35px"
								onClick={() => setPage(3)}
							>
								Next
							</Button>
						) : (
							<Button
								onClick={() => setPage(3)}
								type="submit"
								disabled={true}
								bg={bgButtonDark}
								color={colorButtonDark}
								fontSize="xs"
								variant="no-hover"
								borderRadius="8px"
								px="30px"
								_hover={{
									_after: {
										content: `"Please create a invoice"`,
										position: "absolute",
										top: "-70%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										background: "red",
										color: "white",
										fontSize: "sm",
										padding: "4px",
										borderRadius: "md",
										whiteSpace: "nowrap",
									},
								}}
								_active={activeDark}
								alignSelf="flex-end"
								mt="24px"
								w={{ sm: "75px", lg: "100px" }}
								h="35px"
							>
								Next
							</Button>
						)
					}
				</Flex>
			</>
		);
	};
	const renderInvoicesStep = () => {
		return (
			<>
				{/* {page === 3 || page === 3 ? props.children : <Subscription />} */}
				{page === 3 && <Subscription setTmpPlan={setTmpPlan} setPage={setPage} />}
				<Flex
					direction={{ sm: "column", lg: "row" }}
					justifyContent="space-between"
					alignItems="center"
					mt="24px"
				>
					<Button
						alignSelf="flex-end"
						mt="24px"
						w={{ sm: "75px", lg: "100px" }}
						h="35px"
						bg={bgButtonLight}
						color={colorButtonLight}
						fontSize="xs"
						variant="no-hover"
						borderRadius="8px"
						px="30px"
						_hover={hoverLight}
						_active={activeLight}
						onClick={() => setPage(1)}
						mr={3}
					>
						Back
					</Button>
					{
						// if selectedPlan is in localstorage then render this
						tmpPlan ? (
							<Button
								type="submit"
								bg={bgButtonDark}
								color={colorButtonDark}
								fontSize="xs"
								variant="no-hover"
								borderRadius="8px"
								px="30px"
								_hover={hoverDark}
								_active={activeDark}
								alignSelf="flex-end"
								mt="24px"
								w={{ sm: "75px", lg: "100px" }}
								h="35px"
								onClick={() => setPage(page + 1)}
							>
								Next
							</Button>
						) : (
							<Button
								onClick={() => setPage(page + 1)}
								type="submit"
								disabled={true}
								bg={bgButtonDark}
								color={colorButtonDark}
								fontSize="xs"
								variant="no-hover"
								borderRadius="8px"
								px="30px"
								_hover={{
									_after: {
										content: `"Please create a subscription"`,
										position: "absolute",
										top: "-70%",
										left: "50%",
										transform: "translate(-50%, -50%)",
										background: "red",
										color: "white",
										fontSize: "sm",
										padding: "4px",
										borderRadius: "md",
										whiteSpace: "nowrap",
									},
								}}
								_active={activeDark}
								alignSelf="flex-end"
								mt="24px"
								w={{ sm: "75px", lg: "100px" }}
								h="35px"
							>
								Next
							</Button>
						)
					}
				</Flex>
			</>
		);
	};
	const renderPricingStep = () => {
		return (
			<>
				{/* {page === 4 ? props.children : <Checkout />} */}
				{page === 4 && <Checkout setPage={setPage} />}
				<Flex
					direction={{ sm: "column", lg: "row" }}
					justifyContent="space-between"
					alignItems="center"
					mt="24px"
				>
					<Button onClick={() => setPage(page - 1)} mr={3}>
						Back
					</Button>
					<Button type="submit">Submit</Button>
				</Flex>
			</>
		);
	};

	const renderConfirmPaymend = () => {
		return (
			<>
				<Confirm />
			</>
		);
	};

	const renderPage = () => {
		if (page === 0) {
			return renderAboutStep();
		} else if (page === 1) {
			return renderAccountStep();
		} else if (page === 2) {
			return renderAccountStep();
		} else if (page === 3) {
			if (localStorage.getItem("activeInvoice")) {
				return renderInvoicesStep();
			} else {
				// Handle case where activeInvoice is not in local storage
			}
		} else if (page === 4) {
			return renderPricingStep();
		} else if (page === 5) {
			return renderAccountStep();
		} else if (page === 6) {
			return renderAboutStep();
		} else if (page === 7) {
			return renderConfirmPaymend();
		}
	};

	return (
		<Box w={"85%"} margin={"0 auto"} mt={10}>
			{/* rendering about steps {step} */}
			<Flex align="center">
				<Flex direction="column" align="center" onClick={() => setPage(0)} cursor={"pointer"}>
					<Box
						w="8px"
						h="8px"
						borderRadius="50%"
						bg={step >= 1 ? "blue.500" : "gray.500"}
						mb={2}
					></Box>
					<Text fontWeight="bold" color={step >= 1 ? "blue.500" : "gray.500"}>
						About
					</Text>
				</Flex>
				<Box flex="1" h="1px" bg={page >= 2 ? "blue.500" : "gray.400"} mx={3}></Box>

				{/* rendering invoice steps if activeCompany exists in localstorage */}
				{localStorage.getItem("activeCompany") ? (
					<Flex direction="column" align="center" onClick={() => setPage(1)} cursor={"pointer"}>
						<Box
							w="8px"
							h="8px"
							borderRadius="50%"
							bg={page >= 2 ? "blue.500" : "gray.500"}
							mb={2}
						></Box>
						<Text fontWeight="bold" color={step >= 2 ? "blue.500" : "gray.500"}>
							Invoice
						</Text>
					</Flex>
				) : (
					<Flex
						direction="column"
						align="center"
						cursor={"pointer"}
						position="relative"
						_hover={{
							_after: {
								content: `"Please create a company"`,
								position: "absolute",
								top: "-70%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								background: "red.500",
								color: "white",
								fontSize: "sm",
								padding: "4px",
								borderRadius: "md",
								whiteSpace: "nowrap",
							},
						}}
					>
						<Box
							w="8px"
							h="8px"
							borderRadius="50%"
							bg={page >= 2 ? "blue.500" : "gray.500"}
							mb={2}
						></Box>
						<Text fontWeight="bold" color={step >= 2 ? "blue.500" : "gray.500"}>
							Invoice
						</Text>
					</Flex>
				)}
				<Box flex="1" h="1px" bg={page >= 3 ? "blue.500" : "gray.400"} mx={3}></Box>
				{/* rendering pricing steps if activeCompany and activeInvoice exists in localstorage */}
				{localStorage.getItem("selectedPlan") ? (
					<Flex direction="column" align="center" onClick={() => setPage(3)} cursor={"pointer"}>
						<Box
							w="8px"
							h="8px"
							borderRadius="50%"
							bg={page >= 2 ? "blue.500" : "gray.500"}
							mb={2}
						></Box>
						<Text fontWeight="bold" color={page >= 2 ? "blue.500" : "gray.500"}>
							Pricing
						</Text>
					</Flex>
				) : (
					<Flex
						direction="column"
						align="center"
						cursor={"pointer"}
						position="relative"
						_hover={{
							_after: {
								content: `"Please create a company and invoice"`,
								position: "absolute",
								top: "-70%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								background: "red.500",
								color: "white",
								fontSize: "sm",
								padding: "4px",
								borderRadius: "md",
								whiteSpace: "nowrap",
							},
						}}
					>
						<Box
							w="8px"
							h="8px"
							borderRadius="50%"
							bg={page >= 3 ? "blue.500" : "gray.500"}
							mb={2}
						></Box>
						<Text fontWeight="bold" color={page >= 3 ? "blue.500" : "gray.500"}>
							Pricing
						</Text>
					</Flex>
				)}
				<Box flex="1" h="1px" bg={page >= 4 ? "blue.500" : "gray.400"} mx={3}></Box>
				{/* rendering checkout steps if activeCompany, activeInvoice and activeAddress exists in localstorage */}
				{localStorage.getItem("activeCompany") &&
				localStorage.getItem("selectedPlan") &&
				localStorage.getItem("activeInvoice") ? (
					<Flex direction="column" align="center" onClick={() => setPage(4)} cursor={"pointer"}>
						<Box
							w="8px"
							h="8px"
							borderRadius="50%"
							bg={page >= 4 ? "blue.500" : "gray.500"}
							mb={2}
						></Box>
						<Text fontWeight="bold" color={page >= 4 ? "blue.500" : "gray.500"}>
							Checkout
						</Text>
					</Flex>
				) : (
					<Flex
						direction="column"
						align="center"
						cursor={"pointer"}
						position="relative"
						_hover={{
							_after: {
								content: `"Please create a company, invoice and address"`,
								position: "absolute",
								top: "-70%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								background: "red.500",
								color: "white",
								fontSize: "sm",
								padding: "4px",
								borderRadius: "md",
								whiteSpace: "nowrap",
							},
						}}
					>
						<Box
							w="8px"
							h="8px"
							borderRadius="50%"
							bg={page >= 4 ? "blue.500" : "gray.500"}
							mb={2}
						></Box>
						<Text fontWeight="bold" color={page >= 4 ? "blue.500" : "gray.500"}>
							Checkout
						</Text>
					</Flex>
				)}
			</Flex>
			<VStack
				marginTop={"5vh"}
				height={"80vh"}
				justifyContent="space-between"
				spacing={4}
				overflow={["auto", "auto", "hidden"]}
				// add responsive height
			>
				{renderPage()}
			</VStack>
		</Box>
	);
};

export default Wizard;
