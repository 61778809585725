import React from "react";
import { Box } from "@chakra-ui/react";
import { GoogleMap } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";

function Map({ formik, showMap, locationId, isLoaded, empty }) {
	const containerStyle = {
		width: "100%",
		marginTop: "1em",
		height: "70vh",
	};

	const center = {
		lat: parseFloat(formik.values.latitude),
		lng: parseFloat(formik.values.longitude),
	};

	const [map, setMap] = React.useState(null);

	const onLoad = map => {};

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);

	return (
		<Box overflowY="hidden">
			{empty ? (
				<>Incorrect address.</>
			) : isLoaded && (showMap || locationId) ? (
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={center}
					zoom={15}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					<Marker
						draggable={true}
						onDragEnd={i => {
							formik.setFieldValue("latitude", i.latLng.lat());
							formik.setFieldValue("longitude", i.latLng.lng());
						}}
						position={center}
					></Marker>
					<></>
				</GoogleMap>
			) : (
				<>Fill street field on location tab.</>
			)}
		</Box>
	);
}

export default Map;
