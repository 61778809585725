import React from "react";
import { FormControl, FormLabel, FormErrorMessage, Box, Tooltip } from "@chakra-ui/react";

import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import { getCities } from "../../../../api/libs/countries";

export default function Cities({ formik, column }) {
	const lang = useSelector(state => state.languageProvider.language);

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		if (searchQuery && searchQuery.length < 3) {
			return null;
		}
		const response = await getCities(
			formik.values.city.name || formik.values.city,
			searchQuery,
			formik.values.country.iso2 || formik.values.country,
			formik.values.state.state_code || formik.values.state,
			page,
		);
		if (formik.values.city && page === 1) {
			formik.setFieldValue("city", response.data[0]);
		}
		return {
			options: response.data,
			hasMore: response.next_page_url,
			additional: {
				page: searchQuery ? 2 : page + 1,
			},
		};
	};

	const onChange = option => {
		formik.setFieldValue("city", option);
	};

	return (
		<FormControl
			display="grid"
			gridTemplateColumns={
				column ? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "2fr 8fr", "2fr 8fr"]
			}
			alignItems="center"
			isInvalid={formik.errors.city}
			id="city"
		>
			<FormLabel
				_focus={{
					color: "#1274BC",
					fontWeight: 700,
				}}
			>
				{lang === "de" ? "City" : "City"}
			</FormLabel>
			<Box display="flex" flexDirection="column">
				<Tooltip label={formik.errors.country} hasArrow arrowSize={15} bg="red.600">
					<AsyncPaginate
						key={JSON.stringify(formik.values.state)}
						theme={theme => ({
							...theme,
							borderRadius: 0,
						})}
						isDisabled={
							typeof formik.values.state !== "object" || typeof formik.values.country !== "object"
						}
						value={formik.values.city || ""}
						loadOptions={loadOptions}
						getOptionValue={option => option.name}
						getOptionLabel={option => option.name}
						onChange={onChange}
						placeholder={
							formik.values.city !== ""
								? formik.values.city.name
								: formik.values.state
								? "Select City"
								: "Select State First"
						}
						additional={{
							page: 1,
						}}
					/>
				</Tooltip>
				{/* <FormErrorMessage
          position={column ? "absolute" : "relative"}
          bottom="-1.2em"
        >
          {formik.errors.city}
        </FormErrorMessage> */}
			</Box>
		</FormControl>
	);
}
