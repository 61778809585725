import toast from "react-hot-toast";
import { stopSubscription } from "../../../api/libs/paypal";
import { paypalActions } from "../../actionTypes";
import { fetchPaypalSubscriptions } from "./fetchPaypalSubscriptions";
export const cancelPaypalSubscription = data => {
	return async dispatch => {
		dispatch({
			type: paypalActions.CANCEL_PAYPAL_SUBSCRIPTION,
		});
		toast.promise(stopSubscription(data), {
			loading: "Pausing subscription...",
			success: res => {
				dispatch({
					type: paypalActions.CANCEL_PAYPAL_SUBSCRIPTION_FULFILLED,
					payload: res.data,
				});
				dispatch(fetchPaypalSubscriptions());
				return <b>Paused subscription</b>;
			},
			error: err => {
				dispatch({
					type: paypalActions.CANCEL_PAYPAL_SUBSCRIPTION_REJECTED,
					payload: err.response,
				});
				return <b>Can't pause subscription</b>;
			},
		});
	};
};
