import { locationActions } from "../../actionTypes";
import { addHours } from "../../../api/libs/locations";
import toast from "react-hot-toast";

export const createHours = (data, setErrors, history) => {
	return async dispatch => {
		dispatch({
			type: locationActions.CREATE_HOURS,
		});
		addHours(data)
			.then(res => {
				dispatch({
					type: locationActions.CREATE_HOURS_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully edited hours");
				history.push("/locations");
			})
			.catch(err => {
				setErrors(err.response.data.errors);
				const errors = Object.keys(err.response.data.errors).map(
					key => err.response.data.errors[key][0],
				);
				setErrors({ open_hours: errors });
				toast.error(errors[0]);
				dispatch({
					type: locationActions.CREATE_HOURS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
