import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Subscription from "../components/Subscription";
import Checkout from "../components/payment";
import Confirm from "../components/confirm";
import PaypalSuccess from "../components/paypal/success";
import PaypalCancel from "../components/paypal/cancel";
import Wizard from "../components/auth/register/components/Wizard";
function UnpaidRouter() {
	return (
		<Wizard>
			<Switch>
				<Route path="/subscription" component={Subscription} />
				<Route path="/checkout" component={Checkout} />
				<Route path="/confirm" component={Confirm} />
				<Route
					path="/payment/company/paypal/subscribe/redirect/success"
					component={PaypalSuccess}
				/>
				<Route path="/payment/company/paypal/subscribe/redirect/cancel" component={PaypalCancel} />
				{/* <Redirect to="/subscription" /> */}
			</Switch>
		</Wizard>
	);
}

export default UnpaidRouter;
