import { uploadBase64, uploadMedia, uploadVideo } from "../api/libs/upload";

const toFile = async url => {
	let file = await fetch(url).then(r => r.blob());
	return file;
};

/*
const checkForPano = async resImage => {
	console.log(resImage);
	if (!resImage.image) {
		const reader = new FileReader();
		reader.onload = theFile => {
			const image = new Image();
			image.src = theFile.target.result;
			image.onload = async () => {
				const { width, height } = image;
				if (width / height === 2) {
					return true;
					// let url = URL.createObjectURL(resImage);
					// let pano = await toFile(url);
					// console.log("RECIVED PANO", pano);
					// const formData = new FormData();
					// formData.append("image[0]", pano);
					// let res = await uploadMedia(formData);
					// console.log("[RECIVED IMAGE]:", res.data.images[0]);
					// newGallery.push(res.data.images[0]);
				} else {
					return false;
					// newGallery.push(resImage.image);
				}
			};
		};
		reader.readAsDataURL(resImage);
	} else {
		return false;
	}
};
*/

export default async function newGallery(gallery) {
	if (gallery.length > 0) {
		let newGallery = [];
		for (let i = 0; i < gallery.length; i++) {
			if (typeof gallery[i] === "object") {
				if (gallery[i].type?.toString().includes("video")) {
					let url = URL.createObjectURL(gallery[i]);
					let video = await toFile(url);
					const formData = new FormData();
					formData.append("video", video);
					let res = await uploadVideo(formData);

					if (res.data.encoding === "started") {
						newGallery.push(res.data.video);
					}
				} else {
					// console.log("checkForPano");
					// let isPano = await checkForPano(gallery[i]);
					// console.log("isPano:", isPano);
					// if (isPano) {
					let url = URL.createObjectURL(gallery[i]);
					console.log("url", url);
					let pano = await toFile(url);
					console.log("RECIVED PANO", pano);
					const formData = new FormData();
					formData.append("image[0]", pano);
					let res = await uploadMedia(formData);
					console.log("[RECIVED IMAGE]:", res.data.images[0]);
					newGallery.push(res.data.images[0]);
					// } else {
					//   newGallery.push(gallery[i].image);
					// }
					// const reader = new FileReader();
					// reader.onload = (theFile) => {
					//   const image = new Image();
					//   image.src = theFile.target.result;
					//   image.onload = async () => {
					//     const { width, height } = image;
					//     if (width / height === 2) {
					//       let url = URL.createObjectURL(gallery[i]);
					//       let pano = await toFile(url);
					//       console.log("RECIVED PANO", pano);
					//       const formData = new FormData();
					//       formData.append("image[0]", pano);
					//       let res = await uploadMedia(formData);
					//       console.log("[RECIVED IMAGE]:", res.data.images[0]);
					//       newGallery.push(res.data.images[0]);
					//     } else {
					//       newGallery.push(gallery[i].image);
					//     }
					//   };
					// };
					// reader.readAsDataURL(gallery[i]);
				}
			} else {
				if (gallery[i].includes("data:image")) {
					const formData = new FormData();
					formData.append("image[0]", gallery[i]);
					let res = await uploadBase64(formData, false, false);

					newGallery.push(res.data.images[0]);
				} else if (gallery[i].includes("https")) {
					let sliced = gallery[i].slice(gallery[i].lastIndexOf("/") + 1);

					newGallery.push(sliced);
				} else {
					newGallery.push(gallery[i]);
				}
			}
		}
		console.log("[OUTPUT NEW GALLERY:]", newGallery);
		return newGallery;
	}
	return gallery;
}
