import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { initStripePayment } from "../../../store/actions";
import { initPaypal } from "../../../api/libs/plans";

function Form({ history }) {
	const dispatch = useDispatch();
	const elements = useElements();
	const selectedPlan = useSelector(state => state.selectedPlan.selectedPlan);

	const stripe = useStripe();

	useEffect(() => {
		Object.keys(selectedPlan).length === 0 && history.push("/subscription");
	}, []);

	const handleSubmit = e => {
		e.preventDefault();
		stripe
			.confirmSetup({
				elements,
				confirmParams: {
					// return_url: "http://localhost:3000/confirm",
					return_url: "https://demo-dashboard.yezzgo.de/confirm",
				},
			})
			.then(function (result) {
				console.log("result", result);
				// let data = {
				//   plan_price_id: selectedPlan.id,
				//   stripe_token: result.setupIntent.payment_method,
				//   invoice_location_id: localStorage.getItem("activeInvoice"),
				// };
				// console.log("success payment");
				// dispatch(initStripePayment(data, history));
				// if (result.error) {
				//   // Inform the customer that there was an error.
				// }
			});
	};
	return (
		<Box flex="1" display="flex" flexDirection="column">
			<form style={{ flex: 1 }} onSubmit={handleSubmit}>
				<Box display="flex" flexDirection="column">
					<PaymentElement />
					<Box
						as="button"
						w={"100%"}
						h={"100%"}
						mr={2}
						p={1}
						bg="#1074bc"
						color="white"
						_hover={{ bg: "#1074bc99" }}
						type="submit"
						disabled={!stripe}
						_disabled={{ bg: "#1074bc99" }}
						mb="2em"
					>
						Checkout
					</Box>
				</Box>
			</form>
			<Box
				as="button"
				w={"100%"}
				h={"100%"}
				mr={2}
				p={1}
				bg="#1074bc"
				color="white"
				_hover={{ bg: "#1074bc99" }}
				onClick={() => {
					initPaypal({
						plan_price_id: localStorage.getItem("selectedPlan"),
					}).then(res => {
						window.location.href = res.data.href;
					});
				}}
			>
				PAYPAL
			</Box>
		</Box>
	);
}

export default Form;
