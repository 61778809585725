import React, { useState } from "react";
import { Stack, VStack, Grid, Box } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { content } from "./content";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";

function Form({ loading, lang, formik }) {
	const t = content[lang];
	const history = useHistory();

	const [canSeePass, setCanSeePass] = useState(false);

	return (
		<VStack w={"full"} h={"60vh"} justify={"center"} align={["center", "flex-end"]}>
			<Box as="form" mr={["0", "20%"]} w={["auto", "40%"]}>
				<Box
					width={["90vw", "auto"]}
					bg="rgba(0,0,0,0.3)"
					mb={3}
					p={4}
					color="white"
					borderWidth="2px"
					borderColor="white"
				>
					<Stack spacing={4}>
						<PrimaryInput
							borderColor="#000"
							labelBackground="#231F1F"
							labelAlign="center"
							_focus={{ borderColor: "#000" }}
							label={t.email}
							type="text"
							id="email"
							containerHeight="2em"
							formik={formik}
						/>
						<PrimaryInput
							borderColor="#000"
							labelBackground="#231F1F"
							labelAlign="center"
							containerHeight="2em"
							_focus={{ borderColor: "#000" }}
							label={t.password}
							id="password"
							formik={formik}
							type={canSeePass ? "text" : "password"}
							rightIcon={canSeePass ? "eye-slash" : "eye"}
							rightIconAction={() => {
								setCanSeePass(!canSeePass);
							}}
						/>
					</Stack>
				</Box>

				<Grid
					bg="rgba(0,0,0,0.5)"
					templateColumns={["1fr", "repeat(3, 1fr)"]}
					gap={3}
					p={4}
					color="white"
					borderWidth="2px"
					borderColor="white"
				>
					<PrimaryButton
						bg="#231F1F"
						type="submit"
						label={loading ? "loading..." : t.login}
						onClick={formik.handleSubmit}
						disabled={loading}
					/>

					<PrimaryButton
						bg="#231F1F"
						label={t.forget}
						onClick={() => history.push("/recover-password")}
						disabled={loading}
					/>

					<PrimaryButton
						bg="#231F1F"
						label={t.register}
						onClick={() => history.push("/register")}
						disabled={loading}
					/>
				</Grid>
			</Box>
		</VStack>
	);
}

export default Form;
