import React from "react";
import Layout from "./components/Layout";
import Player from "./components/Player";

function VideoPlayer({ slide }) {
	let url = typeof slide.video === "string" ? slide.video : URL.createObjectURL(slide.video);
	return (
		<Layout>
			<Player media={url} />
		</Layout>
	);
}

export default VideoPlayer;
