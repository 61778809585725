export const content = {
	en: {
		header: "Coupons",
		client: "Create coupon",
		desc: "Description",
		ctitle: "Title",
		start_at: "Start at",
		end_at: "End at",
		location: "Location",
		locations: "Locations",
		confirm: "Confirm",
		reset: "Reset",
		value: "Value",
		coupon_code: "Coupon code",
		type: "Type",
		publish: "Publish",
		previous_price: "Previous price",
		limited_amount: "Limited Amount",
		new_price: "New Price",
		post_end_at: "Post end at",
	},
	de: {
		header: "Gutscheine",
		client: "Gutschein erstellen",
		desc: "Beschreibung",
		ctitle: "Titel Mandant",
		start_at: "Beginne bei",
		end_at: "Ende um",
		location: "Standort",
		locations: "Standorte",
		confirm: "Bestätigen",
		reset: "Zurücksetzten",
		value: "Wert",
		coupon_code: "Gutscheincode",
		type: "Typ",
		publish: "Veröffentlichen",
		previous_price: "Vorheriger Preis",
		limited_amount: "Begrenzte Menge",
		new_price: "Neuer Preis",
		post_end_at: "Beitragsende um",
	},
};
