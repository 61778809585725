import { subActions } from "../../actionTypes";
import { paypalCancel } from "../../../api/libs/plans";
import toast from "react-hot-toast";
import { fetchCompany } from "../company";
import { fetchInvoiceLocations } from "../invoice";

export const onPaypalCancel = query => {
	return async dispatch => {
		dispatch({
			type: subActions.PAYPAL_CANCEL,
		});
		paypalCancel(query)
			.then(res => {
				toast.success("Success");
				dispatch({
					type: subActions.PAYPAL_CANCEL_FULFILLED,
					payload: res,
				});
				dispatch(fetchCompany(localStorage.getItem("activeCompany")));
				dispatch(fetchInvoiceLocations());
			})
			.catch(err => {
				dispatch({
					type: subActions.PAYPAL_CANCEL_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
