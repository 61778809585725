export const getThumb = name => {
	return `https://static.yezzgo.de/images/thumbs/${name}`;
};

export const getLogoes = name => {
	return `https://static.yezzgo.de/images/logos/${name}`;
};

export const getOriginal = name => {
	return `https://static.yezzgo.de/images/original/${name}`;
};

export const getAvatar = name => {
	return `https://static.yezzgo.de/images/avatars/${name}`;
};

export const getQR = name => {
	return `https://static.yezzgo.de/images/qr/${name}`;
};

export const getVideo = name => {
	return `https://static.yezzgo.de/videos/story/${name}`;
};

export const getMobThumb = name => {
	return `https://static.yezzgo.de/mobile/images/thumb/${name}`;
};
export const getMobOriginal = name => {
	return `https://static.yezzgo.de/mobile/images/original/${name}`;
};
