import React, { useState, useEffect } from "react";
import { FormControl, FormLabel, FormErrorMessage, Button, Text } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { getDescendantProp } from "../../../utils/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Thead, Tbody, Tr, Th, Td, Image } from "@chakra-ui/react";
import Modal from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents, fetchLocations, fetchNewses } from "../../../store/actions";
import { getOriginal } from "../../../utils/imgUri";
import Loading from "../Loading";
export default function OptionSelect({
	label,
	type,
	id,
	header,
	column,
	formik,
	setUploaded,
	labelBackground,
	labelAlign,
	containerHeight,
	customChange,
	labels,
	values,
	reset,
	couponId,
	fields = [],
	...rest
}) {
	const dispatch = useDispatch();

	//news
	const { newses, fetched: fetchedNewses } = useSelector(state => state.fetchNewses);

	//events

	const { events, fetched: fetchedEvents } = useSelector(state => state.fetchEvents);

	//locations
	const { locations, fetched: fetchedLocations } = useSelector(state => state.fetchLocations);

	const [modalState, setModalState] = useState(false);
	useEffect(() => {
		switch (formik.values[id]) {
			case "event":
				if (!fetchedEvents) dispatch(fetchEvents("published", 1));
				break;
			case "news":
				if (!fetchedNewses) dispatch(fetchNewses("published", 1, true, couponId));
				break;
			case "location":
				if (!fetchedLocations) dispatch(fetchLocations());
				break;
			default:
				break;
		}
	}, [formik.values[id]]);

	const handleReset = () => formik.setFieldValue("data_id", "");

	const handleSelect = (funcID, color) => {
		formik.setFieldValue("data_id", funcID);
	};
	let dataToMap = [];
	switch (formik.values[id]) {
		case "event":
			dataToMap = events;
			break;
		case "news":
			dataToMap = newses;
			break;
		case "location":
			dataToMap = locations;
			break;
		default:
			break;
	}

	return (
		<FormControl
			display="grid"
			gridTemplateColumns={
				column ? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "2fr 8fr", "2fr 8fr"]
			}
			mt="0.5em"
			alignItems="center"
			gridGap={labelBackground && "1em"}
			isInvalid={getDescendantProp(formik.errors, id) && getDescendantProp(formik.touched, id)}
			id={id}
			position="relative"
		>
			{modalState && (
				<Modal
					header={header}
					handleClose={() => {
						setModalState(false);
					}}
				>
					<Box height="100%" display="flex" flexDirection="column">
						<Box pb="3em" flexGrow="1" height="75vh" overflow="scroll" position="relative">
							{formik.values[id] === "news" && !fetchedNewses && <Loading height={true} />}
							{formik.values[id] === "event" && !fetchedEvents && <Loading height={true} />}
							{formik.values[id] === "location" && !fetchedLocations && <Loading height={true} />}
							<Table variant="simple">
								<Thead
									zIndex="2"
									boxShadow="0 3px 0px -2px black"
									position="sticky"
									top="0"
									backgroundColor="#FFF"
								>
									<Tr>
										{fields.map((fieldName, key) => (
											<Th fontSize="1.2em" key={key}>
												{fieldName.includes(".")
													? fieldName.split(".")[1]
													: fieldName.replaceAll("_", " ")}
											</Th>
										))}
									</Tr>
								</Thead>
								<Tbody>
									{dataToMap?.data?.map(item => {
										const color = getDescendantProp(formik.values, "data_id").includes(item.id);
										return (
											<Tr
												cursor="pointer"
												onClick={() => handleSelect(item.id, color)}
												_hover={{
													backgroundColor: color ? "#AFDA09" : "#F2F1F0",
												}}
												backgroundColor={color ? "#AFCA09" : "#FFF"}
												key={item.id}
											>
												{fields.map((fieldName, key) =>
													fieldName === "image" ||
													fieldName === "logo" ||
													fieldName === "user.avatar" ||
													fieldName === "avatar" ? (
														<Td py="1em" key={key}>
															<Image
																border="0.5px solid black"
																borderRadius="100%"
																height="4em"
																width="4em"
																objectFit="contain"
																src={getOriginal(getDescendantProp(item, fieldName))}
															/>
														</Td>
													) : (
														<Td py="1em" key={key}>
															{getDescendantProp(item, fieldName)}
														</Td>
													),
												)}
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</Box>
						<Box
							py="0.5em"
							flexGrow="1"
							borderTop="2px solid #7C7C7C"
							display="grid"
							gridTemplateColumns="1fr 1fr"
							gridGap="1em"
						>
							<Box display="flex" justifyContent="flex-end" alignItems="center">
								<Text mr="1em">Confirm</Text>
								<Button
									width="3em"
									height="3em"
									borderRadius="100%"
									justifySelf="flex-end"
									_hover={{ bg: "#9BC962" }}
									_focus={{ bg: "#B0D352" }}
									_active={{ bg: "#B0D352" }}
									bg="#D7D7D6"
									onClick={() => {
										setModalState(false);
									}}
								>
									<FontAwesomeIcon color="#FFF" size="lg" icon="check" />
								</Button>
							</Box>
							<Box display="flex" alignItems="center">
								<Button
									onClick={handleReset}
									_hover={{ bg: "#AAAAAD" }}
									_focus={{ bg: "#6C6C6E" }}
									_active={{ bg: "#6C6C6E" }}
									width="3em"
									borderRadius="100%"
									bg="#D7D7D6"
									height="3em"
								>
									<FontAwesomeIcon color="#FFF" size="lg" icon="undo" />
								</Button>
								<Text ml="1em">Reset</Text>
							</Box>
						</Box>
					</Box>
				</Modal>
			)}
			<FormLabel
				fontFamily="Segoe UI Semilight"
				_focus={{
					color: labelBackground ? "#FFF" : "#1274BC",
					fontWeight: 700,
				}}
				height={labelBackground && "2em"}
				m={labelBackground && "0"}
				display={labelBackground && "flex"}
				width={labelBackground && "100%"}
				alignItems={labelBackground && "center"}
				justifyContent={labelBackground && "center"}
				backgroundColor={labelBackground}
				textAlign={labelAlign}
			>
				{label}
			</FormLabel>
			<Box display="flex" flexDirection="column">
				<Box display="grid" gridTemplateColumns={["1fr", `repeat(${values.length}, 1fr)`]}>
					{values.map((val, index) => (
						<Button
							key={index}
							px="4"
							py="0"
							height="2em"
							borderRadius="0px"
							borderColor="#CBCCCB"
							borderWidth="1px"
							backgroundColor={formik.values[id] === val ? "#0E65AE" : "transparent"}
							boxShadow="none"
							_hover={{ backgroundColor: "#0E65AE", color: "#FFF" }}
							_focus={{
								backgroundColor: "#0E65AE",
								borderColor: formik.errors[id] ? "red" : "#1274BC",
							}}
							color={formik.values[id] === val ? "#FFF" : "#000"}
							placeholder={label}
							_placeholder={{ color: "rgba(0,0,0,0.5)" }}
							onClick={() => {
								if (reset.length) {
									reset.map(item => formik.setFieldValue(item, ""));
								}

								if (val !== formik.values.data_type) {
									formik.setFieldValue("data_id", "");
								}
								formik.setFieldValue(id, val);

								if (fields.length) setModalState(true);
							}}
							{...rest}
						>
							{labels[index]}
						</Button>
					))}
				</Box>
				<FormErrorMessage position={column ? "absolute" : "relative"} bottom="-1.2em">
					{getDescendantProp(formik.errors, id)}
				</FormErrorMessage>
			</Box>
		</FormControl>
	);
}
