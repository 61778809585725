import { keywordActions } from "../../actionTypes";
import { addKeyword } from "../../../api/libs/keywords";

export const createKeyword = (data, setErrors, formik) => {
	return async dispatch => {
		dispatch({
			type: keywordActions.CREATE_KEYWORD,
		});
		addKeyword(data)
			.then(res => {
				dispatch({
					type: keywordActions.CREATE_KEYWORD_FULFILLED,
					payload: res,
				});

				dispatch({
					type: keywordActions.ADD_KEYWORD,
					payload: res,
				});
				formik.setFieldValue("keywords", [...formik.values.keywords, res.data]);
			})
			.catch(err => {
				dispatch({
					type: keywordActions.CREATE_KEYWORD_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
