import { notificationActions } from "../../actionTypes";
import { updateNotification as updateNotificationFunction } from "../../../api/libs/notifications";
import toast from "react-hot-toast";
import { fetchNotifications, fetchNotificationsCount } from ".";

export const updateNotification = (id, seen) => {
	return async dispatch => {
		dispatch({
			type: notificationActions.UPDATE_NOTIFICATION,
		});
		updateNotificationFunction(id, seen)
			.then(res => {
				dispatch({
					type: notificationActions.UPDATE_NOTIFICATION_FULFILLED,
					payload: res.data,
				});
				dispatch(fetchNotificationsCount());
				dispatch(fetchNotifications());
			})
			.catch(err => {
				toast.error("Error");
				dispatch({
					type: notificationActions.UPDATE_NOTIFICATION_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
