import React, { useState, useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";

import Button from "./Button";

function FilterItem({ img, onApply, onRemove, canvas, name }) {
	const [loaded, setLoaded] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const onLoad = () => {
		setLoaded(true);
	};

	const checkIfApplied = () => {
		if (canvas) {
			if (canvas.getActiveObject()) {
				let ao = canvas.getActiveObject();
				if (ao.type === "image") {
					let bool = false;
					for (let i = 0; i < ao.filters.length; i++) {
						if (ao.filters[i].type === name) {
							bool = true;
							setIsActive(true);
							return;
						}
					}
					return bool;
				}
			}
		}
	};
	useEffect(() => {
		checkIfApplied();
	}, [isActive]);

	return (
		<Box pos="relative" h="auto" w="48%" mb="10px">
			{!loaded && <Skeleton height="100%" width="100%" />}
			<Image
				src={require(`../../../../../../../assets/builder/filters/${img}`).default}
				onLoad={onLoad}
				userSelect="none"
				draggable={false}
			/>
			{loaded && (
				<Box
					pos="absolute"
					bottom="0"
					left="0"
					width="100%"
					display="flex"
					justifyContent="right"
					px="5px"
					pb="5px"
				>
					{isActive ? (
						<Button
							icon={"times"}
							action={() => {
								setIsActive(false);
								onRemove();
							}}
						/>
					) : (
						<Button
							icon={"check"}
							action={() => {
								if (canvas) {
									if (canvas.getActiveObject()) {
										if (canvas.getActiveObject().type === "image") {
											setIsActive(true);
										}
									}
								}
								onApply();
							}}
						/>
					)}
				</Box>
			)}
		</Box>
	);
}

export default FilterItem;
