import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiCallAc, setActiveCompanyToken } from "../../../api";
import { fetchPushNotifications } from "../../../store/actions";
import { content } from "./components/content";
import Layout from "./components/Layout";

function PushNotificationsList() {
	const dispatch = useDispatch();

	//Fetch company before fetching events
	const company = useSelector(state => state.fetchCompany.company);
	//Fetch events
	const {
		pushNotifications,
		fetching,
		fetched: fetchedPushNotifications,
		pushNotifications: { current_page, next_page_url },
	} = useSelector(state => state.fetchPushNotifications);
	//Refetch after changing language
	const lang = useSelector(state => state.languageProvider.language);
	//Refetch after creating new event

	useEffect(() => {
		setActiveCompanyToken(company.id);
		if (apiCallAc.defaults.headers.common && !fetchedPushNotifications) {
			dispatch(fetchPushNotifications(1));
		}
	}, [company, lang]);

	const t = content[lang];
	return (
		<Layout
			loading={fetching}
			header={t.header}
			pushNotifications={pushNotifications}
			lang={lang}
			next_page_url={next_page_url}
			current_page={current_page}
			fetchPushNotifications={fetchPushNotifications}
			fetched={fetchedPushNotifications}
		/>
	);
}

export default PushNotificationsList;
