import { eventActions } from "../../actionTypes";

const initialState = {
	event: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case eventActions.FETCH_EVENT: {
			return { ...initialState, fetching: true };
		}

		case eventActions.FETCH_EVENT_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				event: action.payload,
			};
		}

		case eventActions.FETCH_EVENT_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
