import React from "react";
import { Box } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import Tabbar from "./Tabbar";
import { content } from "./lang";
function Layout({ lang, ...props }) {
	const t = content[lang];
	return (
		// <PublicLayout>
		<Box maxW={["100%", "500px"]} w={["80%", "50%"]} mr="auto" ml="auto">
			<Heading my={5} fontSize="24px" fontWeight="500">
				{t.select_company}
			</Heading>

			<Tabbar lang={lang} />
			{props.children}
		</Box>

		// </PublicLayout>
	);
}

export default Layout;
