import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { onPaypalSuccess } from "../../../store/actions";
import Loading from "../../shared/Loading";

export default function PaypalSuccess() {
	const { search } = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(onPaypalSuccess(search, history));
	}, []);

	return <Loading />;
}
