import React, { useState, useEffect } from "react";
import ScrollContainer from "../components/ScrollContainer";
import Button from "./components/Button";

import {
	onToogleUnderline,
	onToogleLinethrough,
	onToUpperCase,
	onToLowerCase,
	onSuperScript,
	onSubScript,
} from "../../../../../../../../utils/textboxActions";

function Decorations({ state, canvas }) {
	const [underline, setUnderline] = useState(false);
	const [linethrough, setLinethrough] = useState(false);

	useEffect(() => {
		if (state.activeObject) {
			setUnderline(state.activeObject.underline);
			setLinethrough(state.activeObject.linethrough);
		}
	}, [state.activeObject]);
	return (
		<ScrollContainer>
			<Button
				type="underline"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={underline}
				handleClick={() => {
					onToogleUnderline(canvas, state);
					setUnderline(!underline);
				}}
			/>

			<Button
				type="strikethrough"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={linethrough}
				handleClick={() => {
					onToogleLinethrough(canvas, state);
					setLinethrough(!linethrough);
				}}
			/>

			<Button
				type="font"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				handleClick={() => {
					onToUpperCase(canvas, state);
				}}
				secondary="level-up-alt"
			/>

			<Button
				type="font"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				handleClick={() => {
					onToLowerCase(canvas, state);
				}}
				secondary="level-down-alt"
			/>

			<Button
				type="superscript"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				handleClick={() => {
					onSuperScript(canvas, state);
				}}
			/>
			<Button
				type="subscript"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				handleClick={() => {
					onSubScript(canvas, state);
				}}
			/>
		</ScrollContainer>
	);
}

export default Decorations;
