import { galleryActions } from "../../actionTypes";

const initialState = {
	gallery: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case galleryActions.FETCH_GALLERY:
			return { ...initialState, fetching: true };
		case galleryActions.FETCH_GALLERY_FULFILLED:
			return {
				gallery: action.payload,
				error: false,
				fetching: false,
				fetched: true,
			};
		case galleryActions.FETCH_GALLERY_REJECTED:
			return { ...state, fetching: false, error: action.payload };
		default:
			return state;
	}
};

export default Reducer;
