import { eventActions } from "../../actionTypes";
import { restoreEvent } from "../../../api/libs/events";
import toast from "react-hot-toast";

export const recoverEvent = (id, history) => {
	return async dispatch => {
		dispatch({
			type: eventActions.RECOVER_EVENT,
		});
		restoreEvent(id)
			.then(res => {
				dispatch({
					type: eventActions.RECOVER_EVENT_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully recovered event");
				history.push(`/events/edit/${id}`);
			})
			.catch(err => {
				dispatch({
					type: eventActions.RECOVER_EVENT_REJECTED,
					payload: err.response,
				});
			});
	};
};
