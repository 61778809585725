import React, { useRef } from "react";
import { Box } from "@chakra-ui/react";
import { content } from "../components/content";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import ModalSelect from "../../../shared/inputs/ModalSelect";
import OptionSelect from "../../../shared/inputs/OptionSelect";
import DateInput from "../../../shared/inputs/date-input";
import CustomLocation from "../../../shared/inputs/CustomLocation";

function Client({ formik, lang, couponId, newsSelector = true, maxWidth = "auto" }) {
	const boxRef = useRef();
	const t = content[lang];

	return (
		<Box maxWidth={maxWidth}>
			<PrimaryInput column={true} label={t.ctitle} type="text" id="title" formik={formik} />

			<Box
				ref={boxRef}
				display="grid"
				gridTemplateColumns={[
					"1fr",
					boxRef.current
						? boxRef.current.clientWidth < 600
							? "1fr 1fr"
							: "1fr 1fr 2fr 2fr"
						: "1fr 1fr",
				]}
				gridGap="0.5em"
			>
				<PrimaryInput
					column={true}
					label={t.value}
					disabled={formik.values.type === "free"}
					type={formik.values.type === "money" ? "number" : "text"}
					id={formik.values.type === "money" ? "money_value" : "percentage_value"}
					step="0.01"
					formik={formik}
					onChange={e =>
						formik.values.type === "money"
							? formik.setFieldValue("money_value", Math.round(e.target.value * 100) / 100)
							: formik.setFieldValue("percentage_value", Math.round(e.target.value))
					}
				/>
				<OptionSelect
					formik={formik}
					label="Typ"
					id="type"
					column={true}
					values={["money", "percentage", "free"]}
					reset={["money_value", "percentage_value", "previous_price"]}
					labels={["€", "%", "FREE"]}
				/>

				<PrimaryInput
					column={true}
					label={t.previous_price}
					type="number"
					step="0.01"
					id="previous_price"
					formik={formik}
					onChange={e =>
						formik.setFieldValue("previous_price", Math.round(e.target.value * 100) / 100)
					}
				/>

				<PrimaryInput
					column={true}
					label={t.new_price}
					disabled
					value={
						formik.values.previous_price &&
						(formik.values.percentage_value || formik.values.money_value)
							? formik.values.type === "percentage"
								? formik.values.percentage_value >= 100
									? "Prozentwert muss niedriger sein"
									: Math.round(
											((formik.values.previous_price * (100 - formik.values.percentage_value)) /
												100) *
												100,
									  ) / 100
								: formik.values.previous_price - formik.values.money_value < 0
								? "Der vorherige Preis muss höher sein als der Wert"
								: Math.round((formik.values.previous_price - formik.values.money_value) * 100) / 100
							: "0"
					}
					type="text"
					id="new_price"
					formik={formik}
				/>
			</Box>
			{newsSelector && <CustomLocation formik={formik} />}
			<Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="0.5em">
				<PrimaryInput
					column={true}
					label={t.coupon_code}
					type="text"
					id="coupon_code"
					formik={formik}
				/>
				<PrimaryInput
					column={true}
					label={t.limited_amount}
					type="number"
					id="limited_amount"
					formik={formik}
				/>
			</Box>
			<OptionSelect
				formik={formik}
				label="Visible for"
				id="visible_for"
				column={true}
				reset={[]}
				values={newsSelector ? ["all", "subscribed", "chosen_ones"] : ["all", "subscribed"]}
				labels={
					newsSelector
						? lang === "en"
							? ["All", "Subscribed", "Chosen ones"]
							: ["Alle", "Gezeichnet", "Auserwählte"]
						: lang === "en"
						? ["All", "Subscribed"]
						: ["Alle", "Gezeichnet"]
				}
			/>
			{formik.values.visible_for === "chosen_ones" ? (
				<ModalSelect
					formik={formik}
					id="user.id"
					fields={["user.avatar", "user.name", "user.surname"]}
					label={lang === "en" ? "Users" : "Benutzer"}
					header="Select users"
					type="users"
				/>
			) : formik.values.custom_location.reduce(
					(acc, element) => acc + element.coupon_coordinates.length,
					0,
			  ) === 0 ? (
				newsSelector && (
					<ModalSelect
						formik={formik}
						id="news.id"
						fields={["logo", "title", "coupon_count", "post_end_at"]}
						label={lang === "en" ? "News" : "Nachrichten"}
						header="Select news"
						type="news"
						couponId={couponId}
					/>
				)
			) : null}
			<DateInput label={t.post_end_at} formik={formik} id="post_end_at" />
		</Box>
	);
}

export default Client;
