import { pushNotificationActions } from "../../actionTypes";
import { getNotifiableUsers } from "../../../api/libs/pushNotifications";

export const fetchNotifiableUsers = page => {
	return async dispatch => {
		dispatch({
			type: pushNotificationActions.FETCH_NOTIFIABLE_USERS,
		});
		getNotifiableUsers(page)
			.then(res => {
				dispatch({
					type: pushNotificationActions.FETCH_NOTIFIABLE_USERS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: pushNotificationActions.FETCH_NOTIFIABLE_USERS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
