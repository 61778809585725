import { userActions } from "../../actionTypes";
import { resetPassword } from "../../../api/libs/user";
import toast from "react-hot-toast";

export const reset = (data, setErrors) => {
	return async dispatch => {
		dispatch({
			type: userActions.RESET_PASSWORD,
		});
		resetPassword(data)
			.then(res => {
				if (res.success) {
					dispatch({
						type: userActions.RESET_FULLFILLED,
					});
					toast.success("Success");
				}
			})
			.catch(err => {
				dispatch({
					type: userActions.RESET_REJECTED,
					payload: err.response,
				});
				setErrors(err.response.data.errors);
			});
	};
};
