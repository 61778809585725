import { stripeActions } from "../../actionTypes";
import { getSubscription } from "../../../api/libs/stripe";

export const fetchStripeSubscription = id => {
	return async dispatch => {
		dispatch({
			type: stripeActions.FETCH_STRIPE_SUBSCRIPTION,
		});
		getSubscription(id)
			.then(res => {
				dispatch({
					type: stripeActions.FETCH_STRIPE_SUBSCRIPTION_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: stripeActions.FETCH_STRIPE_SUBSCRIPTION_REJECTED,
					payload: err.response,
				});
			});
	};
};
