import apiCall, { apiCallAc } from "..";

export function getPlans() {
	return apiCall.get("/payment/payplan?type=company&limitations=true");
}

export function getPlanById(id) {
	return apiCall.get(`/payment/payplan/${id}?type=company`);
}

export function getUserPlan() {
	return apiCall.get(`/dashboard/userPayInfo`);
}

export function stripePayment(data) {
	return apiCallAc.post(`/payment/company/subscribe`, JSON.stringify(data));
}

export function initPaypal(data) {
	return apiCallAc.post(`/payment/company/paypal/subscribe`, data);
}

export function paypalSuccess(query) {
	return apiCallAc.get(`/payment/company/paypal/subscribe/redirect/success${query}`);
}

export function paypalCancel(query) {
	return apiCallAc.get(`/payment/company/paypal/subscribe/redirect/cancel${query}`);
}
