import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
	title: Yup.string().required(),
	sub_title: Yup.string().required(),
	street: Yup.string().min(5).required(),
	street_number: Yup.string().required(),
	postal_code: Yup.string().min(2).required(),
	gallery: Yup.array().min(1).required(),
	phone_number: Yup.string().required(),
	email: Yup.string().required(),
	web_page: Yup.string().required(),
	official_name: Yup.string().required(),
	description_title: Yup.string().required(),
	category_id: Yup.string().required(),
	latitude: Yup.string().required(),
	longitude: Yup.string().required(),
	logo: Yup.string().required(),
	keywords: Yup.array().min(1).required(),
	description: Yup.string().required(),
	open_hours: Yup.array()
		.of(
			Yup.object().shape({
				day_id: Yup.number(),
				hours: Yup.array().of(
					Yup.object().shape({
						open_at: Yup.string(),
						close_at: Yup.string(),
					}),
				),
			}),
		)
		.test("atone", "rame", function (days) {
			const dat = days.map(day => day.hours.filter(item => !item.close_at || !item.open_at).length);
			const dat_two = days.map(
				day => day.hours.filter(item => !item.close_at && !item.open_at).length,
			);

			if (!days.some(day => day.hours.some(item => item.close_at && item.open_at))) return false;
			else return dat.filter(i => i === 1).length === dat_two.filter(i => i === 1).length;
		}),
});
