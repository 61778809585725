import { editorActions } from "../../actionTypes";

export const setLastUsedColor = color => {
	return dispatch => {
		dispatch({
			type: editorActions.SET_LAST_USED_COLOR,
			payload: color,
		});
	};
};
