import { paypalActions } from "../../actionTypes";
import { getSubscriptions } from "../../../api/libs/paypal";

export const fetchPaypalSubscriptions = (page, reset) => {
	return async dispatch => {
		dispatch({
			type: paypalActions.FETCH_PAYPAL_SUBSCRIPTIONS,
			payload: { reset },
		});
		getSubscriptions(page)
			.then(res => {
				dispatch({
					type: paypalActions.FETCH_PAYPAL_SUBSCRIPTIONS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: paypalActions.FETCH_PAYPAL_SUBSCRIPTIONS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
