import React, { useState } from "react";
import { applyFilter, removeFilter } from "../../../utils/objectActions";

import Layout from "./components/Layout";
import WrapLayout from "../components/WrapLayout";
import FilterItem from "./components/FilterItem";

function Filters({ canvas }) {
	const [dataCollection] = useState(() => require("./filters.json"));
	const { filters } = dataCollection;
	const [filtersCollection, setFiltersCollection] = useState(() => filters);

	const renderFiltersList = () => {
		if (filtersCollection) {
			return filtersCollection.map((i, k) => (
				<FilterItem
					key={k}
					canvas={canvas}
					img={i.ex}
					name={i.name}
					onApply={() => {
						applyFilter(canvas, i.name);
					}}
					onRemove={() => {
						removeFilter(canvas, i.name);
					}}
				/>
			));
		}
	};

	return (
		<Layout>
			{canvas.getActiveObject()?.type === "image" ? (
				<WrapLayout>{renderFiltersList()}</WrapLayout>
			) : (
				"Select image to apply filter"
			)}
		</Layout>
	);
}

export default Filters;
