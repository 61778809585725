import React from "react";
import { Box } from "@chakra-ui/react";

function Layout(props) {
	return (
		<Box
			pos="fixed"
			top={0}
			left={0}
			zIndex={100}
			display="flex"
			flexDir="column"
			alignItems="center"
			h="100%"
			w="calc(100vw)"
			bg="white"
		>
			{props.children}
		</Box>
	);
}

export default Layout;
