import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createMessage } from "../../store/actions";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";
import Client from "./panels/Client";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function ChatRoom() {
	const { id } = useParams();
	const { company } = useSelector(state => state.fetchCompany);
	const dispatch = useDispatch();

	useEffect(() => {
		formik.setValues(initialValues);
	}, []);

	function handleSubmit(values) {
		dispatch(
			createMessage(id, {
				message: values.message,
				dashboard_id: company.id,
			}),
		);
		formik.resetForm();
	}

	// if new chatroom is created update list

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false,
	});

	return <Client formik={formik} handleSubmit={handleSubmit} />;
}

export default ChatRoom;
