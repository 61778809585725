import React from "react";
import Layout from "./component/Layout";

import PanelButtons from "./component/PanelButtons";
import TextDecoration from "./component/TextDecoration";
import Alignment from "./component/alignment";

import Fonts from "./panels/fonts";
import ColorPicker from "./panels/color-picker";

const FontEditor = ({ canvas, state, activePanel, setActivePanel }) => {
	// const [activePanel, setActivePanel] = useState("FONT_EDIT");
	const PanleView = () => {
		switch (activePanel) {
			case "FONT_EDIT":
				return <Fonts state={state} canvas={canvas} />;
			case "COLOR_PICKER":
				return <ColorPicker state={state} canvas={canvas} />;

			default:
				return <Fonts state={state} canvas={canvas} />;
		}
	};

	return (
		<Layout>
			<PanleView />
			<div className="custom-scroll" style={{ overflowY: "auto", paddingBottom: 50 }}>
				<Alignment
					state={state}
					canvas={canvas}
					activePanel={activePanel}
					setActivePanel={setActivePanel}
				/>
				<TextDecoration state={state} canvas={canvas} />
				<PanelButtons state={state} activePanel={activePanel} setActivePanel={setActivePanel} />
			</div>
		</Layout>
	);
};

export default FontEditor;
