import React from "react";
import Selector from "./components/Selector";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../../store/actions";

function LanguageSelector({ ...rest }) {
	const dispatch = useDispatch();
	const lang = useSelector(state => state.languageProvider.language);
	const set = lang => {
		dispatch(setLanguage(lang));
	};

	return <Selector {...rest} current={lang} set={set} />;
}

export default LanguageSelector;
