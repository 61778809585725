import { useDisclosure } from "@chakra-ui/hooks";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLogs } from "../../../store/actions";
import Loading from "../../shared/Loading";
import Layout from "./components/Layout";

function Logs() {
	const dispatch = useDispatch();
	const [currentId, setCurrentId] = useState(0);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const {
		logs,
		fetched,
		fetching,
		logs: { current_page, next_page_url, prev_page_url, per_page },
	} = useSelector(state => state.fetchLogs);
	const lang = useSelector(state => state.languageProvider.language);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		dispatch(fetchLogs(currentPage));
	}, [lang, currentPage]);

	if (!fetched) return <Loading />;

	return (
		<Layout
			fetching={fetching}
			currentId={currentId}
			setCurrentId={setCurrentId}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			logs={logs.data}
			lang={lang}
			next_page_url={next_page_url}
			prev_page_url={prev_page_url}
			setCurrentPage={setCurrentPage}
			current_page={current_page}
			per_page={per_page}
		/>
	);
}

export default Logs;
