import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logUser } from "../../../store/actions/";
import Note from "../../shared/Note";
import Layout from "./components/Layout";
import Form from "./components/Form";
import { useFormik } from "formik";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";

function Login({ history, ...props }) {
	const lang = useSelector(state => state.languageProvider.language);
	const { fetching, user } = useSelector(state => state.currentUser);
	const registered = useSelector(state => state.registerUser.success);
	const dispatch = useDispatch();

	const handleLogin = data => {
		dispatch(logUser(data));
	};

	useEffect(() => {
		localStorage.removeItem("activeCompany");
		localStorage.removeItem("activeInvoice");
		localStorage.removeItem("selectedPlan");
		localStorage.removeItem("activePlanID");
		if (user.access_token) {
			history.push("/dashboard");
		}
	}, [user.access_token]);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleLogin,
		validationSchema: validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		<Layout>
			<Form formik={formik} loading={fetching} lang={lang} />
			{registered ||
				(props.location?.state?.message && (
					<Note
						title="Email Verification"
						content={`Email has been sent. \n Please verify email first. `}
					/>
				))}
		</Layout>
	);
}

export default Login;
