import React from "react";

import { Box } from "@chakra-ui/react";

function Layout(props) {
	return (
		<Box
			position="absolute"
			top="0"
			left="0"
			h="540px"
			w="540px"
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			backgroundColor="white"
		>
			{props.children}
		</Box>
	);
}

export default Layout;
