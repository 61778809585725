import React from "react";
import { Box, Spinner, Image } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ForLogo({ state, setState, fileInput, onInputImg }) {
	return (
		<Box display="flex" flexDir="column" h="540px" p="10px">
			<Box
				display="flex"
				flexDir="column"
				justifyContent="center"
				alignItems="center"
				h="20%"
				mb="10px"
			>
				{state.imageLoading ? (
					<Spinner color="green" />
				) : (
					<Box
						border="1px dashed #D6D6D5"
						textAlign="center"
						display="flex"
						alignItems="center"
						justifyContent="center"
						cursor="pointer"
						_active={{ bg: "rgba(245,245,245,0.3)", borderWidth: 0 }}
						onClick={() => {
							onInputImg(setState, fileInput);
						}}
						width="40%"
						p={"20px"}
					>
						<FontAwesomeIcon icon="plus" style={{ fontSize: "30" }} color="#AACD56" />
					</Box>
				)}
			</Box>
			<Box w="100%" h="1px" background="#D6D6D5" />
			<Box display="flex" flexDir="column" justifyContent="center" alignItems="center" h="100%">
				{state.liveImg && (
					<Image
						h="60%"
						src={state.liveImg}
						alt={`slide-board}`}
						borderRadius="50%"
						border="1px solid rgba(0,0,0,0.1)"
					/>
				)}
			</Box>
		</Box>
	);
}

export default ForLogo;
