import React, { useEffect } from "react";
import { fetchLogsA } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { Box, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { keysIn } from "lodash";
import Loading from "../shared/Loading";

function LogModal({ refer }) {
	const dispatch = useDispatch();
	const { logs, fetching } = useSelector(state => state.fetchLogs);

	useEffect(() => {
		dispatch(fetchLogsA(refer));
	}, []);

	const renderColumn = data => {
		let arr = [];
		for (let key in data) {
			arr.push(data[key]);
		}
		return arr;
	};

	const renderRaw = () => {
		let data = logs?.location?.data;
		if (data) {
			return data.map((i, k) => {
				let tds = renderColumn(i);
				return (
					<Tr key={k.toString()}>
						{tds?.map((i, k) => (
							<Td key={k.toString()}>{typeof i !== "object" ? i : "[object]"}</Td>
						))}
					</Tr>
				);
			});
		}
	};

	const renderTableHeader = () => {
		let data = logs?.location?.data;
		if (data) {
			let keys = Object.keys(data[0]);
			return keys.map((i, k) => <Th key={k.toString()}>{i}</Th>);
		} else return null;
	};

	if (fetching) return <Loading />;

	if (!logs[refer]) return null;

	return (
		<Box overflow={"auto"} width="100%">
			<Table>
				<Thead>
					<Tr>{renderTableHeader()}</Tr>
				</Thead>
				<Tbody>{renderRaw()}</Tbody>
			</Table>
		</Box>
	);
}

export default LogModal;
