import { userActions } from "../../actionTypes";
import { getLogs } from "../../../api/libs/user";

export const fetchLogs = page => {
	return async dispatch => {
		dispatch({
			type: userActions.LOGS_FETCHING,
		});
		getLogs(page)
			.then(res => {
				dispatch({
					type: userActions.LOGS_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				dispatch({
					type: userActions.LOGS_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
