import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
	title: Yup.string().required(),

	type: Yup.string().required(),
	money_value: Yup.string().when("type", {
		is: "money",
		then: Yup.string().required("You must enter money value"),
	}),
	percentage_value: Yup.string()
		.nullable()
		.when("type", {
			is: "percentage",
			then: Yup.string().required("You must enter percentage value value"),
		}),
});
