import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SlideReorder({ variant, slides, state, moveSlide }) {
	let activeSlide = state.activeSlide;
	if (variant === "logo" || slides.length < 2) return null;
	return (
		<Box display="flex" mb="15px">
			<Button
				outline="none"
				_active={{ outline: "none" }}
				_focus={{ outline: "none", boxShadow: "none" }}
				isDisabled={activeSlide === 0}
				mr="5px"
				borderRadius="15px"
				border="0.5px solid #AECB2D"
				onClick={() => {
					moveSlide(activeSlide, "left");
				}}
			>
				<FontAwesomeIcon icon="arrow-left" />
			</Button>
			<Button
				outline="none"
				_active={{ outline: "none" }}
				_focus={{ outline: "none", boxShadow: "none" }}
				isDisabled={activeSlide === slides.length - 1}
				borderRadius="15px"
				border="0.5px solid #AECB2D"
				onClick={() => {
					moveSlide(activeSlide, "right");
				}}
			>
				<FontAwesomeIcon icon="arrow-right" />
			</Button>
		</Box>
	);
}

export default SlideReorder;
