import { companyActions } from "../../actionTypes";
import { updateCompany as handleUpdateCompany } from "../../../api/libs/company";
import toast from "react-hot-toast";

export const updateCompany = (id, data, history, setErrors) => {
	return async dispatch => {
		dispatch({
			type: companyActions.COMPANY_UPDATE_FETCHING,
		});
		toast.promise(handleUpdateCompany(id, data), {
			loading: "Updating company...",
			success: res => {
				dispatch({
					type: companyActions.COMPANY_UPDATE_FULFILLED,
					payload: res.success,
				});
				dispatch({
					type: companyActions.SET_CURRENT_COMPANY,
					payload: data,
				});

				dispatch({
					type: companyActions.SET_COMPANY,
					payload: data,
				});

				history.push("/locations");

				return <b>Company updated!</b>;
			},
			error: err => {
				dispatch({
					type: companyActions.COMPANY_UPDATE_REJECTED,
					payload: err.response,
				});
				setErrors(err.response.data.errors);
				return <b>Couldn't update company.</b>;
			},
		});
	};
};
