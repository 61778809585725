import { newsActions } from "../../actionTypes";

const initialState = {
	newses: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case newsActions.FETCH_NEWSES: {
			return action.payload.reset
				? { ...initialState, fetching: true }
				: { ...state, fetching: true };
		}

		case newsActions.FETCH_NEWSES_FULFILLED: {
			return state.newses.current_page < action.payload.current_page
				? {
						...state,
						fetching: false,
						fetched: true,
						newses: {
							...action.payload,
							data: state.newses.data
								? [...state.newses.data, ...action.payload.data]
								: action.payload.data,
						},
				  }
				: {
						...state,
						fetching: false,
						fetched: true,
						newses: action.payload,
				  };
		}

		case newsActions.FETCH_NEWSES_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
