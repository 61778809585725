import React, { useEffect, useState, memo } from "react";

import ModalLayout from "./components/ModalLayout";

import Texts from "./Texts";
import Layers from "./Layers";
import Stickers from "./Stickers";
import Filters from "./Filters";
import Templates from "./Templates";

function Modal({ state, setState, canvas, variant }) {
	const type = state.modal.type;
	const [header, setHeader] = useState("");
	const canvasCache = React.useMemo(() => canvas);

	useEffect(() => {
		if (type === "fonts") {
			setHeader("Fonts");
		}
		if (type === "layers") {
			setHeader("Layers");
		}
		if (type === "stickers") {
			setHeader("Stickers");
		}
		if (type === "filters") {
			setHeader("Filters");
		}
		if (type === "templates") {
			setHeader("Templates");
		}
	}, [type]);
	const [activePanel, setActivePanel] = useState("FONT_EDIT");
	const ModalCotent = () => {
		switch (type) {
			case "fonts": {
				return (
					<Texts
						canvas={canvas}
						state={state}
						activePanel={activePanel}
						setActivePanel={setActivePanel}
					/>
				);
			}
			case "layers": {
				return <Layers {...{ state, setState, canvas, variant }} />;
			}
			case "stickers": {
				return <Stickers canvas={canvasCache} variant={variant} />;
			}
			case "filters": {
				return <Filters canvas={canvas} />;
			}
			case "templates": {
				return <Templates canvas={canvas} variant={variant} setState={setState} />;
			}

			default:
				return null;
		}
	};
	return (
		<ModalLayout {...{ header, setState }}>
			<ModalCotent />
		</ModalLayout>
	);
}

export default memo(Modal);
