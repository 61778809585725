import { languageActions } from "../../actionTypes";
import { setLanguageHeader } from "../../../api";

export const setLanguage = lang => {
	return dispatch => {
		if (lang === "en" || lang === "de") {
			setLanguageHeader(lang);
			window.localStorage.setItem("lang", lang);
			dispatch({
				type: languageActions.SET_LANGUAGE,
				payload: lang,
			});
		}
	};
};
