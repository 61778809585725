import { userActions } from "../../actionTypes";

const initialState = {
	status: false,
	loading: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case userActions.RESEND_EMAIL: {
			return { ...state, error: null, loading: true };
		}

		case userActions.RESEND_FULLFILLED: {
			return { ...state, loading: false, status: true };
		}

		case userActions.RESEND_REJECTED: {
			return { ...state, loading: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
