import { chatsActions } from "../../actionTypes";
import { fetchNext } from "../../../api/libs/chats";

export const fetchNextMessages = url => {
	return async dispatch => {
		dispatch({
			type: chatsActions.ADD_MESSAGE_TOP_START,
		});
		dispatch({
			type: chatsActions.FETCH_NEXT,
		});

		fetchNext(url)
			.then(res => {
				dispatch({
					type: chatsActions.FETCH_NEXT_FULFILLED,
					payload: res.data,
				});

				dispatch({
					type: chatsActions.ADD_MESSAGE_TOP,
					payload: res.data,
				});
			})
			.catch(err => {
				console.log(err);
				dispatch({
					type: chatsActions.FETCH_NEXT_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
