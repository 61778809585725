import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import PrimaryInput from "../../shared/inputs/PrimaryInput";
import CountrySelect from "../../shared/inputs/countrySelect";
import { useFormik } from "formik";
import { initialValues } from "../new/formInitialValues";
import { updateInvoiceLocation, fetchInvoiceLocation } from "../../../store/actions";
import { content } from "../new/components/lang";
import { useSelector } from "react-redux";
import PrimaryButton from "../../shared/buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { getInvoiceLocation } from "../../../api/libs/invoice";
import { useState } from "react";
import { getTimezones } from "../../../api/libs/countries";

const EditInvoice = ({ activeEdit, setPage }) => {
	const lang = useSelector(state => state.languageProvider.language);
	const dispatch = useDispatch();
	const [defaults, setDefaults] = useState({});
	const [errors, setErrors] = useState([]);
	const t = content[lang];

	useEffect(() => {
		getInvoiceLocation(activeEdit)
			.then(res => {
				setDefaults(res.data);
			})
			.catch(err => console.log(err));
	}, []);

	const defaultValues = async () => {
		if (defaults.id) {
			console.log(defaults);
			const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			const responseTimezone = await getTimezones(timezone);
			formik.setFieldValue("timezone", responseTimezone.data[0]);
			formik.setFieldValue("country", defaults.country);
			formik.setFieldValue("state", defaults.state);
			formik.setFieldValue("city", defaults.city);
			formik.setFieldValue("street", defaults.street);
			formik.setFieldValue("street_number", defaults.street_number);
			formik.setFieldValue("postal_code", defaults.postal_code);
			formik.setFieldValue("address_line_2", defaults.address_line_2);
			formik.setFieldValue("vat_number", defaults.vat_number);
		}
	};

	useEffect(() => {
		defaultValues();
	}, [defaults]);

	const handleSubmit = values => {
		const moddedValues = {
			...values,
			country: typeof values.country === "object" ? values.country.iso2 : values.country,
			state: typeof values.state === "object" ? values.state.state_code : values.state,
			city_id: typeof values.city === "object" ? values.city.id : values.city,
			timezone: typeof values.timezone === "object" ? values.timezone.zone_name : values.timezone,
			default: true,
		};
		dispatch(updateInvoiceLocation(activeEdit, moddedValues))
			.then(res => {
				if (res.payload.success) {
					setErrors([]);
					setPage(1);
				} else {
					setErrors(Object.values(res.payload));
				}
			})
			.catch(err => console.log(err));
	};

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		// validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false,
	});

	return (
		<>
			<Box pt="34px" pb="34px" display="flex" width="100%" flexDirection="column" as={"form"}>
				<CountrySelect formik={formik} column={true} />

				<Box display="grid" gridTemplateColumns={["1fr", "4fr 2fr 2fr 4fr"]} gridGap="1em">
					<Box>
						<PrimaryInput column={true} label={t.street} type="text" id="street" formik={formik} />
						{/* {errors.street && <Text fontSize={"14px"} color={"red.600"}>{errors.street}</Text>} */}
					</Box>
					<Box>
						<PrimaryInput
							column={true}
							label={t.street_number}
							type="text"
							id="street_number"
							formik={formik}
						/>
						{/* {errors.street_number && <Text fontSize={"14px"} color={"red.600"}>{errors.street_number}</Text>} */}
					</Box>
					<Box>
						<PrimaryInput
							column={true}
							label={t.postal_code}
							type="text"
							id="postal_code"
							formik={formik}
						/>
						{/* {errors.postal_code && <Text fontSize={"14px"} color={"red.600"}>{errors.postal_code}</Text>} */}
					</Box>
					<Box>
						<PrimaryInput
							column={true}
							label={t.address_line_2}
							type="text"
							id="address_line_2"
							formik={formik}
						/>
					</Box>
				</Box>
				<PrimaryInput
					column={true}
					label={t.vat_number}
					type="number"
					id="vat_number"
					formik={formik}
				/>
				{errors.length > 0 && (
					<Flex flexDir={"column"} gap={"10px"}>
						{errors.map(item => (
							<Text color={"red.600"} fontSize={"16px"}>
								{item[0]}
							</Text>
						))}
					</Flex>
				)}
				<PrimaryButton mt="1em" onClick={formik.handleSubmit} label={"Edit"} />
			</Box>
		</>
	);
};

export default EditInvoice;
