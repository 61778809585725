import React from "react";
import { Box } from "@chakra-ui/react";

const EditorLayout = props => {
	return (
		<Box position="relative" w="540px" h="540px">
			<Box display="flex" flexDir="column" h="540px" justifyContent="center" alignItems="center">
				<canvas id={`c-${props.variant}`} />
			</Box>
			{props.children}
		</Box>
	);
};

export default EditorLayout;
