import { notificationActions } from "../../actionTypes";
import { getNotifications } from "../../../api/libs/notifications";

export const fetchNotifications = page => {
	return async dispatch => {
		dispatch({
			type: notificationActions.FETCH_NOTIFICATIONS,
		});
		getNotifications(page)
			.then(res => {
				dispatch({
					type: notificationActions.FETCH_NOTIFICATIONS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: notificationActions.FETCH_NOTIFICATIONS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
