import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiCallAc, setActiveCompanyToken } from "../../../api";
import { fetchNewses } from "../../../store/actions";
import { content } from "./components/content";
import Layout from "./components/Layout";

function Newses() {
	const [fetchNewsesType, setFetchNewsesType] = useState("published");
	const [mapModal, setMapModal] = useState(false);
	const [chartsModal, setChartsModal] = useState({
		active: false,
		itemId: null,
	});

	const dispatch = useDispatch();

	const {
		newses,
		fetching,
		fetched: fetchedNews,
		newses: { current_page, next_page_url },
	} = useSelector(state => state.fetchNewses);
	const company = useSelector(state => state.fetchCompany.company);
	const fetched = useSelector(state => state.createNews.fetched);
	const lang = useSelector(state => state.languageProvider.language);

	const handleClose = () => {
		setMapModal(false);
		setChartsModal({
			active: false,
			itemId: null,
		});
	};

	useEffect(() => {
		dispatch({ type: "RESET_STATE" });
	}, []);

	useEffect(() => {
		setActiveCompanyToken(company.id);
		if (apiCallAc.defaults.headers.common && !fetchedNews) {
			dispatch(fetchNewses(fetchNewsesType, 1));
		}
	}, [company, fetched, lang, fetchNewsesType]);

	useEffect(() => {
		dispatch(fetchNewses(fetchNewsesType, 1, true));
	}, [fetchNewsesType]);
	const t = content[lang];

	return (
		<Layout
			fetchNewsesType={fetchNewsesType}
			loading={fetching}
			fetched={fetchedNews}
			setFetchNewsesType={setFetchNewsesType}
			mapModal={mapModal}
			chartsModal={chartsModal}
			setChartsModal={setChartsModal}
			handleClose={handleClose}
			setMapModal={setMapModal}
			header={t.header}
			newses={newses}
			lang={lang}
			next_page_url={next_page_url}
			current_page={current_page}
			fetchNewses={fetchNewses}
		/>
	);
}

export default Newses;
