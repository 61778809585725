import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiCallAc, setActiveCompanyToken } from "../../../api";
import { fetchCoupons } from "../../../store/actions";
import { content } from "./components/content";
import Layout from "./components/Layout";

function Coupons() {
	const [fetchCouponsType, setFetchCouponsType] = useState("published");
	const [mapModal, setMapModal] = useState(false);

	const dispatch = useDispatch();

	const {
		coupons,
		fetching,
		fetched: fetchedCoupons,
		coupons: { current_page, next_page_url },
	} = useSelector(state => state.fetchCoupons);
	const company = useSelector(state => state.fetchCompany.company);
	const fetched = useSelector(state => state.createNews.fetched);
	const lang = useSelector(state => state.languageProvider.language);

	const handleClose = () => {
		setMapModal(false);
	};

	useEffect(() => {
		setActiveCompanyToken(company.id);
		if (apiCallAc.defaults.headers.common && !fetchedCoupons) {
			dispatch(fetchCoupons(fetchCouponsType, 1));
		}
	}, [company, fetched, lang, fetchCouponsType]);
	useEffect(() => {
		dispatch(fetchCoupons(fetchCouponsType, 1, true));
	}, [fetchCouponsType]);
	const t = content[lang];

	return (
		<Layout
			fetchCouponsType={fetchCouponsType}
			loading={fetching}
			setFetchCouponsType={setFetchCouponsType}
			mapModal={mapModal}
			handleClose={handleClose}
			setMapModal={setMapModal}
			header={t.header}
			coupons={coupons}
			lang={lang}
			next_page_url={next_page_url}
			current_page={current_page}
			fetchCoupons={fetchCoupons}
			fetched={fetchedCoupons}
		/>
	);
}

export default Coupons;
