import React from "react";
import {
	Menu,
	MenuList,
	MenuButton,
	MenuOptionGroup,
	MenuItemOption,
	Box,
	MenuItem,
	Image,
	Button,
	Text,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLogoes } from "../../../../utils/imgUri";
import { content } from "./content";
import { Link } from "react-router-dom";
function Selector({ current, set, logo, lang, companies, currentId }) {
	const t = content[lang];
	return (
		<Menu>
			<MenuButton
				bg="white"
				outline="none"
				_active={{ outline: "none" }}
				_focus={{ outline: "none" }}
				as={Button}
				rightIcon={
					<FontAwesomeIcon icon="chevron-down" color="#CCCCCC" style={{ fontSize: ".85em" }} />
				}
			>
				<Box display="flex" alignItems="center">
					<Image
						mr="10px"
						w="30px"
						h="30px"
						borderRadius="50%"
						bg="#D7D8D7"
						src={getLogoes(logo)}
					/>
					<Text mr="5px">{t.client}</Text>
					<Text mr="5px">{current}</Text>
				</Box>
			</MenuButton>

			<MenuList>
				<Box display={["block", "none"]}>
					<Link to={"/profile"}>
						<MenuItem>{t.profile}</MenuItem>
					</Link>
				</Box>
				<Box display={["block", "none"]}>
					<Link to={"/profile/edit"}>
						<MenuItem>{t["edit.profile"]}</MenuItem>
					</Link>
				</Box>
				<Link to={"/company"}>
					<MenuItem>{t.payments}</MenuItem>
				</Link>
				<Link to={"/create-company"}>
					<MenuItem>{t.add_company}</MenuItem>
				</Link>

				<Link to={"/company/edit/" + currentId}>
					<MenuItem>{t.edit_company}</MenuItem>
				</Link>

				<MenuOptionGroup
					defaultValue={currentId}
					type="radio"
					onChange={companyId => {
						localStorage.removeItem("activeInvoice");
						set(companyId);
					}}
				>
					{companies.map((item, key) => (
						<MenuItemOption key={key} value={item.company.id}>
							<Box display="flex" alignItems="center">
								<Image
									mr="10px"
									w="30px"
									h="30px"
									borderRadius="50%"
									bg="#D7D8D7"
									src={getLogoes(item?.company?.logo)}
								/>
								<Text>{item?.company?.name}</Text>
							</Box>
						</MenuItemOption>
					))}
				</MenuOptionGroup>
			</MenuList>
		</Menu>
	);
}

export default Selector;
