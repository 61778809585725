import { apiCallAc } from "..";

export function getStats(config) {
	console.log(config.refer);
	if (config.refer === "news") {
		return apiCallAc.get(
			`/dashboard/mobile/statistics/views/chart/${config.refer}?period=${config.period}&previous_${config.period}=${config.amount}&news_id=${config.itemId}`,
		);
	} else if (config.refer === "subscriber" || config.refer === "subscribers") {
		if (config.amount > 6) {
			const months = (config.amount = 6);
			return apiCallAc.get(`/dashboard/mobile/statistics/views/chart/user?months=${months}`);
		}
	} else {
		return apiCallAc.get(
			`/dashboard/mobile/statistics/views/chart/${config.refer}?period=${config.period}&previous_${config.period}=${config.amount}&location_id=${config.itemId}`,
		);
	}
}

export function getHomeStats(count) {
	return apiCallAc.get("/dashboard/mobile/statistics/views/chart/multiple?hours=" + count);
}

export function getSubscribedStats(id) {
	return apiCallAc.get(`/dashboard/mobile/statistics/views/chart/user?location_id=${id}`);
}
export function getSubscribedStat(count) {
	return apiCallAc.get(`/dashboard/mobile/statistics/views/chart/user?months=${count}`);
}

export function subscirbedUsers() {
	return apiCallAc.get(`/dashboard/mobile/statistics/subscribed/users`);
}

export function getSubs(config) {
	return apiCallAc.get(
		`/dashboard/mobile/statistics/views/chart/user?months=${config.amount}&period=${config.period}`,
	);
}

export function getStatsId(config) {
	console.log(config.refer);
	if (config.refer === "news") {
		return apiCallAc.get(
			`/dashboard/mobile/statistics/views/chart/${config.refer}?period=${config.period}&previous_${config.period}=${config.amount}&news_id=${config.itemId}`,
		);
	} else if (config.refer === "subscriber") {
		if (config.amount > 6) {
			//const months = (config.amount = 6);
			// return apiCallAc.get(
			//   `/dashboard/mobile/statistics/views/chart/user?months=${months}`
			// );
			return null;
		}
	} else {
		return apiCallAc.get(
			`/dashboard/mobile/statistics/views/chart/${config.refer}?period=${config.period}&previous_${config.period}=${config.amount}&location_id=${config.itemId}`,
		);
	}
}

export function getStatsCountries(config) {
	console.log(config.refer);
	if (config.refer === "news") {
		return apiCallAc.get(
			`/dashboard/mobile/statistics/views/chart/countries?data_for=news&period=${config.period}&previous_${config.period}=${config.amount}&news_id=${config.itemId}`,
		);
	} else if (config.refer === "subscribers" || config.refer === "subscriber") {
		if (config.amount > 6) {
			//const months = (config.amount = 6);
			// return apiCallAc.get(
			//   `/dashboard/mobile/statistics/views/chart/user?months=${months}`
			// );
			return null;
		}
	} else {
		return apiCallAc.get(
			`/dashboard/mobile/statistics/views/chart/countries?data_for=${
				config.refer === "news" ? config.refer : config.refer + "s"
			}&period=${config.period}&previous_${config.period}=${config.amount}`,
		);
	}
}

export function getStatsPlatforms(config) {
	console.log(config.refer);
	if (config.refer === "subscribers" || config.refer === "subscriber") {
		if (config.amount > 6) {
			//const months = (config.amount = 6);
			// return apiCallAc.get(
			//   `/dashboard/mobile/statistics/views/chart/user?months=${months}`
			// );
			return null;
		}
	} else {
		return apiCallAc.get(
			`/dashboard/mobile/statistics/views/chart/platforms?data_for=${
				config.refer === "news" ? config.refer : config.refer + "s"
			}&period=${config.period}&previous_${config.period}=${config.amount}`,
		);
	}
}

export function getLogs(refer = "location") {
	return apiCallAc.get(`/dashboard/mobile/statistics/views/details/${refer}`);
}
