import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHours } from "../../../../../store/actions";
import { initialValues } from "../../formInitialValues";
import Layout from "./components/Layout";
import Loading from "../../../../shared/Loading";
export default function Hours({ formikHours, lang, id }) {
	const {
		hours,
		fetched: fetchedHours,
		fetching: fetchingHours,
	} = useSelector(state => state.fetchHours);
	const { location } = useSelector(state => state.fetchLocation);
	useEffect(() => {
		if (id) {
			dispatch(fetchHours(id));
		}
	}, []);
	useEffect(() => {
		if (id && fetchedHours) {
			hours.data?.length
				? formikHours.setValues({
						...formikHours.values,
						closed_until: location.closed_until
							? location.closed_until.replace(" ", "T").slice(0, -3)
							: "",
						send_hours: location.closed_until ? false : true,
						closed_message: location.closed_message
							? location.closed_message.replace(" ", "T").slice(0, -3)
							: "",

						open_hours: initialValues.open_hours.map(
							item => hours.data.find(itemSearch => itemSearch.day_id === item.day_id) || item,
						),
				  })
				: formikHours.setValues({
						...formikHours.values,
						closed_until: location.closed_until
							? location.closed_until.replace(" ", "T").slice(0, -3)
							: "",
						closed_message: location.closed_message
							? location.closed_message.replace(" ", "T").slice(0, -3)
							: "",
						send_hours: location.closed_until ? false : true,
				  });
		}
	}, [fetchedHours]);

	const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	const dispatch = useDispatch();
	const changeSize = (element, operation, timeIndex) => {
		let emptyArr = { ...element };
		switch (operation) {
			case "add":
				emptyArr.hours = [...emptyArr.hours, { open_at: "", close_at: "" }];
				break;
			case "subtract":
				timeIndex === 0
					? (emptyArr.hours = [{ open_at: "", close_at: "" }])
					: (emptyArr.hours = emptyArr.hours
							.slice(0, timeIndex)
							.concat(emptyArr.hours.slice(timeIndex + 1, emptyArr.hours.length)));
				break;
			default:
		}

		return emptyArr;
	};

	const actionClicked = (day, operation, timeIndex) => {
		const toBeChanged = formikHours.values.open_hours.find(finder => finder.day_id === day.day_id);
		const filtered = [
			...formikHours.values.open_hours.filter(filterDay => filterDay.day_id !== day.day_id),
		];

		formikHours.setFieldValue(
			"open_hours",
			[...filtered, changeSize(toBeChanged, operation, timeIndex)].sort(
				(a, b) => a.day_id - b.day_id,
			),
		);
	};

	const onOpenInputChange = (value, dayIndex, timeIndex) => {
		const newArr = formikHours.values.open_hours.map((newDay, newDayIndex) => {
			return {
				...newDay,
				hours: newDay.hours.map((newTime, newTimeIndex) =>
					newDayIndex === dayIndex &&
					value > newTime.close_at &&
					timeIndex < newTimeIndex &&
					(newTime.close_at || newTime.open_at)
						? {
								open_at: "",
								close_at: "",
						  }
						: newDayIndex === dayIndex &&
						  value > newTime.close_at &&
						  timeIndex === newTimeIndex &&
						  newTime.close_at
						? {
								close_at: "",
								open_at: value,
						  }
						: newDayIndex === dayIndex && newTimeIndex === timeIndex && value > newTime.close_at
						? {
								...newTime,
								open_at: value,
						  }
						: newDayIndex === dayIndex && newTimeIndex === timeIndex && value < newTime.close_at
						? {
								...newTime,
								open_at: value,
						  }
						: newTime,
				),
			};
		});

		formikHours.setFieldValue("open_hours", newArr);
	};

	const onCloseInputChange = (value, dayIndex, timeIndex) => {
		const newArr = formikHours.values.open_hours.map((newDay, newDayIndex) => {
			return {
				...newDay,
				hours: newDay.hours.map((newTime, newTimeIndex) =>
					newDayIndex === dayIndex && newTimeIndex === timeIndex
						? { ...newTime, close_at: value }
						: newTime,
				),
			};
		});

		formikHours.setFieldValue("open_hours", newArr);
	};

	const handleCopy = (dayHours, dayIndex) => {
		console.log(formikHours.values.open_hours);
		formikHours.setFieldValue(
			"open_hours",
			formikHours.values.open_hours.map((day, index) =>
				index === dayIndex + 1 ? { ...day, hours: dayHours } : day,
			),
		);
	};
	const maxOpenAt = formikHours.values.open_hours.map(
		day =>
			day.hours.reduce(function (prev, current) {
				return prev.open_at > current.open_at ? prev : current;
			}).open_at,
	);
	const maxCloseAt = formikHours.values.open_hours.map(
		day =>
			day.hours.reduce(function (prev, current) {
				return prev.close_at > current.close_at ? prev : current;
			}).close_at,
	);

	const maxTimes = maxOpenAt.map((item, index) =>
		maxOpenAt[index] > maxCloseAt[index] ? maxOpenAt[index] : maxCloseAt[index],
	);
	if (fetchingHours) return <Loading />;
	return (
		<Layout
			formikHours={formikHours}
			days={days}
			maxCloseAt={maxCloseAt}
			maxTimes={maxTimes}
			onOpenInputChange={onOpenInputChange}
			onCloseInputChange={onCloseInputChange}
			actionClicked={actionClicked}
			id={id}
			dispatch={dispatch}
			handleCopy={handleCopy}
		/>
	);
}
