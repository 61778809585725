import React, { useEffect, useState } from "react";
import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { months } from "./constantValues";
import { content } from "./components/content";
import { useSelector } from "react-redux";
import CalendarModal from "./components/Modal";
import {
	getDaysInMonth,
	format,
	isBefore,
	startOfDay,
	setHours,
	setMinutes,
	isToday,
	isSameDay,
} from "date-fns";
import TimeModal from "./components/TimeModal";
import SelectedTable from "./components/SelectedTable";

export default function DateInput({ formik, label, start, end, id, customChange }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const today = new Date();
	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];
	const [modalVisible, setModalVisible] = useState(false);
	const [data, setData] = useState([]);
	const [dateSelected, setDateSelected] = useState(false);
	const [timeValue, setTimeValue] = useState("00:00");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [openAt, setOpenAt] = useState(undefined);

	//Generates calendar data
	useEffect(() => {
		let generated = [];
		let month = today.getMonth();
		let year = today.getFullYear();

		for (let i = 0; i < 12; i++) {
			let monthData = {};
			let firstDay = new Date(year, month).getDay();
			let daysInMonth = getDaysInMonth(new Date(year, month));
			monthData.firstDay = firstDay;
			monthData.name = months[month];
			monthData.year = year;
			monthData.daysInMonth = daysInMonth;
			monthData.month = month;
			if (month === 11) {
				year += 1;
				month = 0;
			} else {
				month += 1;
			}

			generated.push(monthData);
		}

		setData(generated);
	}, []);

	//handle selecting days
	const handleSelect = open_at => {
		customChange && customChange();
		formik.setFieldValue(id, format(new Date(open_at), "yyyy-MM-dd'T'HH:mm"));
		setOpenAt(undefined);
	};

	//day color change
	const dayColor = day => {
		return formik.values.post_end_at && isSameDay(day, new Date(formik.values.post_end_at));
	};

	const handleDisabled = day => {
		if (isBefore(day, startOfDay(new Date()))) return true;

		return isBefore(day, startOfDay(new Date()));
	};

	//Time input modal open
	const handleModalOpen = day => {
		setSelectedDate(day);

		if (isToday(day)) setTimeValue(format(new Date(), "HH:mm"));

		if (dateSelected) {
			const dateWithHours = setHours(day, timeValue.split(":")[0]);
			const dateWithMinutes = setMinutes(dateWithHours, timeValue.split(":")[1]);

			handleSelect(dateWithMinutes);
		} else {
			onOpen();
		}
	};

	//Time input modal close
	const handleModalConfirm = () => {
		handleSelect(openAt);
		onClose();
		setTimeValue("00:00");
	};

	//Reset whole input
	const handleReset = () => {
		setDateSelected(false);
		formik.setFieldValue(id, null);
	};

	return (
		<Box>
			<CalendarModal
				handleReset={handleReset}
				dayColor={dayColor}
				handleDisabled={handleDisabled}
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				t={t}
				label={label}
				data={data}
				lang={lang}
				start={start}
				formik={formik}
				handleSelect={handleSelect}
				setDateSelected={setDateSelected}
				dateSelected={dateSelected}
				onOpen={handleModalOpen}
			/>
			<Text mt="0.5em" paddingBottom="0.5em">
				{label}
			</Text>
			<SelectedTable
				t={t}
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				formik={formik}
				lang={lang}
			/>
			<TimeModal
				lang={lang}
				setSelectedDate={setSelectedDate}
				selectedDate={selectedDate}
				isOpen={isOpen}
				onClose={onClose}
				handleModalConfirm={handleModalConfirm}
				setTimeValue={setTimeValue}
				setOpenAt={setOpenAt}
				openAt={openAt}
			/>
		</Box>
	);
}
