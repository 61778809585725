import React, { useState, useCallback } from "react";

import Layout from "./components/Layout";
import LayerItem from "./components/LayerItem";

import {
	onSetActiveObject,
	onToggleHideObject,
	onRemoveObjectFromCanvas,
	onObjectMove,
	onbjectOpacityChange,
} from "../../../utils/objectActions";
import DNDContainer from "../../SideBar/components/Initial/Components/DNDContainer";

function Layers({ state, setState, canvas, variant }) {
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);
	let selectedObj = state.activeObject;

	let layers = canvas.getObjects().filter(item => !item.id || !item.id.toString().includes("snap"));

	const onSelect = item => {
		onSetActiveObject(canvas, setState, item);
	};

	const onToogle = item => {
		onToggleHideObject(canvas, item, setState, variant);
	};

	const onRemove = item => {
		onSetActiveObject(canvas, setState, item);
		onRemoveObjectFromCanvas(canvas, item);
	};

	const onOpacityChange = opacity => {
		onbjectOpacityChange(canvas, opacity);
	};

	const onMove = (item, index) => {
		onObjectMove(canvas, item, index);
	};

	const onDragEnd = res => {
		if (!res.destination) return;
		let itemId = res.draggableId;
		let item = layers.find(item => item.uuid === itemId);
		let updatedIndex = res.destination.index + 2;
		onMove(item, updatedIndex);
		forceUpdate();
	};

	return (
		<Layout activeObject={selectedObj} onOpacityChange={onOpacityChange}>
			<DNDContainer onDragEnd={onDragEnd}>
				{layers.map((item, key) => (
					<LayerItem
						key={item.uuid}
						selectedObj={selectedObj}
						item={item}
						index={key}
						onSelect={onSelect}
						onToogle={onToogle}
						onRemove={onRemove}
						forceReRender={forceUpdate}
					/>
				))}
			</DNDContainer>
		</Layout>
	);
}

export default Layers;
