import { apiCallAc } from "..";

export function uploadToken(data) {
	return apiCallAc.post(`/dashboard/chats/auth`, data);
}

export function getChatableUsers(page = 1) {
	return apiCallAc.get(`/dashboard/chats/users?page=${page}&show=participants`);
}

export function revealUsers(id) {
	return apiCallAc.get(`/dashboard/chats/${id}/users/reveal`);
}

export function addChat(data) {
	return apiCallAc.post(`/dashboard/chats`, data);
}

export function getChats(data) {
	return apiCallAc.get(`/dashboard/chats?show=participants`, data);
}

export function getMessages(id, data) {
	return apiCallAc.get(`/dashboard/chats/${id}/messages`, data);
}

export function changeChatName(id, name) {
	return apiCallAc.put(`/dashboard/chats/${id}?name=${name}`);
}

export function deleteChat(id) {
	return apiCallAc.delete(`/dashboard/chats?chat_id=${id}`);
}

export function seenMessage(id) {
	return apiCallAc.get(`/dashboard/chats/${id}/messages`);
}

export function addMessage(id, data) {
	return apiCallAc.post(`/dashboard/chats/${id}/messages`, data);
}

export function fetchNext(link) {
	return apiCallAc.get(`${link}&show=participants`);
}
