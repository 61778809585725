import { subActions } from "../../actionTypes";
import { paypalSuccess } from "../../../api/libs/plans";
import toast from "react-hot-toast";
import { fetchCompany } from "../company";
import { fetchInvoiceLocations } from "../invoice";

export const onPaypalSuccess = (query, history) => {
	return async dispatch => {
		dispatch({
			type: subActions.PAYPAL_SUCCESS,
		});
		paypalSuccess(query)
			.then(res => {
				toast.success("Success");
				dispatch({
					type: subActions.PAYPAL_SUCCESS_FULFILLED,
					payload: res,
				});
				dispatch(fetchCompany(localStorage.getItem("activeCompany")));
				dispatch(fetchInvoiceLocations());
				history.push("/");
			})
			.catch(err => {
				toast.success("Cancel other plans to create new one");
				history.push("/company");
				dispatch({
					type: subActions.PAYPAL_SUCCESS_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
