import { locationActions } from "../../actionTypes";

const initialState = {
	hours: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case locationActions.DELETE_HOURS: {
			return { ...state, ...initialState };
		}

		case locationActions.DELETE_HOURS_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				hours: action.payload,
			};
		}

		case locationActions.DELETE_HOURS_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
