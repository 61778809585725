import { statisticActions } from "../../actionTypes";
import produce from "immer";

const initialState = {
	logs: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case statisticActions.FETCH_LOGS: {
				draft.fetching = true;
				break;
			}

			case statisticActions.FETCH_LOGS_FULFILED: {
				draft.fetching = false;
				draft.fetched = true;
				draft.logs[action.payload.refer] = action.payload.res;
				break;
			}

			case statisticActions.FETCH_LOGS_REJECTED: {
				draft.fetching = false;
				draft.error = action.payload;
				break;
			}
			default:
				return draft;
		}
	});

export default Reducer;
