import React from "react";
import { Td as ChakraTd } from "@chakra-ui/react";
import { Consumer } from "../utils";
import useWindowSize from "../../../utils/useWindowSize";
const TdInner = props => {
	const { width } = useWindowSize();
	const { narrowHeaders, columnKey = 0, className, hide, ...rest } = props;
	const classes = `${props.className || ""} pivoted`;
	return (
		<ChakraTd
			data-testid="td"
			{...rest}
			className={classes}
			paddingLeft={hide ? "" : width <= 768 ? "calc(50% + 10px) !important;" : ""}
		>
			{!hide && width <= 768 && (
				<div data-testid="td-before" className="tdBefore">
					{narrowHeaders[columnKey]}
				</div>
			)}

			{props.children ?? <div>&nbsp;</div>}
		</ChakraTd>
	);
};

export const Td = props => (
	<Consumer>{headers => <TdInner {...props} narrowHeaders={headers} />}</Consumer>
);
