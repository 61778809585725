import React, { useEffect, useState } from "react";
import Stories from "react-insta-stories";
import { getOriginal } from "../../../utils/imgUri";
import Loading from "../Loading";

export default function CustomStories({ locationIndex, gallery, selectedGallery }) {
	const [gal, setGal] = useState([]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setGal([...gallery[selectedGallery].gallery.data]);
		}, 100);

		return () => {
			setGal([]);
			clearTimeout(timer);
		};
	}, [selectedGallery]);

	if (!gal.length) return <Loading height={true} />;

	return (
		<Stories
			loop={false}
			stories={gal.map(item => ({
				url: getOriginal(item.image),
				type: "image",
			}))}
			width={"375px"}
			height={"667px"}
		/>
	);
}
