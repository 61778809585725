export const toasterConfig = {
	style: {
		border: "1px solid #713200",
		padding: "16px",
		color: "#713200",
	},
	iconTheme: {
		primary: "#713200",
		secondary: "#FFFAEE",
	},
};

export const toastOptions = {
	success: {
		style: {
			background: "#9AE6B4",
			color: "#161822",
			fontFamily: "Segoe UI SemiBold",
		},
		iconTheme: {
			primary: "#000",
			secondary: "#FFF",
		},
	},
	error: {
		style: {
			background: "#FEB2B2",
			color: "#161822",
			fontFamily: "Segoe UI SemiBold",
		},
		iconTheme: {
			primary: "transparent",
			secondary: "transparent",
		},
	},
};
