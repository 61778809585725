import { extendTheme } from "@chakra-ui/react";

const colors = {
	brand: {
		50: "#ecefff",
		100: "#cbceeb",
		200: "#a9aed6",
		300: "#888ec5",
		400: "#666db3",
		500: "#4d5499",
		600: "#3c4178",
		700: "#2a2f57",
		800: "#181c37",
		900: "#080819",
	},
	blue: {
		400: "#286AA6",
	},
	ourGreen: "#AACD56",
	red: {
		400: "#9E3B11",
	},
};
const config = {
	initialColorMode: "light",
	useSystemColorMode: false,
};

const theme = extendTheme({
	colors,
	config,
	fonts: {
		heading: "Segoe UI",
		body: "Segoe UI",
		box: "Segoe UI Semi-Light",
	},
});

export { theme };
