import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";

import { useMount } from "./hooks/useMount";
import { useSlides } from "../Builder/store";

import MainDisplay from "./components/MainDisplay";
import EditorWindow from "./components/EditorWindow";
import { previewGallery } from "./utils/canvas";
function RBuilder({ variant, refer, formik }) {
	const [state, setState] = useImmer({
		activeSlide: 0,
		triggerPreview: false,
	});
	const slides = useSlides(state => state[variant]);
	const setStoreActiveSlide = useSlides(state => state.setActiveSlide);
	useEffect(() => {
		setStoreActiveSlide(state.activeSlide);
	}, [state.activeSlide]);
	const [isReady] = useMount(variant, formik);
	const [editorActive, setEditorActive] = useState(false);
	const onCloseEditorWindow = () => {
		setEditorActive(false);
	};

	useEffect(() => {
		if (state.triggerPreview) {
			previewGallery(slides, formik);
			setState(state => {
				state.triggerPreview = false;
			});
		}
	}, [state.triggerPreview]);

	if (!isReady) return null;
	return (
		<>
			<MainDisplay
				{...{
					variant,
					refer,
					formik,
					state,
					setState,
					setEditorActive,
				}}
			/>
			{editorActive && <EditorWindow {...{ variant, refer, onCloseEditorWindow, formik }} />}
		</>
	);
}

export default RBuilder;
