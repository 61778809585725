import { apiCallAc } from "..";

export function getNotifications(page = 1) {
	return apiCallAc.get(`/dashboard/inAppNotifications?page=${page}`);
}

export function getNotificationsCount() {
	return apiCallAc.get("/dashboard/inAppNotifications?count=true&seen=false");
}

export function getNotification(id) {
	return apiCallAc.get("/dashboard/inAppNotifications/" + id);
}

export function updateNotification(id, seen) {
	return apiCallAc.put("/dashboard/inAppNotifications/" + id + "?seen=" + seen);
}

export function updateNotifications(ids, seen) {
	return apiCallAc.delete("/dashboard/inAppNotifications?seen=" + seen + "&ids=" + ids);
}
