import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, IconButton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { _menuBtn } from "../../../../../content";

const MenuBtn = ({ lang, onPreview, onExportPng, onExportTmpl, onSaveTemplate }) => {
	const t = _menuBtn[lang];
	return (
		<Menu>
			<MenuButton
				ml="auto"
				pointerEvents="visible"
				cursor="pointer"
				_active={{ outline: "none" }}
				_focus={{ outline: "none" }}
				_hover={{ bg: "rgba(170,205,86, 0.7)" }}
				w="45px"
				h="45px"
				borderRadius="full"
				as={IconButton}
				aria-label="Options"
				bg="rgba(176,177,175, 0.7)"
				icon={<FontAwesomeIcon color="white" icon="ellipsis-v" style={{ fontSize: "22px" }} />}
				variant="outline"
			/>
			<MenuList
				pointerEvents="visible"
				bg="rgba(39,41,41,0.9)"
				color="white"
				fontSize="14px"
				border="none"
			>
				<MenuItem
					display="flex"
					justifyContent="center"
					iconSpacing="5px"
					//  isDisabled={!prevActive}
					icon={<FontAwesomeIcon icon="mobile" style={{ fontSize: "16px", width: "20px" }} />}
					_focus={{ bg: "#AACD56" }}
					_hover={{ bg: "#AACD56" }}
					onClick={onPreview}
				>
					{t.preview}
				</MenuItem>
				<MenuItem
					display="flex"
					justifyContent="center"
					iconSpacing="5px"
					icon={<FontAwesomeIcon icon="cloud" style={{ fontSize: "16px", width: "20px" }} />}
					_focus={{ bg: "#AACD56" }}
					_hover={{ bg: "#AACD56" }}
					onClick={onSaveTemplate}
				>
					{t.saveTmpl}
				</MenuItem>
				<MenuDivider />
				<MenuItem
					iconSpacing="5px"
					icon={<FontAwesomeIcon icon="download" style={{ fontSize: "16px", width: "20px" }} />}
					_focus={{ bg: "#AACD56" }}
					_hover={{ bg: "#AACD56" }}
					onClick={onExportPng}
				>
					{t.exportPng}
				</MenuItem>
				<MenuItem
					iconSpacing="5px"
					icon={<FontAwesomeIcon icon="file-export" style={{ fontSize: "16px", width: "20px" }} />}
					_focus={{ bg: "#AACD56" }}
					_hover={{ bg: "#AACD56" }}
					onClick={onExportTmpl}
				>
					{t.exportTmpl}
				</MenuItem>
			</MenuList>
		</Menu>
	);
};

export default MenuBtn;
