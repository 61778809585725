import { userActions } from "../../actionTypes";
import { updateUser as handleUpdateUser } from "../../../api/libs/user";
import toast from "react-hot-toast";
import { fetchUserData } from "./fetchUserData";

export const updateUser = (data, history, setErrors) => {
	return async dispatch => {
		dispatch({
			type: userActions.USER_UPDATE_FETCHING,
		});
		toast.promise(handleUpdateUser(data), {
			loading: "Updating user...",
			success: res => {
				dispatch({
					type: userActions.USER_UPDATE_FULFILLED,
					payload: res.success,
				});
				dispatch(fetchUserData());

				history.push("/profile");

				return <b>User updated!</b>;
			},
			error: err => {
				dispatch({
					type: userActions.USER_UPDATE_REJECTED,
					payload: err.response.data.errors,
				});
				setErrors(err.response.data.errors);
				return <b>Couldn't update user.</b>;
			},
		});
	};
};
