import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";

import {
	onToogleUnderline,
	onToogleLinethrough,
	onToUpperCase,
	onToLowerCase,
	onSuperScript,
	onSubScript,
} from "../../../../utils/textboxActions";

import TextActionBtn from "./buttons/TextActionBtn";

function TextDecoration({ state, canvas }) {
	const [underline, setUnderline] = useState(false);
	const [linethrough, setLinethrough] = useState(false);

	useEffect(() => {
		if (state.activeObject) {
			setUnderline(state.activeObject.underline);
			setLinethrough(state.activeObject.linethrough);
		}
	}, [state.activeObject]);

	return (
		<Box
			py="20px"
			display="flex"
			justifyContent="center"
			alignItems="center"
			borderBottom="1px solid #CBCCCB"
			borderTop="1px solid #CBCCCB"
		>
			<TextActionBtn
				type="underline"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={underline}
				handleClick={() => {
					onToogleUnderline(canvas, state);
					setUnderline(!underline);
				}}
			/>

			<TextActionBtn
				type="strikethrough"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={linethrough}
				handleClick={() => {
					onToogleLinethrough(canvas, state);
					setLinethrough(!linethrough);
				}}
			/>

			<TextActionBtn
				type="font"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				//   selected={clicked}
				handleClick={() => {
					onToUpperCase(canvas, state);
				}}
				secondary="level-up-alt"
			/>

			<TextActionBtn
				type="font"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				handleClick={() => {
					onToLowerCase(canvas, state);
				}}
				secondary="level-down-alt"
			/>

			<TextActionBtn
				type="superscript"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				handleClick={() => {
					onSuperScript(canvas, state);
				}}
			/>
			<TextActionBtn
				type="subscript"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				handleClick={() => {
					onSubScript(canvas, state);
				}}
			/>
		</Box>
	);
}

export default TextDecoration;
