import React, { useEffect } from "react";
import { Box, Text, Stack, ListItem, Heading, UnorderedList, ListIcon } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { Stat, StatNumber, StatHelpText } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../shared/buttons/PrimaryButton";
import { MdCheckCircle, MdRemoveCircle } from "react-icons/md";
import { swapPaypalSubscription, swapStripeSubscription } from "../../../store/actions";

function PlanCard({
	item,
	loading,
	lang,
	planPrices,
	handleChange,
	setSelectedSlug,
	disabled,
	selected,
	slug,
	title,
	active,
	planId,
	onPlanClose,
	setPage,
	setActivePlan,
	index,
}) {
	const history = useHistory();
	const { selectedPlan } = useSelector(state => state.selectedPlan);

	const labels = {
		image_editor: "Image editor",
		statistics: "Statistics",
		chats: "Chats",
		locations: "Locations / Events / News",
		facebook_pages: "Facebook Pages",
		push_notifications: "Push / Email Notifications",
		company_coupons: "Company Coupons",
		vip_actions: "VIP aktionen",
		shop: "shop",
	};

	const dispatch = useDispatch();
	return (
		<Box
			onClick={e => {
				e.stopPropagation();
				handleChange(planPrices[1].id);
				localStorage.setItem("activePlanID", planId);
				setSelectedSlug(slug);
			}}
		>
			<Box
				border={selected ? "3px solid #AFC80D" : "3px solid #306496"}
				bg="#FFFFFF"
				borderRadius="3em"
				overflow={"hidden"}
				p="1em"
				w="full"
				height="100%"
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				alignItems="space-between"
			>
				<Box>
					<Heading fontSize="2em" color={selected && "#AFC80D"} textAlign="center">
						{title}
					</Heading>
					<Stack textAlign={"center"} color="#1A202C" align={"center"}>
						<Text color="#CA3171" pt="1em">
							SALE - UP TO <span style={{ fontWeight: 900 }}>10%</span>
						</Text>
						<Stack direction={"row"} align={"space-evenly"} justify={"space-evenly"} spacing={5}>
							<Stat color={planPrices[0].id === selectedPlan.id && "#AFC80D"}>
								<Text color="#000" textDecoration="line-through" textDecorationColor="#CA3171">
									{planPrices[0].previous_cost}€
								</Text>
								<StatNumber>
									<Text>{planPrices[0].cost}€</Text>
								</StatNumber>
								<StatHelpText>/{planPrices[0].interval}</StatHelpText>
							</Stat>
							<Stat color={planPrices[1].id === selectedPlan.id && "#AFC80D"}>
								<Text color="#000" textDecoration="line-through" textDecorationColor="#CA3171">
									{planPrices[1].previous_cost}€
								</Text>
								<StatNumber>{planPrices[1].cost}€</StatNumber>
								<StatHelpText>/{planPrices[1].interval}</StatHelpText>
							</Stat>
						</Stack>
					</Stack>

					<Box display="flex" justifyContent="space-evenly" py="1em">
						<PrimaryButton
							disabled={
								active.plan_price_id === planPrices[0].id ||
								(planId !== active.plan_id && active.plan_id)
							}
							bg={
								selectedPlan.id === planPrices[0].id || active.plan_price_id === planPrices[0].id
									? "#AFC80D"
									: "#A9A9AD"
							}
							onClick={e => {
								e.stopPropagation();
								handleChange(planPrices[0].id);
								localStorage.setItem("activePlanID", planId);
								setSelectedSlug(slug);
							}}
							label="MONTHLY"
						></PrimaryButton>
						<PrimaryButton
							disabled={
								active.plan_price_id === planPrices[1].id ||
								(planId !== active.plan_id && active.plan_id !== "")
							}
							bg={
								selectedPlan.id === planPrices[1].id || active.plan_price_id === planPrices[1].id
									? "#AFC80D"
									: "#A9A9AD"
							}
							label="YEARLY"
						></PrimaryButton>
					</Box>

					<Box px={3} py={5}>
						<UnorderedList listStyleType="none" spacing={1}>
							{Object.keys(planPrices[0].company_per_plan_limitation).map((s, key) =>
								planPrices[0].company_per_plan_limitation[s] !== 0 && labels[s] ? (
									<ListItem key={key}>
										<ListIcon as={MdCheckCircle} color={selected ? "#AFC80D" : "#306496"} />
										{labels[s]}
									</ListItem>
								) : labels[s] ? (
									<ListItem key={key}>
										<ListIcon as={MdRemoveCircle} color="#ABBED2" />
										{labels[s]}
									</ListItem>
								) : null,
							)}
							{slug === "enterprise_company" || slug === "premium_company" ? (
								<ListItem>
									<ListIcon as={MdCheckCircle} color={selected ? "#AFC80D" : "#306496"} />
									Virtual Reality{" "}
									<Text as="span" color="#CA3171">
										{" "}
										(Later)
									</Text>
								</ListItem>
							) : (
								<ListItem>
									<ListIcon as={MdRemoveCircle} color="#ABBED2" />
									Virtual Reality{" "}
									<Text as="span" color="#CA3171">
										{" "}
										(Later)
									</Text>
								</ListItem>
							)}
							{slug === "enterprise_company" ? (
								<ListItem>
									<ListIcon as={MdCheckCircle} color={selected ? "#AFC80D" : "#306496"} />
									VIP Aktionen
									<Text as="span" color="#CA3171">
										{" "}
										(Later)
									</Text>
								</ListItem>
							) : (
								<ListItem>
									<ListIcon as={MdRemoveCircle} color="#ABBED2" />
									VIP Aktionen
									<Text as="span" color="#CA3171">
										{" "}
										(Later)
									</Text>
								</ListItem>
							)}
							{slug === "enterprise_company" ? (
								<ListItem>
									<ListIcon as={MdCheckCircle} color={selected ? "#AFC80D" : "#306496"} />
									Shop
									<Text as="span" color="#CA3171">
										{" "}
										(Later)
									</Text>
								</ListItem>
							) : (
								<ListItem>
									<ListIcon as={MdRemoveCircle} color="#ABBED2" />
									Shop
									<Text as="span" color="#CA3171">
										{" "}
										(Later)
									</Text>
								</ListItem>
							)}
						</UnorderedList>
					</Box>
				</Box>
				<Box alignSelf="center" mt="1em">
					<PrimaryButton
						size="lg"
						disabled={
							!selectedPlan.id ||
							(selectedPlan.id !== planPrices[1].id && selectedPlan.id !== planPrices[0].id)
						}
						bg={selected ? "#AFC80D" : "#306496"}
						label={active.plan_price_id ? "SWAP" : "CHECKOUT"}
						onClick={() => {
							if (active.plan_id) {
								dispatch(
									swapPaypalSubscription({
										paypal_subscription_id: active.subscription_id,
										paypal_plan_id: selectedPlan.id,
									}),
								);
								onPlanClose();
							} else if (active.plan_price_id) {
								console.log({
									plan_price_id: selectedPlan.id,
									subscription_id: active.subscription_id,
								});
								dispatch(
									swapStripeSubscription({
										plan_price_id: selectedPlan.id,
										subscription_id: active.subscription_id,
									}),
								);
								onPlanClose();
								console.log("SWAP STRIPE", active, selectedPlan.id);
							} else {
								setPage(4);
								// null
								//  history.push("/checkout");
							}
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
}

export default PlanCard;
