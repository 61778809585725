import React, { useEffect } from "react";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { registerUser, registerUserWithRef, resetRegisterState } from "../../../store/actions";
import { initialValues } from "./formInitialValues";
import Layout from "./components/Layout";
import Form from "./components/Form";
import { useFormik } from "formik";
import { validationSchema } from "./validationSchema";

function Register({ history, location }) {
	const { ref } = queryString.parse(location.search);

	const lang = useSelector(state => state.languageProvider.language);
	const { user } = useSelector(state => state.currentUser);

	const dispatch = useDispatch();

	const { fetching: loading, success } = useSelector(state => state.registerUser);

	useEffect(() => {
		success && history.push("/");
		dispatch(resetRegisterState());
	}, [success]);

	useEffect(() => {
		if (user.access_token) {
			history.push("/dashboard");
		}
	}, [user.access_token]);

	// handle button click of login form
	const handleRegister = async data => {
		if (ref) {
			dispatch(registerUserWithRef(data, ref, formik.setErrors));
		} else {
			dispatch(registerUser(data, formik.setErrors));
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleRegister,
		validationSchema: validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		<Layout>
			<Form handleRegister={handleRegister} loading={loading} lang={lang} formik={formik} />
		</Layout>
	);
}
export default Register;
