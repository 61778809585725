import { apiMedia, apiBase64, apiCallAc, apiVideo, apiMediaLogo } from "..";

export function uploadMedia(data, fullUrl, setProgress) {
	console.log("UPLOADING PANO");
	return apiMedia.post(
		// fullUrl
		// ? `/dashboard/upload/image${fullUrl && "?full_url=true"}`
		// :
		`/dashboard/upload/image`,
		data,
		{
			onUploadProgress: progressEvent => {
				let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
				if (setProgress) {
					setProgress(percentCompleted);
				} else {
					// console.log(percentCompleted);
				}
			},
		},
	);
}

export function uploadLogo(data, fullUrl, setProgress) {
	return apiMediaLogo.post(
		// fullUrl
		// ? `/dashboard/upload/image${fullUrl && "?full_url=true"}`
		// :
		`/dashboard/upload/image`,
		data,
		{
			onUploadProgress: progressEvent => {
				let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
				if (setProgress) {
					setProgress(percentCompleted);
					console.log("Upload Successful", percentCompleted);
				} else {
					// console.log(percentCompleted);
				}
			},
		},
	);
}

export function uploadBase64(data, fullUrl, setProgress = false) {
	if (setProgress) {
		setProgress(prev => ({
			...prev,
			loading: true,
		}));
	}
	return apiBase64.post(
		// fullUrl
		// ? `/dashboard/upload/image/${fullUrl && "?full_url=true"}`
		// :
		`/dashboard/upload/image?base64=true`,
		data,
		{
			onUploadProgress: progressEvent => {
				let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
				if (setProgress) {
					setProgress(prev => ({
						...prev,
						val: percentCompleted,
					}));
				} else {
					// console.log("Upload Successful",fullUrl, percentCompleted, setProgress);
					// console.log(percentCompleted / 2);
				}
			},
		},
	);
}

export function uploadTemplate(data, type) {
	return apiCallAc.post(`/dashboard/template?type=${type}`, data);
}

export const uploadVideo = async data => {
	return apiVideo.post("/dashboard/upload/video?storage=s3", data);
};

export function uploadChatMedia(formData) {
	return apiMedia.post(`/mobile/upload/image`, formData);
}
