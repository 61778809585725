import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TextActionBtn({ type, active, selected, handleClick, secondary = false }) {
	const [mouseDown, setMouseDown] = useState(false);
	if (!active) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				mr="15px"
				cursor="pointer"
				borderRadius="3px"
				p="5px"
			>
				<FontAwesomeIcon style={{ fontSize: "20px" }} icon={type} color={"#E3E3E3"} />
				{secondary && (
					<FontAwesomeIcon style={{ fontSize: "15px" }} icon={secondary} color={"#E3E3E3"} />
				)}
			</Box>
		);
	}
	return (
		<Box
			display="flex"
			justifyContent="center"
			mr="15px"
			cursor="pointer"
			_hover={{ bg: "rgba(245,245,245, 0.5)" }}
			borderRadius="3px"
			p="5px"
			onMouseDown={() => setMouseDown(true)}
			onMouseUp={() => {
				setMouseDown(false);
				handleClick();
			}}
		>
			<FontAwesomeIcon
				style={{ fontSize: "20px" }}
				icon={type}
				color={selected || mouseDown ? "#AEC53A" : "black"}
			/>
			{secondary && (
				<FontAwesomeIcon
					style={{ fontSize: "15px" }}
					icon={secondary}
					color={selected || mouseDown ? "#AEC53A" : "black"}
				/>
			)}
		</Box>
	);
}

export default TextActionBtn;
