import React from "react";

import HorizontalScrollContainer from "./components/HorizontalScrollContainer";
import ObjectActionBtn from "./components/ObjectActionBtn";

import rl1 from "../../../../../../../../assets/builder/rotate_10_l_1.svg";
import rl2 from "../../../../../../../../assets/builder/rotate_10_l_2.svg";
import rr1 from "../../../../../../../../assets/builder/rotate_10_r_1.svg";
import rr2 from "../../../../../../../../assets/builder/rotate_10_r_2.svg";
import rR1 from "../../../../../../../../assets/builder/reset_rotate_1.svg";
import rR2 from "../../../../../../../../assets/builder/reset_rotate_2.svg";
import fh1 from "../../../../../../../../assets/builder/flip_h_1.svg";
import fh2 from "../../../../../../../../assets/builder/flip_h_2.svg";
import fv1 from "../../../../../../../../assets/builder/flip_v_1.svg";
import fv2 from "../../../../../../../../assets/builder/flip_v_2.svg";
import zi1 from "../../../../../../../../assets/builder/zoom_in_1.svg";
import zi2 from "../../../../../../../../assets/builder/zoom_in_2.svg";
import zo1 from "../../../../../../../../assets/builder/zoom_out_1.svg";
import zo2 from "../../../../../../../../assets/builder/zoom_out_2.svg";
import d1 from "../../../../../../../../assets/builder/duplicate_1.svg";
import d2 from "../../../../../../../../assets/builder/duplicate_2.svg";

import {
	onObjectRotate,
	onObjectRotateReset,
	onObjectFlip,
	onMagnifier,
	onCloneObject,
} from "../../../../../utils/objectActions";

function ObjectActions({ canvas, activeObject }) {
	if (!activeObject) return null;
	return (
		<HorizontalScrollContainer>
			<ObjectActionBtn
				state0={rl1}
				state1={rl2}
				action={() => {
					onObjectRotate(canvas, "left");
					canvas.fire("event:render-live", {});
				}}
			/>
			<ObjectActionBtn
				state0={rr1}
				state1={rr2}
				action={() => {
					onObjectRotate(canvas, "right");
					canvas.fire("event:render-live", {});
				}}
			/>
			<ObjectActionBtn
				state0={rR1}
				state1={rR2}
				action={() => {
					onObjectRotateReset(canvas);
					canvas.fire("event:render-live", {});
				}}
			/>
			<ObjectActionBtn
				state0={fh1}
				state1={fh2}
				action={() => {
					onObjectFlip(canvas, "y");
					canvas.fire("event:render-live", {});
				}}
			/>
			<ObjectActionBtn
				state0={fv1}
				state1={fv2}
				action={() => {
					onObjectFlip(canvas, "x");
					canvas.fire("event:render-live", {});
				}}
			/>
			<ObjectActionBtn
				state0={zi1}
				state1={zi2}
				action={() => {
					onMagnifier(canvas, "in");
					canvas.fire("event:render-live", {});
				}}
			/>
			<ObjectActionBtn
				state0={zo1}
				state1={zo2}
				action={() => {
					onMagnifier(canvas, "out");
					canvas.fire("event:render-live", {});
				}}
			/>
			<ObjectActionBtn
				state0={d1}
				state1={d2}
				action={() => {
					onCloneObject(canvas, activeObject);
					canvas.fire("event:render-live", {});
				}}
			/>
		</HorizontalScrollContainer>
	);
}

export default ObjectActions;
