export const calculateDimensions = () => {
	const vh = window.innerHeight;
	const vw = window.innerWidth;
	let obj = {};
	if (vh - 200 >= 540 && vw >= 540) {
		obj = {
			squere: {
				name: "squere",
				width: 540,
				height: 540,
				ratio: 2,
			},
			portrait: {
				name: "portrait",
				width: 432,
				height: 540,
				ratio: 2.5,
			},
			landscape: {
				name: "landscape",
				width: 540,
				height: 304,
				ratio: 2,
			},
		};
		return obj;
	}
	if (vh > vw) {
		if (vh - 200 >= vw) {
			obj = {
				squere: {
					name: "squere",
					width: vw,
					height: vw,
					ratio: 1080 / vw,
					// 1080/1080
				},
				portrait: {
					name: "portrait",
					width: 1080 / (1350 / vw),
					height: vw,
					ratio: 1350 / vw,
					// 1080/1350
				},
				landscape: {
					name: "landscape",
					width: vw,
					height: 608 / (1080 / vw),
					ratio: 1080 / vw,
					// 1080/608
				},
			};
			return obj;
		} else {
			obj = {
				squere: {
					name: "squere",
					width: vh - 200,
					height: vh - 200,
					ratio: 1080 / (vh - 200),
					// 1080/1080
				},
				portrait: {
					name: "portrait",
					width: 1080 / (1350 / (vh - 200)),
					height: vh - 200,
					ratio: 1350 / (vh - 200),
					// 1080/1350
				},
				landscape: {
					name: "landscape",
					width: vh - 200,
					height: 608 / (1080 / (vh - 200)),
					ratio: 1080 / (vh - 200),
					// 1080/608
				},
			};
			return obj;
		}
	} else {
		obj = {
			squere: {
				name: "squere",
				width: vh - 200,
				height: vh - 200,
				ratio: 1080 / (vh - 200),
				// 1080/1080
			},
			portrait: {
				name: "portrait",
				width: 1080 / (1350 / (vh - 200)),
				height: vh - 200,
				ratio: 1350 / (vh - 200),
				// 1080/1350
			},
			landscape: {
				name: "landscape",
				width: vh - 200,
				height: 608 / (1080 / (vh - 200)),
				ratio: 1080 / (vh - 200),
				// 1080/608
			},
		};
		return obj;
	}
};
