import { subActions } from "../../actionTypes";

const initialState = {
	plans: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case subActions.FETCH_PLANS: {
			return { ...state, fetching: true };
		}

		case subActions.FETCH_PLANS_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				plans: action.payload,
			};
		}

		case subActions.FETCH_PLANS_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
			return state;
	}
};

export default Reducer;
