import React from "react";
import { Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { onAddText } from "../../../../utils/fabricActions";
import { onSetActiveObject } from "../../../../utils/objectActions";

import TextBoxPrev from "./components/TextBoxPrev";

import { _sideMenu } from "../../../../../content";

function Texts({ lang, canvas, state, setState, variant }) {
	console.log(canvas);
	const t = _sideMenu[lang];
	let arr = [];
	if (canvas) {
		arr = canvas.getObjects("textbox");
	}
	return (
		<Box display="flex" flexDir="column" h="540px" p="10px" overflowY="scroll">
			<Box
				border="1px dashed #D6D6D5"
				fontSize="20"
				color="#CBCCCB"
				py="5px"
				px="10px"
				cursor="pointer"
				_active={{ bg: "rgba(245,245,245,0.3)", borderWidth: 0 }}
				onClick={() => {
					onAddText(canvas, setState);
				}}
			>
				<FontAwesomeIcon icon="plus" style={{ marginRight: "10px" }} />
				{t.newText}
			</Box>
			{arr.length > 0 &&
				arr.map((item, key) => (
					<TextBoxPrev
						key={key}
						item={item}
						activeObj={state.activeObject}
						handleClick={() => {
							onSetActiveObject(canvas, setState, item);
						}}
					/>
				))}
		</Box>
	);
}

export default Texts;
