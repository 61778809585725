export const content = {
	en: {
		header: "News",
		client: "Create News",
		description: "Description",
		title: "Title",
		start_at: "Logo",
		end_at: "End at",
		location: "Location",
		future: "future",
		old: "old",
		published: "published",
		no_data: "There is no data for this type.",
		add_news: "Add news",
		locb: "Location button",
		coupon_count: "Coupons",
		logo: "Logo",
		delete: "Delete",
		edit: "Edit",
		name: "Name",
		surname: "Surname",
		phone_number: "Phone Number",
		email: "Email",
		picture: "Picture",
		copy: "Link",
	},
	de: {
		header: "Nachrichten",
		client: "Nachrichten erstellen",
		description: "Beschreibung",
		title: "Titel",
		start_at: "Logo",
		end_at: "Ende um",
		location: "Standort",
		future: "Zukunft",
		old: "alt",
		published: "veröffentlicht",
		no_data: "Für diesen Typ sind keine Daten vorhanden.",
		add_news: "Nachrichten hinzufügen",
		locb: "Standort-Schaltfläche",
		coupon_count: "Gutscheine",
		logo: "Logo",
		delete: "Löschen",
		edit: "Bearbeiten",
		name: "Name",
		surname: "Surname",
		phone_number: "Phone Number",
		email: "Email",
		picture: "Picture",
		copy: "Link",
	},
};
