import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { days } from "../constantValues";
import Month from "./Month";

export default function Calendar({
	data,
	start,
	selectedType,
	formik,
	dateSelected,
	dayColor,
	handleDisabled,
	onOpen,
}) {
	return data.map(({ firstDay, name, year, daysInMonth, month }) => (
		<Box key={name}>
			<Text textAlign="center" color="#7E7E7E">
				{name} {year}
			</Text>
			<Box display="grid" gridTemplateColumns="repeat(7,1fr)" gridTemplateRows="1fr">
				{days.map((day, index) => (
					<Text key={index} width="1.5em" height="1.5em" textAlign="center" color="#7E7E7E">
						{day}
					</Text>
				))}
			</Box>
			<Month
				onOpen={onOpen}
				dayColor={dayColor}
				handleDisabled={handleDisabled}
				dateSelected={dateSelected}
				daysInMonth={daysInMonth}
				firstDay={firstDay}
				start={start}
				selectedType={selectedType}
				year={year}
				month={month}
				formik={formik}
			/>
		</Box>
	));
}
