import { userActions } from "../../actionTypes";
import { getFacebookPages } from "../../../api/libs/user";

export const fetchFacebookPages = code => {
	return async dispatch => {
		dispatch({
			type: userActions.FACEBOOK_PAGES_FETCHING,
		});
		getFacebookPages(code)
			.then(res => {
				dispatch({
					type: userActions.FACEBOOK_PAGES_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: userActions.FACEBOOK_PAGES_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
