import { newsActions } from "../../actionTypes";
import { addNews } from "../../../api/libs/news";
import toast from "react-hot-toast";
import newGallery from "../../../utils/gallery";
import { createGallery } from "../../../api/libs/gallery";

export const createNews = (data, gallery, setErrors, history, handleKeywordsSubmit) => {
	return async dispatch => {
		dispatch({
			type: newsActions.CREATE_NEWS,
		});

		toast.promise(addNews(data), {
			loading: "Creating news...",
			success: res => {
				history.push("/news");
				handleKeywordsSubmit(res.data.id, setErrors);
				newGallery(gallery)
					.then(galleryArr => {
						dispatch({
							type: newsActions.CREATE_NEWS_FULFILLED,
							payload: res.data,
						});
						createGallery(res.data.gallery_id, galleryArr, data.instagram_page_share)
							.then(r => console.log(r))
							.catch(e => console.log(e));
					})
					.catch(err => {
						console.log(err);
					});

				return <b>Successfully created news</b>;
			},
			error: err => {
				setErrors(err.response.data.errors);
				Object.keys(err.response.data.errors).map(key =>
					toast.error(err.response.data.errors[key]),
				);
				dispatch({
					type: newsActions.CREATE_NEWS_REJECTED,
					payload: err.response.data.errors,
				});
				return <b>Couldn't create news</b>;
			},
		});
	};
};
