import React, { useState } from "react";
import { Box, Image, Spinner } from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";

import bg from "../../../../../../../assets/builder/transparent_pattern.png";

function StickerItem({ name, loadSticker, url = false }) {
	const [compLoaded, setCompLoaded] = useState(false);
	const [loading, setLoading] = useState(false);

	const onMount = () => {
		setCompLoaded(true);
	};

	const Wrapper = ({ children }) => {
		return (
			<Box pos="absolute" top={0} left={0} w="100%" h="100%" textAlign="center">
				{children}
			</Box>
		);
	};

	return (
		<Box
			w="48%"
			h="70px"
			mb={"10px"}
			background="white"
			backgroundImage={bg}
			backgroundRepeat="repeat"
			backgroundSize="5%"
			display="flex"
			justifyContent="center"
			cursor="pointer"
			border="1px solid rgba(0,0,0,.15)"
			_hover={{ bg: "rgba(170,205,86, 0.1)" }}
			onClick={() => {
				loadSticker(name, setLoading, url);
			}}
			pos="relative"
		>
			{/* {!compLoaded && (
        <Skeleton height="88%" width="32%" circle wrapper={Wrapper} />
      )} */}
			{loading ? (
				<Box
					pos="absolute"
					w="100%"
					h="100%"
					top={0}
					left={0}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Spinner color="#AACD56" size="xl" />
				</Box>
			) : (
				<>
					{url ? (
						<>
							<Image
								h="90%"
								src={`https://static.yezzgo.de/image-editor-statics/stickers/microsoftEmojis/${name}`}
								onLoad={onMount}
							/>
						</>
					) : (
						<Image
							h="90%"
							src={require(`../../../../../../../assets/stickers/${name}`).default}
							onLoad={onMount}
						/>
					)}
				</>
			)}
		</Box>
	);
}

export default StickerItem;
