import React, { useState } from "react";
import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	Progress,
	InputGroup,
	InputRightAddon,
	InputRightElement,
	Tooltip,
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { upload, uploadMediaLogo } from "../../../utils/upload";
import { getDescendantProp } from "../../../utils/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PrimaryInput({
	label,
	type,
	id,
	column,
	formik,
	setUploaded,
	labelBackground,
	labelAlign,
	containerHeight,
	autoComplete,
	customChange,
	value,
	rightIcon,
	rightIconAction,
	...rest
}) {
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const resolvePath = (object, path, defaultValue) =>
		path
			.split(/[.[\]'"]/)
			.filter(p => p)
			.reduce((o, p) => (o ? o[p] : defaultValue), object);

	return (
		<FormControl
			display="grid"
			gridTemplateColumns={
				column ? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "2fr 8fr", "2fr 8fr"]
			}
			mt="0.5em"
			alignItems="center"
			gridGap={labelBackground && "1em"}
			isInvalid={getDescendantProp(formik.errors, id) && getDescendantProp(formik.touched, id)}
			id={id}
			position="relative"
		>
			<FormLabel
				fontFamily="Segoe UI Semilight"
				_focus={{
					color: labelBackground ? "#FFF" : "#1274BC",
					fontWeight: 700,
				}}
				height={labelBackground && "2em"}
				m={labelBackground && "0"}
				marginBottom={!column && ["0", "1em !important"]}
				display={labelBackground && "flex"}
				width={labelBackground && "100%"}
				alignItems={labelBackground && "center"}
				justifyContent={labelBackground && "center"}
				backgroundColor={labelBackground}
				textAlign={labelAlign}
				position="relative"
			>
				{label}
			</FormLabel>
			<Box display="flex" flexDirection="column">
				<InputGroup>
					{/* if formit.error[id] exists show tootlti else dont */}

					<Tooltip
						label={getDescendantProp(formik.errors, id)}
						hasArrow
						arrowSize={15}
						bg="red.600"
					>
						<Input
							px="4"
							py="0"
							height="2em"
							borderRadius="0px"
							borderColor="#CBCCCB"
							borderWidth="1px"
							backgroundColor="transparent"
							boxShadow="none"
							_hover={{ backgroundColor: "#F2F1F1" }}
							_focus={{
								backgroundColor: "#F2F1F1",
								borderColor: formik.errors[id] ? "red" : "#1274BC",
							}}
							color={type === "file" ? "transparent" : "#000"}
							placeholder={label}
							_placeholder={{ color: "rgba(0,0,0,0.5)" }}
							type={type}
							multiple={true}
							autoComplete={autoComplete}
							onChange={
								customChange
									? customChange
									: type === "file"
									? e => {
											id === "logo"
												? uploadMediaLogo(e, setLoading, setUploaded, false, setProgress)
												: upload(e, setLoading, setUploaded, false, setProgress);
									  }
									: formik.handleChange
							}
							value={
								value
									? value
									: type === "file"
									? null
									: id.includes(".")
									? resolvePath(formik.values, id)
									: formik.values[id] || ""
							}
							mb={!column && ["1em", "1em"]}
							{...rest}
						/>
					</Tooltip>
					{rightIcon && (
						<InputRightElement
							height="2em"
							mb={!column && ["1em", "1em"]}
							children={
								<FontAwesomeIcon
									// size="2x"
									color="#231F1F"
									icon={rightIcon}
									onClick={() => {
										rightIconAction();
									}}
								/>
							}
						/>
					)}
				</InputGroup>
				{/* 
          if formik.errors, id exists, show error message as tootip

        */}
				{/* <FormErrorMessage
          position="absolute"
          bottom={column ? "-1.2em" : ["-0.2em", "-0.2em"]}
        >
          {getDescendantProp(formik.errors, id)}
        </FormErrorMessage> */}
				{loading && <Progress mt="1" hasStripe value={progress} isAnimated={true} />}
			</Box>
		</FormControl>
	);
}
