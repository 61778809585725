import { couponActions } from "../../actionTypes";
import { deleteCoupon as deleteCouponFunction } from "../../../api/libs/coupons";
import toast from "react-hot-toast";
import { fetchCoupons } from ".";

export const deleteCoupon = id => {
	return async dispatch => {
		dispatch({
			type: couponActions.DELETE_COUPON,
		});
		deleteCouponFunction(id)
			.then(res => {
				dispatch({
					type: couponActions.DELETE_COUPON_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully deleted coupon");

				dispatch(fetchCoupons());
			})
			.catch(err => {
				dispatch({
					type: couponActions.DELETE_COUPON_REJECTED,
					payload: err.response,
				});
			});
	};
};
