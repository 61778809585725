import { locationActions } from "../../actionTypes";
import { getHours } from "../../../api/libs/locations";

export const fetchHours = (category, top) => {
	return async dispatch => {
		dispatch({
			type: locationActions.FETCH_HOURS,
		});
		getHours(category, top)
			.then(res => {
				dispatch({
					type: locationActions.FETCH_HOURS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: locationActions.FETCH_HOURS_REJECTED,
					payload: err.response.data.error,
				});
			});
	};
};
