import { locationActions } from "../../actionTypes";
import { updateLocation as updateLocationFunction } from "../../../api/libs/locations";
import { createGallery } from "../../../api/libs/gallery";
import toast from "react-hot-toast";
import newGallery from "../../../utils/gallery";
import { Alert, AlertIcon, AlertTitle, AlertDescription, Flex } from "@chakra-ui/react";
import { BsExclamationOctagon } from "react-icons/bs";

export const updateLocation = (
	id,
	data,
	galleryId,
	gallery,
	setErrors,
	history,
	handleHourSubmit,
	handleKeywordsSubmit,
) => {
	return async dispatch => {
		dispatch({
			type: locationActions.UPDATE_LOCATION,
		});

		toast.promise(updateLocationFunction(id, data), {
			loading: "Updating...",
			success: res => {
				history.push("/locations");
				handleHourSubmit(id, setErrors);
				handleKeywordsSubmit(id, setErrors);
				console.log("[Gallery]:", gallery);
				newGallery(gallery)
					.then(galleryArr => {
						console.log("[galleryArr]", galleryArr);
						dispatch({
							type: locationActions.UPDATE_LOCATION_FULFILLED,
							payload: res.data,
						});
						createGallery(galleryId, galleryArr, data.instagram_page_share)
							.then(r => console.log(r))
							.catch(e => console.log(e));
					})
					.catch(err => {
						console.log(err);
					});

				return <p>Successfully edited location</p>;
			},
			error: err => {
				setErrors(err.response.data.errors);
				dispatch({
					alert: "error",
					type: locationActions.UPDATE_LOCATION_REJECTED,
					payload: err.response.data.errors,
				});

				return (
					<p>
						<Flex justifyContent={"center"} alignItems={"center"}>
							{" "}
							<BsExclamationOctagon />{" "}
							<span style={{ marginLeft: "10px" }}>Couldn't update location.</span>
						</Flex>
					</p>
				);
			},
		});
	};
};
