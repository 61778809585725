import { couponActions } from "../../actionTypes";
import { getCoupon } from "../../../api/libs/coupons";

export const fetchCoupon = id => {
	return async dispatch => {
		dispatch({
			type: couponActions.FETCH_COUPON,
		});
		getCoupon(id)
			.then(res => {
				dispatch({
					type: couponActions.FETCH_COUPON_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: couponActions.FETCH_COUPON_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
