export const initialValues = {
	image: "",
	title: "",
	description: "",
	news: {
		id: [],
	},
	user: {
		id: [],
	},
	type: "money",
	visible_for: "all",
	coupon_code: "",
	post_end_at: "",
	limited_amount: "",
	previous_price: "",
	money_value: "",
	percentage_value: "",
	custom_location: [],
};
