import { couponActions } from "../../actionTypes";
import { updateCoupon as updateCouponFunction } from "../../../api/libs/coupons";
import toast from "react-hot-toast";

export const updateCoupon = (id, data, setErrors, history) => {
	return async dispatch => {
		dispatch({
			type: couponActions.UPDATE_COUPON,
		});
		updateCouponFunction(id, data)
			.then(res => {
				dispatch({
					type: couponActions.UPDATE_COUPON_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully edited coupon");
				history.push("/coupons");
			})
			.catch(err => {
				setErrors(err.response.data.errors);
				toast.error("Error");
				dispatch({
					type: couponActions.UPDATE_COUPON_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
