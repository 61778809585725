import React, { useState, useEffect } from "react";
import { Input } from "@chakra-ui/react";

function TextBoxPrev({ item, handleClick, activeObj }) {
	const [val, setVal] = useState("");

	useEffect(() => {
		setVal(item.text);
	}, []);

	return (
		<Input
			border="1px solid"
			as={Input}
			borderColor={activeObj === item ? "black" : "#CBCCCB"}
			fontSize="20"
			mt="10px"
			color={activeObj === item ? "black" : "#CBCCCB"}
			py="5px"
			px="10px"
			cursor="default"
			_active={{ bg: "rgba(245,245,245,0.3)" }}
			onClick={() => {
				if (activeObj !== item) {
					handleClick();
				}
			}}
			_disabled={{ cursor: "initial" }}
			_hover={{ border: "1px solid" }}
			overflowY="scroll"
			overflowWrap="anywhere"
			minH="50px"
			maxH="50px"
			h="50px"
			bg="#F2F1F1"
			value={val}
			onChange={e => {
				setVal(e.target.value);
				item.set("text", e.target.value);
				item.canvas.renderAll();
			}}
		/>
	);
}

export default TextBoxPrev;
