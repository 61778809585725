import { userActions } from "../../actionTypes";

const initialState = {
	logs: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case userActions.LOGS_FETCHING: {
			return { ...state, fetching: true };
		}

		case userActions.LOGS_FULFILLED: {
			return state.logs.current_page !== action.payload.current_page
				? {
						...state,
						fetching: false,
						fetched: true,
						logs: {
							...action.payload,
							data: state.logs.data
								? [...state.logs.data, ...action.payload.data]
								: action.payload.data,
						},
				  }
				: {
						...state,
						fetching: false,
						fetched: true,
						logs: action.payload,
				  };
		}

		case userActions.LOGS_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
			return state;
	}
};

export default Reducer;
