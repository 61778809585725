import React from "react";
import { Box, Image } from "@chakra-ui/react";

function BackButton({ icon, action }) {
	return (
		<Box
			outline="none"
			display="flex"
			flexDir="row"
			alignItems="center"
			justifyContent="center"
			minWidth={50}
			maxWidth={50}
			height={50}
			padding="5px 0"
			cursor="pointer"
			borderRight="0.5px solid black"
			onClick={action}
		>
			<span
				style={{
					fontSize: "20px",
					color: "#0564B1",
					marginRight: "-5px",
				}}
			>
				&#10554;
			</span>
			<Image src={icon} boxSize="35px" cursor="pointer" />
		</Box>
	);
}

export default BackButton;
