import { Box, Divider, Image, Text } from "@chakra-ui/react";
import React from "react";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../../shared/table";
import { content } from "./content";
import placeholder from "../../../../assets/images/instaPlaceholder.jpeg";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";

export default function Layout({
	fetching,
	lang,
	userDetails,
	currentPage,
	setCurrentPage,
	next_page_url,
	prev_page_url,
	current_page,
	per_page,
	pages,
	resync,
	onClose,
	isOpen,
	currentId,
	setCurrentId,
	onOpen,
}) {
	const t = content[lang];
	const handleScroll = e => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom && next_page_url && !fetching) {
			setCurrentPage(current_page + 1);
		}
	};

	return (
		<Box m={5}>
			<Box display="flex" flexDirection="column">
				<Box
					paddingX="20px"
					display="flex"
					flexDirection="column"
					flexGrow="1"
					position="absolute"
					overflowY="scroll"
					onScroll={handleScroll}
					bottom="0"
					right="0"
					width="100%"
					top={["3em", "0"]}
					pb={["5em", "1em"]}
				>
					<Box>
						<Box display="flex" justifyContent="space-between">
							<Box py="3">
								<Box mb="1">
									<Text fontSize="24px">{t.facebook}</Text>
								</Box>
								<Box w="40px">
									<Divider borderBottom="4px" opacity="1" />
								</Box>
							</Box>
						</Box>
						<Box display="grid" gridTemplateColumns={["1fr", "2fr 8fr"]} gridGap="2em">
							{userDetails.email_verified_at ? (
								<Box display="flex" flexDirection="column" gridGap={["1em", "0.2em"]}>
									<Box display="grid" gridTemplateColumns={["1fr", "1fr 1fr"]}>
										<Text>{t.email_verified_at}: </Text>
										<Text>{userDetails.email_verified_at}</Text>
									</Box>
									<Box display="grid" gridTemplateColumns={["1fr", "1fr 1fr"]}>
										<Text>{t.facebook_email}: </Text>
										<Text>{userDetails.facebook_email}</Text>
									</Box>
									<Box display="grid" gridTemplateColumns={["1fr", "1fr 1fr"]}>
										<Text>{t.facebook_user_id}: </Text>

										<Text>{userDetails.facebook_user_id}</Text>
									</Box>
									<Box display="grid" gridTemplateColumns={["1fr", "1fr 1fr"]}>
										<Text>{t.token_updated_at}: </Text>
										<Text>{userDetails.token_updated_at}</Text>
									</Box>
									<Box display="grid" gridTemplateColumns={["1fr", "1fr 1fr"]}>
										<Text>{t.token_valid_for_days}: </Text>
										<Text>{userDetails.token_valid_for_days}</Text>
									</Box>
								</Box>
							) : (
								"Facebook not connected"
							)}
							<Box display="flex" justifyContent="center" alignItems="center">
								<PrimaryButton
									onClick={() => resync()}
									label={userDetails.email_verified_at ? t.resync : t.connect}
								/>
							</Box>
						</Box>
					</Box>
					<Box py="3">
						<Box mb="1">
							<Text fontSize="24px">Pages</Text>
						</Box>
						<Box w="40px">
							<Divider borderBottom="4px" opacity="1" />
						</Box>
					</Box>
					<Box display="flex" flexDirection="column">
						<Table>
							<Thead>
								<Tr>
									<Th>#</Th>
									<Th>{t.logo}</Th>
									<Th>{t.name}</Th>
									<Th>{t.instagram}</Th>
								</Tr>
							</Thead>

							<Tbody borderTop="2px solid #000">
								{pages?.length
									? pages.map((item, key) => (
											<Tr key={key}>
												<Td py="2em">{key + 1}</Td>
												<Td>
													<Image height="3em" width="3em" src={item.picture} />
												</Td>
												<Td>{item.name}</Td>

												<Td hide>
													<PrimaryButton
														onClick={() => {
															setCurrentId(key);
															onOpen();
														}}
														disabled={!item.linked_instagram_pages}
														label={item.linked_instagram_pages ? t.open_details : t.no_details}
													/>
												</Td>
											</Tr>
									  ))
									: "There are no pages connected"}
							</Tbody>
						</Table>
					</Box>
				</Box>
			</Box>

			{pages && (
				<Modal onClose={onClose} size="full" isOpen={isOpen}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>{t.instagram_details}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Box display="flex" flexGrow="1" pt="30px" flexDirection="column">
								<Table>
									<Thead>
										<Tr>
											<Th fontSize="1.2em">Picture</Th>
											<Th fontSize="1.2em">Name</Th>
											<Th fontSize="1.2em">Username</Th>
											<Th fontSize="1.2em">Followers Count</Th>
											<Th fontSize="1.2em">Follows Count</Th>
											<Th fontSize="1.2em">Media Count</Th>
										</Tr>
									</Thead>
									<Tbody borderTop="2px solid #000">
										<Tr
											_hover={{
												backgroundColor: "#F2F1F0",
											}}
										>
											<Td ml="1">
												<Image
													width="3em"
													height="3em"
													src={pages[currentId]?.linked_instagram_pages?.picture || placeholder}
												/>
											</Td>
											<Td ml="1">{pages[currentId]?.linked_instagram_pages?.name}</Td>
											<Td ml="1">{pages[currentId]?.linked_instagram_pages?.username}</Td>
											<Td ml="1">{pages[currentId]?.linked_instagram_pages?.followers_count}</Td>
											<Td ml="1">{pages[currentId]?.linked_instagram_pages?.follows_count}</Td>
											<Td ml="1">{pages[currentId]?.linked_instagram_pages?.media_count}</Td>
										</Tr>
									</Tbody>
								</Table>
							</Box>
						</ModalBody>
						<ModalFooter>
							<PrimaryButton onClick={onClose} label={t.close} />
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</Box>
	);
}
