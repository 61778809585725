import React from "react";
import { Box, Text, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../../shared/Modal";
import Calendar from "./Calendar";

export default function CalendarModal({
	modalVisible,
	setModalVisible,
	t,
	selectedType,
	data,
	start,
	formik,
	handleSelect,
	dateSelected,
	dayColor,
	handleDisabled,
	handleReset,
	onOpen,
	lang,
}) {
	return modalVisible ? (
		<Modal header="Veröffentlichung" handleClose={() => setModalVisible(false)}>
			<Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
				<Box
					flexGrow="1"
					borderBottom="2px solid #7C7C7C"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					{lang === "en" ? "Select date" : "Datum auswählen"}
				</Box>
				<Box>
					<Box
						fontFamily="Segoe UI SemiBold"
						display="grid"
						gridTemplateColumns="1fr 1fr"
						gridGap="1em"
						px="3em"
						pb="1em"
						height="70vh"
						overflowY="scroll"
					>
						<Calendar
							onOpen={onOpen}
							dayColor={dayColor}
							handleDisabled={handleDisabled}
							dateSelected={dateSelected}
							data={data}
							start={start}
							selectedType={selectedType}
							formik={formik}
							handleSelect={handleSelect}
						/>
					</Box>
				</Box>

				<Box
					py="0.5em"
					flexGrow="1"
					borderTop="2px solid #7C7C7C"
					display="grid"
					gridTemplateColumns="1fr 1fr"
					gridGap="1em"
				>
					<Box display="flex" justifyContent="flex-end" alignItems="center">
						<Text mr="1em">{t.confirm}</Text>
						<Button
							width="3em"
							height="3em"
							borderRadius="100%"
							justifySelf="flex-end"
							_hover={{ bg: "#9BC962" }}
							_focus={{ bg: "#B0D352" }}
							_active={{ bg: "#B0D352" }}
							bg="#D7D7D6"
							onClick={() => {
								setModalVisible(false);
							}}
						>
							<FontAwesomeIcon color="#FFF" size="lg" icon="check" />
						</Button>
					</Box>
					<Box display="flex" alignItems="center">
						<Button
							onClick={handleReset}
							_hover={{ bg: "#AAAAAD" }}
							_focus={{ bg: "#6C6C6E" }}
							_active={{ bg: "#6C6C6E" }}
							width="3em"
							borderRadius="100%"
							bg="#D7D7D6"
							height="3em"
						>
							<FontAwesomeIcon color="#FFF" size="lg" icon="undo" />
						</Button>
						<Text ml="1em">{t.reset}</Text>
					</Box>
				</Box>
			</Box>
		</Modal>
	) : null;
}
