import React from "react";
import { FormControl, FormLabel, Tooltip, Box } from "@chakra-ui/react";

import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import { getFormats } from "../../../../api/libs/events";

export default function Formats({ formik, column }) {
	const lang = useSelector(state => state.languageProvider.language);

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		if (searchQuery && searchQuery.length < 2) {
			return null;
		}
		const response = await getFormats(page);
		if (formik.values.format_id && page === 1) {
			formik.setFieldValue("format_id", response.data[0]);
		}
		return {
			options: response.data.data,
			hasMore: response.next_page_url,
			additional: {
				page: searchQuery ? 2 : page + 1,
			},
		};
	};

	const onChange = option => {
		formik.setFieldValue("format_id", option);
	};

	return (
		<Tooltip label={formik.errors.format_id} hasArrow arrowSize={15} bg="red.600">
			<FormControl
				display="grid"
				gridTemplateColumns={
					column ? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "2fr 8fr", "2fr 8fr"]
				}
				alignItems="center"
				isInvalid={formik.errors.format_id && formik.touched.format_id}
				id="format_id"
			>
				<FormLabel
					_focus={{
						color: "#1274BC",
						fontWeight: 700,
					}}
				>
					{lang === "de" ? "Format" : "Format"}
				</FormLabel>
				<Box display="flex" flexDirection="column">
					<AsyncPaginate
						theme={theme => ({
							...theme,
							borderRadius: 0,
						})}
						value={formik.values.format_id || ""}
						loadOptions={loadOptions}
						getOptionValue={option => option.name}
						getOptionLabel={option => option.name}
						onChange={onChange}
						placeholder={
							formik.values.format_id !== "" ? formik.values.format_id : "Auswählen Format"
						}
						additional={{
							page: 1,
						}}
					/>

					{/* <FormErrorMessage
          position={column ? "absolute" : "relative"}
          bottom="-1.2em"
        >
          {formik.errors.format_id}
        </FormErrorMessage> */}
				</Box>
			</FormControl>
		</Tooltip>
	);
}
