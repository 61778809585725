import { invoiceActions } from "../../actionTypes";
import { getInvoiceLocations } from "../../../api/libs/invoice";
import { fetchLocations } from "../locations";

export const fetchInvoiceLocations = () => {
	return async dispatch => {
		dispatch({
			type: invoiceActions.FETCH_INVOICE_LOCATIONS,
		});
		getInvoiceLocations()
			.then(res => {
				dispatch({
					type: invoiceActions.FETCH_INVOICE_LOCATIONS_FULFILLED,
					payload: res.data,
				});
				dispatch(fetchLocations());
			})
			.catch(err => {
				dispatch({
					type: invoiceActions.FETCH_INVOICE_LOCATIONS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
