import { drawSnapLine } from "./canvas";
import { useSlides } from "../store";

export const onListenCanvasEvents = (canvas, variant, setState, saveSlide) => {
	if (canvas) {
		canvas.on("mouse:down", event => {
			if (event.target === null) {
				setState(state => {
					state.activeObject = null;
				});
			} else {
				setState(state => {
					state.activeObject = event.target;
				});
			}
		});

		canvas.on("selection:created", event => {
			setState(state => {
				state.activeObject = event.target;
			});
		});

		canvas.on("selection:cleared", () => {
			setState(state => {
				state.activeObject = null;
			});
		});

		canvas.on("object:modified", event => {
			setState(state => {
				state.activeObject = event.target;
			});
			canvas.fire("event:render-live", {});
			canvas.fire("event:cache", {});
		});

		canvas.on("text:changed", () => {});

		canvas.on("object:moving", options => {
			let snapZone = 10;

			let objectMiddleV = options.target.getCenterPoint().x;
			let objectMiddleH = options.target.getCenterPoint().y;

			options.target.setPositionByOrigin(
				{
					x:
						objectMiddleV > canvas.width / 2 - snapZone &&
						objectMiddleV < canvas.width / 2 + snapZone
							? canvas.width / 2
							: objectMiddleV,
					y:
						objectMiddleH > canvas.height / 2 - snapZone &&
						objectMiddleH < canvas.height / 2 + snapZone
							? canvas.height / 2
							: objectMiddleH,
				},
				"center",
				"center",
			);

			if (
				objectMiddleV > canvas.width / 2 - snapZone &&
				objectMiddleV < canvas.width / 2 + snapZone
			) {
				drawSnapLine(canvas, "vertical_snap", "1");
			} else {
				drawSnapLine(canvas, "vertical_snap", "0");
			}

			if (
				objectMiddleH > canvas.height / 2 - snapZone &&
				objectMiddleH < canvas.height / 2 + snapZone
			) {
				drawSnapLine(canvas, "horizontal_snap", "1");
			} else {
				drawSnapLine(canvas, "horizontal_snap", "0");
			}
		});

		canvas.on("object:moved", () => {
			drawSnapLine(canvas, "vertical_snap", "0");
			drawSnapLine(canvas, "horizontal_snap", "0");
		});

		canvas.on("object:added", () => {});
		canvas.on("object:removed", () => {});

		canvas.on("event:render-live", () => {
			setState(state => {
				state.liveImg = canvas.toDataURL({ format: "png", multiplier: 0.5 });
			});
		});

		canvas.on("event:cache", () => {
			saveSlide(canvas, variant);
			useSlides.getState().markSlideEdited();
		});
	}
};
