import { templateActions } from "../../actionTypes";

const initialState = {
	templates: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case templateActions.FETCH_TEMPLATES: {
			return { ...initialState, fetching: true };
		}

		case templateActions.FETCH_TEMPLATES_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				templates: action.payload,
			};
		}

		case templateActions.FETCH_TEMPLATES_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		case templateActions.ADD_TEMPLATE: {
			if (Object.keys(state.templates).length > 0) {
				return {
					...state,
					templates: {
						...state.templates,
						data: [action.payload, ...state.templates.data],
					},
				};
			} else {
				return state;
			}
		}

		case templateActions.DELETE_TEMPLATE: {
			return {
				...state,
				templates: {
					...state.templates,
					data: state.templates.data.filter(item => item.id !== action.payload),
				},
			};
		}

		default:
			return state;
	}
};

export default Reducer;
