import React from "react";
import { Box, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from "@chakra-ui/react";

const CustomSlider = ({
	canvas,
	title,
	val,
	setVal,
	color,
	max = 100,
	type,
	isDisabled,
	op = false,
}) => {
	return (
		<Box>
			<Text textAlign="center" fontSize="14px">
				{title}{" "}
				<span style={{ fontSize: "16px" }}>
					{type === "shadow" ? val * 5 : type === "opacity" ? val : val * 100}%
				</span>
			</Text>
			<Slider
				aria-label="slider-ex-1"
				defaultValue={val}
				value={val}
				mb="5"
				min={0}
				max={max}
				onChange={val => {
					setVal(val);
				}}
				onChangeEnd={() => {
					canvas.fire("event:cache", {
						eName: `change-${type}`,
						from: "picker/sliders",
					});
				}}
				isDisabled={isDisabled}
			>
				<SliderTrack
					bg={type === "stroke" ? "black" : "linear-gradient(to right, black,rgba(0,0,0,0))"}
					h="8px"
				>
					<SliderFilledTrack bg="transperant" />
				</SliderTrack>
				<SliderThumb
					bg={
						type === "opacity" && color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${op})` : color
					}
					borderColor={"rgba(0,0,0,0.5)"}
					borderWidth={1}
					h="20px"
					w="20px"
				/>
			</Slider>
		</Box>
	);
};

export default CustomSlider;
