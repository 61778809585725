import React from "react";
import { Box } from "@chakra-ui/layout";
import { useLocation } from "react-router-dom";

import Sidebar from "./sidebar";
import Header from "./header";

function DashboardLayout({ isAuthenticated, ...props }) {
	let location = useLocation();

	return (
		<>
			{!isAuthenticated ||
			location.pathname.includes("/email/verify") ||
			location.pathname.includes("/recover-password") ||
			location.pathname.includes("/password/reset") ? (
				<>{props.children}</>
			) : isAuthenticated && location.pathname.includes("/create-company") ? (
				<>{props.children}</>
			) : isAuthenticated &&
			  (location.pathname.includes("/select-company") ||
					location.pathname.includes("/invoices") ||
					location.pathname.includes("/invoices/new") ||
					location.pathname.includes("/subscription") ||
					location.pathname.includes("/checkout")) ? (
				<>{props.children}</>
			) : (
				<Box width="100%" display="flex" flexWrap="wrap" height={["100%", "100vh"]}>
					<Sidebar />
					<Box
						id="cont"
						flex="1"
						overflow={["hidden", "hidden"]}
						display="flex"
						flexDirection="column"
						pb={["0", "0"]}
					>
						<Header />
						<Box position="relative" h="100%" overflow="auto">
							{props.children}
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
}

export default DashboardLayout;
