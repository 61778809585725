import React, { useState } from "react";

//Chakra
import { Box, Text, Divider } from "@chakra-ui/react";

//Components
import PrimaryInput from "../../../shared/inputs/PrimaryInput";

//Text content
import { content } from "./content";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";

function RegisterForm({ lang, formik }) {
	const t = content[lang];
	const [canSeePass, setCanSeePass] = useState(false);

	return (
		<Box w={["auto", "60%"]} ml={[0, "35%"]}>
			<Box mb="2" p={5}>
				<Box mb="1">
					<Text fontSize="20px">{t.register}</Text>
				</Box>
				<Box w="40px">
					<Divider borderBottom="4px" opacity="1" />
				</Box>
			</Box>
			<form onSubmit={formik.handleSubmit}>
				<Box align="right" mr={[0, "40px"]} p={5}>
					<PrimaryInput label={t.name} type="text" id="name" formik={formik} />
					<PrimaryInput label={t.surname} type="text" id="surname" formik={formik} />
					<PrimaryInput label={t.email} type="email" id="email" formik={formik} />
					<PrimaryInput
						label={t.password}
						id="password"
						formik={formik}
						type={canSeePass ? "text" : "password"}
						rightIcon={canSeePass ? "eye-slash" : "eye"}
						rightIconAction={() => {
							setCanSeePass(!canSeePass);
						}}
					/>

					<PrimaryButton
						width={["100%", "auto"]}
						height="1.5rem"
						px="3em"
						mt="1em"
						label={t.register2}
						onClick={formik.handleSubmit}
						type="submit"
						bg="#A9A9AD"
					/>
				</Box>
			</form>
		</Box>
	);
}

export default RegisterForm;
