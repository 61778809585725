import React, { useEffect, useState, createContext, useContext } from "react";
import {
	Box,
	Grid,
	GridItem,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	StatArrow,
	StatGroup,
	Flex,
	Text,
	Divider,
	Avatar,
	AvatarGroup,
	Button,
} from "@chakra-ui/react";
import Select from "react-select";
import { Line, Pie, Bar } from "react-chartjs-2";
import { Spinner } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { getHomeStats, getSubscribedStats } from "../../api/libs/stats";
import { Link } from "react-router-dom";
import { useSubscribed } from "./context";
function Dashboard() {
	const [hoursFilter, setHoursFilter] = useState(6);
	const chartOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					stepSize: 0.5,
				},
			},
		},
	};

	const generatFilterArray = number => {
		let arr = Array.from({ length: number }, (_, index) => index + 1);
		let options = [];
		arr.map((i, k) => {
			options.push({
				value: i,
				label: i.toString(),
			});
		});
		return options;
	};
	const colourStyles = {
		option: (styles, { data, isDisabled, isFocused }) => {
			return {
				...styles,
				backgroundColor: isFocused ? "#3F629D" : data.isValid ? "red" : "",
				color: data.isValid ? "#FFF" : isDisabled ? "gray" : isFocused ? "#FFF" : "black",
				cursor: isDisabled ? "not-allowed" : "pointer",
				textAlign: "center",
				zindex: 999,
			};
		},
		container: styles => ({ ...styles, width: "100px" }),
	};

	const [statsData, setStatsData] = useState();
	const [loading, setLoading] = useState(false);

	// get data form getHomeStats api and set it to statsData
	useEffect(() => {
		getHomeStats(hoursFilter)
			.then(res => {
				setStatsData(res.data);
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [hoursFilter]);

	const [subscribedStatsData, setSubscribedStatsData] = useState();
	useEffect(() => {
		getSubscribedStats()
			.then(res => {
				setSubscribedStatsData(res.data);
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const generateMultiLineChartData = () => {
		if (statsData) {
			return {
				labels: statsData?.labels,
				datasets: [
					{
						label: "# of views for locations",
						data: statsData?.locations,
						fill: false,
						backgroundColor: "#0E65AE",
						borderColor: "rgba(14, 101, 174, 0.4)",
					},
					{
						label: "# of views for news",
						data: statsData?.news,
						fill: false,
						backgroundColor: "rgb(255, 99, 132)",
						borderColor: "rgba(255, 99, 132, 0.4)",
					},
					{
						label: "# of views for events",
						data: statsData?.events,
						fill: false,
						backgroundColor: "rgb(54, 162, 235)",
						borderColor: "rgba(54, 162, 235, 0.4)",
					},
				],
			};
		}
	};

	const Baroptions = {
		indexAxis: "y",
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				position: "right",
			},
		},
	};
	// make currentTab state and set it to subscribers and make it global state
	const currentTab = useSelector(state => state.currentTab);
	const dispatch = useDispatch();
	const handleTabChange = tab => {
		dispatch({ type: "SET_CURRENT_TAB", payload: tab });
	};

	return (
		<Grid h="100%" templateRows="100px" templateColumns="repeat(5, 1fr)" gap={1}>
			<GridItem
				h={["150px", "66px"]}
				colSpan={5}
				rowSpan={"100px"}
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				<h3
					style={{
						fontSize: 20,
						marginRight: 10,
					}}
				>
					Filter by Hours
				</h3>
				<Select
					isSearchable={false}
					defaultValue={{
						value: hoursFilter,
						label: hoursFilter.toString(),
					}}
					options={generatFilterArray(23)}
					onChange={e => setHoursFilter(e.value)}
					styles={colourStyles}
					theme={theme => ({
						...theme,
						borderRadius: 0,
						width: "100%",
					})}
				/>
			</GridItem>
			<GridItem colSpan={5} rowSpan={1}>
				<Box width={["100%", "95%"]} height={["100%", "100%"]} margin="auto" marginTop="-2%">
					{
						// if statsData is  null  show the loading spinner else show the chart
						statsData ? (
							<>
								<Flex justifyContent={"space-between"} flexWrap={"wrap"}>
									<Box width={["100%", "60%"]}>
										<Line data={generateMultiLineChartData} options={chartOptions} />
									</Box>
									<Box width={["100%", "29%"]}>
										<Flex flexWrap={"wrap"}>
											<Box width={"100%"}>
												<StatGroup
													marginTop={"3vh"}
													border={"1px solid #E5E4E2"}
													borderRadius={"12px"}
													padding={"15px"}
												>
													<Stat>
														<StatLabel color={"gray.500"} fontSize={"1.075rem"} fontWeight={"400"}>
															Views
														</StatLabel>
														<StatNumber fontSize={"2.5rem"} fontWeight={"bold"}>
															{statsData.views}
														</StatNumber>
														<StatHelpText
															backgroundColor={"green.100"}
															w={"60px"}
															padding={"3px"}
															borderRadius={"10px"}
															display={"flex"}
															alignItems={"center"}
														>
															<StatArrow type="increase" />
															<Text color={"green.600"}>{statsData.views_increased_by}</Text>
														</StatHelpText>
													</Stat>
												</StatGroup>
											</Box>
											<Box width={"100%"}>
												<StatGroup
													marginTop={"1vh"}
													border={"1px solid #E5E4E2"}
													borderRadius={"12px"}
													padding={"15px"}
												>
													<Stat>
														<StatLabel color={"gray.500"} fontSize={"1.075rem"} fontWeight={"400"}>
															Previous Views
														</StatLabel>
														<StatNumber fontSize={"2.5rem"} fontWeight={"bold"}>
															{statsData.previous_views}
														</StatNumber>
														<StatHelpText
															backgroundColor={"red.100"}
															w={"65px"}
															padding={"3px"}
															borderRadius={"10px"}
															display={"flex"}
															alignItems={"center"}
														>
															<StatArrow type="decrease" />
															{
																// if subscribed_decreased_by is null show 0 else show the value
																statsData.views_decreased_by === null ? (
																	<Text color={"red.600"}>0%</Text>
																) : (
																	<Text color={"red.600"}> {statsData.views_decreased_by}</Text>
																)
															}

															{/* {statsData.views_decreased_by} */}
														</StatHelpText>
													</Stat>
												</StatGroup>
											</Box>
										</Flex>
									</Box>
								</Flex>
							</>
						) : (
							<Spinner
								position="relative"
								top="50%"
								left="50%"
								thickness="4px"
								speed="0.65s"
								emptyColor="gray.200"
								color="blue.500"
								size="xl"
							/>
						)
					}
				</Box>
			</GridItem>

			{/* <GridItem
        h={["150px", "100px"]}
        colSpan={5}
        rowSpan={"100px"}
        justifyContent="center"
        alignItems="center"
      >
        <Flex w={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Box width={"95%"} margin={"0 auto"}>
            <StatGroup
              border={"1px solid #E5E4E2"}
              borderRadius={"12px"}
              padding={"15px"}
            >
              <Flex w={"100%"} justifyContent={"center"}  alignItems={"center"}>
                <Box w={"25%"}>
                  <Stat>
                    <StatLabel fontSize={"20px"} fontWeight={"400"} textAlign={"center"}>
                      Subscribes
                    </StatLabel>
                  </Stat>
                </Box>
                <Box w={"25%"}>
                  <Flex w={"100%"}>
                    <Box w={"50%"}>
                      <h3
                        style={{
                          fontSize: 20,
                          marginRight: 10,
                        }}
                      >
                        Filter by Month
                      </h3>
                    </Box>
                    <Box w={"50%"}>
                      <Select
                        isSearchable={false}
                        defaultValue={{
                          value: monthFilter,
                          label: monthFilter.toString(),
                        }}
                        options={generatFilterArray(6)}
                        onChange={(e) => setMonthFilter(e.value)}
                        styles={colourStyles}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          width: "100%",
                        })}
                      />
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </StatGroup>
          </Box>
        </Flex>
      </GridItem> */}
			<GridItem colSpan={5} rowSpan={1}>
				<Box width={["100%", "95%"]} height={["100%", "100%"]} margin="auto" marginTop="-30px">
					{
						// if statsData is  null  show the loading spinner else show the chart
						subscribedStatsData ? (
							<>
								<Flex justifyContent={"end"} flexWrap={"wrap"}>
									<Box width={["100%", "29%"]}>
										<StatGroup
											marginTop={"0.5vh"}
											border={"1px solid #E5E4E2"}
											borderRadius={"12px"}
											padding={"15px"}
										>
											<Stat>
												<StatNumber fontSize={"2.5rem"} fontWeight={"bold"}>
													{subscribedStatsData.subscribers}
												</StatNumber>
												<StatLabel color={"gray.500"} fontSize={"1.075rem"} fontWeight={"400"}>
													Subscribers
												</StatLabel>

												<Button
													marginTop={"10px"}
													colorScheme="blue"
													variant="outline"
													as={Link}
													to="/statistics?tab=subscribers"
												>
													View More
												</Button>
											</Stat>
										</StatGroup>
									</Box>
									{/* <Box width={["100%", "45%"]}>
                    <Box w={"100%"}> 
                    </Box>
                      <Box width={"100%"}>
                        <StatGroup
                          marginTop={"3vh"}
                          border={"1px solid #E5E4E2"}
                          borderRadius={"12px"}
                          padding={"15px"}
                        >
                          <Stat>
                            <StatLabel  color={"gray.500"} fontSize={"1.075rem"} fontWeight={"400"}>Previous Subscribes</StatLabel>
                            <StatNumber  fontSize={"2.5rem"} fontWeight={"bold"}>
                              {subscribedStatsData.previous_subscribed}
                            </StatNumber>
                            <StatHelpText backgroundColor={"red.100"} w={"65px"} padding={"3px"} borderRadius={"10px"} display={"flex"} alignItems={"center"}>
                              <StatArrow type="decrease" />
                              {
                                // if subscribed_decreased_by is null show 0 else show the value
                                subscribedStatsData.subscribed_decreased_by === null ? (
                                  <Text color={"red.600"}>0%</Text>
                                ) : (
                                  <Text color={"red.600"}>  {subscribedStatsData.subscribed_decreased_by}</Text>
                                )
                              }
                             
                            </StatHelpText>

                            <AvatarGroup size='sm' max={4} marginTop={"45px"}>
                              <Avatar name='Ryan Florence'  />
                              <Avatar name='Segun Adebayo' />
                              <Avatar name='Kent Dodds' />
                              <Avatar name='Prosper Otemuyiwa' />
                              <Avatar name='Christian Nwamba' />
                            </AvatarGroup>
                          </Stat>
                        </StatGroup>
                      </Box>
                  </Box> */}
								</Flex>
							</>
						) : (
							<Spinner
								position="relative"
								top="50%"
								left="50%"
								thickness="4px"
								speed="0.65s"
								emptyColor="gray.200"
								color="blue.500"
								size="xl"
							/>
						)
					}
				</Box>
			</GridItem>
		</Grid>
	);
}

export default Dashboard;
