export const content = {
	en: {
		logged: "Logged in as:",
		client: "Active company:",
		profile: "Profile",
		"edit.profile": "Edit profile",
		logout: "Logout",
	},
	de: {
		logged: "Angemeldet als",
		client: "Aktiver Mandant:",
		profile: "Profil",
		"edit.profile": "Profil bearbeiten",
		logout: "Abmelden",
	},
};
