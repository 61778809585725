import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/form-control";
import { Box } from "@chakra-ui/layout";
import { Button, Text } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/skeleton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCoupon, fetchCoupons } from "../../../store/actions";
import Modal from "../Modal";
import { Table, Thead, Tbody, Tr, Th, Td, Image } from "@chakra-ui/react";
import { getQR } from "../../../utils/imgUri";
import { content } from "../../coupons/list/components/content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tab from "../tabs";
import Client from "../../coupons/new/panels/Client";
import { initialValues } from "../../coupons/new/formInitialValues";
import { validationSchema } from "../../coupons/new/validationSchema";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import PrimaryButton from "../buttons/PrimaryButton";

export default function CouponSelect({ formik, newsId, column }) {
	const [activeTab, setActiveTab] = useState(0);
	const [dataToMap, setDataToMap] = useState([]);
	const dispatch = useDispatch();
	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];
	const history = useHistory();

	const { coupons, fetched } = useSelector(state => state.fetchCoupons);
	const [modalState, setModalState] = useState(false);
	useEffect(() => {
		dispatch(fetchCoupons("published", 1, true, newsId));
	}, []);

	const handleReset = () => formik.setFieldValue("coupon.id", []);

	const handleCouponSelect = (id, color) => {
		const couponItem = coupons.data.find(item => item.id === id);
		formik.setValues({
			...formik.values,
			image: couponItem?.logo,
			couponTitle: couponItem?.title,
			couponPhone: couponItem?.phone_number,
			couponAddress: couponItem?.street ? `${couponItem.street} ${couponItem.street_number} ` : "",
			couponCategory: couponItem?.category?.name,
			coupon: {
				id: formik.values.coupon.id.includes(id)
					? formik.values.coupon.id.filter(it => it !== id)
					: [...formik.values.coupon.id, id],
			},
		});
	};

	function handleSubmit(values, { setErrors }) {
		let data = {
			...values,

			post_end_at: values.post_end_at
				? new Date(values.post_end_at).toISOString().replace(".000Z", "").replace("T", " ")
				: null,
		};
		console.log("submitted");
		dispatch(createCoupon(data, setErrors, history, false, setActiveTab, formik));
	}

	const formikCreate = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false,
	});

	useEffect(() => {
		formikCreate.setFieldValue("news.id", [newsId]);
	}, []);

	useEffect(() => {
		formikCreate.setFieldValue("user.id", []);
		formikCreate.setFieldValue("news.id", []);
	}, [formikCreate.values.visible_for]);

	useEffect(() => {
		formikCreate.setFieldValue("money_value", "");
		formikCreate.setFieldValue("percentage_value", "");
		formikCreate.setFieldValue("previous_price", "");
	}, [formikCreate.values.type]);

	useEffect(() => {
		if (coupons.data) setDataToMap(coupons.data);
	}, [coupons.data]);

	if (!fetched) return <Skeleton height="2em" />;

	const options = {
		Coupons: (
			<Box height="100%" width="100%" display="flex" flexDirection="column">
				<Box pb="3em" flexGrow="1" height="75vh" overflow="scroll" position="relative">
					<Table variant="simple">
						<Thead
							zIndex="2"
							boxShadow="0 3px 0px -2px black"
							position="sticky"
							top="0"
							backgroundColor="#FFF"
						>
							<Tr>
								<Th fontSize="1.2em">{t.title}</Th>
								<Th fontSize="1.2em">{t.coupon_code}</Th>
								<Th fontSize="1.2em">{t.discount}</Th>
								<Th textAlign="center" fontSize="1.2em">
									{t.used_amount}
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{dataToMap.map((item, index) => {
								const color = formik.values.coupon.id.includes(item.id);
								return (
									<Tr
										cursor="pointer"
										onClick={() => handleCouponSelect(item.id, color)}
										_hover={{
											backgroundColor: color ? "#AFDA09" : "#F2F1F0",
										}}
										backgroundColor={color ? "#AFCA09" : "#FFF"}
										key={item.id}
									>
										<Td>{item.title}</Td>
										<Td>
											<Image
												onError={() =>
													setDataToMap(
														dataToMap.map(item => ({
															...item,
															qr_code: `${item.qr_code}?t=${new Date().getTime()}`,
														})),
													)
												}
												title={item.coupon_code}
												alt={item.coupon_code}
												width="10ch"
												src={getQR(item.qr_code) || "test"}
											/>
										</Td>
										<Td>
											{item.percentage_value || item.money_value}{" "}
											{item.type === "percentage" ? "%" : "€"}
										</Td>
										<Td textAlign="center">
											{item.used_amount || "0"}
											{" / "}
											{item.limited_amount || "∞"}
										</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</Box>
				<Box
					py="0.5em"
					flexGrow="1"
					borderTop="2px solid #7C7C7C"
					display="grid"
					gridTemplateColumns="1fr 1fr"
					gridGap="1em"
				>
					<Box display="flex" justifyContent="flex-end" alignItems="center">
						<Text mr="1em">{t.confirm}</Text>
						<Button
							width="3em"
							height="3em"
							borderRadius="100%"
							justifySelf="flex-end"
							_hover={{ bg: "#9BC962" }}
							_focus={{ bg: "#B0D352" }}
							_active={{ bg: "#B0D352" }}
							bg="#D7D7D6"
							onClick={() => {
								setModalState(false);
							}}
						>
							<FontAwesomeIcon color="#FFF" size="lg" icon="check" />
						</Button>
					</Box>
					<Box display="flex" alignItems="center">
						<Button
							onClick={handleReset}
							_hover={{ bg: "#AAAAAD" }}
							_focus={{ bg: "#6C6C6E" }}
							_active={{ bg: "#6C6C6E" }}
							width="3em"
							borderRadius="100%"
							bg="#D7D7D6"
							height="3em"
						>
							<FontAwesomeIcon color="#FFF" size="lg" icon="undo" />
						</Button>
						<Text ml="1em">{t.reset}</Text>
					</Box>
				</Box>
			</Box>
		),
		Create: (
			<Box height="100%" width="100%" display="flex" flexDirection="column">
				<Box pb="3em" flexGrow="1" height="75vh" overflow="scroll" position="relative">
					<Client maxWidth="30vw" newsSelector={false} formik={formikCreate} lang={lang} />
				</Box>
				<Box
					py="0.5em"
					flexGrow="1"
					borderTop="2px solid #7C7C7C"
					display="grid"
					gridTemplateColumns="1fr"
					gridGap="1em"
					pt="2em"
				>
					<PrimaryButton
						hoverColor="#B0D352"
						label={t.publish}
						onClick={() => {
							formikCreate.handleSubmit();
						}}
					/>
				</Box>
			</Box>
		),
	};
	const fields = [[], []];
	return (
		<FormControl
			display="flex"
			flexDirection="column"
			mt="0.5em"
			isInvalid={formik.errors["coupon.id"]}
			id="coupon.id"
		>
			{modalState && (
				<Modal header="Select coupon" handleClose={() => setModalState(false)}>
					<Box m="1em">
						<Tab
							height="100%"
							submitClicked={false}
							formik={formik}
							fields={fields}
							active={activeTab}
							setActive={setActiveTab}
							options={options}
						/>
					</Box>
				</Modal>
			)}
			<FormLabel>{lang === "de" ? "Coupon" : "Coupon"}</FormLabel>
			<Box display="flex" flexDirection="column">
				<Box
					onClick={() => setModalState(true)}
					height="2em"
					borderRadius="0px"
					borderColor="#CBCCCB"
					borderWidth="1px"
					backgroundColor="transparent"
					boxShadow="none"
					_hover={{ backgroundColor: "#F2F1F1" }}
					_focus={{ backgroundColor: "#F2F1F1" }}
					as={Button}
				>
					<Text mr="0.2em">{lang === "de" ? "Gutscheine" : "Coupons"}</Text>

					<Text color="#AFCB2E"> ({formik.values.coupon.id.length})</Text>
				</Box>
				<FormErrorMessage position={column ? "absolute" : "relative"} bottom="-1.2em">
					{formik.errors["coupon.id"]}
				</FormErrorMessage>
			</Box>
		</FormControl>
	);
}
