export const content = {
	en: {
		header: "Events",
		client: "Create event",
		desc: "Description",
		ctitle: "Title",
		location: "Location",
		locations: "Locations",
		start_at: "Start at",
		end_at: "End at",
		confirm: "Next",
		reset: "Reset",
		AlbumText: "AlbumText",
		Builder: "Builder",
		back: "Back",
		publish: "Veröffentlichen",
		price_from: "Price",
		price_to: "Price to",
		price_free: "Price free",
		zoom: "Zoom link",
		google_meet: "Google meet link",
	},
	de: {
		header: "Mandant erstellen",
		client: "Mandat",
		desc: "Beschreibung",
		ctitle: "Titel Mandant",
		location: "Lage",
		locations: "Standorte",
		start_at: "Beginne bei",
		end_at: "Ende um",
		confirm: "Nächste",
		reset: "Zurücksetzten",
		AlbumText: "AlbumText",
		Builder: "Builder",
		back: "Zurück",
		publish: "Veröffentlichen",
		price_from: "Preis",
		price_to: "Preis zu",
		price_free: "Kostenlos",
		zoom: "Zoom link",
		google_meet: "Google meet link",
	},
};
