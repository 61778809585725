import { apiCallAc } from "..";

export function addEvent(data) {
	return apiCallAc.post(`/dashboard/event?fb_page_share=${data.fb_page_share}`, data);
}

export function getEvents(type = "published", page = 1) {
	return apiCallAc.get(`/dashboard/event?&show=${type}&page=${page}`);
}

export function getCategories(page = 1, name) {
	return apiCallAc.get(`dashboard/eventCategory?page=${page}&event_category_id=${name}`);
}

export function getSubCategories(id, page = 1, name) {
	return apiCallAc.get(`dashboard/eventCategory/${id}?page=${page}&event_sub_category_id=${name}`);
}

export function getFormats(page = 1, name) {
	return apiCallAc.get(`dashboard/eventFormat?page=${page}&event_format_id=${name}`);
}

export function getFormat(id) {
	return apiCallAc.get(`dashboard/eventFormat/${id}`);
}

export function getEvent(id) {
	return apiCallAc.get("/dashboard/event/" + id + "/edit");
}

export function updateEvent(id, data) {
	return apiCallAc.put(`/dashboard/event/${id}?fb_page_share=${data.fb_page_share}`, data);
}

export function restoreEvent(id) {
	return apiCallAc.post("/dashboard/trashed/event/" + id);
}

export function deleteEvent(id) {
	return apiCallAc.delete("/dashboard/event/" + id);
}
