import React, { useState, useEffect } from "react";
import ScrollContainer from "../components/ScrollContainer";
import Button from "./components/Button";

import { onSetTextAlignment } from "../../../../../../../../utils/textboxActions";

function Alignment({ state, canvas }) {
	const [selected, setSelected] = useState(false);
	useEffect(() => {
		if (state.activeObject) {
			setSelected(state.activeObject.textAlign);
		}
	}, [state.activeObject]);
	return (
		<ScrollContainer>
			<Button
				type="align-left"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={selected === "left"}
				handleClick={() => {
					onSetTextAlignment(canvas, state, "left");
					setSelected("left");
				}}
			/>
			<Button
				type="align-center"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={selected === "center"}
				handleClick={() => {
					onSetTextAlignment(canvas, state, "center");
					setSelected("center");
				}}
			/>
			<Button
				type="align-right"
				active={state.activeObject !== null && state.activeObject?.type === "textbox"}
				selected={selected === "right" || false}
				handleClick={() => {
					onSetTextAlignment(canvas, state, "right");
					setSelected("right");
				}}
			/>
		</ScrollContainer>
	);
}

export default Alignment;
