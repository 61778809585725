import React from "react";
import { Box, Switch, Text } from "@chakra-ui/react";
import { content } from "../components/content";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import PrimaryTextArea from "../../../shared/inputs/PrimaryTextArea";
import TimeCategories from "../../../shared/inputs/TimeCategories";
import CouponSelect from "../../../shared/inputs/CouponSelect";
import ModalSelect from "../../../shared/inputs/ModalSelect";
import { addMonths, format } from "date-fns";
import DateInput from "../../../shared/inputs/date-input";

function Client({ formik, lang, list, newsId }) {
	const t = content[lang];
	return (
		<Box
			position="relative"
			border={formik.values.is_pinned ? "2px solid #afcb2d" : "2px solid transparent"}
			p="0.5em"
		>
			<Box display="grid" position="absolute" right="0.5em" top="0.5em" zIndex={1}>
				<Box display="flex" alignItems="center">
					<Text mr="1em">{t.is_pinned.toUpperCase()}</Text>
					<Switch
						isChecked={formik.values.is_pinned}
						onChange={e => {
							if (e.target.checked) {
								formik.setFieldValue("is_pinned", true);
							} else {
								formik.setFieldValue("is_pinned", false);
							}
						}}
					/>
				</Box>
			</Box>
			<PrimaryInput column={true} label={t.ctitle} type="text" id="title" formik={formik} />

			<PrimaryTextArea column={true} label={t.desc} type="text" id="description" formik={formik} />
			<ModalSelect
				formik={formik}
				id="location.id"
				fields={["logo", "title", "street", "street_number", "city"]}
				label={lang === "en" ? "Location" : "Lage"}
				header="Select location"
				type="location"
			/>

			<CouponSelect newsId={newsId} formik={formik} />
			<TimeCategories
				formik={formik}
				label={lang === "en" ? "Post start at" : "Beitragsbeginn um"}
				id="post_start_at"
				column={true}
				type="date"
				values={[0, 1, 2, 3, 4]}
				labels={
					lang === "en"
						? ["Now", "30 minutes", "1 hour", "2 hours", "1 day"]
						: ["Jetzt", "30 Minuten", "1 Stunde", "2 Stunden", "1 Tag"]
				}
			/>

			<TimeCategories
				formik={formik}
				label={lang === "en" ? "Post end at" : "Beitragsende um"}
				id="post_end_at_number"
				column={true}
				type="date"
				values={[0, 1, 2, 3, 4]}
				labels={
					lang === "en"
						? ["Never", "1 Month", "2 Months", "3 Months", "6 Month"]
						: ["Noch Nie", "1 Monat", "2 Monate", "3 Monate", "6 Monate"]
				}
				customChange={val => {
					const post_end_at =
						val === 0
							? null
							: val === 1
							? format(addMonths(new Date(), 1), "yyyy-MM-dd'T'HH:mm")
							: val === 2
							? format(addMonths(new Date(), 2), "yyyy-MM-dd'T'HH:mm")
							: val === 3
							? format(addMonths(new Date(), 3), "yyyy-MM-dd'T'HH:mm")
							: format(addMonths(new Date(), 6), "yyyy-MM-dd'T'HH:mm");
					formik.setFieldValue("post_end_at", post_end_at);
					formik.setFieldValue("post_end_at_number", val);
				}}
			/>

			<DateInput
				label="OR"
				formik={formik}
				id="post_end_at"
				customChange={() => {
					formik.setFieldValue("post_end_at_number", 6);
				}}
			/>
		</Box>
	);
}

export default Client;
