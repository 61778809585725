import { Button } from "@chakra-ui/button";

const TabButton = ({ label, index, active, error, gray, ...rest }) => {
	return (
		<Button
			px="2em"
			backgroundColor="transparent"
			position="relative"
			top="2px"
			paddingBottom="2px"
			boxSizing="content-box"
			borderRight={
				active === index && active !== 0 ? (error ? "2px solid red" : "2px solid #000") : "none"
			}
			borderLeft={
				active === index && active !== 0 ? (error ? "2px solid red" : "2px solid #000") : "none"
			}
			_focus={{ outline: "none", backgroundColor: "none" }}
			_hover={{ backgroundColor: "none" }}
			_before={{
				content: `""`,
				background: error ? "red" : index === active ? "#000" : gray ? "#CBCCCB" : "#000",
				position: "absolute",
				top: "0",
				right: index > active || index === 0 ? "0" : "auto",
				left: index < active ? "0" : "auto",
				height: index === active ? "100%" : "80%",
				width: index === active && index !== 0 ? "0px" : "2px",
			}}
			_after={{
				content: `""`,
				transition: "200ms all ease",

				background: error ? "red" : index === active ? "#000" : gray ? "#CBCCCB" : "#000",
				position: "absolute",
				top: "0",
				right: index > active ? "0" : "auto",
				left: index < active ? "0" : "auto",
				height: "2px",
				width: index === active ? "100%" : "calc(100% - 10px)",
			}}
			borderRadius="0"
			color={error ? "red" : index === active ? "#000" : gray ? "#CBCCCB" : "#000"}
			{...rest}
		>
			<span
				style={{
					left: "0",
					transition: "200ms all ease",
					background: index === active ? "#FFF" : "transparent",
					position: "absolute",
					bottom: "0",
					height: "2px",
					width: index === 0 ? "98.5%" : "100%",
				}}
			/>
			{label}
		</Button>
	);
};
export default TabButton;
