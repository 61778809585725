export const initialState = {
	activeObject: null,
	lastUsedColor: false,
	templateLoading: false,
	activePanel: "INIT",
	imageLoading: false,
	liveImg: false,
	preview: false,
	modal: {
		active: false,
		type: null,
	},
	activeSlide: 0,
	triggerRender: false,
};
