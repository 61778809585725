import { chatsActions } from "../../actionTypes";
import { getChatableUsers, fetchNext } from "../../../api/libs/chats";

export const fetchChatableUsers = (page, reset) => {
	return async dispatch => {
		dispatch({
			type: chatsActions.FETCH_CHATABLE_USERS,
			payload: { reset },
		});
		getChatableUsers(page)
			.then(res => {
				dispatch({
					type: chatsActions.FETCH_CHATABLE_USERS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: chatsActions.FETCH_CHATABLE_USERS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};

export const fetchChatableUsersNext = url => {
	return async dispatch => {
		dispatch({
			type: chatsActions.FETCH_CHATABLE_USERS_NEXT,
		});
		fetchNext(url)
			.then(res => {
				dispatch({
					type: chatsActions.FETCH_CHATABLE_USERS_NEXT_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: chatsActions.FETCH_CHATABLE_USERS_NEXT_REJECTED,
					payload: err.response?.data,
				});
			});
	};
};
