export const initialValues = {
	title: "",
	body: "",
	prefer: "push_notification",
	user: {
		id: [],
	},
	data_type: "",
	data_id: "",
};
