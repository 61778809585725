import { keywordActions } from "../../actionTypes";

const initialState = {
	myKeywords: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case keywordActions.FETCH_MY_KEYWORDS: {
			return { ...state, ...initialState };
		}

		case keywordActions.FETCH_MY_KEYWORDS_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				myKeywords: action.payload.data,
			};
		}

		case keywordActions.ADD_KEYWORD: {
			return {
				...state,
				fetching: false,
				fetched: true,
				myKeywords: {
					...state.myKeywords,
					data: [action.payload.data, ...state.myKeywords.data],
				},
			};
		}

		case keywordActions.REMOVE_KEYWORD: {
			return {
				...state,
				fetching: false,
				fetched: true,
				myKeywords: {
					...state.myKeywords,
					data: state.myKeywords.data.filter(item => item.id !== action.payload.id),
				},
			};
		}

		case keywordActions.FETCH_MY_KEYWORDS_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
