import React from "react";
import { Box } from "@chakra-ui/react";

function RightSideLayout(props) {
	return (
		<Box h="100%" display="flex" flexDir="column">
			{props.children}
		</Box>
	);
}

export default RightSideLayout;
