import { userActions } from "../../actionTypes";

const initialState = {
	isAuthenticated: false,
	user: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case userActions.USER_FETCHING: {
			return { ...state, fetching: true };
		}

		case userActions.SET_CURRENT_USER: {
			return { ...state, user: { ...state.user, user: { ...action.payload } } };
		}

		case userActions.USER_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				user: action.payload,
				isAuthenticated: true,
			};
		}

		case userActions.USER_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		case userActions.LOGOUT_USER: {
			return { ...state, ...initialState };
		}

		default:
	}

	return state;
};

export default Reducer;
