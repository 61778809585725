import React from "react";
import { Box, Text } from "@chakra-ui/react";

const Layout = props => {
	return (
		<Box h="100%" px="40px">
			<Box h="100%" py="20px">
				<Text fontSize="22px">Select Filter</Text>
				<Box my="20px" maxH="80vh" display="flex" flexDir="column" overflowY="auto" pr="10px">
					{props.children}
				</Box>
			</Box>
		</Box>
	);
};

export default Layout;
