import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchTemplates, loadTemplate, removeTemplate } from "../../../../../../store/actions";
import { jsonToCanvas } from "../../../utils/canvas";

import Layout from "./components/Layout";
import WrapLayout from "../components/WrapLayout";
import NotFound from "./components/NotFound";
import Loading from "./components/Loading";
import TemplateItem from "./components/TemplateItem";

function Templates({ canvas, variant, setState }) {
	const dispatch = useDispatch();
	const { fetching } = useSelector(state => state.fetchTemplates);
	const data = useSelector(state => state.fetchTemplates.templates.data);
	const { fetching: loadingTemplate, fetched, template } = useSelector(state => state.loadTemplate);

	const { fetching: removingTemplate } = useSelector(state => state.removeTemplate);

	useEffect(() => {
		if (!data) {
			dispatch(fetchTemplates());
		}
	}, []);

	const [selected, setSelected] = useState("");

	const fetchTemplate = id => {
		setSelected(id);
		dispatch(loadTemplate(id));
	};

	const resetTemplateState = () => {
		dispatch({ type: "RESET_LOADED_TEMPLATE" });
		canvas.fire("event:cache", {});
	};

	const onRemoveTemplate = id => {
		setSelected(id);
		dispatch(removeTemplate(id));
	};

	useEffect(() => {
		if (fetched) {
			jsonToCanvas(canvas, template.template, resetTemplateState, setState);
		}
	}, [fetched]);
	const renderTemplateList = () => {
		if (data) {
			return data.map(
				(item, key) =>
					item.type === variant && (
						<TemplateItem
							key={key}
							id={item.id}
							img={item.preview}
							onLoadTemplate={fetchTemplate}
							selected={selected}
							loadingTemplate={loadingTemplate}
							onRemoveTemplate={onRemoveTemplate}
							removingTemplate={removingTemplate}
						/>
					),
			);
		}
	};

	if (fetching) return <Loading />;
	if (data?.length === 0 || !data) return <NotFound />;
	return (
		<Layout>
			<WrapLayout>{renderTemplateList()}</WrapLayout>
		</Layout>
	);
}

export default Templates;
