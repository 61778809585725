import { editorActions } from "../../actionTypes";

const initialState = {
	logo: {
		isEdited: false,
		template: null,
		templateImages: [],
		png: null,
		stateLine: {
			axis: [],
			currentAxisPoint: null,
		},
	},
	slides: [
		{
			template: null,
			templateImages: [],
			png: null,
		},
	],
	lastAddedColor: "black",
};

export const builderState = (state = initialState, action) => {
	switch (action.type) {
		case editorActions.SAVE_LOGO_STATE: {
			return {
				...state,
				isEdited: true,
				logo: { ...state.logo, ...action.payload },
			};
		}
		case editorActions.SAVE_GALLERY_STATE: {
			return { ...state, slides: action.payload };
		}
		case editorActions.UPDATE_COLOR:
			return { ...state, lastAddedColor: action.payload };

		case editorActions.RESET_STATE:
			return { ...initialState };

		case editorActions.ADD_TO_STATELINE:
			return {
				...state,
				logo: {
					...state.logo,
					stateLine: {
						axis: [...state.logo.stateLine.axis, action.payload],
						currentAxisPoint:
							state.logo.stateLine.currentAxisPoint === null
								? 0
								: state.logo.stateLine.currentAxisPoint + 1,
					},
				},
			};

		case editorActions.UNDO:
			return {
				...state,
				logo: {
					...state.logo,
					stateLine: {
						...state.logo.stateLine,
						currentAxisPoint: state.logo.stateLine.currentAxisPoint - 1,
					},
				},
			};

		case editorActions.REDO:
			return {
				...state,
				logo: {
					...state.logo,
					stateLine: {
						...state.logo.stateLine,
						currentAxisPoint: state.logo.stateLine.currentAxisPoint + 1,
					},
				},
			};

		default:
			return state;
	}
};
