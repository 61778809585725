import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { storeToken } from "../../../store/actions";
import { addMessage } from "../../../store/actions/chats/addMessage";
import { requestForToken, onMessageListener } from "./firebase";

const Notification = () => {
	const location = useLocation();
	const [pushToken, setPushToken] = useState("");
	const dispatch = useDispatch();
	const { isAuthenticated } = useSelector(state => state.currentUser);
	const [notification, setNotification] = useState({ title: "", body: "" });
	function ToastDisplay() {
		return (
			<div>
				<p>
					<b>{notification?.title}</b>
				</p>
				<p>{notification?.body}</p>
			</div>
		);
	}
	useEffect(() => {
		if (notification?.title) {
			toast.success(<ToastDisplay />);
		}
	}, [notification]);
	requestForToken(setPushToken);

	useEffect(() => {
		if (isAuthenticated && pushToken) {
			dispatch(
				storeToken({
					device_key: pushToken,
					device_agent: window.navigator.userAgent,
				}),
			);
		}
	}, [pushToken, isAuthenticated]);

	onMessageListener()
		.then(payload => {
			console.log(payload);
			if (!location.pathname.includes(payload.data.roomId)) {
				setNotification({
					title: payload?.notification?.title,
					body: payload?.notification?.body,
				});
			} else {
				setNotification({
					title: "",
					body: "",
				});
				dispatch(
					addMessage({
						title: payload.notification.title,
						message: payload.notification.body,
						mobile_id: payload.data.senderId,
					}),
				);
			}
		})
		.catch(err => console.log("failed: ", err));

	return null;
};

export default Notification;
