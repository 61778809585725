export const initialCanvas = {
	version: "4.6.0",
	objects: [
		{
			type: "line",
			version: "4.6.0",
			originX: "left",
			originY: "top",
			left: 270,
			top: 0,
			width: 0,
			height: 540,
			fill: "red",
			stroke: "red",
			strokeWidth: 1,
			strokeDashArray: null,
			strokeLineCap: "butt",
			strokeDashOffset: 0,
			strokeLineJoin: "miter",
			strokeUniform: false,
			strokeMiterLimit: 4,
			scaleX: 1,
			scaleY: 1,
			angle: 0,
			flipX: false,
			flipY: false,
			opacity: 0,
			shadow: null,
			visible: true,
			backgroundColor: "",
			fillRule: "nonzero",
			paintFirst: "fill",
			globalCompositeOperation: "source-over",
			skewX: 0,
			skewY: 0,
			id: "vertical_snap",
			selectable: false,
			evented: false,
			helper: true,
			x1: 0,
			x2: 0,
			y1: -270,
			y2: 270,
		},
		{
			type: "line",
			version: "4.6.0",
			originX: "left",
			originY: "top",
			left: 0,
			top: 270,
			width: 540,
			height: 0,
			fill: "red",
			stroke: "red",
			strokeWidth: 1,
			strokeDashArray: null,
			strokeLineCap: "butt",
			strokeDashOffset: 0,
			strokeLineJoin: "miter",
			strokeUniform: false,
			strokeMiterLimit: 4,
			scaleX: 1,
			scaleY: 1,
			angle: 0,
			flipX: false,
			flipY: false,
			opacity: 0,
			shadow: null,
			visible: true,
			backgroundColor: "",
			fillRule: "nonzero",
			paintFirst: "fill",
			globalCompositeOperation: "source-over",
			skewX: 0,
			skewY: 0,
			id: "horizontal_snap",
			selectable: false,
			evented: false,
			helper: true,
			x1: -270,
			x2: 270,
			y1: 0,
			y2: 0,
		},
	],
	background: {
		type: "pattern",
		source:
			"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAZklEQVQ4je2TMQ4AIQgEd4m9//+kjT1cMF4LWOskVG5GioVjDEOAmUFE0HsHySi6kDRxyBPeIGzeswh/96l0cAm9tJnQM6paklJV4xW3dM5ZkrbSr+SSlYSpbW/oon8i3uk94SkAPuTMK/qx7kp3AAAAAElFTkSuQmCC",
		repeat: "repeat",
		crossOrigin: "anonymous",
		offsetX: 0,
		offsetY: 0,
		patternTransform: null,
		id: 143,
		helper: true,
	},
};
