import { setTokenHeader } from "../../../api";
import { logOutUser } from "../../../api/libs/user";

export const logOut = callback => {
	return async dispatch => {
		logOutUser()
			.then(res => {
				if (res.success) {
					dispatch({
						type: "USER_LOGOUT",
					});
					dispatch({
						type: "SET_APP_STATE",
						payload: false,
					});
					localStorage.removeItem("token");
					localStorage.removeItem("activeInvoice");
					localStorage.removeItem("activeCompany");
					setTokenHeader(false);
					callback();
				}
			})
			.catch(err => console.log(err));
	};
};
