import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchFacebookPages,
	fetchFacebookToken,
	fetchFacebookUserDetails,
} from "../../../store/actions";
import Layout from "./components/Layout";
import Loading from "../../shared/Loading";
import { useHistory } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import useQuery from "../../../utils/query";
export default function FacebookList() {
	const query = useQuery();
	const dispatch = useDispatch();
	const history = useHistory();
	const [currentId, setCurrentId] = useState(0);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [currentPage, setCurrentPage] = useState(1);

	const { userDetails, fetched } = useSelector(state => state.fetchFacebookUserDetails);
	const { pages, fetched: fetchedPages } = useSelector(state => state.fetchFacebookPages);

	const { token } = useSelector(state => state.fetchFacebookToken);

	const lang = useSelector(state => state.languageProvider.language);

	useEffect(() => {
		if (!fetched) {
			dispatch(fetchFacebookUserDetails());
		}

		if (!fetchedPages) {
			dispatch(fetchFacebookPages(query.get("resync")));
		}
	}, []);

	const resync = () => {
		dispatch(fetchFacebookToken(history));
	};

	if (!fetched) return <Loading />;
	return (
		<Layout
			resync={resync}
			token={token}
			fetching={!fetchedPages}
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			lang={lang}
			userDetails={userDetails.data}
			pages={pages.data}
			currentId={currentId}
			setCurrentId={setCurrentId}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
		/>
	);
}
