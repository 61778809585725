import React from "react";
import { useSelector } from "react-redux";

import Container from "./components/Container";
import Initial from "./components/Initial";
import Texts from "./components/Texts";

function SideBar(props) {
	const { activePanel } = props.state;
	const lang = useSelector(state => state.languageProvider.language);

	const PanleView = () => {
		switch (activePanel) {
			case "INIT":
				return <Initial {...{ ...props, lang }} />;
			case "TEXT":
				return <Texts {...{ ...props, lang }} />;
			default:
				return null;
		}
	};

	return (
		<Container>
			<PanleView />
		</Container>
	);
}

export default SideBar;
