import React, { useState } from "react";
import HorizontalScrollContainer from "./components/HorizontalScrollContainer";
import FilterItem from "./components/FilterItem";

import { applyFilter, removeFilter } from "../../../../../../../utils/objectActions";

function ImageControlls({ canvas }) {
	const [dataCollection] = useState(() => require("./filters.json"));
	const { filters } = dataCollection;
	const [filtersCollection] = useState(() => filters);

	const renderFiltersList = () => {
		if (filtersCollection) {
			return filtersCollection.map((i, k) => (
				<FilterItem
					key={k}
					canvas={canvas}
					img={i.ex}
					name={i.name}
					onApply={() => {
						applyFilter(canvas, i.name);
					}}
					onRemove={() => {
						removeFilter(canvas, i.name);
					}}
				/>
			));
		}
	};

	return <HorizontalScrollContainer>{renderFiltersList()}</HorizontalScrollContainer>;
}

export default ImageControlls;
