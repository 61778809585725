import React, { useEffect, useRef } from "react";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

import { useSlides } from "../../store";

import Layout from "./components/Layout";

function PanoramaView({ state, setState }) {
	const photoSphereRef = useRef();

	const slides = useSlides(state => state.gallery);
	const slide = slides[state.activeSlide];

	let url = typeof slide.pano === "string" ? slide.pano : URL.createObjectURL(slide.pano);

	return (
		<Layout>
			<div
				className="App"
				style={{
					height: "270px",
					width: "100%",
					// zIndex: 100000,
				}}
			>
				<ReactPhotoSphereViewer
					ref={photoSphereRef}
					src={url}
					height={"270"}
					width={"540"}
				></ReactPhotoSphereViewer>
			</div>
		</Layout>
	);
}

export default PanoramaView;
