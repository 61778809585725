import React, { useState } from "react";
import { Box, Text, Image } from "@chakra-ui/react";

function Button({ label, state0, state1, action = () => {} }) {
	const [onDown, setOnDown] = useState(false);
	return (
		<Box
			outline="none"
			display="flex"
			flexDir="column"
			alignItems="center"
			justifyContent="center"
			width="100%"
			height="auto"
			padding="5px 0"
			borderRadius={8}
			onMouseDown={() => setOnDown(true)}
			onTouchStart={() => setOnDown(true)}
			onMouseUp={() => {
				setOnDown(false);
				action();
			}}
			onMouseMove={() => setOnDown(false)}
			onTouchMove={() => setOnDown(false)}
			onTouchEnd={() => {
				setOnDown(false);
			}}
			cursor="pointer"
		>
			<Image src={onDown ? state1 : state0} height="auto" maxHeight={30} cursor="pointer" />
			<Text color={onDown ? "#0564B1" : "black"} fontSize={12}>
				{label}
			</Text>
		</Box>
	);
}

export default Button;
