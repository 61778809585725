import { statisticActions } from "../../actionTypes";
import produce from "immer";

const initialState = {
	stats: {
		location: { countries: {}, platforms: {} },
		news: { countries: {}, platforms: {} },
		event: { countries: {}, platforms: {} },
	},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case statisticActions.FETCH_STATS_COUNTRIES: {
				draft.fetching = true;
				break;
			}

			case statisticActions.FETCH_STATS_COUNTRIES_FULFILLED: {
				draft.fetching = false;
				draft.fetched = true;
				draft.stats[action.payload.refer].countries[action.payload.period] = action.payload.res;
				break;
			}

			case statisticActions.FETCH_STATS_COUNTRIES_REJECTED: {
				draft.fetching = false;
				draft.error = action.payload;
				break;
			}
			case statisticActions.FETCH_STATS_PLATFORMS: {
				draft.fetching = true;
				break;
			}

			case statisticActions.FETCH_STATS_PLATFORMS_FULFILLED: {
				draft.fetching = false;
				draft.fetched = true;
				draft.stats[action.payload.refer].platforms[action.payload.period] = action.payload.res;
				break;
			}

			case statisticActions.FETCH_STATS_PLATFORMS_REJECTED: {
				draft.fetching = false;
				draft.error = action.payload;
				break;
			}

			default:
				return draft;
		}
	});

export default Reducer;
