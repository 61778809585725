import React from "react";
import TabButton from "./TabButton";
import { Box } from "@chakra-ui/react";
import { content } from "./lang";
import useWindowSize from "../../../utils/useWindowSize";
import Select from "react-select";
export default function TabHeader({
	options,
	active,
	setActive,
	lang,
	formik,
	fields,
	submitClicked,
}) {
	const { width } = useWindowSize();

	const t = content[lang];
	const colourStyles = {
		option: (styles, { data, isDisabled, isFocused }) => {
			return {
				...styles,
				backgroundColor: isFocused ? "#3F629D" : data.isValid ? "red" : "",
				color: data.isValid ? "#FFF" : isDisabled ? "gray" : isFocused ? "#FFF" : "black",
				cursor: isDisabled ? "not-allowed" : "pointer",
				textAlign: "center",
			};
		},
		container: styles => ({ ...styles, width: "100%" }),
	};
	const optionsMobile = Object.keys(options).map((key, index) => {
		const ans = fields[index].filter(item => {
			return formik.errors[item] !== undefined;
		});

		return {
			value: index,
			label: key,
			isValid:
				(formik.dirty && !formik.isValid && submitClicked && ans.length) ||
				(ans.length && index === 1 && fields[index].includes("street") && active === 6),

			// isDisabled: ans_two.length,
			isDisabled: false,
		};
	});

	return (
		<Box zIndex={2}>
			<Box display={["flex", "none"]} borderColor="red" width="100%" border="1px solid black">
				<Select
					isSearchable={false}
					value={optionsMobile.find(item => item.value === active)}
					options={optionsMobile}
					onChange={e => setActive(e.value)}
					styles={colourStyles}
					theme={theme => ({
						...theme,
						borderRadius: 0,
						width: "100%",
					})}
				/>
			</Box>
			<Box
				display={["none", "flex"]}
				borderBottom="2px solid #000"
				position="relative"
				flexDirection={width <= 768 ? "column" : "row"}
			>
				{Object.keys(options).map((key, index) => {
					const ans = fields[index].filter(item => {
						return formik.errors[item] !== undefined;
					});
					const ans_two =
						index === 0
							? true
							: fields[index - 1].filter(item => {
									return !formik.dirty || formik.errors[item] !== undefined;
							  });

					return (
						<TabButton
							error={
								(formik.dirty && !formik.isValid && submitClicked && ans.length) ||
								(ans.length && index === 1 && fields[index].includes("street") && active === 6)
							}
							active={active}
							key={index}
							index={index}
							onClick={() => {
								setActive(index);
							}}
							label={t[key]}
							disabled={ans_two.length}
						></TabButton>
					);
				})}
			</Box>
		</Box>
	);
}
