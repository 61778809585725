import { stripeActions } from "../../actionTypes";
import { getSubscriptions } from "../../../api/libs/stripe";

export const fetchStripeSubscriptions = (page, reset) => {
	return async dispatch => {
		dispatch({
			type: stripeActions.FETCH_STRIPE_SUBSCRIPTIONS,
			payload: { reset },
		});
		getSubscriptions(page)
			.then(res => {
				dispatch({
					type: stripeActions.FETCH_STRIPE_SUBSCRIPTIONS_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: stripeActions.FETCH_STRIPE_SUBSCRIPTIONS_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
