import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
	title: Yup.string().required(),
	description: Yup.string().required(),

	location: Yup.object().shape({
		id: Yup.array().min(1).required(),
	}),
	gallery: Yup.array().min(1).required(),
	keywords: Yup.array().min(1).required(),
});
