import { chatsActions } from "../../actionTypes";
import { addChat } from "../../../api/libs/chats";
import { fetchChatableUsers } from "./fetchChatableUsers";

export const createChat = (data, history, setSelected) => {
	return async dispatch => {
		dispatch({
			type: chatsActions.CREATE_CHAT,
		});
		addChat(data)
			.then(res => {
				console.log(res.data);
				dispatch({
					type: chatsActions.CREATE_CHAT_FULFILLED,
					payload: res.data,
				});
				dispatch(fetchChatableUsers());
				history.push(`/chats/${res.data.id}`);
				setSelected(0);
			})
			.catch(err => {
				dispatch({
					type: chatsActions.CREATE_CHAT_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
