import { statisticActions } from "../../actionTypes";
import { getStatsId } from "../../../api/libs/stats";

export const fetchStatsById = config => {
	return async dispatch => {
		dispatch({
			type: statisticActions.FETCH_STATS_ID,
		});
		getStatsId(config)
			.then(res => {
				dispatch({
					type: statisticActions.FETCH_STATS_ID_FULFILED,
					payload: { ...config, res: res.data },
				});
			})
			.catch(err => {
				dispatch({
					type: statisticActions.FETCH_STATS_ID_REJECTED,
					payload: err.response,
				});
			});
	};
};
