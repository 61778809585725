import React from "react";

import { Box } from "@chakra-ui/react";
import { calculateDimensions } from "../../../../../utils/dimensions";

function Layout(props) {
	let dimensions = calculateDimensions();
	return (
		<Box
			position="absolute"
			top="35px"
			left="0"
			h={dimensions.squere.height}
			w={dimensions.squere.height}
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			backgroundColor="white"
		>
			{props.children}
		</Box>
	);
}

export default Layout;
