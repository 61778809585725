import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Draggable } from "react-beautiful-dnd";

function LayerItem({ selectedObj, item, index, onSelect, onToogle, onRemove, forceReRender }) {
	return (
		<Draggable draggableId={item.uuid} index={index}>
			{(provided, snapshotDrag) => {
				let transform = provided.draggableProps.style.transform;

				if (snapshotDrag.isDragging && transform) {
					transform = transform.replace(/\(.+,/, "(0,");
				}

				const style = {
					...provided.draggableProps.style,
					transform,
				};
				return (
					<div {...provided.draggableProps} ref={provided.innerRef} style={style}>
						<Box
							display="flex"
							bg="#F2F1F1"
							border={`1px solid ${selectedObj === item ? "#9F9F9E" : "#CBCCCB"}`}
							marginTop={"5px"}
							h="60px"
							onClick={() => {
								onSelect(item);
							}}
						>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								px="10px"
								borderRight={`1px solid ${selectedObj === item ? "#9F9F9E" : "#CBCCCB"}`}
							>
								{item.type === "textbox" ? (
									<FontAwesomeIcon
										icon="font"
										color="#A5A4A4"
										style={{ width: "3em", height: "3em" }}
									/>
								) : (
									<Image width="3em" src={item.getSrc().replace("original", "thumbs")} />
								)}
							</Box>
							<Box display="flex" justifyContent="center" alignItems="center" px="10px" mr="auto">
								<Text wordBreak="break-all" noOfLines="2">
									{item.type === "textbox" ? item.text : item.cacheKey}
								</Text>
							</Box>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								borderLeft={`1px solid ${selectedObj === item ? "#9F9F9E" : "#CBCCCB"}`}
								p="2px"
								px="4px"
							>
								<Box
									textAlign="center"
									cursor="pointer"
									onClick={e => {
										e.stopPropagation();
										onToogle(item);
										forceReRender();
									}}
									mr="3px"
								>
									<FontAwesomeIcon
										icon={item.opacity === 0 ? "eye-slash" : "eye"}
										color="#A5A4A4"
										fontSize={20}
									/>
								</Box>
								<Box
									p="2px"
									textAlign="center"
									cursor="pointer"
									onClick={e => {
										e.stopPropagation();
										onRemove(item);
									}}
								>
									<FontAwesomeIcon icon="trash-alt" color="#A5A4A4" fontSize={20} />
								</Box>
							</Box>
							<Box
								minW="25px"
								h="100%"
								// ml="auto"
								bg="#CBCCCB"
								cursor="pointer"
								borderLeft={`1px solid ${selectedObj === item ? "#9F9F9E" : "#CBCCCB"}`}
								{...provided.dragHandleProps}
							/>
						</Box>
					</div>
				);
			}}
		</Draggable>
	);
}

export default LayerItem;
