import { customActions } from "../../actionTypes";
const initialState = true;

export const appLoading = (state = initialState, action) => {
	switch (action.type) {
		case customActions.SET_APP_STATE:
			return action.payload;
		default:
			return state;
	}
};
