import React, { useState, useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";

function FilterItem({ img, onApply, onRemove, canvas, name }) {
	const [loaded, setLoaded] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const onLoad = () => {
		setLoaded(true);
	};

	const checkIfApplied = () => {
		if (canvas) {
			if (canvas.getActiveObject()) {
				let ao = canvas.getActiveObject();
				if (ao.type === "image") {
					let bool = false;
					for (let i = 0; i < ao.filters.length; i++) {
						if (ao.filters[i].type === name) {
							bool = true;
							setIsActive(true);
							return;
						}
					}
					return bool;
				}
			}
		}
	};
	useEffect(() => {
		checkIfApplied();
	}, [isActive]);

	const toogleFilter = () => {
		if (loaded) {
			if (isActive) {
				setIsActive(false);
				onRemove();
			} else {
				if (canvas?.getActiveObject()?.type === "image") {
					setIsActive(true);
				}
				onApply();
			}
		}
	};
	return (
		<Box
			minWidth={100}
			h={50}
			mr={1}
			border={isActive ? "5px solid #AFCB02" : "5px solid white"}
			onClick={toogleFilter}
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			{!loaded && <Skeleton height="100%" width="100%" />}
			<Image
				src={require(`../../../../../../../../../../../assets/builder/filters/${img}`).default}
				maxH={35}
				w="100%"
				onLoad={onLoad}
				userSelect="none"
				draggable={false}
				fit="cover"
			/>
		</Box>
	);
}

export default FilterItem;
