import React from "react";
import { Table as ChakraTable } from "@chakra-ui/react";
import { Provider } from "../utils";
import useWindowSize from "../../../utils/useWindowSize";

export const Table = ({ className, ...rest }) => {
	const classes = `${className || ""} responsiveTable`;
	const { width } = useWindowSize();

	return (
		<Provider value={{}}>
			<ChakraTable variant={width <= 768 ? "striped" : "simple"} {...rest} className={classes} />
		</Provider>
	);
};
