import { countriesActions } from "../../actionTypes";

const initialState = {
	states: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case countriesActions.FETCH_STATES: {
			return { ...state, fetching: true };
		}

		case countriesActions.FETCH_STATES_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				states: action.payload,
			};
		}

		case countriesActions.FETCH_STATES_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
			return state;
	}
};
export default Reducer;
