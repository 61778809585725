import { fabric } from "fabric";
import { generateUUID } from "../../../../utils/uuid";
import { dimensionsObj } from "../static/canvasDimensions";

import deleteIcon from "../../../../assets/builder/remove_obj_1.svg";
import resizeIcon from "../../../../assets/builder/resize_1.svg";
import alIcon from "../../../../assets/builder/canvas/al.svg";
import acIcon from "../../../../assets/builder/canvas/ac.svg";
import arIcon from "../../../../assets/builder/canvas/ar.svg";
import iIcon from "../../../../assets/builder/canvas/i.svg";

export function onAddText(canvas, setState) {
	const text = new fabric.Textbox("Some text here", {
		left: 100,
		top: 100,
		width: 250,
		fontSize: 30,
		textAlign: "center",
		centeredRotation: true,
		uuid: generateUUID(),
	});

	const { length } = canvas.getObjects();
	text.index = length;

	canvas.add(text);
	canvas.centerObject(text).setActiveObject(text).renderAll();

	setState(state => {
		state.activeObject = text;
	});
	canvas.fire("event:render-live", {});
	canvas.fire("event:cache", { eName: "add-text", from: "fabricActions" });
}

export function onLoadImage(canvas, img, url) {
	fabric.Image.fromURL(url, canvasObject => {
		let image;
		canvasObject.scaleToWidth(400).scaleToHeight(260);

		image = canvasObject
			.set({
				left: 0,
				top: 0,
				angle: 0,
				centeredRotation: true,
				uuid: generateUUID(),
			})
			.center();

		image.index = canvas.getObjects().length;

		canvas.add(image);
		canvas.centerObject(image).setActiveObject(image).renderAll();
		canvas.fire("event:render-live", {});
		canvas.fire("event:cache", { eName: "add-image", from: "fabricActions" });
	});

	// ZUSTAND SOLUTION -------

	// if (!state.slides[state.activeSlide].original) {
	//   setState((state) => {
	//     state.slides[state.activeSlide].original = img;
	//   });
	// }
}

export function loadPreviewImage(canvas, url) {
	fabric.Image.fromURL(
		url,
		canvasObject => {
			let image;
			if (canvasObject.height === canvasObject.width) {
				canvasObject
					.scaleToWidth(dimensionsObj.squere.width)
					.scaleToHeight(dimensionsObj.squere.height);
			} else if (canvasObject.height > canvasObject.width) {
				canvasObject
					.scaleToWidth(dimensionsObj.portrait.width)
					.scaleToHeight(dimensionsObj.portrait.height);
			} else {
				canvasObject
					.scaleToWidth(dimensionsObj.landscape.width)
					.scaleToHeight(dimensionsObj.landscape.height);
			}
			image = canvasObject
				.set({
					left: 0,
					top: 0,
					angle: 0,
					centeredRotation: true,
					uuid: generateUUID(),
				})
				.center();
			image.index = canvas.getObjects().length;
			canvas.add(image);
			canvas.centerObject(image).setActiveObject(image).renderAll();
			canvas.fire("event:render-live", {});
		},
		{ crossOrigin: "*" },
	);
}

// export function onLoadSticker(canvas, name, setLoading) {
//   fabric.Image.fromURL(
//     require(`../../../../assets/stickers/${name}`).default,
//     (canvasObject) => {
//       let image;
//       canvasObject.scaleToWidth(100);
//       image = canvasObject
//         .set({
//           left: 0,
//           top: 0,
//           angle: 0,
//           centeredRotation: true,
//           uuid: generateUUID(),
//         })
//         .center();

//       image.index = canvas.getObjects().length;

//       canvas.add(image);
//       canvas.centerObject(image).setActiveObject(image).renderAll();
//       if (setLoading) {
//         setLoading(false);
//       }
//     }
//   );
//   canvas.fire("event:render-live", {});
//   canvas.fire("event:cache", { eName: "add-sticker", from: "fabricActions" });
// }

export function onLoadSticker(canvas, name, setLoading, url = false) {
	console.log("adding sticker, URL", url);
	if (!url) {
		fabric.Image.fromURL(require(`../../../../assets/stickers/${name}`).default, canvasObject => {
			let image;
			canvasObject.scaleToWidth(100);
			image = canvasObject
				.set({
					left: 0,
					top: 0,
					angle: 0,
					centeredRotation: true,
					uuid: generateUUID(),
				})
				.center();

			image.index = canvas.getObjects().length;

			canvas.add(image);
			canvas.centerObject(image).setActiveObject(image).renderAll();
			if (setLoading) {
				setLoading(false);
			}
		});
	} else {
		fabric.Image.fromURL(
			name,
			canvasObject => {
				let image;
				canvasObject.scaleToWidth(100);
				image = canvasObject
					.set({
						left: 0,
						top: 0,
						angle: 0,
						centeredRotation: true,
						uuid: generateUUID(),
						crossOrigin: "anonymous",
					})
					.center();

				image.index = canvas.getObjects().length;

				canvas.add(image);
				canvas.centerObject(image).setActiveObject(image).renderAll();
				if (setLoading) {
					setLoading(false);
				}
			},
			{ crossOrigin: "*" },
		);
	}
	canvas.fire("event:render-live", {});
	canvas.fire("event:cache", { eName: "add-sticker", from: "fabricActions" });
}

function deleteObject(eventData, transform) {
	var target = transform.target;
	var canvas = target.canvas;
	canvas.remove(target);
	canvas.renderAll();
	canvas.fire("event:render-live", {});
	canvas.fire("event:cache", {
		eName: "remove-Object",
		from: "fabricActions",
	});
}

export const onDrawBorders = () => {
	var deleteImg = document.createElement("img");
	deleteImg.src = deleteIcon;
	var resizeImg = document.createElement("img");
	resizeImg.src = resizeIcon;

	var alImage = document.createElement("img");
	alImage.src = alIcon;
	var acImage = document.createElement("img");
	acImage.src = acIcon;
	var arImage = document.createElement("img");
	arImage.src = arIcon;
	var iImage = document.createElement("img");
	iImage.src = iIcon;

	// fabric.Object.prototype.borderOpacityWhenMoving = 1;
	fabric.Object.prototype.cornerStyle = "circle";
	fabric.Object.prototype.transparentCorners = false;
	fabric.Object.prototype.cornerColor = "#9F9F9E";
	fabric.Object.prototype.cornerSize = 10;
	fabric.Object.prototype.hasBorders = true;
	fabric.Object.prototype.borderColor = "#9F9F9E";
	fabric.Object.prototype.borderDashArray = [3];
	fabric.Object.prototype.setControlsVisibility({
		ml: false,
		mb: false,
		mr: false,
		mt: false,
		mtr: false,
	});

	function renderDelete(ctx, left, top, styleOverride, fabricObject) {
		let size = this.cornerSize;
		let txtSize =
			ctx.measureText(
				`| ${fabricObject.angle}° | ${(fabricObject.width * fabricObject.scaleX).toFixed(0)}px°`,
			).width + 7;
		ctx.save();
		ctx.translate(left, top);
		ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
		ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size);
		ctx.fillStyle = "black";
		ctx.strokeStyle = "white";
		ctx.fillRect(-(size / 2 + 1), -size / 2, -(txtSize + 10), size);
		ctx.strokeRect(-(size / 2 + 1), -size / 2, -(txtSize + 10), size);
		ctx.fillStyle = "white";
		if (fabricObject.type === "textbox") {
			if (fabricObject.textAlign === "left") {
				ctx.drawImage(alImage, -(txtSize + size - 5), -size / 2 + 7, 10, 10);
			}
			if (fabricObject.textAlign === "center") {
				ctx.drawImage(acImage, -(txtSize + size - 5), -size / 2 + 7, 10, 10);
			}
			if (fabricObject.textAlign === "right") {
				ctx.drawImage(arImage, -(txtSize + size - 5), -size / 2 + 7, 10, 10);
			}
		} else {
			ctx.drawImage(iImage, -(txtSize + size - 5), -size / 2 + 7, 10, 10);
		}
		ctx.fillText(
			`| ${(fabricObject.width * fabricObject.scaleX).toFixed(0)}px |`,
			-(txtSize + 5),
			3,
		);
		ctx.fillText(`${fabricObject.angle}°`, -(txtSize - 35), 3);
		ctx.restore();
	}

	function renderResize(ctx, left, top, styleOverride, fabricObject) {
		let size = this.cornerSize;
		ctx.save();
		ctx.translate(left, top);
		ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
		ctx.drawImage(resizeImg, -size / 2, -size / 2, size, size);
		ctx.restore();
	}

	fabric.Object.prototype.controls.deleteControl = fabric.Textbox.prototype.controls.deleteControl =
		new fabric.Control({
			x: 0.4,
			y: 0.56,
			cursorStyle: "pointer",
			mouseUpHandler: deleteObject,
			render: renderDelete,
			cornerSize: 24,
		});

	fabric.Object.prototype.controls.tr = fabric.Textbox.prototype.controls.tr = new fabric.Control({
		x: 0.5,
		y: -0.5,
		cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
		actionHandler: fabric.controlsUtils.scalingEqually,
		render: renderResize,
		cornerSize: 24,
	});
};

export const onDrawSnapHelperLines = canvas => {
	let vertical = new fabric.Line([canvas.width / 2, 0, canvas.width / 2, canvas.height], {
		fill: "red",
		stroke: "red",
		strokeWidth: 1,
		selectable: false,
		evented: false,
		opacity: 0,
		id: "vertical_snap",
		helper: true,
	});
	vertical.selectable = false;
	vertical.evented = false;
	canvas.add(vertical).renderAll();

	let horizontal = new fabric.Line([0, canvas.height / 2, canvas.width, canvas.height / 2], {
		fill: "red",
		stroke: "red",
		strokeWidth: 1,
		selectable: false,
		evented: false,
		opacity: 0,
		id: "horizontal_snap",
		helper: true,
	});
	horizontal.selectable = false;
	horizontal.evented = false;
	canvas.add(horizontal).renderAll();
};
