import { paypalActions } from "../../actionTypes";
import { getSubscription } from "../../../api/libs/paypal";

export const fetchPaypalSubscription = id => {
	return async dispatch => {
		dispatch({
			type: paypalActions.FETCH_PAYPAL_SUBSCRIPTION,
		});
		getSubscription(id)
			.then(res => {
				dispatch({
					type: paypalActions.FETCH_PAYPAL_SUBSCRIPTION_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: paypalActions.FETCH_PAYPAL_SUBSCRIPTION_REJECTED,
					payload: err.response,
				});
			});
	};
};
