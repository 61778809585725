import { userActions } from "../../actionTypes";
import apiCall, { setTokenHeader } from "../../../api";
import { registerUserRef } from "../../../api/libs/user";
import toast from "react-hot-toast";

export const registerUser = (userData, setErrors) => {
	return async dispatch => {
		dispatch({
			type: userActions.REGISTER_USER,
		});
		apiCall
			.post("/auth/register/client", JSON.stringify(userData))
			.then(res => {
				window.localStorage.setItem("token", res.access_token);
				setTokenHeader(res.access_token);
				dispatch({
					type: userActions.REGISTER_USER_FULFILLED,
					payload: res,
				});
				dispatch({
					type: userActions.USER_FULFILLED,
					payload: res,
				});
				toast.success("Success");
			})
			.catch(err => {
				setErrors(err.response.data.errors);
				dispatch({
					type: userActions.REGISTER_USER_REJECTED,
					payload: err.response.data,
				});
			});
	};
};

export const registerUserWithRef = (userData, ref, setErrors) => {
	return async dispatch => {
		dispatch({
			type: userActions.REGISTER_USER,
		});
		registerUserRef(userData, ref)
			.then(res => {
				if (res.success) {
					dispatch({
						type: userActions.REGISTER_USER_FULFILLED,
						payload: res.success,
					});
					toast.success("Success");
				}
			})
			.catch(err => {
				setErrors(err.response.data.errors);
				dispatch({
					type: userActions.REGISTER_USER_REJECTED,
					payload: err.response.data,
				});
			});
	};
};

export const resetRegisterState = () => {
	return dispatch => {
		dispatch({
			type: userActions.RESET_REGISTER_STATE,
		});
	};
};
