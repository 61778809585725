import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	Box,
	Flex,
	Icon,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorModeValue,
	Image,
	Button,
} from "@chakra-ui/react";
// Custom components
import { BsCircleFill } from "react-icons/bs";
import Logo from "../../assets/images/logo.svg";
import { useHistory } from "react-router-dom";
import "./../../styles/hidden.css";
import { verifyEmail } from "../../api/libs/user";
function PublicLayout(props) {
	const textColor = useColorModeValue("gray.700", "white");
	const [activeBullets, setActiveBullets] = useState({
		about: true,
		account: false,
		address: false,
	});

	const history = useHistory();

	const aboutTab = useRef();
	const accountTab = useRef();
	const addressTab = useRef();

	// get url params
	console.log(window.location.pathname);
	const url = window.location.pathname;

	// set hidden attribute always to true

	// if url contains token

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (url === "/create-company" || url === "/") {
			setActiveBullets({
				about: true,
				account: false,
				address: false,
			});
		} else if (url === "/invoices" || url === "/invoices/new") {
			setActiveBullets({
				about: true,
				account: true,
				address: false,
			});
		} else if (url === "/subscription" || url === "/checkout") {
			setActiveBullets({
				about: true,
				account: true,
				address: true,
			});
		} else if (url === "/email/verify/" || url === "/email/verify") {
			const token = new URLSearchParams(window.location.search).get("token");
			console.log(token);
			setLoading(true);
			verifyEmail({ token }).then(res => {
				console.log(res);
				setLoading(false);
				if (res.success === true) {
					history.push("/create-company");
					setActiveBullets({
						about: true,
						account: false,
						address: false,
					});
				}
			});
		}
	}, [url, setActiveBullets]);

	return (
		<>
			{url === "/select-company" ? (
				<Flex
					direction="column"
					align="center"
					justify="center"
					textAlign="center"
					w="80%"
					mx="auto"
				>
					{props.children}
				</Flex>
			) : (
				<>
					{/* <Box w={"full"} paddingBottom="20px" height="auto" overflow="scroll">
              <Flex flexDirection="row" justifyContent="space-between">
                <Box width="60%">
                  <Link to="/">
                    <Image src={Logo} />
                  </Link>
                </Box>
              </Flex>

            </Box> */}
					<Flex direction="column" minH="auto" align="center" pt={{ sm: "125px", lg: "10px" }}>
						<Flex direction="column" textAlign="center" mb={{ sm: "25px", md: "45px" }}>
							<Text
								color={textColor}
								fontSize={{ sm: "2xl", md: "3xl", lg: "4xl" }}
								fontWeight="bold"
								mb="8px"
							>
								Build your profile
							</Text>
							<Text color="gray.400" fontWeight="normal" fontSize={{ sm: "sm", md: "lg" }}>
								This information will let us know more about you.
							</Text>
						</Flex>
						<Tabs isLazy={false} variant="unstyled" mt="24px" display="flex" flexDirection="column">
							<TabList display="flex" align="center" alignSelf="center" justifySelf="center">
								<Tab
									ref={aboutTab}
									_focus="none"
									w={{ sm: "120px", md: "250px", lg: "300px" }}
									onClick={() =>
										setActiveBullets({
											about: true,
											account: false,
											address: false,
										})
									}
								>
									<Flex
										direction="column"
										justify="center"
										align="center"
										position="relative"
										_before={{
											content: "''",
											width: { sm: "120px", md: "250px", lg: "300px" },
											height: "3px",
											bg: activeBullets.account ? textColor : "gray.200",
											left: { sm: "12px", md: "26px" },
											top: { sm: activeBullets.about ? "6px" : "4px", md: null },
											position: "absolute",
											bottom: activeBullets.about ? "40px" : "38px",
											zIndex: -1,
											transition: "all .3s ease",
										}}
									>
										<Icon
											as={BsCircleFill}
											color={activeBullets.about ? textColor : "gray.300"}
											w={activeBullets.about ? "16px" : "12px"}
											h={activeBullets.about ? "16px" : "12px"}
											mb="8px"
										/>
										<Text
											color={activeBullets.about ? { textColor } : "gray.300"}
											fontWeight={activeBullets.about ? "bold" : "normal"}
											display={{ sm: "none", md: "block" }}
											fontSize="sm"
										>
											Company
										</Text>
									</Flex>
								</Tab>
								<Tab
									ref={accountTab}
									_focus="none"
									w={{ sm: "120px", md: "250px", lg: "300px" }}
									onClick={() =>
										setActiveBullets({
											about: true,
											account: true,
											address: false,
										})
									}
								>
									<Flex
										direction="column"
										justify="center"
										align="center"
										position="relative"
										_before={{
											content: "''",
											width: { sm: "120px", md: "250px", lg: "300px" },
											height: "3px",
											bg: activeBullets.address ? textColor : "gray.200",
											left: { sm: "12px", md: "28px" },
											top: { sm: activeBullets.account ? "6px" : "4px", md: null },
											position: "absolute",
											bottom: activeBullets.account ? "40px" : "38px",
											zIndex: -1,
											transition: "all .3s ease",
										}}
									>
										<Icon
											as={BsCircleFill}
											color={activeBullets.account ? textColor : "gray.300"}
											w={activeBullets.account ? "16px" : "12px"}
											h={activeBullets.account ? "16px" : "12px"}
											mb="8px"
										/>
										<Text
											color={activeBullets.account ? { textColor } : "gray.300"}
											fontWeight={activeBullets.account ? "bold" : "normal"}
											transition="all .3s ease"
											fontSize="sm"
											_hover={{ color: textColor }}
											display={{ sm: "none", md: "block" }}
										>
											Invoices
										</Text>
									</Flex>
								</Tab>
								<Tab
									ref={addressTab}
									_focus="none"
									w={{ sm: "120px", md: "250px", lg: "300px" }}
									onClick={() =>
										setActiveBullets({
											about: true,
											account: true,
											address: true,
										})
									}
								>
									<Flex
										direction="column"
										justify="center"
										align="center"
										position="relative"
										_before={{
											content: "''",
											width: { sm: "120px", md: "250px", lg: "300px" },
											height: "3px",
											// bg: activeBullets.profile ? textColor : "gray.200",
											left: { sm: "12px", md: "32px" },
											top: { sm: activeBullets.address ? "6px" : "4px", md: null },
											position: "absolute",
											bottom: activeBullets.address ? "40px" : "38px",
											zIndex: -1,
											transition: "all .3s ease",
										}}
									>
										<Icon
											as={BsCircleFill}
											color={activeBullets.address ? textColor : "gray.300"}
											w={activeBullets.address ? "16px" : "12px"}
											h={activeBullets.address ? "16px" : "12px"}
											mb="8px"
										/>
										<Text
											color={activeBullets.address ? { textColor } : "gray.300"}
											fontWeight={activeBullets.address ? "bold" : "normal"}
											transition="all .3s ease"
											fontSize="sm"
											_hover={{ color: textColor }}
											display={{ sm: "none", md: "block" }}
										>
											Checkout
										</Text>
									</Flex>
								</Tab>
							</TabList>
							<TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
								<TabPanel w={{ sm: "330px", md: "700px", lg: "1130px" }} mx="auto">
									<Box>
										<Flex mb="40px" display={"block"}>
											<Flex
												direction="column"
												align="center"
												justify="center"
												textAlign="center"
												w="80%"
												mx="auto"
											>
												{url === "/"
													? props.children
													: url === "/create-company"
													? props.children
													: url === "email/verify"
													? props.children
													: null}
											</Flex>
										</Flex>
									</Box>
								</TabPanel>
								<TabPanel
									w={{ sm: "330px", md: "700px", lg: "1130px" }}
									mx="auto"
									display={"block"}
								>
									<Box display={"block"}>
										<Flex mb="40px" display={"block"}>
											<Flex
												direction="column"
												align="center"
												justify="center"
												textAlign="center"
												w="80%"
												mx="auto"
											>
												{url === "/invoices" ? (
													// <Flex direction="column" align="center" justify="center" textAlign="center" w="80%" mx="auto"
													// >
													//   <Text fontSize="2xl" fontWeight="bold" color="gray.500" mb="20px">No invoices yet</Text>
													//   <Text fontSize="md" color="gray.500" mb="20px">Create an invoice by clicking the button below</Text>
													//   <Button
													//     colorScheme="blue"
													//     variant="solid"
													//     w="200px"
													//     h="50px"
													//     borderRadius="10px"
													//     fontSize="md"
													//     fontWeight="bold"
													//     onClick={() => history.push("/invoices/new")}
													//   >
													//     Create Invoice
													//   </Button>
													// </Flex>
													<Flex>
														<Flex
															direction="column"
															align="center"
															justify="center"
															textAlign="center"
															w="80%"
															mx="auto"
														>
															{props.children}
														</Flex>
													</Flex>
												) : url === "/invoices/new" ? (
													props.children
												) : null}
											</Flex>
										</Flex>
									</Box>
								</TabPanel>
								<TabPanel margin w={{ sm: "330px", md: "700px", lg: "100%" }} mx="auto">
									<Box marginTop={"-16vh"}>
										<Flex mb="40px">
											{url === "/subscription"
												? props.children
												: url === "/checkout"
												? props.children
												: null}
										</Flex>
									</Box>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Flex>
				</>
			)}
		</>
	);
}

export default PublicLayout;
