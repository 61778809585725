import React, { useEffect, useState, memo } from "react";

import ModalLayout from "./components/ModalLayout";

import Stickers from "./Stickers";
import Layers from "./Layers";
import Picker from "./Picker";

function Modal({ state, setState, canvas, variant }) {
	const type = state.modal.type;
	const [header, setHeader] = useState("");
	useEffect(() => {
		if (type === "stickers") {
			setHeader("Stickers");
		}
		if (type === "layers") {
			setHeader("Layers");
		}
		if (type === "picker") {
			setHeader("Color Picker");
		}
	}, [type]);

	const onCloseModal = () => {
		setState(state => {
			state.modal = {
				active: false,
				type: null,
			};
		});
	};

	const ModalCotent = () => {
		switch (type) {
			case "layers": {
				return <Layers {...{ state, setState, canvas, variant }} />;
			}
			case "stickers": {
				return <Stickers canvas={canvas} variant={variant} />;
			}
			case "picker": {
				return <Picker {...{ canvas, state }} />;
			}
			default:
				return null;
		}
	};
	return (
		<ModalLayout {...{ header, onCloseModal, type }}>
			<ModalCotent />
		</ModalLayout>
	);
}

export default memo(Modal);
