import React from "react";
import { Heading } from "@chakra-ui/react";
import { Box, Text } from "@chakra-ui/layout";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import CountrySelect from "../../../shared/inputs/countrySelect";
import { content } from "./content";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";

export default function Layout({ formik, lang }) {
	const t = content[lang];

	return (
		<Box p={5} position="absolute" top="0" bottom="0" right="0" width="100%" overflow="scroll">
			<Heading pt={["2em", "0"]} mb="1em">
				{t.edit_profile}
			</Heading>
			<form style={{ display: "flex", flexDirection: "column" }} onSubmit={formik.handleSubmit}>
				<PrimaryInput label={t.name} type="text" id="name" formik={formik} />
				<PrimaryInput label={t.surname} type="text" id="surname" formik={formik} />
				<PrimaryInput label={t.phone_number} type="text" id="phone_number" formik={formik} />
				<PrimaryInput label={t.vat_number} type="text" id="vat_number" formik={formik} />
				<Text mt="1em" fontSize="1.5em" mb="0.5em">
					{t.address}
				</Text>
				<CountrySelect countryLabel={true} formik={formik} />

				<PrimaryInput label={t.street} type="text" id="street" formik={formik} />
				<PrimaryInput label={t.postal_code} type="text" id="postal_code" formik={formik} />

				<PrimaryButton mt="0.5em" alignSelf="flex-end" type="submit" label={t.submit} />
			</form>
		</Box>
	);
}
