export const onInputImg = (setState, fileInput) => {
	setState(state => {
		state.imageLoading = true;
	});
	fileInput.current.click();
};

const load = url =>
	new Promise(resolve => {
		const img = new Image();
		img.onload = () => resolve({ height: img.height, width: img.width });
		img.src = url;
	});

export const getOrientation = async url => {
	const { height, width } = await load(url);
	if (height === width) {
		return "squere";
	} else if (height > width) {
		return "portrait";
	} else {
		return "landscape";
	}
};

export const preview = () => {};
