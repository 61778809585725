import React, { useState } from "react";

import Container from "./components/Container";
import Button from "./components/Button";

import addText0 from "../../../assets/icons/addText0.png";
import addText1 from "../../../assets/icons/addText1.png";
import addMedia0 from "../../../assets/icons/addMedia0.png";
import addMedia1 from "../../../assets/icons/addMedia1.png";
import stickers0 from "../../../assets/icons/stickers0.png";
import stickers1 from "../../../assets/icons/stickers1.png";
import layers0 from "../../../assets/icons/layers0.png";
import layers1 from "../../../assets/icons/layers1.png";
import removeVideo0 from "../../../assets/icons/removeVideo0.png";
import removeVideo1 from "../../../assets/icons/removeVideo1.png";

import orPor from "../../../assets/icons/orPor.png";
import orLand from "../../../assets/icons/orLand.png";

import { onAddText } from "../../../../../utils/fabricActions";
import { onInputImg } from "../../../../../utils/common";
import { changeMobOrientation } from "../../../../../utils/canvas";

import { useSlides } from "../../../../../../Builder/store";

function MainControlls({ variant, canvas, setState, fileInput, slide, activeSlide, refer }) {
	const modifySlide = useSlides(state => state.modifySlide);
	const addText = () => {
		onAddText(canvas, setState);
	};
	const handleInput = () => {
		onInputImg(setState, fileInput);
	};

	const [cor, setCor] = useState(canvas.width < canvas.height ? "portrait" : "landscape");

	const onToogleOrientation = () => {
		let target = canvas.width > canvas.height ? "portrait" : "landscape";
		setCor(target);
		changeMobOrientation(canvas, target);
	};

	const openModal = type => {
		setState(state => {
			state.modal = { active: true, type };
		});
	};

	const onRemoveVideo = () => {
		if (slide.isVideo) {
			modifySlide(activeSlide, {
				isVideo: false,
				video: null,
				isPreview: false,
				png: false,
				orientation: refer === "locations" ? "landscape" : "portrait",
			});
			changeMobOrientation(canvas, refer === "locations" ? "landscape" : "portrait");
		}
	};

	if (slide.isVideo) {
		return (
			<Container>
				<Button
					label="Remoe Video"
					state0={removeVideo0}
					state1={removeVideo1}
					action={onRemoveVideo}
				/>
			</Container>
		);
	}

	return (
		<Container>
			<Button label="Add text" state0={addText0} state1={addText1} action={addText} />
			<Button label="Add media" state0={addMedia0} state1={addMedia1} action={handleInput} />
			<Button
				label="Stickers"
				state0={stickers0}
				state1={stickers1}
				action={() => {
					openModal("stickers");
				}}
			/>
			<Button
				label="Layers"
				state0={layers0}
				state1={layers1}
				action={() => {
					openModal("layers");
				}}
			/>
			{variant === "gallery" && (
				<Button
					action={onToogleOrientation}
					state0={cor === "portrait" ? orLand : orPor}
					state1={cor === "portrait" ? orLand : orPor}
				/>
			)}
		</Container>
	);
}

export default MainControlls;
