import React, { useCallback, useEffect, useState } from "react";
import {
	Box,
	Divider,
	Spinner,
	Text,
	StatLabel,
	StatNumber,
	StatHelpText,
	StatArrow,
	StatGroup,
	Stat,
	Flex,
} from "@chakra-ui/react";
import { Line, Bar } from "react-chartjs-2";
import Select from "react-select";
import { getSubscribedStat } from "../../../api/libs/stats";
import { useSelector, useDispatch } from "react-redux";
import { fetchStatsById } from "../../../store/actions";
import { getSubscribedStats } from "../../../api/libs/stats";

function Charts({ currentTab, itemId }) {
	const dispatch = useDispatch();
	console.log("currentTab", currentTab);
	const fetchData = (refer, period, amount) => {
		dispatch(
			fetchStatsById({
				itemId,
				refer,
				period,
				amount,
			}),
		);
	};

	const { stats } = useSelector(state => state.fetchStatsById);

	const [monthsFilter, setMonthsFilter] = useState(6);
	const [daysFilter, setDaysFilter] = useState(120);
	const [hoursFilter, setHoursFilter] = useState(23);
	const [subscribedStatsData, setSubscribedStatsData] = useState();
	const [loading, setLoading] = useState(true);

	const [subscribeperLocation, setSubscribeperLocation] = useState();

	useEffect(() => {
		getSubscribedStats(itemId)
			.then(res => {
				setSubscribeperLocation(res.data);
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [itemId]);

	useEffect(() => {
		getSubscribedStat(monthsFilter)
			.then(res => {
				setSubscribedStatsData(res.data);
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [monthsFilter]);

	const Baroptions = {
		indexAxis: "x",
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				position: "right",
			},
		},
	};

	const generateBarChartData = data => {
		if (data) {
			return {
				labels: data?.labels,
				datasets: [
					{
						label: "# Subscribed users",
						data: data?.values,
						fill: false,
						backgroundColor: "#0E65AE",
						borderColor: "rgba(14, 101, 174, 0.4)",
					},
				],
			};
		}
	};

	useEffect(() => {
		fetchData(currentTab, "months", monthsFilter);
	}, [monthsFilter, currentTab]);
	useEffect(() => {
		fetchData(currentTab, "days", daysFilter);
	}, [daysFilter, currentTab]);
	useEffect(() => {
		fetchData(currentTab, "hours", hoursFilter);
	}, [hoursFilter]);

	const generateLineChartData = data => {
		return {
			labels: data.labels,
			datasets: [
				{
					label: "# of Views",
					data: data.values,
					fill: false,
					backgroundColor: "#0E65AE",
					borderColor: "rgba(255, 99, 132, 0.2)",
					yAxisID: "y-axis-1",
				},
			],
		};
	};
	const genereateBardata = data => {
		return {
			labels: data?.labels,
			datasets: [
				{
					label: "# Subscribed users",
					data: data?.values,
					fill: false,
					backgroundColor: "#0E65AE",
					borderColor: "rgba(14, 101, 174, 0.4)",
				},
			],
		};
	};

	const chartOptions = {
		scales: {
			yAxes: [
				{
					type: "linear",
					display: true,
					position: "left",
					id: "y-axis-1",
				},
			],
		},
	};

	const generatFilterArray = number => {
		let arr = Array.from({ length: number }, (_, index) => index + 1);
		let options = [];
		arr.map((i, k) => {
			options.push({
				value: i,
				label: i.toString(),
			});
		});
		return options;
	};

	const colourStyles = {
		option: (styles, { data, isDisabled, isFocused }) => {
			return {
				...styles,
				backgroundColor: isFocused ? "#3F629D" : data.isValid ? "red" : "",
				color: data.isValid ? "#FFF" : isDisabled ? "gray" : isFocused ? "#FFF" : "black",
				cursor: isDisabled ? "not-allowed" : "pointer",
				textAlign: "center",
			};
		},
		container: styles => ({ ...styles, width: "100px" }),
	};

	return (
		<Box height={"100%"} overflowY="scroll" pt={50} pb="150px" scrollPaddingY={"150px"}>
			<>
				{stats.months &&
					(console.log("stats.months", stats.months),
					(
						<Box ml={"30px"} mr={"30px"}>
							{
								// check if tab is subscribed and render subscribed chart
								currentTab === "subscriber" ? (
									<>
										<Box
											display="flex"
											flexDir="row"
											alignItems="center"
											justifyContent="center"
											marginBottom={10}
											marginTop={10}
										>
											<h3
												style={{
													textAlign: "center",
													fontSize: 20,
													marginLeft: "auto",
													marginRight: "auto",
												}}
											>
												Showing last {monthsFilter} months
											</h3>
											<Select
												isSearchable={false}
												defaultValue={{
													value: monthsFilter,
													label: monthsFilter.toString(),
												}}
												options={generatFilterArray(6)}
												onChange={e => setMonthsFilter(e.value)}
												styles={colourStyles}
												theme={theme => ({
													...theme,
													borderRadius: 0,
													width: "100%",
												})}
											/>
										</Box>
										<Bar data={genereateBardata(stats.months)} options={Baroptions} />
									</>
								) : (
									<>
										<Box
											display="flex"
											flexDir="row"
											alignItems="center"
											justifyContent="center"
											marginBottom={10}
										>
											<h3
												style={{
													textAlign: "center",
													fontSize: 20,
													marginLeft: "auto",
													marginRight: "auto",
												}}
											>
												Showing last {monthsFilter} months
											</h3>
											<Select
												isSearchable={false}
												defaultValue={{
													value: monthsFilter,
													label: monthsFilter.toString(),
												}}
												options={generatFilterArray(6)}
												onChange={e => setMonthsFilter(e.value)}
												styles={colourStyles}
												theme={theme => ({
													...theme,
													borderRadius: 0,
													width: "100%",
												})}
											/>
										</Box>
										<Line data={generateLineChartData(stats.months)} options={chartOptions} />
									</>
								)
							}
						</Box>
					))}
				{stats.months &&
					(console.log("stats.months", stats.months),
					(
						<Box mt="30px" ml={"30px"} mr={"30px"}>
							{
								// check if tab is subscribed and render subscribed chart
								currentTab === "subscriber" ? (
									<Box width={["100%", "100%"]}>
										<Flex flexWrap={"wrap"}>
											<Box width={"100%"}>
												<StatGroup
													marginTop={"3vh"}
													border={"1px solid #E5E4E2"}
													borderRadius={"12px"}
													padding={"15px"}
												>
													<Stat>
														<StatLabel color={"gray.500"} fontSize={"1.075rem"} fontWeight={"400"}>
															Views
														</StatLabel>
														<StatNumber fontSize={"2.5rem"} fontWeight={"bold"}>
															{stats.months.views}
														</StatNumber>
														<StatHelpText
															backgroundColor={"green.100"}
															w={"60px"}
															padding={"3px"}
															borderRadius={"10px"}
															display={"flex"}
															alignItems={"center"}
														>
															<StatArrow type="increase" />
															<Text color={"green.600"}>{stats.months.views_increased_by}</Text>
														</StatHelpText>
													</Stat>
												</StatGroup>
											</Box>
										</Flex>
									</Box>
								) : (
									<>
										<Box
											display="flex"
											flexDir="row"
											alignItems="center"
											justifyContent="center"
											marginBottom={10}
										>
											<h3
												style={{
													textAlign: "center",
													fontSize: 20,
													marginLeft: "auto",
													marginRight: "auto",
												}}
											>
												Showing last {daysFilter} days
											</h3>
											<Select
												isSearchable={false}
												defaultValue={{
													value: daysFilter,
													label: daysFilter.toString(),
												}}
												options={generatFilterArray(120)}
												onChange={e => setDaysFilter(e.value)}
												styles={colourStyles}
												theme={theme => ({
													...theme,
													borderRadius: 0,
													width: "100%",
												})}
											/>
										</Box>
										<Line data={generateLineChartData(stats.days)} options={chartOptions} />
									</>
								)
							}
						</Box>
					))}
				{stats.months && (
					<Box mt="30px" ml={"30px"} mr={"30px"}>
						{currentTab === "subscriber" ? (
							<Box width={["100%", "100%"]}>
								<Flex flexWrap={"wrap"}>
									<Box width={"100%"}>
										<StatGroup
											marginTop={"3vh"}
											border={"1px solid #E5E4E2"}
											borderRadius={"12px"}
											padding={"15px"}
										>
											<Stat>
												<StatLabel color={"gray.500"} fontSize={"1.075rem"} fontWeight={"400"}>
													Previous Views
												</StatLabel>
												<StatNumber fontSize={"2.5rem"} fontWeight={"bold"}>
													{stats.months.previous_views}
												</StatNumber>
												<StatHelpText
													backgroundColor={"red.100"}
													w={"65px"}
													padding={"3px"}
													borderRadius={"10px"}
													display={"flex"}
													alignItems={"center"}
												>
													<StatArrow type="decrease" />
													{
														// if subscribed_decreased_by is null show 0 else show the value
														stats.months.views_decreased_by === null ? (
															<Text color={"red.600"}>0%</Text>
														) : (
															<Text color={"red.600"}> {stats.months.views_decreased_by}</Text>
														)
													}

													{/* {statsData.views_decreased_by} */}
												</StatHelpText>
											</Stat>
										</StatGroup>
									</Box>
								</Flex>
							</Box>
						) : (
							<>
								<Box
									display="flex"
									flexDir="row"
									alignItems="center"
									justifyContent="center"
									marginBottom={10}
								>
									<h3
										style={{
											textAlign: "center",
											fontSize: 20,
											marginLeft: "auto",
											marginRight: "auto",
										}}
									>
										Showing last {hoursFilter} hours
									</h3>
									<Select
										isSearchable={false}
										defaultValue={{
											value: hoursFilter,
											label: hoursFilter.toString(),
										}}
										options={generatFilterArray(23)}
										onChange={e => setHoursFilter(e.value)}
										styles={colourStyles}
										theme={theme => ({
											...theme,
											borderRadius: 0,
											width: "100%",
										})}
									/>
								</Box>
								<Line data={generateLineChartData(stats.hours)} options={chartOptions} />
							</>
						)}
					</Box>
				)}
			</>
		</Box>
	);
}

export default Charts;
