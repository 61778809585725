import { invoiceActions } from "../../actionTypes";
import { updateInvoiceLocation as updateInvoiceLocationFunc } from "../../../api/libs/invoice";

export const updateInvoiceLocation = (id, data) => {
	return async dispatch => {
		dispatch({
			type: invoiceActions.UPDATE_INVOICE_LOCATION,
		});
		try {
			const res = await updateInvoiceLocationFunc(id, data);
			dispatch({
				type: invoiceActions.UPDATE_INVOICE_LOCATION_FULFILLED,
				payload: res.data,
			});
			return {
				type: invoiceActions.UPDATE_INVOICE_LOCATION_FULFILLED,
				payload: res.data,
			};
		} catch (err) {
			dispatch({
				type: invoiceActions.UPDATE_INVOICE_LOCATION_REJECTED,
				payload: err?.response?.data?.errors,
			});
			return {
				type: invoiceActions.UPDATE_INVOICE_LOCATION_REJECTED,
				payload: err?.response?.data?.errors,
			};
		}
	};
};
