import React from "react";
import {
	Box,
	Table,
	Text,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Modal = ({
	activeState,
	disabled,
	handleClose,
	header,
	type,
	width,
	customWidth = 30,
	subscribedUsers,
	...props
}) => {
	return (
		<Box
			pos="fixed"
			top="0"
			right="0"
			zIndex="100"
			height="100vh"
			boxSizing="border-box"
			w="100vw"
			bg="transparent"
			display="flex"
		>
			<Box
				flexGrow="1"
				width={["0", `${100 - customWidth}vw`]}
				pointerEvents="auto"
				height="100%"
				bg="rgba(0,0,0,0.2)"
				onClick={() => {
					if (!disabled) {
						handleClose();
					}
				}}
			></Box>

			<Box
				boxShadow="-5px 0px 31px 0px rgba(0,0,0,0.49);"
				width={["100vw", `${customWidth}vw`]}
				bg="white"
				display="flex"
				flexDirection="column"
				border="1px solid black"
				boxSizing="border-box"
			>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					px="1em"
					pt="0.2em"
					bg="black"
				>
					<Text
						color="white"
						textTransform="uppercase"
						fontSize="25"
						borderBottom="4px solid #73B13F"
					>
						{header}
					</Text>
					<FontAwesomeIcon
						cursor="pointer"
						icon="times"
						color="white"
						style={{ fontSize: "25px" }}
						onClick={handleClose}
					/>
				</Box>
				{
					// if header === LOGS FOR SUBSCRIBERS then show the logs
					// else show the charts
					header === "Logs for subscribers" ? (
						// using subscribedUsers use names to show the logs for each user
						<TableContainer>
							<Table size="sm">
								<Thead>
									<Tr>
										<th>Location ID</th>
										<th>Title</th>
										<th>Created At</th>
										<th>user Name</th>
										<th>user surname</th>
										<th>User Id</th>
										<th>User Last Seen at</th>
										<th>User Email Verified</th>
									</Tr>
								</Thead>
								<Tbody>
									{subscribedUsers.map((log, index) => (
										<Tr key={index}>
											<Td>{log.location_id}</Td>
											<Td>{log.location.title}</Td>
											<Td>{log.created_at}</Td>
											<Td>{log.user.name}</Td>
											<Td>{log.user.surname}</Td>
											<Td>{log.user.id}</Td>
											<Td>{log.user.last_seen_at}</Td>
											<Td>{log.user.email_verified}</Td>
										</Tr>
									))}
								</Tbody>
								<Tfoot>
									<Tr>
										<th>Location ID</th>
										<th>Title</th>
										<th>Created At</th>
										<th>user Name</th>
										<th>user surname</th>
										<th>User Id</th>
										<th>User Last Seen at</th>
										<th>User Email Verified</th>
									</Tr>
								</Tfoot>
							</Table>
						</TableContainer>
					) : (
						<Box h="100%" {...props}>
							{props.children}
						</Box>
					)
				}
				<Box h="100%" {...props}>
					{props.children}
				</Box>
			</Box>
		</Box>
	);
};

export default Modal;
