import React from "react";
import { Box } from "@chakra-ui/react";
import ChildButton from "./ChildButton";
import ParentButton from "./ParentButton";

function MenuItem({
	active: expanded,
	item,
	trStyle,
	iStyle,
	locationsLength,
	handleToggle,
	limits,
}) {
	return (
		<Box key={item.id} onClick={handleToggle}>
			<ParentButton
				limits={limits}
				locationsLength={locationsLength}
				trStyle={trStyle}
				item={item}
			/>

			<Box style={trStyle}>
				{item.child &&
					!expanded &&
					Object.values(item.child).map((child, key) => (
						<ChildButton key={key} child={child} trStyle={trStyle} />
					))}
			</Box>
		</Box>
	);
}

export default MenuItem;
