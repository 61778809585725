import React, { useState, useEffect } from "react";
import Note from "./Note";
import { content } from "./content/verification";
import { Box, Input } from "@chakra-ui/react";
import { fetchUserData } from "../../store/actions";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resendEmail } from "../../store/actions";
import PrimaryButton from "./buttons/PrimaryButton";

function Protected() {
	const history = useHistory();
	const [vis, setVis] = useState(false);
	const [val, setVal] = useState("");
	const dispatch = useDispatch();
	const { status } = useSelector(state => state.verification);
	const { isAuthenticated, user } = useSelector(state => state.currentUser);
	const lang = useSelector(state => state.languageProvider.language);
	// const status = useSelector((state) => state.resendEmail.status);
	const t = content[lang];

	const resend = newEmail => {
		dispatch(resendEmail(newEmail));
	};

	useEffect(() => {
		if (status) {
			if (isAuthenticated) {
				dispatch(fetchUserData());
			}
		}
	}, [status]);
	const handleEmailChange = () => {
		dispatch(resendEmail(val));
		setVal("");
		setVis(false);
	};

	useEffect(() => {
		console.log("here");
		const interval = setInterval(() => {
			if (user?.user?.email_verified_at) {
				console.log("verfied");
				clearInterval(interval);
			} else {
				dispatch(fetchUserData(false, true));
			}
		}, 3000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Box display="flex" flexDirection="column" alignItems="center" gridGap="1em" width={"100%"}>
			<Note title={t.title} content={t.content} />
			<PrimaryButton
				onClick={() => {
					resend(false);
				}}
				label={t.notRecived}
			></PrimaryButton>
			<PrimaryButton
				onClick={() => {
					setVis(!vis);
				}}
				label={t.change}
			/>
			{vis && (
				<Box display="flex" mt="20px">
					<Input
						mr="15px"
						type="email"
						onChange={e => {
							setVal(e.target.value);
						}}
					/>
					<PrimaryButton minW="100px" onClick={handleEmailChange} label="Submit" />
				</Box>
			)}
		</Box>
	);
}

export default Protected;
