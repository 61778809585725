import React from "react";
import { FormControl, FormLabel, FormErrorMessage, Box, Tooltip } from "@chakra-ui/react";

import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import { getStates } from "../../../../api/libs/countries";

export default function States({ formik, column }) {
	const lang = useSelector(state => state.languageProvider.language);

	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		if (searchQuery && searchQuery.length < 2) {
			return null;
		}
		const response = await getStates(
			formik.values.state.state_code || formik.values.state,
			searchQuery,
			formik.values.country.iso2 || formik.values.country,
			page,
		);
		if (formik.values.state && page === 1) {
			formik.setFieldValue("state", response.data[0]);
		}
		return {
			options: response.data,
			hasMore: response.next_page_url,
			additional: {
				page: searchQuery ? 2 : page + 1,
			},
		};
	};

	const onChange = option => {
		formik.setFieldValue("state", option);
		formik.setFieldValue("city", "");
	};

	return (
		<FormControl
			display="grid"
			gridTemplateColumns={
				column ? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "2fr 8fr", "2fr 8fr"]
			}
			alignItems="center"
			isInvalid={formik.errors.state}
			id="state"
		>
			<FormLabel
				_focus={{
					color: "#1274BC",
					fontWeight: 700,
				}}
			>
				{lang === "de" ? "State" : "State"}
			</FormLabel>
			<Box display="flex" flexDirection="column">
				<Tooltip label={formik.errors.country} hasArrow arrowSize={15} bg="red.600">
					<AsyncPaginate
						key={JSON.stringify(formik.values.country)}
						theme={theme => ({
							...theme,
							borderRadius: 0,
						})}
						isDisabled={typeof formik.values.country !== "object"}
						value={formik.values.state || ""}
						loadOptions={loadOptions}
						getOptionValue={option => option.state_code}
						getOptionLabel={option => option.name}
						onChange={onChange}
						placeholder={
							formik.values.state !== ""
								? formik.values.state
								: formik.values.country
								? "Select State"
								: "Select Country First"
						}
						additional={{
							page: 1,
						}}
					/>
				</Tooltip>
				{/* <FormErrorMessage
          position={column ? "absolute" : "relative"}
          bottom="-1.2em"
        >
          {formik.errors.state}
        </FormErrorMessage> */}
			</Box>
		</FormControl>
	);
}
