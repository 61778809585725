import React, { useEffect } from "react";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { reset } from "../../../store/actions";
import PublicLayout from "../../shared/PublicLayout";
import Form from "./components/Form";
import { useFormik } from "formik";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";

function Recover({ history, location }) {
	const lang = useSelector(state => state.languageProvider.language);
	const { status } = useSelector(state => state.resetPassword);
	const dispatch = useDispatch();

	const { token } = queryString.parse(location.search);

	const handleSubmit = values => {
		let data = {
			...values,
			token,
		};
		dispatch(reset(data, formik.setErrors));
	};

	useEffect(() => {
		if (status) {
			history.push("/");
		}
	}, [status]);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		//  <PublicLayout>
		<Form formik={formik} lang={lang} />
		//  </PublicLayout>
	);
}
export default Recover;
