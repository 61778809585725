import React from "react";

function Player({ media }) {
	let url = typeof media === "string" ? media : URL.createObjectURL(media);
	return (
		<video
			src={media}
			controls
			muted
			autoPlay={false}
			loop
			style={{
				objectFit: "contain",
				minWidth: "100%",
				minHeight: "100%",
				width: "auto",
				height: "auto",
				maxHeight: "200px",
			}}
		>
			<source src={url} />
		</video>
	);
}

export default Player;
