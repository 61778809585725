import React from "react";
import { Link } from "react-router-dom";
import { Box, Image } from "@chakra-ui/react";
import Logo from "../../../../assets/images/logo.svg";

function layout(props) {
	return (
		<Box w={"full"} paddingBottom="20px" height={"100%"}>
			<Box display="flex">
				<Link to="/login">
					<Image w={"50%"} src={Logo} />
				</Link>
			</Box>
			{props.children}
		</Box>
	);
}

export default layout;
