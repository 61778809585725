import { newsActions } from "../../actionTypes";

const initialState = {
	news: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case newsActions.DELETE_NEWS: {
			return { ...state, ...initialState };
		}

		case newsActions.DELETE_NEWS_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				news: action.payload,
			};
		}

		case newsActions.DELETE_NEWS_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
