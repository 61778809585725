import React from "react";
import { Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Button({ icon, action = () => {}, ...rest }) {
	return (
		<Box
			onClick={action}
			border="1px solid white"
			borderRadius="full"
			w="35px"
			h="35px"
			display="flex"
			justifyContent="center"
			alignItems="center"
			bg="rgba(176,177,175, 0.4)"
			_hover={{ bg: "rgba(170,205,86, 0.7)" }}
			cursor="pointer"
			pointerEvents="visible"
			{...rest}
		>
			<FontAwesomeIcon color="white" icon={icon} style={{ fontSize: "15px" }} />
		</Box>
	);
}

export default Button;
