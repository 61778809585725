import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import Content from "./components/Content";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { initialValues } from "./formInitialValues";
import { validationSchema } from "./validationSchema";
import { createInvoiceLocation } from "../../../store/actions";
import { useHistory } from "react-router-dom";
import { getCountries, getTimezones } from "../../../api/libs/countries";
function InvoiceCreation({ setPage }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const lang = useSelector(state => state.languageProvider.language);
	const [errors, setErrors] = useState({
		postal_code: "",
		street: "",
		street_number: "",
	});
	const setSuggested = async () => {
		const responseCountry = await getCountries("", "", 1, true);
		formik.setFieldValue("country", responseCountry.data[0]);

		formik.setFieldValue("state", responseCountry.data[0].state);
		formik.setFieldValue("city", responseCountry.data[0].city);
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const responseTimezone = await getTimezones(timezone);
		formik.setFieldValue("timezone", responseTimezone.data[0]);
	};

	useEffect(() => {
		setSuggested();
	}, []);
	const handleSubmit = async values => {
		const moddedValues = {
			...values,
			country: typeof values.country === "object" ? values.country.iso2 : values.country,
			state: typeof values.state === "object" ? values.state.state_code : values.state,
			city_id: typeof values.city === "object" ? values.city.id : values.city,
			timezone: typeof values.timezone === "object" ? values.timezone.zone_name : values.timezone,
			default: true,
		};
		console.log("erorr log", moddedValues);
		dispatch(createInvoiceLocation(moddedValues, history))
			.then(res => {
				setPage(3);
				console.log("succccces1", res);
				setErrors({});
			})
			.catch(error => {
				// Handle error here
				setErrors(error.data.errors);
				console.log("Error creating invoice location:", error);
			});
	};

	useEffect(() => {}, [errors]);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: handleSubmit,
		validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		validateOnMount: false,
	});

	return <Content formik={formik} errors={errors} />;
}

export default InvoiceCreation;
