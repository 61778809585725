import { locationActions } from "../../actionTypes";
import toast from "react-hot-toast";
import { restoreLocation } from "../../../api/libs/locations";

export const recoverLocation = (id, history) => {
	return async dispatch => {
		dispatch({
			type: locationActions.RECOVER_LOCATION,
		});
		restoreLocation(id)
			.then(res => {
				dispatch({
					type: locationActions.RECOVER_LOCATION_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully recovered location");
				history.push(`/locations/edit/${id}`);
			})
			.catch(err => {
				dispatch({
					type: locationActions.RECOVER_LOCATION_REJECTED,
					payload: err.response,
				});
			});
	};
};
