import React from "react";
import { useSelector } from "react-redux";

import TextAlignment from "./TextAlignment";

function Alignment({ state, canvas, activePanel, setActivePanel }) {
	const lastUsedColor = useSelector(state => state.editorReducer.lastUsedColor);
	const tooglePanel = () => {
		if (
			activePanel === "FONT_EDIT" &&
			state.activeObject !== null &&
			state.activeObject?.type === "textbox"
		) {
			setActivePanel("COLOR_PICKER");
		} else if (activePanel === "COLOR_PICKER") {
			setActivePanel("FONT_EDIT");
		}
	};

	return (
		<TextAlignment
			state={state}
			canvas={canvas}
			activePanel={activePanel}
			tooglePanel={tooglePanel}
			lastUsedColor={lastUsedColor}
		/>
	);
}

export default Alignment;
