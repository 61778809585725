import { templateActions } from "../../actionTypes";
import { getTemplate } from "../../../api/libs/templates";

export const loadTemplate = id => {
	return async dispatch => {
		dispatch({
			type: templateActions.LOAD_TEMPLATE,
		});
		getTemplate(id)
			.then(res => {
				dispatch({
					type: templateActions.LOAD_TEMPLATE_FULFILLED,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: templateActions.LOAD_TEMPLATE_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
