import React from "react";
import { Box, Spinner } from "@chakra-ui/react";

function Loading() {
	return (
		<Box h="100%" w="100%" display="flex" justifyContent="center" py="100px">
			<Spinner color="#AACD56" size="xl" />
		</Box>
	);
}

export default Loading;
