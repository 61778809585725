export const content = {
	en: {
		header: "Coupons",
		client: "Create Coupons",
		description: "Description",
		title: "Title",
		start_at: "Start at",
		end_at: "End at",
		location: "Location",
		future: "future",
		old: "old",
		published: "published",
		no_data: "There is no data for this type.",
		add_news: "Add coupon",
		locb: "Location button",
		coupon_code: "Coupon code",
		discount: "Discount",
		edit: "Edit",
		created_at: "Created",
		used_amount: "Used amount",
		delete: "Delete",
		publish: "Create",
		info: "Info",
		no_end_date: "No end date",
	},
	de: {
		header: "Gutscheine",
		client: "Gutscheine erstellen",
		description: "Beschreibung",
		title: "Titel",
		start_at: "Beginne bei",
		end_at: "Beitragsende um",
		location: "Standort",
		future: "Zukunft",
		old: "alt",
		published: "veröffentlicht",
		no_data: "Für diesen Typ sind keine Daten vorhanden.",
		add_news: "Gutschein hinzufügen",
		locb: "Standort-Schaltfläche",
		coupon_code: "Gutscheincode",
		discount: "Rabatt",
		edit: "Bearbeiten",
		created_at: "Erstellt",
		used_amount: "Gebrauchte Menge",
		delete: "Löschen",
		publish: "Create",
		info: "Die Info",
		no_end_date: "Kein Enddatum",
	},
};
