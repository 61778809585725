import { couponActions } from "../../actionTypes";
import { addCoupon } from "../../../api/libs/coupons";
import toast from "react-hot-toast";
import { fetchCoupons } from ".";

export const createCoupon = (data, setErrors, history, push = true, setActiveTab, formik) => {
	return async dispatch => {
		dispatch({
			type: couponActions.CREATE_COUPON,
		});
		addCoupon(data)
			.then(res => {
				dispatch({
					type: couponActions.CREATE_COUPON_FULFILLED,
					payload: res.data,
				});

				toast.success("Successfully created coupon");
				if (push) {
					history.push("/coupons");
				} else {
					setActiveTab(0);
					if (formik.values.coupon) {
						formik.setFieldValue("coupon.id", [...formik.values.coupon.id, res.data.id]);
					}
				}
				// formik.resetForm();

				dispatch(fetchCoupons());
			})
			.catch(err => {
				console.log(err);
				setErrors(err.response.data.errors);
				toast.error("Error");
				dispatch({
					type: couponActions.CREATE_COUPON_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
