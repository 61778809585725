import { keywordActions } from "../../actionTypes";
import { showKeywords } from "../../../api/libs/keywords";

export const fetchKeywords = (
	type,
	location_category_ids,
	event_format_id,
	event_category_id,
	event_sub_category_id,
) => {
	return async dispatch => {
		dispatch({
			type: keywordActions.FETCH_KEYWORDS,
		});
		showKeywords(
			type,
			location_category_ids,
			event_format_id,
			event_category_id,
			event_sub_category_id,
		)
			.then(res => {
				dispatch({
					type: keywordActions.FETCH_KEYWORDS_FULFILLED,
					payload: res,
				});
			})
			.catch(err => {
				dispatch({
					type: keywordActions.FETCH_KEYWORDS_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
