import React from "react";
import { Box } from "@chakra-ui/react";
import { content } from "../components/content";
import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import CategorySelect from "../../../shared/inputs/CategorySelect";
import CountrySelect from "../../../shared/inputs/countrySelect";
import { useState } from "react";

function Client({ formik, lang, setShowMap, isLoaded }) {
	// title, address, country
	const [value, setValue] = useState("");

	console.log(value);
	console.log(typeof value);
	console.log(Number(value));

	const t = content[lang];

	return (
		<Box>
			<PrimaryInput
				column={true}
				label={t.official_name}
				type="text"
				id="official_name"
				formik={formik}
			/>

			<Box display="grid" gridTemplateColumns={["1fr", "4fr 2fr 2fr 2fr"]} gridGap="1em">
				<PrimaryInput
					column={true}
					label={t.street}
					type="text"
					id="street"
					formik={formik}
					autoComplete="street-address"
				/>
				<PrimaryInput
					column={true}
					label={t.street_number}
					type="text"
					id="street_number"
					formik={formik}
				/>
				<PrimaryInput
					column={true}
					label={t.post_code}
					type="text"
					id="postal_code"
					formik={formik}
					autoComplete="postal-code"
				/>
				<PrimaryInput
					column={true}
					label={t.phone_number}
					type="number"
					id="phone_number"
					formik={formik}
					autoComplete="tel"
					validate={value => {
						let error;
						const regex = /^\+?\d+$/;
						if (!regex.test(value)) {
							error = t("invalid_phone_number");
						}
						return error;
					}}
				/>
			</Box>

			<CountrySelect column={true} formik={formik} />

			<Box display="grid" gridTemplateColumns={["1fr", "1fr 1fr 1fr"]} gridGap="1em">
				<CategorySelect column={true} formik={formik} />
				<PrimaryInput
					column={true}
					label={t.email}
					type="text"
					id="email"
					formik={formik}
					autoComplete="email"
				/>
				<PrimaryInput
					column={true}
					label={t.web_page}
					type="text"
					id="web_page"
					formik={formik}
					autoComplete="url"
				/>
			</Box>
		</Box>
	);
}

export default Client;
