export const content = {
	en: {
		header: "Push Notifications",
		client: "Create Notification",
		no_data: "There is no data for this type.",
		add_event: "Create Notification",
		test: "Test",
		title: "Title",
		body: "Body",
		type: "Type",
		users: "Users",
		fcm: "FCM",
		delivered: "Delivered",
		avatar: "Avatar",
		name: "Name",
		surname: "Surname",
		logo: "Logo",
	},
	de: {
		header: "Mitteilungen",
		client: "Benachrichtigung erstellen",
		no_data: "Für diesen Typ sind keine Daten vorhanden.",
		add_event: "Benachrichtigung erstellen",
		test: "Test",
		title: "Title",
		body: "Body",
		type: "Type",
		users: "Users",
		fcm: "FCM",
		delivered: "Delivered",
		avatar: "User",
		name: "Name",
		surname: "Surname",
		logo: "Logo",
	},
};
