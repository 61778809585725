import { templateActions } from "../../actionTypes";
import { deleteTemplate } from "../../../api/libs/templates";

export const removeTemplate = id => {
	return async dispatch => {
		dispatch({
			type: templateActions.REMOVE_TEMPLATE,
		});
		deleteTemplate(id)
			.then(res => {
				dispatch({
					type: templateActions.REMOVE_TEMPLATE_FULFILLED,
					payload: res.data,
				});
				dispatch({
					type: templateActions.DELETE_TEMPLATE,
					payload: id,
				});
			})
			.catch(err => {
				dispatch({
					type: templateActions.REMOVE_TEMPLATE_REJECTED,
					payload: err.response.data.errors,
				});
			});
	};
};
