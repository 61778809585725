import { Box } from "@chakra-ui/react";

const Layout = props => {
	return (
		<Box display="flex" flexDir="column" h="98%" px="40px">
			{props.children}
		</Box>
	);
};

export default Layout;
