import React from "react";
import { Box, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DNDContainer from "./DNDContainer";
import Slide from "./Slide";

import { _sideMenu } from "../../../../../../content";

function ForGallery({
	state,
	setState,
	fileInput,
	onInputImg,
	lang,
	slides,
	onAddSlide,
	onSelectSlide,
	onRemoveSlide,
	onCloneSlide,
	slideLoading,
	onDragEnd,
}) {
	const t = _sideMenu[lang];
	return (
		<Box h="540px" p="10px">
			<Box display="flex" flexDir="column" overflowY="scroll" className="custom-scroll" h="520px">
				<Box
					display="flex"
					flexDir="column"
					justifyContent="center"
					alignItems="center"
					borderBottom="1px solid #DEDFDE"
					py="15px"
				>
					{state.imgLoading ? (
						<Box
							border="1px dashed #D6D6D5"
							textAlign="center"
							display="flex"
							alignItems="center"
							justifyContent="center"
							cursor="pointer"
							_active={{ bg: "rgba(245,245,245,0.3)", borderWidth: 0 }}
							height="70px"
							width="40%"
						>
							<Spinner color="green" />
						</Box>
					) : (
						<Menu>
							<MenuButton
								width="40%"
								cursor="pointer"
								_active={{ outline: "none" }}
								_focus={{ outline: "none" }}
							>
								{state.imageLoading ? (
									<Box
										border="1px dashed #D6D6D5"
										textAlign="center"
										display="flex"
										alignItems="center"
										justifyContent="center"
										cursor="pointer"
										_active={{ bg: "rgba(245,245,245,0.3)", borderWidth: 0 }}
										height="70px"
										width="100%"
									>
										<Spinner color="green" />
									</Box>
								) : (
									<Box
										border="1px dashed #D6D6D5"
										textAlign="center"
										display="flex"
										alignItems="center"
										justifyContent="center"
										cursor="pointer"
										_active={{ bg: "rgba(245,245,245,0.3)", borderWidth: 0 }}
										height="70px"
										width="100%"
									>
										<FontAwesomeIcon icon="plus" style={{ fontSize: "30" }} color="#AACD56" />
									</Box>
								)}
							</MenuButton>
							<MenuList
								pointerEvents="visible"
								bg="rgba(39,41,41,0.9)"
								color="white"
								fontSize="14px"
								border="none"
							>
								<MenuItem
									display="flex"
									justifyContent="center"
									iconSpacing="5px"
									icon={<FontAwesomeIcon icon="image" style={{ fontSize: "16px" }} />}
									_focus={{ bg: "#AACD56" }}
									_hover={{ bg: "#AACD56" }}
									onClick={() => {
										onInputImg(setState, fileInput);
									}}
								>
									{t.loadImg}
								</MenuItem>
								<MenuItem
									display="flex"
									justifyContent="center"
									iconSpacing="5px"
									icon={<FontAwesomeIcon icon="layer-group" style={{ fontSize: "16px" }} />}
									_focus={{ bg: "#AACD56" }}
									_hover={{ bg: "#AACD56" }}
									isDisabled={slides.length > 4}
									onClick={onAddSlide}
								>
									{t.newSlide}
								</MenuItem>
							</MenuList>
						</Menu>
					)}
				</Box>
				<DNDContainer onDragEnd={onDragEnd} id="layers-dnd">
					{slides.map((slide, index) => (
						<Slide
							key={index}
							data={slide}
							index={index}
							indexStr={"0" + (index + 1)}
							img={
								state.activeSlide === index && !slide.isVideo && !slide.isPano
									? state.liveImg
									: slide.png
							}
							isPano={slide.isPano}
							isActive={state.activeSlide === index}
							length={slides.length}
							onSelectSlide={() => {
								onSelectSlide(index);
							}}
							onRemoveSlide={() => {
								onRemoveSlide(index);
							}}
							onCloneSlide={onCloneSlide}
							slideLoading={slideLoading}
						/>
					))}
				</DNDContainer>
			</Box>
		</Box>
	);
}

export default ForGallery;
