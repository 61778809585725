import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/form-control";
import { Box } from "@chakra-ui/layout";
import { Button, Text } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/skeleton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchUsers,
	fetchNewses,
	fetchLocations,
	fetchNotifiableUsers,
} from "../../../store/actions";
import Modal from "../Modal";
import PrimaryButton from "../buttons/PrimaryButton";
import { Table, Thead, Tbody, Tr, Th, Td, Image } from "@chakra-ui/react";
import { content } from "../../news/list/components/content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDescendantProp } from "../../../utils/common";
import { getAvatar, getOriginal } from "../../../utils/imgUri";

export default function ModalSelect({ formik, id, fields, label, header, type, couponId }) {
	const dispatch = useDispatch();
	const lang = useSelector(state => state.languageProvider.language);
	const t = content[lang];

	//news
	const { newses, fetched: fetchedNewses } = useSelector(state => state.fetchNewses);

	//users
	const { users, fetched: fetchedUsers } = useSelector(state => state.fetchUsers);

	//notifiable users
	const { notifiableUsers, fetched: fetchedNotifiableusers } = useSelector(
		state => state.fetchNotifiableUsers,
	);

	//locations
	const { locations, fetched: fetchedLocations } = useSelector(state => state.fetchLocations);

	const [modalState, setModalState] = useState(false);
	useEffect(() => {
		switch (type) {
			case "notifiableUsers":
				if (!fetchedNotifiableusers) dispatch(fetchNotifiableUsers());
				break;
			case "users":
				if (!fetchedUsers) dispatch(fetchUsers());
				break;
			case "news":
				dispatch(fetchNewses("published", 1, true, couponId));
				break;
			case "location":
				if (!fetchedLocations) dispatch(fetchLocations());
				break;
			default:
				break;
		}
	}, [type]);

	const handleReset = () => formik.setFieldValue(id, []);

	const selectAll = () => {
		switch (type) {
			case "notifiableUsers":
				formik.setFieldValue(
					id,
					notifiableUsers.data.map(item => item.id),
				);

				break;
			case "users":
				formik.setFieldValue(
					id,
					users.data.map(item => item.id),
				);

				break;
			case "news":
				formik.setFieldValue(
					"news.id",
					newses.data.map(item => item.id),
				);
				break;
			case "location":
				formik.setFieldValue(
					"location.id",
					locations.data.map(item => item.id),
				);
				break;
			default:
				break;
		}
	};

	const handleSelect = (funcID, color) => {
		switch (type) {
			case "notifiableUsers":
				formik.setValues({
					...formik.values,
					user: {
						id: getDescendantProp(formik.values, id).includes(funcID)
							? getDescendantProp(formik.values, id).filter(it => it !== funcID)
							: [...getDescendantProp(formik.values, id), funcID],
					},
				});
				break;
			case "users":
				const userItem = users.data.find(item => item.id === id);
				formik.setValues({
					...formik.values,
					image: userItem?.logo,
					user: {
						id: getDescendantProp(formik.values, id).includes(funcID)
							? getDescendantProp(formik.values, id).filter(it => it !== funcID)
							: [...getDescendantProp(formik.values, id), funcID],
					},
				});
				break;
			case "news":
				const newsItem = newses.data.find(item => item.id === id);

				formik.setValues({
					...formik.values,
					image: newsItem?.logo,
					news: {
						id: formik.values.news.id.includes(funcID)
							? formik.values.news.id.filter(it => it !== funcID)
							: [...formik.values.news.id, funcID],
					},
				});
				break;
			case "location":
				const arr = formik.values.location.id.includes(funcID)
					? formik.values.location.id.filter(it => it !== funcID)
					: [...formik.values.location.id, funcID];
				const locationItem = locations.data.find(item => item.id === arr[arr.length - 1]);

				formik.setValues({
					...formik.values,
					locationLogo: locationItem?.logo,
					locationTitle: locationItem?.title,
					locationPhone: locationItem?.phone_number,
					locationAddress: locationItem?.street
						? `${locationItem.street} ${locationItem.street_number} `
						: "",
					locationCategory: locationItem?.category?.name,
					location: {
						id: formik.values.location.id.includes(funcID)
							? formik.values.location.id.filter(it => it !== funcID)
							: [...formik.values.location.id, funcID],
					},
				});
				break;
			default:
				break;
		}
	};
	let dataToMap = [];
	switch (type) {
		case "notifiableUsers":
			dataToMap = notifiableUsers;
			break;
		case "users":
			dataToMap = users;
			break;
		case "news":
			dataToMap = newses;
			break;
		case "location":
			dataToMap = locations;
			break;
		default:
			break;
	}
	switch (type) {
		case "notifiableusers":
			if (!fetchedNotifiableusers) return <Skeleton height="2em" />;
			break;
		case "users":
			if (!fetchedUsers) return <Skeleton height="2em" />;
			break;
		case "news":
			if (!fetchedNewses) return <Skeleton height="2em" />;
			break;
		case "location":
			if (!fetchedLocations) return <Skeleton height="2em" />;
			break;
		default:
			break;
	}
	return (
		<FormControl
			display="flex"
			flexDirection="column"
			mt="0.5em"
			isInvalid={getDescendantProp(formik.errors, id) && getDescendantProp(formik.touched, id)}
			id={id}
			position="relative"
		>
			{modalState && dataToMap.data && (
				<Modal header={header} handleClose={() => setModalState(false)}>
					<Box height="100%" display="flex" flexDirection="column">
						<Box pb="3em" flexGrow="1" height="88vh" overflow="scroll" position="relative">
							<PrimaryButton
								m="1em"
								as={Button}
								onClick={
									getDescendantProp(formik.values, id)?.length === dataToMap.data.length
										? () => formik.setFieldValue(id, [])
										: selectAll
								}
								label={
									getDescendantProp(formik.values, id)?.length === dataToMap.data.length
										? "Unselect All"
										: "Select All"
								}
							></PrimaryButton>

							<Table variant="simple">
								<Thead
									zIndex="2"
									boxShadow="0 3px 0px -2px black"
									position="sticky"
									top="0"
									backgroundColor="#FFF"
								>
									<Tr>
										{fields.map((fieldName, key) => (
											<Th fontSize="14px" key={key}>
												{fieldName.includes(".")
													? fieldName.split(".")[1]
													: fieldName.replaceAll("_", " ")}
											</Th>
										))}
									</Tr>
								</Thead>
								<Tbody>
									{dataToMap?.data?.map(item => {
										const color = getDescendantProp(formik.values, id).includes(item.id);
										console.log(item);
										return (
											<Tr
												cursor="pointer"
												onClick={() => handleSelect(item.id, color)}
												_hover={{
													backgroundColor: color ? "#AFDA09" : "#F2F1F0",
												}}
												backgroundColor={color ? "#AFCA09" : "#FFF"}
												key={item.id}
											>
												{fields.map((fieldName, key) =>
													fieldName === "image" ||
													fieldName === "logo" ||
													fieldName === "user.avatar" ||
													fieldName === "avatar" ? (
														<Td py="1em" key={key}>
															<Image
																border="0.5px solid black"
																borderRadius="100%"
																height="auto"
																width="4em"
																objectFit="cover"
																src={
																	fieldName === "user.avatar" || fieldName === "avatar"
																		? getAvatar(getDescendantProp(item, fieldName))
																		: getOriginal(getDescendantProp(item, fieldName))
																}
															/>
														</Td>
													) : (
														<Td py="1em" key={key} fontSize={"12px"}>
															{getDescendantProp(item, fieldName)}
														</Td>
													),
												)}
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</Box>
						<Box
							py="0.5em"
							flexGrow="1"
							borderTop="2px solid #7C7C7C"
							display="grid"
							gridTemplateColumns="1fr 1fr"
							gridGap="1em"
						>
							<Box display="flex" justifyContent="flex-end" alignItems="center">
								<Text mr="1em">{t.confirm}</Text>
								<Button
									width="3em"
									height="3em"
									borderRadius="100%"
									justifySelf="flex-end"
									_hover={{ bg: "#9BC962" }}
									_focus={{ bg: "#B0D352" }}
									_active={{ bg: "#B0D352" }}
									bg="#D7D7D6"
									onClick={() => {
										setModalState(false);
									}}
								>
									<FontAwesomeIcon color="#FFF" size="lg" icon="check" />
								</Button>
							</Box>
							<Box display="flex" alignItems="center">
								<Button
									onClick={handleReset}
									_hover={{ bg: "#AAAAAD" }}
									_focus={{ bg: "#6C6C6E" }}
									_active={{ bg: "#6C6C6E" }}
									width="3em"
									borderRadius="100%"
									bg="#D7D7D6"
									height="3em"
								>
									<FontAwesomeIcon color="#FFF" size="lg" icon="undo" />
								</Button>
								<Text ml="1em">{t.reset}</Text>
							</Box>
						</Box>
					</Box>
				</Modal>
			)}
			<FormLabel>{label}</FormLabel>
			<Box display="flex" flexDirection="column">
				<FormErrorMessage position="absolute" top="1em">
					{getDescendantProp(formik.errors, id)}
				</FormErrorMessage>
				<Box
					onClick={() => setModalState(true)}
					height="2em"
					borderRadius="0px"
					borderColor={
						getDescendantProp(formik.errors, id) && getDescendantProp(formik.touched, id)
							? "red"
							: "#CBCCCB"
					}
					borderWidth="1px"
					backgroundColor="transparent"
					boxShadow="none"
					_hover={{
						backgroundColor: "#F2F1F1",
						borderColor: getDescendantProp(formik.errors, id) ? "red" : "#1274BC",
					}}
					_focus={{ backgroundColor: "#F2F1F1" }}
					as={Button}
				>
					<Text mr="0.2em"> {label}</Text>

					<Text color="#AFCB2E">({getDescendantProp(formik.values, id)?.length})</Text>
				</Box>
			</Box>
		</FormControl>
	);
}
