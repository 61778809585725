import React, { useEffect, useState } from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { getLogoes } from "../../../utils/imgUri";
import Loading from "../../shared/Loading";

function CompanyItem({ handleSelect, item, setPage }) {
	const handleEdit = id => {
		localStorage.setItem("activeCompany", id);
		setPage(6);
	};

	const [logo, setLogo] = useState("");

	useEffect(() => {
		setLogo(getLogoes(item.company?.logo));
	}, []);
	return (
		<Box
			display="flex"
			flexDirection={["column", "row"]}
			alignItems="center"
			mb="10px"
			p="10px"
			borderRadius="5"
			_hover={{ bg: "rgba(200,200,200, 0.1 )" }}
		>
			{logo.length > 5 ? (
				<>
					<Image
						onClick={() => handleSelect(item.company.id)}
						cursor="pointer"
						src={getLogoes(item.company?.logo)}
						w="50px"
						h="50px"
						borderRadius="50%"
						mr={["0px", "20px"]}
					/>

					<Box
						display="flex"
						flexDirection="column"
						cursor="pointer"
						onClick={() => handleSelect(item.company.id)}
					>
						<Text mb="5px">{item.company?.name}</Text>
						<Text>{item.company?.address}</Text>
					</Box>
					<Text display="flex" flexDirection="column" ml={["none", "auto"]} gridGap="0.2em">
						<Text
							fontSize="0.8em"
							bg={
								item.company.stripe_subscriptions[0] || item.company.paypal_subscriptions[0]
									? "#AFCB2E"
									: "red"
							}
							borderRadius="2%"
							py="0.3em"
							px="1em"
							color="#FFF"
							fontFamily="Segoe UI Bold"
							textAlign="center"
						>
							{item.company.stripe_subscriptions[0] || item.company.paypal_subscriptions[0]
								? "ACTIVE"
								: "NOT PAID"}
						</Text>
						<Text
							fontSize="0.8em"
							bg="#0E65AE"
							borderRadius="2%"
							textAlign="center"
							py="0.3em"
							px="1em"
							color="#FFF"
							fontFamily="Segoe UI Bold"
						>
							{item.role?.name.replace("_", " ").toUpperCase()}
						</Text>
						<Text
							fontSize="0.8em"
							bg="#a1a1a1"
							borderRadius="2%"
							textAlign="center"
							py="0.3em"
							px="1em"
							color="#FFF"
							fontFamily="Segoe UI Bold"
							cursor="pointer"
							onClick={() => handleEdit(item.company.id)}
						>
							EDIT
						</Text>
					</Text>
				</>
			) : (
				<Loading />
			)}
		</Box>
	);
}

export default CompanyItem;
