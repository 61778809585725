import { locationActions } from "../../actionTypes";
import { addLocation } from "../../../api/libs/locations";
import { createGallery } from "../../../api/libs/gallery";
import toast from "react-hot-toast";
import newGallery from "../../../utils/gallery";
import { fetchLocations } from ".";

export const createLocation = (
	data,
	gallery,
	setErrors,
	history,
	handleHourSubmit,
	handleKeywordsSubmit,
) => {
	return async dispatch => {
		dispatch({
			type: locationActions.CREATE_LOCATION,
		});
		toast.promise(addLocation(data), {
			loading: "Creating location...",
			success: res => {
				history.push("/locations");
				handleHourSubmit(res.data.id, setErrors);
				handleKeywordsSubmit(res.data.id, setErrors);
				newGallery(gallery)
					.then(galleryArr => {
						dispatch({
							type: locationActions.CREATE_LOCATION_FULFILLED,
							payload: res.data,
						});

						createGallery(res.data.gallery_id, galleryArr, data.instagram_page_share)
							.then(r => console.log(r))
							.catch(e => console.log(e));

						dispatch(fetchLocations("published", 1));
					})
					.catch(err => {
						console.log(err);
					});

				return <b>Successfully created location</b>;
			},
			error: err => {
				setErrors(err.response.data.errors);

				dispatch({
					type: locationActions.CREATE_LOCATION_REJECTED,
					payload: err.response.data.errors,
				});
				return <b>Couldn't create location</b>;
			},
		});
	};
};
