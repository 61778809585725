import React from "react";
import { Box } from "@chakra-ui/react";
import Formats from "./Formats";
import Categories from "./Categories";
import SubCategories from "./SubCategories";

export default function EventSelect({ formik, column, countryLabel, ...rest }) {
	return (
		<Box
			display="grid"
			gridTemplateColumns={column ? ["1fr", "1fr 1fr 1fr"] : "1fr "}
			gridGap="0.5em"
			mt="0.5em"
		>
			<Formats formik={formik} column={column} />
			<Categories formik={formik} column={column} />
			<SubCategories formik={formik} column={column} />
		</Box>
	);
}
