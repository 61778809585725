import React, { useState, useEffect } from "react";
import { Input } from "@chakra-ui/react";

import { onLoadImage } from "../../utils/fabricActions";
import { onLoadVideo } from "../../utils/video";
import { onLoadPano } from "../../utils/pano";

import defaultVideoThumbnail from "../../../../../assets/builder/def-vid-thumb.jpeg";
import defaultPanoThumbnail from "../../../../../assets/builder/def-360-thumb.png";

function InputComp({ state, setState, fileInput, canvas, variant, slides, modifySlide }) {
	const [video, setVideo] = useState({
		status: false,
		media: false,
		url: false,
		thumb: defaultVideoThumbnail,
	});

	const [pano, setPano] = useState({
		status: false,
		media: false,
		url: false,
		thumb: defaultPanoThumbnail,
	});

	const resetVideoState = () => {
		setVideo({
			status: false,
			media: false,
			url: false,
			thumb: defaultVideoThumbnail,
		});
	};

	const resetPanoState = () => {
		setVideo({
			status: false,
			media: false,
			url: false,
			thumb: defaultPanoThumbnail,
		});
	};

	useEffect(() => {
		if (video.status && video.media && video.thumb) {
			onLoadVideo(state.activeSlide, video.media, video.thumb, resetVideoState);
		}
	}, [video]);

	useEffect(() => {
		if (pano.status && pano.media) {
			console.log(pano.thumb);
			onLoadPano(state.activeSlide, pano.media, defaultPanoThumbnail, resetPanoState);
		}
	}, [pano]);

	function checkForFile() {
		if (fileInput.current.value.length) {
		} else {
			setState(state => {
				state.imageLoading = false;
			});
		}
		document.body.onfocus = null;
	}

	function addFocusListener() {
		document.body.onfocus = checkForFile;
	}

	const handleLoad = e => {
		let media = e.target.files[0];
		let url = URL.createObjectURL(media);
		if (media.type.toString().includes("video")) {
			setVideo({
				status: true,
				thumb: defaultVideoThumbnail,
				url,
				media,
			});
		} else {
			const reader = new FileReader();
			reader.onload = theFile => {
				const image = new Image();
				image.src = theFile.target.result;
				image.onload = () => {
					const { width, height } = image;
					if (width / height === 2) {
						console.log("IS PANORAMA");
						let url = URL.createObjectURL(media);
						setPano({
							status: true,
							url,
							media,
						});
					} else {
						if (slides[state.activeSlide].isPano || slides[state.activeSlide].isVideo) {
							modifySlide(state.activeSlide, {
								...slides[state.activeSlide],
								isPano: false,
								isVideo: false,
							});
						}
						onLoadImage(canvas, media, url, state, setState, variant);
					}
				};
			};
			reader.readAsDataURL(media);
		}
		fileInput.current.value = null;
	};

	return (
		<>
			<Input
				onClick={addFocusListener}
				type="file"
				accept={variant === "logo" ? "image/*" : "image/*,video/*"}
				name="upload"
				id="upload"
				ref={fileInput}
				display="none"
				onChange={handleLoad}
			/>
		</>
	);
}

export default InputComp;
