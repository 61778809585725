import React from "react";
import { Box, Text, Divider, Image } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { content } from "../components/content";
import { useDispatch } from "react-redux";
import { handleScroll } from "../../../../utils/scroll";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../../shared/table";
import { getAvatar } from "../../../../utils/imgUri";

function Layout({
	header,
	lang,
	pushNotifications,
	loading,
	next_page_url,
	current_page,
	fetchPushNotifications,
	fetched,
}) {
	const dispatch = useDispatch();
	const t = content[lang];

	return (
		<Box
			paddingX="20px"
			display="flex"
			flexDirection="column"
			flexGrow="1"
			overflowY="scroll"
			position="absolute"
			bottom="0"
			right="0"
			width="100%"
			top={["3em", "0"]}
			pb={["5em", "1em"]}
			onScroll={e =>
				handleScroll(
					e,
					() => dispatch(fetchPushNotifications(current_page + 1)),
					current_page,
					loading,
					next_page_url,
				)
			}
		>
			<Box py="3" display="flex" justifyContent="space-between" gridGap="1em">
				<Box>
					<Box mb="1">
						<Text fontSize="24px">{header}</Text>
					</Box>
					<Box w="40px">
						<Divider borderBottom="4px" opacity="1" />
					</Box>
				</Box>
				<Link to="/notifications/new">
					<Box
						border="2px solid #000"
						p={["0.5em", "1em"]}
						cursor="pointer"
						transition="100ms all ease"
						_hover={{ transform: "scale(1.05)" }}
					>
						<Box
							p="0"
							backgroundColor="transparent"
							_hover={{
								backgroundColor: "#transparent",
							}}
							_first={{ _hover: { color: "green" } }}
							_focus={{ border: "none" }}
							color="#D7D8D7"
							display="flex"
							alignItems="center"
						>
							<FontAwesomeIcon icon="plus" color="green" />
							<Text color="#000" _last={{ _hover: { color: "#000" } }} ml="2">
								{t.add_event}
							</Text>
						</Box>
					</Box>
				</Link>
			</Box>
			<Box display="flex" flexGrow="1" pt="30px" flexDirection="column">
				<Table>
					<Thead>
						<Tr>
							<Th>{t.logo}</Th>
							<Th>{t.title}</Th>
							<Th>{t.body}</Th>
							<Th textAlign="center">{t.avatar}</Th>
							<Th>{t.type}</Th>
							<Th>{t.delivered}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{fetched && pushNotifications.data.length
							? pushNotifications.data.map(item => (
									<Tr key={item.id}>
										<Td>
											<Box
												border="1px solid #000"
												borderRadius="100%"
												overflow="hidden"
												width="5em"
												height="5em"
											>
												<Link target="_blank" to={{ pathname: item.dynamic_link }}>
													<Image
														cursor="pointer"
														objectFit="cover"
														width="100%"
														height="100%"
														src={item.image}
													/>
												</Link>
											</Box>
										</Td>
										<Td>{item.title}</Td>
										<Td>{item.body}</Td>
										<Td>
											<Box display="flex" justifyContent="center">
												<Image
													title={`${item.user.name} ${item.user.surname}`}
													width="3em"
													border="0.5px solid black"
													borderRadius="100%"
													objectFit="contain"
													src={getAvatar(item.user.avatar)}
												/>
											</Box>
										</Td>
										<Td>
											<Text
												display="flex"
												justifyContent="center"
												bg={
													item.data_type === "event"
														? "#0E65AF"
														: item.data_type === "news"
														? "#171923"
														: "#AFCB2E"
												}
												borderRadius="2%"
												py="0.3em"
												px="1em"
												color="#FFF"
												fontFamily="Segoe UI Bold"
											>
												{item.data_type.toUpperCase()}
											</Text>
										</Td>
										<Td hide>
											<Box textAlign="center" display="flex" flexDirection="column" gridGap="0.5em">
												<Text
													display="flex"
													fontSize="0.8em"
													bg={item.fcm ? "#728197" : "#495468"}
													borderRadius="2%"
													py="0.3em"
													px="1em"
													color="#FFF"
													fontFamily="Segoe UI Bold"
													textAlign="center"
													justifyContent="center"
													alignItems="center"
													gridGap="1em"
												>
													{item.fcm ? "Push notification" : "Email"}
													<FontAwesomeIcon
														color={item.fcm ? (item.fcm.delivered ? "#AFCB2E" : "red") : "#AFCB2E"}
														icon={
															item.fcm ? (item.fcm.delivered ? "check" : "window-close") : "check"
														}
													/>
												</Text>
											</Box>
										</Td>
									</Tr>
							  ))
							: !loading && (
									<Tr p="5">
										<Td>{t.no_data}</Td>
									</Tr>
							  )}
						{loading && (
							<Tr
								_hover={{
									backgroundColor: "#F2F1F0",
								}}
							>
								{new Array(5).fill(undefined).map((item, id) => (
									<Td key={id}>
										<Skeleton height="20px" />
									</Td>
								))}
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</Box>
	);
}

export default Layout;
