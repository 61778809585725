import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { apiCallAc, setActiveCompanyToken } from "../../../api";
import { fetchEvents } from "../../../store/actions";
import { content } from "./components/content";
import Layout from "./components/Layout";

function Events() {
	const dispatch = useDispatch();

	const [fetchEventsType, setFetchEventsType] = useState("published");
	const [mapModal, setMapModal] = useState(false);
	const [chartsModal, setChartsModal] = useState({
		active: false,
		itemId: null,
	});
	//Fetch company before fetching events
	const company = useSelector(state => state.fetchCompany.company);
	//Fetch events
	const {
		events,
		fetching,
		fetched: fetchedEvents,
		events: { current_page, next_page_url },
	} = useSelector(state => state.fetchEvents);
	//Refetch after changing language
	const lang = useSelector(state => state.languageProvider.language);
	//Refetch after creating new event
	const fetched = useSelector(state => state.createEvent.fetched);

	const handleClose = () => {
		setMapModal(false);
		setChartsModal({
			active: false,
			itemId: null,
		});
	};
	useEffect(() => {
		dispatch({ type: "RESET_STATE" });
	}, []);

	useEffect(() => {
		setActiveCompanyToken(company.id);
		if (apiCallAc.defaults.headers.common && !fetchedEvents) {
			dispatch(fetchEvents(fetchEventsType, 1));
		}
	}, [company, fetched, lang, fetchEventsType]);
	useEffect(() => {
		dispatch(fetchEvents(fetchEventsType, 1, true));
	}, [fetchEventsType]);

	const t = content[lang];
	return (
		<Layout
			loading={fetching}
			setFetchEventsType={setFetchEventsType}
			fetchEventsType={fetchEventsType}
			mapModal={mapModal}
			handleClose={handleClose}
			setMapModal={setMapModal}
			header={t.header}
			events={events}
			lang={lang}
			next_page_url={next_page_url}
			current_page={current_page}
			fetchEvents={fetchEvents}
			fetched={fetchedEvents}
			chartsModal={chartsModal}
			setChartsModal={setChartsModal}
		/>
	);
}

export default Events;
