import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getCities, getCountries, getStates, getTimezones } from "../../../api/libs/countries";
import { updateUser } from "../../../store/actions";
import Layout from "./components/Layout";
import { initialValues } from "./formInitialValues";

export default function ProfileEdit() {
	const history = useHistory();
	const dispatch = useDispatch();

	const { user } = useSelector(state => state.currentUser.user);
	const { fetched } = useSelector(state => state.currentUser);
	const setFullNames = async () => {
		const responseCountry = await getCountries(
			formik.values.country.iso2 || formik.values.country,
			"",
			1,
		);
		formik.setFieldValue("country", responseCountry.data[0]);
		const responseState = await getStates(
			formik.values.state.state_code || formik.values.state,
			"",
			formik.values.country.iso2 || formik.values.country,
			1,
		);
		formik.setFieldValue("state", responseState.data[0]);

		const responseCity = await getCities(
			formik.values.city.name || formik.values.city,
			"",
			formik.values.country.iso2 || formik.values.country,
			formik.values.state.state_code || formik.values.state,
			1,
		);
		formik.setFieldValue("city", responseCity.data[0]);
		const responseTimezone = await getTimezones(
			formik.values.timezone.zone_name || formik.values.timezone,
			1,
			formik.values.country.iso2,
		);
		formik.setFieldValue("timezone", responseTimezone.data[0]);
	};

	const lang = useSelector(state => state.languageProvider.language);

	const onSubmit = values => {
		const formatted = {
			...values,
			country: values.country.iso2,
			state: values.state.state_code,
			city_id: values.city.id,
			timezone: values.timezone.zone_name,
		};
		dispatch(updateUser(formatted, history, formik.setErrors));
	};

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: onSubmit,
	});
	useEffect(() => {
		formik.setValues(initialValues);
		if (fetched) {
			formik.setValues({
				...user,
				country: user.country || "",
				state: user.state || "",
				city: user.city || "",
				timezone: user.timezone || "",
			});
		}
	}, [fetched]);

	useEffect(() => {
		if (typeof formik.values.country === "string" && formik.values.country) {
			setFullNames();
		}
	}, [formik.values.country]);

	return <Layout lang={lang} formik={formik} user={user}></Layout>;
}
