import { tokenActions } from "../../actionTypes";

const initialState = {
	status: false,
	loading: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case tokenActions.REQUEST_TOKEN: {
			return { ...state, loading: true };
		}

		case tokenActions.RESET_RECOVER_STATE: {
			return { ...state, ...initialState };
		}

		case tokenActions.REQUEST_FULFILLED: {
			return { ...state, loading: false, status: true };
		}

		case tokenActions.REQUEST_REJECTED: {
			return { ...state, loading: false, error: action.payload };
		}

		default:
	}

	return state;
};

export default Reducer;
