import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	toogleSidebar,
	setLanguage,
	fetchNotifications,
	fetchNotification,
} from "../../../store/actions";
import { fetchNotificationsCount } from "../../../store/actions/notification";

import Layout from "./components/Layout";

function Header() {
	const dispatch = useDispatch();
	const [expandedNotification, setExpandedNotification] = useState(false);
	const active = useSelector(state => state.sidebar.active);
	const lang = useSelector(state => state.languageProvider.language);
	const { notifications, fetching } = useSelector(state => state.fetchNotifications);
	const {
		count: { amount: notificationAmount },
		fetching: countLoading,
	} = useSelector(state => state.fetchNotificationsCount);
	const { notification, fetching: fetchingNotification } = useSelector(
		state => state.fetchNotification,
	);

	const { name, surname, avatar } = useSelector(state => state.currentUser.user.user);
	const { name: comp, logo } = useSelector(state => state.fetchCompany.company) || "";

	let fn = name.concat(" ", surname);

	const handleToogle = () => {
		dispatch(toogleSidebar());
	};

	const handleLanguageChange = lang => {
		dispatch(setLanguage(lang));
	};

	useEffect(() => {
		dispatch(fetchNotifications());
		dispatch(fetchNotificationsCount());
	}, []);

	useEffect(() => {
		if (expandedNotification) dispatch(fetchNotification(expandedNotification));
	}, [expandedNotification]);

	return (
		<Layout
			avatar={avatar}
			sidebarActive={active}
			user={fn}
			comp={comp}
			handleToogle={handleToogle}
			lang={lang}
			handleLanguageChange={handleLanguageChange}
			logo={logo}
			expandedNotification={expandedNotification}
			setExpandedNotification={setExpandedNotification}
			isLoading={fetching}
			notifications={notifications}
			notification={notification}
			fetchingNotification={fetchingNotification}
			notificationAmount={notificationAmount}
			countLoading={countLoading}
		/>
	);
}

export default Header;
