import React, { forwardRef } from "react";
import "../index.scss";

const Display = forwardRef((props, ref) => (
	<div className="color-picker-container">
		{props.isDisabled ? (
			<div className="picker-disabled" />
		) : (
			<canvas id="color-picker" ref={ref} />
		)}
		<div className="selected" />
	</div>
));

export default Display;
