import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import { getOriginal, getVideo } from "../../../../../../utils/imgUri";
import defaultPanoThumbnail from "../../../../../../assets/builder/def-360-thumb.png";

function MobileGallery({ gallery }) {
	const renderSlide = slide => {
		// string
		if (typeof slide === "string") {
			if (slide.includes("data:image")) {
				return (
					<img
						src={slide}
						style={{ listStyle: "none", width: "100%", height: 220 }}
						alt={`Slide`}
					/>
				);
			} else {
				// https
				if (slide.includes(".mp4")) {
					return (
						<video autoPlay={false} muted loop style={{ height: 220, objectFit: "initial" }}>
							<source src={slide} />
						</video>
					);
				} else {
					// check if is panoramic by url
					return (
						<img
							src={slide}
							style={{
								listStyle: "none",
								width: "100%",
								height: 220,
							}}
							alt={`Slide`}
						/>
					);
				}
			}
		} // object
		else {
			if (slide instanceof File) {
				//is File
				if (slide.type.includes("video")) {
					return (
						<video autoPlay={false} muted loop style={{ height: 220, objectFit: "initial" }}>
							<source src={URL.createObjectURL(slide)} />
						</video>
					);
				} else {
					return (
						<img
							src={URL.createObjectURL(slide)}
							style={{
								listStyle: "none",
								width: "100%",
								height: 220,
							}}
							alt={`Slide`}
						/>
					);
				}
			} else {
				//not file
				if (slide.image) {
					return (
						<img
							src={getOriginal(slide.image)}
							style={{
								listStyle: "none",
								width: "100%",
								height: 220,
							}}
							alt={`Slide`}
						/>
					);
				} else {
					return (
						<video autoPlay={false} muted loop style={{ height: 220, objectFit: "initial" }}>
							<source src={getVideo(slide.video)} />
						</video>
					);
				}
			}
		}
	};

	return (
		<div>
			<Swiper
				id="main"
				tag="section"
				wrapperTag="ul"
				spaceBetween={0}
				slidesPerView={1}
				loop={true}
				autoHeight={true}
			>
				{gallery?.length > 0 &&
					gallery.map((s, k) => <SwiperSlide key={`slide-${k}`}>{renderSlide(s)}</SwiperSlide>)}
			</Swiper>
		</div>
	);
}

export default MobileGallery;
