import React from "react";
// import { Box } from "@chakra-ui/layout";
import { Box, Text } from "@chakra-ui/react";

import PrimaryInput from "../../../shared/inputs/PrimaryInput";
import { useSelector } from "react-redux";
import { content } from "./lang";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import CountrySelect from "../../../shared/inputs/countrySelect";
function Content({ formik, errors }) {
	const lang = useSelector(state => state.languageProvider.language);

	const t = content[lang];
	return (
		<Box pt="34px" pb="34px" display="flex" width="100%" flexDirection="column" as={"form"}>
			<CountrySelect formik={formik} column={true} />

			<Box display="grid" gridTemplateColumns={["1fr", "4fr 2fr 2fr 4fr"]} gridGap="1em">
				<Box>
					<PrimaryInput column={true} label={t.street} type="text" id="street" formik={formik} />
					{errors.street && (
						<Text fontSize={"14px"} color={"red.600"}>
							{errors.street}
						</Text>
					)}
				</Box>
				<Box>
					<PrimaryInput
						column={true}
						label={t.street_number}
						type="text"
						id="street_number"
						formik={formik}
					/>
					{errors.street_number && (
						<Text fontSize={"14px"} color={"red.600"}>
							{errors.street_number}
						</Text>
					)}
				</Box>
				<Box>
					<PrimaryInput
						column={true}
						label={t.postal_code}
						type="text"
						id="postal_code"
						formik={formik}
					/>
					{errors.postal_code && (
						<Text fontSize={"14px"} color={"red.600"}>
							{errors.postal_code}
						</Text>
					)}
				</Box>
				<Box>
					<PrimaryInput
						column={true}
						label={t.address_line_2}
						type="text"
						id="address_line_2"
						formik={formik}
					/>
				</Box>
			</Box>

			<PrimaryInput
				column={true}
				label={t.vat_number}
				type="number"
				id="vat_number"
				formik={formik}
			/>
			<PrimaryButton mt="1em" onClick={formik.handleSubmit} label={t.create} />
		</Box>
	);
}

export default Content;
