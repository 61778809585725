import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import Form from "./Form";
const stripePromise = loadStripe("pk_test_idXEYEBalDxDzEQJ0KFRk5IH00Tm4xoPbw");

export default function Confirm() {
	return (
		<Elements stripe={stripePromise}>
			<Form />
		</Elements>
	);
}
