import { subActions } from "../../actionTypes";

export const fetchSelectedPlan = id => {
	return async dispatch => {
		dispatch({
			type: subActions.FETCH_PLAN_FULFILLED,
			payload: { id },
		});
	};
};
