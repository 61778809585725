export const content = {
	en: {
		selecting: "Selecting",
		start_date: "start date",
		end_date: "end date",
		confirm: "Confirm",
		reset: "Reset",
		range: "Date time range",
		daily: "Daily",
		weekly: "Weekly",
		monthly: "Monthly",
		clocked: "Recurrence",
		none: "None",
		choose: "Click to choose dates",
	},
	de: {
		selecting: "Auswahl",
		start_date: "startdatum",
		end_date: "endtermin",
		confirm: "Bestätigen",
		reset: "Zurücksetzten",
		range: "datum zeitbereich",

		daily: "täglich",
		weekly: "wöchentlich",
		monthly: "monatlich",
		clocked: "Getaktet",
		none: "Keiner",
		choose: "Klicken Sie, um Daten auszuwählen",
	},
};
