export const onDisplayModal = (type, setState) => {
	setState(state => {
		state.modal = {
			active: true,
			type,
		};
		if (type === "fonts") {
			state.activePanel = "TEXT";
		} else {
			state.activePanel = "INIT";
		}
	});
};
export const onCloseModal = setState => {
	setState(state => {
		state.modal = {
			active: false,
			type: null,
		};
		state.activePanel = "INIT";
	});
};
