import { combineReducers } from "redux";

import { languageProvider } from "./language";
import { menu } from "./menu";
import { sidebar } from "./sidebar";
import { builderState } from "./builder";
import { editorReducer } from "./editor";

import * as appStateReducers from "./app";
import * as userReducers from "./user";
import * as companyReducers from "./company";
import * as subscriptionReducers from "./subscription";
import * as locationReducers from "./locations";
import * as eventReducers from "./event";
import * as templateReducers from "./templates";
import * as newsReducers from "./news";
import * as galleryReducers from "./gallery";
import * as couponReducers from "./coupons";
import * as storyReducers from "./story";
import * as notificationReducers from "./notification";
import * as usersReducers from "./users";
import * as keywordReducers from "./keywords";
import * as invoiceReducers from "./invoice";
import * as countriesReducers from "./countries";
import * as pushNotificationReducers from "./pushNotifications";
import * as chatsReducers from "./chats";
import * as paypalReducers from "./paypal";
import * as stripeReducers from "./stripe";
import * as statsReducers from "./stats";

const appReducer = combineReducers({
	...appStateReducers,
	languageProvider,
	...userReducers,
	menu,
	sidebar,
	...companyReducers,
	...subscriptionReducers,
	...locationReducers,
	...eventReducers,
	...templateReducers,
	...newsReducers,
	...storyReducers,
	...couponReducers,
	builderState,
	editorReducer,
	...galleryReducers,
	...notificationReducers,
	...usersReducers,
	...keywordReducers,
	...invoiceReducers,
	...countriesReducers,
	...pushNotificationReducers,
	...chatsReducers,
	...paypalReducers,
	...stripeReducers,
	...statsReducers,
});

const rootReducer = (state, action) => {
	if (action.type === "USER_LOGOUT") {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

export default rootReducer;
