import { subActions } from "../../actionTypes";

const initialState = {
	currentPlan: {},
	fetching: false,
	fetched: false,
	error: null,
};

const Reducer = (state = initialState, action) => {
	switch (action.type) {
		case subActions.FETCH_ACTIVE_PLAN: {
			return { ...state, fetching: true };
		}

		case subActions.FETCH_ACTIVE_PLAN_FULFILLED: {
			return {
				...state,
				fetching: false,
				fetched: true,
				currentPlan: action.payload,
			};
		}

		case subActions.FETCH_ACTIVE_PLAN_REJECTED: {
			return { ...state, fetching: false, error: action.payload };
		}

		default:
			return state;
	}
};

export default Reducer;
