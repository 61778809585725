import React from "react";
import { Box } from "@chakra-ui/react";
import CategoryBtn from "./CategoryBtn";

function StickerCategories({ categoriesCollection, handleFilter }) {
	return (
		<Box w="100%" display="flex" justifyContent="space-evenly" mt="20px">
			{categoriesCollection.map((i, k) => (
				<CategoryBtn key={k} label={i.value} val={i.key} handleFilter={handleFilter} />
			))}
			<CategoryBtn key={"emojis"} label="emjis" val="emoji" handleFilter={handleFilter} />
		</Box>
	);
}

export default StickerCategories;
