import { keywordActions } from "../../actionTypes";
import { getKeywords } from "../../../api/libs/keywords";

export const fetchKeywordsById = (type, id, formik) => {
	return async dispatch => {
		dispatch({
			type: keywordActions.FETCH_KEYWORDS_BY_ID,
		});
		getKeywords(type, id)
			.then(res => {
				dispatch({
					type: keywordActions.FETCH_KEYWORDS_BY_ID_FULFILLED,
					payload: res,
				});
				formik.setFieldValue("keywords", res.data.data);
			})
			.catch(err => {
				dispatch({
					type: keywordActions.FETCH_KEYWORDS_BY_ID_REJECTED,
					payload: err.response.data,
				});
			});
	};
};
