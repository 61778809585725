export const content = {
	en: {
		edit_profile: "Edit profile",
		name: "Name",
		surname: "Surname",
		phone_number: "Phone number",
		vat_number: "VAT Number",
		address: "Address",
		city: "City",
		street: "Street",
		postal_code: "Postal code",
		submit: "Submit",
	},
	de: {
		edit_profile: "Profil bearbeiten",
		name: "Name",
		surname: "Nachname",
		phone_number: "Telefonnummer",
		vat_number: "Umsatzsteuer-Identifikationsnummer",
		address: "Die Anschrift",
		city: "Stadt",
		street: "Straße",
		postal_code: "Postleitzahl",
		submit: "Einreichen",
	},
};
