import React, { useState } from "react";
import { Box, Image, Spinner } from "@chakra-ui/react";

import bg from "../../../../../../../../../assets/builder/transparent_pattern.png";

function StickerItem({ name, loadSticker, url = false }) {
	const [loading, setLoading] = useState(false);

	return (
		<Box
			minW="70px"
			h="50px"
			mr={"10px"}
			background="white"
			backgroundImage={bg}
			backgroundRepeat="repeat"
			backgroundSize="5%"
			display="flex"
			justifyContent="center"
			cursor="pointer"
			border="1px solid rgba(0,0,0,.15)"
			_hover={{ bg: "rgba(170,205,86, 0.1)" }}
			onClick={() => {
				loadSticker(name, setLoading, url);
			}}
			pos="relative"
		>
			{loading ? (
				<Box
					pos="absolute"
					w="100%"
					h="100%"
					top={0}
					left={0}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Spinner color="#AACD56" size="xl" />
				</Box>
			) : (
				// <>
				//   <Image
				//     h="90%"
				//     src={
				//       require(`../../../../../../../../../assets/stickers/${name}`)
				//         .default
				//     }
				//   />
				// </>
				<>
					{url ? (
						<>
							<Image
								h="90%"
								src={`https://static.yezzgo.de/image-editor-statics/stickers/microsoftEmojis/${name}`}
							/>
						</>
					) : (
						<Image
							h="90%"
							src={require(`../../../../../../../../../assets/stickers/${name}`).default}
						/>
					)}
				</>
			)}
		</Box>
	);
}

export default StickerItem;
