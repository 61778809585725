import { statisticActions } from "../../actionTypes";
import { getLogs } from "../../../api/libs/stats";

export const fetchLogsA = refer => {
	return async dispatch => {
		dispatch({
			type: statisticActions.FETCH_LOGS,
		});
		getLogs(refer)
			.then(res => {
				dispatch({
					type: statisticActions.FETCH_LOGS_FULFILED,
					payload: { refer, res: res.data },
				});
			})
			.catch(err => {
				dispatch({
					type: statisticActions.FETCH_LOGS_REJECTED,
					payload: err.response,
				});
			});
	};
};
