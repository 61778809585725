import React from "react";
import { Box, Image, VStack } from "@chakra-ui/react";
import Logo from "../../../../assets/images/logo.svg";
import LanguageSelector from "../../../shared/languageSelector";

function Layout(props) {
	return (
		<Box
			w="100vw"
			h="100vh"
			backgroundImage="url(/images/background.jpg)"
			backgroundSize="cover"
			backgroundPosition="center center"
		>
			<Box position="fixed" top="0.2em" right="0.2em">
				<LanguageSelector />
			</Box>
			<VStack w={"full"} justify={"flex-end"} align={"center"} flexDir="row">
				<Box mt={"20px"} mr={"30px"}>
					<Image w={"80%"} src={Logo} />
				</Box>
			</VStack>
			{props.children}
		</Box>
	);
}

export default Layout;
