import React from "react";
import { Box, Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import { useState, useEffect } from "react";
const AvatarList = ({ chatMembers }) => {
	return (
		<div>
			<AvatarGroup size="sm" max={1}>
				{chatMembers.map(user => {
					return (
						<Avatar key={user.id} name={user.user.name}>
							<AvatarBadge
								boxSize="1.25em"
								bg={
									user.user.last_seen
										? Date.now() - user.user.last_seen < 10000
											? "green.500"
											: "red.500"
										: "red.500"
								}
							/>
						</Avatar>
					);
				})}
			</AvatarGroup>
		</div>
	);
};

export default AvatarList;
