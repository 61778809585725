import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { onCloseModal } from "../../../utils/modal";

function ModalLayout({ children, header, setState }) {
	return (
		<Box
			pos="fixed"
			top="0"
			right="0"
			zIndex="100"
			h={["100%", "100vh"]}
			w="30vw"
			bg="white"
			display="flex"
			flexDir="column"
			border="1px solid black"
		>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				px="20px"
				pt="5px"
				bg="black"
			>
				<Text
					color="white"
					textTransform="uppercase"
					fontSize="25"
					borderBottom="4px solid #73B13F"
				>
					{header}
				</Text>
				<FontAwesomeIcon
					cursor="pointer"
					icon="times"
					color="white"
					style={{ fontSize: "25" }}
					onClick={() => {
						onCloseModal(setState);
					}}
				/>
			</Box>
			<Box h="100%">{children}</Box>
		</Box>
	);
}

export default ModalLayout;
