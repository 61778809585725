import React from "react";
import { Box, Image } from "@chakra-ui/react";

import Player from "./Player";
import ACOverlay from "./ACOverlay";

function ActiveSlide({
	length,
	slide,
	acIndex,
	variant,
	onOpenEditor,
	onCloneSlide,
	onRemoveSlide,
}) {
	const { index, isEdited, isPreview, isVideo, orientation, png, video } = slide;
	return (
		<Box
			pos="relative"
			w="100%"
			display="flex"
			justifyContent="center"
			alignItems="center"
			bg="#efefef"
			mb="auto"
			maxHeight="190px"
			minHeight="190px"
		>
			{png && !isVideo && (
				<Image src={png} borderRadius={variant === "logo" ? "50%" : 0} h="100%" fit="contain" />
			)}
			{isVideo && <Player media={video} />}
			<ACOverlay
				canClone={length < 5 && variant === "gallery"}
				canDelete={length > 1}
				acIndex={acIndex}
				onOpenEditor={onOpenEditor}
				onCloneSlide={onCloneSlide}
				onRemoveSlide={onRemoveSlide}
			/>
		</Box>
	);
}

export default ActiveSlide;
