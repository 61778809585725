import React, { useState, useEffect } from "react";
import { FaEdit, FaWindowClose, FaSave, FaTrashAlt } from "react-icons/fa";
import {
	Flex,
	Input,
	IconButton,
	Box,
	Text,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/react";
import { changeChatName, deleteChat } from "../../../api/libs/chats";
import { useHistory, useParams } from "react-router-dom";

function EditableText({ text, chatId, handleDeleteClick }) {
	const [editing, setEditing] = useState(false);
	const [value, setValue] = useState(text);
	const history = useHistory();
	const handleClick = () => {
		setEditing(true);
		console.log(chatId);
	};

	const handleChange = event => {
		setValue(event.target.value);
	};

	const handleBlur = () => {
		setEditing(false);
		changeChatName(chatId, value);
		console.log(chatId, value);
	};

	return (
		<div>
			{editing ? (
				<Flex alignItems={"center"} justifyContent={"space-between"}>
					<Input
						value={value}
						onChange={handleChange}
						onBlur={handleBlur}
						style={{ marginRight: "10px" }}
					/>
					<Box display={"flex"} flexDirection={"column"} gap={2}>
						<IconButton
							aria-label="Save"
							icon={<FaSave />}
							onClick={handleBlur}
							style={{ marginRight: "10px" }}
						/>
						<IconButton
							aria-label="Cancel"
							icon={<FaWindowClose />}
							onClick={handleBlur}
							style={{ marginRight: "10px" }}
						/>
					</Box>
				</Flex>
			) : (
				<>
					<Flex alignItems={"center"} justifyContent={"space-between"} gap={2} width={"100%"}>
						<Box width={"100%"}>
							{value ? (
								<Text fontWeight={"bold"} fontSize={"16px"} noOfLines={"1"}>
									{value}
								</Text>
							) : (
								<Text>{text}</Text>
							)}
						</Box>
						<Box display={"flex"} flexDirection={"column"} gap={2}>
							<IconButton
								aria-label="Edit"
								icon={<FaEdit />}
								onClick={handleClick}
								style={{ marginRight: "10px" }}
							/>
							<IconButton
								aria-label="Delete"
								icon={<FaTrashAlt />}
								onClick={handleDeleteClick}
								style={{ marginRight: "10px" }}
							/>
						</Box>
					</Flex>
				</>
			)}
		</div>
	);
}

export default EditableText;
